import { put, call } from 'redux-saga/effects'
import { REQUEST_NOTIFICATION, getNotifications } from 'actions/V2/notification'
import { watchRequest } from 'sagas/helper'

import api from 'utils/api'

type Args = {
  size?: number,
  page: number,
  workspace_id?: number
}

function* request(args: Args) {
  const { data, meta } = yield call(api, '/notifications', 'get', { ...args })
  yield put(getNotifications(data, meta, {
    size: args.size,
    workspace_id: args.workspace_id
  }))
  return data
}

export default watchRequest(REQUEST_NOTIFICATION, request)
