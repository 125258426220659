import * as React from 'react';
import Button from 'components/Custom/Button';
import NoData from 'components/NoData';
import SearchBar from 'components/SearchBar';
import { apiV3 } from 'utils/api';
import {
    Button as Btn,
    Input,
    Pagination,
    Tooltip,
    Divider,
    Popconfirm
} from 'antd';
import {
    CollectionItem,
    requestCollection,
    RequestCollectionAction
} from 'actions/V3/collections';
import { DatasourceObj } from 'actions/V3/datasources';
import { NotificationLimit } from 'actions/V3/notificationConfig';
import { DispatchProp, connect } from 'react-redux';
import { RecordSiteStateAction, updateSiteState } from 'actions/uiState';
import {
    requestIndicatorAvailableDataSource,
    RequestIndicatorAvailableDataSourceAction
} from 'actions/V3/indicators';
import { t } from 'i18next';
import './modal.css';
import classNames from 'classnames';
import { isNaN, cloneDeep, values, entries } from 'lodash';
import Highlighter from 'react-highlight-words';
import {
    requestIndicator,
    RequestIndicatorAction,
    REQUEST_INDICATOR_V3
} from 'actions/V3/indicators';
import cache from 'sagas/cache';

type DeleteProps = {
  close: () => void;
  indicatorID: number;
  goBack: () => void;
};

type Props = {
  query: any;
} & DeleteProps &
  DispatchProp<RecordSiteStateAction | RequestIndicatorAction>;

class DeleteModal extends React.PureComponent<Props, {}> {
  deleteIndicator = async () => {
      const { indicatorID, goBack, dispatch, query } = this.props;
      await apiV3(`/indicators/${indicatorID}`, 'delete');
      cache.cleanCache(REQUEST_INDICATOR_V3);
      dispatch(requestIndicator(query));

      dispatch(
          updateSiteState({
              collectionsURL: '',
              indicatorsURL: ''
          })
      );
      goBack();
  };

  render() {
      return (
          <div className='colletion_modal-container'>
              <h3 className='register-title'>{t('indicator.Delete Indicator')}</h3>
              <div className='register-body'>
                  <p className='register-message small_text'>
                      {t('indicator.Deleting this indicator')}
                  </p>
                  <p className='register-message small_text'>
                      {t('indicator.This action cannot be undone')}
                  </p>
              </div>
              <div className='colletion_modal-button_group'>
                  <Button customType='light' radius='rect' onClick={this.props.close}>
                      {t('Cancel')}
                  </Button>
          &nbsp;&nbsp;
                  <Button
                      customType='danger'
                      radius='rect'
                      onClick={this.deleteIndicator}
                  >
                      {t('Delete')}
                  </Button>
              </div>
          </div>
      );
  }
}
const ms: MapState<{ query: any }> = ({
    V3: {
        indicators: { query }
    }
}) => ({
    query
});

export const Delete = connect(ms)(DeleteModal);

interface DataSourceStatePops {
  dataSource: DatasourceObj[];
  meta?: Meta;
  query?: {};
}

type P = {
  indicatorID: number;
  close: () => void;
} & DispatchProp<RequestIndicatorAvailableDataSourceAction> &
  DataSourceStatePops;

class DataSourceClass extends React.PureComponent<P, {}> {
    componentDidMount() {
        this.fetchDataSource();
    }

  fetchDataSource = (query = {}) => {
      const { dispatch, indicatorID } = this.props;
      dispatch(
          requestIndicatorAvailableDataSource({
              ...query,
              size: 5,
              show: false,
              indicator_id: indicatorID
          })
      );
  };

  search = (text: string): void => {
      const name = text.trim();
      this.fetchDataSource({ name });
  };

  selectPage = page => {
      const { query } = this.props;
      this.fetchDataSource({
          ...(query as { indicator_id: number }),
          page
      });
  };

  bindDataSource = async id => {
      const { indicatorID } = this.props;
      await apiV3(`/indicators/${indicatorID}`, 'put', {
          indicator: {
              data_source_id: id
          }
      });
      this.props.close();
  };

  renderHighlight = text => {
      const { query } = this.props;
      return query && (query as any).name ? (
          <Highlighter
              searchWords={[(query as any).name]}
              autoEscape={true}
              textToHighlight={text}
          />
      ) : (
          text
      );
  };

  renderDataSource = () => {
      const { dataSource, meta } = this.props;
      return !dataSource.length && meta ? (
          <NoData tip=' ' />
      ) : (
          <>
              <div className='colletion_modal-data_source-row is-title'>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--uuid'>
                      {t('Name')}
                  </div>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--identifier'>
            UUID
                  </div>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--brand '>
                      {t('datasource.Brand')}
                  </div>
              </div>
              {dataSource.map((v, i) => (
                  <div
                      className='colletion_modal-data_source-row'
                      key={`dataSourceList-${i}`}
                      onClick={() => this.bindDataSource(v.id)}
                  >
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--uuid'>
                          {this.renderHighlight(v.name)}
                      </div>
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--identifier grey_text'>
                          {this.renderHighlight(v.identifier)}
                      </div>
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--brand grey_text'>
                          {v.brand}
                      </div>
                      {/* <div className='colletion_modal-data_source-field colletion_modal-data_source-field--unassign'>
            {t('datasource.data channels unassigned', {
              count: v.unassigned_count
            })}
          </div> */}
                  </div>
              ))}
          </>
      );
  };

  render() {
      const { meta } = this.props;

      return (
          <div>
              <div className='colletion_modal-data_source colletion_modal-data_source-title'>
                  <span>{t('indicator.SELECT DATA SOURCE')}</span>
              </div>
              <SearchBar
                  className='colletion_modal-search'
                  placeholder={t('Search by data source name or UUID')}
                  onSearch={this.search}
                  iconStyle={{
                      marginLeft: 0
                  }}
              />
              {this.renderDataSource()}
              {meta && (
                  <Pagination
                      hideOnSinglePage={true}
                      onChange={this.selectPage}
                      size='small'
                      style={{ padding: '10px 0', textAlign: 'center' }}
                      current={meta.current_page}
                      defaultPageSize={5}
                      total={meta.total_count}
                  />
              )}
          </div>
      );
  }
}

const mapState: MapState<DataSourceStatePops> = ({
    V3: {
        indicators: { dataSource, dataSourceMeta, dataSourceQuery }
    }
}) => ({
    dataSource: dataSource,
    meta: dataSourceMeta,
    query: dataSourceQuery
});

export const DataSource = connect(mapState)(DataSourceClass);

interface CollctionStateProps {
  collections: CollectionItem[];
  meta?: Meta;
  query?: {};
}
class CollectionClass extends React.PureComponent<
  CollctionStateProps &
    DispatchProp<RequestCollectionAction> & {
      id: number;
      indicatorID: number;
      close: () => void;
    },
  {}
> {
    componentDidMount() {
        const { id, dispatch } = this.props;
        dispatch(requestCollection({ show: false, workspace_id: id, size: 5 }));
    }

  selectPage = (page, pageSize) => {
      const { dispatch, id, query } = this.props;
      dispatch(requestCollection({ ...query, page, workspace_id: id }));
  };

  search = (text: string): void => {
      const name = text.trim();
      const { id, dispatch } = this.props;
      dispatch(
          requestCollection({ show: false, workspace_id: id, size: 5, name })
      );
  };

  bindCollection = async collectionID => {
      const { indicatorID, close } = this.props;
      await apiV3(`/collections/${collectionID}/indicators`, 'post', {
          indicators: [indicatorID]
      });
      close();
  };

  renderHighlight = text => {
      const { query } = this.props;
      return query && (query as any).name ? (
          <Highlighter
              searchWords={[(query as any).name]}
              autoEscape={true}
              textToHighlight={text}
          />
      ) : (
          text
      );
  };

  renderCollection = () => {
      const { collections, meta } = this.props;
      return !collections.length && meta ? (
          <NoData tip=' ' />
      ) : (
          collections.map((v, i) => (
              <div
                  className='colletion_modal-data_source-row'
                  key={`collection-${i}`}
                  onClick={() => this.bindCollection(v.id)}
              >
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--collection_name'>
                      {this.renderHighlight(v.name)}
                  </div>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--collection_parameter'>
                      {v.indicators.slice(0, 5).map((indicator, index) => (
                          <span key={`collection-parameter-${index}`}>
                              {indicator.name}
                          </span>
                      ))}
                      {v.indicators.length > 5 && (
                          <Tooltip
                              placement='bottom'
                              title={v.indicators
                                  .slice(5)
                                  .map(ii => ii.name + ' ')
                                  .toString()}
                          >
                              <span className='grey_text'>{`+${v.indicators.length -
                  5}`}</span>
                          </Tooltip>
                      )}
                  </div>
              </div>
          ))
      );
  };

  render() {
      const { meta } = this.props;
      return (
          <div>
              <div className='colletion_modal-data_source colletion_modal-data_source-title'>
                  <span>{t('indicator.SELECT COLLECTION')}</span>
              </div>

              <SearchBar
                  onSearch={this.search}
                  className='colletion_modal-search'
                  placeholder={t('Search by collection name')}
                  iconStyle={{
                      marginLeft: 0
                  }}
              />

              {this.renderCollection()}
              {meta && (
                  <Pagination
                      hideOnSinglePage={true}
                      onChange={this.selectPage}
                      size='small'
                      style={{ padding: '10px 0', textAlign: 'center' }}
                      current={meta.current_page}
                      defaultPageSize={5}
                      total={meta.total_count}
                  />
              )}
          </div>
      );
  }
}
const mapStateCollection: MapState<CollctionStateProps> = ({
    V3: {
        collections: { data, query, meta }
    }
}) => ({
    collections: data,
    meta,
    query
});

export const Collection = connect(mapStateCollection)(CollectionClass);

type NotificationState = Readonly<{
  updated: {
    [P: number]: {
      type: 'max' | 'min';
      value: string;
    };
  };
  newLimit: {
    type: 'max' | 'min';
    value: string;
  }[];
}>;
export class Notification extends React.PureComponent<
  {
    limit: NotificationLimit[];
    indicatorID: number;
    cancel: () => void;
    refresh: () => void;
  },
  NotificationState
> {
  state: NotificationState = {
      updated: {},
      newLimit: []
  };

  newLimit = () => {
      const { newLimit } = this.state;
      this.setState({
          newLimit: newLimit.concat([
              {
                  type: 'max',
                  value: ''
              }
          ])
      });
  };

  changeNewLimit = (index: number, type: 'value' | 'type', value) => {
      const { newLimit } = this.state;
      const clone = newLimit.slice();
      clone[index] = { ...clone[index], [type]: value };
      this.setState({
          newLimit: clone
      });
  };

  removeNewLimit = index => {
      const { newLimit } = this.state;
      this.setState({
          newLimit: newLimit.filter((v, i) => i !== index)
      });
  };

  deleteLimit = async id => {
      await apiV3(`/notification_configs/${id}`, 'delete');
      this.props.refresh();
  };

  updateLimit = (id, type: 'type' | 'value', value) => {
      const { updated } = this.state;
      const { limit } = this.props;
      const clone = cloneDeep(updated);
      if (!clone[id]) {
          const sourceLimit = limit.find(v => v.id === id);
          clone[id] = {
              type: sourceLimit!.type,
              value: sourceLimit!.value + ''
          };
      }

      clone[id][type] = value;
      this.setState({ updated: clone });
  };

  updateAndCreate = async () => {
      const { newLimit, updated } = this.state;
      const { indicatorID, cancel, refresh } = this.props;
      const updatedLimit = entries(updated);
      if (newLimit.length) {
          await apiV3('/notification_configs', 'post', {
              category: 'indicator',
              indicator_id: indicatorID,
              factors: newLimit
          });
      }

      if (updatedLimit.length) {
          const allPromise = updatedLimit.map(([id, limit]) =>
              apiV3(`/notification_configs/${id}`, 'patch', {
                  notification_config: limit
              })
          );

          await Promise.all(allPromise);
      }

      refresh();
      cancel();
  };
  render() {
      const { limit } = this.props;
      const { newLimit, updated } = this.state;
      const all = values(updated).concat(newLimit);

      const invaild = !all.length || all.some(v => !v.value || isNaN(+v.value));

      return (
          <div className='colletion_modal-calibration'>
              <div className='colletion_modal-calibration-row is-text'>
                  <div className='colletion_modal-calibration-label' />
                  <div className='colletion_modal-calibration-content'>
                      <div
                          className='colletion_modal-calibration-factor is-notification'
                          style={{ textIndent: '1em' }}
                      >
                          {t('indicator.Type')}
                      </div>
                      <div className='colletion_modal-calibration-value is-notification'>
                          {t('indicator.Max Value')}
                      </div>
                  </div>
              </div>

              {limit.map((item, index) => (
                  <div
                      className='colletion_modal-calibration-row'
                      key={`collection-limit-${index}`}
                  >
                      <div className='colletion_modal-calibration-label'>
                          {t('Factors')}
                      </div>
                      <div className='colletion_modal-calibration-content'>
                          <div className='colletion_modal-calibration-factor is-notification'>
                              <Btn.Group>
                                  <Button
                                      onChange={() => this.updateLimit(item.id, 'type', 'max')}
                                      customType={
                                          (updated[item.id] || item).type === 'max'
                                              ? 'dark'
                                              : 'light'
                                      }
                                  >
                                      {t('MAX')}
                                  </Button>
                                  <Button
                                      onChange={() => this.updateLimit(item.id, 'type', 'min')}
                                      customType={
                                          (updated[item.id] || item).type === 'max'
                                              ? 'light'
                                              : 'dark'
                                      }
                                  >
                                      {t('MIN')}
                                  </Button>
                              </Btn.Group>
                          </div>
                          <div
                              className={classNames(
                                  'colletion_modal-calibration-value',
                                  'is-notification',
                                  'ant-form-item-control',
                                  {
                                      'has-error':
                      updated[item.id] && isNaN(+updated[item.id].value)
                                  }
                              )}
                          >
                              <Input
                                  onChange={e =>
                                      this.updateLimit(item.id, 'value', e.target.value.trim())
                                  }
                                  size='small'
                                  value={(updated[item.id] || item).value}
                              />
                          </div>
                          <Popconfirm
                              title={t('Are you sure delete this Notification Limit?')}
                              onConfirm={() => this.deleteLimit(item.id)}
                              okType='danger'
                          >
                              <a className='material-icons colletion_modal-calibration-delete danger_text'>
                  delete
                              </a>
                          </Popconfirm>
                      </div>
                  </div>
              ))}

              <Divider />

              {newLimit.map((item, index) => (
                  <div
                      className='colletion_modal-calibration-row'
                      key={`collection-limit-${index}`}
                  >
                      <div className='colletion_modal-calibration-label'>Factors</div>
                      <div className='colletion_modal-calibration-content'>
                          <div className='colletion_modal-calibration-factor is-notification'>
                              <Btn.Group>
                                  <Button
                                      onClick={() => {
                                          if (item.type === 'min') {
                                              this.changeNewLimit(index, 'type', 'max');
                                          }
                                      }}
                                      customType={item.type === 'max' ? 'dark' : 'light'}
                                  >
                                      {t('MAX')}
                                  </Button>
                                  <Button
                                      onClick={() => {
                                          if (item.type === 'max') {
                                              this.changeNewLimit(index, 'type', 'min');
                                          }
                                      }}
                                      customType={item.type === 'max' ? 'light' : 'dark'}
                                  >
                                      {t('MIN')}
                                  </Button>
                              </Btn.Group>
                          </div>
                          <div
                              className={classNames(
                                  'colletion_modal-calibration-value',
                                  'is-notification',
                                  'ant-form-item-control',
                                  {
                                      'has-error': !item.value || isNaN(+item.value)
                                  }
                              )}
                          >
                              <Input
                                  size='small'
                                  value={item.value}
                                  onChange={e =>
                                      this.changeNewLimit(index, 'value', e.target.value.trim())
                                  }
                              />
                          </div>
                          <a
                              onClick={() => this.removeNewLimit(index)}
                              className='material-icons colletion_modal-calibration-delete danger_text'
                          >
                delete
                          </a>
                      </div>
                  </div>
              ))}

              <div className='colletion_modal-calibration-row'>
                  <div className='colletion_modal-calibration-label' />
                  <div className='colletion_modal-calibration-content'>
                      <div className='colletion_modal-calibration-factor'>
                          <span
                              className='accent_text collection-add_text'
                              onClick={this.newLimit}
                          >
                              <i className='material-icons'>add</i>
                              <span>{t('indicator.Add Factor')}</span>
                          </span>
                      </div>
                  </div>
              </div>

              <div className='colletion_modal-calibration-button_group'>
          &nbsp; &nbsp; &nbsp;
                  <Button customType='light' size='small' onClick={this.props.cancel}>
                      {t('Cancel')}
                  </Button>
          &nbsp; &nbsp;
                  <Button
                      onClick={this.updateAndCreate}
                      customType='dark'
                      size='small'
                      disabled={invaild}
                  >
                      {t('Save')}
                  </Button>
              </div>
          </div>
      );
  }
}
