import React from 'react';
import * as Picker from 'react-color';
import { t } from 'i18next';
import {
    Checkbox,
    Col,
    Icon,
    Input,
    InputNumber,
    Popover,
    Radio,
    Row,
    Slider,
    Upload,
    Switch,
    Select
} from 'antd';
import Video from 'components/Video';
import Cloud from '-!svg-react-loader!images/svg/cloud.svg';
import { DraggerProps } from 'antd/lib/upload';
import customKioskSettings from '../../vendors/custom';
import CustomSelect from 'components/Custom/Select';
import { fontFamily } from 'utils/constant';

const Dragger: React.SFC<DraggerProps> = Upload.Dragger;
const Option = Select.Option;

export default function ThemeSetting({
    state,
    updateKioskData,
    movedDownSetState,
    uploadWebBackground,
    onChangeRemoveImage,
    uploadWebLogo,
    handleColorChange,
    handleFontColorChange
}) {
    const {
        background_image,
        background_video,
        logo_image,
        currentKiosk,
        rm_background,
        rm_logo
    } = state;

    if (!currentKiosk) {
        return <div>{t('all locations has kiosks')}</div>;
    }
    const bgVideo =
    background_video !== undefined
        ? background_video
        : currentKiosk.background_video;
    const bgImage = background_image || currentKiosk.background_image;
    const logoImage = logo_image || currentKiosk.logo_image;
    const postion = currentKiosk.data_panel_position || 'left';
    const theme = currentKiosk.theme_color || window.PRIMARY_COLOR;
    const fontColor = currentKiosk.font_color || '#ffffff';
    const useVideo = currentKiosk.enable_background_video;
    const opacity =
    typeof currentKiosk.data_panel_opacity === 'number'
        ? currentKiosk.data_panel_opacity
        : 0.9;
    const priColors = [
        '#006b94',
        window.PRIMARY_COLOR !== '#006b94' && window.PRIMARY_COLOR
    ].filter(Boolean);
    const colors = (priColors as string[]).concat([
        '#D9E3F0',
        '#F47373',
        '#697689',
        '#37D67A',
        '#2CCCE4',
        '#555555',
        '#dce775'
    ]);

    return (
        <div className='body'>
            <div className='location_detail-setting-row' style={{ marginBottom: 0 }}>
                <div className='location_detail-setting-row_left'>
                    <div className='label'>{t('Background')}</div>
                </div>

                <div className='location_detail-setting-row_right'>
                    <Radio.Group
                        onChange={(e) =>
                            updateKioskData(
                                'enable_background_video',
                                e.target.value === 'video'
                            )
                        }
                        value={useVideo ? 'video' : 'image'}
                        buttonStyle='solid'
                        size='small'
                    >
                        <Radio.Button value='image'>{t('Image')}</Radio.Button>
                        <Radio.Button value='video'>{t('Video')}</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            <div className='upload-bg'>
                {useVideo ? (
                    <>
                        <p className='register-group-label'>
                            {t('Video address')}
                            <a
                                href='https://blog.qlear.io/2019/02/18/Kiosk-Video.html'
                                target='_blank'
                                rel='noopener noreferrer'
                            >
                                <Icon
                                    type='question-circle'
                                    className='register-add_location-icon'
                                />
                            </a>
                        </p>
                        <Input
                            value={bgVideo}
                            onChange={(e) =>
                                movedDownSetState({ background_video: e.target.value })
                            }
                        />
                        {bgVideo && (
                            <div className='kiosk-video'>
                                <Video url={bgVideo} autoPlay={false} />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <p
                            className='small_text'
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            {t('kiosk.Background size')}
                        </p>
                        <Dragger
                            accept='image/jpeg,image/png,image/jpg'
                            showUploadList={false}
                            customRequest={uploadWebBackground}
                            style={{
                                backgroundImage: `url(${bgImage})`
                            }}
                        >
                            <p className='ant-upload-text'>
                                <Cloud className='register-add_location-cloud' />
                                {t('Drop files')}
                                <a className='accent_text'>{t('browse')}</a>
                            </p>
                        </Dragger>
                        {bgImage && (
                            <div className='check-remove'>
                                <Checkbox
                                    checked={rm_background}
                                    onChange={(v) =>
                                        onChangeRemoveImage('rm_background', v.target.checked)
                                    }
                                >
                                    {t('kiosk.Remove Image')}
                                </Checkbox>
                            </div>
                        )}
                    </>
                )}
            </div>

            {currentKiosk.template_key !==
        customKioskSettings.covid_index.template_key && (
                <div>
                    <div className='upload-logo'>
                        <div className='label'>{t('Logo')}</div>
                        <Dragger
                            accept='image/jpeg,image/png,image/jpg'
                            showUploadList={false}
                            customRequest={uploadWebLogo}
                            style={{
                                backgroundImage: `url(${logoImage})`
                            }}
                        >
                            <p className='ant-upload-text'>
                                <Cloud className='register-add_location-cloud' />
                                {t('Drop files')}
                                <a className='accent_text'>{t('browse')}</a>
                            </p>
                        </Dragger>
                        {logoImage && (
                            <div className='check-remove'>
                                <Checkbox
                                    checked={rm_logo}
                                    onChange={(v) =>
                                        onChangeRemoveImage('rm_logo', v.target.checked)
                                    }
                                >
                                    {t('kiosk.Remove Logo')}
                                </Checkbox>
                            </div>
                        )}
                    </div>

                    <div className='location_detail-setting-row'>
                        <div className='location_detail-setting-row_left'>
                            <div className='label'>{t('kiosk.theme color')}</div>
                        </div>

                        <div className='location_detail-setting-row_right'>
                            <Popover
                                content={
                                    <Picker.BlockPicker
                                        triangle='hide'
                                        color={theme}
                                        colors={colors}
                                        onChangeComplete={handleColorChange}
                                    />
                                }
                                trigger='click'
                                overlayClassName='color-picker-block'
                            >
                                <div className='kiosk-color-swatch'>
                                    <div
                                        className='kiosk-color-block'
                                        style={{ backgroundColor: theme }}
                                    />
                                </div>
                            </Popover>
                        </div>
                    </div>

                    <p className='small_text kiosk-des'>{t('kiosk.theme color des')}</p>
                    <div className='location_detail-setting-row'>
                        <div className='location_detail-setting-row_left'>
                            <div className='label'>{t('kiosk.font color')}</div>
                        </div>

                        <div className='location_detail-setting-row_right'>
                            <Popover
                                content={
                                    <Picker.BlockPicker
                                        triangle='hide'
                                        color={fontColor}
                                        colors={colors}
                                        onChangeComplete={handleFontColorChange}
                                    />
                                }
                                trigger='click'
                                overlayClassName='color-picker-block'
                            >
                                <div className='kiosk-color-swatch'>
                                    <div
                                        className='kiosk-color-block'
                                        style={{ backgroundColor: fontColor }}
                                    />
                                </div>
                            </Popover>
                        </div>
                    </div>
                    <div className='location_detail-setting-row'>
                        <div className='location_detail-setting-row_left'>
                            <div className='label'>{t('kiosk.font setting')}</div>
                        </div>

                        <div className='location_detail-setting-row_right'>
                            <CustomSelect
                                underline={false}
                                className='primary-indicator'
                                dropdownMatchSelectWidth={true}
                                value={currentKiosk.font}
                                allowClear={true}
                                onChange={(v) => updateKioskData('font', v)}
                            >
                                {fontFamily.map((font, i) => (
                                    <Option key={i} value={font}>
                                        {font}
                                    </Option>
                                ))}
                            </CustomSelect>
                        </div>
                    </div>

                    <div className='location_detail-setting-row'>
                        <div className='location_detail-setting-row_left'>
                            <div className='label'>{t('kiosk.data panel position')}</div>
                        </div>

                        <div className='location_detail-setting-row_right'>
                            <Radio.Group
                                onChange={(e) =>
                                    updateKioskData('data_panel_position', e.target.value)
                                }
                                value={postion}
                                buttonStyle='solid'
                                size='small'
                            >
                                <Radio.Button value='left'>{t('kiosk.left')}</Radio.Button>
                                <Radio.Button value='right'>{t('kiosk.right')}</Radio.Button>
                            </Radio.Group>
                        </div>
                    </div>

                    <div className='location_detail-setting-row'>
                        <div className='location_detail-setting-row_left'>
                            <div className='label'>{t('kiosk.data panel opacity')}</div>
                        </div>

                        <div className='location_detail-setting-row_right w200'>
                            <Row>
                                <Col span={10}>
                                    <Slider
                                        min={0}
                                        max={1}
                                        onChange={(v) => updateKioskData('data_panel_opacity', v)}
                                        value={typeof opacity === 'number' ? opacity : 0}
                                        step={0.1}
                                    />
                                </Col>
                                <Col span={4}>
                                    <InputNumber
                                        min={0}
                                        max={1}
                                        style={{ marginLeft: 16 }}
                                        step={0.1}
                                        value={opacity}
                                        onChange={(v) => updateKioskData('data_panel_opacity', v)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            )}
            <div className='location_detail-setting-row'>
                <div className='location_detail-setting-row_left'>
                    <div className='label'>{t('kiosk.background fullscreen')}</div>
                </div>
                <div className='location_detail-setting-row_right'>
                    <Switch
                        checked={currentKiosk.background_full_screen}
                        onChange={(v) => updateKioskData('background_full_screen', v)}
                    />
                </div>
            </div>
            <p className='small_text kiosk-des'>
                {t('kiosk.background fullscreen des')}
            </p>
        </div>
    );
}
