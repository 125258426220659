import React from 'react'
import { Button, Upload } from 'antd'
import { DraggerProps } from 'antd/lib/upload'
import { t } from 'i18next'
import Cloud from '-!svg-react-loader!images/svg/cloud.svg'

const Dragger: React.SFC<DraggerProps> = Upload.Dragger

export default function AppThemeSetting({
    state,
    currentKiosk,
    uploadAppBackground,
    uploadAppLogo,
    movedDownSetState,
    doSave
}) {
    const { app_background_image, app_logo_image } = state

    const bgImage = app_background_image || (currentKiosk && currentKiosk.app_background_image)
    const logoImage = app_logo_image || (currentKiosk && currentKiosk.app_logo_image)

    return (
        <div className='body'>
            <div className='location_detail-setting-row' style={{ marginBottom: 0 }}>
                <div className='location_detail-setting-row_left'>
                    <div className='label'>{t('Background')}</div>
                </div>
            </div>
            <div className='upload-bg'>
                <p className='small_text' style={{ marginBottom: 10, marginTop: 10 }}>
                    {t('kiosk.image only')}
                </p>
                <Dragger
                    accept='image/jpeg,image/png,image/jpg'
                    showUploadList={false}
                    customRequest={uploadAppBackground}
                    style={{
                        backgroundImage: `url(${bgImage})`
                    }}
                >
                    <p className='ant-upload-text'>
                        <Cloud className='register-add_location-cloud' />
                        {t('Drop files')}
                        <a className='accent_text'>{t('browse')}</a>
                    </p>
                </Dragger>
            </div>

            <div className='upload-logo'>
                <div className='label'>{t('Logo')}</div>
                <Dragger
                    accept='image/jpeg,image/png,image/jpg'
                    showUploadList={false}
                    customRequest={uploadAppLogo}
                    style={{
                        backgroundImage: `url(${logoImage})`
                    }}
                >
                    <p className='ant-upload-text'>
                        <Cloud className='register-add_location-cloud' />
                        {t('Drop files')}
                        <a className='accent_text'>{t('browse')}</a>
                    </p>
                </Dragger>
            </div>
            <div className='footer'>
                <Button
                    type='primary'
                    ghost={true}
                    onClick={() => {
                        movedDownSetState({
                            appThemeModalVisible: false
                        })
                    }}
                >
                    {t('Cancel')}
                </Button>
                <Button type='primary' onClick={doSave}>
                    {t('Save')}
                </Button>
            </div>
        </div>
    )
}
