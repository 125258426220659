import GraphIcon from '-!svg-react-loader!images/svg/Graph.svg';
import KioskIcon from '-!svg-react-loader!images/svg/Kiosk.svg';
import LocationIcon from '-!svg-react-loader!images/svg/location.svg';
import ReportIcon from '-!svg-react-loader!images/svg/Report.svg';
import SettingIcon from '-!svg-react-loader!images/svg/settings.svg';
import TableIcon from '-!svg-react-loader!images/svg/Table.svg';
import WorkspaceIcon from '-!svg-react-loader!images/svg/Workspace.svg';
import { changeModal, updateSiteState } from 'actions/uiState';
import {
  requestWorkspace,
  requestWorkspaceDelete,
  requestWorkspaceEdit
} from 'actions/V3/workspace';
import { Dropdown, Form, Icon, Input, Menu, Modal } from 'antd';
import classnames from 'classnames';
import CustomButton from 'components/Custom/Button';
import { P, State, StateProps } from 'containers/Navbar/type';
import { t } from 'i18next';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { promiseDispatch } from 'utils';
import { apiV3 } from 'utils/api';
import { Guard } from 'utils/guard';
import './index.css';

interface ApiResponse {
  data: {
    access_token: string;
    secret_token: string;
  };
}

type Key =
  | 'workspaces'
  | 'report'
  | 'graph'
  | 'data'
  | 'calendar'
  | 'kiosk'
  | 'locations'
  | 'setting';

type LinkItem = {
  icon: any;
  text: string;
  disable?: boolean;
  to: string;
  key: Key;
};

@Guard
class Navbar extends React.PureComponent<P, State> {
  state: State = {
    canDelWs: false,
    modal: ''
  };

  GuardHandle_workspaceOverviewUpdate = (rule?: GuardRule) =>
    rule && rule.enable;

  componentDidMount() {
    const { workspaceID } = this.props;
    this.fetchWorkspaceData(workspaceID);
  }

  UNSAFE_componentWillReceiveProps(next: P) {
    const id = this.props.workspaceID;
    const nextID = next.workspaceID;

    if (id !== nextID) {
      this.fetchWorkspaceData(nextID);
    }
  }

  createLinks = (): LinkItem[] => {
    const { settingsURL, locationID, workspaceID: id, locations } = this.props;
    const isMobile = window.innerWidth <= 576;

    let locationPath = `/workspaces/${id}/graph`;

    if (!locationID && isMobile && locations.length > 0) {
      locationPath += `/${locations[0].id}`;
    } else if (locationID) {
      locationPath += `/${locationID}`;
    }

    return [
      {
        icon: WorkspaceIcon,
        text: t('navbar.overview'),
        key: 'workspaces',
        to: `/workspaces/${id}`
      },
      {
        icon: LocationIcon,
        text: t('navbar.location'),
        key: 'locations',
        to: `/workspaces/${id}/locations${locationID ? '/' + locationID : ''}`
      },
      {
        icon: ReportIcon,
        text: t('navbar.report'),
        key: 'report',
        to: `/workspaces/${id}/reports`
      },
      {
        icon: GraphIcon,
        text: t('navbar.graph'),
        key: 'graph',
        to: locationPath
      },
      {
        icon: TableIcon,
        text: t('navbar.Table'),
        key: 'data',
        to: `/workspaces/${id}/data${locationID ? '?location_id=' + locationID : ''
          }`
      },
      {
        icon: KioskIcon,
        text: t('navbar.Kiosk'),
        key: 'kiosk',
        to: `/workspaces/${id}/kiosk`
      },
      {
        icon: SettingIcon,
        text: t('navbar.setting'),
        key: 'setting',
        to:
          settingsURL && settingsURL.includes(`/workspaces/${id}`)
            ? settingsURL
            : `/workspaces/${id}/collections`
      }
    ];
  };

  filterLinksForMobile = (links: LinkItem[]): LinkItem[] => {
    const isMobile = window.innerWidth <= 576;
    if (isMobile) {
      return links.filter(link =>
        link.key === 'workspaces' ||
        link.key === 'kiosk' ||
        link.key === 'locations' ||
        link.key === 'graph'
      );
    }
    return links;
  };

  push = (key: Key, to: string) => {
    const { history } = this.props;
    history.push(to);
    // this.setState({ modal: true, key });
  };

  fetchWorkspaceData = id => {
    const { dispatch } = this.props;
    dispatch(requestWorkspace({ workspace_id: id }));
  };

  Guard_handleRemoveWs = e => {
    e.preventDefault();
    const { workspaceDetail } = this.props;
    if (!workspaceDetail) {
      return;
    }
    const { location, indicator, data_source } = workspaceDetail.counter || {
      location: {
        total: 0
      },
      indicator: {
        total: 0
      },
      data_source: {
        total: 0
      }
    };
    // 仅仅当如下三个数量都为0时才可以删除workspace
    this.setState({
      modal: 'delete',
      canDelWs: location.total + indicator.total + data_source.total === 0
    });
  };

  Guard_showAPIToken = () => {
    this.setState({ modal: 'develop' });
  };

  navToTab = tab => {
    const { history, workspaceID } = this.props;
    history.push(`/workspaces/${workspaceID}/${tab}`);
  };

  detectSelectd = (key: Key): boolean => {
    const {
      location: { pathname }
    } = this.props;

    if (key === 'setting') {
      return ['indicators', 'people', 'collections', 'datasources'].some(v =>
        pathname.includes(v)
      );
    }

    if (key !== 'workspaces') {
      if (key === 'data') {
        return pathname.includes('data') && !pathname.includes('datasources');
      }
      return pathname.includes(key);
    }

    return [
      'locations',
      'report',
      'graph',
      'data',
      'indicators',
      'people',
      'collections',
      'kiosk'
    ].every(v => !pathname.includes(v));
  };

  goToUpgrade = () => {
    const { history, workspaceID } = this.props;
    history.push({
      pathname: `/upgrade/${workspaceID}/intro`
    });
  };

  confirmDeleteWs = async e => {
    const { workspaceDetail, history, dispatch } = this.props;
    if (!workspaceDetail) {
      return;
    }
    await promiseDispatch({
      dispatch,
      actionCreator: requestWorkspaceDelete,
      payload: {
        id: workspaceDetail.id
      }
    });
    dispatch(
      updateSiteState({
        workspaceID: ''
      })
    );
    this.closeModal();
    history.replace('/workspaces');
  };

  submitPassword = e => {
    const { workspaceID, form } = this.props;
    e.preventDefault();
    form.validateFields((err, data) => {
      apiV3<ApiResponse>(`/workspaces/${workspaceID}/token`, 'post', data).then(resp => {
        const { access_token, secret_token } = resp.data;
        this.setState({
          showToken: {
            access_token,
            secret_token
          }
        });
      });
    });
  };


  submitEditWs = e => {
    const { form, dispatch, workspaceDetail } = this.props;
    if (!workspaceDetail) {
      return;
    }
    e.preventDefault();
    form.validateFields(async (err, data) => {
      await promiseDispatch({
        dispatch,
        actionCreator: requestWorkspaceEdit,
        payload: {
          id: workspaceDetail.id,
          name: data.name
        }
      });
      this.closeModal();
    });
  };

  mouseIn = (e: any) => {
    e.target.innerHTML = t('overview.UPGRAGE');
  };

  mouseOut = (e: any) => {
    e.target.innerHTML = t('overview.BASIC');
  };

  renderModal() {
    const { modal, canDelWs, showToken } = this.state;
    const {
      workspaceDetail,
      form: { getFieldDecorator }
    } = this.props;

    switch (modal) {
      case 'develop':
        return showToken ? (
          <div>
            <label>{t('Access Token')}:</label>
            <div className='token-value'>{showToken.access_token}</div>
            <label>{t('Secret Token')}:</label>
            <div className='token-value'>{showToken.secret_token}</div>
          </div>
        ) : (
          <Form
            onSubmit={this.submitPassword}
            layout='vertical'
            className='form'
          >
            <Form.Item label={t('Authorization Verify')}>
              {getFieldDecorator('password', {})(
                <Input type='password' placeholder={t('account.pwd tip')} />
              )}
            </Form.Item>
            <div className='submit-btn-wrap'>
              <CustomButton
                customType='dark'
                htmlType='submit'
                className='verify-button'
              >
                {t('Verify')}
              </CustomButton>
            </div>
          </Form>
        );
      case 'delete':
        return canDelWs ? (
          <div className='can-del-ws'>
            <div className='can-del-ws-title'>{t('Delete Workspace')}</div>
            <div className='can-del-ws-msg'>
              {t('overview.If you delete this workspace')}
            </div>
            <div className='can-del-ws-footer'>
              <CustomButton customType='light' onClick={this.closeModal}>
                {t('Not right now')}
              </CustomButton>
              <CustomButton
                customType='danger'
                className='del-ws-button'
                onClick={this.confirmDeleteWs}
              >
                {t('Yes, delete workspace')}
              </CustomButton>
            </div>
          </div>
        ) : (
          <div className='cannot-del-ws'>
            <div className='cannot-del-ws-title'>
              <Icon
                type='exclamation-circle'
                theme='filled'
                style={{ color: '#D75453' }}
              />
              <span>{t('overview.Delete Failed')}</span>
            </div>
            <div className='cannot-del-ws-p'>
              {t('overview.Workspace cannot be deleted')}
            </div>
            <div className='cannot-del-ws-p'>
              {t('overview.Please remove all locations')}
            </div>
          </div>
        );
      case 'edit':
        return (
          <>
            <Form
              onSubmit={this.submitEditWs}
              layout='vertical'
              className='form'
            >
              <Form.Item label={t('Workspace Name')}>
                {getFieldDecorator('name', {
                  initialValue: workspaceDetail!.name
                })(<Input type='text' />)}
              </Form.Item>
              <div className='submit-btn-wrap'>
                <CustomButton customType='light' onClick={this.closeModal}>
                  {t('Cancel')}
                </CustomButton>
                <CustomButton
                  customType='dark'
                  htmlType='submit'
                  className='verify-button'
                >
                  {t('Confirm')}
                </CustomButton>
              </div>
            </Form>
          </>
        );
      default:
        return null;
    }
  }

  menu = () => {
    const editAble = this.GuardHandle_workspaceOverviewUpdate();
    return (
      <Menu>
        {editAble && (
          <Menu.Item key='0'>
            <a onClick={this.Guard_showAPIToken}>
              <i className='iconfont icon-code' />
              <span className='icon-desc'>{t('API Token')}</span>
            </a>
          </Menu.Item>
        )}

        {editAble && (
          <Menu.Item key='1'>
            <a onClick={() => this.setState({ modal: 'edit' })}>
              <Icon type='edit' theme='outlined' />
              <span className='icon-desc'>{t('Edit Workspace')}</span>
            </a>
          </Menu.Item>
        )}

        <Menu.Item key='2'>
          <a onClick={this.Guard_handleRemoveWs}>
            <Icon type='delete' theme='outlined' />
            <span className='icon-desc'>{t('Delete Workspace')}</span>
          </a>
        </Menu.Item>
        {editAble && (
          <Menu.Item key='3'>
            <a
              onClick={() => {
                this.props.dispatch(changeModal('ActivityLogs'));
              }}
            >
              <i className='iconfont icon-activity1' />
              <span className='icon-desc'>{t('overview.Activity')}</span>
            </a>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  closeModal = () => this.setState({ modal: '' });

  backList = () => {
    const { history } = this.props;
    history.replace('/workspaces');
  };

  render() {
    const { workspaceDetail, org } = this.props;
    const { modal } = this.state;

    if (!workspaceDetail) {
      return null;
    }

    const modalObj = {
      develop: {
        title: t('API Tokens'),
        class: 'dev-modal'
      },
      delete: {
        title: null,
        class: 'del-modal'
      },
      edit: {
        title: t('Edit Workspace'),
        class: 'edit-modal'
      },
      activity: {
        title: null,
        class: 'workspace-ov-activity'
      }
    };

    const {
      location,
      data_source,
      collection,
      indicator,
      user
    } = workspaceDetail.counter || {
      collection: {
        total: 0
      },
      user: {
        total: 0
      },
      location: {
        total: 0
      },
      indicator: {
        total: 0
      },
      data_source: {
        total: 0
      }
    };

    const links = this.filterLinksForMobile(this.createLinks());

    return (
      <>
        <div className='navbar-wrapper'>
          <div className='navbar-container'>
            <div className='back-home'>
              <span className='link' onClick={this.backList}>
                <i className='iconfont icon-zhuye' />
              </span>
              <i className='iconfont icon-jiantou-copy' />
            </div>
            <div className='navbar-header'>
              <div className='ov-left'>
                <div className='workspace-ov-title'>
                  <span className='name'>{workspaceDetail.name}</span>
                  {workspaceDetail.pro ? (
                    <span className='workspace-ov-badge'>
                      {t('overview.PRO')}
                    </span>
                  ) : (
                    !org && (
                      <span
                        onClick={this.goToUpgrade}
                        className='workspace-ov-badge is-basic link'
                      >
                        {t('Upgrade')}
                      </span>
                    )
                  )}
                </div>
                <div className='workspace-ov-id'>
                  {`${t('overview.Workspace ID')}: ${workspaceDetail.code}`}
                </div>
              </div>
              <div className='ov-right'>
                <div
                  className='ov-summary-item'
                  onClick={() => this.navToTab('locations')}
                >
                  <span>{location.total}</span>
                  <div>{t('Locations')}</div>
                </div>
                <div
                  className='ov-summary-item'
                  onClick={() => this.navToTab('collections')}
                >
                  <span>{collection.total}</span>
                  <div>{t('Collections')}</div>
                </div>
                <div
                  className='ov-summary-item'
                  onClick={() => this.navToTab('indicators')}
                >
                  <span>{indicator.total}</span>
                  <div>{t('Indicators')}</div>
                </div>
                <div
                  className='ov-summary-item'
                  onClick={() => this.navToTab('datasources')}
                >
                  <span>{data_source.total}</span>
                  <div>{t('Data Source')}</div>
                </div>

                <div
                  className='ov-summary-item'
                  onClick={() => this.navToTab('people')}
                >
                  <span>{user.total}</span>
                  <div>{t('People')}</div>
                </div>
                {this.GuardHandle_workspaceOverviewUpdate() && (
                  <Dropdown
                    overlay={this.menu()}
                    trigger={['click']}
                    placement='bottomRight'
                  >
                    <a className='ant-dropdown-link material-icons'>
                      more_vert
                    </a>
                  </Dropdown>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='navbar-wrapper light'>
          <div className='navbar-container block'>
            {links.map((item, index) =>
              item.disable ? (
                <div
                  className='navbar-item is-disabled'
                  key={`navbar-item-${index}`}
                >
                  <item.icon className='navbar-icon' />
                  <p>{item.text}</p>
                </div>
              ) : (
                <a
                  className={classnames('navbar-item', {
                    'is-selected': this.detectSelectd(item.key)
                  })}
                  key={`navbar-item-${index}`}
                  onClick={() => this.push(item.key as Key, item.to)}
                >
                  <item.icon className='navbar-icon' />
                  <p className='hidden-mobile'>{item.text}</p>
                </a>
              )
            )}
          </div>
        </div>
        <Modal
          closable={true}
          maskClosable={true}
          centered={true}
          visible={!!modal}
          bodyStyle={{ padding: '20px 30px' }}
          footer={null}
          title={modal ? modalObj[modal].title : null}
          onCancel={this.closeModal}
          wrapClassName={modal ? modalObj[modal].class : ''}
        >
          {this.renderModal()}
        </Modal>
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  uiState: {
    siteState: { settingsURL, locationID, workspaceID }
  },
  V3: { workspace, locationSelect, org }
}) => ({
  locationID,
  settingsURL,
  workspaceDetail: workspace.data,
  locations: locationSelect.data,
  workspaceID,
  org: org.detail
});

export default Form.create()(withRouter(connect(mapStateToProps)(Navbar)));
