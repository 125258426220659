import { updateSiteState } from 'actions/uiState'
import { Tabs } from 'antd'
import Collections from 'containers/Collections'
import Datasources from 'containers/Datasources'
import Indicators from 'containers/Indicators'
import IconGroup from 'containers/ModalIconGroup'
import People from 'containers/People'
import { P, StateProps } from 'containers/Settings/type'
import { t } from 'i18next'
import * as React from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'
import './index.css'

const TabPane = Tabs.TabPane

class Workspace extends React.PureComponent<P> {
  componentWillUnmount() {
    const {
      location: { pathname, search },
      dispatch
    } = this.props

    dispatch(
      updateSiteState({
        settingsURL: pathname + search
      })
    )
  }

  pathToKey = (): string => {
    const {
      location: { pathname }
    } = this.props
    const keyArray = [
      {
        key: 'collections',
        value: 'collections'
      },
      {
        key: 'indicators',
        value: 'indicators'
      },
      {
        key: 'datasources',
        value: 'datasources'
      },
      {
        key: 'people',
        value: 'people'
      }
    ]

    const pathObj = keyArray.find(v => pathname.includes(v.key)) || {
      value: ''
    }
    return pathObj.value || 'collections'
  }

  tabClick = (key: string, activeKey: string): void => {
    const {
      history,
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    const { href, origin } = window.location
    // 记录上一个 tab 的url
    dispatch(
      updateSiteState({
        [`${activeKey}URL`]: href.replace(origin, '')
      })
    )
    const oldURL = this.props[`${key}URL`]
    history.push(oldURL ? oldURL : `/workspaces/${id}/${key}`)
  }

  render() {
    const activeKey = this.pathToKey()
    return (
      <>
        <div className='overview-tab_line' />
        <IconGroup />
        <Tabs className='overview-tab' onTabClick={key => this.tabClick(key, activeKey)} activeKey={activeKey}>
          <TabPane tab={t('tab.Collections')} key='collections'>
            <Route path='/workspaces/:id/collections' component={Collections} />
          </TabPane>
          <TabPane tab={t('tab.Indicators')} key='indicators'>
            <Route path='/workspaces/:id/indicators' component={Indicators} />
          </TabPane>
          <TabPane tab={t('tab.Data Sources')} key='datasources'>
            <Route path='/workspaces/:id/datasources' component={Datasources} />
          </TabPane>
          <TabPane tab={t('tab.People')} key='people'>
            <Route path='/workspaces/:id/people' component={People} />
          </TabPane>
        </Tabs>
      </>
    )
  }
}

const mapState: MapState<StateProps> = ({
  uiState: {
    siteState: { collectionsURL, indicatorsURL, datasourcesURL, peopleURL }
  }
}) => ({
  indicatorsURL,
  collectionsURL,
  datasourcesURL,
  peopleURL
})

export default withRouter(connect(mapState)(Workspace))
