import * as React from 'react'
import DatasourceDetail from 'containers/Datasources/detail'
import DatasourceTable from 'containers/Datasources/DatasourceTable'
import { connect } from 'react-redux'
import { P, State } from './type'
import { Switch, withRouter, Route } from 'react-router-dom'
import './index.css'

class Datasources extends React.Component<P, State> {
  render() {
    return (
      <div className='collection-content'>
        <Switch>
          <Route path='/workspaces/:id/datasources/:datasource_id' component={DatasourceDetail} />
          <Route path='/workspaces/:id/datasources' component={DatasourceTable} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(connect()(Datasources))
