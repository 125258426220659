import * as React from 'react'
import CollectionDetail from 'containers/CollectionDetail'
import CollectionTable from 'containers/CollectionTable'
import { connect } from 'react-redux'
import { P } from './type'
import { Switch, withRouter, Route } from 'react-router-dom'
import './index.css'

class Collections extends React.PureComponent<P, {}> {
  render() {
    return (
      <div className='collection-content'>
        <Switch>
          <Route exact={true} path='/workspaces/:id/collections' component={CollectionTable} />
          <Route path='/workspaces/:id/collections/:collection_id' component={CollectionDetail} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(connect()(Collections))
