import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import { CLEAN_STORE_STATE } from 'actions/global'
import uiState, { UiState } from 'reducers/uiState'
import global, { GlobalState as globalState } from 'reducers/global'
import V2, { StoreStateV2 } from 'reducers/V2'
import V3, { StoreStateV3 } from 'reducers/V3'

export interface GlobalState {
  global: globalState;
  router: RouterState;
  V2: StoreStateV2;
  V3: StoreStateV3;
  uiState: UiState;
}

const appReducer = (h: History) =>
  combineReducers<GlobalState>({
    uiState,
    global,
    V2,
    V3,
    router: connectRouter(h)
  })

const rootReducer = (h: History) => (state, action) =>
  appReducer(h)(
    action.type === CLEAN_STORE_STATE
      ? {
          V3: { config: state.V3.config }
        }
      : state,
    action
  )

export default rootReducer
