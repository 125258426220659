import { put, call } from 'redux-saga/effects'
import {
  REQUEST_COLLECTIONS_V3,
  getCollection,
  getCollectionDetail,
  REQUEST_COLLECTION_DETAIL_V3,
  getCollectionForSelector,
  REQUEST_COLLECTION_FOR_SELECTOR_V3
} from 'actions/V3/collections'
import { watchRequestNoCancel, watchRequest } from 'sagas/helper'
import { apiV3 } from 'utils/api'

type Args = {
  size?: number,
  page?: number,
  name?: string,
  workspace_id: number,
  fields?: string
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/collections', 'get', args)

  yield put(getCollection(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_COLLECTIONS_V3, request)

function* requestDetail(args: { collection_id: number }) {
  const { data } = yield call(apiV3, `/collections/${args.collection_id}`)

  yield put(getCollectionDetail(data))
  return data
}

export const collectionDetailWatcher = watchRequest(REQUEST_COLLECTION_DETAIL_V3, requestDetail)

function* requestListForSelector(args: Args) {
  args.fields = 'id,name'
  const { data } = yield call(apiV3, '/collections/select', 'get', args)

  yield put(getCollectionForSelector(data))
  return data
}

export const collectionForSelectorWatcher = watchRequest(REQUEST_COLLECTION_FOR_SELECTOR_V3, requestListForSelector)
