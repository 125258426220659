import EmptyLocationImg from '-!svg-react-loader!images/svg/emptyLocation.svg'
import { changeModal, ModalChangeAction } from 'actions/uiState'
import { Button } from 'antd'
import LocationList from 'containers/LocationList'
import NavLinkContaier from 'containers/NavLinkContainer'
import { t } from 'i18next'
import * as React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link } from 'react-router-dom'

class AllLocation extends React.PureComponent<DispatchProp<ModalChangeAction>, {}> {
  renderNoLocation = () => {
    const { dispatch } = this.props
    return (
      <div className='home-table-wrap'>
        <div className='home-ws-empty-block'>
          <EmptyLocationImg />
          <div className='home-ws-empty-title'>{t('No Location Found')}</div>
          <div className='home-ws-empty-tips'>
            <span dangerouslySetInnerHTML={{ __html: t('all.here you will') }} />
            <Link className='accent_text' to='/public'>
              {t('Click here')}
            </Link>
            {t('all.to visit other locations')}
          </div>
          <Button type='primary' onClick={() => dispatch(changeModal('AddLocation'))}>
            {t('Create Location')}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    return (
      <NavLinkContaier active='locations'>
        <React.Fragment>
          <div className='home-tab-header'>
            <div className='home-tab-label'>{t('public.All Locations')}</div>
          </div>
          <LocationList noData={this.renderNoLocation()} type='all' />
        </React.Fragment>
      </NavLinkContaier>
    )
  }
}

export default connect()(AllLocation)
