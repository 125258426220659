import { Action, ActionCreator } from 'redux'
export const REQUEST_SEARCH_V3 = 'REQUEST_SEARCH_V3'
export const REQUEST_SEARCH_DETAIL_V3 = 'REQUEST_SEARCH_DETAIL_V3'
export const GET_SEARCH_V3 = 'GET_SEARCH_V3'
export const GET_SEARCH_DETAIL_V3 = 'GET_SEARCH_DETAIL_V3'

export type SearchResult = {
  collections: { id: number, name: string, workspace_id: number }[],
  indicators: {
    channel: string,
    id: number,
    identifier: string,
    name: string,
    workspace_id: number
  }[],
  locations: {
    id: number,
    name: string,
    workspace_id: number
  }[],
  data_sources: {
    id: number,
    identifier: string,
    name: string,
    workspace_id: number
  }[],
  workspaces: {
    id: number,
    code: string,
    name: string
  }[]
}

export interface RequestV3SearchAction extends Action<typeof REQUEST_SEARCH_V3> {
  args: any;
}

export interface RequestV3SearchDetailAction extends Action<typeof REQUEST_SEARCH_DETAIL_V3> {
  args: any;
}
interface GetV3Search extends Action<typeof GET_SEARCH_V3> {
  data: SearchResult;
}

interface GetV3SearchDetail extends Action<typeof GET_SEARCH_DETAIL_V3> {
  data: SearchResult;
}

export type SearchV3Action = GetV3Search | GetV3SearchDetail

export const requestV3Seacrh = (args: {
  q: string,
  size?: number,
  show?: boolean,
  workspace_size?: number,
  location_size?: number,
  indicator_size?: number,
  collection_size?: number,
  monitor_size?: number
}): RequestV3SearchAction => ({
  args,
  type: REQUEST_SEARCH_V3
})

export const requestV3SeacrhDetail = (args: {
  q: string,
  size?: number,
  show?: boolean,
  workspace_size?: number,
  location_size?: number,
  indicator_size?: number,
  collection_size?: number,
  monitor_size?: number
}): RequestV3SearchDetailAction => ({
  args,
  type: REQUEST_SEARCH_DETAIL_V3
})

export const getV3Search: ActionCreator<GetV3Search> = data => ({
  data,
  type: GET_SEARCH_V3
})

export const getV3SearchDetail: ActionCreator<GetV3SearchDetail> = data => ({
  data,
  type: GET_SEARCH_DETAIL_V3
})
