import * as React from 'react'
import { Input, Icon } from 'antd'
import { InputProps } from 'antd/lib/input'
import classNames from 'classnames'
import './Input.css'

interface Props extends InputProps {
  type: 'success' | 'error' | 'normal' | 'none';
}

const CustomInput: React.SFC<Props> = React.memo(({ className, type, disabled, ...other }) => (
  <>
    <Input {...other} disabled={disabled} className={classNames(className, 'custom-input', `custom-input--${type}`)} />
    {disabled && type !== 'error' && type !== 'none' && (
      <i className={classNames('material-icons', 'custom-input_icon', `custom-input_icon--${type}`)}>
        {type === 'success' && 'group_work'}
        {type === 'normal' && 'close'}
      </i>
    )}
    {disabled && type === 'error' && <Icon className='custom-input_icon custom-input_icon--error' type='question' />}
  </>
))

export default CustomInput
