import {
  AnnouncementItem,
  CLEAN_ANNOUNCEMENT_LATEST,
  CleanAnnouncementAction,
  requestAnnouncement,
  RequestAnnouncementLatestAction
} from 'actions/V2/announcement'
import { requestCertifications, RequestCertificationsAction } from 'actions/V2/certifications'
import { requestUserShip, RequestUserShipAction, WorkspaceItem } from 'actions/V2/userShip'
import { UserInfo } from 'actions/V2/userSign'
import { Config, requestConfig, RequestConfigAction } from 'actions/V3/config'
import { OrgObj, requestOrgDetail, RequestOrgDetailAction } from 'actions/V3/org'
import { requestUserConfigV3, RequestUserConfigV3Action } from 'actions/V3/userConfig'
import { notification } from 'antd'
import WelcomeModal from 'components/WelcomeModal'
import React, { useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { promiseDispatch } from 'utils'
import api, { apiV3 } from 'utils/api'
import { isLogin } from 'utils/auth'

type Props = {
  setReady: () => void
}

type StateProps = {
  announcement?: AnnouncementItem,
  userShip: WorkspaceItem[],
  config?: Config,
  showNoteBar?: boolean,
  org?: OrgObj,
  user?: UserInfo
}
type P = Props &
  StateProps &
  DispatchProp<
    | RequestConfigAction
    | RequestUserShipAction
    | RequestAnnouncementLatestAction
    | CleanAnnouncementAction
    | RequestUserConfigV3Action
    | RequestOrgDetailAction
    | RequestCertificationsAction
  >

const ConfigControl: React.SFC<P> = React.memo(props => {
  const { dispatch, userShip, announcement, setReady, user } = props
  const [showWelcome, setShowWelcome] = React.useState(false)
  const fetchWhiteLabelConfigData = async () => {
    await promiseDispatch({
      dispatch,
      actionCreator: requestConfig,
      payload: {
        show: false
      }
    })
  }

  const fetchUserShipData = async () => {
    if (!userShip.length) {
      await promiseDispatch({
        dispatch,
        actionCreator: requestUserShip,
        payload: {
          show: false
        }
      })
    }
  }

  const fetchCertificationData = async () => {
    await promiseDispatch({
      dispatch,
      actionCreator: requestCertifications,
      payload: {
        show: false
      }
    })
  }

  const fetchAnnouncementData = async () => {
    const { search } = window.location
    const s = search.toUpperCase()
    const data = await promiseDispatch<AnnouncementItem>({
      dispatch,
      actionCreator: requestAnnouncement,
      payload: s.includes('FROM=V2') ? { from: 'v2', show: false } : { show: false }
    })

    if (data && !announcement) {
      notification.info({
        message: data.title,
        onClose: async () => {
          await api('/announcements', 'post', { id: data.id })
          dispatch({ type: CLEAN_ANNOUNCEMENT_LATEST })
        },
        description: <div className='changelog-content' dangerouslySetInnerHTML={{ __html: data.content }} />,
        duration: null
      })
    }
  }

  const fetchWelcome = async () => {
    let show = true
    const userConfig: any = await promiseDispatch({
      dispatch,
      actionCreator: requestUserConfigV3,
      payload: {
        show: false
      }
    })
    if (userConfig) {
      show = !userConfig.tutorial_guide_clicked
      if (userConfig.orgs.length) {
        dispatch(requestOrgDetail({ id: userConfig.orgs[0].id, show: false }))
      }
    }
    setShowWelcome(show)
  }

  const hideWelcome = () => {
    setShowWelcome(false)
    apiV3('/users/configs', 'post', {
      user: {
        tutorial_guide_clicked: true
      }
    }).then()
  }

  useEffect(() => {
    const { search } = window.location
    const s = search.toUpperCase()
    window.FROM_V2 = s.includes('KIOSK=TRUE')
    const toWaitFor: Promise<any>[] = [fetchWhiteLabelConfigData(), fetchCertificationData()]
    if (isLogin()) {
      toWaitFor.push(fetchAnnouncementData(), fetchWelcome(), fetchUserShipData())
    }

    Promise.all(toWaitFor).then(() => {
      setReady()
    })
  }, [user])

  return showWelcome ? <WelcomeModal onClose={hideWelcome} /> : null
})

const mapStateToProps: MapState<StateProps> = ({
  V2: {
    userShip,
    announcement,
    userSign: { data }
  }
}) => ({
  announcement: announcement.announcement,
  userShip: userShip.data,
  user: data
})

export default connect(mapStateToProps)(ConfigControl)
