import Cloud from '-!svg-react-loader!images/svg/cloud.svg';
import GraphIcon from '-!svg-react-loader!images/svg/Graph.svg';
import TableIcon from '-!svg-react-loader!images/svg/Table.svg';
import { updateSiteState } from 'actions/uiState';
import { requestDictionary } from 'actions/V2/dictionary';
import { requestOutdoor } from 'actions/V2/location';
import {
  LocationBase, requestV3Location,
  requestV3LocationEdit,
  requestV3LocationUpdate, REQUEST_LOCATION_BASE_V3
} from 'actions/V3/location';
import { REQUEST_LOCATION_LIST_V3 } from 'actions/V3/locationList';
import { REQUEST_LOCATION_SELECT_V3 } from 'actions/V3/locationSelect';
import { refreshPermission } from 'actions/V3/permission';
import {
  Breadcrumb,
  // Col,
  Dropdown,
  Input,
  Menu,
  message,
  Modal,
  Popconfirm,
  Progress,
  // Row,
  Skeleton, Tooltip, Upload
} from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import classNames from 'classnames';
import Button from 'components/Custom/Button';
import CustomSelect, { Option } from 'components/Custom/Select';
import { P, State, StateProps } from 'containers/LocationDetail/type';
import { t } from 'i18next';
import defaultBg from 'images/StockSnap_95PRN3JH49.png';
import { entries, groupBy, maxBy } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import cache from 'sagas/cache';
import { promiseDispatch, uploadImage } from 'utils';
import { apiV3 } from 'utils/api';
import { LEVEL, LEVEL_DICT, shortName } from 'utils/constant';
import { Guard } from 'utils/guard';
import { formateTimeZone } from 'utils/timeZone';
import AddCollection from './AddCollection';
import EditLocation from './EditLocation';
import './index.css';
import Integration from './Integration';
import ReadingLimit from './ReadingLimit';

interface ApiResponse {
  data: any;
}
@Guard
class Detail extends React.PureComponent<P, State> {
  state: State = {
    edit: false,
    welcome: false,
    percent: 100,
    deleteInput: '',
    modal: '',
    followedHover: false,
    indicatorsForOrder: undefined
  };

  listEnd: any;

  translation = {
    airFilter: t('filter.air'),
    electricityFilter: t('filter.electricity'),
    ...LEVEL(),
    ...LEVEL_DICT()
  };


  componentDidMount() {
    const { dispatch } = this.props;
    this.checkArcStatus();
    dispatch(requestDictionary({ name: 'time_zones' }));
    dispatch(requestDictionary({ name: 'cities' }));
    this.fetchLocationEdit();
    this.fetchLocation(true);
  }

  UNSAFE_componentWillReceiveProps(next: P) {
    const { location_id } = next.match.params;
    const { location_id: locationID } = this.props.match.params;
    if (location_id !== locationID) {
      this.fetchLocationEdit(next);
      this.fetchLocation(true, next);
    }
  }

  GuardHandle_locationSettingUpdate = (rule?: GuardRule) => rule && rule.enable;
  GuardHandle_collectionUpdate = (rule?: GuardRule) => rule && rule.enable;
  GuardHandle_collectionDelete = (rule?: GuardRule) => rule && rule.enable;
  GuardHandle_collectionDropdown = (rule?: GuardRule) => rule && rule.enable;
  GuardHandle_locationBaseUpdate = (rule?: GuardRule) => rule && rule.enable;
  GuardHandle_collectionAverage = (rule?: GuardRule) => rule && rule.enable;
  Guard_collectionCreate = () => this.setState({ modal: 'add' });
  GuardHandle_collectionCreate = (rule?: GuardRule) => rule && rule.enable;
  Guard_integration = () => this.setState({ modal: 'integration' });
  GuardHandle_locationOutdoor = (rule?: GuardRule) => rule && rule.enable;
  GuardHandle_locationAverage = (rule?: GuardRule, m?: string) => {
    const locationBase = this.state.locationBase!;

    const reading = locationBase.average
      ? entries(groupBy(locationBase.average.readings, 'type'))
      : [];

    const disabled = rule && !rule.enable;

    const editAble = this.GuardHandle_collectionUpdate();
    return (
      locationBase.average && (
        <div className='location_detail-collection'>
          <div className='location_detail-collection-name'>
            <Tooltip
              placement='left'
              title={
                !disabled && this.translation[locationBase.average.performance]
              }
            >
              <span
                className={classNames('location_detail-collection-status', {
                  [`is-${locationBase.average.performance}`]:
                    !disabled &&
                    !!locationBase.average.performance &&
                    !locationBase.average.stale
                })}
              />
            </Tooltip>
            <div>
              <span className='location_detail-name'>
                {t('Location Average')}
              </span>
            </div>
            <p className='small_text'>
              {locationBase.average.stale && (
                <span className='location_detail-stale'>{t('STALE')}</span>
              )}
              {disabled ? (
                <span>{m}</span>
              ) : locationBase.average.last_reading_time ? (
                <span>
                  {t('locationOverview.count time', {
                    date: moment(
                      locationBase.average.last_reading_time
                    ).fromNow()
                  })}
                </span>
              ) : (
                <span>{t('No Data Available')}</span>
              )}
            </p>
            {!disabled && editAble && (
              <Dropdown
                overlay={this.dropdownMenuForAverage(
                  locationBase.id,
                  locationBase.average
                )}
                placement='bottomRight'
                className='hidden-mobile'
              >
                <a className='material-icons location_detail-average-more'>
                  more_vert
                </a>
              </Dropdown>
            )}
          </div>

          {!disabled &&
            reading.map(([type, values], _index) => (
              <div
                className='location_detail-collection-reading'
                key={`collection-reading-${_index}`}
              >
                <p className='location_detail-collection-type small_text'>
                  {this.translation[type]}
                </p>
                {this.renderAverage(
                  values,
                  'like_monitor',
                  !!(locationBase.average && locationBase.average.stale)
                )}
              </div>
            ))}
        </div>
      )
    );
  };

  checkArcStatus = async () => {
    const {
      location: { search },
      match: {
        params: { id, location_id }
      }
    } = this.props;
    const { authorize_code } = qs.parse(search.slice(1));

    if (!authorize_code) {
      return;
    }

    try {
      await apiV3('/arc/verify_code', 'post', {
        workspace_id: id,
        location_id,
        authorize_code
      });

      this.setState({
        modal: 'integration',
        integration: {
          status: 'arc',
          arcStatus: 'authed'
        }
      });
    } catch (error) {
      this.closeArc();
    }
  };

  fetchLocation = async (init = false, props = this.props) => {
    const {
      dispatch,
      location: { hash },
      match: {
        params: { location_id }
      }
    } = props;

    const locationBase = await promiseDispatch<LocationBase>({
      dispatch,
      actionCreator: requestV3Location,
      payload: { location_id: +location_id, show: init }
    });

    dispatch(requestOutdoor({ city_id: locationBase.city_id, show: init }));
    const state = {
      locationBase,
      edit: false
    };

    if (init) {
      (state as any).welcome = !locationBase.collections.length;
    }
    this.setState(state);
    // 定位 collection
    const elm = document.getElementById(hash.slice(1));
    if (elm) {
      elm.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    }
  };

  fetchLocationEdit = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { location_id }
      }
    } = props;

    dispatch(
      requestV3LocationEdit({
        location_id: +location_id
      })
    );
  };

  dropdownMenuForAverage = (locationId, average) => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return (
      <Menu>
        <Menu.Item>
          <Link
            className='link-icon'
            to={`/workspaces/${id}/data?cate=location&location_id=${locationId}`}
          >
            <i className='iconfont icon-table' />
            <span className='small_text'>{t('navbar.Table')}</span>
          </Link>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            this.setState({
              indicatorOrderTy: {
                name: t('indicator.average'),
                id: 0
              },
              indicatorsForOrder: average.readings
            });
          }}
        >
          <div className='link-icon'>
            <i className='iconfont icon-indicator' />
            <span className='small_text'>{t('indicator.sortMenu')}</span>
          </div>
        </Menu.Item>
      </Menu>
    );
  };

  dropdownMenu = (obj, index) => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { locationBase } = this.state;
    const collections = locationBase!.collections;

    return (
      <Menu>
        {this.GuardHandle_collectionDropdown() && (
          <Menu.Item>
            <Link
              className='link-icon'
              to={`/workspaces/${id}/data?cate=collection&collection_id=${obj.id}`}
            >
              <i className='iconfont icon-table' />
              <span className='small_text'>{t('navbar.Table')}</span>
            </Link>
          </Menu.Item>
        )}
        {this.GuardHandle_collectionDelete() && (
          <Menu.Item>
            <Popconfirm
              title={t(
                'locationOverview.Are you sure you want to remove this Collection'
              )}
              onConfirm={() => this.unbindCollection(obj.id)}
              okType='danger'
            >
              <a>
                <i className='material-icons line_icon'>delete</i>
                <span className='small_text'>
                  {t('locationOverview.Remove Collection')}
                </span>
              </a>
            </Popconfirm>
          </Menu.Item>
        )}
        <Menu.Item
          className='link-icon'
          onClick={() => {
            this.setState({
              indicatorOrderTy: {
                id: obj.id,
                name: obj.name
              },
              indicatorsForOrder: obj.readings
            });
          }}
        >
          <i className='iconfont icon-indicator' />
          <span className='small_text'>{t('indicator.sortMenu')}</span>
        </Menu.Item>
        {index > 0 && (
          <Menu.Item
            className='link-icon'
            onClick={() => {
              const itemClone = Object.assign({}, obj);
              collections[index] = collections[index - 1];
              collections[index - 1] = itemClone;
              this.updateCollectionPositions(collections);
            }}
          >
            <i className='iconfont icon-rising'></i>
            <span className='small_text'>{t('locationOverview.moveUp')}</span>
          </Menu.Item>
        )}
        {index < collections.length - 1 && (
          <Menu.Item
            className='link-icon'
            onClick={() => {
              const itemClone = Object.assign({}, obj);
              collections[index] = collections[index + 1];
              collections[index + 1] = itemClone;
              this.updateCollectionPositions(collections);
            }}
          >
            <i className='iconfont icon-falling'></i>
            <span className='small_text'>{t('locationOverview.moveDown')}</span>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  updatePositions = async () => {
    const { indicatorsForOrder, indicatorOrderTy } = this.state;
    if (!indicatorsForOrder || !indicatorOrderTy) {
      return;
    }
    const {
      dispatch,
      match: {
        params: { location_id }
      }
    } = this.props;
    if (indicatorOrderTy.id === 0) {
      await promiseDispatch<{}>({
        dispatch,
        actionCreator: requestV3LocationUpdate,
        payload: {
          show: false,
          location_id: +location_id,
          form: {
            display_config: {
              positions: indicatorsForOrder.map((ind) => ind.data_channel)
            }
          }
        }
      });
    } else {
      await apiV3(`/collections/${indicatorOrderTy.id}`, 'patch', {
        collection: {
          display_config: {
            positions: indicatorsForOrder.map((ind) => ind.data_channel)
          }
        }
      });
    }

    this.setState({
      indicatorsForOrder: undefined,
      indicatorOrderTy: undefined
    });
  };

  updateCollectionPositions = (collections) => {
    const {
      dispatch,
      match: {
        params: { location_id }
      }
    } = this.props;
    this.setState({
      locationBase: Object.assign({}, this.state.locationBase, {
        collections
      })
    });
    dispatch(
      requestV3LocationUpdate({
        show: false,
        location_id: +location_id,
        form: {
          display_config: {
            collection_positions: collections.map((c) => c.id)
          }
        }
      })
    );
  };

  deleteLocation = async () => {
    const {
      history,
      dispatch,
      match: {
        params: { location_id }
      }
    } = this.props;
    cache.cleanCache(REQUEST_LOCATION_SELECT_V3);
    cache.cleanCache(REQUEST_LOCATION_LIST_V3);
    await apiV3(`/locations/${location_id}`, 'delete');

    dispatch(
      updateSiteState({
        locationID: ''
      })
    );

    dispatch(
      refreshPermission({
        show: false
      })
    );
    this.setState({ modal: '' });
    history.goBack();
  };

  unbindCollection = async (id) => {
    const { locationBase } = this.state;
    const collections = locationBase!.collections.filter((v) => v.id !== id);
    await apiV3(`/locations/${locationBase!.id}/collections/${id}`, 'delete');
    const newLocation = { ...locationBase, collections };
    this.setState({ modal: '', locationBase: newLocation as any });
  };

  uploadBackground = ({ file }: any) => {
    uploadImage(file).then((observable) => {
      if (observable) {
        observable.subscribe(
          (next) => {
            /* next handle */
            this.setState({ percent: Math.ceil(next.total.percent) });
          },
          (err) => {
            /* error handle */
            message.error(t('upload Error', { message: err.message }));
            this.setState({ percent: 100 });
          },
          async ({ key: path }) => {
            /* complete handle */
            const { locationBase } = this.state;
            const {
              match: {
                params: { location_id }
              },
              dispatch
            } = this.props;

            const clone: LocationBase = {
              ...locationBase!,
              background_image: `https://assets-1.qlear.io/${path}`
            };
            await promiseDispatch<LocationBase>({
              dispatch,
              actionCreator: requestV3LocationUpdate,
              payload: {
                location_id: +location_id,
                form: { background_image: path }
              }
            });

            this.setState({ locationBase: clone });
          }
        );
      }
    });
  };

  removeBackground = async () => {
    const { locationBase } = this.state;
    const {
      match: {
        params: { location_id }
      }
    } = this.props;

    const clone: LocationBase = {
      ...locationBase!,
      background_image: undefined
    };

    await apiV3(`/locations/${location_id}`, 'patch', {
      location: { background_image: null }
    });

    this.setState({ locationBase: clone, modal: '' });
  };

  calcPercent(channel: string, value: number, level: string) {
    const indicator = this.translation[channel];
    if (!indicator || !value) {
      return 0;
    }
    const max = indicator[0][0];
    return (value / +max) * 100;
  }

  indicatorMenu = (id, channel, indicatorId) => {
    const {
      match: {
        params: { id: workspaceID, location_id }
      }
    } = this.props;
    const list = [
      {
        icon: <GraphIcon />,
        text: t('navbar.graph'),
        path: `/workspaces/${workspaceID}/graph/${location_id}?collection=${id}&channel=${channel}`
      },
      {
        icon: <TableIcon />,
        text: t('navbar.Table'),
        path: `/workspaces/${workspaceID}/data?cate=indicator&indicator_id=${indicatorId}`
      },
      {
        icon: <i className='material-icons md-18'>notifications</i>,
        text: t('navbar.Notification'),
        path: `/notification?collection=${id}&channel=${channel}`
      }
    ];
    return (
      <Menu
        selectable={false}
        className='location_detail-collection-indicator_menu'
      >
        {list.map((item, index) => (
          <Menu.Item key={`collection-menu-${id}-${index}`}>
            <Link
              to={item.path}
              className='location_detail-collection-indicator_menu_item'
            >
              <div>{item.icon}</div>
              <span className='small_text grey_text'>{item.text}</span>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  renderCollection = () => {
    const { locationBase } = this.state;
    const editAble = this.GuardHandle_collectionUpdate();
    const canViewDropdown = this.GuardHandle_collectionDropdown();
    const showCollectionAverage = this.GuardHandle_collectionAverage();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    return locationBase!.collections.map((item, index) => {
      const reading = entries(groupBy(item.readings, 'type'));
      return (
        <div
          className='location_detail-collection'
          key={`collection-${index}`}
          id={`collection-${item.id}`}
        >
          <div className='location_detail-collection-name'>
            <Tooltip
              placement='left'
              title={
                item.performance
                  ? this.translation[item.performance]
                  : this.translation[item.status]
              }
            >
              <span
                className={classNames('location_detail-collection-status', {
                  'is-online': !item.performance && item.status === 'online',
                  [`is-${item.performance}`]: !!item.performance && !item.stale
                })}
              />
            </Tooltip>
            <div>
              <Link
                to={`/workspaces/${id}/collections/${item.id}`}
                className='location_detail-name'
              >
                {item.name}
              </Link>
            </div>

            {(editAble || canViewDropdown) && (
              <Dropdown
                overlay={this.dropdownMenu(item, index)}
                placement='bottomRight'
                className='hidden-mobile'
              >
                <a className='material-icons location_detail-collection-more-absolute'>
                  more_vert
                </a>
              </Dropdown>
            )}

            <p className='small_text'>
              {item.stale && (
                <span className='location_detail-stale'>{t('STALE')}</span>
              )}
              {showCollectionAverage &&
                (item.last_reading_time ? (
                  <span>
                    {t('locationOverview.count time', {
                      date: moment(item.last_reading_time).fromNow()
                    })}
                  </span>
                ) : (
                  <span>{t('No Data Available')}</span>
                ))}
            </p>
          </div>

          {reading.map(([type, values], _index) => (
            <div
              className='location_detail-collection-reading'
              key={`collection-${index}-reading-${_index}`}
            >
              <p className='location_detail-collection-type small_text'>
                {this.translation[type]}
              </p>
              <ul className='location_detail-collection-list flex'>
                {values.map((indicator, i) =>
                  canViewDropdown ? (
                    <Dropdown
                      overlay={this.indicatorMenu(
                        item.id,
                        indicator.data_channel,
                        indicator.indicator_id
                      )}
                      key={`collection-${index}-reading-${_index}-indicator-${i}`}
                    >
                      <li>
                        <div className='location_detail-collection-line'>
                          <Tooltip
                            title={
                              indicator.level
                                ? this.translation[indicator.level]
                                : indicator.value !== null
                                  ? t('Notification.online')
                                  : t('Notification.offline')
                            }
                          >
                            <span
                              className={classNames(
                                'location_detail-collection-indicator_status',
                                {
                                  [`is-${indicator.level}`]:
                                    !!indicator.level && !item.stale,
                                  'is-online':
                                    !indicator.level &&
                                    indicator.value !== null &&
                                    !item.stale,
                                  'is-offline': indicator.value === null
                                }
                              )}
                            />
                          </Tooltip>
                          <span className='small_text grey_text'>
                            {indicator.name}
                          </span>
                        </div>
                        <div className='location_detail-collection-line'>
                          <span className='location_detail-collection-value'>
                            {indicator.value === null ? 'N/A' : indicator.value}
                          </span>
                          <span className='small_text indicator-unit'>
                            {indicator.value === null ? ' ' : indicator.unit}
                          </span>
                        </div>
                      </li>
                    </Dropdown>
                  ) : (
                    <li
                      key={`collection-${index}-reading-${_index}-indicator-${i}`}
                    >
                      <div className='location_detail-collection-line'>
                        <span
                          className={classNames(
                            'location_detail-collection-indicator_status',
                            {
                              [`is-${indicator.level}`]:
                                !!indicator.level && !item.stale,
                              'is-online':
                                !indicator.level &&
                                indicator.value !== null &&
                                !item.stale,
                              'is-offline': indicator.value === null
                            }
                          )}
                        />
                        <span className='small_text grey_text'>
                          {indicator.name}
                        </span>
                      </div>
                      <div className='location_detail-collection-line'>
                        <span className='location_detail-collection-value'>
                          {indicator.value === null ? 'N/A' : indicator.value}
                        </span>
                        <span className='small_text'>
                          {indicator.value === null ? ' ' : indicator.unit}
                        </span>
                      </div>
                    </li>
                  )
                )}
              </ul>
            </div>
          ))}
        </div>
      );
    });
  };

  openUploader = () => {
    const input = document.querySelector(
      '.location_detail-left-upload .ant-upload-btn input'
    );
    if (input) {
      (input as any).click();
    }
  };

  renderFavBtn = () => {
    const { locationBase, followedHover } = this.state;
    if (!locationBase) {
      return '';
    }
    return (
      <Button
        className='location_detail-favorite-btn'
        customType={
          locationBase.followed ? (followedHover ? 'danger' : 'dark') : 'light'
        }
        size='small'
        onMouseEnter={() =>
          this.setState({
            followedHover: true
          })
        }
        onMouseLeave={() =>
          this.setState({
            followedHover: false
          })
        }
        onClick={() => this.followAction(!locationBase.followed)}
      >
        {!locationBase.followed
          ? t('locationOverview.follow it')
          : followedHover
            ? t('locationOverview.unfollow it')
            : t('locationOverview.following')}
      </Button>
    );
  };

  renderLocationBase = () => {
    const { edit, percent } = this.state;
    const { cities, outdoor, locationBase } = this.props;
    if (!locationBase) {
      return <Skeleton active={true} />;
    }

    const editAble = this.GuardHandle_locationBaseUpdate();
    const showOutdoor = this.GuardHandle_locationOutdoor();
    const Dragger: React.SFC<DraggerProps> = Upload.Dragger;

    return (
      <div className='location_detail-left'>
        {editAble ? (
          <div className='location_detail-left-upload'>
            <Dragger
              customRequest={this.uploadBackground}
              accept='image/jpeg,image/png,image/jpg'
              showUploadList={false}
              style={{
                backgroundImage: `url(${locationBase.background_image
                  ? locationBase.background_image
                  : defaultBg
                  })`
              }}
            />

            <div className='location_detail-left-upload_layer'>
              <p className='ant-upload-text' onClick={this.openUploader}>
                <Cloud className='register-add_location-cloud is-detail' />
                {t('locationOverview.Add your location image')}
              </p>
              {locationBase.background_image && (
                <p
                  className='ant-upload-text danger_text'
                  onClick={this.confirmRemoveBackground}
                >
                  <i className='material-icons register-add_location-remove'>
                    remove_circle_outline
                  </i>
                  {t('locationOverview.remove location image')}
                </p>
              )}
            </div>
            {percent !== 100 && (
              <div className='location_detail-left-progress'>
                <Progress percent={percent} size='small' status='active' />
              </div>
            )}
          </div>
        ) : (
          <div className='location_detail-left-upload'>
            <div
              className='ant-upload ant-upload-drag'
              style={{
                backgroundImage: `url(${locationBase.background_image
                  ? locationBase.background_image
                  : defaultBg
                  })`
              }}
            />
          </div>
        )}

        {/* location name */}
        <div className='location_detail-left-row'>
          <div className='location_detail-left-label'>
            <span className='grey_text small_text'>
              {t('locationOverview.Location Name')}
            </span>
          </div>
          <div className='location_detail-left-content'>
            <i className='material-icons'>bookmark</i>
            <div className='location_detail-left-text'>{locationBase.name}</div>
          </div>
        </div>
        {/* location address */}
        <div className='location_detail-left-row'>
          <div className='location_detail-left-label'>
            <span className='grey_text small_text'>
              {t('locationOverview.Location Address')}
            </span>
          </div>
          <div className='location_detail-left-content'>
            <i className='material-icons'>location_on</i>
            <div className='location_detail-left-text'>
              {locationBase.address}
            </div>
          </div>
        </div>
        {/* location city */}
        <div className='location_detail-left-row'>
          <div className='location_detail-left-label'>
            <span className='grey_text small_text'>{t('City')}</span>
          </div>
          <div className='location_detail-left-content'>
            <i className='material-icons'>business</i>
            <CustomSelect
              dropdownTextCenter={false}
              size='small'
              style={{ minWidth: 100 }}
              placeholder={t('City')}
              value={locationBase.city_id || ''}
              underline={edit}
              showArrow={edit}
              disabled={!edit}
              showSearch={true}
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option.props.children as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              <Option value=''>None</Option>
              {cities.map((v, index) => (
                <Option key={`city-${index}`} value={v.id}>
                  {v.name}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </div>
        {/* location timezone */}
        <div className='location_detail-left-row'>
          <div className='location_detail-left-label'>
            <span className='grey_text small_text'>
              {t('locationOverview.Time Zone')}
            </span>
          </div>
          <div className='location_detail-left-content'>
            <i className='material-icons'>language</i>
            <CustomSelect
              dropdownTextCenter={false}
              size='small'
              style={{ width: '100%' }}
              showSearch={true}
              placeholder={t('locationOverview.Time Zone')}
              value={locationBase.time_zone}
              underline={edit}
              showArrow={edit}
              disabled={!edit}
              optionFilterProp='children'
              filterOption={(input, option) =>
                (option.props.children as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            >
              {formateTimeZone(this.props.timeZone).map((value, index) => (
                <Option
                  key={`time-zone-${index}`}
                  disabled={value.disabled}
                  value={value.value}
                >
                  {value.label}
                </Option>
              ))}
            </CustomSelect>
          </div>
        </div>

        <div className='location_detail-left-row'>
          <div className='location_detail-left-label'>
            <span className='grey_text small_text'>
              {t('locationOverview.Location Type')}
            </span>
          </div>
          <div className='location_detail-left-content'>
            <i className='material-icons'>location_city</i>
            <CustomSelect
              dropdownTextCenter={true}
              size='small'
              placeholder={t('locationOverview.Location Type')}
              value={locationBase.category}
              underline={edit}
              showArrow={edit}
              disabled={!edit}
            >
              <Option value='indoor'>{t('filter.indoor')}</Option>
              <Option value='outdoor'>{t('filter.outdoor')}</Option>
            </CustomSelect>
          </div>
        </div>
        {showOutdoor && locationBase.category === 'indoor' && (
          <div className='location_detail-left-row'>
            <div className='location_detail-left-label'>
              <span className='grey_text small_text'>
                {t('locationOverview.Outdoor Counterpart')}
              </span>
            </div>
            <div className='location_detail-left-content'>
              <i className='material-icons'>cloud</i>

              <CustomSelect
                style={{ minWidth: 100 }}
                size='small'
                value={(locationBase.outdoor && locationBase.outdoor.id) || ''}
                underline={edit}
                showArrow={edit}
                disabled={!edit}
              >
                <Option value='' key='outdoor-none'>
                  None
                </Option>
                {outdoor &&
                  entries(outdoor).map((v, index) => (
                    <Option key={`outdoor-${index}`} value={+v[0]}>
                      {v[1]}
                    </Option>
                  ))}
              </CustomSelect>
            </div>
          </div>
        )}
      </div>
    );
  };

  confirmRemoveBackground = () => {
    Modal.confirm({
      title: t('locationOverview.remove location image'),
      okType: 'danger',
      content: t(
        'locationOverview.Are you sure you want to remove your Location Image'
      ),
      onOk: this.removeBackground
    });
  };

  closeArc = () => {
    const {
      history,
      location: { pathname }
    } = this.props;
    history.replace(pathname);
    this.setState({ modal: '', integration: undefined });
  };

  followAction = async (status: boolean) => {

    const { locationBase } = this.state;
    cache.cleanCache(REQUEST_LOCATION_BASE_V3);
    if (status) {
      // Follow
      const response: ApiResponse = await apiV3('/followings', 'post', {
        location_id: locationBase!.id
      });
      if (response) {
        message.success(t('locationOverview.follow success'));
        this.setState({ locationBase: { ...locationBase!, followed: true, following_id: response.data.following_id } });
      }

    } else {
      // Unfollow
      await apiV3(`/followings/${locationBase!.following_id}`, 'delete');

      message.success(t('locationOverview.unfollow success'));
      this.setState({ locationBase: { ...locationBase!, followed: false } });
    }
  };

  renderModalElement = () => {
    const { modal, locationBase, deleteInput, integration } = this.state;
    const {
      match: {
        params: { id, location_id }
      },
      locationEdit,
      dispatch
    } = this.props;
    const editAble = this.GuardHandle_locationSettingUpdate();
    const showOutdoor = this.GuardHandle_locationOutdoor();
    switch (modal) {
      case 'integration':
        return (
          locationEdit &&
          locationBase && (
            <Integration
              {...((integration || {}) as any)}
              collections={locationBase.collections}
              locationEdit={locationEdit}
              locationID={+location_id}
              workspaceID={+id}
              refresh={this.fetchLocationEdit}
              close={this.closeArc}
            />
          )
        );
      case 'add':
        return (
          <AddCollection
            name={locationBase!.name}
            filterCollectionIds={locationBase!.collections.map((e) => +e.id)}
            locationID={+location_id}
            workspaceID={+id}
            createable={!!this.GuardHandle_collectionCreate()}
            close={this.closeArc}
            refresh={() => {
              cache.cleanCache(REQUEST_LOCATION_BASE_V3);
              this.fetchLocation();
              dispatch(
                refreshPermission({
                  show: false
                })
              );
              this.listEnd.scrollIntoView({ behavior: 'smooth' });
            }}
          />
        );
      case 'setting':
        return (
          locationEdit &&
          locationBase && (
            <EditLocation
              refresh={this.fetchLocationEdit}
              openDelete={() => this.setState({ modal: 'delete Location' })}
              close={this.closeArc}
              showOutdoor={showOutdoor}
              locationEdit={locationEdit}
              locationBase={locationBase}
              workspaceID={+id}
              locationID={+location_id}
              collections={locationBase!.collections}
            />
          )
        );
      case 'notifications':
        return (
          locationBase && (
            <div className='colletion_modal-container'>
              <h3 className='register-title'>
                {t('locationOverview.Conditional Notification')}
              </h3>
              <ReadingLimit
                disabled={!editAble}
                close={this.closeArc}
                locationBase={locationBase}
                locationID={+location_id}
              />
            </div>
          )
        );
      case 'delete Location':
        return (
          <div className='colletion_modal-container'>
            <h3 className='register-title'>
              {t('locationOverview.delete Location')}
            </h3>
            <div className='register-body'>
              <p className='register-message small_text'>
                {t('locationOverview.input location name')}
              </p>
              <div
                className={classNames('ant-form-item-control', {
                  'has-error': locationBase!.name !== deleteInput
                })}
              >
                <Input
                  style={{ width: '100%' }}
                  placeholder={t('Location Name')}
                  onChange={(e) =>
                    this.setState({ deleteInput: e.target.value })
                  }
                  value={deleteInput}
                />
              </div>
            </div>
            <div className='colletion_modal-button_group'>
              <Button customType='light' radius='rect' onClick={this.closeArc}>
                {t('Cancel')}
              </Button>
              &nbsp;&nbsp;
              <Button
                customType='danger'
                radius='rect'
                onClick={this.deleteLocation}
                disabled={locationBase!.name !== deleteInput}
              >
                {t('Delete')}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  renderAverage(data: any[], type: string, stale: boolean) {
    switch (type) {
      case 'airFilter':
        return (
          <div className='location_detail-collection-list'>
            {data.map((indicator, i) => (
              <div
                key={`circle-${i}`}
                className='location_detail-collection-circle_container'
              >
                <span className='location_detail-collection-circle_name small_text grey_text'>
                  {shortName[indicator.name] || indicator.name}
                </span>
                <Tooltip
                  title={
                    indicator.level
                      ? this.translation[indicator.level]
                      : indicator.value === null
                        ? t('Notification.offline')
                        : t('Notification.online')
                  }
                >
                  <Progress
                    className={classNames('location_detail-collection-circle', {
                      [`is-${indicator.level}`]: !!indicator.level && !stale,
                      'is-online':
                        !indicator.level && indicator.value !== null && !stale,
                      'is-offline': indicator.value === null || stale
                    })}
                    status={
                      this.calcPercent(
                        indicator.data_channel,
                        indicator.value,
                        indicator.level
                      ) >= 100
                        ? 'exception'
                        : undefined
                    }
                    type='circle'
                    format={(percent) =>
                      indicator.value === null ? 'N/A' : indicator.value
                    }
                    percent={this.calcPercent(
                      indicator.data_channel,
                      indicator.value,
                      indicator.level
                    )}
                    width={60}
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        );
      case 'electricityFilter':
        let max = maxBy(data, (indicator) => indicator.value);
        max = max && max.value;

        return (
          <div className='location_detail-collection-list'>
            {data.map((indicator, i) => (
              <div
                key={`progress-${i}`}
                className='location_detail-collection-progress_container'
              >
                <span className='location_detail-collection-progress_name small_text grey_text'>
                  {indicator.name}
                </span>
                <Tooltip
                  title={
                    indicator.value === null
                      ? t('Notification.offline')
                      : t('Notification.online')
                  }
                >
                  <Progress
                    percent={
                      max && indicator.value ? (indicator.value / max) * 100 : 0
                    }
                    showInfo={false}
                    strokeColor='#0b75a9'
                  />
                </Tooltip>
                <span className='location_detail-collection-progress_value'>
                  {indicator.value === null ? 'N/A' : indicator.value}
                  <span
                    style={{ marginLeft: 5 }}
                    className='small_text grey_text'
                  >
                    {indicator.unit}
                  </span>
                </span>
              </div>
            ))}
          </div>
        );

      default:
        return (
          <ul className='location_detail-collection-list flex'>
            {data.map((indicator, i) => (
              <Tooltip
                key={i}
                title={
                  indicator.level
                    ? this.translation[indicator.level]
                    : indicator.value === null
                      ? t('Notification.offline')
                      : t('Notification.online')
                }
              >
                <li key={`location-average-${i}`}>
                  <div className='location_detail-collection-line'>
                    <span
                      className={classNames(
                        'location_detail-collection-indicator_status',
                        {
                          [`is-${indicator.level}`]: !!indicator.level,
                          'is-online':
                            !indicator.level && indicator.value !== null,
                          'is-offline': indicator.value === null
                        }
                      )}
                    />
                    <span className='small_text grey_text'>
                      {indicator.name}
                    </span>
                  </div>
                  <div className='location_detail-collection-line'>
                    <span className='location_detail-collection-value'>
                      {indicator.value === null ? 'N/A' : indicator.value}
                    </span>
                    <span className='small_text'>
                      {indicator.value === null ? ' ' : indicator.unit}
                    </span>
                  </div>
                </li>
              </Tooltip>
            ))}
          </ul>
        );
    }
  }

  render() {
    const {
      modal,
      locationBase,
      welcome,
      indicatorsForOrder,
      indicatorOrderTy
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    if (!locationBase) {
      return <Skeleton active={true} />;
    }

    return (
      <>
        <div className='location_detail-header'>
          <Breadcrumb
            className='location_detail-header-breadcrumb'
            separator={
              <i className='material-icons location_detail-header-arrow'>
                keyboard_arrow_right
              </i>
            }
          >
            <Breadcrumb.Item>
              <Link
                to={`/workspaces/${id}/locations`}
                className='location_detail-breadcrumb'
              >
                {t('locationOverview.Locations')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a className='location_detail-breadcrumb is-active'>
                {locationBase.name}
              </a>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className='location_detail-top-action'>
          {this.renderFavBtn()}
          <Link
            to={`/workspaces/${id}/kiosk?id=${locationBase.id}`}
            className='header-btn-item'
          >
            {t('locationOverview.Go to Kiosk')}
          </Link>
          <div
            className='header-btn-item'
            onClick={() => {
              this.setState({ modal: 'notifications' });
            }}
          >
            <i className='icon-notificationbellring iconfont' />
            {t('locationOverview.Set Notifications')}
          </div>
          <div className='header-btn-item' onClick={this.Guard_integration}>
            <i className='icon-list-certification iconfont' />
            {t('locationOverview.integration')}
          </div>
          <div
            className='header-btn-item'
            onClick={() => this.setState({ modal: 'add' })}
          >
            <i className='material-icons line_icon'>add_circle</i>
            {t('locationOverview.AddCollection')}
          </div>
          <div
            className='header-btn-item'
            onClick={() => this.setState({ modal: 'setting' })}
          >
            <i className='material-icons line_icon'>settings</i>
            {t('locationOverview.EditSettings')}
          </div>
        </div>

        <div className='location_detail-content'>
          {this.renderLocationBase()}
          <div className='location_detail-right'>
            {welcome && (
              <div className='location_detail-right-welcome'>
                <h2>
                  {t('locationOverview.welcome')}
                  <br />
                  {t('locationOverview.Location Overview')}
                </h2>
                <p>{t('locationOverview.This is where you can')}</p>
                <p>{t('locationOverview.To get started')}</p>
                <div style={{ textAlign: 'right' }}>
                  <a onClick={() => this.setState({ welcome: false })}>
                    {t('locationOverview.Dimiss')}
                  </a>
                </div>
              </div>
            )}
            {this.GuardHandle_locationAverage()}
            {this.renderCollection()}
          </div>
        </div>
        <Modal
          visible={!!modal}
          centered={true}
          onCancel={this.closeArc}
          footer={null}
          maskClosable={false}
          bodyStyle={{ padding: 0 }}
          width={580}
        >
          {this.renderModalElement()}
        </Modal>
        {indicatorsForOrder && indicatorOrderTy && (
          <Modal
            visible={true}
            centered={true}
            onCancel={() => {
              this.setState({
                indicatorsForOrder: undefined
              });
            }}
            maskClosable={true}
            bodyStyle={{
              padding: '20px 30px'
            }}
            // okButtonProps={{
            //   style: {width: '120px'}
            // }}
            okText={t('indicator.saveBtn')}
            onOk={this.updatePositions}
            title={t('indicator.sort', { ty: indicatorOrderTy.name })}
            width={580}
          >
            <div className='order-indicator-list'>
              {indicatorsForOrder.map((ind, i) => (
                <div className='indicator-item' key={i}>
                  <span>{ind.name}</span>
                  <div className='order-action'>
                    {/* <Tooltip title={t('locationOverview.moveDown')}> */}
                    <span
                      onClick={() => {
                        if (i > 0) {
                          const itemClone = Object.assign({}, ind);
                          indicatorsForOrder[i] = indicatorsForOrder[i - 1];
                          indicatorsForOrder[i - 1] = itemClone;
                          this.setState({
                            indicatorsForOrder: indicatorsForOrder.slice()
                          });
                        }
                      }}
                    >
                      <i className='iconfont icon-rising'></i>
                    </span>
                    {/* </Tooltip> */}
                    <span
                      onClick={() => {
                        if (i < indicatorsForOrder.length - 1) {
                          const itemClone = Object.assign({}, ind);
                          indicatorsForOrder[i] = indicatorsForOrder[i + 1];
                          indicatorsForOrder[i + 1] = itemClone;
                          this.setState({
                            indicatorsForOrder: indicatorsForOrder.slice()
                          });
                        }
                      }}
                    >
                      <i className='iconfont icon-falling'></i>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </Modal>
        )}
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            this.listEnd = el;
          }}
        />
      </>
    );
  }
}

const mapState: MapState<StateProps> = ({
  V2: {
    locationInfo,
    dictionary: { time_zones, cities }
  },
  V3: { location }
}) => ({
  outdoor: locationInfo.outdoor,
  timeZone: time_zones,
  locationEdit: location.edit,
  locationBase: location.base,
  cities
});

export default withRouter(connect(mapState)(Detail));
