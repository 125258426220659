export default function returnStatus(obj) {
  switch (obj.name) {
    case 'PM 2.5':
      if (obj.value <= 35) {
        return 'Good'
      } else {
        return 'Moderate'
      }
    case 'CO₂':
      if (obj.value <= 1000) {
        return 'Good'
      } else {
        return 'Moderate'
      }
    case 'Total VOC':
      if (obj.value <= 0.45) {
        return 'Good'
      } else {
        return 'Moderate'
      }
    default:
      return 'no data'
  }
}
