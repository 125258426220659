import { requestGraph } from 'actions/V2/graph';
import { requestV3Location } from 'actions/V3/location';
import {
  Button, Checkbox, DatePicker, Divider, Input, message,
  Select
} from 'antd';
import classNames from 'classnames';
import CustomSelect from 'components/Custom/Select';
import LineChart from 'components/LineChart';
import NoData from 'components/NoData';
import { P, StateProps } from 'containers/Graph/type';
import { t } from 'i18next';
import { isNumber, pick } from 'lodash';
import moment, { Moment } from 'moment-timezone';
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LEVEL } from 'utils/constant';
import { downloadToLocal } from 'utils/helper';
import { queryMerge } from 'utils/query';
import './index.css';

const { RangePicker } = DatePicker;
const Option = Select.Option;
type Query = {
  collection?: string;
  channel?: string;
  time_zone?: string;
};

const filterObjs = [
  {
    name: t('Report.Working Hours'),
    value: 1
  },
  {
    name: t('Report.All Hours'),
    value: 0
  }
];

const Graph: React.SFC<P> = React.memo((props) => {
  const {
    graph, // graph 数据
    certifications, // 认证标准数据
    locationList, // workspaces 下的 所有location
    kiosk, // 仅在 kiosk 页面中 传递过来的kiosk 信息
    locationBase, // 请求到的locationbase 信息
    dispatch,
    history,
    features,
    currentID, // 仅在 kiosk 页面中传递的选中的状态， 数字代表collection id，undefined 代表average
    location: { search, pathname },
    match: {
      params: { location_id, id } // location_id 在kiosk 页面中不存在， id 在kiosk 中会变为 location_id 在外面代表workspace id, 不建议经常用
    }
  } = props;
  const canvas = React.useRef<LineChart>(null);
  // graph 时间区间，rangePicker 的 value
  // set default range to 5 days prior to today
  const [period, setPeriod] = React.useState<(Moment | undefined)[]>([
    moment().subtract(5, 'days'),
    moment()
  ]);
  // workhour select 状态  0 = all 1 = workinghour
  const [useWorkingHour, setUseWorking] = React.useState(0);
  // stander select 状态 -1 = none
  const [useStander, setUseStander] = React.useState(-1);
  // 是否使用average
  const [average, setAverage] = React.useState(true);
  // 是否使用outdoor
  const [outdoor, setOutdoor] = React.useState(true);
  const [maxY, setMaxY] = React.useState<string>('');
  /**
 * location_id 回调
 * 触发请求locationBase的数据
 * 触发条件
 * 1. 不是kiosk页面中
 * 2. location_id 存在 （在kiosk中就不存在）
 * 3. locationBase 不存在 或 locationBase的数据不是新增location_id的
 */
  React.useEffect(() => {
    if (
      location_id &&
      !kiosk &&
      !(locationBase && locationBase.id === +location_id)
    ) {
      dispatch(requestV3Location({ location_id: +location_id, channel: true }));
    }
  }, [dispatch, kiosk, locationBase, location_id]);

  // computed -> location (graph 页面中的locationbase, kiosk 页面中的kiosk)
  const location = React.useMemo(() => {
    return kiosk || locationBase;
  }, [kiosk, locationBase]);

  /**
 * location 回调
 * 获得新的location 时，根据状态设置 是否使用outdoor average
 */
  React.useEffect(() => {
    if (location) {
      setAverage(!!location.average);
      setOutdoor(!!location.outdoor);
    }
  }, [location]);

  /**
 * computed -> indicatorList
 * kiosk 页面中 根据 选中的状态 返回 average 或collection 的reading
 * 外面页面返回 locationbase 的data_channel
 */
  const indicatorList = React.useMemo(() => {
    if (kiosk) {
      return currentID
        ? kiosk.collections.find((c) => c.id === currentID)!.readings
        : kiosk.average!.readings;
    }

    return location ? location.data_channels || [] : [];
  }, [kiosk, currentID, location]);

  /**
 * computed -> 是否显示 average, outdoor
 */
  const { showAverage, showOutdoor } = React.useMemo(
    () =>
      location
        ? {
          showAverage: features.average && !!location.average,
          showOutdoor: features.outdoor && !!location.outdoor
        }
        : { showAverage: false, showOutdoor: false },
    [location, features]
  );

  /**
 * computed -> 从hash 获得 collection 和channel
 */
  const { collection, channel, time_zone } = React.useMemo(
    () =>
      pick<Query>(qs.parse(search.slice(1)), [
        'collection',
        'channel',
        'time_zone'
      ]),
    [search]
  );

  const selectAllCollections = React.useMemo(() => {
    if (!location || !collection) {
      return false;
    }
    return location.collections.length === collection.split(',').length;
  }, [location, collection]);

  /**
 * computed -> 获取当前选中的indicator
 */
  const indicator = React.useMemo(
    () =>
      channel || (indicatorList.length ? indicatorList[0].channel : undefined),
    [channel, indicatorList]
  );

  /**
 * computed -> 获取当前选中的collections
 */
  const selectCollection = React.useMemo(() => {
    const c = kiosk && currentID ? String(currentID) : collection;
    return c || '';
  }, [kiosk, collection, currentID]);

  // computed -> 返回选中的标准 | undefined
  const stander = React.useMemo(
    () => certifications[useStander],
    [useStander, certifications]
  );

  // computed -> collection id 数组
  const collectionID = React.useMemo(
    () => selectCollection.split(',').filter(Boolean).map(Number),
    [selectCollection]
  );

  // computed ->  图表数据
  const chart = React.useMemo(() => {
    if (!graph) return null;
    // Search for negative value and set the minYStart
    let minYStart = 0;
    if (graph && graph.dataList) {
      const allData = graph.dataList.flatMap(obj => obj.data);
      const minValue = Math.min(...allData);
      minYStart = Math.floor(minValue / 10) * 10;
    }
    const chart = {
      maxTicksLimit: 6,
      labelText: '',
      indicatorData: graph.dataList,
      minYStart: minYStart,
      standardLabel: stander && stander.name
    };

    if (channel === 'tvoc') {
      (chart as any).suggestedMax = 0.5;
    }

    const config = {
      colourBar: LEVEL()[indicator!],
      labelText: graph!.units[indicator!],
      standardVal: stander && stander.standards.default[indicator!]
    };

    return indicator ? { ...chart, ...config } : chart;
  }, [channel, graph, indicator, stander]);

  const maxYEnd = React.useMemo(() => {
    if (!maxY || !isNumber(+maxY)) return undefined;
    const num = +maxY;
    return num <= 0 ? undefined : num;
  }, [maxY]);

  // didmount location 不合法则返回
  React.useEffect(() => {
    if (!kiosk) {
      const finded = locationList.find((v) => v.id === +location_id!);
      if (!finded) {
        history.replace(`/workspaces/${id!}/graph`);
        message.warning(t('location not found'));
        return;
      }
    }
  }, [history, id, kiosk, locationList, location_id]);

  const fetchGraph = () => {
    const params: any = {
      location_id: kiosk ? kiosk.location_id : location_id,
      outdoor: showOutdoor && (kiosk ? true : outdoor), // kiosk 默认显示outdoor 除非不允许
      location_average: showAverage && (kiosk ? !currentID : average), // kiosk 如果选择average 则显示average , collection 则不显示average 除非不允许
      station_id: selectCollection,
      indicator,
      time_zone
    };

    if (period[0] && period[1]) {
      params.begin_time = moment(period[0]).format('YYYY-MM-DD');
      params.end_time = moment(period[1]).format('YYYY-MM-DD');
    }

    dispatch(requestGraph(params));
  };

  /**
 * 监听grpah 请求相关的参数
 * 当 collection, channel, average, outdoor, location, period, time_zone 变化时，根据情况做url重置处理或请求图表
 * 1. 无location 信息等待location
 * 2. url 中的 channel collection 不存在，且支持average，直接请求
 * 3. 非 kiosk 中 不支持average，collection 不存在，但是 location 有 collection,默认转为第一个collectio
 * 4. 之后判断 channel collection 是否合法，不合法则replace
 * 5. 合法之后进入最后请求环节
 */
  React.useEffect(() => {
    if (!location) return;
    if (!(channel || collection || time_zone) && showAverage) {
      return fetchGraph();
    }
    if (!kiosk && !showAverage && !collection && location.collections.length) {
      return history.replace({
        pathname,
        search: queryMerge({
          search,
          query: {
            collection: String(location.collections[0].id)
          }
        })
      });
    }

    const redirectQuery: Query = {};

    const removeChannel =
      !!channel &&
      (location.data_channels || []).every((v) => v.channel !== channel);

    // collectionID 已经处理过 kiosk 状态
    const collectionIDS = collectionID.filter((id) =>
      location.collections.some((c) => c.id === id)
    );
    // kiosk 页面不处理collection
    const collectionInvaild =
      !kiosk && collectionIDS.toString().length !== (collection || '').length;

    const shouldRedicrt = collectionInvaild || removeChannel;

    // 重置争取的collections
    if (collectionInvaild && collectionIDS.length) {
      redirectQuery.collection = collectionIDS.join(',');
    }
    // channel 正确 保留
    if (!removeChannel) {
      redirectQuery.channel = channel;
    }

    if (shouldRedicrt) {
      history.replace({
        pathname,
        search: `?${qs.stringify(redirectQuery)}`
      });
    } else {
      fetchGraph();
    }
  }, [collection, channel, average, outdoor, location, period, time_zone, showAverage, kiosk, collectionID, fetchGraph, history, pathname, search]);

  if (!location) {
    return null;
  }

  /**
 * 切换location
 * 触发location_id 变动回调
 * 请求新的location
 * 新的location 触发url 判断的回调
 * 正确后触发请求graph
 */
  const changeLocation = (v) => {
    history.push({
      pathname: `/workspaces/${location.workspace_id}/graph/${v}`,
      search
    });
  };

  /**
 * 切换collection
 * 改变url中的collection
 * 触发验证回调，触发请求graph
 */
  const changeCollection = (v) => {
    const query = queryMerge({
      search,
      query: {
        collection: v
          .filter((v) => v !== 'outdoor' && v !== 'average')
          .join(',')
      }
    });

    history.push({
      pathname,
      search: query
    });

    setAverage(v.includes('average'));
    setOutdoor(v.includes('outdoor'));
  };

  const changeSelectAllCollections = (e) => {
    if (!selectAllCollections) {
      const collectionIDs = (location.collections as any[]).map(
        (item) => item.id
      );
      const query = queryMerge({
        search,
        query: {
          collection: collectionIDs.join(',')
        }
      });

      history.push({
        pathname,
        search: query
      });
    } else {
      history.push({ pathname });
    }
  };

  const changeTimezone = (v) => {
    const query = queryMerge({
      search,
      query: {
        time_zone: v
      }
    });

    history.push({
      pathname,
      search: query
    });
  };

  /**
 * indicator
 * 改变url中的 indicator
 * 触发验证回调，触发请求graph
 */
  const changeIndicator = (indicator) => {
    const query = queryMerge({
      search,
      query: {
        channel: indicator
      }
    });

    history.push({
      pathname,
      search: query
    });
  };

  const disabledDate = (current) => {
    if (!graph) {
      return true;
    }
    // 保证只能选择最多 6个月
    if (period[0]) {
      const minValidDate = moment.max(
        moment(graph.configs.search_time_enabled_at),
        period[0]
      );
      const tooLate = period[0] && current.diff(period[0], 'days') > 180;
      const tooEarly = period[0] && minValidDate.diff(current, 'days') > 180;
      return tooEarly || tooLate || current > moment();
    } else {
      return (
        (current && current < moment(graph.configs.search_time_enabled_at)) ||
        current > moment()
      );
    }
  };

  const downloadImg = () => {
    if (canvas.current) {
      const b64 = canvas.current.chart.chartInstance.toBase64Image();
      downloadToLocal('qlear-chart.png', b64);
    }
  };

  const renderHeaderSelector = () => {
    const cids: any[] = [
      showAverage && average && 'average',
      showOutdoor && outdoor && 'outdoor'
    ]
      .concat(collectionID as any)
      .filter(Boolean);

    return (
      <div className='graph-header-selector'>
        <div className='group'>
          <div className='label'>{t('Location')}</div>
          <CustomSelect
            className='graph-location'
            showSearch={true}
            filterOption={(input, option) =>
              (option.props.children as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            optionFilterProp='children'
            dropdownMatchSelectWidth={true}
            value={+location_id!}
            onChange={changeLocation}
          >
            {locationList.map((loc, i) => (
              <Option key={i} value={loc.id}>
                {loc.name}
              </Option>
            ))}
          </CustomSelect>
        </div>
        <div className='group'>
          <div className='label'>{t('datatable.timezone')}</div>
          <CustomSelect
            className='graph-timezone'
            dropdownMatchSelectWidth={true}
            showSearch
            value={time_zone || 'Asia/Shanghai'}
            placeholder={t('datatable.Select timezone')}
            onChange={changeTimezone}
          >
            {moment.tz.names().map((name, index) => (
              <Select.Option key={index} value={name}>
                [{moment().tz(name).format('Z')}] {name}
              </Select.Option>
            ))}
          </CustomSelect>
        </div>
        <div className='block'>
          <div className='label'>{t('Reference')}</div>
          <CustomSelect
            className='graph-refer'
            value={cids}
            dropdownMatchSelectWidth={true}
            onChange={changeCollection}
            placeholder={t('Please select')}
            mode='multiple'
            dropdownRender={(menu) => (
              <div>
                {menu}
                <Divider style={{ margin: '2px 0' }} />
                <div style={{ padding: '4px 8px 8px 8px', cursor: 'pointer' }} onMouseDown={(e) => e.preventDefault()}>
                  <Checkbox
                    checked={selectAllCollections}
                    onChange={changeSelectAllCollections}
                  >
                    Select All Collections
                  </Checkbox>
                </div>
              </div>
            )}
          >
            {showAverage && (
              <Option value='average'>{t('Location Average')}</Option>
            )}

            {showOutdoor && (
              <Option value='outdoor'>{t('Report.Outdoor')}</Option>
            )}
            {(location.collections as { id: number; name: string }[]).map(
              (col, i) => (
                <Option key={i} value={col.id}>
                  {col.name}
                </Option>
              )
            )}
          </CustomSelect>
        </div>
      </div>
    );
  };

  const renderWordSelect = () => {
    return (
      <div className='graph-header'>
        {!kiosk && <span>{t('Showing')}</span>}
        <div className='graph-dropdown'>
          <div className='graph-dropdown-label'>{t('Indicator')}</div>
          <CustomSelect
            hideIcon={true}
            className='graph-dropdown-indicator'
            underline={true}
            value={indicator}
            onChange={changeIndicator}
          >
            {indicatorList.map((item, i) => (
              <Option key={i} value={item.channel}>
                {item.name}
              </Option>
            ))}
          </CustomSelect>
        </div>
        {!kiosk && <span className='hidden-mobile'>{t('between')}</span>}
        <div className='graph-dropdown'>
          <div className='graph-dropdown-label'>{t('Start')}</div>
          <RangePicker
            value={(period[0] ? period : [moment(), moment()]) as any}
            disabledDate={disabledDate}
            onCalendarChange={(date) => setPeriod(date)}
            className='graph-date-picker'
            format={'YYYY/MM/DD'}
            allowClear={false}
          />
        </div>
        {!kiosk && <span className='hidden-mobile'>{t('within')}</span>}
        <div className='graph-dropdown'>
          <div className='graph-dropdown-label'>{t('Filter')}</div>
          <CustomSelect
            underline={true}
            hideIcon={true}
            value={useWorkingHour}
            onChange={(V) => setUseWorking(V as number)}
          >
            {filterObjs.map((item, i) => (
              <Option key={i} value={item.value}>
                {item.name}
              </Option>
            ))}
          </CustomSelect>
        </div>
        {!kiosk && <span>{t('according to')}</span>}
        <div className='graph-dropdown'>
          <div className='graph-dropdown-label'>{t('Performance')}</div>
          <CustomSelect
            underline={true}
            hideIcon={true}
            dropdownTextCenter={true}
            value={useStander}
            onChange={(v) => setUseStander(v as number)}
          >
            <Option key={-1} value={-1}>
              {t('None')}
            </Option>
            {certifications.map((item, i) => (
              <Option key={i} value={i}>
                {item.name}
              </Option>
            ))}
          </CustomSelect>
        </div>
        {!kiosk && <span>{t('MAX')}</span>}
        <div className='graph-dropdown'>
          <div className='graph-dropdown-label'>{t('yaxis')}</div>
          <Input
            size='small'
            className={classNames('graph-dropdown-yaxis', {
              'is-error': maxY && !maxYEnd
            })}
            value={maxY}
            onChange={(v) => setMaxY(v.target.value.trim())}
          />
        </div>
      </div>
    );
  };
  return (
    <div className={classNames('graph-content', { inKiosk: kiosk })}>
      {!kiosk && (
        <div className='datatable-header-selector'>
          {renderHeaderSelector()}
          <div className='datatable-header-right hidden-mobile'>
            <div className='label'>&nbsp;</div>
            <Button
              className='datatable-download'
              icon='download'
              onClick={downloadImg}
            >
              {t('Graph.download')}
            </Button>
          </div>
        </div>
      )}
      {renderWordSelect()}
      <div className='graph-tips'>
        <small className='grey_text'>{t('Please note that currently')}</small>
      </div>

      {graph ? (
        <div style={{ padding: '5px 0' }} className='graph-panel'>
          <LineChart
            {...chart!}
            ref={canvas}
            isLegendDisplayed={true}
            isStatic={false}
            spanGaps={true}
            noFill={true}
            category={graph.times}
            maxYEnd={maxYEnd}
            workHoursFilter={useWorkingHour ? graph.workHours : undefined}
          />
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
});

const mapStateToProps: MapState<StateProps> = ({
  V2: { graph, certifications },
  V3: { locationSelect, location }
}) => ({
  graph: graph.data,
  certifications: certifications.data,
  locationList: locationSelect.data,
  locationBase: location.base
});

export default withRouter(connect(mapStateToProps)(Graph));
