import ClockIcon from '-!svg-react-loader!images/svg/clock.svg';
import FilterIcon from '-!svg-react-loader!images/svg/filter.svg';
import { requestNotifications } from 'actions/V2/notification';
import { Button, DatePicker, Icon, Menu, Popover, Select, Tooltip } from 'antd';
import Table from 'components/Custom/Table';
import { P, State, StateProps } from 'containers/Notification/type';
import { t } from 'i18next';
import { pick, xor } from 'lodash';
import moment from 'moment';
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notificationIcon, translations } from 'utils/constant';
import { notificationDate } from 'utils/helper';
import './filter.css';
import './index.css';

const { RangePicker } = DatePicker;

class Notification extends React.PureComponent<P, State> {
  loadingPage?: number;
  filterTranslation = {
    location: t('Location'),
    collection: t('Collection'),
    data_source: t('Datasource'),
    indicator: t('Indicator')
  };
  constructor(props: P) {
    super(props);
    const {
      location: { search }
    } = props;
    const q = pick(qs.parse(search.slice(1)), ['ws']);
    this.state = {
      filterSelected: q.ws ? [q.ws.toString()] : [],
      period: []
    };
  }
  componentDidMount() {
    this.requestNotifications();
  }
  requestNotifications = (query = {}) => {
    const {
      dispatch,
      location: { search }
    } = this.props;
    const q = pick(qs.parse(search.slice(1)), ['channel', 'collection', 'ws']);
    const param: any = {};

    if (q.channel) {
      param.data_channel = q.channel;
    }

    if (q.ws) {
      param.workspace_id = q.ws;
    }

    if (q.collection) {
      param.collection_id = q.collection;
    }

    dispatch(requestNotifications({ ...param, ...query }));
  };

  geneTableColumns = () => {
    return [
      {
        title: '',
        dataIndex: 'key',
        key: 'key',
        width: '10%',
        className: 'notification-icon-col',
        render: (text, record) => {
          const date = moment(record.updated_at)
            .format('HH:mm,MMM DD YYYY')
            .split(',');
          return (
            <div className='noti-col-date'>
              {date.map((d, i) => (
                <div className={`date-${i}`} key={i}>
                  {d}
                </div>
              ))}
            </div>
          );
        }
      },
      {
        title: t('Notification.TYPE'),
        dataIndex: 'type',
        key: 'type',
        width: '10%',
        className: 'notification-type-col',
        render: (text, record) => {
          return (
            <Tooltip title={translations[record.type]}>
              <img
                src={notificationIcon[record.type]}
                className='noti-tableIcon'
                alt={translations[record.type]}
              />
            </Tooltip>
          );
        }
      },
      {
        title: t('Notification.DESCRIPTION'),
        dataIndex: 'description',
        key: 'description',
        width: '45%',
        className: 'notification-description-col',
        render: (Text, record) => {
          return (
            <div className='noti-station'>
              {record.type === 'data_source' ? (
                <div className='noti-station-box'>
                  <b className='noti-title'>{record.title}</b>
                  &nbsp;
                  <span className='noti-rep'>{t('Notification.reported')}</span>
                  <span className={'noti-status ' + record.status}>
                    {translations[record.status]}
                  </span>
                </div>
              ) : (
                <div className='noti-message'>
                  <b>{translations[`${record.status}Rule`]}</b>
                  <br />
                  {t('Notification.content', {
                    location_name: record.title,
                    indicator: record.data_channel_name,
                    content: record.content || '',
                    status: translations[record.status] || ''
                  })}
                </div>
              )}
              <i className='noti-date'>
                {record.reading_time && notificationDate(record.reading_time)}
              </i>
            </div>
          );
        }
      },
      {
        title: t('Notification.Workspace'),
        dataIndex: 'workspace_name',
        key: 'workspace_name',
        width: '20%'
      },
      {
        title: '',
        dataIndex: 'resolved_at',
        key: 'resolved_at',
        width: '15%',
        render: (text, record) => {
          return (
            !!record.resolved_at && (
              <div className='noti-resolve'>
                <div className='noti-resolved'>
                  <i className='material-icons'>check_circle</i>
                  {t('Notification.Resolved')}
                </div>
                <i className='noti-date'>
                  {notificationDate(record.resolved_at)}
                </i>
              </div>
            )
          );
        }
      }
    ];
  };

  renderExpandedRow = record => {
    const indicators = record.indicators;
    if (!indicators) {
      return null;
    }
    return (
      <div className='noti-row-indicators'>
        {indicators.map(item => {
          return (
            <div key={item.id} className='noti-row-indicator-item'>
              <div className='noti-row-icon'>
                <img
                  src={notificationIcon.indicator}
                  className='noti-tableIcon'
                  alt='indicator'
                />
              </div>
              <div className='noti-row-info'>
                {item.data_channel_name}
                <span>({item.name})</span>
                <span>{t('Notification.reported')}</span>
                <span className={'noti-status-text ' + record.status}>
                  {translations[record.status]}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  navToSetting = () => {
    const { history } = this.props;
    history.push('/notification/settings');
  };

  filterRequest = () => {
    const { userShip } = this.props;
    const { filterSelected, period } = this.state;
    const workspaceId = filterSelected
      .filter(k => userShip.map(e => e.id).includes(+k))
      .toString();
    const type = filterSelected
      .filter(k => Object.keys(this.filterTranslation).includes(k))
      .toString();
    let params = {
      workspace_id: workspaceId,
      type,
      page: this.loadingPage || 1
    };
    if (period.length) {
      params = Object.assign(params, {
        updated_at_begin: period[0],
        updated_at_end: period[1]
      });
    }
    this.requestNotifications(params);
  };

  filterChange = ({ key }) => {
    const { filterSelected } = this.state;
    const copy = xor(filterSelected, [key]);
    this.setState({ filterSelected: copy }, this.filterRequest);
  };

  selectWorkspace = id => {
    const { filterSelected } = this.state;
    const copy = xor(filterSelected, [id]);
    this.setState({ filterSelected: copy }, this.filterRequest);
  };

  renderMenu = () => {
    const { filterSelected } = this.state;
    const { userShip } = this.props;

    return (
      <div className='custom-filter'>
        <Menu mode='inline' multiple={true} selectedKeys={filterSelected}>
          <Menu.SubMenu key='workspace' title={t('Workspace')}>
            <Menu.Item className='location-filter-search_menu'>
              <Select
                mode='multiple'
                showSearch={true}
                value={[]}
                onSelect={this.selectWorkspace}
                className='location-filter-search'
                placeholder={t('filter.selectWorkspace')}
                optionFilterProp='children'
                size='small'
                dropdownClassName='location-filter-dropdown'
                filterOption={(input, option) =>
                  (option.props.children as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {userShip.map((item, i) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Menu.Item>
          </Menu.SubMenu>

          <Menu.SubMenu key='type' title={t('Type')}>
            {Object.keys(this.filterTranslation).map((item, i) => (
              <Menu.Item key={item} onClick={this.filterChange}>
                <span className='grey_text small_text menu_text'>
                  {this.filterTranslation[item]}
                </span>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu>
      </div>
    );
  };

  onChangePeriod = time => {
    this.setState(
      {
        period: [
          time[0].format('YYYY-MM-DD HH:mm'),
          time[1].format('YYYY-MM-DD HH:mm')
        ]
      },
      this.filterRequest
    );
  };

  cancelPeriod = () => {
    this.setState(
      {
        period: []
      },
      this.filterRequest
    );
  };

  selectPage = page => {
    this.loadingPage = page;
    this.filterRequest();
  };

  pagination = () => {
    const { meta } = this.props;
    return meta
      ? {
        hideOnSinglePage: true,
        showQuickJumper: true,
        onChange: this.selectPage,
        current: meta.current_page,
        defaultPageSize: 20,
        total: meta.total_count,
        showTotal: () => {
          return t('showTotal', { total: meta.total_count });
        }
      }
      : {};
  };

  render() {
    const { notifications, meta, userShip } = this.props;
    const { filterSelected, period } = this.state;

    if (!meta) {
      return null;
    }

    const data = notifications.map((item, i) => {
      return Object.assign(item, { key: i });
    });

    return (
      <div className='noti-content location-wrapper'>
        <div className='noti-header-bar'>
          <div className='noti-header-left'>{t('Notification.Center')}</div>
          <div className='noti-header-right'>
            <Button
              ghost={true}
              type='primary'
              icon='setting'
              onClick={this.navToSetting}
            >
              {t('Notification.Setting')}
            </Button>
          </div>
        </div>
        <div className='noti-options-bar'>
          <div className='noti-options-filter_item'>
            <ClockIcon />
            <span className='small_text grey_text noti-options-filter_text'>
              {t('Period')}
            </span>
            <RangePicker
              style={{ width: '80px' }}
              showTime={{ format: 'HH:mm' }}
              format='YYYY-MM-DD HH:mm'
              onOk={this.onChangePeriod}
            />
            {!!period.length && (
              <div
                className='noti-datetime-selected'
                onClick={this.cancelPeriod}
              >
                <Icon type='close' />
                <span>
                  {period[0]} - {period[1]}
                </span>
              </div>
            )}
          </div>
          <div className='noti-options-filter_item noti-options-filter_item--filter'>
            <Popover
              overlayClassName='header-notification-overlay is-menu'
              content={this.renderMenu()}
              placement='bottom'
              trigger='click'
            >
              <FilterIcon />
              <span className='small_text grey_text noti-options-filter_text'>
                {t('filter.Filter')}
              </span>
              {!!filterSelected.length && (
                <span className='noti-options-badge'>
                  {filterSelected.length}
                </span>
              )}
            </Popover>
          </div>

          <div className='location-filter_condition'>
            {filterSelected.map((item, index) => (
              <div
                className='location-filter_condition-item'
                key={`filter-item-${index}`}
              >
                <div
                  className='location-filter_condition-close'
                  onClick={() => this.filterChange({ key: item })}
                >
                  <Icon
                    type='close'
                    style={{
                      fontSize: 10,
                      color: '#9B9B9B',
                      lineHeight: '30px'
                    }}
                  />
                </div>
                <div className='location-filter_condition-text'>
                  {this.filterTranslation[item]
                    ? this.filterTranslation[item]
                    : (userShip.find(v => String(v.id) === String(item)) || {})
                      .name}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='noti-list-wrap'>
          <Table
            type='light'
            pagination={this.pagination()}
            centerAlign={true}
            expandRowByClick={true}
            rowClassName={(record: any) =>
              !(record.indicators || []).length ? 'noti-list-no_expand' : ''
            }
            expandedRowRender={this.renderExpandedRow}
            columns={this.geneTableColumns()}
            dataSource={data}
            size='small'
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V2: { notifications, userShip }
}) => ({
  notifications: notifications.data,
  meta: notifications.meta,
  query: notifications.query,
  userShip: userShip.data
});

export default withRouter(connect(mapStateToProps)(Notification));
