import { put, call, select } from 'redux-saga/effects'
import { setCookie } from 'utils/auth'
import { cleanStore } from 'actions/global'
import { REQUEST_USER_SIGN_IN, getUserSign, REQUEST_USER_SIGN_UP, REQUEST_RESET_USER_PWD } from 'actions/V2/userSign'
import { watchRequest } from 'sagas/helper'
import { replace } from 'connected-react-router'
import api from 'utils/api'
import { getUserShip } from 'actions/V2/userShip'

const selector = ({
  uiState: {
    siteState: { redirectURL }
  }
}) => redirectURL

type Arg = {
  email: string,
  password: string,
  remember?: boolean,
  agree?: boolean,
  user_name?: string
}
function* dataHandle(data: any, args: Arg) {
  yield put(cleanStore())
  localStorage.setItem('emailSource', data.email)
  setCookie(data, args.remember ? 365 : 7)
  yield put(getUserSign(data))
  const { data: userShips } = yield call(api, '/user_ships', 'get', {
    user_id: data.id,
    auth_token: data.auth_token
  })
  yield put(
    getUserShip(
      userShips.filter(v => v.visibility === 'show'),
      userShips
    )
  )
}

function* request(args: Arg) {
  const { data } = yield call(api, '/users/sign_in', 'post', args)
  let redirect = yield select(selector)
  redirect = redirect || ''
  yield dataHandle(data, args)
  yield put(replace(redirect.includes('exception') ? '/' : redirect || '/'))
  return data
}

function* requestSignUp(args: Arg) {
  const { data } = yield call(api, '/users/sign_up', 'post', args)
  yield dataHandle(data, args)
  return data
}

export const watchSignIn = watchRequest(REQUEST_USER_SIGN_IN, request)
export const watchSignUp = watchRequest(REQUEST_USER_SIGN_UP, requestSignUp)

function* requestResetUserPWD(args: Arg) {
  const resp = yield call(api, '/users/reset_password', 'get', args)
  return resp
}

export const watchResetUserPWD = watchRequest(REQUEST_RESET_USER_PWD, requestResetUserPWD)
