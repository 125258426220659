import { Dispatch } from 'redux';
import { ActionCreator, Action } from 'redux';
import * as qiniu from 'qiniu-js';
import { apiV3 } from 'utils/api';
import { message } from 'antd';
type PromisefiyDispatch = <T extends any, A extends Action = Action>(
  passContent: {
    dispatch: Dispatch<A>;
    actionCreator: ActionCreator<A>;
    payload?: {};
  }
) => Promise<T>;

export const promiseDispatch: PromisefiyDispatch = ({
    dispatch,
    actionCreator,
    payload = {}
}) =>
    new Promise((resolve, reject) => {
        const promisify = Object.create(null, {
            resolve: {
                value: resolve,
                enumerable: false,
                writable: false,
                configurable: false
            },
            reject: {
                value: reject,
                enumerable: false,
                writable: false,
                configurable: false
            }
        });

        return dispatch(actionCreator(Object.assign(promisify, payload)));
    });

type Observable = {
  subscribe(
    next: (
      next: { total: { percent: number; loaded: number; total: number } }
    ) => void,
    error: (
      error: {
        isRequestError?: boolean;
        reqId: string;
        code: number;
        message: string;
      }
    ) => void,
    complete: (complete: { hash: string; key: string }) => void
  ): void;
  unSubscribe(): void;
};
type ImageUpload = (
  file: File,
  type?: 'background' | 'logo'
) => Promise<Observable | undefined>;

export const uploadImage: ImageUpload = async (file, type = 'background') => {
    const options = {
        quality: 0.92,
        noCompressIfLarger: true,
        maxWidth: 1920,
        maxHeight: 1080
    };
    /* compress image if > 1920 * 1080 */
    const { dist } = await qiniu.compressImage(file, options);
    const match = /\.(jpg|png|jpeg)$/.exec(file.name);

    if (!match) {
        message.error('文件格式不支持，请重新选择');
        return;
    }
    const {
        data: { sequence, token }
    } = await apiV3<any>('/tokens/qiniu', 'get', { type: 'image' });
    const firstElement = 0
    const key =`images/locations/${type}/${sequence}-${Date.now()}${match![firstElement]}`;

    return qiniu.upload(dist, key, token);
};

export const uploadVideo = async (
    file: File
): Promise<Observable | undefined> => {
    const {
        data: { sequence, token }
    } = await apiV3<any>('/tokens/qiniu', 'get', { type: 'video' });
    const key = `videos/kiosks/video/${sequence}-${Date.now()}${file.name}`;
    return qiniu.upload(file, key, token);
};
