import * as React from 'react'
import classNames from 'classnames'
import './index.css'

interface State {
  editable: boolean;
}

interface Props {
  render: (editable: boolean, disableFunc: any) => any;
  iconStyle?: React.CSSProperties;
  iconClass?: string;
  className?: string;
  iconPosition?: 'start' | 'end';
  showIcon?: boolean;
}

export default class IconEdit extends React.PureComponent<Props, State> {
  static defaultProps = {
    className: '',
    iconClass: '',
    iconPosition: 'start',
    showIcon: true
  }

  state = {
    editable: false
  }

  disableEdit = () => this.setState({ editable: false })

  render() {
    const { editable } = this.state
    const { className, iconClass, iconStyle, iconPosition, showIcon } = this.props

    const editIcon = showIcon && !editable && (
      <a
        style={iconStyle}
        className={classNames('material-icons', 'edit-icon', iconClass)}
        onClick={() => this.setState({ editable: true })}
      >
        mode_edit
      </a>
    )

    return (
      <div className={className}>
        {iconPosition === 'start' && editIcon}
        {this.props.render(editable, this.disableEdit)}
        {iconPosition === 'end' && editIcon}
      </div>
    )
  }
}
