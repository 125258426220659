import { Action, ActionCreator } from 'redux'

export const REQUEST_DOWNLOADS_V3 = 'REQUEST_DOWNLOADS_V3'
export const GET_DOWNLOADS_V3 = 'GET_DOWNLOADS_V3'

export type DownloadObj = {
  id: number,
  name: string,
  range: string,
  frequency: string,
  category: string,
  count: number,
  url: string,
  status: string,
  location: string,
  collection: string,
  monitor: string,
  indicator: string
}

export interface RequestDownloadsAction extends Action<typeof REQUEST_DOWNLOADS_V3> {
  args: any;
}

interface GetDownloadsAction extends Action<typeof GET_DOWNLOADS_V3> {
  downloads: DownloadObj[];
  meta: Meta;
  query: {};
}

export type DownloadAction = GetDownloadsAction

export const requestDownloads: ActionCreator<RequestDownloadsAction> = args => ({
  type: REQUEST_DOWNLOADS_V3,
  args
})

export const getDownloads: ActionCreator<GetDownloadsAction> = (downloads, meta, query) => ({
  type: GET_DOWNLOADS_V3,
  downloads,
  meta,
  query
})
