import { put, call } from 'redux-saga/effects'

import {
  REQUEST_ORG_LIST_V3,
  REQUEST_ORG_USERS_V3,
  getOrgList,
  getOrgUsers,
  REQUEST_ORG_HISTORIES_V3,
  getOrgHistories,
  getOrgDetail,
  REQUEST_ORG_DETAIL_V3,
  getNewOrgUser,
  REQUEST_NEW_ORG_USER_V3,
  getDeleteOrgUser,
  REQUEST_DELETE_ORG_USER_V3,
  getUpdateOrgUser,
  REQUEST_UPDATE_ORG_USER_V3
} from 'actions/V3/org'
import { watchRequest } from 'sagas/helper'

import { apiV3 } from 'utils/api'

type Args = {
  size?: number,
  page?: number
}

function* request(args: Args) {
  const { data } = yield call(apiV3, '/orgs', 'get', { ...args })

  yield put(getOrgList(data))
  return data
}

export default watchRequest(REQUEST_ORG_LIST_V3, request)

function* requestOrgUsers(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}/users`, 'get')
  yield put(getOrgUsers(data))
  return data
}

export const watchOrgUsers = watchRequest(REQUEST_ORG_USERS_V3, requestOrgUsers)

function* requestOrgHistories(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}/histories`, 'get')
  yield put(getOrgHistories(data))
  return data
}

export const watchOrgHistories = watchRequest(REQUEST_ORG_HISTORIES_V3, requestOrgHistories)

function* requestOrgDetail(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}`, 'get')
  yield put(getOrgDetail(data))
  return data
}

export const watchOrgDetail = watchRequest(REQUEST_ORG_DETAIL_V3, requestOrgDetail)

function* requestNewOrgUser(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}/users`, 'post', { user: args.user })
  yield put(getNewOrgUser(data))
  return data
}

export const watchNewOrgUser = watchRequest(REQUEST_NEW_ORG_USER_V3, requestNewOrgUser)

function* requestDeleteOrgUser(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}/users/${args.org_user_id}`, 'delete')
  yield put(getDeleteOrgUser({ id: args.org_user_id }))
  return data
}

export const watchDeleteOrgUser = watchRequest(REQUEST_DELETE_ORG_USER_V3, requestDeleteOrgUser)

function* requestUpdateOrgUser(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}/users/${args.org_user_id}`, 'patch', {
    user: args.user
  })
  yield put(getUpdateOrgUser(data))
  return data
}

export const watchUpdateOrgUser = watchRequest(REQUEST_UPDATE_ORG_USER_V3, requestUpdateOrgUser)
