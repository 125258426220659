import React from 'react';
import { t } from 'i18next';
import templateJPMC from 'images/kiosk/JPMCFilledBackground.png';
import { Radio } from 'antd';

export default function KioskCustom() {
    return (
        <Radio value='templateJPMC'>
            {t('kiosk.Template JPMC')}
            <div className='template-img tabbed'>
                <img src={templateJPMC} alt='templateJPMC' />
            </div>
        </Radio>
    );
}
