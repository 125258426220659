import * as React from 'react';
import api from 'utils/api';
import Button from 'components/Custom/Button';
import { cleanStore, CleanStoreAction } from 'actions/global';
import { Col, Form, Input, message, Row } from 'antd';
import { connect, DispatchProp } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { getCookie, setCookie } from 'utils/auth';
import { isEmpty, values } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { t } from 'i18next';
import './index.css';

type Props = {
  type?: 'password';
};

type State = Readonly<{
  name: string;
}>;

type P = Props &
  FormComponentProps &
  RouteComponentProps<{}> &
  DispatchProp<CleanStoreAction>;

class Profile extends React.PureComponent<P, State> {
  state: State;

  constructor(props: P) {
      super(props);

      this.state = { name: getCookie()!.user_name };
  }

  update = async () => {
      const { name } = this.state;
      const { history, location } = this.props;
      const { data } = await api<any>('/users/profile', 'post', {
          user_name: name
      });
      setCookie(data, 365);
      history.replace(location);
  }

  renderProfile = () => {
      const { name } = this.state;
      const user = getCookie()!;
      return (
          <div className='profile-container'>
              <h2 className='profile-container-title'>
                  {t('profile.Personal Information')}
              </h2>
              <p className='profile-container-line' />
              <Row gutter={40}>
                  <Col span={10} className='profile-container-label'>
                      {t('user.E-mail')}
                  </Col>
                  <Col span={8} className='profile-container-message'>
                      {user.email}
                  </Col>
                  <Col span={10} className='profile-container-label'>
                      {t('user.User name')}
                  </Col>
                  <Col span={8} className='profile-container-message'>
                      <Input
                          size='small'
                          className='profile-container-input'
                          value={name}
                          onChange={e => this.setState({ name: e.target.value })}
                      />
                  </Col>
                  <Col span={14} offset={10} className='profile-container-message'>
                      <Button
                          onClick={this.update}
                          disabled={name === user.user_name}
                          size='small'
                          customType='dark'
                      >
                          {t('Update')}
                      </Button>
                  </Col>
              </Row>
          </div>
      );
  }

  compareToFirstPassword = (rule, value, callback) => {
      const form = this.props.form;
      if (value && value !== form.getFieldValue('new_password')) {
          callback('Two passwords that you enter is inconsistent!');
      } else {
          callback();
      }
  }

  submit = e => {
      e.preventDefault();
      const { form, dispatch, history } = this.props;
      form.validateFields(async (err, v) => {
          if (!err) {
              await api('/users/password', 'post', v)
              dispatch(cleanStore());
              message.success(t('profile.update Successfully'),1,() => {history.replace('/user/sign_in');});
          }
      });
  }

  passwordField = (type?: 'confrim' | 'new') => {
      const { getFieldDecorator } = this.props.form;
      const passwordRule: {}[] = [
          {
              required: true,
              message: t('user.Please input your Password')
          },
          {
              min: 8,
              message: t('user.at least 8 character')
          }
      ];

      if (type === 'confrim') {
          passwordRule.push({
              validator: this.compareToFirstPassword
          });
      }

      const label = !type
          ? t('profile.Old Password')
          : type === 'new'
              ? t('profile.New Password')
              : t('profile.Confirm New Password');
      const field = !type
          ? 'old_password'
          : type === 'new'
              ? 'new_password'
              : 'confirm';
      return (
          <Form.Item
              label={<span className='profile-container-label is-pwd'>{label}</span>}
              hasFeedback={!!type}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 8 }}
          >
              {getFieldDecorator(field, {
                  rules: passwordRule
              })(
                  <Input
                      size='small'
                      className='profile-container-input'
                      type='password'
                  />
              )}
          </Form.Item>
      );
  }

  renderPassword = () => {
      const { getFieldsError, getFieldsValue } = this.props.form;
      const val = getFieldsValue();
      const disable =
      isEmpty(val) ||
      values(val).some(v => !v) ||
      values(getFieldsError()).some(v => !!v);
      return (
          <div className='profile-container'>
              <h2 className='profile-container-title'>
                  {t('profile.Change Password')}
              </h2>
              <p className='profile-container-line' />
              <Form hideRequiredMark={true} onSubmit={this.submit}>
                  {this.passwordField()}
                  {this.passwordField('new')}
                  {this.passwordField('confrim')}
                  <Form.Item
                      wrapperCol={{
                          offset: 10,
                          span: 14
                      }}
                  >
                      <Button
                          disabled={disable}
                          customType='dark'
                          htmlType='submit'
                          size='small'
                      >
                          {t('Update')}
                      </Button>
                  </Form.Item>
              </Form>
          </div>
      );
  }

  render() {
      const { type } = this.props;
      return (
          <div className='location-wrapper'>
              <div className='location-inner'>
                  {type === 'password' ? this.renderPassword() : this.renderProfile()}
              </div>
          </div>
      );
  }
}

export default Form.create<Props & FormComponentProps>()(
    withRouter(connect()(Profile))
);
