import { put, call } from 'redux-saga/effects'
import * as actions from 'actions/V3/following'
import { watchRequest } from 'sagas/helper'
import { apiV3 } from 'utils/api'

function* request(args: any) {
  const { data, meta } = yield call(apiV3, '/followings', 'get', {
    ...args
  })

  yield put(actions.getFollowsV3(data, meta, args))
  return data
}

export default watchRequest(actions.REQUEST_FOLLOWING_V3, request)
