import { put, call } from 'redux-saga/effects'
import { REQUEST_ACTIVITY_CITY, getActivities } from 'actions/V2/activities'
import { watchRequestNoCancel } from 'sagas/helper'
import api from 'utils/api'

function* request() {
  const { data } = yield call(api, '/cities/activity')
  yield put(getActivities(data))
  return data
}

export default watchRequestNoCancel(REQUEST_ACTIVITY_CITY, request)
