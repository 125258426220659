import React from 'react'
import { t } from 'i18next'
import { Button, Tabs, Popconfirm, Row, Col, Input, Select } from 'antd'
import CustomSelect from 'components/Custom/Select'
import AlertPanel from './AlertPanel'

const TabPane = Tabs.TabPane
const Option = Select.Option

export default function SettingModalContentT2({
    currentKiosk,
    currentTab,
    notificationConfigs,
    movedDownSetState,
    accounts,
    _getUserNotificationSettings,
    GuardRender_publicSetting,
    handleDeleteKiosk,
    handleSettingModalCancel,
    doSaveTemplate2
}) {
    if (!currentKiosk) {
        return <div>{t('all locations has kiosks')}</div>
    }
    currentKiosk.template_config = currentKiosk.template_config || {
        advices: {}
    }
    const virusTransmissionDefences = currentKiosk.template_config.defences || [
        {
            name: '',
            value: ''
        }
    ]

    return (
        <div className='body'>
            <Tabs
                defaultActiveKey='1'
                onChange={key => {
                    movedDownSetState({
                        currentTab: key
                    })
                }}
                animated={{ inkBar: true, tabPane: false }}
            >
                <TabPane tab={t('kiosk.Transmission Risk')} key='1'>
                    <h3 className='label-priamry'>{t('kiosk.basic')}</h3>
                    <div className='input-group'>
                        <div className='advice-label'>
                            <div className='label-priamry'>{t('kiosk.location name')}</div>
                        </div>
                        <div>{currentKiosk.location_name}</div>
                    </div>
                    <div className='input-group'>
                        <div className='advice-label'>
                            <div className='label-priamry'>{t('kiosk.current collection or location average')}</div>
                        </div>
                        <div>{currentKiosk.collection_name || t('kiosk.location average')}</div>
                    </div>
                </TabPane>
                <TabPane tab={t('kiosk.Defences')} key='2'>
                    {virusTransmissionDefences.map((defence, didx) => (
                        <Row gutter={16} key={didx} className='defence-row'>
                            <Col span={11}>
                                <div className='defence-label'>{t('kiosk.defence label name')}</div>
                                <Input
                                    value={defence.name}
                                    onChange={e => {
                                        virusTransmissionDefences[didx].name = e.currentTarget.value
                                        currentKiosk.template_config.defences = virusTransmissionDefences
                                        movedDownSetState({
                                            currentKiosk
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={11}>
                                <div className='defence-label'>{t('kiosk.defence label input')}</div>
                                <Input
                                    value={defence.value}
                                    onChange={e => {
                                        virusTransmissionDefences[didx].value = e.currentTarget.value
                                        currentKiosk.template_config.defences = virusTransmissionDefences
                                        movedDownSetState({
                                            currentKiosk
                                        })
                                    }}
                                />
                            </Col>
                            <Col span={2}>
                                <div>&nbsp;</div>
                                <span
                                    className='defence-action'
                                    onClick={() => {
                                        virusTransmissionDefences.splice(didx, 1)
                                        currentKiosk.template_config.defences = virusTransmissionDefences
                                        movedDownSetState({
                                            currentKiosk
                                        })
                                    }}
                                >
                                    <i className='iconfont icon-shanchu' />
                                </span>
                            </Col>
                        </Row>
                    ))}
                    <div className='defence-add-one'>
                        <span
                            className={'defence-add-one-btn' + (virusTransmissionDefences.length > 2 ? ' disabled' : '')}
                            onClick={() => {
                                if (virusTransmissionDefences.length > 2) {
                                    return
                                }
                                virusTransmissionDefences.push({ name: '', value: '' })
                                currentKiosk.template_config.defences = virusTransmissionDefences
                                movedDownSetState({
                                    currentKiosk
                                })
                            }}
                        >
                            <i className='iconfont icon-plus' />
                            {t('kiosk.defence add one')}
                        </span>
                    </div>
                </TabPane>
                <TabPane tab={t('kiosk.Notifications & Advices')} key='3'>
                    <div className='input-group'>
                        <div className='advice-label'>
                            <div className='label-priamry'>{t('kiosk.advice choose user')}</div>
                            <div className='label-tip'>{t('kiosk.advice choose user tip')}</div>
                        </div>
                        <CustomSelect
                            underline={false}
                            className='accounts'
                            style={{ width: '100%' }}
                            showSearch={true}
                            dropdownMatchSelectWidth={true}
                            optionFilterProp='label'
                            value={currentKiosk.template_config.notification_user_id}
                            onChange={v => {
                                currentKiosk.template_config.notification_user_id = +v
                                movedDownSetState(
                                    {
                                        currentKiosk
                                    },
                                    _getUserNotificationSettings
                                )
                            }}
                        >
                            {accounts.map((account, i) => (
                                <Option key={i} {...{ label: account.name }} value={account.id}>
                                    {account.name}
                                </Option>
                            ))}
                        </CustomSelect>
                    </div>
                    <div className='input-group'>
                        <div className='advice-label'>
                            <div className='label-priamry'>{t('kiosk.Notifications & Advices')}</div>
                            {!currentKiosk.template_config.notification_user_id && (
                                <div className='label-tip'>{t('kiosk.advice empty tip')}</div>
                            )}
                        </div>
                        {currentKiosk.template_config.notification_user_id && (
                            <AlertPanel currentKiosk={currentKiosk} movedDownSetState={movedDownSetState} opts={{ ty: 2 }} />
                        )}
                        {notificationConfigs.map(noti => (
                            <AlertPanel
                                currentKiosk={currentKiosk}
                                movedDownSetState={movedDownSetState}
                                opts={{ ty: 1, obj: noti }}
                            />
                        ))}
                    </div>
                </TabPane>
                <TabPane tab={t('kiosk.tab public')} key='4'>
                    {GuardRender_publicSetting()}
                </TabPane>
                <TabPane tab={t('kiosk.tab delete')} key='5'>
                    <div className='body'>
                        <div className='tab-title'>{t('kiosk.tab delete')}</div>
                        <p className='tab-desc'>{t('kiosk.tab delete content')}</p>
                    </div>
                </TabPane>
            </Tabs>
            {currentTab === '5' ? (
                <div className='footer'>
                    <Popconfirm
                        title='Are you sure delete this kiosk?'
                        onConfirm={() => {
                            handleDeleteKiosk(currentKiosk)
                        }}
                        okText='Yes'
                        cancelText='No'
                    >
                        <Button type='danger'>{t('kiosk.tab delete')}</Button>
                    </Popconfirm>
                </div>
            ) : (
                <div className='footer'>
                    <Button type='primary' ghost={true} onClick={handleSettingModalCancel}>
                        {t('Cancel')}
                    </Button>
                    <Button type='primary' onClick={doSaveTemplate2}>
                        {t('Save')}
                    </Button>
                </div>
            )}
        </div>
    )
}
