import { Action, ActionCreator } from 'redux'
export const REQUEST_ANNOUNCEMENT = 'REQUEST_ANNOUNCEMENT'
export const GET_ANNOUNCEMENT = 'GET_ANNOUNCEMENT'
export const REQUEST_ANNOUNCEMENT_LATEST = 'REQUEST_ANNOUNCEMENT_LATEST'
export const GET_ANNOUNCEMENT_LATEST = 'GET_ANNOUNCEMENT_LATEST'
export const CLEAN_ANNOUNCEMENT_LATEST = 'CLEAN_ANNOUNCEMENT_LATEST'

export type AnnouncementItem = {
  id: number,
  category: 'change_log',
  title: string,
  enabled_at?: string,
  created_at: string,
  content: string
}

export type RequestAnnouncementAction = Action<typeof REQUEST_ANNOUNCEMENT>
export type CleanAnnouncementAction = Action<typeof CLEAN_ANNOUNCEMENT_LATEST>

interface GetAnnouncementAction extends Action<typeof GET_ANNOUNCEMENT> {
  data: AnnouncementItem[];
}

export type RequestAnnouncementLatestAction = Action<typeof REQUEST_ANNOUNCEMENT_LATEST>

interface GetAnnouncementLatestAction extends Action<typeof GET_ANNOUNCEMENT_LATEST> {
  data: AnnouncementItem;
}

export type AnnouncementAction = GetAnnouncementAction | GetAnnouncementLatestAction | CleanAnnouncementAction

export const requestChangelog: ActionCreator<RequestAnnouncementAction> = args => ({
  type: REQUEST_ANNOUNCEMENT,
  args
})

export const getChanglog: ActionCreator<GetAnnouncementAction> = data => ({
  type: GET_ANNOUNCEMENT,
  data
})

export const requestAnnouncement: ActionCreator<RequestAnnouncementLatestAction> = args => ({
  type: REQUEST_ANNOUNCEMENT_LATEST,
  args
})

export const getAnnouncement: ActionCreator<GetAnnouncementLatestAction> = data => ({
  type: GET_ANNOUNCEMENT_LATEST,
  data
})
