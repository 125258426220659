import {
    OrgAction,
    GET_ORG_LIST_V3,
    OrgObj,
    GET_ORG_USERS_V3,
    UserObj,
    GET_ORG_HISTORIES_V3,
    HisObj,
    GET_ORG_DETAIL_V3,
    GET_NEW_ORG_USER_V3,
    GET_DELETE_ORG_USER_V3,
    GET_UPDATE_ORG_USER_V3
} from 'actions/V3/org';

export type OrgState = {
  list?: OrgObj[];
  users?: UserObj[];
  histories?: HisObj[];
  detail?: OrgObj;
};

export default function Org(
    state: OrgState = {
    },
    action: OrgAction
) {
    switch (action.type) {
    case GET_ORG_LIST_V3:
        return {
            list: action.orgs
        };
    case GET_ORG_USERS_V3:
        return {
            ...state,
            users: action.users
        };
    case GET_ORG_HISTORIES_V3:
        return {
            ...state,
            histories: action.histories
        };
    case GET_ORG_DETAIL_V3:
        return {
            ...state,
            detail: action.org
        };
    case GET_NEW_ORG_USER_V3:
        const users = state.users || [];
        users.push(action.user);
        return {
            ...state,
            users
        };
    case GET_DELETE_ORG_USER_V3:
        if (state.users) {
            return {
                ...state,
                users: state.users.filter(u => u.id !== action.userDel.id)
            };
        }
        return state;
    case GET_UPDATE_ORG_USER_V3:
        if (state.users) {
            const newUsers = state.users.slice(0).map(e => {
                if (e.id === action.user.id) {
                    e.permission = action.user.permission;
                }
                return e;
            });
            return {
                ...state,
                users: newUsers
            };
        }
        return state;
    default:
        return state;
    }
};
