import { Action, ActionCreator } from 'redux'

export const REQUEST_NOTIFICATION = 'REQUEST_NOTIFICATION'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'

export type NotificationObj = {
  content: string,
  data_channel: string,
  data_channel_name: string,
  id: number,
  indicator_id: number,
  location_id: number,
  location_name: string,
  reading_time: string,
  reading_value: number,
  monitor_id: number,
  station_id: number,
  resolved_at: string,
  status: string,
  title: string,
  type: NotificationType,
  workspace_id: number,
  workspace_name: string,
  updated_at: string
}

export interface RequestNotificationAction extends Action<typeof REQUEST_NOTIFICATION> {
  args: any;
}

interface GetNotificationAction extends Action<typeof GET_NOTIFICATION> {
  notifications: NotificationObj[];
  meta: Meta;
  query: any;
}

export type NotificationAction = GetNotificationAction

export const requestNotifications: ActionCreator<RequestNotificationAction> = args => ({
  type: REQUEST_NOTIFICATION,
  args
})

export const getNotifications: ActionCreator<GetNotificationAction> = (notifications, meta, query) => ({
  type: GET_NOTIFICATION,
  notifications,
  meta,
  query
})
