import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import rootReducer, { GlobalState } from 'reducers';
import { applyMiddleware, compose, createStore, Store } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'sagas';

export function configureStore(history: History) {
    const sagaMiddleware = createSagaMiddleware();
    const loggerMiddleware = createLogger();
    const middlewares = [sagaMiddleware, routerMiddleware(history)];

    if (process.env.NODE_ENV === 'development') {
      middlewares.push(loggerMiddleware);
    }

    const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const store: Store<GlobalState> = createStore(
        rootReducer(history),
        composeEnhancers(applyMiddleware(...middlewares))
    );

    sagaMiddleware.run(rootSaga);

    return store;
}
