import * as React from 'react'
import CustomSelect from 'components/Custom/Select'
import { Button, Checkbox, Col, Collapse, Icon, Input, InputNumber, Radio, Row, Slider, Switch, Tabs, Upload } from 'antd'
import { t } from 'i18next'
import Cloud from '-!svg-react-loader!images/svg/cloud.svg'
import { DraggerProps } from 'antd/lib/upload'

const TabPane = Tabs.TabPane
const Panel = Collapse.Panel
const Dragger: React.SFC<DraggerProps> = Upload.Dragger

const MockSetting: React.SFC<{ close: () => void }> = React.memo(props => (
  <div className='body'>
    <Tabs defaultActiveKey='1' animated={{ inkBar: true, tabPane: false }}>
      <TabPane tab={t('kiosk.tab content')} key='1'>
        <div className='unit-of-type'>
          <span className='label'>{t('Units of Type')}:</span>
          <Radio.Group size='small' defaultValue='a' disabled={true} buttonStyle='solid'>
            <Radio.Button value='a'>{t('kiosk.Concentration')}</Radio.Button>
            <Radio.Button value='b'>AQI</Radio.Button>
          </Radio.Group>
          <div className='help-hint'>({t('Units of Type hint')})</div>
        </div>
        <Collapse accordion={true} bordered={false} defaultActiveKey={['average']}>
          <Panel
            header={t('Location Average')}
            key='average'
            extra={
              <div className='kiosk-collection-hidden'>
                <span className='desc'>{t('Visible')}</span>
                <Switch checked={false} disabled={true} />
              </div>
            }
          >
            <div className='data-channel-settings'>
              <div className='sm-label'>{t('Indicator')}</div>
              <Collapse accordion={true}>
                <Panel
                  key='key'
                  header={
                    <span>
                      <span className='formula-label'>F</span>
                    </span>
                  }
                  extra={
                    <Switch
                      checkedChildren={<Icon type='check' />}
                      unCheckedChildren={<Icon type='close' />}
                      checked={true}
                      disabled={true}
                    />
                  }
                >
                  <div className='dc-identifier'>
                    <span className='cell-label'>UUID:</span>
                  </div>
                  <div className='data-channel-advance'>
                    <div className='data-formula'>
                      <div className='cell-label'>
                        <span>{t('Formula')}</span>
                        <a
                          href='https://blog.qlear.io/2019/04/25/How-to-switch-between-different-units.html'
                          target='blank'
                          className='help-link'
                        >
                          <i className='iconfont icon-help' />
                        </a>
                      </div>
                      <Input disabled={true} />
                    </div>
                    <div className='unit-group'>
                      <div className='cell-label'>{t('UNITS')}</div>
                      <CustomSelect underline={false} className='data-channel-unit' dropdownMatchSelectWidth={true} disabled={true} />
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </div>
            <div className='primary-indicator-settings'>
              <div className='sm-label'>{t('Primary Data Channel')}</div>
              <CustomSelect underline={false} className='primary-indicator' value='PM2.5' dropdownMatchSelectWidth={true} disabled={true} />
            </div>
          </Panel>
        </Collapse>
      </TabPane>
      <TabPane tab={t('kiosk.tab web theme')} key='2'>
        <div className='body'>
          <div className='location_detail-setting-row' style={{ marginBottom: 0 }}>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('Background')}</div>
            </div>

            <div className='location_detail-setting-row_right'>
              <Radio.Group value='image' buttonStyle='solid' size='small' disabled={true}>
                <Radio.Button value='image'>{t('Image')}</Radio.Button>
                <Radio.Button value='video'>{t('Video')}</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className='upload-bg'>
            <p className='small_text' style={{ marginBottom: 10, marginTop: 10 }}>
              {t('kiosk.Background size')}
            </p>
            <Dragger accept='image/jpeg,image/png,image/jpg' showUploadList={false} disabled={true}>
              <p className='ant-upload-text'>
                <Cloud className='register-add_location-cloud' />
                {t('Drop files')}
                <a className='accent_text'>{t('browse')}</a>
              </p>
            </Dragger>
            <div className='check-remove'>
              <Checkbox checked={false} disabled={true}>
                {t('kiosk.Remove Image')}
              </Checkbox>
            </div>
          </div>

          <div className='upload-logo'>
            <div className='label'>{t('Logo')}</div>
            <Dragger accept='image/jpeg,image/png,image/jpg' showUploadList={false} disabled={true}>
              <p className='ant-upload-text'>
                <Cloud className='register-add_location-cloud' />
                {t('Drop files')}
                <a className='accent_text'>{t('browse')}</a>
              </p>
            </Dragger>

            <div className='check-remove'>
              <Checkbox checked={false} disabled={true}>
                {t('kiosk.Remove Logo')}
              </Checkbox>
            </div>
          </div>

          <div className='location_detail-setting-row'>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('kiosk.theme color')}</div>
            </div>

            <div className='location_detail-setting-row_right'>
              <div className='kiosk-color-swatch'>
                <div className='kiosk-color-block' style={{ backgroundColor: window.PRIMARY_COLOR }} />
              </div>
            </div>
          </div>
          <p className='small_text kiosk-des'>{t('kiosk.theme color des')}</p>
          <div className='location_detail-setting-row'>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('kiosk.font color')}</div>
            </div>

            <div className='location_detail-setting-row_right'>
              <div className='kiosk-color-swatch'>
                <div className='kiosk-color-block' style={{ backgroundColor: '#ffffff' }} />
              </div>
            </div>
          </div>
          <div className='location_detail-setting-row'>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('kiosk.font setting')}</div>
            </div>

            <div className='location_detail-setting-row_right'>
              <CustomSelect
                underline={false}
                className='primary-indicator'
                dropdownMatchSelectWidth={true}
                allowClear={true}
                disabled={true}
              />
            </div>
          </div>

          <div className='location_detail-setting-row'>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('kiosk.data panel position')}</div>
            </div>

            <div className='location_detail-setting-row_right'>
              <Radio.Group value='left' disabled={true} buttonStyle='solid' size='small'>
                <Radio.Button value='left'>{t('kiosk.left')}</Radio.Button>
                <Radio.Button value='right'>{t('kiosk.right')}</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className='location_detail-setting-row'>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('kiosk.data panel opacity')}</div>
            </div>

            <div className='location_detail-setting-row_right w200'>
              <Row>
                <Col span={10}>
                  <Slider min={0} max={1} disabled={true} value={1} step={0.1} />
                </Col>
                <Col span={4}>
                  <InputNumber min={0} max={1} style={{ marginLeft: 16 }} step={0.1} value={1} disabled={true} />
                </Col>
              </Row>
            </div>
          </div>
          <div className='location_detail-setting-row'>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('kiosk.background fullscreen')}</div>
            </div>
            <div className='location_detail-setting-row_right'>
              <Switch checked={true} disabled={true} />
            </div>
          </div>
          <p className='small_text kiosk-des'>{t('kiosk.background fullscreen des')}</p>
        </div>
      </TabPane>

      <TabPane tab={t('kiosk.tab app theme')} key='3'>
        <div className='body'>
          <div className='location_detail-setting-row' style={{ marginBottom: 0 }}>
            <div className='location_detail-setting-row_left'>
              <div className='label'>{t('Background')}</div>
            </div>
          </div>
          <div className='upload-bg'>
            <p className='small_text' style={{ marginBottom: 10, marginTop: 10 }}>
              {t('kiosk.image only')}
            </p>
            <Dragger accept='image/jpeg,image/png,image/jpg' showUploadList={false} disabled={true}>
              <p className='ant-upload-text'>
                <Cloud className='register-add_location-cloud' />
                {t('Drop files')}
                <a className='accent_text'>{t('browse')}</a>
              </p>
            </Dragger>
          </div>

          <div className='upload-logo'>
            <div className='label'>{t('Logo')}</div>
            <Dragger accept='image/jpeg,image/png,image/jpg' showUploadList={false} disabled={true}>
              <p className='ant-upload-text'>
                <Cloud className='register-add_location-cloud' />
                {t('Drop files')}
                <a className='accent_text'>{t('browse')}</a>
              </p>
            </Dragger>
          </div>
        </div>
      </TabPane>
      <TabPane tab={t('kiosk.tab public')} key='4'>
        <div className='location_detail-setting-row is-working_hours'>
          <div className='location_detail-setting-row_left'>
            <div className='label'>{t('locationOverview.Locked')}</div>
            <p className='small_text'>{t('locationOverview.if locked')}</p>

            <div style={{ marginTop: 16 }}>
              <p className='register-group-label'>{t('password')}</p>
              <Input disabled={true} />
            </div>
          </div>

          <div className='location_detail-setting-row_right'>
            <Switch checked={false} disabled={true} />
          </div>
        </div>
      </TabPane>
    </Tabs>

    <div className='footer'>
      <Button type='primary' ghost={true} onClick={props.close}>
        {t('Cancel')}
      </Button>
      <Button type='primary' disabled={true}>
        {t('Save')}
      </Button>
    </div>
  </div>
))

export default MockSetting
