import ClockIcon from '-!svg-react-loader!images/svg/clock.svg'
import { Report, requestReportList, RequestReportListAction } from 'actions/V3/report'
import { DatePicker, Icon, Popconfirm, Tooltip } from 'antd'
import classname from 'classnames'
import Button from 'components/Custom/Button'
import Table from 'components/Custom/Table'
import Nodata from 'components/NoData'
import { t } from 'i18next'
import { pick } from 'lodash'
import qs from 'qs'
import * as React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { apiV3 } from 'utils/api'
import { reportStatusText } from 'utils/constant'
import { ReportStatus } from 'utils/enums'
import { queryMerge } from 'utils/query'

type StateProps = {
  list: Report[],
  meta?: Meta,
  query: any
}

type State = Readonly<{
  period: string[]
}>

type P = StateProps & DispatchProp<RequestReportListAction> & RouteComponentProps<{ id: string }>

class ReportList extends React.PureComponent<P, State> {
  state: State = {
    period: []
  }
  componentDidMount() {
    const {
      location: { search }
    } = this.props

    const query = pick(qs.parse(search.slice(1)), ['page'])

    this.fetchReportList({
      page: +(query.page || 0)
    })
  }

  fetchReportList = (query = {}) => {
    const {
      match: {
        params: { id }
      },
      dispatch
    } = this.props

    dispatch(requestReportList({ workspace_id: id, ...query }))
  }

  filterRequest = () => {
    const { period } = this.state

    const query = !!period.length ? { begin_date: period[0], end_date: period[1] } : {}
    this.fetchReportList(query)
  }

  onChangePeriod = time => {
    this.setState(
      {
        period: [time[0].format('YYYY-MM-DD'), time[1].format('YYYY-MM-DD')]
      },
      this.filterRequest
    )
  }

  cancelPeriod = () => {
    this.setState(
      {
        period: []
      },
      this.filterRequest
    )
  }

  statusTextColor = (status: number) => {
    switch (status) {
      case ReportStatus.Pending:
      case ReportStatus.GeneratingReport:
      case ReportStatus.GeneratingPDF:
        return <b className='grey_text'>{reportStatusText(status)}</b>
      case ReportStatus.DoneforReport:
      case ReportStatus.DoneforPDF:
        return <b className='accent_text'>{reportStatusText(status)}</b>
      case ReportStatus.ReportFail:
      case ReportStatus.PDFFail:
        return <b className='danger_text'>{reportStatusText(status)}</b>
      default:
        return reportStatusText(status)
    }
  }

  geneTableColumns = () => {
    return [
      {
        title: t('Code'),
        dataIndex: 'code',
        render: text => <p className='small_text'>{text}</p>
      },
      {
        title: t('Location'),
        dataIndex: 'location',
        render: text => <span className='accent_text'>{text}</span>
      },
      {
        title: t('Period'),
        dataIndex: 'period',
        width: 190,
        render: text => <p className='small_text'>{text}</p>
      },
      {
        title: t('Status'),
        dataIndex: 'statusText',
        render: (_, record) => this.statusTextColor(record.status),
        width: 100
      },
      {
        dataIndex: 'preview',
        title: t('Report.View'),
        width: 50,
        className: 'report-table-icon',
        render: (_, record) => (
          <div
            className={classname('report-table-icon is-primary', {
              'is-disable': record.status !== ReportStatus.DoneforReport
            })}
            onClick={record.status !== ReportStatus.DoneforReport ? undefined : () => this.viewReport(record.id)}
          >
            <Tooltip title={t('Report.View')}>
              <Icon type='eye' />
            </Tooltip>
          </div>
        )
      },
      {
        dataIndex: 'download',
        width: 50,
        title: t('Report.Print'),
        className: 'report-table-icon',
        render: (_, record) => (
          <div
            className={classname('report-table-icon is-primary', {
              'is-disable': record.status !== ReportStatus.DoneforReport
            })}
            onClick={record.status !== ReportStatus.DoneforReport ? undefined : () => window.open(`/reportpdf/${record.id}`)}
          >
            <Tooltip title={t('Report.Print')}>
              <i className='icon-dayin iconfont' />
            </Tooltip>
          </div>
        )
      },
      {
        dataIndex: 'delete',
        width: 50,
        title: t('Report.Delete'),
        className: 'report-table-icon is-danger',
        render: (_, record) => (
          <div className='report-table-icon'>
            <Popconfirm title={t('Report.Are you sure delete this Report')} onConfirm={() => this.delReport(record.id)} okType='danger'>
              <Tooltip title={t('Report.Delete')}>
                <i className='iconfont icon-shanchu' />
              </Tooltip>
            </Popconfirm>
          </div>
        )
      }
    ]
  }

  getData = () => {
    const { list } = this.props
    return list.map((item, index) => ({
      code: item.code,
      location: item.location_name,
      period: `${item.begin_date.replace(/-/g, '.')}-${item.end_date.replace(/-/g, '.')}`,
      status: item.report_status,
      id: item.id,
      key: `report-list-${index}`
    }))
  }

  selectPage = page => {
    const {
      location: { search, pathname },
      history,
      query: q
    } = this.props
    const query = queryMerge({
      search,
      query: {
        page: page
      },
      delKeys: page === 1 ? ['page'] : []
    })
    history.push({
      pathname,
      search: query
    })
    this.fetchReportList({ ...q, page })
  }

  delReport = async id => {
    await apiV3(`/reports/${id}`, 'delete')
    this.fetchReportList()
  }

  downloadPDF = id => {
    apiV3(`/reports/${id}/download`)
  }

  viewReport = rid => {
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props
    history.push(`/workspaces/${id}/reports/${rid}`)
  }

  pagination = () => {
    const { meta } = this.props
    return meta
      ? {
        hideOnSinglePage: true,
        showQuickJumper: true,
        onChange: this.selectPage,
        current: meta.current_page,
        defaultPageSize: 20,
        total: meta.total_count
      }
      : {}
  }

  render() {
    const {
      history,
      match: {
        params: { id }
      },
      meta
    } = this.props

    const { period } = this.state

    if (!meta) {
      return null
    }

    return (
      <>
        <div className='report-title-button'>
          <Button customType='dark' onClick={() => history.push(`/workspaces/${id}/reports/template`)}>
            {t('Report.Request Report')}
          </Button>
        </div>

        <div className='report-filter'>
          <div className='noti-options-filter_item'>
            <ClockIcon />
            <span className='small_text grey_text noti-options-filter_text'>{t('Period')}</span>
            <DatePicker.RangePicker
              style={{ width: '80px' }}
              showTime={{ format: 'HH:mm' }}
              format='YYYY-MM-DD'
              placeholder={['Start Time', 'End Time']}
              onOk={this.onChangePeriod}
            />
          </div>
          {!!period.length && (
            <div className='noti-datetime-selected' onClick={this.cancelPeriod}>
              <Icon type='close' />
              <span>
                {period[0]} - {period[1]}
              </span>
            </div>
          )}
        </div>

        <div className='home-table-wrap'>
          {this.getData().length ? (
            <Table
              type='light'
              pagination={this.pagination()}
              columns={this.geneTableColumns()}
              dataSource={this.getData()}
              size='default'
            />
          ) : (
            <Nodata tip=' ' />
          )}
        </div>
      </>
    )
  }
}

const mapState: MapState<StateProps> = ({ V3: { report } }) => ({
  list: report.list,
  meta: report.listMeta,
  query: report.listQuery
})

export default withRouter(connect(mapState)(ReportList))
