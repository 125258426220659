import * as React from 'react';
import api from 'utils/api';
import { Badge, Popover, Icon as AntIcon, Tooltip } from 'antd';
import {
    NotificationObj,
    RequestNotificationAction,
    requestNotifications
} from 'actions/V2/notification';
import { DispatchProp } from 'react-redux';
import { connect } from 'react-redux';
import { notificationDate } from 'utils/helper';
import { notificationIcon, translations } from 'utils/constant';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { t } from 'i18next';
import moment from 'moment';

type Count = {
  count: number;
  last_read_time: string;
};

const fiveMin = 1000 * 60 * 5;
let timer: number;

type StateProps = {
  notifications: NotificationObj[];
  notificationMeta?: Meta;
};

export type P = StateProps &
  DispatchProp<RequestNotificationAction> &
  RouteComponentProps<{id: string}>;

const Icon: React.SFC<P> = React.memo(props => {
    const { dispatch, notificationMeta, notifications, history } = props;
    const [count, setCount] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [showPop, setPop] = React.useState(false);
    const [lastTime, setLastTime] = React.useState('');
    const fetchCount = async (query = {}) => {
        setLoading(true);
        setLastTime(moment().format());
        const { data } = await api<{ data: Count }>(
            '/notifications/unread_count',
            'get',
            query
        );
        setCount(data.count);
        setLoading(false);
    };

    const changeVisiable = (visible: boolean) => {
        const workspaceId = props.match.params.id;
        if (visible) {
            if (!notificationMeta) {
                dispatch(
                    requestNotifications({
                        show: false,
                        size: 10,
                        page: 1,
                        workspace_id: workspaceId
                    })
                );
            }

            setCount(0);
            api('/notifications/event', 'post');
        }
        setPop(visible);
    };

    const content = () => {
        if (!notificationMeta) {
            return (
                <div className='noti-empty-tip'>
                    <AntIcon
                        type='loading'
                        style={{
                            color: window.PRIMARY_COLOR
                        }}
                    />
                </div>
            );
        }
        return notifications.length ? (
            notifications.map(record => {
                return (
                    <div
                        className='noti-item-wrap'
                        key={record.id}
                        onClick={() => {
                            history.push('/notification');
                            setPop(false);
                        }}
                    >
                        <div className='noti-item-icon'>
                            <Tooltip title={translations[record.type]}>
                                <img
                                    src={notificationIcon[record.type]}
                                    className='noti-tableIcon'
                                    alt={translations[record.type]}
                                />
                            </Tooltip>
                        </div>
                        <div className='noti-item-inner-content'>
                            <div className='noti-item-ws'>{record.workspace_name}</div>
                            {record.type !== 'data_source' ? (
                                <div className='noti-message'>
                                    <b>{translations[record.status + 'Rule']}</b>
                                    <br />
                                    {t('Notification.content', {
                                        location_name: record.title,
                                        indicator: record.data_channel_name,
                                        content: record.content || '',
                                        status: translations[record.status] || ''
                                    })}
                                </div>
                            ) : (
                                <div>
                                    <b className='noti-item-title'>{record.title}</b>
                                    <span className='noti-rep'>{t('Notification.reported')}</span>
                                    <span className={`noti-status ${record.status}`}>
                                        {translations[record.status]}
                                    </span>
                                </div>
                            )}
                            <i className='noti-date'>
                                {record.reading_time && notificationDate(record.reading_time)}
                            </i>
                            {!!record.resolved_at && (
                                <div className='noti-resolve'>
                                    <div className='noti-resolved'>
                                        <i className='material-icons'>check_circle</i>
                                        {t('Notification.Resolved')}
                                    </div>
                                    <i className='noti-date'>
                                        {notificationDate(record.resolved_at)}
                                    </i>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })
        ) : (
            <div className='noti-empty-tip'>{t('No data')}</div>
        );
    };

    React.useEffect(() => {
        fetchCount();
    }, []);

    React.useEffect(() => {
        if (loading) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = window.setTimeout(() => {
                fetchCount({
                    interval: true,
                    last_message_timestamp: lastTime
                });
            }, fiveMin);
        }
    }, [lastTime, loading]);

    return loading ? (
        <AntIcon type='loading' />
    ) : (
        <Badge count={count} dot={true} offset={[-10, 0]}>
            <Popover
                overlayClassName='header-notification-overlay'
                content={content()}
                title={
                    <div className='noti-title'>
                        <b>{t('Notifications')}</b>
                        <Link to='/notification' onClick={() => setPop(false)}>
                            {t('See All')}
                        </Link>
                    </div>
                }
                getPopupContainer={trigger => trigger!.parentElement!}
                placement='bottom'
                trigger='click'
                visible={showPop}
                onVisibleChange={changeVisiable}
            >
                <i className='material-icons md-18' style={{ color: 'white' }}>
          notifications
                </i>
            </Popover>
        </Badge>
    );
});

const mapStateToProps: MapState<StateProps> = ({ V2: { notifications } }) => ({
    notifications: notifications.data,
    notificationMeta: notifications.meta
});

export default withRouter(connect(mapStateToProps)(Icon));
