import * as actions from 'actions/V3/report'

export type ReportState = {
  list: actions.Report[],
  detail?: actions.ReportDetail,
  listMeta?: Meta,
  listQuery?: any,
  template: actions.Template[],
  templateMeta?: Meta,
  templateQuery?: any
}

export default function Report(
  state: ReportState = {
    list: [],
    template: []
  },
  action: actions.ReportAction
) {
  switch (action.type) {
    case actions.GET_REPORT_LIST_V3:
      return {
        ...state,
        list: action.data,
        listMeta: action.meta,
        listQuery: action.query
      }
    case actions.GET_REPORT_TEMPLATE_V3:
      return {
        ...state,
        template: action.data,
        templateMeta: action.meta,
        templateQuery: action.query
      }
    case actions.GET_REPORT_DETAIL_V3:
      return {
        ...state,
        detail: action.data
      }
    default:
      return state
  }
}
