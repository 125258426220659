import {GET_ADMIN_PARAMS_BANNER, GetAdminParamsBannerAction} from 'actions/V3/adminParams'

export type Banner = {
  show_technical_difficulties_banner: boolean
}

export type AdminParamsState = {
  banner?: Banner
}

export default function AdminParams(state: AdminParamsState = {}, action: GetAdminParamsBannerAction): AdminParamsState {
  switch (action.type) {
    case GET_ADMIN_PARAMS_BANNER:
      const banner: Banner = {
        show_technical_difficulties_banner: action.data.show_technical_difficulties_banner === 'true'
      }
      return {
        ...state,
        banner
      }
    default:
      return state
  }
}
