import { t } from 'i18next'
import * as React from 'react'
import { ReactElement } from 'react'
import './index.css'


const NotificationBanner = (): ReactElement => {


  return (
    <div className='notification-banner'>
      <i className='material-icons notification-banner-warning'>warning</i>
      <div className='notification-banner-text'>
        {t('NotificationBanner.Technical Difficulties')}
      </div>
    </div>
  )
}

export default NotificationBanner


