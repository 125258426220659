import * as actions from 'actions/V3/locationList'

export type LocationListState = {
  data: actions.LocationItem[],
  meta?: Meta,
  query: any
}

export default function LocationList (
  state: LocationListState = {
    data: [],
    query: {}
  },
  action: actions.LocationListV3Action
) {
  switch (action.type) {
    case actions.GET_LOCATION_LIST_V3:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        query: action.query
      }
    case actions.GET_LOCATION_LIST_AVAILABLE_V3:
      return {
        ...state,
        data: action.data
      }
    default:
      return state
  }
}
