import { t } from 'i18next';
import { Doughnut } from 'react-chartjs-2';
import { covidDoughnutPlugin } from './CovidDoughnutPlugin';

const labelData: { threshold: number, label: string }[] = [
  { threshold: 90, label: t('kioskV3.Excellent') },
  { threshold: 80, label: t('kioskV3.Good') },
  { threshold: 70, label: t('kioskV3.Fair') },
  { threshold: 60, label: t('kioskV3.Satisfactory') },
  { threshold: 50, label: t('kioskV3.Unsatisfactory') },
  { threshold: 0, label: t('kioskV3.Poor') },
  { threshold: -1, label: '' }
];

const quartersData: { threshold: number, values: number[] }[] = [
  { threshold: 98, values: [1, 1.5, 0, 0.3] },
  { threshold: 94, values: [1, 1.5, 0, 0.24] },
  { threshold: 90, values: [1, 1.5, 0, 0.18] },
  { threshold: 85, values: [1, 1.5, 0, 0.12] },
  { threshold: 80, values: [1, 1.5, 0, 0.06] },
  { threshold: 75, values: [1, 1.5, 0, 0] },
  { threshold: 70, values: [1, 1.5, -0.2] },
  { threshold: 65, values: [1, 1.5, -0.4] },
  { threshold: 60, values: [1, 1.5, -0.8] },
  { threshold: 55, values: [1, 1.5, -1.2] },
  { threshold: 50, values: [1, 1.5, -1.5] },
  { threshold: 45, values: [1, 1.46] },
  { threshold: 40, values: [1, 1.38] },
  { threshold: 35, values: [1, 1.3] },
  { threshold: 30, values: [1.22] },
  { threshold: 25, values: [1.12] },
  { threshold: 20, values: [1.02] },
  { threshold: 15, values: [0.92] },
  { threshold: 10, values: [0.82] },
  { threshold: -1, values: [0.72] }
];

export default function CovidDoughnutChart({ covidIndicators }) {

  let covidIndex: number = 0
  if (covidIndicators.covid_index_data) {
    covidIndex = Math.floor(covidIndicators.covid_index_data.optimization_index || covidIndicators.covid_index_data.covid_index)
  }

  if (!covidIndex) return <div />

  const chartQuarters = quartersData.find(d => covidIndex > d.threshold)!.values
  const covidIndicator = labelData.find(l => covidIndex > l.threshold)!.label;

  const doughnutChartData = { datasets: [{ data: [] }] };
  const doughnutChartOptions = {
    plugins: {
      bill: false,
      // data for our covidDoughnutPlugin
      covidDoughnut: {
        covidIndex,
        covidIndicator,
        chartQuarters
      }
    },
    legend: { display: false }
  };

  return (
    <Doughnut
      data={doughnutChartData}
      options={doughnutChartOptions}
      height={230}
      plugins={[covidDoughnutPlugin] as any}
    />
  );
}
