import { changeModal, ModalChangeAction } from 'actions/uiState'
import { WorkspaceItem } from 'actions/V2/userShip'
import {
  Brand,
  DatasourceObj,
  RequestDatasourceBrandAction,
  requestDatasourceBrands,
  requestDatasources,
  RequestDatasourcesAction,
  REQUEST_DATA_SOURCES_V3
} from 'actions/V3/datasources'
import { RefreshPermission, refreshPermission } from 'actions/V3/permission'
import { Form, Icon, Input, Select } from 'antd'
import { FormComponentProps } from 'antd/lib/form'
import Button from 'components/Custom/Button'
import { t } from 'i18next'
import * as React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import cache from 'sagas/cache'
import { apiV3 } from 'utils/api'
import NoDatasourceImg from '../../images/NoDatasourceImg.png'
import './index.css'
const CUSTOM_BRANDS = ['Kaiterra', 'Tongdy', 'DST', 'QLEAR', 'SourceOne', 'Airveda']

export type ButtonAction = () => void
type State = Readonly<{
  dataSourceForm: {
    identifier: string,
    workspace_id?: number,
    brand_id?: number,
    model_id?: number
  },
  created?: DatasourceObj,
  step: number,
  currentModel?: {
    id: number,
    name: string,
    image: string,
    type: string
  },
  errorMsg?: string,
  showOtherBrands?: boolean,
  openDropdown?: boolean
}>

interface ApiResponse {
  data: any;
}

type StateProps = {
  userShip: WorkspaceItem[],
  workspaceID?: string,
  brands: Brand[]
}

type P = DispatchProp<RequestDatasourceBrandAction | ModalChangeAction | RefreshPermission | RequestDatasourcesAction> &
  FormComponentProps &
  StateProps &
  RouteComponentProps<{ id: string }>

class AddDatasource extends React.Component<P, State> {
  state: State = {
    step: 1,
    dataSourceForm: {
      identifier: ''
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(requestDatasourceBrands())
  }

  fetchDeviceInfo = (identifier, workspace_id, then) => {
    apiV3('/data_sources/check', 'get', { identifier, workspace_id }).then((resp: any) => {
      // It exists and is unavailable. It has been used by other customers. You need to unbind it before you can bind it to a new customer again
      const alreadyUsedState = 1
      if (resp.data.state === alreadyUsedState) {
        then(false)
      } else {
        then(resp.data)
      }
    })
  }

  submitForm = e => {
    const {
      form,
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    e.preventDefault()
    form.validateFields((err, formData) => {
      if (!err) {
        try {
          this.fetchDeviceInfo(formData.identifier, +id, async result => {
            if (result) {
              cache.cleanCache(REQUEST_DATA_SOURCES_V3)
              const response: ApiResponse = await apiV3('/data_sources/', 'post', {
                data_source: Object.assign({}, formData, {
                  workspace_id: id
                })
              })
              const { data } = response
              this.setState({
                step: 3,
                created: data
              })
              dispatch(
                requestDatasources({
                  workspace_id: id
                })
              )
              dispatch(
                refreshPermission({
                  show: false
                })
              )
            } else {
              this.setState({
                errorMsg: `${t('Device')} ${formData.identifier} ${t('Register.Has been used')}`
              })
            }
          })
        } catch (error) {}
      }
    })
  }

  renderStep1 = () => {
    const {
      brands,
      form: { getFieldDecorator, setFieldsValue }
    } = this.props
    const { dataSourceForm, currentModel, errorMsg, showOtherBrands } = this.state

    // 对 brands 进行优先级排序
    const arrayHasNoElements = 0
    const otherBrands = brands.filter(b => CUSTOM_BRANDS.indexOf(b.name) < arrayHasNoElements)
    const firstBrands: any = []
    for (const b of CUSTOM_BRANDS) {
      const target = brands.find(obj => obj.name === b)
      if (target) {
        firstBrands.push(target)
      }
    }
    const brandsForSelect = showOtherBrands ? firstBrands.concat(otherBrands) : firstBrands

    return (
      <Form onSubmit={this.submitForm} layout='vertical' className='register-body is-datasource'>
        <div className='ds-img-wrap'>
          <img src={(currentModel && currentModel.image) || NoDatasourceImg} alt='' />
          {currentModel ? (
            <div className='ds-tip'>
              <span>{t('datasource.type')}: </span>
              <span className='ds-type'>{currentModel.type === 'air' ? t('Air') : t('Electricity')}</span>
            </div>
          ) : (
            <div className='ds-tip'>{t('datasource.img tip')}</div>
          )}
        </div>
        <Form.Item label={t('Register.Brand')}>
          {getFieldDecorator('brand_id', {
            rules: [
              {
                required: true,
                message: t('Please select brand!')
              }
            ]
          })(
            <Select
              className='register-device-brand-selector'
              placeholder={t('Select brand')}
              onChange={e => {
                this.updateFrom('brand_id', +e)
                setFieldsValue({ model_id: '' })
              }}
            >
              {brandsForSelect.map((item, i) => (
                <Select.Option key={i} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
              {!showOtherBrands && (
                <Select.Option key={-1} value={0} disabled={true}>
                  <a
                    className='see-more-brands-link'
                    onClick={e => {
                      e.preventDefault()
                      e.stopPropagation()
                      this.setState({
                        showOtherBrands: true
                      })
                    }}
                  >
                    See All
                  </a>
                </Select.Option>
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('Register.Model')}>
          {getFieldDecorator('model_id', {
            rules: [
              {
                required: true,
                message: t('Please select model!')
              }
            ]
          })(
            <Select
              className='register-device-brand-selector'
              disabled={!dataSourceForm.brand_id}
              onChange={e => {
                const list = this.getModelsByBrandId(dataSourceForm.brand_id)
                const obj = list.find(m => m.id === e)
                if (obj) {
                  this.setState({
                    currentModel: obj
                  })
                }
              }}
              placeholder={t('Select model')}
            >
              {this.getModelsByBrandId(dataSourceForm.brand_id).map((item, i) => (
                <Select.Option key={i} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
        <Form.Item label={t('Register.UUID')}>
          {getFieldDecorator('identifier', {
            rules: [
              {
                required: true,
                message: t('Please enter uuid!')
              }
            ]
          })(
            <div>
              <Input disabled={!dataSourceForm.brand_id} />
              {errorMsg && <div className='error-msg'>{errorMsg}</div>}
            </div>
          )}
        </Form.Item>
        <div className='input-tip'>Example：EM01A3900852</div>
        <div className='register-button_group'>
          <Button
            customType='light'
            block={true}
            onClick={() => {
              this.props.dispatch(changeModal(''))
            }}
          >
            {t('Register.Cancel')}
          </Button>
          <Button customType='dark' block={true} htmlType='submit'>
            {t('Register.Confirm')}
          </Button>
        </div>
      </Form>
    )
  }

  updateFrom = (name, v) => {
    const { dataSourceForm } = this.state
    const data = Object.assign(dataSourceForm, {
      [name]: v
    })
    this.setState({
      dataSourceForm: data
    })
  }

  goToDatasouce = id => {
    const { created } = this.state
    const { workspaceID, history, dispatch } = this.props
    if (created) {
      dispatch(changeModal(''))
      history.push(`/workspaces/${workspaceID}/datasources/${created.id}`)
    }
  }

  renderStep3 = () => {
    // const { toAssignIndicator } = this.props;
    const { created } = this.state
    return (
      <div className='register-device-step3'>
        <Icon className='icon' type='check-circle' />
        <h2>{t('Register Success')}</h2>
        <div className='desc'>
          {t('datasource.register success tip1')}
          {/* <a onClick={toAssignIndicator}>{t('datasource.Assign Indicator')}</a>
          {t('datasource.register success tip2')} */}
        </div>
        {created && (
          <>
            <br />
            <Button customType='light' onClick={this.goToDatasouce}>
              {t('datasource.go to datasource', {
                identifier: created.identifier
              })}
            </Button>
          </>
        )}
      </div>
    )
  }

  getModelsByBrandId = bid => {
    const { brands } = this.props
    const brand = brands.find(e => e.id === bid)
    return brand ? brand.models : []
  }

  renderStep4 = () => {
    const { dataSourceForm } = this.state
    return (
      <div className='register-device-error-tip'>
        <div>{`${t('Device')} ${dataSourceForm.identifier}`}</div>
        {t('Register.Has been used')}
      </div>
    )
  }

  render() {
    const { step } = this.state

    let content
    switch (step) {
      case 1:
        content = this.renderStep1()
        break
      case 3:
        content = this.renderStep3()
        break
      default:
        return null
    }

    return (
      <div>
        {step !== 3 && <h3 className='register-title'>{t('Register.dataSource')}</h3>}
        {content}
      </div>
    )
  }
}

const mapToState: MapState<StateProps> = ({
  V2: { userShip },
  V3: { datasources },
  uiState: {
    siteState: { workspaceID }
  }
}) => ({
  userShip: userShip.data,
  workspaceID,
  brands: datasources.brands || []
})

export default Form.create()(withRouter(connect(mapToState)(AddDatasource)))
