import * as React from 'react'
import NodataImg from '-!svg-react-loader!images/svg/nodata.svg'
import { t } from 'i18next'
import './index.css'

const NoData: React.SFC<{ tip?: string, title?: string }> = React.memo(({ tip, title }) => (
  <div className='nodata-container'>
    <NodataImg />
    <h3 className='nodata-text'>{title || t('noData')}</h3>
    <div className='nodata-tip'>{tip || t('noDataTip')}</div>
  </div>
))

export default NoData
