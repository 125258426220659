import EmptyLocationImg from '-!svg-react-loader!images/svg/Nolocation.svg';
import { changeModal } from 'actions/uiState';
import { requestNotifications } from 'actions/V2/notification';
import { requestActiveLog } from 'actions/V3/activeLog';
import { Col, Popover, Row, Tooltip } from 'antd';
import PieChart from 'components/PieChart';
import { P, State, StateProps } from 'containers/WorkspaceOverview/type';
import { t } from 'i18next';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { notificationIcon, translations } from 'utils/constant';
import { Guard } from 'utils/guard';
import { notificationDate } from 'utils/helper';
import './index.css';

const NEW_WORKSPACE_WELCOME_NOTE_SHOWN = 'NEW_WORKSPACE_WELCOME_NOTE_SHOWN';

@Guard
class WorkspaceOverview extends React.PureComponent<P, State> {
  state: State = {
    showWelcome: true
  };

  componentDidMount() {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.requestLogs(id);
    this.requestNotification(id);
  }

  UNSAFE_componentWillReceiveProps(nextP: P) {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const workspaceId = nextP.match.params.id;
    if (id !== workspaceId) {
      this.requestLogs(workspaceId);
      this.requestNotification(workspaceId);
    }
  }

  requestNotification = id => {
    const { dispatch } = this.props;
    dispatch(
      requestNotifications({
        show: false,
        size: 5,
        resolved: false,
        workspace_id: id,
        page: 1
      })
    );
  };

  GuardHandle_nofications = (rule?: GuardRule, message?: string) => {
    const { notifications } = this.props;
    const disabled = rule && !rule.enable;

    return (
      <div className='workspace-ov-card'>
        <div className='title'>
          <span>{t('Notifications')}</span>
          {!disabled && (
            <a onClick={this.navToNotificationCenter} className='more'>
              {t('header.more')}
            </a>
          )}
        </div>
        <ul>
          {disabled ? (
            message
          ) : !!notifications.length ? (
            notifications.map((record, i) => (
              <li key={'noti-' + i} className='noti-item-card'>
                <div className='noti-item-icon'>
                  <Tooltip title={translations[record.type]}>
                    <img
                      src={notificationIcon[record.type]}
                      className='noti-tableIcon'
                      alt={translations[record.type]}
                    />
                  </Tooltip>
                </div>
                {record.type !== 'data_source' ? (
                  <div className='noti-message'>
                    <b>{translations[record.status + 'Rule']}</b>
                    <br />
                    {t('Notification.content', {
                      location_name: record.title,
                      indicator: record.data_channel_name,
                      content: record.content || '',
                      status: translations[record.status] || ''
                    })}
                  </div>
                ) : (
                  <div>
                    <div className='noti-item-title'>{record.title}</div>
                    <span className='noti-rep'>
                      {t('Notification.reported')}
                    </span>
                    <span className={'noti-status ' + record.status}>
                      {translations[record.status]}
                    </span>
                  </div>
                )}
                <i className='noti-date'>
                  {record.reading_time && notificationDate(record.reading_time)}
                </i>
              </li>
            ))
          ) : (
            <li className='empty-tip'>{t('Notification.Empty Tip')}</li>
          )}
        </ul>
      </div>
    );
  };

  GuardHandle_averagePerformance = (rule?: GuardRule, message?: string) => ({
    enable: rule && rule.enable,
    message
  });

  requestLogs = id => {
    const { dispatch } = this.props;
    dispatch(
      requestActiveLog({
        item_type: 'all',
        workspace_id: id,
        size: 5
      })
    );
  };

  handleCreateLocation = () => {
    this.props.dispatch(changeModal('AddLocation'));
  };

  navToLocationsWithStatus = obj => {
    const {
      match: {
        params: { id }
      },
      history
    } = this.props;
    if (obj.label) {
      history.push({
        pathname: `/workspaces/${id}/locations`,
        search: `?status=${obj.label}`
      });
    }
  };

  navToLocationsWithPerf = perf => {
    const {
      match: {
        params: { id }
      },
      history
    } = this.props;
    if (perf) {
      history.push({
        pathname: `/workspaces/${id}/locations`,
        search: `?performance=${perf}`
      });
    }
  };

  navToNotificationCenter = e => {
    e.preventDefault();
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props;
    history.push({
      pathname: '/notification',
      search: `?ws=${id}`
    });
  };

  checkAllActivity = e => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(changeModal('ActivityLogs'));
  };

  navToDatasource = obj => {
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props;
    if (obj.label) {
      history.push({
        pathname: `/workspaces/${id}/datasources`,
        search: `?status=${obj.label}`
      });
    }
  };

  dismissWelcome = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    localStorage.setItem(NEW_WORKSPACE_WELCOME_NOTE_SHOWN + id, 'true');
    this.setState({
      showWelcome: false
    });
  };

  render() {
    const {
      // locations,
      logs,
      workspaceDetail
    } = this.props;
    const { showWelcome } = this.state;
    if (!workspaceDetail) {
      return null;
    }

    const localWelcomeShown = localStorage.getItem(
      NEW_WORKSPACE_WELCOME_NOTE_SHOWN + workspaceDetail.id
    );

    const { location, performance, data_source } = workspaceDetail.counter || {
      location: {
        total: 0,
        online: 0,
        empty: 0,
        offline: 0,
        'partial-offline': 0
      },
      performance: {
        total: 0,
        good: 0,
        moderate: 0,
        sensitive: 0,
        hazardous: 0,
        very_unhealthy: 0,
        unhealthy: 0,
        empty: 0
      },
      data_source: {
        total: 0,
        online: 0,
        offline: 0
      }
    };
    const locationStatusList = [
      {
        label: t('overview.Online'),
        status: 'online',
        data: location.online,
        color: '#1B6FE2'
      },
      {
        label: t('overview.Empty'),
        status: 'empty',
        data: location.empty,
        color: '#D2E4FF'
      },
      {
        label: t('overview.Partial Offline'),
        status: 'partial-offline',
        data: location['partial-offline'],
        color: '#EF692A'
      },
      {
        label: t('overview.Offline'),
        status: 'offline',
        data: location.offline,
        color: '#CACACA'
      }
    ];
    const locationPerformanceList = [
      {
        desc: t('level.Good'),
        performance: 'good',
        value: performance.good,
        color: '#40C6CC'
      },
      {
        desc: t('level.Moderate'),
        performance: 'moderate',
        value: performance.moderate,
        color: '#F6E19E'
      },
      {
        desc: t('level.Sensitive'),
        performance: 'sensitive',
        value: performance.sensitive,
        color: '#FAAD5B'
      },
      {
        desc: t('level.Unhealthy'),
        performance: 'unhealthy',
        value: performance.unhealthy,
        color: '#F37647'
      },
      {
        desc: t('level.Very Unhealthy'),
        performance: 'very-unhealthy',
        value: performance.very_unhealthy,
        color: '#D93327'
      },
      {
        desc: t('level.Hazardous'),
        performance: 'hazardous',
        value: performance.hazardous,
        color: '#590103'
      }
    ];
    const totalLocations = location.total;

    const showPerformance = this.GuardHandle_averagePerformance();

    return (
      <div className='workspace-ov-content'>
        <div className='workspace-ov-container'>
          {showWelcome &&
            !localWelcomeShown &&
            data_source.total + totalLocations === 0 && (
              <div className='workspace-ov-card welcome-tip-panel'>
                <h1>{t('Welcome to your Workspace Overview')}</h1>
                <div className='welcome-desc'>
                  <div>{t('welcome.workspace1')}</div>
                  {t('welcome.workspace2')}
                </div>
                <div className='dismiss-link' onClick={this.dismissWelcome}>
                  {t('Dismiss')}
                </div>
              </div>
            )}
          <Row gutter={16}>
            <Col xs={{ span: 24 }} sm={{ span: 8 }}>
              <div className='workspace-ov-datasource workspace-ov-card'>
                <div className='label'>{t('Data Source Status')}</div>
                {data_source.total ? (
                  <div className='panel-info'>
                    <div className='graph'>
                      <PieChart
                        collection={[
                          {
                            label: t('datasource.Online'),
                            color: '#1B6FE2',
                            status: 'online',
                            data: data_source.online
                          },
                          {
                            label: t('datasource.Offline'),
                            color: '#CACACA',
                            status: 'offline',
                            data: data_source.offline
                          }
                        ]}
                        defaultActiveIdx={1}
                        onClick={this.navToDatasource}
                      />
                    </div>
                  </div>
                ) : (
                  <div className='panel-info no'>
                    {t('datasource.No Data')}
                    {/* <Link to={`/workspaces/${workspaceDetail.id}/datasources`}>
                      {t('Data Source')}
                    </Link> */}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 16 }}>
              <div className='workspace-ov-location workspace-ov-card'>
                <div className='label'>{t('Location Status')}</div>
                {totalLocations ? (
                  <div className='panel-info'>
                    <div className='graph'>
                      <PieChart
                        onClick={this.navToLocationsWithStatus}
                        collection={locationStatusList.filter(e => e.data)}
                      />
                    </div>
                    <div className='status-labels'>
                      {locationStatusList.map(item => (
                        <div className='label-item' key={item.color}>
                          <span
                            className='label-dot'
                            style={{ borderColor: `${item.color}` }}
                          />
                          {item.label}
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className='panel-info no'>
                    {t('locationOverview.No Data')}
                    {/* <Link to={`/workspaces/${workspaceDetail.id}/locations`}>
                      {t('Locations')}
                    </Link> */}
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <div className='workspace-ov-card location-performence'>
            {showPerformance.enable && (
              <div className='workspace-ov-status-header'>
                <div className='left'>
                  <div className='workspace-ov-status--label'>
                    {t('Locations') + ' ' + t('Performance')}
                  </div>
                  {!!performance.empty && (
                    <div className='workspace-ov-status--tip'>
                      {t('Performance No Data Tip', {
                        num: performance.empty
                      })}
                    </div>
                  )}
                </div>
                <span className='right'>
                  {!!totalLocations &&
                    workspaceDetail.counter &&
                    t('Last updated', {
                      date: moment(workspaceDetail.counter.updated_at).fromNow()
                    })}
                </span>
              </div>
            )}
            {showPerformance.enable && totalLocations ? (
              <div className='workspace-ov-status--content'>
                <div className='label'>{t('Air')}</div>
                <div className='workspace-ov-status--line-wrap'>
                  {locationPerformanceList
                    .filter(e => e.value)
                    .map((item, i) => (
                      <Popover
                        key={'ls' + i}
                        getPopupContainer={trigger => trigger!.parentElement!}
                        content={
                          <div
                            onClick={() =>
                              this.navToLocationsWithPerf(item.performance)
                            }
                            className='pop-inner'
                          >
                            <div className='pop-desc'>{item.desc}</div>
                            <div className='pop-num'>
                              <strong>{item.value}</strong>/{totalLocations}
                            </div>
                          </div>
                        }
                        title={null}
                      >
                        <div
                          key={item.color}
                          className={'workspace-ov-status--line'}
                          style={{
                            flex: `${(item.value / totalLocations) * 100}%`,
                            background: `${item.color}`
                          }}
                          onClick={() =>
                            this.navToLocationsWithPerf(item.performance)
                          }
                        />
                      </Popover>
                    ))}
                </div>
                <div className='workspace-ov-status--line-descwrap'>
                  {locationPerformanceList.map((item, i) => (
                    <div
                      key={item.desc}
                      className='workspace-ov-status--line-desc'
                    >
                      <span
                        className='label-dot'
                        style={{ borderColor: `${item.color}` }}
                      />
                      {item.desc}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className='workspace-ov-status--content empty'>
                <EmptyLocationImg />
                <div>
                  {showPerformance.enable
                    ? t('No Location Performance Data')
                    : showPerformance.message}
                </div>
              </div>
            )}
          </div>
          <Row gutter={16}>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>{this.GuardHandle_nofications()}</Col>
            <Col xs={{ span: 24 }} sm={{ span: 12 }}>
              <div className='workspace-ov-card'>
                <div className='title'>
                  <span>{t('Activity')}</span>
                  <a onClick={this.checkAllActivity} className='more'>
                    {t('header.more')}
                  </a>
                </div>
                <ul>
                  {logs.length ? (
                    logs.map((log, i) => (
                      <li key={'log-' + i} className='activity-item-card'>
                        <span className='workspace-ov-activity-name'>
                          {log.user_name}
                        </span>
                        <span className='workspace-ov-activity-event'>
                          {translations[log.event] || log.event}
                        </span>
                        <Tooltip
                          title={`${translations[log.item_type] ||
                            log.item_type}: ${log.item_id}`}
                        >
                          <span className='workspace-ov-activity-type'>
                            {translations[log.item_type] || log.item_type}
                            {log.item_name && ` - ${log.item_name}`}
                          </span>
                        </Tooltip>
                        <div className='date'>
                          {notificationDate(log.created_at)}
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className='empty-tip'>{t('activity.Empty Tip')}</li>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V2: { notifications },
  V3: { workspace, locationSelect, activeLog }
}) => ({
  locations: locationSelect.data,
  workspaceDetail: workspace.data,
  logs: activeLog.data,
  notifications: notifications.data
});

export default withRouter(connect(mapStateToProps)(WorkspaceOverview));
