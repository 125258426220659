import { BackTop } from 'antd'
import ErrorBoundary from 'components/ErrorBoundary'
import RouteChangeTracker from 'components/RouteChangeTracker'
import SibeiBackButton from 'components/SibeiBackButton'
import Header from 'containers/Header'
import Exception from 'pages/Exception'
import Kiosk from 'pages/Kiosk'
import LayoutRouter from 'pages/LayoutRouter'
import { paths } from 'pages/LayoutRouter/path'
import ReportPDF from 'pages/ReportPDF'
import User from 'pages/User'
import * as React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { isLogin } from 'utils/auth'

// const User = load(import("pages/User"));
// const ReportPDF = load(import("pages/ReportPDF"));
// const LayoutRouter = load(import("pages/LayoutRouter"));

/**
 * NOTICE: !!IMPORTANT
 * 由于部分页布局的结构不通，为了避免header以及container布局组件重复渲染导致api重复请求
 * 整个路由结构进行了重构
 * app 组件中直接挂载口上在 ErrorBoundary > Layout 中
 * ErrorBoundary 顶层错误捕捉组件 不处理过多预处理请求的逻辑
 * Layout 中挂载了ConfigControll 该组件只挂载一次，用于处理第一次进入页面的请求
 * 之后进入APP 处理路由常规请求
 * APP 中的路由都会各自处理布局
 * 同样布局的组件可以抽象到一个组件中，利用一个组件统一挂载内容
 * APP 中通过path 数组的格式接入通一布局的组件
 *
 * LayoutRouter 是一个特殊路由，它处理所有包含 header container 路由
 * 如果更新 LayoutRouter 内容需要同时更新paths 数组，以保证路由可以正确进入
 */

const App: React.SFC<{}> = () => (
  <ErrorBoundary>
    <BackTop />
    <Switch>
      <Route path='/user' component={User} />
      <Route exact={true} path='/reportpdf/:report_id' component={ReportPDF} />
      <Route exact={true} path={['/exception', '/404']} component={Exception} />
      <Route path={paths} component={LayoutRouter} />
      <Route exact={true} path='/' render={() => <Redirect to={isLogin() ? '/workspaces' : '/public'} />} />
      <Redirect exact={true} to='/' from='/workspaces' />
      <Redirect exact={true} to='/favorites' from='/dashboard' />
      <Redirect exact={true} to='/notification' from='/notifications' />
      <Route
        exact={true}
        path={['/:id/:collection_id', '/:id']}
        render={() => (
          <>
            <Header kiosk={true} />
            <Kiosk />
          </>
        )}
      />
      <Redirect to='/404' from='*' />
    </Switch>
    <RouteChangeTracker />
    <SibeiBackButton />
  </ErrorBoundary>
)

export default App
