import { t } from 'i18next';
import collectionIcon from 'images/icons/collection.png';
import locationIcon from 'images/icons/location.png';
import customIcon from '../images/icons/cnIcon.png';
import dsIcon from '../images/icons/dsIcon.png';
import indicatorIcon from '../images/icons/indIcon.png';
import { ReportStatus } from '../utils/enums';

const COLOR: {
  L1: '#21B5BB';
  L2: '#F6E19E';
  L3: '#FAAD5B';
  L4: '#F36D3C';
  L5: '#D93127';
  L6: '#4F0004';
} = {
    L1: '#21B5BB',
    L2: '#F6E19E',
    L3: '#FAAD5B',
    L4: '#F36D3C',
    L5: '#D93127',
    L6: '#4F0004'
};

export const LEVEL_DICT = () => ({
    online: t('Notification.online'),
    offline: t('Notification.offline'),
    flatline: t('Notification.flatline'),
    'partial-offline': t('Notification.partial-offline'),
    'no-status': t('Notification.no-status'),
    empty: t('Notification.empty'),
    air: t('filter.air'),
    electricity: t('filter.electricity'),
    indoor: t('filter.indoor'),
    outdoor: t('filter.outdoor'),
    excellent: t('level.Excellent'),
    good: t('level.Good'),
    hazardous: t('level.Hazardous'),
    moderate: t('level.Moderate'),
    sensitive: t('level.Sensitive'),
    unhealthy: t('level.Unhealthy'),
    'very-unhealthy': t('level.Very Unhealthy'),
    'no-performance': t('level.No Performance')
});

export const LEVEL = () => ({
    pm2p5: [
        ['200', COLOR.L6, t('level.Hazardous')],
        ['120', COLOR.L5, t('level.Very Unhealthy')],
        ['100', COLOR.L4, t('level.Unhealthy')],
        ['75', COLOR.L3, t('level.Sensitive')],
        ['35', COLOR.L2, t('level.Moderate')],
        ['0', COLOR.L1, t('level.Good')]
    ],
    co2: [
        ['5000', COLOR.L6, t('level.Unhealthy')],
        ['2500', COLOR.L5, t('level.Impaired')],
        ['1500', COLOR.L4, t('level.Sensitive')],
        ['1000', COLOR.L3, t('level.Moderate')],
        ['600', COLOR.L2, t('level.Good')],
        ['0', COLOR.L1, t('level.Excellent')]
    ],
    tvoc: [
        ['0.55', COLOR.L3, t('level.Unhealthy')],
        ['0.45', COLOR.L2, t('level.Moderate')],
        ['0', COLOR.L1, t('level.Good')]
    ]
});

export const notificationIcon = {
    location: locationIcon,
    collection: collectionIcon,
    indicator: indicatorIcon,
    data_source: dsIcon,
    configured: customIcon
};

export const indicatorList = [
    {
        indicator: 'pm2p5',
        name: 'PM 2.5',
        unit: 'μg/m³',
        value: 1,
        level: null,
        bto: '5.0'
    },
    {
        indicator: 'co2',
        name: 'CO₂',
        unit: 'ppm',
        value: 666,
        level: 'good'
    },
    {
        indicator: 'tvoc',
        name: 'Total VOC',
        unit: 'mg/m³',
        value: 0.13,
        level: 'good'
    },
    {
        indicator: 'pm10',
        name: 'PM 10',
        unit: 'μg/m³',
        value: 0,
        level: null
    },
    {
        indicator: 'temperature',
        name: 'Temperature',
        unit: '°C',
        value: 28,
        level: null
    },
    {
        indicator: 'humidity',
        name: 'Humidity',
        unit: '%RH',
        value: 42.53,
        level: null
    }
];

export const graphColorList = [
    '#029588',
    '#1564C0',
    '#333333',
    '#983221',
    '#15f4C0'
];

export const indicatorShortName = {
    ap: 'AP',
    'Active Power': 'AP',
    'Power Consumption': 'PC',
    humidity: 'HUM',
    temperature: 'TEMP',
    co: 'CO',
    co2: 'CO₂',
    hcho: 'HCHO',
    no2: 'NO₂',
    o3: 'O₃',
    pc: 'PC',
    pm2p5: 'PM 2.5',
    pm10: 'PM 10',
    pm100: 'PM 100',
    so2: 'SO₂',
    tvoc: 'TVOC',
    bto: 'BTO'
};

export const workspaceOvStateColor = [
    '#0C76AB',
    '#A6A6A6',
    '#FBB66C',
    '#969DE7'
];

export const sortKey = [
    'pm2p5',
    'pm10',
    'pm100',
    'co',
    'co2',
    'tvoc',
    'hcho',
    'formaldehyde',
    'formaldehyde_raw',
    'temperature',
    'water_temperature',
    'humidity',
    'o3',
    'no2',
    'so2',
    'light',
    'sound',
    'current',
    'voltage'
];

export const unitList = ['μg/m³', 'mg/m³', 'ppm', 'ppb'];

export const indicatorUnitDict = {
    temperature: ['°C', '°F'],
    humidity: ['%RH'],
    ap: ['W'],
    pc: ['kWh'],
    light: ['lux'],
    sound: ['dB'],
    current: ['A'],
    voltage: ['V']
};

export const formulas = ['y = a*x + b', 'y = a*(x+b) + c'];

export const dataTypeObjs = () => [
    {
        name: t('Average'),
        value: 'hour'
    },
    {
        name: t('Raw Data'),
        value: 'raw'
    }
];

export const shortName = {
    'Total VOC': 'TVOC',
    Temperature: 'TEMP',
    Humidity: 'HUM'
};

export const fontFamily = ['Nunito', 'Dosis', 'Arial', 'Georgia'];


export const reportStatusText = (state: ReportStatus) => {
    switch (state) {
    case ReportStatus.Pending:
        return t('Report.Pending');
    case ReportStatus.GeneratingReport:
        return t('Report.GeneratingReport');
    case ReportStatus.GeneratingPDF:
        return t('Report.GeneratingPDF');
    case ReportStatus.DoneforReport:
        return t('Report.DoneforReport');
    case ReportStatus.DoneforPDF:
        return t('Report.DoneforPDF');
    case ReportStatus.ReportFail:
        return t('Report.ReportFail');
    case ReportStatus.PDFFail:
        return t('Report.PDFFail');
    default:
        return '';
    }
};

export const translations = {
    max: t('Notification.exceeded'),
    min: t('Notification.below'),
    maxRule: t('Notification.maxRULE'),
    minRule: t('Notification.minRULE'),
    location: t('Location'),
    data_source: t('Datasource'),
    collection: t('Collection'),
    indicaotr: t('Indicator'),
    offline: t('Notification.offline'),
    online: t('Notification.online'),
    flatline: t('Notification.flatline'),
    monitor: t('activity.monitor'),
    update: t('activity.update'),
    create: t('activity.create'),
    destroy: t('activity.destroy')
};
