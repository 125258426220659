import { Action, ActionCreator } from 'redux'

export const REQUEST_DICTIONARY = 'REQUEST_DICTIONARY'
export const GET_DICTIONARY_DATA = 'GET_DICTIONARY_DATA'

export type CityItem = {
  id: number,
  name: string,
  time_zone: string
}

export type TimeZoneItem = {
  [P: string]: {
    [S: string]: string[]
  }
}

export type MonitorConfigItem = {
  brand_name: string,
  brand_id: number,
  brand_type: string,
  sku_name: string,
  sku_id: number,
  sku_indicators: string[],
  sku_units: string[]
}

type DictionaryKeys = 'indicators' | 'time_zones' | 'cities' | 'monitor_configs'

export type IndicatorItem = TimeZoneItem

export type OutdoorItem = {
  [P: number]: string
}
export interface RequestDictionaryAction extends Action<typeof REQUEST_DICTIONARY> {
  args: {
    name: DictionaryKeys
  };
}

export interface GetDictionaryAction extends Action<typeof GET_DICTIONARY_DATA> {
  name: string;
  data: any;
}

export const requestDictionary = (args: { name: DictionaryKeys }): RequestDictionaryAction => ({
  type: REQUEST_DICTIONARY,
  args
})

export const getDictionaryData: ActionCreator<GetDictionaryAction> = (name, data) => ({
  type: GET_DICTIONARY_DATA,
  name,
  data
})
