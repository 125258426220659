import * as React from 'react';
import Button from 'components/Custom/Button';
import moment, { Moment } from 'moment';
import { apiV3 } from 'utils/api';
import { Checkbox, Divider, Form, message, Select } from 'antd';
import { connect, DispatchProp } from 'react-redux';
import { DatePicker } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { Location } from 'actions/V3/locationSelect';
import {
    requestLocationSelect,
    RequestSelectAction
} from 'actions/V3/locationSelect';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { t } from 'i18next';

type State = Readonly<{
  loaded: boolean;
  successed: boolean;
  limit: Moment[];
  compareEnd?: Moment;
}>;

type StateProps = {
  locations: Location[];
};

type P = StateProps &
  RouteComponentProps<{ id: string }> &
  FormComponentProps &
  DispatchProp<RequestSelectAction>;
class CreateReport extends React.PureComponent<P, State> {
  state: State = {
      loaded: false,
      successed: false,
      limit: []
  };

  // TODO: fix 这个warning玩意
  UNSAFE_componentWillMount() {
      const {
          location: { state },
          match: {
              params: { id }
          },
          dispatch
      } = this.props;
      if (!state) {
          this.goToTemplate();
          message.warn(t('Report.please select template first'));
      } else {
          dispatch(
              requestLocationSelect({
                  show: false,
                  workspace_id: +id
              })
          );

          this.setState({ loaded: true });
      }
  }

  goToTemplate = () => {
      const {
          match: {
              params: { id }
          },
          history
      } = this.props;
      history.replace(`/workspaces/${id}/reports/template`);
  };

    validateRange = (rule, value: [Moment, Moment], callback) => {
        const firstElement = 0
        const secondElement = 1
        if (!value || !value[firstElement] || !value[secondElement]) {
            callback(t('Report.please select correct'));
            return;
        }

        const previousMonth = 1
        const begin = value[firstElement].clone();
        const end = value[secondElement].clone();

        if (begin.isBefore(end.subtract(previousMonth, 'months'))) {
            callback(t('Report.range limit 1 month'));
            return;
        }
        callback();
    };

  validateCompare = (rule, value: Moment, callback) => {
      if (!value) {
          callback(t('Report.Please Select Compare Begin Date'));
          return;
      }

      const begin = value.clone();
      const firstElement = 0
      const rangeBegin = this.props.form.getFieldValue('period')[firstElement];
      if (begin.isAfter(rangeBegin)) {
          callback(t('Report.Compare Begin Date need before Range begin'));
          return;
      }
      callback();
  };

  submit = e => {
      e.preventDefault();
      this.props.form.validateFields(async (err, values) => {
          if (!err) {
              const {
                  location: { state }
              } = this.props;
              const firstElement = 0
              const secondElement = 1
              const data = {
                  location_id: values.location_id,
                  enable_outdoor_location: values.enable_outdoor_location,
                  report_template_id: state.id,
                  // generae_pdf: values.generae_pdf,
                  begin_date: values.period[firstElement].format('YYYY-MM-DD'),
                  end_date: values.period[secondElement].format('YYYY-MM-DD'),
                  compared_begin_date: values.begin_date
              };

              if (!data.compared_begin_date) {
                  delete data.compared_begin_date;
              }
              await apiV3('/reports', 'post', data);
              this.setState({ successed: true });
          }
      });
  };

    disabledDate = current => {
        const firstElement = 0
        const rangeBegin = this.props.form.getFieldValue('period')[firstElement];
        return current && current.isAfter(rangeBegin);
    };

  disableRange = current => {
      const { limit } = this.state;
      const firstElement = 0
      const secondElement = 1
      if (!limit.length) {
          return current && current.isAfter(moment());
      }

      return (

          current.isBefore(limit[firstElement]) ||
      current.isAfter(limit[secondElement]) ||
      current.isAfter(moment())
      );
  };

    changeEndDate = (date: Moment | null, dateString: string) => {
        const getNegativeValue = -1
        const [start, end]: Moment[] = this.props.form.getFieldValue('period');
        const days = Math.abs(start.diff(end, 'days')) * getNegativeValue;
        const endDate = date!.clone().subtract(days, 'days');
        this.setState({
            compareEnd: endDate
        });
    };

  rangeLimit = ([start, end]: (Moment | undefined)[]) => {
      if (end) {
          this.setState({
              limit: [],
              compareEnd: undefined
          });
          this.props.form.resetFields(['begin_date']);
          return;
      }

      if (start) {
          const previousMonth = 1
          const nextMonth = -1
          const limitStart = start.clone().subtract(previousMonth, 'months');
          const limitEnd = start.clone().subtract(nextMonth, 'months');
          this.setState({
              limit: [limitStart, limitEnd]
          });
          return;
      }
  };
  goToReportList = () => {
      const {
          match: {
              params: { id }
          },
          history
      } = this.props;
      history.replace(`/workspaces/${id}/reports`);
  };

  renderRequest() {
      const {
          locations,
          form: { getFieldDecorator, getFieldsError, getFieldValue }
      } = this.props;

      const {
          location: { state }
      } = this.props;
      const hasError = Object.values(getFieldsError()).some(Boolean);
      const { compareEnd } = this.state;
      const hasHistorical = state.modules.some(
          m =>
              m.features.includes('historical') ||
        m.features.includes('historical_percent_reduction')
      );

      return (
          <Form onSubmit={this.submit}>
              <div className='report-request'>
                  <div className='report-request-location'>
                      <Form.Item label={t('Location')}>
                          {getFieldDecorator('location_id', {
                              rules: [{ required: true, message: t('Please Select Location!') }]
                          })(
                              <Select
                                  showSearch={true}
                                  dropdownMatchSelectWidth={true}
                                  filterOption={(input, option) =>
                                      (option.props.children as string)
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                  }
                                  optionFilterProp='children'
                              >
                                  {locations.map((l, i) => (
                                      <Select.Option key={`location-${i}`} value={l.id}>
                                          {l.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          )}
                      </Form.Item>
                      <Form.Item>
                          {getFieldDecorator('enable_outdoor_location', {
                              valuePropName: 'checked',
                              initialValue: false
                          })(<Checkbox>{t('Report.Enable Outdoor Location')}</Checkbox>)}
                      </Form.Item>
                  </div>
                  <br />
                  <Divider />
                  <p className='report-history-label' style={{ lineHeight: 'normal' }}>
                      {t('Report.report time range')}:<br />
                      <small className='grey_text'>
                          {t('Please note that currently')}
                      </small>
                  </p>
                  <Form.Item>
                      {getFieldDecorator('period', {
                          rules: [{ validator: this.validateRange }]
                      })(
                          <DatePicker.RangePicker
                              onCalendarChange={this.rangeLimit}
                              disabledDate={this.disableRange}
                          />
                      )}
                  </Form.Item>

                  {hasHistorical && (
                      <div>
                          <div className='report-history-date'>
                              <Form.Item label={t('Report.History Compare Begin Date')}>
                                  {getFieldDecorator('begin_date', {
                                      rules: [{ validator: this.validateCompare }]
                                  })(
                                      <DatePicker
                                          onChange={this.changeEndDate}
                                          disabledDate={this.disabledDate}
                                          disabled={!getFieldValue('period')}
                                      />
                                  )}
                              </Form.Item>
                          </div>

                          <div className='report-history-date'>
                              <p className='report-history-label'>
                                  {t('Report.History Compare End Date')}:
                              </p>
                              <DatePicker
                                  disabled={true}
                                  value={compareEnd}
                                  placeholder={t('Report.End Date')}
                              />
                          </div>
                      </div>
                  )}
              </div>

              <div className='report-title-button'>
                  <Button customType='dark' onClick={this.goToTemplate}>
                      {t('Back')}
                  </Button>
          &nbsp;&nbsp;
                  <Button customType='light' htmlType='submit' disabled={hasError}>
                      {t('Report.Create Report')}
                  </Button>
              </div>
              <br />
          </Form>
      );
  }

  renderSuccess = () => (
      <div className='report-success'>
          <div>
              <i className='material-icons md-24 accent_text report-success-icon'>
          check_circle
              </i>
          </div>

          <h2 className='report-success-title'>{t('Report.submit success')}</h2>
          <p className='small_text grey_text report-success-message'>
              {t('Report.5min generate')}
          </p>
          <Button
              className='report-success-button'
              size='large'
              onClick={this.goToReportList}
              customType='dark'
          >
              {t('Report.Back to Report List')}
          </Button>
      </div>
  );

  render() {
      const { successed, loaded } = this.state;
      if (!loaded) {
          return null;
      }
      return successed ? this.renderSuccess() : this.renderRequest();
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { locationSelect } }) => ({
    locations: locationSelect.data
});

export default Form.create()(
    withRouter(connect(mapStateToProps)(CreateReport))
);
