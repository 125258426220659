/* eslint-disable */
import * as React from "react";
import "./index.css";
// import axios from "axios";
import loadScript from "utils/loadScript";
import NoVideo from "-!svg-react-loader!images/no_video_normal.svg";

interface Props {
  url: string;
  mute?: boolean;
  size?: string;
  autoPlay?: boolean;
}

type PlayMode = "Youtube" | "File";

type State = Readonly<{
  noVideo: boolean;
}>;

// tslint:disable:max-line-length
export default class Video extends React.PureComponent<Props, State> {
  static defaultProps = {
    mute: true,
    autoPlay: true
  };
  state: State = {
    noVideo: false
  };
  player: any;
  qualitySetted = false;
  loopControl = true;
  duration = 0;
  intervalTimer;
  playMode?: PlayMode;
  youtubeDestory = () => {};

  componentWillUnMount() {
    this.youtubeDestory();
  }

  detectUrl = () => {
    const { url } = this.props;
    const matchYoutube = /youtube.com/.test(url);
    // const matchTx = /v.qq.com/.test(url);

    if (matchYoutube) {
      this.playMode = "Youtube";
      let matched = url.match(/youtube.com\/\w+\/(\w+)"/);
      const iframeID = matched && matched[1];

      matched = url.match(/v=(\w+)/);
      const pageID = matched && matched[1];
      const id = iframeID || pageID;
      return id ? this.renderYoutubeApi(id) : null;
    }
    // if (matchTx) {
    //   let matched = url.match(/vid=(\w+)/);
    //   const iframeID = matched && matched[1];

    //   matched = url.match(/\/(\w+)\.html/);
    //   const pageID = matched && matched[1];
    //   const id = pageID || iframeID;

    //   return id ? this.renderQQ(id) : null;
    // }
    this.playMode = "File";
    return this.renderVideo();
  };

  renderYoutube = (id: string) => {
    const { mute, autoPlay } = this.props;
    let path = `https://www.youtube.com/embed/${id}`;
    path += `?autoplay=${
      autoPlay ? 1 : 0
    }&vq=hd1080&loop=1&controls=0&iv_load_policy=3&fs=1&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&playlist=${id}&mute=${
      mute ? 1 : 0
    }`;

    return (
      <div
        className="video-container"
        dangerouslySetInnerHTML={{
          __html: `
            <iframe
              src='${path}'
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              webkitallowfullscreen mozallowfullscreen allowfullscreen>
            </iframe>`
        }}
      />
    );
  };

  youtubeEvent = event => {
    if (!this.qualitySetted) {
      const [quality] = event.target.getAvailableQualityLevels();
      this.duration = event.target.getDuration();
      event.target.setPlaybackQuality(quality);
      this.qualitySetted = true;
    }
    if (event.data == window.YT.PlayerState.PLAYING && this.loopControl) {
      this.intervalTimer = setTimeout(() => {
        event.target.seekTo(0);
        this.loopControl = true;
      }, (this.duration - 1) * 1000);
      this.loopControl = false;
    }
  };

  renderYoutubeApi = (id: string) => {
    if (!window.YT) {
      const tag = document.createElement("script");
      tag.src = "https://www.youtube.com/iframe_api";
      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode!.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = () => {
        this.player = new window.YT.Player("YT-player", {
          playerVars: {
            origin: `http${window.location.port ? "" : "s"}://${
              window.location.host
            }`
          },
          events: {
            onStateChange: this.youtubeEvent
          }
        });
      };
    } else {
      setTimeout(() => {
        this.player = new window.YT.Player("YT-player", {
          playerVars: {
            origin: `http${window.location.port ? "" : "s"}://${
              window.location.host
            }`
          },
          events: {
            onStateChange: this.youtubeEvent
          }
        });
      });
    }

    this.youtubeDestory = () => {
      if (this.intervalTimer) {
        window.clearTimeout(this.intervalTimer);
      }

      this.player.destroy();
    };

    const { mute, autoPlay } = this.props;
    let path = `https://www.youtube.com/embed/${id}`;
    path += `?autoplay=${
      autoPlay ? 1 : 0
    }&vq=highres&loop=1&controls=0&iv_load_policy=3&fs=1&modestbranding=1&rel=0&showinfo=0&enablejsapi=1&playlist=${id}&mute=${
      mute ? 1 : 0
    }`;
    return (
      <div className="video-container">
        <iframe
          id="YT-player"
          src={path}
          // frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          // webkitallowfullscreen
          // mozallowfullscreen
          // allowfullscreen
        />
      </div>
    );
  };

  renderVideo = () => {
    const { url, mute, autoPlay, size } = this.props;
    return (
      <div className="video-container">
        {this.state.noVideo ? (
          size === "small" ? (
            <img
              src={require("../../images/no_video_small.png")}
              alt="no video"
            />
          ) : (
            <NoVideo />
          )
        ) : (
          <video
            ref={r => {
              if (r) {
                r.addEventListener("error", () => {
                  this.setState({
                    noVideo: true
                  });
                });
              }
            }}
            src={url}
            autoPlay={autoPlay}
            loop={true}
            muted={mute}
          />
        )}
      </div>
    );
  };

  // renderBilibili = (url: string) => {
  //   const match = url.match(/cid=(\d+)/);
  //   const cid = match && match[1];
  //   if (cid) {
  //     axios
  //       .get(`https://api.imjad.cn/bilibili/v2/aid=170001&page=1&quality=2`, {})
  //   }
  //   return null;
  // };

  renderQQ = (vid: string) => {
    return (
      <div className="video-container" id="txvideo">
        {this.setupTx(vid)}
      </div>
    );
  };

  setupTx = (vid: string) => {
    loadScript({
      url: "//vm.gtimg.cn/tencentvideo/txp/js/txplayer.js"
    }).then(() => {
      this.player = new (window as any).Txplayer({
        containerId: "txvideo",
        vid: vid,
        width: "100%",
        height: "100%",
        autoplay: true
      });

      this.player.on("playStateChange", ({ state }) => {
        if (state === 0) {
          this.player.seekTo(0);
          this.player.togglePlayPause();
        }
      });
    });

    return null;
  };

  render() {
    return this.detectUrl();
  }
}
