import * as React from 'react';
import { t } from 'i18next';
import { Avatar, Icon, Select, Switch, Tabs } from 'antd';
import Button from 'components/Custom/Button';
import classNames from 'classnames';
import { apiV3 } from 'utils/api';
import { cloneDeep } from 'lodash';
import { LocationEdit, LocationBase } from 'actions/V3/location';
import { Guard } from 'utils/guard';
import EditInformation from './EditInformation';

type State = Readonly<{
  locationEdit: LocationEdit;
  selectedDay: boolean[];
  startTime?: number;
  endTime?: number;
}>;

type Props = Readonly<{
  collections: { name: string; code: string }[];
  locationBase: LocationBase;
  locationID: number;
  workspaceID: number;
  showOutdoor?: boolean;
  locationEdit: LocationEdit;
  close: () => void;
  openDelete: () => void;
  refresh: () => void;
}>;

type P = Props;

const timeArr = Array.from({ length: 49 }).map(
    (_, index) =>
        `${
            Math.floor(index / 2) < 10
                ? '0' + Math.floor(index / 2)
                : Math.floor(index / 2)
        }:${!(index % 2) ? '00' : '30'}`
);
@Guard
class EditLocation extends React.PureComponent<P, State> {
  state: State;

  constructor(props: P) {
      super(props);
      this.state = {
          locationEdit: props.locationEdit,
          selectedDay: Array(7).fill(false)
      };
  }

  GuardHandle_locationSettingUpdate = (rule?: GuardRule) => rule && rule.enable;

  GuardRender_locationDelete = () => (
      <div className='location_detail-setting-row register-body'>
          <div className='location_detail-setting-row_left'>
              <h4>{t('locationOverview.delete Location')}</h4>
              <p className='small_text'>{t('locationOverview.once you delete')}</p>
          </div>

          <div className='location_detail-setting-row_right'>
              <Button
                  size='small'
                  customType='danger'
                  onClick={this.props.openDelete}
              >
                  {t('Delete')}
              </Button>
          </div>
      </div>
  );

  locationEditUpdate = (field: keyof LocationEdit, value) => {
      const { locationEdit } = this.state;
      const cloned: LocationEdit = { ...locationEdit!, [field]: value };
      this.setState({ locationEdit: cloned });
  };

  switchWorkHours = (active: boolean) => {
      const { locationEdit } = this.state;
      const cloned = cloneDeep(locationEdit!);
      cloned.work_hours.active = active;
      this.setState({ locationEdit: cloned });
  };

  addWorkingHour = () => {
      const { startTime, endTime, selectedDay } = this.state;
      const days = selectedDay
          .map((v, i) => (v ? i : -1))
          .filter((v) => v !== -1);

      if (
          !days.length ||
      startTime === undefined ||
      endTime === undefined ||
      endTime <= startTime
      ) {
          return;
      }

      //this is the bug, when the object 'cloned' already exist & a day
      //was removed, the array has a length of 6 or less instead of 7.
      const { work_hours } = this.state.locationEdit!;
      const cloned = cloneDeep(work_hours);
      if (!cloned.data.length) {
          cloned.data = Array(7)
              .fill(1)
              .map((v, i) => ({
                  day_in_week: i,
                  active: false,
                  begin_time: '',
                  end_time: ''
              }));
      }


      days.forEach((day) => {
      //quick fix for 'less than 7 days' arrays.
          if (7 > cloned.data.length) {
              cloned.data.unshift({
                  day_in_week: 0,
                  begin_time: '00:00',
                  end_time: '24:00',
                  active: true
              });
              cloned.data.push({
                  day_in_week: 6,
                  begin_time: '00:00',
                  end_time: '24:00',
                  active: true
              });
          }

          //quick fix for 'more than 7 days' arrays.
          if (7 < cloned.data.length) {
              cloned.data.pop();
          }

          cloned.data[day].active = true;
          cloned.data[day].begin_time = timeArr[startTime];
          cloned.data[day].end_time = timeArr[endTime];
      });

      this.setState({
          locationEdit: { ...this.state.locationEdit!, work_hours: cloned }
      });
  };

  //here, one of the 7 days is removed from the array.
  //therefore, one day of the week cannot be updated anymore
  removeWorkingHour = (day: number) => {
      const { work_hours } = this.state.locationEdit!;
      const cloned = cloneDeep(work_hours);

      cloned.data[day].active = false;

      this.setState({
          locationEdit: { ...this.state.locationEdit!, work_hours: cloned }
      });
  };

  //sends the new settings to the db
  //Issue #37 - github.com/qlear-project/qlear-web-v3/issues/37
  updateLocationEdit = async () => {
      const { locationID, close, refresh } = this.props;
      const { locationEdit } = this.state;

      await apiV3(`/locations/${locationID}`, 'patch', {
          location: locationEdit
      });

      refresh();
      close();
  };

  render() {
      const { selectedDay, locationEdit, startTime, endTime } = this.state;
      const { close, locationBase, showOutdoor } = this.props;
      const editAble = this.GuardHandle_locationSettingUpdate();
      const weekday = [
          t('week.Sunday'),
          t('week.Monday'),
          t('week.Tuesday'),
          t('week.Wednesday'),
          t('week.Thursday'),
          t('week.Friday'),
          t('week.Saturday')
      ];

      const endTimeError = !!(
          endTime !== undefined &&
      startTime !== undefined &&
      endTime <= startTime
      );

      return (
          <div className='colletion_modal-container'>
              <h3 className='register-title'>{t('locationOverview.Settings')}</h3>

              <Tabs defaultActiveKey='1'>
                  <Tabs.TabPane tab={t('locationOverview.EditInformation')} key='1'>
                      <EditInformation
                          close={close}
                          editAble={editAble}
                          showOutdoor={showOutdoor}
                          locationBase={locationBase}
                      />
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t('locationOverview.Data Preferences')} key='2'>
                      <div className='register-body'>
                          <div className='location_detail-setting-row'>
                              <div className='location_detail-setting-row_left'>
                                  <h4>{t('locationOverview.Default TVOC Unit')}</h4>
                                  <p className='small_text'>
                                      {t('locationOverview.Mass Concentration is recommended')}
                                  </p>
                              </div>

                              <div className='location_detail-setting-row_right'>
                                  <Select
                                      disabled={!editAble}
                                      onSelect={(v) => this.locationEditUpdate('tvoc_unit', v)}
                                      value={locationEdit!.tvoc_unit}
                                      placeholder={t('locationOverview.selectUnit')}
                                  >
                                      <Select.Option value='mg/m³'>mg/m³</Select.Option>
                                      <Select.Option value='ppm'>ppm</Select.Option>
                                  </Select>
                              </div>
                          </div>

                          <div className='location_detail-setting-row'>
                              <div className='location_detail-setting-row_left'>
                                  <h4>{t('locationOverview.Average Cycle')}</h4>
                              </div>

                              <div className='location_detail-setting-row_right'>
                                  <Select
                                      disabled={!editAble}
                                      onSelect={(v) =>
                                          this.locationEditUpdate('average_cycle', v)
                                      }
                                      value={editAble ? locationEdit!.average_cycle : undefined}
                                      placeholder={t('locationOverview.selectAverage')}
                                  >
                                      <Select.Option value={30}>
                                          {t('locationOverview.every30')}
                                      </Select.Option>
                                      <Select.Option value={60}>
                                          {t('locationOverview.every60')}
                                      </Select.Option>
                                  </Select>
                              </div>
                          </div>

                          <div className='location_detail-setting-row is-working_hours'>
                              <div className='location_detail-setting-row_left'>
                                  <h4>{t('locationOverview.Configure Working Hours')}</h4>
                                  <p className='small_text'>
                                      {t(
                                          'locationOverview.Enable this feature to allow filtering'
                                      )}
                                  </p>
                              </div>

                              <div className='location_detail-setting-row_right'>
                                  <Switch
                                      disabled={!editAble}
                                      onChange={this.switchWorkHours}
                                      checked={editAble ? locationEdit!.work_hours.active : false}
                                  />
                              </div>
                          </div>
                          {editAble && locationEdit!.work_hours.active && (
                              <div className='location_detail-working_hours'>
                                  <div className='location_detail-working_hours-days'>
                                      {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, i) => (
                                          <span
                                              className='location_detail-working_hours-day_wrapper'
                                              key={`workding-day-avatar-${i}`}
                                              onClick={() => {
                                                  const arr = selectedDay.slice();
                                                  arr[i] = !arr[i];
                                                  this.setState({ selectedDay: arr });
                                              }}
                                          >
                                              <Avatar
                                                  className={classNames(
                                                      'location_detail-working_hours-day',
                                                      {
                                                          'is-select': selectedDay[i]
                                                      }
                                                  )}
                                                  key={`working-hours-${i}`}
                                                  size='small'
                                              >
                                                  {day}
                                              </Avatar>
                                          </span>
                                      ))}
                                  </div>

                                  <div className='location_detail-working_hours-list'>
                                      <div className='location_detail-working_hours-row is-select'>
                                          <span className='location_detail-working_hours-date small_text' />
                                          <span className='location_detail-working_hours-time'>
                                              <Select
                                                  showSearch={true}
                                                  filterOption={(input, option) =>
                                                      (option.props.children as string)
                                                          .toLowerCase()
                                                          .includes(input.toLowerCase())
                                                  }
                                                  placeholder='Start time'
                                                  onSelect={(v) =>
                                                      this.setState({ startTime: v as number })
                                                  }
                                                  optionFilterProp='children'
                                                  value={startTime}
                                              >
                                                  {timeArr.map((time, index) => (
                                                      <Select.Option
                                                          key={`time-pick-1-${index}`}
                                                          value={index}
                                                      >
                                                          {time}
                                                      </Select.Option>
                                                  ))}
                                              </Select>
                                          </span>
                                          <span className='small_text location_detail-working_hours-to'>
                        to
                                          </span>
                                          <span
                                              className={classNames(
                                                  'location_detail-working_hours-time',
                                                  ' ant-form-item-control',
                                                  {
                                                      'has-error': endTimeError
                                                  }
                                              )}
                                          >
                                              <Select
                                                  showSearch={true}
                                                  filterOption={(input, option) =>
                                                      (option.props.children as string)
                                                          .toLowerCase()
                                                          .includes(input.toLowerCase())
                                                  }
                                                  placeholder='End time'
                                                  onSelect={(v) =>
                                                      this.setState({ endTime: v as number })
                                                  }
                                                  value={endTime}
                                                  optionFilterProp='children'
                                              >
                                                  {timeArr.map((time, index) => (
                                                      <Select.Option
                                                          key={`time-pick-2-${index}`}
                                                          value={index}
                                                      >
                                                          {time}
                                                      </Select.Option>
                                                  ))}
                                              </Select>
                                          </span>
                                          <span className='location_detail-working_hours-icon'>
                                              <Icon
                                                  type='plus-circle'
                                                  onClick={this.addWorkingHour}
                                              />
                                          </span>
                                      </div>
                                      {endTimeError && (
                                          <div
                                              className='small_text'
                                              style={{ textAlign: 'center' }}
                                          >
                                              {t(
                                                  'locationOverview.End time should be after the start time'
                                              )}
                                          </div>
                                      )}

                                      {locationEdit!.work_hours.data.map(
                                          (day, index) =>
                                              day.active && (
                                                  <div
                                                      className='location_detail-working_hours-row'
                                                      key={`working-day-${index}`}
                                                  >
                                                      <span className='location_detail-working_hours-date small_text'>
                                                          {weekday[day.day_in_week]}
                                                      </span>
                                                      <span className='location_detail-working_hours-time  is-time small_text accent_text'>
                                                          {day.begin_time}
                                                      </span>
                                                      <span className='small_text location_detail-working_hours-to'>
                              to
                                                      </span>
                                                      <span className='location_detail-working_hours-time  is-time small_text accent_text'>
                                                          {day.end_time}
                                                      </span>
                                                      {editAble && (
                                                          <span className='location_detail-working_hours-icon'>
                                                              <Icon
                                                                  type='close-circle'
                                                                  onClick={() => this.removeWorkingHour(index)}
                                                              />
                                                          </span>
                                                      )}
                                                  </div>
                                              )
                                      )}
                                  </div>
                              </div>
                          )}
                      </div>
                      <div className='location_detail-setting-button'>
                          <Button size='large' customType='light' onClick={close}>
                              {t('Cancel')}
                          </Button>
              &nbsp; &nbsp; &nbsp;
                          <Button
                              disabled={!editAble}
                              size='large'
                              customType='dark'
                              onClick={this.updateLocationEdit}
                          >
                              {t('Save')}
                          </Button>
                      </div>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab={t('Danger Zone')} key='4'>
                      {this.GuardRender_locationDelete()}
                  </Tabs.TabPane>
              </Tabs>
          </div>
      );
  }
}

export default EditLocation;
