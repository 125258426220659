import * as React from 'react'
import moment from 'moment'
import Table from 'components/Custom/Table'
import { connect } from 'react-redux'
import { iosMonth } from 'utils/helper'
import { Link, withRouter } from 'react-router-dom'
import { requestOrgBillings, BillingObj } from 'actions/V3/billing'
import { t } from 'i18next'
import './index.css'
import { RequestOrgBillingAction } from 'actions/V3/billing'
import { OrgObj } from 'actions/V3/org'
import { DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

export interface StateProps {
  org?: OrgObj;
  billingsByMonth: BillingObj[];
  query?: {};
  meta?: Meta;
}

export type DispatchAction = RequestOrgBillingAction

export type P = StateProps & DispatchProp<DispatchAction> & RouteComponentProps<{ id: string, month: string }>

class BillingByMonth extends React.PureComponent<P, {}> {
  componentDidMount() {
    this.fetchBillings()
  }

  fetchBillings = (page = 1) => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    dispatch(requestOrgBillings({ id, page }))
  }

  geneTableColumns = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    const currentMonth = moment().format('YYYY-MM')
    return [
      {
        title: t('Month'),
        dataIndex: 'month',
        width: '20%',
        key: 'month',
        render: (text, record) => {
          return iosMonth(record.year, record.month).format('MMM YYYY')
        }
      },
      {
        title: t('Total'),
        dataIndex: 'total_price',
        width: '30%',
        key: 'total_price',
        render: (text, record) => {
          const lastBillingMonth = iosMonth(record.year, record.month).format('YYYY-MM')
          if (currentMonth === lastBillingMonth) {
            return (
              <div>
                CNY {record.estimated_price} ({t('billing.estimated price')})
              </div>
            )
          }
          return <div>CNY {text}</div>
        }
      },
      {
        title: t('Paid/Unpaid'),
        dataIndex: 'paid',
        width: '15%',
        key: 'paid',
        render: (text, record) => {
          if (record.total_price > 0) {
            return (
              <div>{record.paied ? <div className='accent_text'>{t('Paid')}</div> : <div className='danger_text'>{t('Unpaid')}</div>}</div>
            )
          }
          return '-'
        }
      },
      {
        title: t('Invoice'),
        dataIndex: 'code',
        width: '15%',
        key: 'code',
        render: (text, record) => {
          const lastBillingMonth = iosMonth(record.year, record.month).format('YYYY-MM')
          if (record.total_price > 0 && currentMonth !== lastBillingMonth) {
            return <Link to={`/invoice/${id}/${record.year}/${record.month}`}>{t('See Invoice')}</Link>
          }
          return '-'
        }
      },
      {
        title: '',
        dataIndex: 'id',
        width: '20%',
        key: 'id',
        render: (text, record) => {
          if (record.total_price > 0) {
            return <Link to={`/billing/${id}/detail/${text}`}>{t('Billing By Workspace')}</Link>
          }
          return '-'
        }
      }
    ]
  }

  pagination = () => {
    const { meta } = this.props
    return meta
      ? {
          hideOnSinglePage: true,
          showQuickJumper: true,
          onChange: this.fetchBillings,
          current: meta.current_page,
          pageSize: 25,
          total: meta.total_count,
          showTotal: () => {
            return t('showTotal', { total: meta.total_count })
          }
        }
      : {}
  }

  render() {
    const { billingsByMonth, meta } = this.props
    if (!meta) {
      return null
    }
    const data = billingsByMonth.map((item, i) => ({
      ...item,
      key: i
    }))
    return (
      <div className='billing-by-month'>
        <Table
          rowClassName={() => 'home-ws-row'}
          type='light'
          pagination={this.pagination()}
          columns={this.geneTableColumns()}
          dataSource={data}
          size='default'
        />
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { org, billing } }) => ({
  org: org.detail,
  billingsByMonth: billing.list.data,
  meta: billing.list.meta,
  query: billing.list.query
})

export default withRouter(connect(mapStateToProps)(BillingByMonth))
