import { Action, ActionCreator } from 'redux'

export const REQUEST_LOCATION = 'REQUEST_LOCATION'
export const GET_LOCATION = 'GET_LOCATION'

export const REQUEST_OUTDOOR = 'REQUEST_OUTDOOR'
export const GET_OUTDOOR = 'GET_OUTDOOR'

export type LocationObj = {
  id: number,
  name: string,
  building?: string,
  address?: string,
  tvoc_unit: 'mg/m³' | 'ppm',
  city_id?: number,
  outdoor_location_id?: number,
  time_zone?: string,
  category: LocationCategory,
  theme?: string,
  search_time_enabled_at: string,
  enable_work_hours: boolean,
  report_options: {
    deliver_email: boolean,
    enable_working_hours: boolean,
    health_standard: string
  },
  work_hours: {
    active: boolean,
    data: {
      active: boolean,
      begin_time: string,
      day_in_week: number,
      end_time: string
    }[]
  },
  workspace_id: number
}

export type Outdoor = {
  [P: string]: string
}

export interface RequestLocationAction extends Action<typeof REQUEST_LOCATION> {
  args: {
    location_id?: number
  };
}

export interface RequestOutdoorAction extends Action<typeof REQUEST_OUTDOOR> {
  args: {
    city_id?: number
  };
}

interface GetLocationAction extends Action<typeof GET_LOCATION> {
  location: LocationObj;
}

interface GetOutdoorAction extends Action<typeof GET_OUTDOOR> {
  outdoor: Outdoor;
}

export type LocationAction = GetLocationAction | GetOutdoorAction

export const requestLocation: ActionCreator<RequestLocationAction> = args => ({
  type: REQUEST_LOCATION,
  args
})

export const getLocation: ActionCreator<GetLocationAction> = (location: LocationObj) => ({
  type: GET_LOCATION,
  location
})

export const requestOutdoor: ActionCreator<RequestOutdoorAction> = args => ({
  type: REQUEST_OUTDOOR,
  args
})

export const getOurdoor: ActionCreator<GetOutdoorAction> = (outdoor: Outdoor) => ({
  type: GET_OUTDOOR,
  outdoor
})
