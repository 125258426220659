import { TableDataAction, GET_TABLEDATA, DataObj } from 'actions/V3/tabledata'

export type TableDataState = {
  data: DataObj[],
  meta: Meta,
  query?: {}
}

export default function TableData(
  state: TableDataState = {
    data: [],
    meta: {}
  },
  action: TableDataAction
) {
  switch (action.type) {
    case GET_TABLEDATA:
      return {
        ...state,
        data: action.tabledata,
        meta: action.meta,
        query: action.query
      }
    default:
      return state
  }
}
