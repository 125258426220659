import { Action, ActionCreator } from 'redux'
export const REQUEST_NOTIFICATION_CONFIG_V3 = 'REQUEST_NOTIFICATION_CONFIG_V3'
export const GET_NOTIFICATION_CONFIG_V3 = 'GET_NOTIFICATION_CONFIG_v3'
export type NotificationCategory = 'all' | 'location' | 'collection' | 'datasource' | 'indicator'
export type NotificationLimit = {
  id: number,
  workspace_id: number,
  workspace_name: string,
  category: NotificationCategory,
  location_name?: string,
  location_id?: number,
  collection_id?: number,
  collection_name?: string,
  indicator_id?: number,
  indicator_name?: string,
  data_source_id?: number,
  data_source_name?: string,
  data_channel: string,
  data_channel_name: string,
  type: 'max' | 'min',
  value: number
}

export interface RequestNotificationConfigV3Action extends Action<typeof REQUEST_NOTIFICATION_CONFIG_V3> {
  args: any;
}

interface GetNotificationConfigV3Action extends Action<typeof GET_NOTIFICATION_CONFIG_V3> {
  category: NotificationCategory;
  data: NotificationLimit[];
  meta: Meta;
  query: {};
}

export type NotificationConfigV3Action = GetNotificationConfigV3Action

export const requestNotificationConfigV3: ActionCreator<RequestNotificationConfigV3Action> = args => ({
  type: REQUEST_NOTIFICATION_CONFIG_V3,
  args
})

export const getNotificationConfig: ActionCreator<GetNotificationConfigV3Action> = (category, data, meta, query) => ({
  type: GET_NOTIFICATION_CONFIG_V3,
  category,
  data,
  meta,
  query
})
