import { Action, ActionCreator } from 'redux'
export const REQUEST_ORG_BILLINGS_V3 = 'REQUEST_ORG_BILLINGS_V3'
export const GET_ORG_BILLINGS_V3 = 'GET_ORG_BILLINGS_V3'
export const REQUEST_ORG_BILLING_OV_V3 = 'REQUEST_ORG_BILLING_OV_V3'
export const GET_ORG_BILLINGS_OV_V3 = 'GET_ORG_BILLINGS_OV_V3'
export const REQUEST_BILLING_DETAIL_V3 = 'REQUEST_BILLINGS_DETAIL_V3'
export const GET_BILLING_DETAIL_V3 = 'GET_BILLING_DETAIL_V3'
export const REQUEST_WORKSPACE_BILLING_V3 = 'REQUEST_WORKSPACE_BILLING_V3'
export const GET_WORKSPACE_BILLING_V3 = 'GET_WORKSPACE_BILLING_V3'
export const REQUEST_WORKSPACE_BILLING_DETAIL_V3 = 'REQUEST_WORKSPACE_BILLING_DETAIL_V3'
export const GET_WORKSPACE_BILLING_DETAIL_V3 = 'GET_WORKSPACE_BILLING_DETAIL_V3'

export const REQUEST_MONTH_BILLING_V3 = 'REQUEST_MONTH_BILLING_V3'
export const GET_MONTH_BILLING_V3 = 'GET_MONTH_BILLING_V3'

export type BillingObj = {
  id: number,
  code: string,
  year: number,
  month: number,
  indicators_count: number,
  active_indicators_count: number,
  total_price: number,
  estimated_price: number,
  status: number,
  paied: boolean,
  paied_at: string,
  workspaces_count?: number,
  workspace_code?: string,
  workspace_id?: number,
  workspace_name?: string
}

export type BillingOV = {
  payment: number,
  tax_rate: number,
  name: string,
  id: number,
  balance: number,
  due_date: string
}

export type WorkspaceBillingObj = {
  workspace_id: number,
  workspace_code: string,
  workspace_name: string,
  total_price: number
}

export interface RequestOrgBillingAction extends Action<typeof REQUEST_ORG_BILLINGS_V3> {
  args: any;
}

interface GetOrgBillingAction extends Action<typeof GET_ORG_BILLINGS_V3> {
  billings: BillingObj[];
  meta: Meta;
  query: {};
}

export const requestOrgBillings: ActionCreator<RequestOrgBillingAction> = args => ({
  type: REQUEST_ORG_BILLINGS_V3,
  args
})

export const getOrgBillings: ActionCreator<GetOrgBillingAction> = (billings, meta, query) => ({
  type: GET_ORG_BILLINGS_V3,
  billings,
  meta,
  query
})

export interface RequestOrgBillingOVAction extends Action<typeof REQUEST_ORG_BILLING_OV_V3> {
  args: any;
}

interface GetOrgBillingOVAction extends Action<typeof GET_ORG_BILLINGS_OV_V3> {
  billingOV: BillingOV;
}

export const requestOrgBillingOV: ActionCreator<RequestOrgBillingOVAction> = args => ({
  type: REQUEST_ORG_BILLING_OV_V3,
  args
})

export const getOrgBillingOV: ActionCreator<GetOrgBillingOVAction> = billingOV => ({
  type: GET_ORG_BILLINGS_OV_V3,
  billingOV
})

export interface RequestBillingDetailAction extends Action<typeof REQUEST_BILLING_DETAIL_V3> {
  args: any;
}

interface GetBillingDetailAction extends Action<typeof GET_BILLING_DETAIL_V3> {
  detail: BillingObj[];
  meta: Meta;
  query: {};
}

export const requestBillingDetail: ActionCreator<RequestBillingDetailAction> = args => ({
  type: REQUEST_BILLING_DETAIL_V3,
  args
})

export const getBillingDetail: ActionCreator<GetBillingDetailAction> = (detail, meta, query) => ({
  type: GET_BILLING_DETAIL_V3,
  detail,
  meta,
  query
})

export interface RequestWorkspaceBillingAction extends Action<typeof REQUEST_WORKSPACE_BILLING_V3> {
  args: any;
}

interface GetWorkspaceBillingAction extends Action<typeof GET_WORKSPACE_BILLING_V3> {
  data: WorkspaceBillingObj[];
  meta: Meta;
  query: {};
}

export const requestWorkspaceBillings: ActionCreator<RequestWorkspaceBillingAction> = args => ({
  type: REQUEST_WORKSPACE_BILLING_V3,
  args
})

export const getWorkspaceBillings: ActionCreator<GetWorkspaceBillingAction> = (data, meta, query) => ({
  type: GET_WORKSPACE_BILLING_V3,
  data,
  meta,
  query
})

export interface RequestWorkspaceBillingDetailAction extends Action<typeof REQUEST_WORKSPACE_BILLING_DETAIL_V3> {
  args: any;
}

interface GetWorkspaceBillingDetailAction extends Action<typeof GET_WORKSPACE_BILLING_DETAIL_V3> {
  data: BillingObj[];
  meta: Meta;
  query: {};
}

export const requestWorkspaceBillingDetail: ActionCreator<RequestWorkspaceBillingDetailAction> = args => ({
  type: REQUEST_WORKSPACE_BILLING_DETAIL_V3,
  args
})

export const getWorkspaceBillingDetail: ActionCreator<GetWorkspaceBillingDetailAction> = (data, meta, query) => ({
  type: GET_WORKSPACE_BILLING_DETAIL_V3,
  data,
  meta,
  query
})

export interface RequestMonthBillingAction extends Action<typeof REQUEST_MONTH_BILLING_V3> {
  args: any;
}

interface GetMonthBillingAction extends Action<typeof GET_MONTH_BILLING_V3> {
  data: BillingObj[];
  meta: Meta;
  query: {};
}

export const requestMonthBilling: ActionCreator<RequestMonthBillingAction> = args => ({
  type: REQUEST_MONTH_BILLING_V3,
  args
})

export const getMonthBilling: ActionCreator<GetMonthBillingAction> = (data, meta, query) => ({
  type: GET_MONTH_BILLING_V3,
  data,
  meta,
  query
})

export type BillingAction =
  | GetOrgBillingAction
  | GetOrgBillingOVAction
  | GetBillingDetailAction
  | GetMonthBillingAction
  | GetWorkspaceBillingDetailAction
  | GetWorkspaceBillingAction
