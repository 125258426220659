import * as React from 'react'
import { Input, Icon } from 'antd'
import { SearchProps } from 'antd/lib/input'
import classNames from 'classnames'
import FilterIcon from '-!svg-react-loader!images/svg/filter.svg'
import { t } from 'i18next'
import './index.css'

interface Props extends SearchProps {
  iconStyle?: React.CSSProperties;
  containerClassName?: string;
  filterIcon?: boolean;
}

export default class SearchBar extends React.PureComponent<Props, {}> {
  static defaultProps = {
    iconStyle: {},
    containerClassName: ''
  }
  render() {
    const { iconStyle, className, placeholder, containerClassName, filterIcon, ...other } = this.props
    return (
      <div className={classNames('search-container', containerClassName)}>
        <Input.Search
          className={classNames('search-input', className)}
          {...other}
          placeholder={placeholder || t('Search by collection name, indicator name, or data source UUID')}
          prefix={
            filterIcon ? (
              <FilterIcon />
            ) : (
              <Icon
                type='search'
                style={{
                  color: '#9b9b9b',
                  fontWeight: 'bold',
                  marginLeft: 10,
                  fontSize: 13,
                  ...iconStyle
                }}
              />
            )
          }
        />
      </div>
    )
  }
}
