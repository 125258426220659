import * as React from 'react'
import Notification from 'containers/Notification'
import NotificationSetting from 'containers/NotificationSetting'
import { Switch, Route } from 'react-router-dom'
import { BasicDeny } from 'components/Permission'
import { connect } from 'react-redux'

type StateProps = {
  hasPro: boolean
}

class NotificationRoute extends React.PureComponent<StateProps, {}> {
  notificationRoute = () => (
    <Switch>
      <Route exact={true} path='/notification/settings' component={NotificationSetting} />
      <Route exact={true} path='/notification' component={Notification} />
      <Route />
    </Switch>
  )

  render() {
    const { hasPro } = this.props
    return hasPro ? this.notificationRoute() : <BasicDeny />
  }
}

const mapStateToProps: MapState<StateProps> = ({ V2: { userShip } }) => {
  const hasPro = userShip.data.some(w => w.pro)
  return {
    hasPro
  }
}

export default connect(mapStateToProps)(NotificationRoute)
