import DatasourceIcon from '-!svg-react-loader!images/svg/dataSource.svg';
import FilterIcon from '-!svg-react-loader!images/svg/filter.svg';
import { requestDatasources } from 'actions/V3/datasources';
import { Menu, Popover } from 'antd';
import classnames from 'classnames';
import Table from 'components/Custom/Table';
import SearchBar from 'components/SearchBar';
import { t } from 'i18next';
import { isEqual, mapValues, pick, pickBy, values, xor } from 'lodash';
import qs from 'qs';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LEVEL_DICT } from 'utils/constant';
import { queryMerge } from 'utils/query';
import './index.css';
import { P, State, StateProps } from './type';

const keysArray = {
  status: ['online', 'offline'],
  parameter: ['air', 'electricity']
};

class DatasourcesTable extends React.Component<P, State> {
  state: State = {
    filterSelected: []
  };
  filterTranslation = LEVEL_DICT();

  geneColumns = [
    {
      title: (
        <span>
          <span className='primary'>UUID</span>
          <span className='second'>{t('DATA SOURCE NAME')}</span>
        </span>
      ),
      dataIndex: 'name',
      key: 'name',
      className: 'td-1 data-source-td-mobile',
      render: (text, record) => {
        return (
          <span>
            <span>{record.identifier}</span>
            <span className='uuid'>{text || '-'}</span>
          </span>
        );
      }
    },
    {
      title: t('MODEL'),
      dataIndex: 'model_name',
      key: 'model_name',
      className: 'hidden-mobile'
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: 120,
      render: (text) => (
        <span className={'status ' + text}>
          {this.filterTranslation[text] || text}
        </span>
      )
    },
    {
      title: t('Parameters'),
      dataIndex: 'parameters',
      key: 'parameters',
      width: 100,
      className: 'hidden-mobile',
      render: (arr) => (
        <span className='second'>
          {arr.map((v) => this.filterTranslation[v] || v).join(', ')}
        </span>
      )
    },
    {
      title: t('ASSIGNMENTS'),
      dataIndex: 'unassigned_count',
      key: 'unassigned_count',
      width: 200,
      className: 'hidden-mobile',
      render: (v, record) => (
        <span className={v ? 'link' : 'second'}>
          {record.last_received_time
            ? v
              ? t('datasource.data channels unassigned', { count: v })
              : t('datasource.all data channels assigned')
            : t('datasource.waiting data')}
        </span>
      )
    }
  ];

  componentDidMount() {
    this._requestDatasources();
  }

  UNSAFE_componentWillUpdate(next: P) {
    if (!isEqual(this.props.location, next.location)) {
      this._requestDatasources(next);
    }
  }

  getQueryFilter = (props = this.props) => {
    const {
      location: { search, pathname },
      history
    } = props;
    const q = qs.parse(search.slice(1));

    const searchQuery: any = pick(q, ['page', 'name']);

    let filterQuery: any = mapValues(pick(q, ['status', 'parameter']), (v, k) =>
      typeof v === 'string' && keysArray[k]
        ? v
          .split(',')
          .filter((value) => keysArray[k].includes(value))
          .toString()
        : v
    );

    filterQuery = pickBy(filterQuery, Boolean);

    const result = { ...searchQuery, ...filterQuery };
    const query = qs.stringify(result);
    if (query !== search.slice(1).replace(/lang=\w+&?/, '')) {
      history.replace({
        pathname,
        search: `?${query}`
      });

      return false;
    }

    return result;
  };

  _requestDatasources = (props = this.props, q = {}) => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props;

    const query = this.getQueryFilter(props);
    if (!query) {
      return;
    }

    dispatch(
      requestDatasources({
        ...q,
        ...query,
        workspace_id: +id
      })
    );
  };

  navToDetail = (datasourceID) => {
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props;
    history.push(`/workspaces/${id}/datasources/${datasourceID}`);
  };

  onRowHandler = (record) => {
    return {
      onClick: () => {
        this.navToDetail(record.id);
      }
    };
  };

  selectPage = (page) => {
    const {
      location: { search, pathname },
      history,
      query: q
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        page: page
      },
      delKeys: page === 1 ? ['page'] : []
    });
    history.push({
      pathname,
      search: query
    });
    this._requestDatasources(this.props, { ...q, page });
  };

  pagination = () => {
    const { meta } = this.props;
    return meta
      ? {
        hideOnSinglePage: true,
        showQuickJumper: true,
        onChange: this.selectPage,
        current: meta.current_page,
        defaultPageSize: 20,
        total: meta.total_count,
        showTotal: () => {
          return t('showTotal', { total: meta.total_count });
        }
      }
      : {};
  };

  filterChange = (key, type) => {
    const q = this.getQueryFilter();
    const old = q[type] ? q[type].split(',') : [];
    const copy = xor(old, [key]);

    const {
      location: { search, pathname },
      history
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        [type]: copy.toString()
      },
      delKeys: copy.length ? ['page'] : [type, 'page']
    });

    history.push({
      pathname,
      search: query
    });
  };

  getFilterValue = () => {
    const filter = pick(this.getQueryFilter(), ['status', 'parameter']);
    return values(filter).reduce((p, n) => p.concat(n.split(',')), []);
  };

  onSearch = (text: string) => {
    const name = text.trim();
    const {
      dispatch,
      location: { search },
      history,
      match: {
        params: { id }
      }
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        name
      },
      delKeys: name ? ['page', 'size'] : ['page', 'size', 'name']
    });
    const pathname = `/workspaces/${id}/datasources`;
    history.push({
      pathname,
      search: query
    });
    dispatch(
      requestDatasources({
        name,
        workspace_id: +id
      })
    );
  };

  renderMenu = () => {
    const filterSelected = this.getFilterValue();
    const filterOptions = [
      {
        title: t('State'),
        type: 'status',
        childrens: keysArray.status
      },
      {
        title: t('Parameters'),
        type: 'parameter',
        childrens: keysArray.parameter
      }
    ];
    return (
      <div className={classnames('location-filter')}>
        <Menu mode='inline' multiple={true} selectedKeys={filterSelected}>
          {filterOptions.map((group, index) => (
            <Menu.SubMenu key={group.title} title={group.title}>
              {group.childrens.map((item, i) => (
                <Menu.Item
                  key={item}
                  onClick={() => this.filterChange(item, group.type)}
                >
                  <span className='grey_text small_text menu_text'>
                    {this.filterTranslation[item]}
                  </span>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu>
      </div>
    );
  };

  renderHighlight = (text) => {
    if (!text) {
      return null;
    }
    const { query } = this.props;
    return query && (query as any).name ? (
      <Highlighter
        searchWords={[(query as any).name]}
        autoEscape={true}
        textToHighlight={text}
      />
    ) : (
      text
    );
  };

  render() {
    const {
      datasources,
      meta,
      location: { search }
    } = this.props;
    const { filterSelected } = this.state;

    if (!meta) {
      return null;
    }

    const data = datasources.map((item, i) => ({
      ...item,
      key: i,
      name: this.renderHighlight(item.name),
      identifier: this.renderHighlight(item.identifier)
    }));
    const name = pick(qs.parse(search.slice(1)), ['name']).name || '';

    return (
      <>
        <div className='ds-table-header'>
          <div className='ds-table-label'>
            <DatasourceIcon />
            <span>{t('All Data Sources')}</span>
          </div>
          <div className='ds-right'>
            <div className='ds-filter'>
              <Popover
                overlayClassName='header-notification-overlay is-menu'
                content={this.renderMenu()}
                placement='bottom'
                trigger='click'
              >
                <FilterIcon />
                <span className='small_text grey_text noti-options-filter_text'>
                  {t('filter.Filter')}
                </span>
                {!!filterSelected.length && (
                  <span className='noti-options-badge'>
                    {filterSelected.length}
                  </span>
                )}
              </Popover>
            </div>
            <span className='ds-summary'>
              {t('datasource.total', { count: meta.total_count })}
            </span>
          </div>
        </div>
        <div className='indicator-active-search'>
          <SearchBar
            defaultValue={name as string}
            onSearch={this.onSearch}
            placeholder={t('datasource.Search by data source UUID or name')}
          />
        </div>
        <Table
          onRow={this.onRowHandler}
          type='light'
          size='default'
          rowClassName={() => 'ds-datasource-item'}
          columns={this.geneColumns}
          dataSource={data}
          pagination={this.pagination()}
        />
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { datasources } }) => ({
  datasources: datasources.data,
  meta: datasources.meta,
  query: datasources.query
});

export default withRouter(connect(mapStateToProps)(DatasourcesTable));
