import * as React from 'react';
import { Radio } from 'antd';
import { RadioProps } from 'antd/lib/radio';
import classNames from 'classnames';
import './RadioGroup.css';

interface Props {
  value: string;
  size?: string;
  list: {
    value: string;
    label: string;
  }[];
}

const CustomRadioGroup: React.SFC<Props & RadioProps> = React.memo(
    ({ className, value, size, list, ...other }) => (
        <Radio.Group
            defaultValue={value}
            buttonStyle='solid'
            size={size}
            className={classNames(className, 'custom-radio-group')}
            {...other as any}
        >
            {list.map((item, i) => (
                <Radio.Button key={i} value={item.value}>
                    {item.label}
                </Radio.Button>
            ))}
        </Radio.Group>
    )
);

export default CustomRadioGroup;
