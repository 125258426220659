import { Action, ActionCreator } from 'redux'

export const REQUEST_GRAPH = 'REQUEST_GRAPH'
export const GET_GRAPH = 'GET_GRAPH'

export type GraphObj = {
  readings: {
    name: string,
    ref_id: number,
    ref_name: string,
    data: number[],
    times: string[],
    type: string,
    cycle: number
  }[],
  dataList: {
    outdoor?: boolean,
    label: string,
    color: string,
    data: number[]
  }[],
  times: string[],
  indicator: string,
  workHours?: number[][],
  configs: {
    enable_working_hours: boolean,
    search_time_enabled_at: string,
    working_hours: {
      begin_time: string,
      day_in_week: number,
      end_time: string
    }[]
  },
  units: {
    pm2p5_display?: string,
    temperature?: string,
    pm2p5?: string,
    tvoc?: string,
    humidity?: string,
    co2?: string,
    pm10?: string,
    pm100?: string,
    light?: string,
    sound?: string,
    o3?: string,
    no2?: string,
    so2?: string,
    ap?: string,
    pc?: string
  }
}

export interface RequestGraphAction extends Action<typeof REQUEST_GRAPH> {
  args: {
    location_id: number,
    station_id?: string,
    location_average?: boolean,
    outdoor?: boolean,
    indicator?: string,
    multi_cycles?: boolean,
    begin_time: string,
    end_time: string
  };
}

interface GetGraphAction extends Action<typeof GET_GRAPH> {
  graph: GraphObj;
}

export type GraphAction = GetGraphAction

export const requestGraph: ActionCreator<RequestGraphAction> = args => ({
  type: REQUEST_GRAPH,
  args
})

export const getGraph: ActionCreator<GetGraphAction> = (graph: GraphObj) => ({
  type: GET_GRAPH,
  graph
})
