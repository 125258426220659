import * as React from 'react'
import { t } from 'i18next'
import { updateSiteState } from 'actions/uiState'
import { notification } from 'antd'
import { cleanStore } from 'actions/global'
import { store } from 'index'
import { replace } from 'connected-react-router'
import './index.css'

export const SuggestLogin: React.SFC<{}> = React.memo(() => (
  <>
    {t('exception.access')}
    {t('exception.try')}
    <a
      className='notification-link'
      onClick={() => {
        const { href, origin } = window.location
        store.dispatch(cleanStore())
        store.dispatch(
          updateSiteState({
            redirectURL: href.replace(origin, '')
          })
        )
        store.dispatch(replace('/user/sign_in'))
        notification.close('global Error')
      }}
    >
      {t('exception.login')}
    </a>
  </>
))
