import { Action, ActionCreator } from 'redux'
export const REQUEST_USER_CONFIG_V3 = 'REQUEST_USER_CONFIG_V3'
export const GET_USER_CONFIG_V3 = 'GET_USER_CONFIG_V3'
export const SET_USER_CONFIG_V3 = 'SET_USER_CONFIG_V3'

export type UserConfigObj = {
  id: number,
  notification_period: string,
  notification_channel_condition: string,
  notification_channel_status: string,
  tutorial_guide_clicked: boolean,
  orgs: {
    id: number,
    name: string,
    permission: 'view' | 'edit',
    owner: boolean
  }[]
}

export interface RequestUserConfigV3Action extends Action<typeof REQUEST_USER_CONFIG_V3> {
  args: any;
}

interface GetUserConfigV3Action extends Action<typeof GET_USER_CONFIG_V3> {
  userConfig: UserConfigObj;
}

interface SetUserConfigV3Action extends Action<typeof SET_USER_CONFIG_V3> {
  userConfig: UserConfigObj;
}

export type UserConfigV3Action = GetUserConfigV3Action | SetUserConfigV3Action

export const requestUserConfigV3: ActionCreator<RequestUserConfigV3Action> = args => ({
  type: REQUEST_USER_CONFIG_V3,
  args
})

export const getUserConfig: ActionCreator<GetUserConfigV3Action> = (userConfig: UserConfigObj) => ({
  type: GET_USER_CONFIG_V3,
  userConfig
})

export const setUserConfig: ActionCreator<SetUserConfigV3Action> = (userConfig: UserConfigObj) => ({
  type: SET_USER_CONFIG_V3,
  userConfig
})
