import * as ConfigV3 from 'actions/V3/config'

export type ConfigState = {
  data?: ConfigV3.Config
}

export default function Config(state: ConfigState = {}, action: ConfigV3.GetConfigAction) {
  switch (action.type) {
    case ConfigV3.GET_CONFIG:
      return {
        data: action.data
      }
    default:
      return state
  }
}
