import { put, call } from 'redux-saga/effects'

import {
  REQUEST_INDICATOR_V3,
  getIndicator,
  REQUEST_INDICATOR_DETAIL_V3,
  REQUEST_INDICATOR_AVAILABLE_DATA_SOURCE_V3,
  REQUEST_INDICATOR_DATA_SOURCE_V3,
  getIndicatorDetail,
  getIndicatorAvailabelDataSource
} from 'actions/V3/indicators'
import { watchRequest, watchRequestNoCancel } from 'sagas/helper'

import { apiV3 } from 'utils/api'

type Args = {
  size?: number,
  page?: number,
  name?: string,
  data_source_id?: number,
  data_source_identifier?: string,
  collection_id?: number,
  workspace_id: number
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/indicators', 'get', args)

  yield put(getIndicator(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_INDICATOR_V3, request)

function* requestDetail(args: { indicator_id: number }) {
  const { data } = yield call(apiV3, `/indicators/${args.indicator_id}`)

  yield put(getIndicatorDetail(data))
  return data
}

export const indicatorDetailWatcher = watchRequest(REQUEST_INDICATOR_DETAIL_V3, requestDetail)

function* requestAvailableDataSource({ indicator_id, ...query }: { indicator_id: number }) {
  const { data, meta } = yield call(apiV3, `/indicators/${indicator_id}/available_data_sources`, 'get', query)

  yield put(getIndicatorAvailabelDataSource(data, meta, query))
  return data
}

export const indicatorAvailableDataSourceWatcher = watchRequest(
  REQUEST_INDICATOR_AVAILABLE_DATA_SOURCE_V3,
  requestAvailableDataSource
)

function* requestDataSource({ indicator_id, ...query }: { indicator_id: number }) {
  const { data, meta } = yield call(apiV3, '/indicators/available_data_sources', 'get', query)

  yield put(getIndicatorAvailabelDataSource(data, meta, query))
  return data
}

export const indicatorDataSourceWatcher = watchRequest(REQUEST_INDICATOR_DATA_SOURCE_V3, requestDataSource)
