import CalibrationIcon from '-!svg-react-loader!images/svg/calibration.svg';
import { requestCollection } from 'actions/V3/collections';
import { requestDatasources } from 'actions/V3/datasources';
import { requestIndicator } from 'actions/V3/indicators';
import { requestV3Location } from 'actions/V3/location';
import { requestTableData } from 'actions/V3/tabledata';
import { Button, DatePicker, Icon, Input, Modal, Popover, Select } from 'antd';
import CustomSelect from 'components/Custom/Select';
import Table from 'components/Custom/Table';
import { P, State, StateProps } from 'containers/DataTable/type';
import { t } from 'i18next';
import { debounce } from 'lodash';
import moment from 'moment-timezone';
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { promiseDispatch } from 'utils';
import { apiV3 } from 'utils/api';
import { dataTypeObjs } from 'utils/constant';
import { Guard } from 'utils/guard';
import { queryMerge } from 'utils/query';
import { formatZoneToOffset } from 'utils/timeZone';
import './index.css';

const { MonthPicker } = DatePicker;
const Option = Select.Option;
const filterObjs = [
  {
    name: t('Report.Working Hours'),
    value: true
  },
  {
    name: t('Report.All Hours'),
    value: false
  }
];
const categories = {
  location: t('Location'),
  collection: t('Collection'),
  indicator: t('Indicator'),
  data_source: t('Data Source')
};

@Guard
class DataTable extends React.PureComponent<P, State> {
  state: State = {
    workHoursFilter: filterObjs[1],
    modal: '',
    currentMonth: '',
    average_type: 60,
    pagination: {
      defaultPageSize: 20,
      current: 1,
      hideOnSinglePage: true
    }
  };

  componentDidMount() {
    this.fetchTable({}, this.props);
  }

  UNSAFE_componentWillReceiveProps(nextP: P) {
    const {
      location: { pathname, search }
    } = this.props;
    const { location } = nextP;
    if (pathname !== location.pathname || search !== location.search) {
      this.fetchTable({}, nextP);
    }
  }

  getCurrentDatatype = (cate) => {
    const value = cate !== 'data_source' ? 'hour' : 'raw';
    const defaultType = dataTypeObjs()[0];
    return defaultType.value === value ? defaultType : dataTypeObjs()[1];
  };

  getParams = (props = this.props) => {
    const { average_type, outdoor_location_id, outdoor_collection_id } =
      this.state;

    const {
      cate,
      location_id,
      indicator_id,
      datasource_id,
      collection_id,
      tz
    } = this.getQuery(props);

    const currentDatatype = this.getCurrentDatatype(cate).value;
    const { begin_time, end_time } = this.getDate(props);

    let { currentMonth } = this.state;

    const params: any = {
      t: currentDatatype,
      category: cate,
      begin_time,
      end_time
    };

    currentMonth = currentMonth || moment(params.end_time).format('YYYY-MM');
    if (params.begin_time.indexOf(currentMonth) < 0) {
      params.begin_time = moment(currentMonth)
        .startOf('month')
        .format('YYYY-MM-DD');
    }
    if (params.end_time.indexOf(currentMonth) < 0) {
      params.end_time = moment(currentMonth)
        .endOf('month')
        .format('YYYY-MM-DD');
    }

    if (!location_id && !collection_id && !datasource_id && !indicator_id) {
      return;
    }

    if (tz) {
      params.time_zone = tz;
    }

    if (outdoor_location_id) {
      params.outdoor_location_id = outdoor_location_id;
    }

    if (outdoor_collection_id) {
      params.outdoor_collection_id = outdoor_collection_id;
    }

    if (currentDatatype === 'hour') {
      params.t = average_type + '-minutes';
    }

    if (location_id) {
      params.location_id = location_id;
    }

    if (collection_id) {
      params.collection_id = collection_id;
    }

    if (indicator_id) {
      params.indicator_id = indicator_id.split(',');
    }

    if (datasource_id) {
      params.data_source_id = datasource_id;
    }

    return params;
  };

  fetchTable = async (query = {}, props = this.props) => {
    const params: any = this.getParams(props);
    const { dispatch } = props;
    if (!params) {
      return;
    }

    if (params.collection_id) {
      // 获取collection相关联的Locations供选择要做对比的outdoor
      apiV3(`/collections/${params.collection_id}/locations`, 'get').then(
        (resp: any) => {
          let list = [];
          resp.data.forEach((item) => {
            list = list.concat(
              item.collections.map((e) => {
                e.name = item.name + ' ' + e.name;
                return e;
              })
            );
          });

          this.setState({
            outdoorCollectionList: list
          });
        }
      );
    }

    if (params.location_id) {
      await promiseDispatch({
        dispatch,
        actionCreator: requestV3Location,
        payload: { location_id: params.location_id }
      });
    }

    await promiseDispatch({
      dispatch,
      actionCreator: requestTableData,
      payload: {
        ...params,
        ...query
      }
    });

    const { base } = this.props;

    if (base && base.work_hours && base.work_hours.active) {
      this.setWorkHoursFilter(0);
    }
    this.setState({
      time_zone: params.time_zone || (base && base.time_zone),
      pagination: {
        defaultPageSize: 20,
        current: 1,
        hideOnSinglePage: true
      }
    });
  };

  getQuery = (
    props = this.props
  ): {
    cate?: string;
    indicator_id?: string;
    location_id?: number;
    datasource_id?: number;
    collection_id?: number;
    begin_time?: string;
    end_time?: string;
    tz?: string;
  } => {
    const {
      location: { search }
    } = props;

    const parsedQuery = qs.parse(search.slice(1));

    return {
      cate: typeof parsedQuery.cate === 'string' ? parsedQuery.cate : undefined,
      indicator_id: typeof parsedQuery.indicator_id === 'string' ? parsedQuery.indicator_id : undefined,
      location_id: typeof parsedQuery.location_id === 'string' ? parseInt(parsedQuery.location_id) : undefined,
      datasource_id: typeof parsedQuery.datasource_id === 'string' ? parseInt(parsedQuery.datasource_id) : undefined,
      collection_id: typeof parsedQuery.collection_id === 'string' ? parseInt(parsedQuery.collection_id) : undefined,
      begin_time: typeof parsedQuery.begin_time === 'string' ? parsedQuery.begin_time : undefined,
      end_time: typeof parsedQuery.end_time === 'string' ? parsedQuery.end_time : undefined,
      tz: typeof parsedQuery.tz === 'string' ? parsedQuery.tz : undefined
    };
  };


  getDate = (props = this.props) => {
    let { begin_time, end_time } = this.getQuery(props);

    end_time =
      begin_time && end_time ? end_time : moment().format('YYYY-MM-DD');
    begin_time = begin_time || moment().startOf('month').format('YYYY-MM-DD');

    return { begin_time, end_time };
  };

  setDateBar = (props = this.props) => {
    const { begin_time, end_time } = this.getDate(props);
    const dateDict: any = {};
    const currentDate = moment(end_time);
    // 计算begin_time 到 end_time之间有哪几个月份
    while (currentDate > moment(begin_time).startOf('month')) {
      dateDict[currentDate.year()] = dateDict[currentDate.year()] || [];
      dateDict[currentDate.year()].push(currentDate.format('YYYY-MM'));
      currentDate.subtract(1, 'months');
    }
    this.setState(
      {
        dateBar: dateDict,
        currentMonth: moment(end_time).format('YYYY-MM')
      }
    );
  };

  setCurrentPeriod = (date) => {
    const {
      location: { search, pathname },
      history
    } = this.props;
    let query = '';
    query = queryMerge({
      search,
      query: {
        begin_time: date.startOf('month').format('YYYY-MM-DD'),
        end_time: date.endOf('month').format('YYYY-MM-DD')
      },
      delKeys: date ? [] : ['begin_time', 'end_time']
    });
    history.replace({
      pathname,
      search: query
    });
  };

  setTimezone = (tz) => {
    const {
      location: { search, pathname },
      history
    } = this.props;
    let query = '';
    query = queryMerge({
      search,
      query: {
        tz
      },
      delKeys: tz ? [] : ['tz']
    });
    history.replace({
      pathname,
      search: query
    });
  };

  setWorkHoursFilter = (idx) => {
    this.setState({
      workHoursFilter: filterObjs[idx]
    });
  };

  setCurrentStandard = (idx) => {
    const { certifications } = this.props;
    if (idx === -1) {
      this.setState({
        currentStandard: undefined
      });
      return;
    }
    if (certifications) {
      this.setState({
        currentStandard: certifications[idx]
      });
    }
  };

  Guard_downloadFile = () => {
    this.setState({
      modal: 'input'
    });
  };

  GuardTrigger_downloadFile = () => (
    <Button
      className='datatable-download'
      icon='download'
      type='primary'
      onClick={this.Guard_downloadFile}
    >
      {t('DataTable.Download File')}
    </Button>
  );

  geneTableColumns = () => {
    const { workHoursFilter, currentStandard, time_zone } = this.state;
    const { base, meta } = this.props;
    const hasTz = !!time_zone;
    const offset = hasTz && formatZoneToOffset(time_zone!);
    const indicatorList = meta.data_channels || [];
    if (!indicatorList.length) {
      return [];
    }

    const firstColWid = 150;
    let width = (880 - firstColWid) / indicatorList.length;
    if (width < 150) {
      width = 150;
    }

    const columns: any = [
      {
        title: t('Datatable.READING TIME'),
        dataIndex: 'reading_time',
        key: 'reading_time',
        width: firstColWid,
        fixed: 'left',
        render: (text, record) => {
          const readingTime = new Date(record.reading_time.replace(/-/g, '/'));
          const time = hasTz
            ? moment.tz(readingTime, time_zone!)
            : moment(readingTime);
          const currentDay = time.format('YYYY-MM-DD');
          let inWorkingHours = false;
          if (
            base &&
            base.work_hours &&
            base.work_hours.active &&
            workHoursFilter.value
          ) {
            const day = time.isoWeekday();
            const workingHoursRange = base.work_hours.data[day === 7 ? 0 : day];
            if (workingHoursRange && workingHoursRange.active) {
              const beginT = hasTz
                ? moment(
                  `${currentDay}T${workingHoursRange.begin_time}:00${offset}`
                )
                : moment(`${currentDay} ${workingHoursRange.begin_time}`);
              const endT = hasTz
                ? moment(
                  `${currentDay}T${workingHoursRange.end_time}:00${offset}`
                )
                : moment(`${currentDay} ${workingHoursRange.end_time}`);

              inWorkingHours = time.isBetween(beginT, endT, undefined, '[]');
            }
          }
          const result = time.format('MM/DD HH:mm');

          if (inWorkingHours) {
            return (
              <span className='datatable-time-item'>
                {result}
                <i className='material-icons datatable-wh-icon'>access_time</i>
              </span>
            );
          }
          return result;
        }
      }
    ];
    indicatorList.forEach((item) => {
      columns.push({
        title: item.name,
        dataIndex: item.channel,
        key: item.channel,
        width,
        render: (text, record) => {
          const rawDatas = record[item.raw_channel + '_raw'];
          if (rawDatas) {
            return (
              <Popover
                title={t('Data was calibrated')}
                content={`${t('Raw Data')}: ${rawDatas.join(',')}`}
              >
                <div className='datatable-calibrated'>
                  {text} <CalibrationIcon />
                </div>
              </Popover>
            );
          }
          if (
            currentStandard &&
            +text < currentStandard.standards.default[item.raw_channel]
          ) {
            return <div className='datatable-valid'>{text}</div>;
          }
          return text;
        }
      });
    });
    return columns;
  };

  handleModalConfirm = async () => {
    const { downloadFileName } = this.state;
    const { meta } = this.props;
    const indicators = meta.data_channels!.map((e) => e.channel);
    const params = Object.assign(this.getParams(), {
      name: downloadFileName,
      count: meta ? meta.total_count : 0,
      indicators
    });
    // call downloads post api
    await apiV3('/downloads', 'post', params);
    this.setState({
      modal: 'complete'
    });
  };

  navToDownloadCenter = () => {
    const { history } = this.props;
    history.push('/download');
  };

  handleModalCancel = () => {
    this.setState({
      modal: ''
    });
  };

  onFocusInput = (e) => {
    e.currentTarget.select();
  };

  onChangeInput = (e) => {
    this.setState({
      downloadFileName: e.currentTarget.value
    });
  };

  geneModalFooter = () => {
    return (
      <div className='download-modal-footer'>
        <Button type='default' size='large' onClick={this.handleModalCancel}>
          {t('Cancel')}
        </Button>
        <Button type='primary' size='large' onClick={this.handleModalConfirm}>
          {t('Confirm')}
        </Button>
      </div>
    );
  };

  geneModalFooterReady = () => {
    return (
      <div className='download-modal-footer center'>
        <Button type='primary' size='large' onClick={this.navToDownloadCenter}>
          {t('Go To Download Center')}
        </Button>
      </div>
    );
  };

  selectPage = (page) => {
    const { query } = this.props;
    this.fetchTable({ ...query, page });
  };

  handleTableChange = (pagination) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
  };

  pagination = () => {
    const { meta, tabledata } = this.props;

    return meta && (meta.data_channels || []).length
      ? {
        hideOnSinglePage: true,
        showQuickJumper: true,
        onChange: this.selectPage,
        current: meta.current_page,
        total: meta.total_count,
        pageSize: tabledata.length > 20 ? tabledata.length : 20
      }
      : {};
  };

  // 设置日期范围选择器组件的禁止选择日期参数
  // 禁止选择location created_at 之前的 和 当前时间之后的日期
  disabledDate = (date) => {
    const { base, workspaceDetail } = this.props;
    // 最多选择 6 个月数据 or 2年？
    const { cate } = this.getQuery();
    if (cate === 'data_source') {
      return (
        date < moment().subtract(2, 'years') || date > moment().endOf('month')
      );
    }
    if (workspaceDetail) {
      moment(workspaceDetail.created_at).startOf('month').isSameOrAfter(date) ||
        moment().isBefore(date);
    }
    if (base) {
      return (
        date < moment(base.search_time_enabled_at) ||
        date > moment().endOf('month')
      );
    }
    return date > moment().endOf('month');
  };

  getTableMessage = () => {
    const { meta } = this.props;
    const indicatorList = meta.data_channels || [];
    // 设置 table 高度 和 宽度，以便固定表头和固定列
    const tableHeight = 400;
    const tableWidth = 150 * (indicatorList.length + 1);
    return {
      tableHeight,
      tableWidth
    };
  };

  onChangeCategory = (cate) => {
    const {
      history,
      location: { search, pathname }
    } = this.props;

    const query = queryMerge({
      search,
      query: {
        cate
      },
      delKeys: cate
        ? ['location_id', 'indicator_id', 'datasource_id', 'collection_id']
        : ['cate']
    });
    history.replace({
      pathname,
      search: query
    });
    this.setState({
      outdoor_location_id: undefined,
      outdoor_collection_id: undefined,
      outdoorCollectionList: undefined,
      time_zone: undefined
    });
  };

  onChangeLocation = (locationId) => {
    const {
      history,
      location: { search, pathname }
    } = this.props;

    const query = queryMerge({
      search,
      query: {
        location_id: locationId
      },
      delKeys: locationId ? [] : ['location_id']
    });
    history.replace({
      pathname,
      search: query
    });
  };

  onCollectionChanged = (cid) => {
    const {
      history,
      location: { search, pathname }
    } = this.props;

    const query = queryMerge({
      search,
      query: {
        collection_id: cid
      },
      delKeys: cid ? [] : ['collection_id']
    });

    history.replace({
      pathname,
      search: query
    });
  };

  onIndicatorChanged = (id) => {
    const {
      history,
      location: { search, pathname }
    } = this.props;

    if (id.length > 5) {
      return;
    }

    const query = queryMerge({
      search,
      query: {
        indicator_id: id.join(',')
      },
      delKeys: id.length ? [] : ['indicator_id']
    });
    history.replace({
      pathname,
      search: query
    });
  };

  onDatasourceChanged = (id) => {
    const {
      history,
      location: { search, pathname }
    } = this.props;

    const query = queryMerge({
      search,
      query: {
        datasource_id: id
      },
      delKeys: id ? [] : ['datasource_id']
    });
    history.replace({
      pathname,
      search: query
    });
  };

  setAverageType = (v) => {
    this.setState(
      {
        average_type: v
      },
      this.fetchTable
    );
  };

  setOutdoorNeeded = (id) => {
    const { locationList } = this.props;
    const { location_id, collection_id } = this.getQuery(this.props);
    if (id === 0) {
      this.setState(
        {
          outdoor_location_id: undefined,
          outdoor_collection_id: undefined
        },
        this.fetchTable
      );
      return;
    }
    if (location_id) {
      const location = locationList.find((e) => e.id === +location_id);
      if (location) {
        this.setState(
          {
            outdoor_location_id: location.outdoor!.id
          },
          this.fetchTable
        );
      }
    }
    if (collection_id) {
      this.setState(
        {
          outdoor_collection_id: +id
        },
        this.fetchTable
      );
    }
  };

  onBlurSelector = () => {
    this.setState({
      showNotFound: false
    });
  };

  onSelectorSearch = async (v, ty) => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props;
    if (!v) {
      this.setState({
        showNotFound: false
      });
      return;
    }
    switch (ty) {
      case 'location':
        break;
      case 'collection':
        await promiseDispatch({
          dispatch,
          actionCreator: requestCollection,
          payload: { workspace_id: id, name: v }
        });
        break;
      case 'indicator':
        await promiseDispatch({
          dispatch,
          actionCreator: requestIndicator,
          payload: { workspace_id: id, data_source_identifier: v }
        });
        break;
      case 'data_source':
        await promiseDispatch({
          dispatch,
          actionCreator: requestDatasources,
          payload: { workspace_id: id, identifier: v }
        });
        break;
      default:
        break;
    }
    this.setState({
      showNotFound: true
    });
  };

  defaultDownloadFileName = () => {
    const { begin_time, end_time } = this.getDate();
    const { cate } = this.getQuery(this.props);
    const { meta } = this.props;
    // 默认下载文件名
    const cdt = cate !== 'data_source' ? 'Average' : 'Raw';
    const endStr = `${cdt}_${begin_time}_${end_time}`;
    if (meta.data_source_id) {
      return `Datasource(${meta.data_source_name}) ${endStr}`;
    }
    if (meta.indicators) {
      return `Indicators ${meta.indicators
        .map((i) => i.indicator_name)
        .join('&')} ${endStr}`;
    }
    if (meta.collection_id) {
      return `Collection ${meta.collection_name} ${endStr}`;
    }
    if (meta.location_id) {
      return `Location ${meta.location_name} ${endStr}`;
    }
    return endStr;
  };

  GuardRender_datatable = () => {
    const {
      tabledata,
      certifications,
      meta,
      base,
      locationList,
      datasourceList,
      query
    } = this.props;

    const { begin_time } = this.getDate();
    const {
      workHoursFilter,
      currentStandard,
      modal,
      average_type,
      outdoorCollectionList,
      time_zone,
      pagination,
      showNotFound
    } = this.state;

    const { cate, location_id, collection_id, indicator_id, datasource_id } =
      this.getQuery();
    const data = tabledata.map((item, i) => {
      const extObj: any = {
        key: i
      };
      return Object.assign({}, item, extObj);
    });

    const tableMessage = this.getTableMessage();

    const returnIndicators = () => {
      if (base && base.average) {
        return base.collections[0].readings.map((item, i) => (
          <Option key={i} value={item.indicator_id}>
            {item.name}
          </Option>
        ));
      }
      return;
    };

    return (
      <div className='datatable-content'>
        <div className='datasource-selector-wrap'>
          <div className='datasource-selector-tips'>{t('datatable.tip')}</div>
          <div className='datasource-selector-header'>
            <div className='datatable-header primary'>
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('datatable.type')}
                </div>
                <CustomSelect
                  value={cate}
                  dropdownMatchSelectWidth={true}
                  className='datasource-selector-type'
                  onChange={this.onChangeCategory}
                >
                  {Object.keys(categories).map((item, i) => (
                    <Option key={i} value={item}>
                      {categories[item]}
                    </Option>
                  ))}
                </CustomSelect>
              </div>
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {`${t('datatable.range')}`}
                </div>
                <MonthPicker
                  value={moment(begin_time)}
                  disabledDate={this.disabledDate}
                  onChange={this.setCurrentPeriod}
                  className='datasource-selector-date-picker'
                  allowClear={false}
                />
              </div>
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('datatable.timezone')}
                </div>
                <CustomSelect
                  dropdownMatchSelectWidth={true}
                  showSearch
                  value={time_zone || 'Asia/Shanghai'}
                  placeholder={t('datatable.Select timezone')}
                  style={{ width: '220px' }}
                  onChange={this.setTimezone}
                >
                  {moment.tz.names().map((name, index) => (
                    <Select.Option key={index} value={name}>
                      [{moment().tz(name).format('Z')}] {name}
                    </Select.Option>
                  ))}
                </CustomSelect>
              </div>
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {`${t('datatable.data averaging')}`}
                </div>
                <CustomSelect
                  dropdownMatchSelectWidth={true}
                  value={average_type}
                  style={{ width: '160px' }}
                  disabled={cate === 'data_source'}
                  onChange={this.setAverageType}
                >
                  {[30, 60].map((item, i) => (
                    <Option key={i} value={item}>
                      {t('minutes', { value: item })} {t('Average')}
                    </Option>
                  ))}
                </CustomSelect>
                <div className='datatable-average-label'>
                  {cate === 'data_source' && this.getCurrentDatatype(cate).name}
                </div>
              </div>
            </div>
            {tabledata.length !== 0 && (
              <div className='datatable-header-right'>
                {this.GuardTrigger_downloadFile()}
              </div>
            )}
          </div>
          <div className='datatable-header'>
            {cate === 'location' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('Location Name')}
                </div>
                <CustomSelect
                  onChange={this.onChangeLocation}
                  showSearch={true}
                  style={{ width: 200 }}
                  placeholder={t('datatable.Select location')}
                  filterOption={(input, option) =>
                    (option.props.children as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={location_id ? +location_id : location_id}
                  onSearch={(v) => this.onSelectorSearch(v, 'location')}
                  optionFilterProp='children'
                  dropdownMatchSelectWidth={true}
                >
                  {locationList.map((loc, i) => (
                    <Option key={i} value={loc.id}>
                      {loc.name}
                    </Option>
                  ))}
                </CustomSelect>
              </div>
            )}
            {cate === 'collection' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('Collection Name')}
                </div>
                <CustomSelect
                  onChange={this.onCollectionChanged}
                  showSearch={true}
                  allowClear={true}
                  onBlur={this.onBlurSelector}
                  notFoundContent={showNotFound ? t('Not Found') : ''}
                  style={{ width: 200 }}
                  placeholder={t('datatable.Type to Search')}
                  filterOption={(input, option) =>
                    (option.props.children as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  value={collection_id ? +collection_id : collection_id}
                  onSearch={debounce(
                    (v) => this.onSelectorSearch(v, 'collection'),
                    500
                  )}
                  optionFilterProp='children'
                  dropdownMatchSelectWidth={true}
                >
                  {base &&
                    base.collections.map((item, i) => (
                      <Option key={i} value={item.id}>
                        {item.name}
                      </Option>
                    ))}
                  {/* {!showNotFound && meta.collection_id ? (
                    <Option value={meta.collection_id}>
                      {meta.collection_name}
                    </Option>
                  ) : (
                    showNotFound &&
                    collectionListData.map((col, i) => (
                      <Option key={i} value={col.id}>
                        {col.name}
                      </Option>
                    ))
                  )} */}
                </CustomSelect>
              </div>
            )}
            {cate === 'collection' && outdoorCollectionList && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('datatable.outdoor collection')}
                </div>
                <CustomSelect
                  onChange={this.setOutdoorNeeded}
                  style={{ minWidth: 300 }}
                  placeholder={t('datatable.Select collection')}
                  dropdownMatchSelectWidth={true}
                >
                  <Option key={-1} value={0}>
                    {t('DataTable.no')}
                  </Option>
                  {outdoorCollectionList.map((col, i) => (
                    <Option key={i} value={col.id}>
                      {col.name}
                    </Option>
                  ))}
                </CustomSelect>
              </div>
            )}
            {cate === 'indicator' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('Indicator') + ' (' + t('DataTable.max tip') + ')'}
                </div>
                <CustomSelect
                  showSearch={true}
                  mode='multiple'
                  onBlur={this.onBlurSelector}
                  notFoundContent={showNotFound ? t('Not Found') : ''}
                  placeholder={t('datatable.Select indicator')}
                  onChange={this.onIndicatorChanged}
                  onSearch={debounce(
                    (v) => this.onSelectorSearch(v, 'indicator'),
                    500
                  )}
                  dropdownMatchSelectWidth={false}
                  filterOption={false}
                  value={indicator_id ? indicator_id.split(',') : indicator_id}
                >
                  {returnIndicators()}
                  {/* {!showNotFound && meta.indicators
                    ? meta.data_channels.map(ind => (
                        <Option
                          key={ind.channel}
                          value={ind.channel.split('_')[1]}
                        >
                          {ind.name}
                        </Option>
                      ))
                    : showNotFound &&
                      indicatorList
                        .filter(e => e.data_source)
                        .map((ind, i) => (
                          <Option key={ind.id} value={String(ind.id)}>
                            {ind.name + '(' + ind.data_source!.identifier + ')'}
                          </Option>
                        ))} */}
                </CustomSelect>
              </div>
            )}
            {cate === 'data_source' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>{t('UUID')}</div>
                <CustomSelect
                  style={{ width: 200 }}
                  showSearch={true}
                  onBlur={this.onBlurSelector}
                  notFoundContent={showNotFound ? t('Not Found') : ''}
                  placeholder={t('datatable.Select datasource')}
                  onChange={this.onDatasourceChanged}
                  onSearch={debounce(
                    (v) => this.onSelectorSearch(v, 'data_source'),
                    500
                  )}
                  dropdownMatchSelectWidth={true}
                  filterOption={(input, option) =>
                    (option.props.children as string)
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  optionFilterProp='children'
                  value={datasource_id ? +datasource_id : undefined}
                >
                  {datasourceList &&
                    datasourceList.map((ds, i) => (
                      <Option key={ds.id} value={ds.id}>
                        {ds.identifier}
                      </Option>
                    ))}
                </CustomSelect>
              </div>
            )}
            {cate === 'location' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>{t('Filter')}</div>
                <CustomSelect
                  value={workHoursFilter.name}
                  onChange={this.setWorkHoursFilter}
                >
                  {filterObjs.map((item, i) => (
                    <Option key={i} value={i}>
                      {item.name}
                    </Option>
                  ))}
                </CustomSelect>
              </div>
            )}
            {cate === 'location' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('Performance')}
                </div>
                <CustomSelect
                  dropdownTextCenter={true}
                  value={
                    (currentStandard ? currentStandard.name : t('None')) +
                    ' ' +
                    t('Standard')
                  }
                  onChange={this.setCurrentStandard}
                >
                  <Option key={-1} value={-1}>
                    {t('None')}
                  </Option>
                  {certifications.map((item, i) => (
                    <Option key={i} value={i}>
                      {item.name}
                    </Option>
                  ))}
                </CustomSelect>
              </div>
            )}
            {cate === 'location' && (
              <div className='datatable-dropdown'>
                <div className='datatable-dropdown-label'>
                  {t('DataTable.Outdoor')}
                </div>
                <CustomSelect
                  defaultValue={0}
                  dropdownTextCenter={true}
                  onChange={this.setOutdoorNeeded}
                >
                  <Option value={0}>{t('DataTable.no')}</Option>
                  <Option value={1}>{t('DataTable.yes')}</Option>
                </CustomSelect>
              </div>
            )}
          </div>
        </div>
        <div className='graph-tips'>
          <small className='grey_text'>{t('Please note that currently')}</small>
        </div>
        {(location_id || collection_id || indicator_id || datasource_id) && (
          <div className='datatable-panel'>
            <Table
              type='light'
              className='shadow-right'
              border={true}
              scroll={{ y: 400, x: tableMessage.tableWidth }}
              pagination={cate !== 'indicator' ? this.pagination() : pagination}
              centerAlign={true}
              columns={this.geneTableColumns()}
              dataSource={
                meta.data_channels && meta.data_channels.length ? data : []
              }
              onChange={this.handleTableChange}
              size='small'
            />
          </div>
        )}
        <Modal
          closable={true}
          maskClosable={true}
          centered={true}
          visible={!!modal}
          bodyStyle={{
            padding: '20px 30px'
          }}
          footer={
            modal === 'complete'
              ? this.geneModalFooterReady()
              : this.geneModalFooter()
          }
          title={modal === 'complete' ? ' ' : t('Download Data')}
          onCancel={this.handleModalCancel}
          wrapClassName='download-modal'
        >
          {modal === 'complete' ? (
            <div className='download-modal-ready'>
              <Icon
                style={{ fontSize: 30, color: '#4794B1' }}
                type='check-circle'
              />
              <div className='download-modal-ready-tips'>
                {t('datatable.Data has started to be generated')}
                <br />
                {t('datatable.You can go to the Download Center')}
              </div>
            </div>
          ) : (
            <div>
              {query && (
                <div className='download-modal-line'>
                  <span className='download-modal-label'>{t('Date')}:</span>
                  <span className='download-modal-date'>
                    {moment(query.begin_time).format('YYYY.MM.DD')}
                  </span>
                  <span className='download-modal-span'> - </span>
                  <span className='download-modal-date'>
                    {moment(query.end_time).format('YYYY.MM.DD')}
                  </span>
                </div>
              )}
              <div className='download-modal-line'>
                <span className='download-modal-label'>
                  {t('Data Number')}:
                </span>
                <span>{meta && meta.total_count}</span>
              </div>
              <div className='download-modal-input'>
                <span className='download-modal-label'>{t('File Name')}:</span>
                <Input
                  id='mark'
                  onChange={this.onChangeInput}
                  onFocus={this.onFocusInput}
                  type='text'
                  defaultValue={this.defaultDownloadFileName()}
                />
              </div>
            </div>
          )}
        </Modal>
      </div>
    );
  };

  render() {
    return this.GuardRender_datatable();
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V2: { certifications },
  V3: {
    locationSelect,
    location,
    collections,
    indicators,
    datasources,
    tabledata,
    workspace
  }
}) => ({
  tabledata: tabledata.data,
  meta: tabledata.meta,
  query: tabledata.query,
  certifications: certifications.data,
  locationList: locationSelect.data,
  base: location.base!,
  collectionList: collections.data,
  indicatorList: indicators.data,
  datasourceList: datasources.data,
  workspaceDetail: workspace.data
});

export default withRouter(connect(mapStateToProps)(DataTable));
