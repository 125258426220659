import { GraphAction, GET_GRAPH, GraphObj } from 'actions/V2/graph'

export type GraphState = {
  data?: GraphObj
}

export default function Graph(state: GraphState = {}, action: GraphAction) {
  switch (action.type) {
    case GET_GRAPH:
      return {
        data: action.graph
      }
    default:
      return state
  }
}
