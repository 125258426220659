import { UserConfigV3Action, GET_USER_CONFIG_V3, SET_USER_CONFIG_V3, UserConfigObj } from 'actions/V3/userConfig'

export type UserConfigState = {
  data?: UserConfigObj
}

export default function UserConfig(state: UserConfigState = {}, action: UserConfigV3Action) {
  switch (action.type) {
    case GET_USER_CONFIG_V3:
      return {
        data: action.userConfig
      }
    case SET_USER_CONFIG_V3:
      return {
        data: action.userConfig
      }
    default:
      return state
  }
}
