import { put, call } from 'redux-saga/effects'
import * as actions from 'actions/V3/location'
import { watchRequestNoCancel } from 'sagas/helper'
import { updateSiteState } from 'actions/uiState'
import { apiV3 } from 'utils/api'
import { sortKey } from 'utils/constant'
function* request(args) {
  // 记录location_id
  yield put(
    updateSiteState({
      locationID: args.location_id + ''
    })
  )

  const { data } = yield call(apiV3, `/locations/${args.location_id}`, 'get', {
    ...args
  })
  // 对collection indicator 按名字固定 排序
  data.collections.forEach(collection => {
    collection.readings.sort((a, b) => sortKey.indexOf(a.indicator) - sortKey.indexOf(b.indicator))
  })
  yield put(actions.getV3LocationBase(data))
  return data
}

export default watchRequestNoCancel(actions.REQUEST_LOCATION_BASE_V3, request)

function* requestLocationEdit(args: { location_id: number }) {
  const { data } = yield call(apiV3, `/locations/${args.location_id}/edit`)

  yield put(actions.getV3LocationEdit(data))
  return data
}

export const watchLocationEdit = watchRequestNoCancel(actions.REQUEST_LOCATION_EDIT_V3, requestLocationEdit)

function* requestLocationUpdate(args: { location_id: number, form: actions.LocationBaseUpdate }) {
  const { data } = yield call(apiV3, `/locations/${args.location_id}`, 'patch', {
    location: args.form
  })

  yield put(actions.getV3LocationUpdate(data))
  return data
}

export const watchLocationUpdate = watchRequestNoCancel(actions.REQUEST_LOCATION_UPDATE_V3, requestLocationUpdate)
