import { Action, ActionCreator } from 'redux'
export const REQUEST_LOCATION_SELECT_V3 = 'REQUEST_LOCATION_SELECT_V3'
export const GET_LOCATION_SELECT_V3 = 'GET_LOCATION_SELECT_V3'

export type Location = {
  id: number,
  name: string,
  outdoor?: {
    id: number,
    name: string
  }
}

export interface RequestSelectAction extends Action<typeof REQUEST_LOCATION_SELECT_V3> {
  args: any;
}

export interface GetSelect extends Action<typeof GET_LOCATION_SELECT_V3> {
  data: Location[];
}

export const requestLocationSelect = (args: {
  workspace_id: number,
  page?: number,
  size?: number,
  show?: boolean
}): RequestSelectAction => ({
  type: REQUEST_LOCATION_SELECT_V3,
  args
})

export const getLocationSelect: ActionCreator<GetSelect> = data => ({
  type: GET_LOCATION_SELECT_V3,
  data
})
