import * as React from 'react'
import { Affix } from 'antd'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import scrollama from 'scrollama'
import './index.css'

type Props = {
  offsetTop?: number,
  scrollConfig?: (hash: string) => any,
  trigerName: string
}

type P = Props & RouteComponentProps<{}>

class ScrollAffix extends React.PureComponent<P, {}> {
  static defaultProps = {
    offsetTop: 0
  }

  scroller: any

  componentDidMount() {
    this.bindScroller()
  }

  componentWillUnmount() {
    this.scroller.destroy()
  }

  bindScroller() {
    if (this.scroller) {
      this.scroller.destroy()
    }
    this.scroller = scrollama()
    const { trigerName } = this.props
    this.scroller
      .setup({
        step: trigerName,
        offset: 1
      })
      .onStepEnter(({ element }) => {
        ;[].forEach.call(document.querySelectorAll('.scroll-affix-list li a'), (node: any) => {
          node.className = ''
        })

        const currentNode = document.querySelectorAll(`.scroll-affix-list li a[href="${element.id}"]`)[0]

        if (currentNode) {
          currentNode.className = 'current'
        }
      })
  }

  UNSAFE_componentWillReceiveProps(next: P) {
    const {
      location: { hash },
      scrollConfig
    } = this.props
    const {
      location: { hash: newHash }
    } = next

    if (hash !== newHash) {
      const elm = document.getElementById(newHash)
      if (elm) {
        elm.scrollIntoView(
          scrollConfig
            ? scrollConfig(newHash)
            : {
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
              }
        )

        this.bindScroller()
      }
    }
  }
  render() {
    const { offsetTop, children } = this.props
    return (
      <Affix className='scroll-affix' offsetTop={offsetTop}>
        {children}
      </Affix>
    )
  }
}

export default withRouter(ScrollAffix)
