import { put, call } from 'redux-saga/effects';
import { REQUEST_GRAPH, getGraph } from 'actions/V2/graph';
import { watchRequestNoCancel } from 'sagas/helper';
import { calcWorkingHourRange } from 'utils/helper';
import { updateSiteState } from 'actions/uiState';
import { graphColorList } from 'utils/constant';
import { apiV3 } from 'utils/api';
import moment from 'moment-timezone';

export let lastReqArgs: any = {};
let kioskScrollOffset = 0;
function* request(args: any) {
    if (args.kioskInterval) {
        const modal = document.querySelector('.kiosk-page--content-modal');
        if (modal) {
            kioskScrollOffset = modal.scrollTop;
        }
    }
    // 记录location_id
    yield put(
        updateSiteState({
            locationID: args.location_id + ''
        })
    );
    const { data } = yield call(apiV3, '/graphs', 'get', {
        ...args,
        multi_cycles: true
    });

    lastReqArgs = args;

    const OUTDOOR_TYPE = 'outdoor_location';
    data.times = data.readings[0].times;
    // 任意有某个collection 的 cycle 是 30 分钟的话
    const cycle30 = data.readings.filter(item => item.cycle === 30);
    const hasCycle30 = cycle30.length > 0;
    if (hasCycle30) {
        data.times = cycle30[0].times;
    }
    if (args.time_zone) {
        data.times = data.times.map(t => {
            const timestamp = (new Date(t)).getTime()
            // 校准times, 上海时间与标准时区差值 - 当前时区与标准时区差值
            const offset = moment.tz.zone('Asia/Shanghai')!.utcOffset(timestamp)
            const offset2 = moment.tz.zone(args.time_zone)!.utcOffset(timestamp)
            return moment(timestamp + (offset - offset2) * 60 * 1000).format('YYYY-MM-DD HH:mm')
        })
    }
    data.dataList = [];
    data.readings.forEach((item, i) => {
        const obj = {
            outdoor: item.type === OUTDOOR_TYPE,
            label: item.name,
            color: graphColorList[i % graphColorList.length],
            data: []
        };
        // if (!args.indicator || args.indicator === 'pm2p5') {
        //   item.data.forEach((p, index) => {
        //     // 过滤pm2p5异常值, 暂定 800
        //     if (p > 800) {
        //       item.data[index] = null;
        //     }
        //   });
        // }
        if (hasCycle30 && item.cycle !== 30) {
            obj.data = item.data.map(s => [s, s]).reduce((a, b) => a.concat(b));
            obj.data.pop();
        } else {
            obj.data = item.data;
        }
        data.dataList.push(obj);
    });

    if (data.configs.working_hours) {
        data.workHours = calcWorkingHourRange(
            data.times,
            data.configs.working_hours
        );
    }

    yield put(getGraph(data));
    if (args.kioskInterval) {
        const modal: any = document.querySelector('.kiosk-page--content-modal');
        if (modal && kioskScrollOffset) {
            modal.scrollTop = kioskScrollOffset;
        }
    }
    return data;
}

export default watchRequestNoCancel(REQUEST_GRAPH, request);
