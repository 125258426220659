/* eslint-disable */
import SibeiBackButtonImage from '-!svg-react-loader!images/svg/SibeiBackButton.svg';
import * as React from 'react';
import loadScript from 'utils/loadScript';
import './index.css';

// The original URL has been hosted on Qlear CDN because gitee disallow injecting script in webpage.
// const uniWebView = 'https://gitee.com/dcloud/uni-app/raw/dev/dist/uni.webview.1.5.4.js'
const uniWebView = 'https://assets-1.qlear.io/sibei/uni.webview.1.5.4.js'
const webBrowserIdentification = "Mozilla"

type State = Readonly<{
  bridgeReady: boolean
}>

declare global {
  interface Uni { webView: any }
}

declare var uni: Uni;

export default class SibeiBackButton extends React.PureComponent<{}, State> {
  constructor(props) {
    super(props)
    this.state = {
      bridgeReady: false
    }
  }

  renderFloatingButton = () => {
    const { bridgeReady } = this.state
    return bridgeReady && (
      <div id='sibeiBackButton' onClick={this.onClick}>
        <div className='floating-button'>
          <SibeiBackButtonImage className='sibei-back-button' />
        </div>
      </div>
    )
  }

  onClick() {
    console.log('Jumping')

    uni.webView.postMessage({
      data: {
        data: {
          jumpPage: 'appList'
        },
        callback: 'jumpTo'
      }
    })
  }

  setupScript() {
    loadScript(uniWebView).then(() => {
      console.log('Sibei script loaded!');
      document.addEventListener('UniAppJSBridgeReady', () => {
        console.log('Bridge ready');
        this.setState({
          bridgeReady: true
        })
      })
    })
  }

  render() {
    const userAgent = window.navigator.userAgent;
    if(userAgent.includes(webBrowserIdentification)) {
      // Load the script
      this.setupScript();

      // Render the button element
      return this.renderFloatingButton()
    }
    return ""
  }
}
