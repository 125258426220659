import { put, call } from 'redux-saga/effects'
import * as actions from 'actions/V3/locationList'
import { watchRequestNoCancel } from 'sagas/helper'
import { apiV3 } from 'utils/api'

function* request(args: any) {
  const { data, meta } = yield call(apiV3, '/locations', 'get', {
    ...args
  })

  yield put(actions.getLocations(data, meta, args))
  return data
}

export default watchRequestNoCancel(actions.REQUEST_LOCATION_LIST_V3, request)

function* requestLocationListAvailable(args: any) {
  const { data } = yield call(apiV3, '/kiosks/available_locations', 'get', {
    ...args
  })

  yield put(actions.getLocationsAvailable(data))
  return data
}

export const watchLocationListAvailable = watchRequestNoCancel(
  actions.REQUEST_LOCATION_LIST_AVAILABLE_V3,
  requestLocationListAvailable
)
