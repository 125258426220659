import * as React from 'react';
import cache from 'sagas/cache';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import SearchBar from 'components/SearchBar';
import Table from 'components/Custom/Table';
import { apiV3 } from 'utils/api';
import { Badge, Dropdown, Icon, Menu } from 'antd';
import { changeModal } from 'actions/uiState';
import { connect } from 'react-redux';
import { P, State, StateProps } from './type';
import { pick } from 'lodash';
import { queryMerge } from 'utils/query';
import { REQUEST_INDICATOR_V3, requestIndicator } from 'actions/V3/indicators';
import { t } from 'i18next';
import { withRouter } from 'react-router-dom';
import './index.css';
import { translations } from 'utils/constant';

class IndicatorTable extends React.PureComponent<P, State> {
    state: State = {
        selected: []
    };

    rowSelection = {
        onChange: (_, selectedRows) => {
            this.setState({
                selected: selectedRows.map(v => v.id)
            });
        }
    };

    menu = (
        <Menu>
            <Menu.Item>
                <a onClick={() => this.setStatus('enable')}>
                    {t('indicator.mark as active')}
                </a>
            </Menu.Item>
            <Menu.Item>
                <a onClick={() => this.setStatus('disable')}>
                    {t('indicator.mark as inactive')}
                </a>
            </Menu.Item>
        </Menu>
    );

    componentDidMount() {
        const {
            location: { search }
        } = this.props;

        const query = pick(qs.parse(search.slice(1)), ['page', 'name', 'q']);

        if (typeof query.page === 'string') {
            query.page = String(query.page);
        }

        if (query.name) {
            query.q = query.name;
            delete query.name;
        }

        this.fetchIndicators(query);

    }

    changeModal = (modal: ModalType): void => {
        this.props.dispatch(changeModal(modal));
    };

    columns = () => {
        const { selected } = this.state;
        const showMarkAs = !!selected.length;
        return [
            {
                title: t('Name'),
                key: 'indicatorName',
                dataIndex: 'indicatorName',
                render: (text, record) => (
                    <div>
                        <Badge {...record.active} />
                        {text}
                    </div>
                )
            },
            {
                title: showMarkAs ? (
                    <Dropdown
                        trigger={['click']}
                        overlay={this.menu}
                        placement='bottomCenter'
                    >
                        <a className='dark_text'>
                            {t('indicator.mark as')}
                            <Icon type='down' />
                        </a>
                    </Dropdown>
                ) : (
                    t('Status')
                ),
                key: 'state',
                dataIndex: 'state'
            },
            {
                title: !showMarkAs ? t('Parameter') : null,
                key: 'parameter',
                render: text => <span className='grey_text'>{text}</span>,
                dataIndex: 'parameter'
            },
            {
                title: !showMarkAs ? t('Datasource') : null,
                key: 'dataSource',
                dataIndex: 'dataSource'
            },
            {
                title: !showMarkAs ? t('indicator.# of Collections') : null,
                key: 'collections',
                dataIndex: 'collections',
                render: text => <b>{text}</b>
            }
        ];
    };

    fetchIndicators = (query = {}) => {
        const {
            match: {
                params: { id }
            },
            dispatch
        } = this.props;
        dispatch(requestIndicator({ ...query, workspace_id: +id }));
    };

    selectPage = page => {
        const {
            location: { search, pathname },
            history,
            query: q
        } = this.props;
        const query = queryMerge({
            search,
            query: {
                page: page
            },
            delKeys: page === 1 ? ['page'] : []
        });
        history.push({
            pathname,
            search: query
        });
        this.fetchIndicators({ ...q, page });
    };

    onSearch = (text: string): void => {
        const name = text.trim();
        const {
            dispatch,
            location: { search },
            history,
            match: {
                params: { id }
            }
        } = this.props;

        const query = queryMerge({
            search,
            query: {
                name
            },
            delKeys: name ? ['page', 'size'] : ['page', 'size', 'name']
        });

        history.push({
            pathname: `/workspaces/${id}/indicators`,
            search: query
        });

        dispatch(
            requestIndicator({
                q: name,
                workspace_id: +id
            })
        );
    };

    setStatus = async (status: 'enable' | 'disable') => {
        const { query } = this.props;

        await apiV3(`/indicators/${status}`, 'post', {
            indicator_id: this.state.selected
        });
        cache.cleanCache(REQUEST_INDICATOR_V3);
        this.fetchIndicators({ ...query });
    };

    pagination = () => {
        const { meta } = this.props;
        return meta
            ? {
                hideOnSinglePage: true,
                showQuickJumper: true,
                onChange: this.selectPage,
                current: meta.current_page,
                defaultPageSize: 20,
                total: meta.total_count
            }
            : {};
    };

    renderHighlight = text => {
        const { query } = this.props;
        return query && (query as any).q ? (
            <Highlighter
                searchWords={[(query as any).q]}
                autoEscape={true}
                textToHighlight={text}
            />
        ) : (
            text
        );
    };

    createTable = () => {
        const { indicators } = this.props;
        const activeProps = {
            status: 'processing',
            className: 'active-badge indicator-table_badge'
        };
        const inActiveProps = {
            status: 'default',
            className: 'indicator-table_badge'
        };
        return indicators.map((v, i) => ({
            key: i,
            id: v.id,
            status: v.status,
            active: v.status === 'enable' ? activeProps : inActiveProps,
            indicatorName: this.renderHighlight(v.name),
            parameter: v.data_channel_name,
            state: translations[v.state],
            collections: v.collections.length,
            dataSource:
                v.data_source && this.renderHighlight(v.data_source.identifier)
        }));
    };

    rowClick = record => {
        const {
            match: {
                params: { id }
            },
            history
        } = this.props;
        return {
            onClick: () => history.push(`/workspaces/${id}/indicators/${record.id}`)
        };
    };

    render() {
        const { meta } = this.props;
        if (!meta) return null;
        const {
            location: { search }
        } = this.props;
        const name = pick(qs.parse(search.slice(1)), ['name']).name || '';
        const tableData = this.createTable();
        return (
            <>
                <div className='indicator-active-title'>
                    <span className='indicator-active-count'>
                        <Badge status='processing' className='active-badge' />
                        {t('indicator.active count')}
                        <b>{meta.total_active_count}</b>
                    </span>
                    <span className='indicator-active-count'>
                        <Badge status='default' />
                        {t('indicator.inactive count')}
                        <b>{meta.total_count! - meta.total_active_count!}</b>
                    </span>
                </div>

                <div className='indicator-active-search'>
                    <SearchBar
                        defaultValue={name as string}
                        containerClassName='indicator-active-searchbar'
                        placeholder={t('collection.searchIndicator')}
                        onSearch={this.onSearch}
                    />
                    {/* <Button
            customType='light'
            icon='plus'
            onClick={() => this.changeModal('AssignList')}
          >
            {t('indicator.add indicator')}
          </Button> */}
                </div>

                {meta && (
                    <Table
                        type='light'
                        size='default'
                        onRow={this.rowClick}
                        pagination={this.pagination()}
                        rowSelection={this.rowSelection}
                        rowClassName={(record: any) => {
                            return record.status === 'disable'
                                ? 'indicator-disable_row'
                                : 'ds-datasource-item';
                        }}
                        columns={this.columns()}
                        className='collection-table'
                        dataSource={tableData}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { indicators } }) => ({
    indicators: indicators.data,
    meta: indicators.meta,
    query: indicators.query
});

export default withRouter(connect(mapStateToProps)(IndicatorTable));
