import { Action, ActionCreator } from 'redux'

export const REQUEST_USER_SHIP = 'REQUEST_USER_SHIP'
export const GET_USER_SHIP = 'GET_USER_SHIP'

export type WorkspaceItem = {
  code: string,
  id: number,
  name: string,
  permission: 'regular' | 'admin' | 'system',
  pro: boolean,
  visibility: 'show' | 'hide'
}
export type RequestUserShipAction = Action<typeof REQUEST_USER_SHIP>

interface GetUserShipAction extends Action<typeof GET_USER_SHIP> {
  data: WorkspaceItem[];
  all: WorkspaceItem[];
}

export type UserShipAction = GetUserShipAction

export const requestUserShip: ActionCreator<RequestUserShipAction> = args => ({
  type: REQUEST_USER_SHIP,
  args
})

export const getUserShip: ActionCreator<GetUserShipAction> = (data: WorkspaceItem[], all: WorkspaceItem[]) => ({
  type: GET_USER_SHIP,
  data,
  all
})
