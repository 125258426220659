import { put, call } from 'redux-saga/effects'
import { t } from 'i18next'
import {
  REQUEST_KIOSK_V3,
  getKiosks,
  REQUEST_KIOSK_EDIT_V3,
  getKioskEdit,
  REQUEST_KIOSK_DELETE_V3,
  getKioskDelete,
  REQUEST_KIOSK_DETAIL_V3,
  getKioskDetail,
  REQUEST_KIOSK_NEW_V3,
  getKioskNew,
  REQUEST_KIOSK_EDIT_INFO_V3,
  getKioskEditInfo
} from 'actions/V3/kiosks'
import { watchRequest, watchRequestNoCancel } from 'sagas/helper'
import { apiV3, resHandle } from 'utils/api'
import cache from 'sagas/cache'
import { history } from 'index'

type Args = {
  size?: number,
  page: number,
  client_id: number,
  name?: string,
  id?: number,
  location_id?: number
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/kiosks', 'get', { ...args })
  yield put(getKiosks(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_KIOSK_V3, request)

type EditArgs = {
  kiosk_id: number,
  data: {
    background_image: string,
    primary_data_channel: string,
    locked: boolean,
    password: string
  }
}

type NewArgs = {
  location_id: string,
  collection_id: string,
  background_image: string,
  primary_data_channel: string,
  logo_image: string,
  locked: boolean,
  password: string
}

function* requestKioskNew(args: NewArgs) {
  const { data } = yield call(apiV3, '/kiosks', 'post', {
    kiosk: args
  })

  yield put(Object.assign(getKioskNew(data), { location_id: args.location_id }))
  return data
}

export const watchKioskNew = watchRequest(REQUEST_KIOSK_NEW_V3, requestKioskNew)

function* requestKioskEdit(args: EditArgs) {
  cache.cleanCache(REQUEST_KIOSK_V3)
  const { data } = yield call(apiV3, `/kiosks/${args.kiosk_id}`, 'patch', {
    kiosk: args.data
  })

  yield put(getKioskEdit(data))
  return data
}

export const watchKioskEdit = watchRequest(REQUEST_KIOSK_EDIT_V3, requestKioskEdit)

function* requestKioskDelete(args: { kiosk_id: number, location_id: number }) {
  cache.cleanCache(REQUEST_KIOSK_V3)
  const { data } = yield call(apiV3, `/kiosks/${args.kiosk_id}`, 'delete')
  yield put(getKioskDelete({ id: args.kiosk_id, location_id: args.location_id }))
  return data
}

export const watchKioskDelete = watchRequest(REQUEST_KIOSK_DELETE_V3, requestKioskDelete)

function* requestKioskDetail(args: any) {
  let data, meta
  try {
    const res = yield call(apiV3, `/kiosks/${args.kiosk_id}`, 'get', {
      ...args
    })
    data = res.data
    meta = res.meta
    data.id = args.kiosk_id
    const lock = meta.code === 10023 ? data : undefined
    const detail = meta.code === 10000 ? data : undefined
    yield put(getKioskDetail(detail, lock))
    return data
  } catch (error: any) {
    if (error.code === 10015) {
      if (window.FROM_V2) {
        const { kiosk_id, ...locationArg } = args
        const res = yield call(apiV3, `/locations/${args.kiosk_id}`, 'get', {
          ...locationArg,
          view: 'kiosk'
        })

        data = res.data
        meta = res.meta
        const lock = meta.code === 10023 ? data : undefined
        const detail = meta.code === 10000 ? data : undefined
        yield put(getKioskDetail(detail, lock))
        return data
      }

      history.replace('/404', {
        title: '404',
        message: `
          ${t('exception.notfound')}
          <p class="small_text">from: ${window.location.href}</p>
          `
      })
    } else {
      resHandle(error)
    }
  }
}

export const watchKioskDetail = watchRequestNoCancel(REQUEST_KIOSK_DETAIL_V3, requestKioskDetail)

function* requestKioskEditInfo(args: { kiosk_id: number }) {
  const { data } = yield call(apiV3, `/kiosks/${args.kiosk_id}/edit`, 'get')
  yield put(getKioskEditInfo(data))
  return data
}

export const watchKioskEditInfo = watchRequestNoCancel(REQUEST_KIOSK_EDIT_INFO_V3, requestKioskEditInfo)
