import CollectionIcon from '-!svg-react-loader!images/svg/Addcollection.svg'
import IndicatorIcon from '-!svg-react-loader!images/svg/Assign_Indicator.svg'
import RegisterHardwareIcon from '-!svg-react-loader!images/svg/RegisterHardware.svg'
import { changeModal, ModalChangeAction } from 'actions/uiState'
import { Tooltip } from 'antd'
import * as React from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Guard } from 'utils/guard'

@Guard
class IconGroup extends React.PureComponent<DispatchProp<ModalChangeAction> & RouteComponentProps<{}>, {}> {
  changeModal = (modal: ModalType): void => {
    this.props.dispatch(changeModal(modal))
  }

  Guard_addDatasource = () => {
    this.changeModal('AddDatasource')
  }

  Guard_addCollection = () => {
    this.changeModal('AddCollection')
  }

  Guard_assignIndicator = () => {
    this.changeModal('AssignList')
  }

  // Guard_addUser = () => {
  //   this.changeModal("AddUser");
  // };

  GuardHandle_addDatasource = (rule?: GuardRule, message?: string) => {
    const {
      location: { pathname }
    } = this.props
    const inDataSource = pathname.includes('datasources')

    const disable = rule && !rule.enable

    return (
      inDataSource &&
      (disable ? (
        <Tooltip title={message} className='hidden-mobile'>
          <RegisterHardwareIcon className='icon_disabled' />
        </Tooltip>
      ) : (
        <RegisterHardwareIcon onClick={this.Guard_addDatasource} className='hidden-mobile' />
      ))
    )
  }

  GuardHandle_addIndicator = (rule?: GuardRule, message?: string) => {
    const {
      location: { pathname }
    } = this.props
    const inIndicator = pathname.includes('indicators')

    const disable = rule && !rule.enable

    return (
      inIndicator &&
      (disable ? (
        <Tooltip title={message}>
          <IndicatorIcon className='icon_disabled' />
        </Tooltip>
      ) : (
        <IndicatorIcon onClick={this.Guard_assignIndicator} />
      ))
    )
  }

  GuardHandle_addCollection = (rule?: GuardRule, message?: string) => {
    const {
      location: { pathname }
    } = this.props

    const inCollection = pathname.includes('collections')

    const disable = rule && !rule.enable

    return (
      inCollection &&
      (disable ? (
        <Tooltip title={message} className='hidden-mobile'>
          <CollectionIcon className='icon_disabled' style={{ width: 30 }} />
        </Tooltip>
      ) : (
        <CollectionIcon style={{ width: 30 }} onClick={this.Guard_addCollection} className='hidden-mobile' />
      ))
    )
  }

  render() {
    return (
      <>
        <div className='overview-icons' style={{ cursor: 'pointer' }}>
          {this.GuardHandle_addDatasource()}
          {this.GuardHandle_addCollection()}
          {this.GuardHandle_addIndicator()}
        </div>
      </>
    )
  }
}

export default withRouter(connect()(IconGroup))
