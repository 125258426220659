import * as React from 'react'
import Table from 'components/Custom/Table'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { t } from 'i18next'
import { requestWorkspaceBillings, RequestWorkspaceBillingAction, WorkspaceBillingObj } from 'actions/V3/billing'
import { DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import './index.css'

type StateProps = {
  data: WorkspaceBillingObj[],
  meta?: Meta,
  query?: any
}

export type DispatchAction = RequestWorkspaceBillingAction

export type P = StateProps & DispatchProp<DispatchAction> & RouteComponentProps<{ id: string }>

class WorkspaceBilling extends React.PureComponent<P, {}> {
  componentDidMount() {
    this.fetchBilling()
  }

  fetchBilling = (page = 1) => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props

    dispatch(requestWorkspaceBillings({ id, page }))
  }

  geneTableColumns = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props
    return [
      {
        title: t('Home.Workspace ID'),
        dataIndex: 'workspace_code',
        width: '30%',
        key: 'workspace_code'
      },
      {
        title: t('Home.Workspace Name'),
        dataIndex: 'workspace_name',
        key: 'workspace_name',
        render: (text, record) => {
          return <div className='accent_text'>{text}</div>
        }
      },
      {
        title: t('Accrued Cost'),
        dataIndex: 'total_price',
        key: 'total_price',
        render: (text, record) => <div>CNY {text.toFixed(2)}</div>
      },
      {
        title: '',
        dataIndex: 'workspace_id',
        key: 'workspace_id',
        render: (text, record) =>
          record.total_price > 0 ? <Link to={`/billing/${id}/workspaces/${text}`}>{t('Billing By Month')}</Link> : '-'
      }
    ]
  }

  // pagination = () => {
  //   const { meta } = this.props;
  //   return meta
  //     ? {
  //         hideOnSinglePage: true,
  //         showQuickJumper: true,
  //         onChange: this.fetchBilling,
  //         current: meta.current_page,
  //         pageSize: 20,
  //         total: meta.total_count,
  //         showTotal: () => {
  //           return t("showTotal", { total: meta.total_count });
  //         }
  //       }
  //     : {};
  // };

  render() {
    const { data } = this.props
    const tableData = data.map((item, i) => ({
      key: i,
      ...item
    }))

    return (
      <div className='billing-by-locations'>
        <Table rowClassName={() => 'home-ws-row'} type='light' columns={this.geneTableColumns()} dataSource={tableData} size='default' />
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V3: {
    billing: { workspaceBillings }
  }
}) => workspaceBillings

export default withRouter(connect(mapStateToProps)(WorkspaceBilling))
