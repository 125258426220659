import { Action, ActionCreator } from 'redux'

export const REQUEST_CERTIFICATIONS = 'REQUEST_CERTIFICATIONS'
export const GET_CERTIFICATIONS = 'GET_CERTIFICATIONS'

export type CertificationObj = {
  id: number,
  name: string,
  logo: string,
  standards: {
    default: {
      co?: number,
      o3?: number,
      co2?: number,
      hcho?: number,
      pm10?: number,
      tvoc?: number,
      pm2p5?: number
    },
    high?: {
      co?: number,
      o3?: number,
      co2?: number,
      hcho?: number,
      pm10?: number,
      tvoc?: number,
      pm2p5?: number
    }
  }
}

export interface RequestCertificationsAction extends Action<typeof REQUEST_CERTIFICATIONS> {
  args: any;
}

interface GetCertificationsAction extends Action<typeof GET_CERTIFICATIONS> {
  certifications: CertificationObj[];
}

export type CertificationAction = GetCertificationsAction

export const requestCertifications: ActionCreator<RequestCertificationsAction> = args => ({
  type: REQUEST_CERTIFICATIONS,
  args
})

export const getCertifications: ActionCreator<GetCertificationsAction> = (certifications: CertificationObj[]) => ({
  type: GET_CERTIFICATIONS,
  certifications
})
