import { fork, all } from 'redux-saga/effects'
import certifications from 'sagas/V2/certifications'
import location, { watchOutdoor } from 'sagas/V2/location'
import graph from 'sagas/V2/graph'
import collections from 'sagas/V2/collections'
import userShip from 'sagas/V2/userShip'
import notification from 'sagas/V2/notification'
import { watchSignIn, watchSignUp, watchResetUserPWD } from 'sagas/V2/userSign'
import dictionary from 'sagas/V2/dictionary'
import downloads from 'sagas/V2/downloads'
import activities from 'sagas/V2/activities'
import accounts from 'sagas/V2/accounts'
import changelog, { watchAnnouncement } from 'sagas/V2/announcement'

export default function* watch() {
  yield all([
    // fork(report),
    // fork(watchGeneratePdf),
    fork(activities),
    fork(certifications),
    fork(location),
    fork(graph),
    fork(collections),
    fork(userShip),
    fork(notification),
    // fork(notificationConfig),
    fork(watchSignIn),
    fork(watchSignUp),
    fork(watchResetUserPWD),
    // fork(locationList),
    fork(dictionary),
    fork(downloads),
    fork(watchOutdoor),
    fork(accounts),
    fork(changelog),
    fork(watchAnnouncement)
  ])
}
