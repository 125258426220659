import { put, call } from 'redux-saga/effects'
import { REQUEST_ACCOUNTS, getAccounts } from 'actions/V2/accounts'
import { watchRequestNoCancel } from 'sagas/helper'

import api from 'utils/api'

function* request(args: { workspace_id: number }) {
  const { data, meta } = yield call(api, '/accounts', 'get', {
    ...args
  })

  yield put(getAccounts(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_ACCOUNTS, request)
