import * as React from 'react'
import Button from 'components/Custom/Button'

const TextRow: React.SFC<{ label: string, top?: number }> = React.memo(({ label, children, top }) => (
  <div className='collection-detail-row collection-detail-text_row' style={{ marginTop: top || 17 }}>
    <div className='collection-detail-row_left'>{label}</div>
    <div className='collection-detail-row_right'>{children}</div>
  </div>
))

export default TextRow

export const EditRow: React.SFC<{
  value: string,
  label: string,
  disabled?: boolean,
  onClick: () => void
}> = React.memo(({ value, label, onClick, disabled = false }) => (
  <div className='collection-edit_row'>
    <span>{value}</span>
    <Button customType='dark' disabled={disabled} onClick={onClick} className='collection-edit_row-button'>
      {label}
    </Button>
  </div>
))
