import * as React from 'react';
import moment from 'moment';
import Table from 'components/Custom/Table';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { P, StateProps } from 'containers/DownloadCenter/type';
import { requestDownloads } from 'actions/V3/downloads';
import { t } from 'i18next';
import { withRouter } from 'react-router-dom';
import { BasicDeny } from 'components/Permission';
import './index.css';

class DownloadCenter extends React.PureComponent<P> {
  status = {
      fail: t('fail'),
      success: t('success'),
      pending: t('pending')
  };
  categories = {
      location: t('Location'),
      collection: t('Collection'),
      indicator: t('Indicator'),
      datasource: t('Data Source')
  };
  componentDidMount() {
      this._requestDownloads();
  }

  _requestDownloads = (query = {}) => {
      const { dispatch } = this.props;
      dispatch(
          requestDownloads({
              ...query
          })
      );
  };

  geneTableColumns = () => {
      return [
          {
              title: t('Workspace'),
              dataIndex: 'workspace',
              key: 'workspace',
              fixed: false,
              width: 100
          },
          {
              title: t('Data Type'),
              dataIndex: 'category',
              key: 'category',
              fixed: false,
              width: 100,
              render: text => {
                  return this.categories[text];
              }
          },
          {
              title: t('Name'),
              dataIndex: 'id',
              key: 'id',
              fixed: false,
              width: 200,
              render: (text, record) => {
                  return record[
                      record.category === 'datasource' ? 'monitor' : record.category
                  ];
              }
          },
          {
              title: t('Frequency'),
              dataIndex: 't',
              key: 't',
              fixed: false,
              width: 150,
              render: text => {
                  return text;
              }
          },
          {
              title: t('Period'),
              dataIndex: 'period',
              key: 'period',
              fixed: false,
              width: 160,
              render: (text, record) => {
                  const st = moment(new Date(record.begin_time)).format('YYYY.MM.DD');
                  const ed = moment(new Date(record.end_time)).format('YYYY.MM.DD');
                  return (
                      <div className='download-period'>
                          <div>{st}</div>-<div>{ed}</div>
                      </div>
                  );
              }
          },
          {
              title: t('Create time'),
              dataIndex: 'created_at',
              key: 'created_at',
              fixed: false,
              width: 150,
              render: (text, record) =>
                  moment(new Date(record.created_at)).format('MM-DD HH:mm')
          },
          {
              title: t('File Name'),
              dataIndex: 'name',
              key: 'name',
              fixed: false,
              width: 240,
              render: (text, record) => {
                  return <div className='break-words'>{text || '-'}</div>;
              }
          },
          {
              title: t('Line'),
              dataIndex: 'lines',
              key: 'lines',
              fixed: false,
              width: 100
          },
          {
              title: t('Status'),
              dataIndex: 'status',
              key: 'status',
              fixed: 'right' as 'right',
              width: 100,
              render: (text, record) => {
                  return (
                      <span className={'download-status-' + text}>
                          {this.status[text]}
                      </span>
                  );
              }
          },
          {
              title: '',
              width: 60,
              fixed: 'right' as 'right',
              render: (text, record) => {
                  return (
                      <a href={record.url} download={record.name}>
                          <Icon
                              className={'download-td-btn ' + (record.url ? 'active' : '')}
                              type='download'
                          />
                      </a>
                  );
              }
          }
      ];
  };

  selectPage = page => {
      const { query: q } = this.props;
      this._requestDownloads({ ...q, page });
  };

  pagination = () => {
      const { meta } = this.props;
      return meta
          ? {
              hideOnSinglePage: true,
              showQuickJumper: true,
              onChange: this.selectPage,
              current: meta.current_page,
              defaultPageSize: 20,
              total: meta.total_count,
              showTotal: () => {
                  return t('showTotal', { total: meta.total_count });
              }
          }
          : {};
  };

  render() {
      const { downloads, meta, hasPro } = this.props;
      if (!hasPro) {
          return <BasicDeny />;
      }
      if (!meta) {
          return null;
      }

      const tableHeight = window.innerHeight - 240;

      const data = downloads.map((item, i) => {
          return Object.assign(item, { key: i });
      });

      return (
          <div className='download-content location-wrapper'>
              <div className='download-header-bar'>
                  <div className='download-header-left'>{t('Download.Center')}</div>
                  <div className='download-tip'>{t('Download.tip')}</div>
              </div>
              <div className='download-list-wrap'>
                  <Table
                      type='light'
                      scroll={{ x: 1210, y: tableHeight }}
                      pagination={this.pagination()}
                      centerAlign={true}
                      columns={this.geneTableColumns()}
                      dataSource={data}
                      size='default'
                  />
              </div>
          </div>
      );
  }
}

const mapStateToProps: MapState<StateProps> = ({
    V2: { userShip },
    V3: { downloads }
}) => {
    const hasPro = userShip.data.some(w => w.pro);

    return {
        downloads: downloads.data,
        meta: downloads.meta,
        query: downloads.query,
        hasPro
    };
};

export default withRouter(connect(mapStateToProps)(DownloadCenter));
