import { Col, Modal, Row } from 'antd';
import Button from 'components/Custom/Button';
import Select, { Option } from 'components/Custom/Select';
import { t } from 'i18next';
import * as React from 'react';
import overall from '../../images/screen1.png';
import historical from '../../images/screen2.png';
import overall_percent_reduction from '../../images/screen3.png';
import historical_percent_reduction from '../../images/screen4.png';
import comparsion from '../../images/screen5.png';

export const screen = {
    overall: overall,
    historical: historical,
    overall_percent_reduction: overall_percent_reduction,
    historical_percent_reduction: historical_percent_reduction,
    comparsion: comparsion
};

type State = Readonly<{
  mode?: 'indicator' | 'comparison';
}>;

type Props = {
  visible: boolean;
  onConfirm: (type: 'indicator' | 'comparison') => void;
  close: () => void;
};

class ReportModal extends React.PureComponent<Props, State> {
  state: State = {};
  indicator = [
      {
          title: t('Report.overall'),
          image: screen.overall
      },
      {
          title: t('Report.historical'),
          image: screen.historical
      },
      {
          title: `${t(
              'Report.overall_percent_reduction'
          )} <span class='grey_text' style='font-size:14px'>(${t(
              'Only for pm2p5'
          )})</span>`,
          image: screen.overall_percent_reduction
      },
      {
          title: `${t(
              'Report.historical_percent_reduction'
          )} <span class='grey_text' style='font-size:14px'>(${t(
              'Only for pm2p5'
          )})</span>`,
          image: screen.historical_percent_reduction
      }
  ];

  confirm = () => {
      const { close, onConfirm } = this.props;
      const { mode } = this.state;
      onConfirm(mode!);
      this.setState({ mode: undefined });
      close();
  }
  renderContent = () => {
      const { mode } = this.state;
      const startAtOne = 1
      return (
          <>
              <h3 className='register-title'>{t('Report.Add Template Card')}</h3>
              <div className='register-body'>
                  <div>
                      <Select
                          block={true}
                          dropdownMatchSelectWidth={true}
                          value={mode}
                          onSelect={v => this.setState({ mode: v as any })}
                      >
                          <Option value='indicator'>{t('Report.Indicator Module')}</Option>
                          <Option value='comparison'>
                              {t('Report.Comparison Module')}
                          </Option>
                      </Select>
                  </div>
                  {mode === 'indicator' && (
                      <div>
                          <p className='report-modal-tip grey_text'>
                              {t('Report.Below are those pattern example of Indicator')}
                          </p>
                          {this.indicator.map((item, index) => (
                              <div
                                  className='report-modal-preview'
                                  key={`indicator-${index}`}
                              >
                                  <p
                                      className='report-modal-preview_title'
                                      dangerouslySetInnerHTML={{
                                          __html: `${index + startAtOne}. ${item.title}`
                                      }}
                                  />
                                  <div className='report-modal-preview_image'>
                                      <img src={item.image} width='100%' alt='' />
                                  </div>
                              </div>
                          ))}
                      </div>
                  )}

                  {mode === 'comparison' && (
                      <div>
                          <p className='report-modal-tip grey_text'>
                              {t('Report.Below are those pattern example of Indicator')}
                          </p>

                          <div className='report-modal-preview'>
                              <p className='report-modal-preview_title'>
                                  {t('Report.Comparison Template')}
                              </p>
                              <div className='report-modal-preview_image'>
                                  <img src={screen.comparsion} width='100%' alt='' />
                              </div>
                          </div>
                      </div>
                  )}
              </div>

              <Row gutter={20}>
                  <Col span={12}>
                      <Button
                          size='large'
                          customType='light'
                          block={true}
                          onClick={() => this.props.close()}
                      >
                          {t('Cancel')}
                      </Button>
                  </Col>
                  <Col span={12}>
                      <Button
                          disabled={!mode}
                          size='large'
                          customType='dark'
                          block={true}
                          onClick={this.confirm}
                      >
                          {t('Save')}
                      </Button>
                  </Col>
              </Row>
          </>
      );
  }
  render() {
      const { visible } = this.props;
      return (
          <Modal
              visible={visible}
              closable={false}
              footer={null}
              width={540}
              centered={true}
          >
              {this.renderContent()}
          </Modal>
      );
  }
}

export default ReportModal;
