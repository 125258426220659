import { Action, ActionCreator } from 'redux'

export const REQUEST_COLLECTIONS = 'REQUEST_COLLECTIONS'
export const GET_COLLECTIONS = 'GET_COLLECTIONS'

export type CollectionObj = {
  id: number,
  name: string,
  type: 'station' | 'location',
  code: string,
  indicators: string,
  reading: {
    indicator: string,
    bto: string,
    level: string,
    name: string,
    unit: string
  }[],
  visibility: boolean,
  primary_indicator: string,
  status: string
}

export interface RequestCollectionsAction extends Action<typeof REQUEST_COLLECTIONS> {
  args: {
    location_id?: number
  };
}

interface GetCollectionAction extends Action<typeof GET_COLLECTIONS> {
  collections: CollectionObj[];
}

export type CollectionsAction = GetCollectionAction

export const requestCollections: ActionCreator<RequestCollectionsAction> = args => ({
  type: REQUEST_COLLECTIONS,
  args
})

export const getCollections: ActionCreator<GetCollectionAction> = (collections: CollectionObj[]) => ({
  type: GET_COLLECTIONS,
  collections
})
