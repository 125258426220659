import * as React from 'react'
import classnames from 'classnames'
import Graph from 'containers/Graph'
import { Icon } from 'antd'
import { CSSTransition } from 'react-transition-group'
import { KioskDetail } from 'actions/V3/kiosks'
import { LEVEL_DICT, shortName } from 'utils/constant'
import { t } from 'i18next'

type P = {
  overlay: 'Graph' | 'Collection' | '',
  isFull: boolean,
  current: any,
  kiosk: KioskDetail,
  close: () => void,
  changeCollection: (id: number) => void
}

const Modal: React.SFC<P> = React.memo(props => {
  const { overlay, isFull, close, kiosk, changeCollection, current } = props
  const DICT = LEVEL_DICT()
  const renderCollection = () => (
    <div className='kiosk-page--station'>
      <div className='kiosk-page--modal-header'>{t('Location Collections')}</div>
      <div className='kiosk-page--modal-body'>
        {kiosk.collections.map((collection, i) => (
          <div key={i} className='collection-item-card' onClick={() => changeCollection(collection.id)}>
            <div className='collection-item-card--name-box'>
              <span className='collection-item-card--name'>{collection.name}</span>
              {collection.stale && <span className='stale-label'>{t('STALE')}</span>}
            </div>
            <div className='collection-item-card--body'>
              <label>{t('Indicators')}</label>
              <div className='collection-item-card--indicators'>
                {collection.readings.slice(0, 3).map((indicator, i) => (
                  <div key={`collection-${i}`} className='indicator'>
                    <div className='indicator-label'>{shortName[indicator.name] || indicator.name}</div>
                    <div className='indicator-value'>{indicator.value}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`collection-item-card--footer ${collection.status || 'offline'}`}>
              {collection.status ? DICT[collection.status] : '--'}
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <CSSTransition classNames='appear' timeout={300} unmountOnExit={true} in={!!overlay}>
      <div
        className={classnames('kiosk-page--content-modal', {
          'is-full': isFull
        })}
      >
        <div className='kiosk-page--close-btn' onClick={close}>
          <Icon type='close' theme='outlined' />
        </div>
        {overlay === 'Collection' && renderCollection()}
        {overlay === 'Graph' && (
          <div className='kiosk-page--graph'>
            <div className='kiosk-page--modal-header'>Graph</div>
            <div className='kiosk-page--modal-body'>
              <Graph features={{ average: kiosk.pro, outdoor: kiosk.pro }} currentID={current.id} kiosk={kiosk} />
            </div>
          </div>
        )}
      </div>
    </CSSTransition>
  )
})

export default Modal
