import { Select } from 'antd'
import { t } from 'i18next'
import React from 'react'
import { KioskCollection, KioskDetail } from '../../actions/V3/kiosks'
import IndexDetails from './IndexDetails'
import ProgressBar from './ProgressBar'
import { Current } from './type'

const LOCATION_AVERAGE_ID = -1

interface CovidKioskLeftSideProps {
    kiosk: KioskDetail,
    collectionsList: KioskCollection[]
    covidIndicators: Current
    setCovidIndicators: (v: Current) => void
}

export default function CovidKioskLeftSide({ kiosk, collectionsList, covidIndicators, setCovidIndicators }: CovidKioskLeftSideProps) {
    const Option = Select.Option;
    const defaultCollection = collectionsList[0]

    const changeCollectionCovid = (collectionID: number) => {
        if (!kiosk) return;
        for (const index in kiosk.collections) {
            if (kiosk.collections[index].id === Number(collectionID)) {
                setCovidIndicators(kiosk.collections[index]);
            }
            if (collectionID === LOCATION_AVERAGE_ID) {
                setCovidIndicators(kiosk.average!);
            }
        }
    };
    let topListCollection:number = -1
    if (defaultCollection) {
        topListCollection = collectionsList.some(el => el.id === LOCATION_AVERAGE_ID) ? LOCATION_AVERAGE_ID : defaultCollection.id
}
    return (
        <div className='kiosk-page--group-panel-left'>
            <div className='kiosk-page--practice-index panel-shadow'>
                <Select
                    value={Number(covidIndicators && covidIndicators.id)  || topListCollection}
                    defaultActiveFirstOption={true}
                    dropdownClassName='collection-selector--dropdown'
                    dropdownMatchSelectWidth={true}
                    className='collection-selector'
                    onChange={changeCollectionCovid}
                    getPopupContainer={(trigger) => trigger!.parentElement!}
                >
                    {collectionsList.map((col, i:number) => (
                        <Option key={i} value={col.id}>
                            {col.stale && (
                                <span className='collection-selector--stale'>{t('STALE')}</span>
                            )}
                            <span className='collection-selector--name'>
                                {col.name || t('Location Average')}
                            </span>
                        </Option>
                    ))}
                </Select>
                <div className='kiosk-page--panel-title'>
                    {t('kioskV3.air_quality')}
                </div>
                {ProgressBar('pm2p5', covidIndicators, defaultCollection)}
                {ProgressBar('co2', covidIndicators, defaultCollection)}
                {ProgressBar('tvoc', covidIndicators, defaultCollection)}
                {ProgressBar('temperature', covidIndicators, defaultCollection)}
                {ProgressBar('humidity', covidIndicators, defaultCollection)}
            </div>
            <div
                style={{ marginTop: '2vh' }}
                className='kiosk-page--practice-index panel-shadow'
            >
                <div className='kiosk-page--panel-title'>
                    {t('kioskV3.index_detail')}
                </div>
                {IndexDetails({key: 'system health', kiosk, defaultCollection})}
                {IndexDetails({key: 'Air changes per hour', kiosk, defaultCollection})}
                {IndexDetails({key: 'filtration', kiosk, defaultCollection})}
            </div>
        </div>
    );
}
