import { Action, ActionCreator } from 'redux'
export const REQUEST_USER_SIGN_IN = 'REQUEST_USER_SIGN_IN'
export const REQUEST_USER_SIGN_UP = 'REQUEST_USER_SIGN_UP'
export const GET_USER_SIGN = 'GET_USER_SIGN'
export const REQUEST_RESET_USER_PWD = 'REQUEST_RESET_USER_PWD'
export type UserInfo = {
  auth_token: string,
  id: number,
  category: string,
  user_name: string,
  email: string,
  enable_notification_center: boolean,
  notification_channel: string,
  notification_channel_condition: string,
  notification_channel_data_source: string,
  notification_channel_status: string,
  notification_period: string,
  timezone: string
}
export type RequestUserSignAction = Action<typeof REQUEST_USER_SIGN_IN>
export type RequestUserSignUpAction = Action<typeof REQUEST_USER_SIGN_UP>
export type RequestResetUserPWDAction = Action<typeof REQUEST_RESET_USER_PWD>

interface GetUserSignAction extends Action<typeof GET_USER_SIGN> {
  userInfo: UserInfo;
}

export type UserSignAction = GetUserSignAction

export const requestUserSignIn: ActionCreator<RequestUserSignAction> = args => ({
  type: REQUEST_USER_SIGN_IN,
  args
})

export const requestUserSignUp: ActionCreator<RequestUserSignUpAction> = args => ({
  type: REQUEST_USER_SIGN_UP,
  args
})

export const getUserSign: ActionCreator<GetUserSignAction> = (userInfo: UserInfo) => ({
  type: GET_USER_SIGN,
  userInfo
})

export const requestResetUserPWD: ActionCreator<RequestResetUserPWDAction> = args => ({
  type: REQUEST_RESET_USER_PWD,
  args
})
