import * as React from 'react';
import { t } from 'i18next';
import CustomSelect, { Option } from 'components/Custom/Select';
import { connect, DispatchProp } from 'react-redux';
import cache from 'sagas/cache';
import Button from 'components/Custom/Button';
import { LocationBase, REQUEST_LOCATION_BASE_V3,
    requestV3LocationUpdate, RequestV3LocationUpdateAction } from 'actions/V3/location';
import * as dictionary from 'actions/V2/dictionary';
import {
    Outdoor,
    requestOutdoor,
    RequestOutdoorAction
} from 'actions/V2/location';

import { formateTimeZone } from 'utils/timeZone';
// import classNames from 'classnames';
import { Input } from 'antd';
import { entries, pick } from 'lodash';
// import { apiV3 } from 'utils/api';
// import { indicatorShortName } from 'utils/constant';
type Props = Readonly<{
  locationBase: LocationBase;
  showOutdoor?: boolean;
  close: () => void;
  editAble?: boolean;
}>;

type StateProps = {
  outdoor?: Outdoor;
  cities: dictionary.CityItem[];
  timeZone: dictionary.TimeZoneItem[];
};

type State = Readonly<{
  locationBase: LocationBase;
}>;

type P = Props & DispatchProp<RequestOutdoorAction | RequestV3LocationUpdateAction> & StateProps;

class EditInformation extends React.PureComponent<P, State> {

    constructor(props: P) {
        super(props);
        this.state = {
            locationBase: props.locationBase
        };
    }

  locationBaseUpdate = (field: keyof LocationBase, value) => {
      const { locationBase } = this.props;
      const cloned: LocationBase = { ...locationBase!, [field]: value };
      this.setState({ locationBase: cloned });
  };

  saveUpdate = async () => {
      const { locationBase } = this.state;
      cache.cleanCache(REQUEST_LOCATION_BASE_V3);

      const form = pick(locationBase, [
          'name',
          'address',
          'city_id',
          'time_zone',
          'category',
          'outdoor'
      ]);

      (form as any).outdoor_location_id = form.outdoor ? form.outdoor.id : null;

      this.props.dispatch(
          requestV3LocationUpdate({
              location_id: locationBase.id,
              form
          })
      )

      this.props.close();
  };

  selectCity = id => {
      const { cities, locationBase, dispatch } = this.props;
      const city = cities.find(v => v.id === id);

      const clone: LocationBase = {
          ...locationBase!,
          city_id: id,
          time_zone: city ? city.time_zone : locationBase!.time_zone,
          outdoor: undefined
      };

      dispatch(requestOutdoor({ city_id: id, show: false }));
      this.setState({ locationBase: clone });
  };

  render () {
      const {
          cities,
          showOutdoor,
          outdoor,
          editAble,
          timeZone,
          close
      } = this.props;
      const { locationBase } = this.state;
      const firstValue = 0
      const secondValue = 1
      return (
          <>
              <div className='edit-information-row'>
                  <div className='edit-information-label'>
                      <i className='material-icons'>bookmark</i>
                      <span className='grey_text small_text'>
                          {t('locationOverview.Location Name')}
                      </span>
                  </div>
                  <div className='edit-information-content'>
                      <Input
                          className='location_detail-left-text'
                          value={locationBase.name}
                          onChange={e => this.locationBaseUpdate('name', e.target.value)}
                          placeholder={t('locationOverview.Not Set')}
                      />
                  </div>
              </div>
              <div className='edit-information-row'>
                  <div className='edit-information-label'>
                      <i className='material-icons'>location_on</i>
                      <span className='grey_text small_text'>
                          {t('locationOverview.Location Address')}
                      </span>
                  </div>
                  <div className='edit-information-content'>
                      <Input.TextArea
                          className='location_detail-left-text'
                          placeholder={t('locationOverview.Not Set')}
                          autosize={false}
                          onChange={e =>
                              this.locationBaseUpdate('address', e.target.value)
                          }
                          value={locationBase.address}
                      />
                  </div>
              </div>
              <div className='edit-information-row'>
                  <div className='edit-information-label'>
                      <i className='material-icons'>business</i>
                      <span className='grey_text small_text'>{t('City')}</span>
                  </div>
                  <div className='edit-information-content'>
                      <CustomSelect
                          dropdownTextCenter={false}
                          size='small'
                          style={{ width: '100%' }}
                          placeholder={t('City')}
                          value={locationBase.city_id || ''}
                          showArrow={true}
                          showSearch={true}
                          onSelect={this.selectCity}
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                              (option.props.children as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                          }
                      >
                          <Option value=''>None</Option>
                          {cities.map((v, index) => (
                              <Option key={`city-${index}`} value={v.id}>
                                  {v.name}
                              </Option>
                          ))}
                      </CustomSelect>
                  </div>
              </div>
              <div className='edit-information-row'>
                  <div className='edit-information-label'>
                      <i className='material-icons'>language</i>
                      <span className='grey_text small_text'>
                          {t('locationOverview.Time Zone')}
                      </span>
                  </div>
                  <div className='edit-information-content'>
                      <CustomSelect
                          dropdownTextCenter={false}
                          size='small'
                          style={{ width: '100%' }}
                          showSearch={true}
                          placeholder={t('locationOverview.Time Zone')}
                          value={locationBase.time_zone}
                          showArrow={true}
                          onSelect={v => this.locationBaseUpdate('time_zone', v)}
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                              (option.props.children as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                          }
                      >
                          {formateTimeZone(timeZone).map((value, index) => (
                              <Option
                                  key={`time-zone-${index}`}
                                  disabled={value.disabled}
                                  value={value.value}
                              >
                                  {value.label}
                              </Option>
                          ))}
                      </CustomSelect>
                  </div>
              </div>
              <div className='edit-information-row'>
                  <div className='edit-information-label'>
                      <i className='material-icons'>location_city</i>
                      <span className='grey_text small_text'>
                          {t('locationOverview.Location Type')}
                      </span>
                  </div>
                  <div className='edit-information-content'>
                      <CustomSelect
                          dropdownTextCenter={true}
                          size='small'
                          style={{ width: '100%' }}
                          placeholder={t('locationOverview.Location Type')}
                          value={locationBase.category}
                          onSelect={v => this.locationBaseUpdate('category', v)}
                          showArrow={true}
                      >
                          <Option value='indoor'>{t('filter.indoor')}</Option>
                          <Option value='outdoor'>{t('filter.outdoor')}</Option>
                      </CustomSelect>
                  </div>
              </div>
              {showOutdoor && locationBase.category === 'indoor' && (
                  <div className='edit-information-row'>
                      <div className='edit-information-label'>
                          <i className='material-icons'>cloud</i>
                          <span className='grey_text small_text'>
                              {t('locationOverview.Outdoor Counterpart')}
                          </span>
                      </div>
                      <div className='edit-information-content'>

                          <CustomSelect
                              style={{ width: '100%' }}
                              size='small'
                              onSelect={v =>
                                  this.locationBaseUpdate('outdoor', {
                                      id: v,
                                      name: outdoor![v as number]
                                  })
                              }
                              value={(locationBase.outdoor && locationBase.outdoor.id) || ''}
                              showArrow={true}
                          >
                              <Option value='' key='outdoor-none'>
                  None
                              </Option>
                              {outdoor &&
                  entries(outdoor).map((v, index) => (
                      <Option key={`outdoor-${index}`} value={+v[firstValue]}>
                          {v[secondValue]}
                      </Option>
                  ))}
                          </CustomSelect>
                      </div>
                  </div>
              )}
              <div className='location_detail-setting-button'>
                  <Button size='large' customType='light' onClick={close}>
                      {t('Cancel')}
                  </Button>
          &nbsp; &nbsp; &nbsp;
                  <Button
                      disabled={!editAble}
                      size='large'
                      customType='dark'
                      onClick={this.saveUpdate}
                  >
                      {t('Save')}
                  </Button>
              </div>
          </>
      );
  }
}

const mapStateToProps: MapState<StateProps> = ({
    V2: {
        locationInfo,
        dictionary: { time_zones, cities }
    }
}) => ({
    outdoor: locationInfo.outdoor,
    timeZone: time_zones,
    cities
});

export default connect(mapStateToProps)(EditInformation);
