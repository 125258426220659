import { NotificationAction, GET_NOTIFICATION, NotificationObj } from 'actions/V2/notification'

export type NotificationsState = {
  data: NotificationObj[],
  meta?: Meta,
  query?: {}
}

export default function Notification(
  state: NotificationsState = {
    data: []
  },
  action: NotificationAction
) {
  switch (action.type) {
    case GET_NOTIFICATION:
      // if (state.query) {
      //   if (isEqual(state.query, action.query)) {
      //     list = state.data.concat(action.notifications);
      //   }
      // }
      return {
        data: action.notifications,
        meta: action.meta,
        query: action.query
      }
    default:
      return state
  }
}
