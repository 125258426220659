import * as React from 'react'
import Invoice from 'pages/Invoice'
import WorkspaceInvoice from 'pages/Invoice/workspace'
import { Switch, Route } from 'react-router-dom'
import { BasicDeny } from 'components/Permission'
import { connect } from 'react-redux'

type StateProps = {
  hasPro: boolean
}

class InvoiceRoute extends React.PureComponent<StateProps, {}> {
  invoiceRoute = () => (
    <Switch>
      <Route exact={true} path='/invoice/workspace/:id/:billing_id/:workspace_id' component={WorkspaceInvoice} />
      <Route exact={true} path='/invoice/:id/:year/:month' component={Invoice} />
      <Route exact={true} path='/invoice/:id' component={Invoice} />
    </Switch>
  )

  render() {
    const { hasPro } = this.props
    return hasPro ? this.invoiceRoute() : <BasicDeny />
  }
}

const mapStateToProps: MapState<StateProps> = ({ V2: { userShip } }) => {
  const hasPro = userShip.data.some(w => w.pro)
  return {
    hasPro
  }
}

export default connect(mapStateToProps)(InvoiceRoute)
