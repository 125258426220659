import { put, call } from 'redux-saga/effects'
import { REQUEST_COLLECTIONS, getCollections } from 'actions/V2/collections'
import { watchRequestNoCancel } from 'sagas/helper'
import api from 'utils/api'

function* request(args: { location_id: number }) {
  const { data } = yield call(api, `/locations/${args.location_id}/collections`, 'get')

  const dataSorted = data.sort((a, b) => a.id > b.id)

  yield put(getCollections(dataSorted))
  return data
}

export default watchRequestNoCancel(REQUEST_COLLECTIONS, request)
