import * as React from 'react'
import { connect } from 'react-redux'
import { Spin } from 'antd'
import './index.css'

interface StateProps {
  status: {
    loading: boolean,
    error: boolean,
    message: string
  };
}
let timer: number
const useDelay = (loading, timeout: number = 0) => {
  const [isLoading, setLoading] = React.useState(false)

  React.useEffect(() => {
    const status = loading
    if (timer) {
      window.clearTimeout(timer)
    }
    if (status) {
      setLoading(true)
    } else {
      timer = window.setTimeout(() => {
        setLoading(false)
      }, timeout)
    }
  }, [loading, timeout])
  return isLoading
}

const Loading: React.SFC<StateProps> = React.memo(props => {
  const { status } = props
  const isLoading = useDelay(status.loading, 500)
  return (
    <Spin spinning={isLoading} wrapperClassName='Q-loading' size='large'>
      {props.children}
    </Spin>
  )
})

const mapStateToProps: MapState<StateProps> = ({ global: { loading, message, error } }) => ({
  status: {
    loading,
    message,
    error
  }
})

export default connect(mapStateToProps)(Loading)
