import * as React from 'react'
import BillingByMonth from './byMonth'
import BillingWorkspaces from './BillingWorkspaces'
import Button from 'components/Custom/Button'
import History from 'pages/History'
import moment from 'moment'
import Org from 'pages/Org'
import Workspaces from 'pages/Workspaces'
import { BillingOV, requestOrgBillingOV, RequestOrgBillingOVAction } from 'actions/V3/billing'
import { connect } from 'react-redux'
import { DispatchProp } from 'react-redux'
import { Icon, Modal, Tabs } from 'antd'
import { OrgObj } from 'actions/V3/org'
import { Route, withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { t } from 'i18next'
import { UserConfigObj } from 'actions/V3/userConfig'
import './index.css'

interface StateProps {
  org?: OrgObj;
  userConfig?: UserConfigObj;
  billingOV?: BillingOV;
}

type State = Readonly<{
  showPayTip: boolean
}>

type DispatchAction = RequestOrgBillingOVAction

type P = StateProps & DispatchProp<DispatchAction> & RouteComponentProps<{ id: string }>

class Billing extends React.PureComponent<P, State> {
  state: State = {
    showPayTip: false
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    dispatch(
      requestOrgBillingOV({
        id
      })
    )
  }

  tabClick = (key: string): void => {
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props
    history.push(`/billing/${id}/${key}`)
  }

  renderSummary = billingOV => {
    let showNoteBar = false
    // 当前是否临近缴费日期两周内
    const twoWeeks = 14
    const twoWeekDue = moment() > moment(billingOV.due_date).subtract(twoWeeks, 'days')
    if (billingOV.balance < billingOV.payment && twoWeekDue) {
      showNoteBar = true
    }
    const dueDate = moment(billingOV.due_date).format('YYYY-MM-DD')
    return (
      <>
        {showNoteBar && (
          <div
            className='alert alert-danger'
            onClick={() => {
              this.setState({
                showPayTip: true
              })
            }}
          >
            {t('billing.due tip', { date: dueDate })}
          </div>
        )}
        <div className='billing-tab-summary'>
          <div className='panel'>
            <div className='label'>{t('billing.summary payment')}</div>
            <div className='value'>￥{billingOV.payment}</div>
          </div>
          <div className='panel'>
            <div className='label'>{t('billing.pay date')}</div>
            <div className='value'>{dueDate}</div>
          </div>
          <div className='panel'>
            <div className='label'>{t('billing.balance')}</div>
            <div className='value'>￥{billingOV.balance}</div>
          </div>
        </div>
      </>
    )
  }

  pathToKey = (): string => {
    const {
      location: { pathname }
    } = this.props
    const keyArray = [
      {
        key: 'users',
        value: 'users'
      },
      {
        key: 'history',
        value: 'history'
      },
      {
        key: 'month',
        value: 'month'
      },
      {
        key: 'byWorkspaces',
        value: 'byWorkspaces'
      },
      {
        key: 'workspaces',
        value: 'workspaces'
      }
    ]

    const pathObj = keyArray.find(v => pathname.includes(v.key)) || {
      value: 'month'
    }
    return pathObj.value
  }

  handleClosePayTip = () => {
    this.setState({
      showPayTip: false
    })
  }

  render() {
    const { showPayTip } = this.state
    const { org, userConfig, billingOV } = this.props
    if (!billingOV || !org) {
      return null
    }

    const expiry = moment() > moment(billingOV.due_date)
    const firstElement = 0
    let isAdmin = false
    if (userConfig && userConfig.orgs.length) {
      isAdmin = userConfig.orgs[firstElement].permission === 'edit'
    }

    return (
      <div className='location-wrapper'>
        <div className='billing-container'>
          <div className='org-name'>
            <div className='org-name-label'>{t('billing.orz')}:</div>
            <div className='org-name-value'>{org.name}</div>
          </div>
          <Tabs activeKey={this.pathToKey()} onTabClick={this.tabClick}>
            <Tabs.TabPane tab={t('billing.byMonth')} key='month'>
              {/* this.renderSummary(billingOV) */}
              <Route path='/billing/:id/month' component={BillingByMonth} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('billing.byWorkspace')} key='byWorkspaces'>
              {/* this.renderSummary(billingOV) */}
              <Route path='/billing/:id/byWorkspaces' component={BillingWorkspaces} />
            </Tabs.TabPane>
            {isAdmin && (
              <Tabs.TabPane tab={t('billing.userManage')} key='users'>
                <Route path='/billing/:id/users' component={Org} />
              </Tabs.TabPane>
            )}
            {isAdmin && (
              <Tabs.TabPane tab={t('billing.history')} key='history'>
                <Route path='/billing/:id/history' component={History} />
              </Tabs.TabPane>
            )}
            {isAdmin && (
              <Tabs.TabPane tab={t('workspace.Workspace List')} key='workspaces'>
                <Route path='/billing/:id/workspaces' render={() => <Workspaces editPro={true} />} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
        <Modal
          visible={showPayTip}
          wrapClassName='payment-tip-modal'
          centered={true}
          closable={false}
          width={750}
          maskClosable={false}
          footer={null}
        >
          <div className='title'>
            <Icon type='exclamation-circle' />
            <h1>{expiry ? t('org.expiry title') : t('org.expiry title soon')}</h1>
          </div>
          <div className='content'>
            <div
              dangerouslySetInnerHTML={{
                __html: expiry
                  ? t('org.expiry desc', {
                      org: org.name
                    })
                  : t('org.expiry desc soon', {
                      date: moment(billingOV.due_date).format('LL'),
                      org: org.name
                    })
              }}
            />
            <div dangerouslySetInnerHTML={{ __html: t('org.contact us') }} />
          </div>
          <Button customType='danger' block={true} size='large' onClick={this.handleClosePayTip}>
            {t('org.i know')}
          </Button>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { org, userConfig, billing } }) => ({
  org: org.detail,
  userConfig: userConfig.data,
  billingOV: billing.ov
})

export default withRouter(connect(mapStateToProps)(Billing))
