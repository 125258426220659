import { Action, ActionCreator } from 'redux'

export const REQUEST_LOCATION_BASE_V3 = 'REQUEST_LOCATION_BASE_V3'
export const REQUEST_LOCATION_UPDATE_V3 = 'REQUEST_LOCATION_UPDATE_V3'
export const GET_LOCATION_BASE_V3 = 'GET_LOCATION_BASE_V3'
export const GET_LOCATION_UPDATE_V3 = 'GET_LOCATION_UPDATE_V3'

export const REQUEST_LOCATION_EDIT_V3 = 'REQUEST_LOCATION_EDIT_V3'

export const GET_LOCATION_EDIT_V3 = 'GET_LOCATION_EDIT_V3'

export type Collection = {
  id: number,
  name: string,
  code: string,
  bto: string,
  status: string,
  performance: string,
  stale: boolean,
  last_reading_time?: string,
  primary_data_channel: string,
  category_name: 'location' | 'station',
  readings: {
    indicator_id?: number,
    data_channel: string,
    name: string,
    type: DataChannelType,
    unit: string,
    value?: number,
    level?: string
  }[]
}

export type LocationBase = {
  abbr: string,
  address: string,
  average?: {
    status: string,
    stale: boolean,
    performance: string,
    last_reading_time: string,
    readings: {
      data_channel: string,
      name: string,
      type: DataChannelType,
      unit: string,
      value?: number,
      level?: string
    }[]
  },
  background_image?: string,
  category: LocationCategory,
  city_id: number,
  city_name: string,
  collections: Collection[],
  created_at: string,
  data_channels?: {
    channel: string,
    name: string
  }[],
  followed: boolean,
  following_id?: number,
  id: number,
  locked: boolean,
  logo_image?: string,
  name: string,
  outdoor?: {
    id: number,
    name: string
  },
  pro: boolean,
  qr_code: string,
  search_time_enabled_at: string,
  time_zone?: string,
  work_hours?: {
    active: boolean,
    data: {
      day_in_week: number,
      begin_time: string,
      end_time: string,
      active: boolean
    }[]
  },
  workspace_id: number,
  workspace_name: string
}

export type LocationEdit = {
  id: number,
  tvoc_unit: string,
  locked: boolean,
  password?: string,
  average_cycle: boolean,
  push_channel?: string,
  work_hours: {
    active: boolean,
    data: {
      day_in_week: number,
      begin_time: string,
      end_time: string,
      active: boolean
    }[]
  }
}

export type LocationBaseUpdate = {
  name?: string,
  address?: string,
  city_id?: number,
  time_zone?: string,
  category?: LocationCategory,
  background_image?: string,
  outdoor?: {
    id: number,
    name: string
  },
  display_config?: {
    positions?: string[],
    collection_positions?: string[]
  }
}

export interface RequestV3LocationAction extends Action<typeof REQUEST_LOCATION_BASE_V3> {
  args: {
    location_id: number
  };
}

export interface RequestV3LocationEditAction extends Action<typeof REQUEST_LOCATION_EDIT_V3> {
  args: {
    location_id: number
  };
}

export interface RequestV3LocationUpdateAction extends Action<typeof REQUEST_LOCATION_UPDATE_V3> {
  args: {
    location_id: number,
    form: LocationBaseUpdate
  };
}

interface GetV3Location extends Action<typeof GET_LOCATION_BASE_V3> {
  locationBase: LocationBase;
}

interface GetV3LocationUpdate extends Action<typeof GET_LOCATION_UPDATE_V3> {
  locationBase: LocationBase;
}

interface GetV3LocationEdit extends Action<typeof GET_LOCATION_EDIT_V3> {
  locationEdit: LocationEdit;
}

export type LocationV3Action = GetV3Location | GetV3LocationEdit | GetV3LocationUpdate

export const requestV3Location = (args): RequestV3LocationAction => ({
  args,
  type: REQUEST_LOCATION_BASE_V3
})

export const requestV3LocationEdit = (args: { location_id: number }): RequestV3LocationEditAction => ({
  args,
  type: REQUEST_LOCATION_EDIT_V3
})

export const requestV3LocationUpdate = (args: {
  location_id: number,
  form: LocationBaseUpdate,
  show?: boolean
}): RequestV3LocationUpdateAction => ({
  args,
  type: REQUEST_LOCATION_UPDATE_V3
})

export const getV3LocationBase: ActionCreator<GetV3Location> = locationBase => ({
  locationBase,
  type: GET_LOCATION_BASE_V3
})

export const getV3LocationEdit: ActionCreator<GetV3LocationEdit> = locationEdit => ({
  locationEdit,
  type: GET_LOCATION_EDIT_V3
})

export const getV3LocationUpdate: ActionCreator<GetV3LocationUpdate> = locationBase => ({
  locationBase,
  type: GET_LOCATION_UPDATE_V3
})
