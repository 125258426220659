import { Action, ActionCreator } from 'redux'
import { cleanCookie } from 'utils/auth'
import cache from 'sagas/cache'
export const REQUEST_START = 'REQUEST_START'
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS'
export const REQUEST_FAILED = 'REQUEST_FAILED'

export const CLEAN_STORE_STATE = 'CLEAN_STORE_STATE'

interface RequestStartAction extends Action<typeof REQUEST_START> {
  show: boolean;
}

type RequestSuccessAction = Action<typeof REQUEST_SUCCESS>
export type CleanStoreAction = Action<typeof CLEAN_STORE_STATE>

interface RequestFailedAction extends Action<typeof REQUEST_FAILED> {
  message: string;
}

export type GloblalAction = RequestStartAction | RequestSuccessAction | RequestFailedAction

export const requestStart: ActionCreator<RequestStartAction> = (show: boolean = true) => ({
  type: REQUEST_START,
  show
})

export const requestSuccess: ActionCreator<RequestSuccessAction> = () => ({
  type: REQUEST_SUCCESS
})

export const requestFailed: ActionCreator<RequestFailedAction> = (message: string = '') => ({
  type: REQUEST_FAILED,
  message
})

export const cleanStore: ActionCreator<CleanStoreAction> = () => {
  cleanCookie()
  const localKeys = ['i18nextLng', 'emailSource', 'V3_RELEASE_NOTE_SHOWN']
  const localVal = localKeys.map(key => localStorage.getItem(key))
  localStorage.clear()
  localVal.forEach((val, index) => {
    if (val) {
      localStorage.setItem(localKeys[index], val)
    }
  })

  cache.resetCache()
  return {
    type: CLEAN_STORE_STATE
  }
}
