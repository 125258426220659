import * as React from 'react'
import basicImg from 'images/basic.png'
import regularImg from 'images/regular.png'
import { t } from 'i18next'
import Button from 'components/Custom/Button'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { isNaN } from 'lodash'

const Basic: React.SFC<{ message?: string } & RouteComponentProps<{ id: string }>> = React.memo(props => {
  const {
    message,
    match: {
      params: { id }
    },
    history
  } = props

  return (
    <div className='nodata-container'>
      <img src={basicImg} alt='permission deny' />
      <div className='nodata-tip'>{message || t('cannot use this feature')}</div>
      <div className='nodata-tip'>{t('if you want use this feature')}</div>
      <br />
      {!!id && !isNaN(+id) && (
        <div>
          <Button onClick={() => history.push(`/upgrade/${id}/intro`)} customType='dark'>
            {t('upgrade.toPro')}
          </Button>
        </div>
      )}
    </div>
  )
})

export const BasicDeny = withRouter(Basic)

export const Deny: React.SFC<{ message?: string }> = React.memo(({ message }) => (
  <div className='nodata-container'>
    <img src={regularImg} alt='permission deny' />
    <div className='nodata-tip'>{message || t('cannot use this feature')}</div>
  </div>
))
