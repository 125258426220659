import moment from 'moment-timezone';
import { sortBy } from 'lodash';
import { TimeZoneItem } from 'actions/V2/dictionary';
export const formatZoneToOffset = (zone?: string) => {
    if (!zone) {
        return '';
    }
    const offset = String(
        moment()
            .tz(zone)
            .format('ZZ')
    );
    return `${offset.substring(0, 3)}:${offset.substring(3)}`;
};

export const formateTimeZone = (
    timeZone: TimeZoneItem[]
): { value: string; label: string; disabled?: boolean }[] => {
    if (!timeZone) {
        return [];
    }
    const arr: { value: string; label: string; disabled?: boolean }[] = [];
    const keys = Object.keys(timeZone).sort((a, b) => {
        const [hourA, minA] = a.split(':');
        const [hourB, minB] = b.split(':');
        const valueA = +hourA * 60 - +minA;
        const valueB = +hourB * 60 - +minB;
        return valueA - valueB;
    });

    keys.forEach(offset => {
        arr.push({
            value: offset,
            label: `GMT ${offset}`,
            disabled: true
        });
        const timezones = sortBy(Object.keys(timeZone[offset]));
        timezones.forEach(zone => {
            arr.push({
                value: zone,
                label: `[${offset}] ${zone} (${timeZone[offset][zone].toString()})`
            });
        });
    });

    return arr;
};
