import { Action, ActionCreator } from 'redux'
export const REQUEST_ACTIVITY_CITY = 'REQUEST_ACTIVITY_CITY'
export const GET_ACTIVITY_CITY = 'GET_ACTIVITY_CITY'

type City = {
  id: number,
  image: string,
  name: string
}
export interface ActivityCity {
  china: City[];
  global: City[];
}

export type RequestActivityAction = Action<typeof REQUEST_ACTIVITY_CITY>

export interface GetActivitynAction extends Action<typeof GET_ACTIVITY_CITY> {
  activities: ActivityCity;
}

export const requestActivity: ActionCreator<RequestActivityAction> = args => ({
  type: REQUEST_ACTIVITY_CITY,
  args
})

export const getActivities: ActionCreator<GetActivitynAction> = (activities: ActivityCity) => ({
  type: GET_ACTIVITY_CITY,
  activities
})
