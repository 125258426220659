import React from 'react'
import { Modal } from 'antd'
import { t } from 'i18next'
import './ClickableCanvas.css'
import { Button } from 'antd'
import i18n from 'configs/i18n'
import WhitePaper from './indexKioskSettings'

export default function ClickableCanvas() {
  const [isModal, setIsModal] = React.useState(false)
  const [downloadBtn, setDownloadBtn] = React.useState<boolean>(false)
  const getInfoText = (textContent: string) => {
    return t(`clickableCanvas.${textContent}`)
  }

  const { language } = i18n
  const whitePaperPDFLink = language === 'en' ? WhitePaper.english : WhitePaper.chinese

  return (
    <div onMouseLeave={() => setDownloadBtn(false)}>
      <Modal
        width={900}
        style={{ top: 20 }}
        bodyStyle={{maxHeight: 'calc(100vh - 100px)', overflow:'scroll'}}
        visible={isModal}
        onCancel={() => setIsModal(false)}
        footer={[
          <Button key='close' onClick={() => setIsModal(false)} type='primary'>
            {t('Close')}
          </Button>
        ]}
      >
        <div className='covidScoreExplanation'>
          <h2>{getInfoText('title')}</h2>
          <p>{getInfoText('firstExplanation')}</p>
          <p>{getInfoText('secondExplanation')}</p>
          <section className='covidOptimisationLevel'>
            <p>{getInfoText('sixLevelsTitle')}</p>
            <li>{getInfoText('levelOne')}</li>
            <li>{getInfoText('levelTwo')}</li>
            <li>{getInfoText('levelThree')}</li>
            <li>{getInfoText('levelFour')}</li>
            <li>{getInfoText('levelFive')}</li>
            <li>{getInfoText('levelSix')}</li>
          </section>
          <p>
            <span className='covidBoldIndicator'>{getInfoText('excellent')}</span>
            {getInfoText('descriptionExcellent')}
          </p>
          <p>
            <span className='covidBoldIndicator'>{getInfoText('good')}</span>
            {getInfoText('descriptionGood')}
          </p>
          <p>
            <span className='covidBoldIndicator'>{getInfoText('fair')}</span>
            {getInfoText('descriptionFair')}
          </p>
          <p>
            <span className='covidBoldIndicator'>{getInfoText('satisfactory')}</span>
            {getInfoText('descriptionSatisfactory')}
          </p>
          <p>
            <span className='covidBoldIndicator'>{getInfoText('unsatisfactory')}</span>
            {getInfoText('descriptionUnsatisfactory')}
          </p>
          <p>
            <span className='covidBoldIndicator'>{getInfoText('poor')}</span>
            {getInfoText('descriptionPoor')}
          </p>
        </div>
        <h2>{getInfoText('indicatorRangesTitle')}</h2>
        <section className='covidColumns'>
          <div>
            <h3>{getInfoText('temperatureTitle')}</h3>
            <li>{getInfoText('temperatureDescription')}</li>
          </div>
          <div>
            <h3>{getInfoText('humidityTitle')}</h3>
            <li>{getInfoText('humidityDescription')}</li>
          </div>
          <div>
            <h3>{getInfoText('co2Title')}</h3>
            <li>{getInfoText('co2Description')}</li>
          </div>
          <div>
            <h3>{getInfoText('pm25Title')}</h3>
            <li>{getInfoText('pm25Description')}</li>
          </div>
          <div>
            <h3>{getInfoText('tvocTitle')}</h3>
            <li>{getInfoText('tvocDescription')}</li>
          </div>
          <div>
            <h3>{getInfoText('achTitle')}</h3>
            <li>{getInfoText('achDescription')}</li>
          </div>
        </section>
      </Modal>
      <div className='clickableCanvas'>
        {downloadBtn ? (
          <div className='downloadBtnContainer'>
            <a href={whitePaperPDFLink} download>
              <Button type='primary' icon='download' size='small'>
                {getInfoText('whitePaper')}
              </Button>
            </a>
          </div>
        ) : (
          <div className='downloadBtnContainer' />
        )}
        <div onMouseEnter={() => setDownloadBtn(true)} className='clickableElement' onClick={() => setIsModal(true)} />
        <div className='clickableElement' />
      </div>
    </div>
  )
}
