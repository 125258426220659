import { Action } from 'redux'
import { entries, isEqual } from 'lodash'
import { REQUEST_ACCOUNTS } from 'actions/V2/accounts'
import { REQUEST_ACTIVITY_CITY } from 'actions/V2/activities'
import { REQUEST_ANNOUNCEMENT, REQUEST_ANNOUNCEMENT_LATEST } from 'actions/V2/announcement'
import { REQUEST_CERTIFICATIONS } from 'actions/V2/certifications'
import { REQUEST_COLLECTIONS } from 'actions/V2/collections'
import { REQUEST_COLLECTIONS_V3 } from 'actions/V3/collections'
import { REQUEST_CONFIG } from 'actions/V3/config'
import { REQUEST_DATA_SOURCES_V3, REQUEST_DATA_SOURCE_BRAND_V3 } from 'actions/V3/datasources'
import { REQUEST_DOWNLOADS } from 'actions/V2/downloads'
import { REQUEST_GRAPH } from 'actions/V2/graph'
import { REQUEST_INDICATOR_V3 } from 'actions/V3/indicators'
import { REQUEST_KIOSK_V3 } from 'actions/V3/kiosks'
import { REQUEST_LOCATION, REQUEST_OUTDOOR } from 'actions/V2/location'
import { REQUEST_LOCATION_BASE_V3 } from 'actions/V3/location'
// import { REQUEST_LOCATION_LIST } from "actions/V2/[deprecated]locationList";
import { REQUEST_LOCATION_LIST_V3 } from 'actions/V3/locationList'
import { REQUEST_LOCATION_SELECT_V3 } from 'actions/V3/locationSelect'
import { REQUEST_ORG_DETAIL_V3 } from 'actions/V3/org'
// import { REQUEST_REPORT } from "actions/V2/[Deprecated]report";
// import { REQUEST_REPORT_PERIODS } from "actions/V2/[Deprecated]reportPeriods";
import { REQUEST_SEARCH_DETAIL_V3, REQUEST_SEARCH_V3 } from 'actions/V3/search'
import { REQUEST_TABLEDATA } from 'actions/V3/tabledata'
import { REQUEST_USER_CONFIG_V3 } from 'actions/V3/userConfig'
import {
  REQUEST_WORKSPACE_LIST
  // REQUEST_WORKSPACE_DETAIL
} from 'actions/V3/workspace'

// 时间常量 重复使用
const min = 1000 * 60
const fiveMin = min * 5
const halfHour = min * 30
const oneHour = min * 60
const oneDay = oneHour * 24

// action type 时长的映射表， 添加缓存只需要往中间加入新值
const table: {
  [P: string]: number
} = {
  [REQUEST_DATA_SOURCES_V3]: oneHour,
  [REQUEST_ACCOUNTS]: oneHour,
  // [REQUEST_LOCATION_LIST]: halfHour,
  [REQUEST_ACTIVITY_CITY]: oneDay,
  [REQUEST_CERTIFICATIONS]: oneDay,
  [REQUEST_COLLECTIONS]: oneHour,
  [REQUEST_DOWNLOADS]: min,
  [REQUEST_GRAPH]: fiveMin,
  [REQUEST_LOCATION]: halfHour,
  [REQUEST_OUTDOOR]: oneHour,
  // [REQUEST_REPORT]: halfHour,
  // [REQUEST_REPORT_PERIODS]: oneDay,
  [REQUEST_TABLEDATA]: fiveMin,
  [REQUEST_COLLECTIONS_V3]: fiveMin,
  [REQUEST_INDICATOR_V3]: fiveMin,
  [REQUEST_KIOSK_V3]: halfHour,
  // [REQUEST_KIOSK_DETAIL_V3]: fiveMin,
  [REQUEST_LOCATION_BASE_V3]: halfHour,
  [REQUEST_LOCATION_LIST_V3]: fiveMin,
  [REQUEST_LOCATION_SELECT_V3]: fiveMin,
  [REQUEST_SEARCH_DETAIL_V3]: oneHour,
  [REQUEST_SEARCH_V3]: oneHour,
  [REQUEST_WORKSPACE_LIST]: min,
  [REQUEST_DATA_SOURCE_BRAND_V3]: oneHour,
  [REQUEST_USER_CONFIG_V3]: oneDay,
  [REQUEST_ANNOUNCEMENT]: oneDay,
  [REQUEST_ANNOUNCEMENT_LATEST]: oneDay,
  [REQUEST_CONFIG]: oneDay,
  [REQUEST_ORG_DETAIL_V3]: oneHour
}

type Table = {
  [P: string]: {
    lastTime?: number,
    lastAction?: Action,
    limit: number,
    data?: any
  }
}

// 单例
let instance: Table

const create = () => {
  const _table: Table = {}
  entries(table).forEach(([type, limit]) => {
    _table[type] = { limit }
  })
  instance = _table
  return instance
}
// 返回缓存
const getCache = () => {
  if (instance) {
    return instance
  }

  return create()
}
// 重置缓存
const resetCache = () => {
  return create()
}
// 判断是否过期函数
const shouldUseCache = (action: Action): boolean => {
  const { type } = action
  const state = getCache()

  const cache = state[type]

  if (!cache) {
    return false
  }

  if (!cache.lastTime) {
    cache.lastTime = Date.now()
    cache.lastAction = action
    return false
  }

  const sameRequest = isEqual(cache.lastAction, action)
  const noExpired = Date.now() - cache.lastTime < cache.limit

  if (sameRequest && noExpired) {
    return true
  }

  cache.lastTime = Date.now()
  cache.lastAction = action
  return false
}
// 清除 某类型的缓存控制
const cleanCache = (type: string) => {
  const state = getCache()
  state[type] = {
    limit: table[type]
  }
}
// 初始化调用
create()

const Cache = {
  getCache,
  cleanCache,
  resetCache,
  shouldUseCache
}

export default Cache
