import { put, call } from 'redux-saga/effects'

import {
  REQUEST_ORG_BILLINGS_V3,
  getOrgBillings,
  REQUEST_ORG_BILLING_OV_V3,
  getOrgBillingOV,
  REQUEST_BILLING_DETAIL_V3,
  getBillingDetail,
  REQUEST_WORKSPACE_BILLING_V3,
  getWorkspaceBillings,
  REQUEST_WORKSPACE_BILLING_DETAIL_V3,
  getWorkspaceBillingDetail,
  REQUEST_MONTH_BILLING_V3,
  getMonthBilling
} from 'actions/V3/billing'
import { watchRequest } from 'sagas/helper'

import { apiV3 } from 'utils/api'

type Args = {
  id?: number,
  page?: number,
  year?: number,
  month?: number,
  workspace_id?: number
}

function* request(args: Args) {
  const { id, ...other } = args
  const { data, meta } = yield call(apiV3, `/orgs/${id}/billings`, 'get', other)
  yield put(getOrgBillings(data, meta, args))
  return data
}

export default watchRequest(REQUEST_ORG_BILLINGS_V3, request)

function* requestOrgBillingOv(args: any) {
  const { data } = yield call(apiV3, `/orgs/${args.id}/billings/overview`, 'get')
  yield put(getOrgBillingOV(data))
  return data
}

export const watchOrgBillingOV = watchRequest(REQUEST_ORG_BILLING_OV_V3, requestOrgBillingOv)

function* requestBillingDetail(args: any) {
  const { id, billing_id, ...other } = args

  const { data, meta } = yield call(apiV3, `/orgs/${id}/billings/${billing_id}`, 'get', other)

  yield put(getBillingDetail(data, meta, args))
  return data
}

export const watchBillingDetail = watchRequest(REQUEST_BILLING_DETAIL_V3, requestBillingDetail)

function* requestBillingWorkspaces(args: any) {
  const { id, ...other } = args

  const { data, meta } = yield call(apiV3, `/orgs/${id}/billings/workspaces`, 'get', other)
  yield put(getWorkspaceBillings(data, meta, args))
  return data
}

export const watchWorkspacesBilling = watchRequest(REQUEST_WORKSPACE_BILLING_V3, requestBillingWorkspaces)

function* requestBillingWorkspacDetail(args: any) {
  const { id, ...other } = args

  const { data, meta } = yield call(apiV3, `/orgs/${id}/billings/workspace`, 'get', other)
  yield put(getWorkspaceBillingDetail(data, meta, args))
  return data
}

export const watchWorkspacesBillingDetail = watchRequest(
  REQUEST_WORKSPACE_BILLING_DETAIL_V3,
  requestBillingWorkspacDetail
)

function* requestMonthBilling(args: any) {
  const { id, billing_id, ...other } = args

  const { data, meta } = yield call(apiV3, `/orgs/${id}/billings/${billing_id}/monthly`, 'get', other)
  yield put(getMonthBilling(data, meta, args))
  return data
}

export const watchMonthBilling = watchRequest(REQUEST_MONTH_BILLING_V3, requestMonthBilling)
