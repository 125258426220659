import * as React from 'react'
import CityList from 'containers/CityList'
import LocationList from 'containers/LocationList'
import NavLinkContaier from 'containers/NavLinkContainer'
import { isLogin } from 'utils/auth'
import { Link, Switch, Route } from 'react-router-dom'
import { t } from 'i18next'
import './index.css'

const PublicLocation: React.SFC<{}> = React.memo(() => (
  <Switch>
    <Route exact={true} path='/public/cities' component={CityList} />
    <Route
      exact={true}
      path='/public/:city_id'
      render={() => (
        <>
          <div className='home-tab-header'>
            <div className='home-tab-label'>{t('public.Public Locations')}</div>
            <Link to='/public/cities' className='grey_text small_text'>
              <i className='material-icons line_icon'>location_city</i>
              {t('public.Browse by City')}
            </Link>
          </div>
          <LocationList type='public' />
        </>
      )}
    />
    <Route
      exact={true}
      path='/public'
      render={() => (
        <>
          <div className='home-tab-header'>
            <div className='home-tab-label'>{t('public.Public Locations')}</div>
            <Link to='/public/cities' className='grey_text small_text'>
              <i className='material-icons line_icon'>location_city</i>
              {t('public.Browse by City')}
            </Link>
          </div>
          <LocationList type='public' />
        </>
      )}
    />
  </Switch>
))

const PublicRoute: React.SFC<{}> = React.memo(() =>
  isLogin() ? (
    <NavLinkContaier active='public'>
      <Route path='/public' component={PublicLocation} />
    </NavLinkContaier>
  ) : (
    <div className='location-wrapper'>
      <div className='location-inner--home'>
        <Route path='/public' component={PublicLocation} />
      </div>
    </div>
  )
)

export default PublicRoute
