import { updateSiteState } from 'actions/uiState'
import { Tabs } from 'antd'
import { t } from 'i18next'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import './index.css'
import { P, StateProps } from './type'

class NavLinkContainer extends React.PureComponent<P, {}> {
  tabs = [
    {
      label: t('workspace.Workspace List'),
      key: 'workspaces',
      children: undefined
    },
    {
      label: t('public.All Locations'),
      key: 'locations',
      children: undefined
    },
    {
      label: t('public.Public Locations'),
      key: 'public',
      children: undefined
    },
    {
      label: t('Favorites'),
      key: 'favorites',
      children: undefined
    }
  ]
  componentDidMount() {
    const { dispatch, workspaceID } = this.props
    if (workspaceID) {
      dispatch(
        updateSiteState({
          workspaceID: ''
        })
      )
    }
  }
  tabClick = (key: string): void => {
    const { history } = this.props
    history.push('/' + key)
  }
  render() {
    const { active, children } = this.props

    return (
      <div className='location-wrapper'>
        <div className='location-inner--home'>
          <Tabs activeKey={active} onTabClick={this.tabClick}>
            {this.tabs.map(tab => (
              <Tabs.TabPane tab={tab.label} key={tab.key}>
                {tab.key === active ? children : tab.children}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({
  uiState: {
    siteState: { workspaceID }
  }
}) => ({
  workspaceID
})

export default withRouter(connect(mapStateToProps)(NavLinkContainer))
