import { GetUserPermissionAction, GET_USER_PERMISSION_V3 } from 'actions/V3/permission'

export type PermissionState = {
  data: GuardRule[],
  meta?: Meta,
  query?: any
}

export default function Permission(
  state: PermissionState = {
    data: []
  },
  action: GetUserPermissionAction
) {
  switch (action.type) {
    case GET_USER_PERMISSION_V3:
      return {
        data: action.data,
        meta: action.meta,
        query: action.query
      }
    default:
      return state
  }
}
