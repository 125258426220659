import * as React from 'react'
import moment from 'moment'
import qs from 'qs'
import SearchBar from 'components/SearchBar'
import { apiV3 } from 'utils/api'
import { Col, Dropdown, Icon, Menu, Modal, Pagination, Popconfirm, Row } from 'antd'
import { connect, DispatchProp } from 'react-redux'
import { pick } from 'lodash'
import { queryMerge } from 'utils/query'
import { requestReportTemplate, RequestReportTemplateAction, Template } from 'actions/V3/report'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { screen } from './Modal'
import { t } from 'i18next'

type StateProps = {
  template: Template[],
  meta?: Meta,
  query: any
}

type P = StateProps & DispatchProp<RequestReportTemplateAction> & RouteComponentProps<{ id: string }>

type State = Readonly<{
  previewData?: Template
}>

class TemplateList extends React.PureComponent<P, State> {
  state: State = {}
  menu = tid => {
    const {
      history,
      match: {
        params: { id }
      }
    } = this.props
    return (
      <Menu>
        <Menu.Item>
          <div className='report-card-menu' onClick={() => history.push(`/workspaces/${id}/reports/template/${tid}`)}>
            <Icon type='form' />
            {t('Edit')}
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className='report-card-menu' onClick={() => this.duplicateTemplate(tid)}>
            <Icon type='copy' />
            {t('Duplicate')}
          </div>
        </Menu.Item>
        <Menu.Item>
          <Popconfirm title={t('Report.Are you sure delete this template')} onConfirm={() => this.deleteTemplate(tid)} okType='danger'>
            <div className='report-card-menu'>
              <Icon type='delete' />
              {t('Delete')}
            </div>
          </Popconfirm>
        </Menu.Item>
      </Menu>
    )
  }

  componentDidMount() {
    const {
      location: { search }
    } = this.props
    const removeFirst = 1
    const query = pick(qs.parse(search.slice(removeFirst)), ['page', 'name'])

    this.fetchReportTemplate({
      page: +(query.page || 0),
      name: query.name
    })
  }

  fetchReportTemplate = (query = {}) => {
    const {
      match: {
        params: { id }
      },
      dispatch
    } = this.props

    dispatch(requestReportTemplate({ workspace_id: id, ...query }))
  }

  selectPage = page => {
    const {
      location: { search, pathname },
      history,
      query: q
    } = this.props
    const query = queryMerge({
      search,
      query: {
        page: page
      },
      delKeys: page === 1 ? ['page'] : []
    })
    history.push({
      pathname,
      search: query
    })
    this.fetchReportTemplate({ ...q, page })
  }

  search = (text: string): void => {
    const name = text.trim()
    const {
      location: { search, pathname },
      history
    } = this.props
    const query = queryMerge({
      search,
      query: {
        name
      },
      delKeys: name ? ['page', 'size'] : ['page', 'size', 'name']
    })

    history.push({
      pathname,
      search: query
    })
    this.fetchReportTemplate({ name })
  }

  gotoCreate = () => {
    const {
      match: {
        params: { id }
      },
      history
    } = this.props
    history.push(`/workspaces/${id}/reports/template/create`)
  }

  requestReport = data => {
    const {
      match: {
        params: { id }
      },
      history
    } = this.props
    history.push(`/workspaces/${id}/reports/create`, data)
  }

  duplicateTemplate = async tid => {
    await apiV3(`/report_templates/${tid}/duplicate`, 'post')
    this.fetchReportTemplate()
  }

  deleteTemplate = async tid => {
    await apiV3(`/report_templates/${tid}`, 'delete')
    this.fetchReportTemplate()
  }

  renderPreview = () => {
    const data = this.state.previewData
    if (!data) {
      return null
    }
    return (
      <div>
        <h2 className='report-preview-title'>{data.name}</h2>
        {data.modules.map((m, i) => (
          <div className='report-panel' key={`report-panel-${i}`}>
            <div className='report-panel-header'>
              <div className='report-panel-title'>{m.name || m.indicators.toString()}</div>
            </div>
            {m.category === 'comparison' && (
              <div className='report-preview-img'>
                <img width='100%' src={screen.comparsion} alt='' />
              </div>
            )}

            {m.category === 'indicator' && (
              <>
                {m.features.map(
                  (f, fi) =>
                    screen[f] && (
                      <div className='report-preview-img' key={`report-preview-${i}-${fi}`}>
                        <img width='100%' src={screen[f]} alt='' />
                      </div>
                    )
                )}
              </>
            )}
          </div>
        ))}
      </div>
    )
  }

  renderTemplate = () => {
    const { template } = this.props
    return template.map((item, index) => (
      <Col span={8} key={`template-item-${index}`}>
        <div className='report-card'>
          {moment(item.created_at).isAfter(moment().subtract(7, 'days')) && <div className='report-card-new iconfont icon-new-biaoqian' />}

          <p className='small_text grey_text report-card-msg'>{t('Report.Report Template')}</p>
          <h1 className='report-card-name'>{item.name}</h1>

          <div className='report-card-bottom'>
            <div className='report-card-bottom_button' onClick={() => this.requestReport(item)}>
              <i className='iconfont icon-shiyongwendang' />
              {t('Use')}
            </div>
            <div className='report-card-bottom_button' onClick={() => this.setState({ previewData: item })}>
              <i className='iconfont icon-view' />
              {t('View')}
            </div>
            <Dropdown overlay={this.menu(item.id)} trigger={['click']} placement='bottomRight'>
              <a className='material-icons report-card-more'>more_vert</a>
            </Dropdown>
          </div>
        </div>
      </Col>
    ))
  }

  render() {
    const { previewData } = this.state
    const { meta } = this.props
    return (
      <div>
        <p className='small_text report-small_message grey_text'>{t('Report.You can use a report template or create a new template')}</p>
        <SearchBar
          onSearch={this.search}
          placeholder={t('Report.Search Report Template by name')}
          iconStyle={{
            marginLeft: 0
          }}
        />

        <Row gutter={36}>
          <Col span={8}>
            <div className='report-card is-add' onClick={this.gotoCreate}>
              <i className='material-icons'>add</i>
              <p>{t('Report.Create New Template')}</p>
            </div>
          </Col>
          {this.renderTemplate()}
        </Row>
        {meta && (
          <Pagination
            size='small'
            onChange={this.selectPage}
            hideOnSinglePage={true}
            style={{ textAlign: 'right', marginTop: 20 }}
            current={meta.current_page}
            defaultPageSize={20}
            total={meta.total_count}
          />
        )}
        <Modal visible={!!previewData} width={540} onCancel={() => this.setState({ previewData: undefined })} centered={true} footer={null}>
          {this.renderPreview()}
        </Modal>
      </div>
    )
  }
}

const mapState: MapState<StateProps> = ({ V3: { report } }) => ({
  template: report.template,
  meta: report.templateMeta,
  query: report.templateQuery
})

export default withRouter(connect(mapState)(TemplateList))
