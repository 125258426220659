import { put, call } from 'redux-saga/effects'

import { REQUEST_USER_CONFIG_V3, getUserConfig } from 'actions/V3/userConfig'
import { watchRequest } from 'sagas/helper'

import { apiV3 } from 'utils/api'

function* request(args: {}) {
  const { data } = yield call(apiV3, '/users/configs', 'get', { ...args })
  yield put(getUserConfig(data))
  return data
}

export default watchRequest(REQUEST_USER_CONFIG_V3, request)
