import {
    NotificationConfigV3Action,
    GET_NOTIFICATION_CONFIG_V3,
    NotificationLimit,
    NotificationCategory
} from 'actions/V3/notificationConfig';

type LimitState = {
  data: NotificationLimit[];
  meta?: Meta;
  query?: any;
};

export type NotificationLimitState = {
  [P in NotificationCategory]: LimitState
};

export default function NotificationConfig(
    state: NotificationLimitState = {
        all: {
            data: []
        },
        location: {
            data: []
        },
        collection: {
            data: []
        },
        indicator: {
            data: []
        },
        datasource: {
            data: []
        }
    },
    action: NotificationConfigV3Action
) {
    switch (action.type) {
    case GET_NOTIFICATION_CONFIG_V3:
        return {
            ...state,
            [action.category]: {
                data: action.data,
                meta: action.meta,
                query: action.query
            }
        };
    default:
        return state;
    }
};
