import { put, call } from 'redux-saga/effects'
import { REQUEST_LOCATION, getLocation, getOurdoor, REQUEST_OUTDOOR, requestOutdoor } from 'actions/V2/location'
import { watchRequestNoCancel } from 'sagas/helper'
import { updateSiteState } from 'actions/uiState'
import api from 'utils/api'

function* request(args: { location_id: number, auth_token?: string }) {
  // 记录location_id
  yield put(
    updateSiteState({
      locationID: args.location_id + ''
    })
  )

  const { data } = yield call(api, `/locations/${args.location_id}`, 'get', args)

  const token = args.auth_token ? { auth_token: args.auth_token } : {}

  // 请求室外场所数据
  if (data.city_id) {
    yield put(requestOutdoor({ city_id: data.city_id, ...token }))
  }

  yield put(getLocation(data))
  return data
}

export default watchRequestNoCancel(REQUEST_LOCATION, request)

function* outdoorRequest(args: { city_id: number }) {
  const { data } = yield call(api, '/dictionaries/outdoors', 'get', args)

  yield put(getOurdoor(data))
  return data
}

export const watchOutdoor = watchRequestNoCancel(REQUEST_OUTDOOR, outdoorRequest)
