import { refreshPermission } from 'actions/V3/permission';
import { message, Tooltip } from 'antd';
import { snakeCase } from 'change-case';
import { BasicDeny, Deny } from 'components/Permission';
import { t } from 'i18next';
import * as React from 'react';
import { connect } from 'react-redux';
import { logging } from './loggers';

const oMap = o => {
    const map = {
        '>=': t('Over'),
        '>': t('Over'),
        '<=': t('Less'),
        '<': t('Less')
    };

    return map[o] || o;
};

const template = (rule: GuardRule) => {
    if (rule.p) {
        return `${t('can not use')} ${rule.name} ${t('features')}`;
    }

    if (!rule.b) {
        return `${t('Basic')} ${t('can not use')} ${rule.name} ${t('features')}`;
    }

    switch (rule.c) {
    case 'status':
        return `${t('can not use')} ${rule.name} ${t('features')}`;
    default:
        return `${t('Basic')} ${t('Permission Denied!')} ${rule.name} ${oMap(
            rule.o
        )} ${rule.v}`;
    }
};

const denyTemp = (rule: GuardRule) =>
    React.createElement(!rule.b ? BasicDeny : Deny, { message: template(rule) });
/**
 *
 * [{
 *    key: 'location.edit',
 *    name: '',
 *    c: 'limit|status',
 *    t: 'number|date|'
 *    o: '大于'
 *    v: '2/sj/'
 *    b: // basic 用户是否可用
 *    p: boolean // is pro ?
 *    handle: [] // 用于区别作用
 *    enable: ture | false，
 * }],
 *
 * 使用方法
 *
 * 1. 给 ComponentClass 加上注解
 *
 * @Guard
 * class SomeComponent extends React.Component
 *
 * 2. 根据不同规则对需要验证的函数进行命名
 *
 * Guard_ 开头为功能型函数，一般代表点击按钮等回调功能，如果验证通过则正常执行，不通过则只会发出提示信息
 * GuardRender_ 开头为渲染函数，通过权限控制是否渲染的内容，通过正常渲染，不通过则渲染提示信息
 *
 * GuardTrigger_ 为 Guard_ 开头的函数触发元素，例如button select，通过正常使用，不通过则会被更改为disabled 模式
 * 因此需要注意 GuardTrigger_返回的元素一定需要支持disabled 属性（例如 input button select）
 *
 * 组件名称 加上 前缀之后的命名为功能的key
 *
 * 例:
 * @Guard
 * class SomeComponent extends React.Component {
 *   Guard_action = () => {
 *    // ...some action
 *   }
 *
 *   GuardRender_someting = () => {
 *      return <div>...some element</div>
 *   }
 *
 *   GuardTrigger_action = () {
 *     return <Button onClick={this.Guard_action}>action button</Button>
 *   }
 * }
 *
 * 根据规则
 * 可以得到两个key  SomeComponent.action 和 SomeComponent.someting
 * 之后通过请求后端寻找这两个key 代表的规则是否通过，从而改变组件的真实状态
 *
 * 注意：
 * 1. Guard 相关的函数必须用箭头函数书写，用function模式，在枚举时无法获取
 * 2. Guard 相关的函数支持使用参数，内部使用this等，一般而言在通过模式下不会有改动，而不通过时是不需要执行环境的（this.state, this.porps 等）
 * 3. 为了和后端key统一，Guard前缀之后的函数名称会被转换成snakecase模式，最好统一使用camelcase风格书写
 * 例： Guard_downloadFile  提取出的key 为 download_file
 * 最终 key 为  DataTable.download_file
 * 4. 注意脚本会对文件进行扫描从而得到一个Class.key 的列表，因此不要在使用Guard文件中写多个class，会有可能导致class名称错误
 */

const guardPrefix = ['Guard_', 'GuardRender_', 'GuardTrigger_', 'GuardHandle_'];

export function Guard<T extends { new (...args: any[]): {} }>(constructor: T) {
    const className = constructor.name;
  @((connect as any)(state => ({ permission: state.V3.permission }), {
      refreshPermission
  }))
    class GuardWrap extends constructor {
      constructor(...args: any[]) {
          super(...args);

          this.addGuardPermission();
      }

      // checkPermission() {
      //   // check permission data
      //   const {
      //     V3: { permission }
      //   } = store.getState();
      //   return !!permission.data.length;
      // }

      // run() {
      //   // wait permission data and retry
      //   // permission data 在redux 中会自动刷新组建，一般这一步判断是没必要的
      //   // 有一部分组件和路由是在permission之外的
      //   if (this.checkPermission()) {
      //     this.addGuardPermission();
      //   } else {
      //     setTimeout(() => this.run(), 1000);
      //   }
      // }
      addGuardPermission() {
          const GuardProptypes = Object.getOwnPropertyNames(this).filter(key =>
              guardPrefix.some(prefix => key.includes(prefix))
          );

          GuardProptypes.forEach(key => {
              const method = this[key];
              // 功能函数，无权限不执行本体内容只出现信息提示
              if (key.includes('Guard_')) {
                  const GuardKey = `${className}.${snakeCase(
                      key.replace('Guard_', '')
                  )}`;

                  this[key] = (...ag: any[]) => {
                      const rule = (this as any).props.permission.data.find(
                          v => v.key === GuardKey
                      );

                      if (!rule) {
                          return message.warn(t('cannot use this feature'));
                      }
                      if (!rule.enable) {
                          return message.warn(template(rule));
                      }

                      return method.call(this, ...ag);
                  };
              }

              // 渲染部分，无权限则渲染文字
              if (key.includes('GuardRender_')) {
                  const GuardKey = `${className}.${snakeCase(
                      key.replace('GuardRender_', '')
                  )}`;

                  this[key] = (...ag: any[]) => {
                      const rule = (this as any).props.permission.data.find(
                          v => v.key === GuardKey
                      );

                      if (!rule) {
                          return React.createElement(Deny);
                      }
                      if (!rule.enable) {
                          return denyTemp(rule);
                      }

                      return method.call(this, ...ag);
                  };
              }

              if (key.includes('GuardTrigger_')) {
                  const GuardKey = `${className}.${snakeCase(
                      key.replace('GuardTrigger_', '')
                  )}`;

                  this[key] = (...ag: any[]) => {
                      const rule = (this as any).props.permission.data.find(
                          v => v.key === GuardKey
                      );
                      const element = method.apply(this, ag);
                      if (!rule) {
                          return React.createElement(
                              Tooltip,
                              { title: t('cannot use this feature') },
                              React.cloneElement(element, { disabled: true })
                          );
                      }
                      if (!rule.enable) {
                          return React.createElement(
                              Tooltip,
                              { title: template(rule) },
                              React.cloneElement(element, { disabled: true })
                          );
                      }

                      return element;
                  };
              }

              if (key.includes('GuardHandle_')) {
                  const GuardKey = `${className}.${snakeCase(
                      key.replace('GuardHandle_', '')
                  )}`;

                  this[key] = (...ag: any[]) => {
                      const rule = (this as any).props.permission.data.find(
                          v => v.key === GuardKey
                      );

                      return method.call(this, rule, rule && template(rule), ...ag);
                  };
              }
          });
      // const _this: any = this;
      // if (_this.state) {
      //   _this.forceUpdate();
      // }
      }

    _refreshPermission = () => {
        logging.error((this as any).props);
    };
  }
  return GuardWrap;
}
