import {
  IndicatorAction,
  GET_INDICATOR_V3,
  GET_INDICATOR_DETAIL_V3,
  GET_INDICATOR_AVAILABLE_DATA_SOURCE_V3,
  IndicatorItem
} from 'actions/V3/indicators'
import { DatasourceObj } from 'actions/V3/datasources'
export type IndicatorState = {
  data: IndicatorItem[],
  meta?: Meta,
  query?: {},
  detail?: IndicatorItem,
  dataSource: DatasourceObj[],
  dataSourceMeta?: Meta,
  dataSourceQuery?: {}
}

export default function Indicators(
  state: IndicatorState = {
    data: [],
    dataSource: []
  },
  action: IndicatorAction
) {
  switch (action.type) {
    case GET_INDICATOR_V3:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        query: action.query
      }
    case GET_INDICATOR_DETAIL_V3:
      return {
        ...state,
        detail: action.data
      }
    case GET_INDICATOR_AVAILABLE_DATA_SOURCE_V3:
      return {
        ...state,
        dataSource: action.data,
        dataSourceMeta: action.meta,
        dataSourceQuery: action.query
      }
    default:
      return state
  }
}
