import { changeWorkspace } from 'actions/uiState';
import { requestUserShip } from 'actions/V2/userShip';
import {
  requestWorkspaceEdit,
  requestWorkspaceList,
  requestWorkspaceNew
} from 'actions/V3/workspace';
import { Button, Dropdown, Icon, Input, Menu, Modal } from 'antd';
import Table from 'components/Custom/Table';
import SearchBar from 'components/SearchBar';
import NavLinkContaier from 'containers/NavLinkContainer';
import { t } from 'i18next';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { promiseDispatch } from 'utils';
import './index.css';
import { P, State, StateProps } from './type';

import AddWorkspace from '-!svg-react-loader!images/svg/addWorkspace.svg';
import EmptyWorkspaceImg from '-!svg-react-loader!images/svg/emptyWorkspace.svg';

class Workspaces extends React.PureComponent<P, State> {
  state: State = {
    modalVisible: false,
    name: '',
    search: '',
    pagination: {
      defaultPageSize: 15,
      current: 1,
      hideOnSinglePage: true,
      showQuickJumper: true
    }
  };

  componentDidMount() {
    const { org, editPro, dispatch } = this.props;
    if (org && editPro) {
      dispatch(
        requestWorkspaceList({
          org_id: org.id
        })
      );
    } else {
      dispatch(requestWorkspaceList());
    }
  }

  handleTableChange = pagination => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager
    });
  };

  renderMenu = record => {
    return (
      <Menu>
        <Menu.Item key={1}>
          <a
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.props.history.push('/upgrade/' + record.id);
            }}
          >
            {t('Home.Change Pro Status')}
          </a>
        </Menu.Item>
      </Menu>
    );
  };

  geneTableColumns = editPro => {
    const cols: any = [
      {
        title: '',
        dataIndex: 'key',
        width: '10%',
        key: 'key',
        align: 'center' as 'center'
      },
      {
        title: t('Home.Workspace ID'),
        dataIndex: 'code',
        width: '40%',
        key: 'code',
        render: (text, record) => {
          return (
            <div>
              {text}
              {record.pro && (
                <span className='workspace-ov-badge'>{t('overview.PRO')}</span>
              )}
            </div>
          );
        }
      },
      {
        title: t('Home.Workspace Name'),
        dataIndex: 'name',
        width: '40%',
        key: 'name',
        render: (text, record) => {
          return <div className='accent_text'>{text}</div>;
        }
      }
    ];
    const { userConfig, org, dispatch } = this.props;
    if (org && editPro) {
      cols.push({
        title: '',
        dataIndex: 'pro',
        width: '20%',
        key: 'pro',
        align: 'center' as 'center',
        render: (text, record) => {
          return (
            <Dropdown
              overlay={this.renderMenu(record)}
              trigger={['click']}
              placement='bottomLeft'
            >
              <a className='ant-dropdown-link material-icons'>more_vert</a>
            </Dropdown>
          );
        }
      });
    } else if (org && userConfig && userConfig.orgs[0].permission === 'edit') {
      cols.push({
        title: t('Add to organization'),
        dataIndex: 'pro',
        width: '20%',
        key: 'pro',
        align: 'center' as 'center',
        render: (text, record) => {
          const addToOrg = e => {
            e.stopPropagation();
            e.preventDefault();
            Modal.confirm({
              title: t('org.Add workspace to Billing Organization'),
              content: t('org.Add workspace to Billing Organization Msg'),
              okText: t('org.Add'),
              onOk: () => {
                dispatch(
                  requestWorkspaceEdit({
                    org_id: org.id,
                    id: record.id
                  })
                );
              }
            });
          };
          return record.org_id ? (
            t('org.Added')
          ) : (
            <a onClick={addToOrg} className='accent_link'>
              {t('org.Add')}
            </a>
          );
        }
      });
    }
    return cols;
  };

  navToNewWorksapce = () => {
    this.setState({
      modalVisible: true
    });
  };

  handleModalCancel = () => {
    this.setState({
      modalVisible: false
    });
  };

  onRow = record => {
    return {
      onClick: () => {
        if (!this.props.editPro) {
          const { dispatch } = this.props;
          dispatch(changeWorkspace(record.id));
        }
      } // 点击行
    };
  };

  renderTable = (editPro = false) => {
    const { workspaceList } = this.props;
    const { search } = this.state;
    if (!workspaceList) {
      return null;
    }
    let data = workspaceList
      .filter(v => {
        const nameMatch =
          v.name.toLowerCase().includes(search.toLowerCase()) ||
          search.toLowerCase().includes(v.name.toLowerCase());
        const idMatch = v.id === +search;
        const codeMatch = v.code.includes(search) || search.includes(v.code);
        return nameMatch || idMatch || codeMatch;
      })
      .map((item, i) => ({ ...item, key: i + 1 }));
    if (editPro) {
      data = data.filter(e => e.org_id);
    }
    const pagination = Object.assign(this.state.pagination, {
      showTotal: () => t('showTotal', { total: data.length })
    });
    return (
      <>
        {!editPro && (
          <div className='home-tab-header is-workspace'>
            <div className='home-tab-label'>
              {t('workspace.Workspace List')}
            </div>
            <span
              className='home-add-worksapce-icon hidden-mobile'
              onClick={this.navToNewWorksapce}
            >
              <AddWorkspace />
            </span>
          </div>
        )}

        {!!workspaceList.length && (
          <SearchBar
            onChange={e => this.setState({ search: e.target.value })}
            value={search}
            containerClassName='location-search is-block'
            className='colletion_modal-search'
            placeholder={t('filter.search workspaces')}
            iconStyle={{
              marginLeft: 0
            }}
          />
        )}
        <div className='home-table-wrap'>
          {data.length || search ? (
            <Table
              onRow={this.onRow}
              rowClassName={() => 'home-ws-row'}
              type='light'
              pagination={pagination}
              columns={this.geneTableColumns(editPro)}
              dataSource={data}
              onChange={this.handleTableChange}
              size='default'
            />
          ) : (
            !editPro && (
              <div className='home-ws-empty-block'>
                <EmptyWorkspaceImg />
                <div className='home-ws-empty-title'>
                  {t('workspace.No Data')}
                </div>
                <div className='home-ws-empty-tips'>
                  {t('workspace.There is no workspace')}
                  <br /> {t('or')}
                  {t('workspace.wait invite')}
                </div>
                <Button type='primary' onClick={this.navToNewWorksapce}>
                  {t('workspace.Create New Workspace')}
                </Button>
              </div>
            )
          )}
        </div>
      </>
    );
  };

  confirmNew = async () => {
    const { dispatch, history } = this.props;
    const { name } = this.state;
    if (name) {
      const data = await promiseDispatch<any>({
        dispatch,
        actionCreator: requestWorkspaceNew,
        payload: { name }
      });
      this.setState({
        modalVisible: false,
        name: ''
      });

      Modal.confirm({
        icon: (
          <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
        ),
        centered: true,
        title: `${t('workspace.create success')}`,
        content: (
          <div className='grey_text'>
            {t('workspace.go to workspace to continue')}
          </div>
        ),
        onOk() {
          history.push(`/workspaces/${data.id}`);
        },
        okText: `${t('workspace.go to', { name })}`,
        cancelText: t('Close')
      });
      // workspace 访问权限问题临时方案
      dispatch(requestUserShip({ show: false }));
    }
  };

  onChangeInput = e => {
    this.setState({ name: e.target.value });
  };

  render() {
    const { editPro } = this.props;
    if (editPro) {
      return this.renderTable(true);
    }
    return (
      <>
        <NavLinkContaier active='workspaces'>
          {this.renderTable()}
        </NavLinkContaier>
        <Modal
          closable={false}
          footer={null}
          maskClosable={true}
          centered={true}
          visible={this.state.modalVisible}
          bodyStyle={{
            padding: '24px 40px'
          }}
          onCancel={this.handleModalCancel}
          wrapClassName='new-ws-modal'
        >
          <div className='newWS-modal'>
            <div className='newWS-modal-header'>
              <div className='modal-title'>
                {t('workspace.Create New Workspace')}.
              </div>
              <div className='modal-sub-title'>{t('NewWS.desc2')}</div>
            </div>
            <div className='newWS-modal-body'>
              <div className='newWS-modal-label'>
                {t('NewWS.Workspace Name')}
              </div>
              <div className='newWS-modal-input'>
                <Input
                  id='name'
                  type='text'
                  value={this.state.name}
                  onChange={this.onChangeInput}
                />
              </div>
              <div className='newWS-modal-input-tip'>
                {t(
                  'NewWS.This is typically the name of your organization or company'
                )}
              </div>
            </div>
            <div className='newWS-modal-footer'>
              <Button type='primary' onClick={this.confirmNew}>
                {t('NewWS.Create')}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V3: { workspace, org, userConfig }
}) => ({
  workspaceList: workspace.list,
  userConfig: userConfig.data,
  org: org.detail
});

export default withRouter(connect(mapStateToProps)(Workspaces));
