import Cloud from '-!svg-react-loader!images/svg/cloud.svg';
import { changeModal, ModalChangeAction } from 'actions/uiState';
import {
  CityItem,
  requestDictionary,
  RequestDictionaryAction,
  TimeZoneItem
} from 'actions/V2/dictionary';
import {
  Outdoor,
  requestOutdoor,
  RequestOutdoorAction
} from 'actions/V2/location';
import { WorkspaceItem } from 'actions/V2/userShip';
import {
  RequestLocationListV3Action,
  requestLocationsV3, REQUEST_LOCATION_LIST_V3
} from 'actions/V3/locationList';
import {
  requestLocationSelect,
  RequestSelectAction, REQUEST_LOCATION_SELECT_V3
} from 'actions/V3/locationSelect';
import { RefreshPermission, refreshPermission } from 'actions/V3/permission';
import { Form, Icon, Input, message, Select, Upload } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { DraggerProps } from 'antd/lib/upload';
import Button from 'components/Custom/Button';
import { t } from 'i18next';
import { entries } from 'lodash';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import cache from 'sagas/cache';
import { uploadImage } from 'utils';
import { apiV3 } from 'utils/api';
import { Guard } from 'utils/guard';
import { formateTimeZone } from 'utils/timeZone';
import './index.css';

const Dragger: React.SFC<DraggerProps> = Upload.Dragger;

export interface StateProps {
  outdoor?: Outdoor;
  timeZone: TimeZoneItem[];
  cities: CityItem[];
  workspaceID?: string;
  workspaces: WorkspaceItem[];
}

export type P = StateProps &
  DispatchProp<
    | RequestOutdoorAction
    | RequestDictionaryAction
    | ModalChangeAction
    | RequestLocationListV3Action
    | RequestSelectAction
    | RefreshPermission
  > &
  FormComponentProps;

type State = Readonly<{
  background_image: string;
  logo_image: string;
}>;
@Guard
class AddLocationForm extends React.PureComponent<P, State> {
  uploadBackground: (file: object) => void;
  uploadLogo: (file: object) => void;
  state: State = {
      background_image: '',
      logo_image: ''
  };
  constructor(props: P) {
      super(props);
      this.uploadBackground = this.uploadImage.bind(this, 'background_image');
      this.uploadLogo = this.uploadImage.bind(this, 'logo_image');
  }
  componentDidMount() {
      const { dispatch } = this.props;
      dispatch(requestDictionary({ name: 'time_zones' }));
      dispatch(requestDictionary({ name: 'cities' }));
  }

  GuardHandle_outdoorSelect = (rule?: GuardRule, message?: string) => ({
      enable: rule && rule.enable,
      message
  });

  handleSubmit = e => {
      e.preventDefault();
      cache.cleanCache(REQUEST_LOCATION_SELECT_V3);
      cache.cleanCache(REQUEST_LOCATION_LIST_V3);
      this.props.form.validateFieldsAndScroll(async (err, values) => {
          if (!err) {
              const { outdoor, dispatch, workspaceID } = this.props;
              const form = {
                  ...this.state,
                  ...values
              };

              if (outdoor && values.outdoor) {
                  form.outdoor_location_id = values.outdoor;
              }
              await apiV3('/locations', 'post', { location: form });
              dispatch(
                  refreshPermission({
                      show: false
                  })
              );
              dispatch(changeModal(''));

              if (workspaceID && +workspaceID === +form.workspace_id) {
                  // 当在当前workspace 创建 location 时 更新location 信息
                  dispatch(
                      requestLocationsV3({
                          show: false,
                          workspace_id: +workspaceID
                      })
                  );
                  dispatch(
                      requestLocationSelect({
                          show: false,
                          workspace_id: +workspaceID
                      })
                  );
              }
          }
      });
  };

  handleSelectCity = id => {
      const { dispatch, cities } = this.props;
      const city = cities.find(v => v.id === id);
      dispatch(requestOutdoor({ city_id: id, show: false }));
      this.props.form.setFieldsValue({
          time_zone: city!.time_zone,
          outdoor: null
      });
  };

  uploadImage = (type: keyof State, { file }: any) => {
      const uploadType = type === 'background_image' ? 'background' : 'logo';
      uploadImage(file, uploadType).then(observable => {
          if (observable) {
              observable.subscribe(
                  next => {
                      /* next handle */
                  },
                  err => {
                      /* error handle */
                      message.error(t('upload Error', { message: err.message }));
                  },
                  ({ key: path }) => {
                      const state = {
                          [type]: path
                      };
                      this.setState(state as State);
                  }
              );
          }
      });
  };

  render() {
      const {
          form: { getFieldDecorator, getFieldsError, getFieldValue },
          workspaces,
          workspaceID,
          cities,
          outdoor,
          timeZone
      } = this.props;

      const { background_image } = this.state;
      const res: {
      name?: string[];
      workspace_id?: string[];
    } = getFieldsError();

      const isOutdoor = getFieldValue('category') === 'outdoor';
      const showOutdoor = this.GuardHandle_outdoorSelect();

      return (
          <Form layout='vertical' onSubmit={this.handleSubmit}>
              <div className='register-add_location'>
                  <h3 className='register-title'>{t('Create New Location')}</h3>
                  <div className='register-body'>
                      <Form.Item label={t('navbar.Workspace')}>
                          {getFieldDecorator('workspace_id', {
                              rules: [
                                  {
                                      required: true,
                                      message: t('validate.select workspace')
                                  }
                              ],
                              initialValue: workspaceID && +workspaceID
                          })(
                              <Select
                                  showSearch={true}
                                  style={{ width: '100%' }}
                                  placeholder={t('Select a workspace')}
                                  optionFilterProp='children'
                                  filterOption={(input, option) =>
                                      (option.props.children as string)
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                  }
                              >
                                  {workspaces.map((item, i) => (
                                      <Select.Option key={item.id} value={item.id}>
                                          {item.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          )}
                      </Form.Item>
                      <Form.Item
                          label={
                              <>
                                  {t('locationOverview.Location Name')}
                                  <Icon
                                      type='info-circle-o'
                                      className='register-add_location-icon'
                                  />
                              </>
                          }
                      >
                          {getFieldDecorator('name', {
                              rules: [
                                  {
                                      required: true,
                                      message: t('validate.input location name')
                                  }
                              ]
                          })(<Input />)}
                      </Form.Item>
                      <Form.Item
                          label={
                              <>
                                  {t('locationOverview.Location Address')}
                                  <Icon
                                      type='info-circle-o'
                                      className='register-add_location-icon'
                                  />
                              </>
                          }
                      >
                          {getFieldDecorator('address')(<Input placeholder='address' />)}
                      </Form.Item>
                      <Form.Item label={t('City')}>
                          {getFieldDecorator('city_id')(
                              <Select
                                  showSearch={true}
                                  onSelect={this.handleSelectCity}
                                  style={{ width: '100%' }}
                                  placeholder={t('Select City')}
                                  optionFilterProp='children'
                              >
                                  {cities.map((v, index) => (
                                      <Select.Option key={`city-${index}`} value={v.id}>
                                          {v.name}
                                      </Select.Option>
                                  ))}
                              </Select>
                          )}
                      </Form.Item>

                      <Form.Item label={t('locationOverview.Time Zone')}>
                          {getFieldDecorator('time_zone')(
                              <Select
                                  style={{ width: '100%' }}
                                  showSearch={true}
                                  placeholder={t('Select timezone')}
                                  optionFilterProp='children'
                                  filterOption={(input, option) =>
                                      (option.props.children as string)
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                  }
                              >
                                  {formateTimeZone(timeZone).map((value, index) => (
                                      <Select.Option
                                          key={`time-zone-${index}`}
                                          disabled={value.disabled}
                                          value={value.value}
                                      >
                                          {value.label}
                                      </Select.Option>
                                  ))}
                              </Select>
                          )}
                      </Form.Item>

                      <Form.Item
                          label={
                              <>
                                  {t('locationOverview.Location Type')}
                                  <Icon
                                      type='info-circle-o'
                                      className='register-add_location-icon'
                                  />
                              </>
                          }
                      >
                          {getFieldDecorator('category', { initialValue: 'indoor' })(
                              <Select placeholder={t('locationOverview.Location Type')}>
                                  <Select.Option value='indoor'>
                                      {t('filter.indoor')}
                                  </Select.Option>
                                  <Select.Option value='outdoor'>
                                      {t('filter.outdoor')}
                                  </Select.Option>
                              </Select>
                          )}
                      </Form.Item>

                      <Form.Item
                          className='register-add_location-img is-background'
                          label={
                              <>
                                  {t('locationOverview.Outdoor Counterpart')}
                                  <div className='small_text grey_text'>
                                      {t('locationOverview.if the location type is indoor')}
                                  </div>
                              </>
                          }
                      >
                          {!showOutdoor.enable || isOutdoor ? (
                              <Select
                                  disabled={true}
                                  value={undefined}
                                  placeholder={
                                      !showOutdoor.enable
                                          ? showOutdoor.message
                                          : t('locationOverview.Available only indoor')
                                  }
                              />
                          ) : (
                              getFieldDecorator('outdoor')(
                                  <Select
                                      showSearch={true}
                                      placeholder={t('locationOverview.outdoor need')}
                                      optionFilterProp='children'
                                  >
                                      {outdoor &&
                      entries(outdoor).map((v, index) => (
                          <Select.Option key={`outdoor-${index}`} value={+v[0]}>
                              {v[1]}
                          </Select.Option>
                      ))}
                                  </Select>
                              )
                          )}
                      </Form.Item>

                      <Form.Item
                          className='register-add_location-img is-background'
                          label={
                              <>
                                  {t('locationOverview.Location Image')}
                                  <span className='grey_text'> ({t('optional')})</span>
                                  <Icon
                                      type='info-circle-o'
                                      className='register-add_location-icon '
                                  />
                                  <div className='small_text grey_text'>
                                      {t('locationOverview.For best results')}
                                  </div>
                              </>
                          }
                      >
                          <Dragger
                              accept='image/jpeg,image/png,image/jpg'
                              showUploadList={false}
                              customRequest={this.uploadBackground}
                              style={{
                                  backgroundImage: `url(${
                                      background_image
                                          ? `https://assets-1.qlear.io/${background_image}`
                                          : ''
                                  })`
                              }}
                          >
                              <p className='ant-upload-text'>
                                  <Cloud className='register-add_location-cloud' />
                                  {t('Drop files')}
                                  <a className='accent_text'>{t('browse')}</a>
                              </p>
                          </Dragger>
                      </Form.Item>
                  </div>
                  <div style={{ textAlign: 'right' }}>
                      <Button
                          customType='dark'
                          htmlType='submit'
                          disabled={!!res.name || !!res.workspace_id}
                      >
                          {t('Create Location')}
                      </Button>
                  </div>
              </div>
          </Form>
      );
  }
}

const mapState: MapState<StateProps> = ({
    V2: {
        locationInfo: { outdoor },
        userShip,
        dictionary: { time_zones, cities }
    },
    uiState: {
        siteState: { workspaceID }
    }
}) => ({
    outdoor: outdoor,
    timeZone: time_zones,
    cities,
    workspaceID,
    workspaces: userShip.data
});

export default Form.create()(connect(mapState)(AddLocationForm));
