import { UserInfo, UserSignAction, GET_USER_SIGN } from 'actions/V2/userSign'

export type UserSignState = {
  data?: UserInfo
}

export default function UserSign(state: UserSignState = {}, action: UserSignAction) {
  switch (action.type) {
    case GET_USER_SIGN:
      return {
        data: action.userInfo
      }
    default:
      return state
  }
}
