import * as actions from 'actions/V3/search'

export type SearchState = {
  data?: actions.SearchResult,
  detail?: actions.SearchResult
}

export default function Search(state: SearchState = {}, action: actions.SearchV3Action) {
  switch (action.type) {
    case actions.GET_SEARCH_V3:
      return {
        ...state,
        data: action.data
      }
    case actions.GET_SEARCH_DETAIL_V3:
      return {
        ...state,
        detail: action.data
      }
    default:
      return state
  }
}
