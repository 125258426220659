import * as React from 'react'
import Button from 'components/Custom/Button'
import Table from 'components/Custom/Table'
import { connect } from 'react-redux'
import { Form, Input, Menu, Modal, Popover, Radio } from 'antd'
import { P, State, StateProps } from './type'
import { promiseDispatch } from 'utils'
import { requestDeleteOrgUser, requestNewOrgUser, requestOrgUsers, requestUpdateOrgUser } from 'actions/V3/org'
import { t } from 'i18next'
import { withRouter } from 'react-router-dom'
import './index.css'
import { logging } from 'utils/loggers'
// import { uniq } from 'lodash';
// import { emailValidate } from 'utils/validate';

const permission = {
  view: {
    title: t('org.permission1'),
    desc: t('org.permission1 tip')
  },
  edit: {
    title: t('org.permission2'),
    desc: t('org.permission2 tip')
  }
}

class Org extends React.PureComponent<P, State> {
  state: State = {
    addUserVisible: false,
    popoverVisibleId: '',
    userEmail: ''
  }

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    dispatch(
      requestOrgUsers({
        id
      })
    )
  }

  renderMenu = record => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    return (
      <div className='location-filter'>
        <Menu
          mode='inline'
          onClick={async ({ item, key }) => {
            await promiseDispatch({
              dispatch,
              actionCreator: requestUpdateOrgUser,
              payload: {
                id,
                org_user_id: record.id,
                user: {
                  permission: key
                }
              }
            })
            this.setState({
              popoverVisibleId: ''
            })
          }}
          defaultOpenKeys={['sub1']}
        >
          <Menu.SubMenu key='sub1' title={<span className='billing-menu-title'>{t('billing.Management')}</span>}>
            {Object.keys(permission).map(k => (
              <Menu.Item key={k}>
                <span className='grey_text small_text menu_text'>{permission[k].title}</span>
              </Menu.Item>
            ))}
          </Menu.SubMenu>
        </Menu>
        <Menu
          selectable={false}
          onClick={() => {
            this.setState({
              popoverVisibleId: ''
            })
            Modal.confirm({
              title: t('people.remove user'),
              okText: t('Remove'),
              okType: 'danger',
              content: t('people.Are you sure you want to Remove this user?'),
              onOk: close => {
                promiseDispatch({
                  dispatch,
                  actionCreator: requestDeleteOrgUser,
                  payload: {
                    id,
                    org_user_id: record.id
                  }
                }).then(close)
              }
            })
          }}
        >
          <Menu.Item key='1'>
            <span className='remove-user menu_text'>{t('people.remove user')}</span>
          </Menu.Item>
        </Menu>
      </div>
    )
  }

  popAddUser = () => {
    this.setState({
      addUserVisible: true
    })
  }

  addOrgUser = e => {
    e.preventDefault()
    const {
      form,
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    form.validateFields(async (err, v) => {
      if (!err) {
        try {
          await promiseDispatch({
            dispatch,
            actionCreator: requestNewOrgUser,
            payload: {
              id,
              user: v
            }
          })
          this.setState({
            addUserVisible: false
          })
        } catch (error) {
          logging.error(error)
        }
      }
    })
  }

  geneTableColumns = () => {
    const { popoverVisibleId } = this.state
    return [
      {
        title: t('billing.User Name'),
        dataIndex: 'user_name',
        width: '30%',
        key: 'user_name',
        render: (text, record) => {
          return (
            <div className='username'>
              {text}
              <div className='email'>{record.user_email}</div>
            </div>
          )
        }
      },
      {
        title: t('billing.Management'),
        dataIndex: 'permission',
        width: '60%',
        key: 'permission',
        render: (text, record) => {
          let obj = permission.view
          if (record.key === 0) {
            obj = permission.edit
          } else if (text) {
            obj = permission[text]
          }
          return (
            <div>
              <div className='per-title'>{obj.title}</div>
              <div className='per-desc'>({obj.desc})</div>
            </div>
          )
        }
      },
      {
        title: t('billing.Settings'),
        dataIndex: 'id',
        width: '10%',
        key: 'id',
        render: (text, record) => {
          return (
            record.key !== 0 && (
              <div className='user-settings'>
                <Popover
                  overlayClassName='header-notification-overlay billing-menu is-menu'
                  content={this.renderMenu(record)}
                  placement='bottom'
                  trigger='click'
                  visible={popoverVisibleId === text}
                  onVisibleChange={visible => {
                    this.setState({ popoverVisibleId: visible && text })
                  }}
                >
                  <i className='iconfont icon-menu' />
                </Popover>
              </div>
            )
          )
        }
      }
    ]
  }

  render() {
    const { users } = this.props
    const { getFieldDecorator } = this.props.form
    const data = users.map((item, i) => ({
      ...item,
      key: i
    }))
    const { addUserVisible } = this.state

    return (
      <div className='org-user-manage'>
        <div className='action-new'>
          <span onClick={this.popAddUser}>
            <i className='iconfont icon-plus' />
            {t('org.Add User')}
          </span>
        </div>
        <Table type='light' columns={this.geneTableColumns()} dataSource={data} pagination={false} size='default' />
        <Modal
          visible={addUserVisible}
          className='org-add-user-modal'
          title={
            <>
              <h3>{t('org.addUser')}</h3>
            </>
          }
          centered={true}
          onCancel={() => this.setState({ addUserVisible: false })}
          footer={null}
        >
          <Form onSubmit={this.addOrgUser} hideRequiredMark={true} layout='vertical' className='signinup-form'>
            <Form.Item label={t('user.E-mail Address')} hasFeedback={true}>
              {getFieldDecorator('email', {
                rules: [
                  {
                    type: 'email',
                    message: t('user.Invalid email format')
                  },
                  {
                    required: true,
                    message: t('user.Please input your email')
                  }
                ]
              })(<Input placeholder={t('org.inputTip')} />)}
            </Form.Item>
            <Form.Item className='people-invite-access'>
              {getFieldDecorator('permission', {
                initialValue: 'view'
              })(
                <Radio.Group>
                  {Object.keys(permission).map(k => (
                    <Radio value={k} key={k}>
                      <div className='per-title'>{permission[k].title}</div>
                      <div className='per-desc'>({permission[k].desc})</div>
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </Form.Item>
            <div className='footer'>
              <Button customType='dark' block={true} htmlType='submit'>
                {t('org.Add')}
              </Button>
            </div>
          </Form>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { org } }) => ({
  users: org.users || []
})

export default Form.create()(withRouter(connect(mapStateToProps)(Org)))
