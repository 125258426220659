import * as React from 'react'
import { Doughnut } from 'react-chartjs-2'
import './index.css'
import { isEqual } from 'lodash'

interface Props {
  collection: {
    label: string,
    color: string,
    data: number,
    status?: string
  }[];
  defaultActiveIdx?: number;
  onClick: (el: any) => void;
}

type State = Readonly<{
  activedObj?: {
    label: string,
    color: string,
    data: number,
    status?: string
  },
  hoverObj: boolean
}>

class PieChart extends React.Component<Props, State> {
  // totalCount: number;
  lastChartEle: any
  constructor(props: Props) {
    super(props)
    const { collection, defaultActiveIdx } = props

    this.state = {
      activedObj: collection[defaultActiveIdx || 0],
      hoverObj: false
    }
  }

  UNSAFE_componentWillReceiveProps(nextP: Props) {
    if (!isEqual(this.props, nextP)) {
      const { collection, defaultActiveIdx } = nextP
      this.setState({
        activedObj: collection[defaultActiveIdx || 0],
        hoverObj: false
      })
    }
  }

  // shouldComponentUpdate(nextProps: any) {
  //   return !isEqual(this.props, nextProps);
  // }

  chartData = canvas => {
    const { collection } = this.props
    return {
      labels: collection.map(e => e.status),
      datasets: [
        {
          data: collection.map(e => e.data),
          backgroundColor: collection.map(e => e.color),
          hoverBorderWidth: 6,
          borderColor: collection.map(e => e.color)
        }
      ]
    }
  }

  handleClick = e => {
    const { onClick } = this.props
    if (e.length) {
      onClick(e[0]._model)
    }
  }

  chartOptions = () => ({
    plugins: {
      bill: false,
      datalabels: {
        color: 'transparent',
        anchor: 'end',
        align: 'start',
        font: {
          size: '10'
        }
      },
      doughnut: {
        idx: this.props.defaultActiveIdx
      }
    },
    tooltips: {
      intersect: false,
      enabled: false
    },
    legend: {
      display: false
    },
    onHover: (e, targets) => {
      const { collection } = this.props
      this.setState({
        hoverObj: targets.length
      })
      if (targets.length) {
        this.lastChartEle = targets[0]
        const obj = targets[0]._model
        const activedObj = collection.find(c => c.status === obj.label)
        if (activedObj) {
          this.setState({
            activedObj
          })
        }
      } else if (this.lastChartEle) {
        const chart = this.lastChartEle._chart
        if (!chart.active.length) {
          chart.active = [this.lastChartEle]
          chart.update()
        }
      }
    },
    aspectRatio: 1,
    rotation: 0.2 * Math.PI,
    maintainAspectRatio: false,
    cutoutPercentage: 85
  })

  render() {
    const { activedObj, hoverObj } = this.state
    const { collection } = this.props
    let totalCount = 0
    if (collection.length) {
      totalCount = collection
        .map(e => e.data)
        .reduce((total, num) => {
          return total + num
        })
    }

    return (
      <div
        className='dou-chart-wrap'
        style={{
          cursor: hoverObj ? 'pointer' : ''
        }}
      >
        <Doughnut data={this.chartData} getElementAtEvent={this.handleClick} options={this.chartOptions()} />
        {activedObj && (
          <div className='dou-chart-info'>
            <div className='dou-chart-count'>
              <span className='num'>{activedObj.data}</span>/{totalCount}
            </div>
            <div className='dou-chart-status'>{activedObj.label}</div>
          </div>
        )}
      </div>
    )
  }
}

export default PieChart
