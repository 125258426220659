import * as React from 'react';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';
import './Button.css';

interface Props {
  customType: 'light' | 'dark' | 'danger';
  radius?: 'rect' | 'round';
  block?: boolean;
}

const CustomButton: React.SFC<Props & ButtonProps> = React.memo(
    ({ customType, className, radius = 'round', block = false, ...other }) => (
        <Button
            type='primary'
            className={classNames(
                className,
                'custom-button',
                `custom-button--${radius}`,
                block ? 'custom-button--block' : '',
                `custom-button--${customType}`
            )}
            ghost={customType === 'light'}
            {...other as any}
        />
    )
);

export default CustomButton;
