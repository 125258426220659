import { changeModal, ModalChangeAction } from 'actions/uiState';
import {
  DatasourceDetailObj, DatasourceObj, requestDatasourceDetail,
  RequestDatasourceDetailAction
} from 'actions/V3/datasources';
import {
  requestIndicator,
  requestIndicatorDataSource,
  RequestIndicatorDataSourceAction, REQUEST_INDICATOR_DATA_SOURCE_V3, REQUEST_INDICATOR_V3
} from 'actions/V3/indicators';
import { Pagination, Switch } from 'antd';
import Button from 'components/Custom/Button';
import CustomInput from 'components/Custom/Input';
import Table from 'components/Custom/Table';
import IconEdit from 'components/Editable';
import NoData from 'components/NoData';
import SearchBar from 'components/SearchBar';
import { t } from 'i18next';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import cache from 'sagas/cache';
import { apiV3 } from 'utils/api';
import './indicator.css';

export type ButtonAction = () => void;
type State = Readonly<{
  selected?: number;
  names: (string | undefined)[];
  assigned: boolean[];
}>;
type AssignTableStatePops = {
  dataSource: DatasourceObj[];
  indicatorQuery?: {};
  query?: {};
  meta?: Meta;
  detail?: DatasourceDetailObj;
};
type P = AssignTableStatePops &
  DispatchProp<
    | RequestDatasourceDetailAction
    | RequestIndicatorDataSourceAction
    | ModalChangeAction
  > &
  RouteComponentProps<{ id: string }>;
class AssignTableClass extends React.PureComponent<P, State> {
  state: State = {
      names: [],
      assigned: []
  };

  columns = [
      {
          title: t('Register.INDICATOR'),
          dataIndex: 'indicator',
          width: '250px',
          key: 'indicator'
      },
      {
          title: t('Register.DATACHANNEL'),
          dataIndex: 'channel',
          key: 'channel'
      },
      {
          title: t('ASSIGN'),
          dataIndex: 'assign',
          key: 'assign'
      }
  ];

  componentDidMount() {
      this.fetchDataSource();
  }

  fetchDataSource = (query = {}) => {
      const {
          match: {
              params: { id }
          },
          dispatch
      } = this.props;
      dispatch(
          requestIndicatorDataSource({
              ...query,
              workspace_id: +id,
              size: 5,
              show: false
          })
      );
  };

  search = (text: string): void => {
      const name = text.trim();
      this.fetchDataSource({ name });
  };

  selectPage = page => {
      const { query } = this.props;
      this.fetchDataSource({
          ...query,
          page
      });
  };

  selectDataSource = id => {
      const { dispatch } = this.props;
      this.setState({ selected: id });
      dispatch(changeModal('AssignTable'));
      dispatch(requestDatasourceDetail({ id: id, channel: true }));
  };

  close = () => {
      const { dispatch } = this.props;
      dispatch(changeModal(''));
  };

  updateName = (index, name) => {
      const { names } = this.state;
      const clone = names.slice();
      clone[index] = name;
      this.setState({ names: clone });
  };

  updateChecked = (index, checked) => {
      const { assigned } = this.state;
      const clone = assigned.slice();
      clone[index] = checked;
      this.setState({ assigned: clone });
  };

  update = async () => {
      const { assigned, names, selected } = this.state;
      cache.cleanCache(REQUEST_INDICATOR_DATA_SOURCE_V3);
      cache.cleanCache(REQUEST_INDICATOR_V3);
      if (assigned.every(v => !v)) {
          return this.close();
      }

      const channel = this.props.detail!.channels;
      const indicators = assigned
          .map(
              (v, i) =>
                  v && {
                      data_channel: channel![i].channel,
                      name: names[i] || ''
                  }
          )
          .filter(v => v);

      await apiV3('/indicators', 'post', {
          indicator: {
              data_source_id: selected,
              indicators
          }
      });
      requestIndicator(this.props.indicatorQuery as any);
      this.close();
  };

  createTableData = () => {
      const { detail } = this.props;
      const { names, assigned } = this.state;
      if (!detail) {
          return [];
      }
      const data = (detail.channels || []).map((v, index) => ({
          key: `table-${index}`,
          indicator: (
              <div className='indicator-tr'>
                  <IconEdit
                      iconPosition='end'
                      render={(state, disable) => (
                          <CustomInput
                              className='indicator-name'
                              disabled={!state}
                              onChange={e => this.updateName(index, e.target.value)}
                              onPressEnter={disable}
                              onBlur={disable}
                              value={
                                  names[index] !== undefined
                                      ? names[index]
                                      : v.name || v.channel_name
                              }
                              type='none'
                          />
                      )}
                  />
              </div>
          ),
          channel: (
              <div className='indicator-tr'>
                  <span className='register-table-type'>
                      {`${v.channel_name}${v.unit ? ' / ' + v.unit : ''}`}
                  </span>
              </div>
          ),
          assign: (
              <div className='indicator-tr'>
                  <Switch
                      checked={
                          assigned[index] === undefined ? !!v.indicator : assigned[index]
                      }
                      onChange={checked => this.updateChecked(index, checked)}
                      disabled={!!v.indicator}
                  />
              </div>
          ),
          assigned: !!v.indicator || assigned[index]
      }));

      return data;
  };

  renderHighlight = text => {
      const { query } = this.props;
      return query && (query as any).name ? (
          <Highlighter
              searchWords={[(query as any).name]}
              autoEscape={true}
              textToHighlight={text}
          />
      ) : (
          text
      );
  };

  renderDataChannel = () => {
      const tableData = this.createTableData();
      const datasource = this.props.dataSource.find(
          v => v.id === this.state.selected
      );

      return (
          <>
              <h3 className='register-title'>{t('Assign Indicators')}</h3>
              <div className='register-body' style={{ marginTop: '1rem' }}>
                  <p
                      className='register-message small_text'
                      style={{ marginBottom: '1rem' }}
                  >
                      {t('locationOverview.By default')}
                  </p>
                  <p
                      className='register-message small_text'
                      style={{ marginBottom: '1rem' }}
                  >
            Data Source UUID: <b>{datasource!.identifier}</b>
                  </p>
                  <Table
                      type='light'
                      className='register-table'
                      pagination={{ hideOnSinglePage: true }}
                      dataSource={tableData}
                      columns={this.columns}
                  />

                  <p className='indicator-message small_text'>
                      {t('locationOverview.data channels to be assigned to indicators', {
                          assigned: tableData.filter(v => v.assigned).length,
                          all: tableData.length
                      })}
                  </p>
                  <div className='indicator-button'>
                      <Button customType='light' size='small' onClick={this.close}>
                          {t('Cancel')}
                      </Button>
            &nbsp;&nbsp;
                      <Button customType='dark' size='small' onClick={this.update}>
                          {t('Finish')}
                      </Button>
                  </div>
              </div>
          </>
      );
  };

  renderDataSource = () => {
      const { dataSource, meta } = this.props;
      return !dataSource.length && meta ? (
          <NoData tip=' ' />
      ) : (
          <>
              <div className='colletion_modal-data_source-row is-title'>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--uuid'>
                      {t('Name')}
                  </div>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--identifier'>
            UUID
                  </div>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--brand '>
                      {t('datasource.Brand')}
                  </div>
                  <div className='colletion_modal-data_source-field colletion_modal-data_source-field--unassign'>
                      {t('Status')}
                  </div>
              </div>
              {dataSource.map((v, i) => (
                  <div
                      className='colletion_modal-data_source-row'
                      key={`dataSourceList-${i}`}
                      onClick={() => this.selectDataSource(v.id)}
                  >
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--uuid'>
                          {this.renderHighlight(v.name)}
                      </div>
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--identifier grey_text'>
                          {this.renderHighlight(v.identifier)}
                      </div>
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--brand grey_text'>
                          {v.brand}
                      </div>
                      <div className='colletion_modal-data_source-field colletion_modal-data_source-field--unassign accent_text'>{`${v.unassigned_count} channels unassigned`}</div>
                  </div>
              ))}
          </>
      );
  };

  renderSelectDataSource = () => {
      const { meta } = this.props;
      return (
          <>
              <h3 className='register-title'>{t('Assign Indicators')}</h3>
              <p className='small_text grey_text'>
                  {t('Register.Select the data source you would like to assign from')}
              </p>
              <div className='indicator-search'>
                  <SearchBar
                      onSearch={this.search}
                      className='colletion_modal-search'
                      placeholder={t('Register.Search by data source name or UUID')}
                      iconStyle={{
                          marginLeft: 0
                      }}
                  />
                  {this.renderDataSource()}
                  {meta && (
                      <Pagination
                          hideOnSinglePage={true}
                          onChange={this.selectPage}
                          size='small'
                          style={{ padding: '10px 0', textAlign: 'center' }}
                          current={meta.current_page}
                          defaultPageSize={5}
                          total={meta.total_count}
                      />
                  )}
              </div>
          </>
      );
  };

  render() {
      const { selected } = this.state;
      return selected ? this.renderDataChannel() : this.renderSelectDataSource();
  }
}

const mapState: MapState<AssignTableStatePops> = ({
    V3: { indicators, datasources }
}) => ({
    dataSource: indicators.dataSource,
    query: indicators.dataSourceQuery,
    meta: indicators.dataSourceMeta,
    indicatorQuery: indicators.query,
    detail: datasources.detail
});

export const AssignTable = withRouter(connect(mapState)(AssignTableClass));
