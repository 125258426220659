import * as React from 'react';
import { Select } from 'antd';
import { t } from 'i18next';
import './index.css';

const Option = Select.Option;
type ButtonAction = (v: number) => void;

type Props = {
    collectionsList: {
    name: string;
    code: string;
    id: number;
    stale: boolean;
  }[];
  defaultValue: string;
  handleChange: ButtonAction;
};

const CollectionSelector: React.FC<Props> = React.memo(props => {
    const { collectionsList, defaultValue, handleChange } = props;

    const onChange = value => {
        handleChange(value);
    };

    if (collectionsList.length === 1) {
        return (
            <div className='collection-selector ant-select ant-select-enabled'>
                <div
                    className='ant-select-selection
          ant-select-selection--single'
                >
                    <div className='ant-select-selection__rendered'>
                        <div className='ant-select-selection-selected-value'>
                            {collectionsList[0].stale && (
                                <span className='collection-selector--stale'>{t('STALE')}</span>
                            )}
                            {collectionsList[0].name || t('Location Average')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='collection-selector-wrap'>
            <Select
                value={defaultValue}
                defaultActiveFirstOption={false}
                dropdownClassName='collection-selector--dropdown'
                dropdownMatchSelectWidth={true}
                className='collection-selector'
                onChange={onChange}
                getPopupContainer={trigger => trigger!.parentElement!}
            >
                {collectionsList.map((col, i) => (
                    <Option key={i} value={col.id.toString()}>
                        {col.stale && (
                            <span className='collection-selector--stale'>{t('STALE')}</span>
                        )}
                        {col.name || t('Location Average')}
                    </Option>
                ))}
            </Select>
        </div>
    );
});

export default CollectionSelector;
