import { Action, ActionCreator } from 'redux'
import { LocationItem } from './locationList'
export const REQUEST_FOLLOWING_V3 = 'REQUEST_FOLLOWING_V3'
export const GET_FOLLOWING_V3 = 'GET_FOLLOWING_V3'
export type FollowItem = LocationItem

export interface RequestFollowsAction extends Action<typeof REQUEST_FOLLOWING_V3> {
  args: any;
}

export interface GetFollowsAction extends Action<typeof GET_FOLLOWING_V3> {
  data: FollowItem[];
  meta: Meta;
  query: any;
}

export const requestFollowsV3: ActionCreator<RequestFollowsAction> = args => ({
  type: REQUEST_FOLLOWING_V3,
  args
})

export const getFollowsV3: ActionCreator<GetFollowsAction> = (data, meta, query) => ({
  data,
  meta,
  query,
  type: GET_FOLLOWING_V3
})
