import * as ModalElement from 'containers/IndicatorDetail/Modal'
import * as React from 'react'
import Button from 'components/Custom/Button'
import moment from 'moment'
import TextRow from 'containers/IndicatorDetail/TextRow'
import { Guard } from 'utils/guard'
import { IndicatorItem } from 'actions/V3/indicators'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Modal, Button as AntButton } from 'antd'
import { NotificationLimit } from 'actions/V3/notificationConfig'
import { t } from 'i18next'
import { apiV3 } from 'utils/api'

export type Line = {
  label: string,
  child: React.ReactElement<{}> | string | number,
  edit?: React.ReactElement<{}>,
  top?: number
}

type State = Readonly<{
  modal: 'collection' | 'notification' | 'dataSource' | 'delete' | ''
}>

type Props = {
  indicator: IndicatorItem,
  refresh: () => void,
  limit: NotificationLimit[]
}

type P = Props & RouteComponentProps<{ id: string, indicator_id: string }>
@Guard
class Form extends React.PureComponent<P, State> {
  state: State

  constructor(props: P) {
    super(props)
    const {
      location: { state }
    } = props
    const modal = state && state.readingLimit ? 'notification' : ''

    this.state = {
      modal
    }
  }

  GuardHandle_indicatorUpdate = (rule?: GuardRule) => rule && rule.enable
  GuardHandle_indicatorNotification = () => (
    <AntButton type='primary' ghost size='small' onClick={() => this.setState({ modal: 'notification' })}>
      {t('indicator.Configure')}
    </AntButton>
  )

  GuardTrigger_indicatorDeleteButton = () => (
    <Button onClick={() => this.setState({ modal: 'delete' })} customType='danger'>
      {t('indicator.Delete Indicator')}
    </Button>
  )

  confirmUnassign = indicator => {
    Modal.confirm({
      title: t('indicator.Unassign datasource', {
        identifier: indicator.data_source.identifier
      }),
      content: (
        <div className='datasource_modal-body'>
          <p>{t('indicator.By unassigning the data source')}</p>
        </div>
      ),
      okType: 'danger',
      okText: t('datasource.unassign it'),
      onOk: async () => {
        await apiV3(`/data_sources/${indicator.data_source.id}/unassign`, 'post', {
          indicator_id: indicator.id
        })
        this.close()
      }
    })
  }

  form = (): Line[] => {
    const {
      indicator,
      limit,
      match: {
        params: { id }
      }
    } = this.props

    const editable = this.GuardHandle_indicatorUpdate()

    return [
      {
        label: 'UUID',
        child: indicator.id,
        top: 24
      },
      {
        label: t('indicator.Assigned Data Channel'),
        child: (
          <div className='collection-datasource'>
            {indicator.data_source ? (
              <Link
                className='collection-datasource-name'
                to={`/workspaces/${id}/datasources/${indicator.data_source.id}`}
              >
                {indicator.data_source.identifier}
              </Link>
            ) : (
              <span className='collection-datasource-name' />
            )}
            <span>
              <AntButton
                disabled={!editable}
                type='primary'
                ghost
                size='small'
                onClick={() => this.setState({ modal: 'dataSource' })}
              >
                {indicator.data_source ? t('indicator.Re-assign') : t('indicator.Configure')}
              </AntButton>
              {indicator.data_source && (
                <AntButton
                  disabled={!editable}
                  type='danger'
                  ghost
                  size='small'
                  onClick={() => this.confirmUnassign(indicator)}
                >
                  {t('indicator.unassign it')}
                </AntButton>
              )}
            </span>
          </div>
        )
      },
      {
        label: t('indicator.Parameter'),
        child: indicator.data_channel_name || ''
      },
      {
        label: t('indicator.Reading Limit Notification'),
        child: (
          <div className='collection-datasource'>
            <span className='collection-datasource-name'>
              {t('indicator.limits configured', { limit: limit.length })}
            </span>
            <span>{this.GuardHandle_indicatorNotification()}</span>
          </div>
        )
      },
      {
        label: t('Collections'),
        child: (
          <>
            <div className='collection-date grey_text'>
              {indicator.collections.map((c, i) => (
                <div key={`collection-${i}`}>
                  <Link to={`/workspaces/${id}/collections/${c.id}`}>{c.name}</Link>
                </div>
              ))}
            </div>
            {editable && (
              <div className='collection-add_text accent_text' onClick={() => this.setState({ modal: 'collection' })}>
                <i className='material-icons'>add</i>
                {t('indicator.Add to existing Collection')}
              </div>
            )}
          </>
        ),
        top: 30
      },
      {
        label: t('indicator.Date Created'),
        child: (
          <span className='collection-date grey_text'>
            {indicator.created_at && moment(indicator.created_at).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        ),
        top: 40
      },
      {
        label: t('indicator.Last Modified'),
        child: (
          <span className='collection-date grey_text'>
            {indicator.updated_at && moment(indicator.updated_at).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        ),
        top: 7
      },
      {
        label: t('indicator.First Reading'),
        child: (
          <span className='collection-date grey_text'>
            {indicator.first_reading && moment(indicator.first_reading).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        )
      },
      {
        label: t('indicator.Latest Reading'),
        child: (
          <span className='collection-date grey_text'>
            {indicator.latest_reading && moment(indicator.latest_reading).format('YYYY-MM-DD HH:mm:ss')}
          </span>
        ),
        top: 7
      }
    ]
  }

  close = () => {
    this.setState({ modal: '' })
    this.props.refresh()
  }

  goBack = () => {
    const { history } = this.props
    history.goBack()
  }

  renderModal = () => {
    const { modal } = this.state
    const {
      match: {
        params: { id, indicator_id }
      },
      limit
    } = this.props

    switch (modal) {
      case 'dataSource':
        return <ModalElement.DataSource indicatorID={+indicator_id} close={this.close} />
      case 'delete':
        return (
          <ModalElement.Delete
            close={() => this.setState({ modal: '' })}
            goBack={this.goBack}
            indicatorID={+indicator_id}
          />
        )
      case 'notification':
        return (
          <ModalElement.Notification
            limit={limit}
            indicatorID={+indicator_id}
            refresh={this.props.refresh}
            cancel={() => this.setState({ modal: '' })}
          />
        )
      case 'collection':
        return <ModalElement.Collection id={+id} indicatorID={+indicator_id} close={this.close} />
      default:
        return null
    }
  }

  render() {
    const { modal } = this.state
    return (
      <>
        {this.form().map((v, i) => (
          <TextRow label={v.label} key={`collection-form-${i}`} top={v.top}>
            {v.child}
          </TextRow>
        ))}

        <div className='collection-detail-row'>
          <div className='collection-del_button'>{this.GuardTrigger_indicatorDeleteButton()}</div>
        </div>

        <Modal
          visible={!!modal}
          centered={true}
          onCancel={() => this.setState({ modal: '' })}
          bodyStyle={{ padding: 0 }}
          footer={null}
          closable={false}
          width={730}
          wrapClassName='overview-modal'
        >
          {this.renderModal()}
        </Modal>
      </>
    )
  }
}

export default withRouter(Form)
