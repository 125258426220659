import React from 'react'
import returnStatus from './returnStatus'
import './RenderTemplateJPMC.css'
import { t } from 'i18next'
import moment from 'moment'
import { Timer } from '../../utils/enums'

export default function RenderTemplateJPMC({ kiosk }) {
    const [time, setTime] = React.useState<string>()
    const font_color = 'white'
    const name = 'Shanghai Tower'

    function timeFunction() {
        setInterval(() => {
            setTime(moment().format('HH:mm:ss'))
        }, Timer.SECOND)
    }
    React.useEffect(timeFunction, [])


  interface Indicators {
    channel: string
    data_channel: string
    default_unit: string
    formula: null
    level: string
    name: string
    precision: number
    type: string
    unit: null
    use_aqi: false
    value: number
  }

  const temperature= 3
  const humidity= 4
  const pm2p5= 0
  const co2= 1
  const tvoc= 2

  const indicators:Indicators = kiosk.average.readings

  return (
      <div className='JPMC_background'>
          <p className='JPMC_title'>{name}</p>
          <div className='JPMC_container_floor' style={{ color: font_color }}>
        L49
          </div>
          <div className='JPMC_container_top'>
              <section className='JPMC_container_dates'>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_small' style={{ color: font_color }}>
                          {time}
                      </p>
                      <p className='JPMC_dates_small' style={{ color: font_color }}>
                          {moment().format('dddd')}
                      </p>
                  </div>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_small' style={{ color: font_color }}>
                          {moment().format('YYYY-MM-DD')}
                      </p>
                  </div>
              </section>
              <section className='JPMC_container_dates'>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_small' style={{ color: font_color }}>
                          {`${indicators[temperature].value} 
              ${indicators[temperature].default_unit}`}
                      </p>
                  </div>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_small' style={{ color: font_color }}>
                          {`${Math.floor(indicators[humidity].value)} 
              ${indicators[humidity].default_unit}`}
                      </p>
                  </div>
              </section>
          </div>
          <aside className='JPMC_container_officeFloorPlan' />
          <div className='JPMC_container_bottom'>
              <p className='JPMC_dates_normal_bottom' style={{ color: font_color }}>
                  {t('JPMC.Indoor Air Quality')}
              </p>
              <p className='JPMC_dates_small_bottom' style={{ color: font_color }}>
                  {t('JPMC.Average Readings')}
              </p>
              <section className='JPMC_container_dates average_indicators'>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_normal' style={{ color: font_color }}>
                          {indicators[pm2p5].name}
                      </p>
                      <p className='JPMC_dates_normal' style={{ color: font_color }}>
                          {returnStatus(indicators[pm2p5])}
                      </p>
                  </div>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_normal' style={{ color: font_color }}>
                          {t('JPMC.CO')}
                          <span className='JPMC_exponent'>{t('JPMC.TWO')}</span>
                      </p>
                      <p className='JPMC_dates_normal' style={{ color: font_color }}>
                          {returnStatus(indicators[co2])}
                      </p>
                  </div>
                  <div className='JPMC_dates_indicators_container'>
                      <p className='JPMC_dates_normal' style={{ color: font_color }}>
                          {t('kiosk.tvoc')}
                      </p>
                      <p className='JPMC_dates_normal' style={{ color: font_color }}>
                          {returnStatus(indicators[tvoc])}
                      </p>
                  </div>
              </section>
          </div>
      </div>
  )
}
