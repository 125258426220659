import * as React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import Select, { Option } from 'components/Custom/Select';
import { Location, RequestSelectAction } from 'actions/V3/locationSelect';
import { DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import './index.css';

type StateProps = {
  locations: Location[];
};

type P = StateProps &
  DispatchProp<RequestSelectAction> &
  RouteComponentProps<{ id: string }>;

class LocationNeed extends React.Component<P> {
  onChange = id => {
      const {
          history,
          location: { pathname }
      } = this.props;

      history.replace(`${pathname}/${id}`);
  }

  render() {
      const { locations } = this.props;
      return (
          <div className='location-need-container'>
              <div className='location-need-tip'>{t('Location Need')}</div>
              <Select
                  className='location-need-selector'
                  dropdownMatchSelectWidth={true}
                  showSearch={true}
                  autoFocus={true}
                  underline={true}
                  size='large'
                  placeholder={t('Location')}
                  onSelect={this.onChange}
                  filterOption={(input, option) =>
                      (option.props.children as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  optionFilterProp='children'
              >
                  {locations.map((loc, i) => (
                      <Option key={i} value={loc.id}>
                          {loc.name}
                      </Option>
                  ))}
              </Select>
          </div>
      );
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { locationSelect } }) => ({
    locations: locationSelect.data
});

export default withRouter(connect(mapStateToProps)(LocationNeed));
