import { put, call } from 'redux-saga/effects'

import { REQUEST_DOWNLOADS, getDownloads } from 'actions/V2/downloads'
import { watchRequestNoCancel } from 'sagas/helper'

import api from 'utils/api'

function* request(args: { type?: string, station_id?: string, location_id?: string }) {
  const { data, meta } = yield call(api, '/downloads', 'get', args)

  yield put(getDownloads(data, meta.total_count))
  return data
}

export default watchRequestNoCancel(REQUEST_DOWNLOADS, request)
