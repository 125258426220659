import { put, call } from 'redux-saga/effects'

import { REQUEST_DOWNLOADS_V3, getDownloads } from 'actions/V3/downloads'
import { watchRequestNoCancel } from 'sagas/helper'

import { apiV3 } from 'utils/api'

type Args = {
  size?: number,
  page?: number
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/downloads', 'get', { ...args })

  yield put(getDownloads(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_DOWNLOADS_V3, request)
