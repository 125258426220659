import AddLocationIcon from '-!svg-react-loader!images/svg/AddLocations.svg';
import FilterIcon from '-!svg-react-loader!images/svg/filter.svg';
import SortIcon from '-!svg-react-loader!images/svg/sort.svg';
import { changeModal, updateSiteState } from 'actions/uiState';
import { requestDictionary } from 'actions/V2/dictionary';
import { requestFollowsV3 } from 'actions/V3/following';
import { requestLocationsV3 } from 'actions/V3/locationList';
import {
  Badge,
  Col,
  Dropdown,
  Icon,
  Menu,
  Pagination,
  Popover,
  Row,
  Select,
  Tooltip
} from 'antd';
import Card from 'components/LocationCard';
import NoData from 'components/NoData';
import SearchBar from 'components/SearchBar';
import { P, Props, StateProps } from 'containers/LocationList/type';
import { t } from 'i18next';
import {
  isEmpty,
  isEqual,
  mapValues,
  pick,
  pickBy,
  pull,
  transform,
  uniq,
  values,
  xor
} from 'lodash';
import qs, { ParsedQs } from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { LEVEL_DICT } from 'utils/constant';
import { Guard } from 'utils/guard';
import { queryMerge } from 'utils/query';
import './index.css';


const keysArray = {
  status: ['online', 'offline', 'partial-offline', 'empty'],
  performance: [
    'good',
    'moderate',
    'sensitive',
    'hazardous',
    'unhealthy',
    'very-unhealthy',
    'no-performance'
  ],
  parameter: ['air', 'electricity'],
  type: ['indoor', 'outdoor'],
  sort_value: ['asc', 'desc']
};
@Guard
class LocationList extends React.PureComponent<P, {}> {
  static defaultProps = {
    type: 'workspace' as 'workspace' | 'all' | 'public',
    noData: <NoData />
  };

  filterTranslation = LEVEL_DICT();

  componentDidMount() {
    const { dispatch } = this.props;
    this.fetchLocation();
    dispatch(requestDictionary({ name: 'cities' }));
  }

  UNSAFE_componentWillUpdate(next: P) {
    if (!isEqual(this.props.location, next.location)) {
      this.fetchLocation(next);
    }
  }

  fetchLocation = (props = this.props, q = {}) => {
    const {
      dispatch,
      type,
      match: {
        params: { id, city_id }
      }
    } = props;

    const query = this.getQueryFilter(props);
    if (!query) {
      return;
    }

    if (type === 'dashboard') {
      return dispatch(requestFollowsV3({ ...q, ...query }));
    }

    if (type === 'workspace' && id) {
      query.workspace_id = id;
    }

    if (type === 'public') {
      query.recommend = true;

      if (city_id) {
        query.city_id = city_id;
      }
    }

    if (query.sort_value) {
      query.sort_key = 'name';
    }
    return dispatch(requestLocationsV3({ ...q, ...query }));
  };

  getQueryFilter = (props = this.props) => {
    const {
      location: { search, pathname },
      history
    } = props;
    const q = qs.parse(search.slice(1));

    const searchQuery: any = pick(q, ['page', 'name']);

    let filterQuery: any = mapValues(
      pick(q, [
        'status',
        'performance',
        'parameter',
        'city_id',
        'type',
        'sort_value'
      ]),
      (v, k) =>
        typeof v === 'string' && keysArray[k]
          ? v
            .split(',')
            .filter(value => keysArray[k].includes(value))
            .toString()
          : v
    );


    filterQuery = pickBy(filterQuery, Boolean);

    const result = { ...searchQuery, ...filterQuery };
    const query = qs.stringify(result);
    if (query !== search.slice(1).replace(/lang=\w+&?/, '')) {
      history.replace({
        pathname,
        search: `?${query}`
      });

      return false;
    }

    return result;
  };

  selectPage = page => {
    const {
      location: { search, pathname },
      history
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        page: page
      },
      delKeys: page === 1 ? ['page'] : []
    });
    history.push({
      pathname,
      search: query
    });
  };

  search = (text: string) => {
    const name = text.trim();

    const {
      location: { search, pathname },
      history
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        name
      },
      delKeys: name ? ['page', 'size'] : ['page', 'size', 'name']
    });
    history.push({
      pathname,
      search: query
    });
  };

  changeOrder = ({ key }) => {
    const {
      location: { search, pathname },
      history
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        sort_value: key
      },
      delKeys: key === 'asc' ? ['sort_value'] : []
    });
    history.push({
      pathname,
      search: query
    });
  };

  Guard_addLocation = () => {
    const { dispatch } = this.props;
    dispatch(changeModal('AddLocation'));
  };

  GuardHandle_addLocation = (rule?: GuardRule, message?: string) => {
    const disable = rule && !rule.enable;

    return disable ? (
      <Tooltip title={message} className='hidden-mobile'>
        <AddLocationIcon className='location-header-icons icon_disabled' />
      </Tooltip>
    ) : (
      <AddLocationIcon
        className='location-header-icons hidden-mobile'
        onClick={this.Guard_addLocation}
      />
    );
  };

  getFilterValue = () => {
    const filter = pick(this.getQueryFilter(), [
      'performance',
      'status',
      'parameter',
      'type'
    ]);
    return values(filter).reduce((p, n) => p.concat(n.split(',')), []);
  };

  selectCity = data => {
    const {
      location: { search, pathname },
      history
    } = this.props;
    const { city_id } = this.getQueryFilter();
    const city = city_id ? city_id.split(',') : [];
    const cities = xor(city, [data + '']);

    const query = queryMerge({
      search,
      query: {
        city_id: cities.toString()
      },
      delKeys: cities.length ? ['page'] : ['city_id', 'page']
    });
    history.push({
      pathname,
      search: query
    });
  };

  filterChange = (key, type) => {
    const q = this.getQueryFilter();
    const old = q[type] ? q[type].split(',') : [];
    const copy = xor(old, [key]);

    const {
      location: { search, pathname },
      history
    } = this.props;
    const query = queryMerge({
      search,
      query: {
        [type]: copy.toString()
      },
      delKeys: copy.length ? ['page'] : [type, 'page']
    });

    history.push({
      pathname,
      search: query
    });
  };

  delFilter = text => {
    const {
      location: { search, pathname },
      history
    } = this.props;
    const q = qs.parse(search.slice(1));

    const obj = transform(
      q,
      (result: any, value: string | string[] | ParsedQs | ParsedQs[] | undefined, key: string) => {
        if (typeof value === 'string') {
          let arr = value.split(',');
          arr = pull(arr, text);
          if (arr.length) {
            result[key] = arr.toString();
          }
        }
      },
      {}
    );

    history.push({
      pathname,
      search: `?${qs.stringify(obj)}`
    });
  };


  renderMenu = () => {
    const city = this.props.city;
    const filterSelected = this.getFilterValue();

    const filterOptions = [
      {
        title: t('Performance'),
        type: 'performance',
        childrens: keysArray.performance
      },
      {
        title: t('Status'),
        type: 'status',

        childrens: keysArray.status
      },
      {
        title: t('Parameters'),
        type: 'parameter',
        childrens: keysArray.parameter
      },
      {
        title: t('Location'),
        type: 'type',
        childrens: keysArray.type
      }
    ];

    const defaultTitles = uniq(
      filterOptions.filter(v =>
        v.childrens.some(c => filterSelected.includes(c))
      )
    ).map(v => v.title);
    return (
      <div className='location-filter'>
        <Menu
          mode='inline'
          multiple={true}
          defaultOpenKeys={defaultTitles}
          selectedKeys={filterSelected}
        >
          {filterOptions.map(group => (
            <Menu.SubMenu key={group.title} title={group.title}>
              {group.childrens.map((item, i) => (
                <Menu.Item
                  key={item}
                  onClick={() => this.filterChange(item, group.type)}
                >
                  <span className='grey_text small_text menu_text'>
                    {this.filterTranslation[item]}
                  </span>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
          <Menu.SubMenu key='city' title={t('City')}>
            <Menu.Item className='location-filter-search_menu'>
              <Select
                mode='multiple'
                showSearch={true}
                value={[]}
                onSelect={this.selectCity}
                className='location-filter-search'
                placeholder={t('filter.selectCity')}
                optionFilterProp='children'
                dropdownClassName='location-filter-dropdown'
                filterOption={(input, option) =>
                  (option.props.children as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {uniq(city).map((item: any) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    );
  };

  renderSortMenu = value => (
    <Menu selectedKeys={value}>
      <Menu.Item key='asc' onClick={this.changeOrder}>
        <span className='grey_text small_text menu_text'>A-Z</span>
      </Menu.Item>
      <Menu.Item key='desc' onClick={this.changeOrder}>
        <span className='grey_text small_text menu_text'>Z-A</span>
      </Menu.Item>
    </Menu>
  );

  push = (id, workspaceID): void => {
    const { history, type, dispatch } = this.props;

    if (type === 'public') {
      // const { href, origin } = window.location;
      dispatch(
        updateSiteState({
          originURL: ''
        })
      );
      history.push(`/${id}`);
    } else {
      history.push(`/workspaces/${workspaceID}/locations/${id}`);
    }
  };

  renderNodata = () => {
    const { type, meta, query, noData } = this.props;
    if (type === 'dashboard') {
      return (
        meta && (
          <NoData
            title={t('No Following Location')}
            tip={t('Please try to follow first')}
          />
        )
      );
    }
    return (
      meta &&
      (isEmpty(query) ? (
        noData
      ) : (
        <NoData title={t('No Location Found')} tip=' ' />
      ))
    );
  };

  render() {
    const { data, meta, city, type } = this.props;
    const query = this.getQueryFilter();
    const filterSelected = this.getFilterValue();
    const cites = (query.city_id ? query.city_id.split(',') : []).map(id =>
      city.find(v => v.id === +id)
    );

    return (
      <>
        <div className='location-header'>
          <SearchBar
            onSearch={this.search}
            defaultValue={query.name || ''}
            containerClassName='location-search'
            className='colletion_modal-search'
            placeholder={t('filter.searchLocationName')}
            iconStyle={{
              marginLeft: 0
            }}
          />
          {(type !== 'dashboard') && (
            <div className='location-header-filter hidden-mobile'>
              <Dropdown
                placement='bottomCenter'
                overlay={this.renderSortMenu(query.sort_value || 'asc')}
                trigger={['click']}
              >
                <div className='location-header-filter_item'>
                  <SortIcon />
                  <span className='small_text grey_text'>
                    {t('filter.sort')}
                  </span>
                </div>
              </Dropdown>

              <div className='location-header-filter_item location-header-filter_item--filter'>
                <Popover
                  overlayClassName='header-notification-overlay is-menu'
                  content={this.renderMenu()}
                  placement='bottom'
                  trigger='click'
                >
                  <FilterIcon />
                  <Badge
                    count={filterSelected.length + cites.length}
                    offset={[2, 10]}
                  >
                    <span className='small_text grey_text location-header-filter_text'>
                      {t('filter.Filter')}
                    </span>
                  </Badge>
                </Popover>
              </div>
            </div>
          )}
          {type === 'workspace' && this.GuardHandle_addLocation()}
        </div>
        <div className='location-filter_condition'>
          {filterSelected.map((item, index) => (
            <div
              className='location-filter_condition-item'
              key={`filter-item-${index}`}
            >
              <div
                className='location-filter_condition-close'
                onClick={() => this.delFilter(item)}
              >
                <Icon
                  type='close'
                  style={{
                    fontSize: 10,
                    color: '#9B9B9B',
                    lineHeight: '30px'
                  }}
                />
              </div>
              <div className='location-filter_condition-text'>
                {this.filterTranslation[item]}
              </div>
            </div>
          ))}
          {cites.map((item, index) => (
            <div
              className='location-filter_condition-item'
              key={`city-item-${index}`}
            >
              <div
                className='location-filter_condition-close'
                onClick={() => this.selectCity(item.id)}
              >
                <Icon
                  type='close'
                  style={{
                    fontSize: 10,
                    color: '#9B9B9B',
                    lineHeight: '30px'
                  }}
                />
              </div>
              <div className='location-filter_condition-text'>{item.name}</div>
            </div>
          ))}
        </div>
        {data.length ? (
          <Row gutter={24} className='location-list'>
            {data.map((item, index) => (
              <Col
                key={`location-card-${index}`}
                xs={{ span: 24 }}
                sm={{ span: 6 }}
                className='location-card'
              >
                <Card
                  data={Object.assign(item, { type })}
                  onClick={() => this.push(item.id, item.workspace_id)}
                />
              </Col>
            ))}
          </Row>
        ) : (
          this.renderNodata()
        )}
        {meta && (
          <Pagination
            hideOnSinglePage={true}
            showQuickJumper={true}
            onChange={this.selectPage}
            style={{ padding: '20px 0', textAlign: 'right' }}
            current={meta.current_page}
            defaultPageSize={20}
            showTotal={total => t('showTotal', { total })}
            total={meta.total_count}
          />
        )}
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps, Props> = (
  {
    V2: {
      dictionary: { cities }
    },
    V3: { locationList, follows }
  },
  { type }
) => {
  const list = type === 'dashboard' ? follows : locationList;
  return {
    ...list,
    city: cities
  };
};

export default withRouter(connect(mapStateToProps)(LocationList));
