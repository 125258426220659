import { GetAccountAction, GET_ACCOUNTS, Account } from 'actions/V2/accounts'

export type AccountsState = {
  data: Account[],
  count: number,
  meta?: Meta,
  query: any
}

export default function Accounts(
  state: AccountsState = {
    data: [],
    count: 0,
    query: {}
  },
  action: GetAccountAction
) {
  switch (action.type) {
    case GET_ACCOUNTS:
      return {
        data: action.accounts,
        meta: action.meta,
        count: action.query.name ? state.count : action.accounts.length,
        query: action.query
      }
    default:
      return state
  }
}
