import { Action, ActionCreator } from 'redux'
export const REQUEST_TABLEDATA = 'REQUEST_TABLEDATA'
export const GET_TABLEDATA = 'GET_TABLEDATA'

export type DataObj = {
  reading_time: string,
  created_at: string,
  pm2p5?: number,
  co2?: number,
  tvoc?: number,
  temperature?: number
}

export interface RequestTableDataAction extends Action<typeof REQUEST_TABLEDATA> {
  args: {
    location_id?: number
  };
}

interface GetTableDataAction extends Action<typeof GET_TABLEDATA> {
  tabledata: DataObj[];
  meta: Meta;
  query: {};
}

export type TableDataAction = GetTableDataAction

export const requestTableData: ActionCreator<RequestTableDataAction> = args => ({
  type: REQUEST_TABLEDATA,
  args
})

export const getTableData: ActionCreator<GetTableDataAction> = (tabledata, meta, query) => ({
  type: GET_TABLEDATA,
  tabledata,
  meta,
  query
})
