import { CertificationObj } from 'actions/V2/certifications';
import {
  requestDictionary,
  RequestDictionaryAction
} from 'actions/V2/dictionary';
import { Module } from 'actions/V3/report';
import {
  Checkbox,
  Col,
  Collapse,
  Divider,
  Icon,
  Input,
  message,
  Row
} from 'antd';
import Button from 'components/Custom/Button';
import CustomInput from 'components/Custom/Input';
import Select, { Option } from 'components/Custom/Select';
import Editable from 'components/Editable';
import ScrollAffix from 'components/ScrollAffix';
import { t } from 'i18next';
import { get, set } from 'lodash';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { apiV3 } from 'utils/api';
import Modal, { screen } from './Modal';
import './report.css';

type State = Readonly<{
  showModal: boolean;
  modules: Module[];
  mode: 'preview' | 'edit';
  name: string;
}>;

type StateProps = Readonly<{
  certifications: CertificationObj[];
  indicators: any;
}>;

type P = RouteComponentProps<{ id: string; template_id: string }> &
  StateProps &
  DispatchProp<RequestDictionaryAction>;

type StateKey =
  | keyof Module
  | 'configs.health_standard'
  | 'configs.enable_working_hours';

class CreateTemplate extends React.PureComponent<P, State> {
  state: State = {
    showModal: false,
    modules: [],
    name: t('Report.Report Template'),
    mode: 'edit'
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(requestDictionary({ name: 'indicators' }));
    this.fetchTemplate();
  }

  fetchTemplate = async () => {
    const {
      match: {
        params: { template_id }
      }
    } = this.props;
    if (template_id) {
      const {
        data: { name, modules }
      } = await apiV3<any>(`/report_templates/${template_id}`);
      this.setState({
        name,
        modules
      });
    }
  };

  newModule = (category: 'indicator' | 'comparison') => {
    const module: Module = {
      category,
      indicators: [],
      features: [],
      isCreated: true,
      name: '',
      configs: {
        health_standard: '',
        enable_working_hours: false
      }
    };
    const { modules } = this.state;
    this.setState({ modules: modules.concat([module]) });
  };

  updateState = (index: number, key: StateKey, value) => {
    const copyed = this.state.modules.slice();
    set(copyed[index], key, value);
    if (key === 'indicators' && !value.includes('pm2p5')) {
      copyed[index].features = copyed[index].features.filter(
        v => !v.includes('percent_reduction')
      );
    }
    this.setState({
      modules: copyed
    });
  };

  sortFeatures = (arr: string[]) => {
    const sortKeys = [
      'overall',
      'historical',
      'overall_percent_reduction',
      'historical_percent_reduction'
    ];
    return arr.sort((a, b) => sortKeys.indexOf(a) - sortKeys.indexOf(b));
  };

  getState = (index: number, key: StateKey) => {
    const { modules } = this.state;
    return modules[index] && (get(modules[index], key) as any);
  };

  delModule = index => {
    const { modules } = this.state;
    this.setState({ modules: modules.filter((v, i) => i !== index) });
  };

  validate = (): boolean => {
    const { modules } = this.state;
    if (!modules.length) {
      message.warn(t('Report.please add module'));
      return false;
    }

    const nameError = modules.some(v => !v.name);
    const indicatorError = modules.some(
      v => !v.indicators || !v.indicators.length
    );
    const featuresError = modules.some(
      v => v.category === 'indicator' && (!v.features || !v.features.length)
    );

    const compareIndicatorError = modules
      .map(m =>
        m.category === 'comparison'
          ? m.indicators && m.indicators.length === 2
          : true
      )
      .some(v => !v);

    if (nameError) {
      message.warn(t('Report.please input module name'));
      return false;
    }

    if (indicatorError) {
      message.warn(t('Report.please select module indicator'));
      return false;
    }

    if (featuresError) {
      message.warn(t('Report.need select feature'));
      return false;
    }

    if (compareIndicatorError) {
      message.warn(t('Report.Compare Module need select two indicators'));
      return false;
    }
    return true;
  };

  saveTemplate = async () => {
    const {
      history,
      match: {
        params: { id, template_id }
      }
    } = this.props;

    const { modules, name } = this.state;
    const validateResult = this.validate();
    if (!validateResult) {
      return;
    }

    const { data } = await apiV3<any>(
      `/report_templates${template_id ? '/' + template_id : ''}`,
      template_id ? 'patch' : 'post',
      {
        report_template: {
          workspace_id: id,
          name,
          modules
        }
      }
    );
    history.push(`/workspaces/${id}/reports/create`, data);
  };

  renderName = () => {
    const { name } = this.state;
    return (
      <div>
        <br />
        <Editable
          iconPosition='end'
          iconClass='collection-detail-name_icon'
          render={(edit, disableEdit) =>
            edit ? (
              <CustomInput
                disabled={!edit}
                onPressEnter={disableEdit}
                onBlur={disableEdit}
                className='collection-detail-name--input'
                value={name}
                onChange={e => this.setState({ name: e.target.value })}
                type='none'
              />
            ) : (
              <div className='edit-disabled-text'>{name}</div>
            )
          }
        />
      </div>
    );
  };

  renderCollapse = (index: number) => (
    <Collapse.Panel header={t('Advanced')} key='1'>
      <Row gutter={36}>
        <Col span={12}>
          <p className='report-template-label'>{t('Report.Filter by hours')}</p>
          <Select
            block={true}
            dropdownMatchSelectWidth={true}
            onChange={v =>
              this.updateState(index, 'configs.enable_working_hours', !!v)
            }
            value={this.getState(index, 'configs.enable_working_hours') ? 1 : 0}
          >
            <Option value={1}>{t('Report.Working Hours')}</Option>
            <Option value={0}>{t('Report.All Hours')}</Option>
          </Select>
        </Col>
        <Col span={12}>
          <p className='report-template-label '>
            {t('Report.Show health standards')}
          </p>
          <Select
            block={true}
            dropdownMatchSelectWidth={true}
            onChange={v =>
              this.updateState(index, 'configs.health_standard', v)
            }
            value={this.getState(index, 'configs.health_standard')}
          >
            <Option key={-1} value={''}>
              {t('None')}
            </Option>
            {this.props.certifications.map((item, i) => (
              <Option key={i} value={item.name}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Collapse.Panel>
  );

  renderIndicator = (index: number) => {
    const { indicators } = this.props;
    return (
      <div className='report-template-card' key={`indicator-module-${index}`}>
        <a
          className='material-icons report-template-close'
          onClick={() => this.delModule(index)}
        >
          close
        </a>

        <div className='report-template-collapse'>
          <Row gutter={36}>
            <Col span={12}>
              <p className='report-template-label'>{t('Report.module name')}</p>

              <Input
                value={this.getState(index, 'name')}
                onChange={e => this.updateState(index, 'name', e.target.value)}
              />
            </Col>
            <Col span={12}>
              <div className='report-template-label'>
                {t('Report.You can select indicator module')}
              </div>
              <Select
                block={true}
                dropdownMatchSelectWidth={true}
                value={this.getState(index, 'indicators')}
                onChange={v => this.updateState(index, 'indicators', [v])}
              >
                {Object.entries(indicators || {}).map(([value, label], i) => (
                  <Option value={value} key={`indicators-${i}`}>
                    {label as string}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Checkbox.Group
            className='report-template-check_group'
            value={this.getState(index, 'features')}
            onChange={v =>
              this.updateState(
                index,
                'features',
                this.sortFeatures(v as string[])
              )
            }
          >
            <Row type='flex' gutter={30}>
              <Col>
                <Checkbox value='overall'>{t('Report.overall')}</Checkbox>
                {/* <a>
                  <Icon type='question-circle' />
                </a> */}
              </Col>

              <Col>
                <Checkbox value='historical'>{t('Report.historical')}</Checkbox>
                {/* <a>
                  <Icon type='question-circle' />
                </a> */}
              </Col>
              <Col>
                <Checkbox
                  value='overall_percent_reduction'
                  disabled={
                    !this.getState(index, 'indicators').includes('pm2p5')
                  }
                >
                  {t('Report.overall_percent_reduction')}
                </Checkbox>
                {/* <a>
                  <Icon type='question-circle' />
                </a> */}
                <p className='report-template-label is-checkbox'>
                  {`(${t('Only for pm2p5')})`}
                </p>
              </Col>
              <Col>
                <Checkbox
                  value='historical_percent_reduction'
                  disabled={
                    !this.getState(index, 'indicators').includes('pm2p5')
                  }
                >
                  {t('Report.historical_percent_reduction')}
                </Checkbox>
                {/* <a>
                  <Icon type='question-circle' />
                </a> */}
                <p className='report-template-label is-checkbox'>
                  {`(${t('Only for pm2p5')})`}
                </p>
              </Col>
            </Row>
          </Checkbox.Group>
          <br />
          <Divider />
          <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            expandIcon={({ isActive }) => (
              <Icon type='caret-right' rotate={isActive ? 90 : 0} />
            )}
          >
            {this.renderCollapse(index)}
          </Collapse>
        </div>
      </div>
    );
  };

  renderCompare = (index: number) => {
    const { indicators } = this.props;
    const indicatorValue: string[] = this.getState(index, 'indicators');
    const indicatorArr = Object.entries(indicators || {});
    return (
      <div className='report-template-card' key={`compare-module-${index}`}>
        <a
          className='material-icons report-template-close'
          onClick={() => this.delModule(index)}
        >
          close
        </a>
        <div className='report-template-collapse'>
          <Row gutter={36}>
            <Col span={12}>
              <p className='report-template-label'>{t('Report.module name')}</p>
              <Input
                value={this.getState(index, 'name')}
                onChange={e => this.updateState(index, 'name', e.target.value)}
              />
            </Col>
            <Col span={12}>
              <div className='report-template-label'>
                {t('Report.can select two indicator')}
              </div>
              <Select
                block={true}
                dropdownMatchSelectWidth={true}
                mode='multiple'
                notFoundContent={''}
                onChange={v => this.updateState(index, 'indicators', v)}
                value={indicatorValue}
              >
                {(indicatorValue.length < 2
                  ? indicatorArr
                  : indicatorArr.filter(v => indicatorValue.includes(v[0]))
                ).map(([value, label], i) => (
                  <Option value={value} key={`indicators-${i}`}>
                    {label as string}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Divider />
          <Collapse
            bordered={false}
            defaultActiveKey={['0']}
            expandIcon={({ isActive }) => (
              <Icon type='caret-right' rotate={isActive ? 90 : 0} />
            )}
          >
            {this.renderCollapse(index)}
          </Collapse>
        </div>
      </div>
    );
  };

  renderPreview = () => {
    const { modules, name } = this.state;
    const fakeT = t;
    return (
      <div>
        <ScrollAffix
          offsetTop={300}
          trigerName='.report-scroll-element'
          scrollConfig={hash => ({
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          })}
        >
          {modules.map((m, i) => (
            <React.Fragment key={`module-${i}`}>
              <div>{m.name}</div>
              <ul className='scroll-affix-list'>
                {m.category === 'indicator' ? (
                  m.features.map((f, fi) => (
                    <li key={`scroll-item-${fi}`}>
                      <a href={`#module-${i}-${fi}`}>{fakeT(`Report.${f}`)}</a>
                    </li>
                  ))
                ) : (
                  <li>
                    <a href={`#module-${i}-0`}>{fakeT('Report.comparsion')}</a>
                  </li>
                )}
              </ul>
            </React.Fragment>
          ))}
        </ScrollAffix>
        <h2 className='report-preview-title'>{name}</h2>
        {modules.map((m, index) => (
          <div className='report-panel' key={`report-panel-${index}`}>
            <div className='report-panel-header'>
              <div className='report-panel-title'>
                {m.name || m.indicators.toString()}
              </div>
            </div>
            {m.category === 'comparison' && (
              <div
                className='report-preview-img report-scroll-element'
                id={`#module-${index}-0`}
              >
                <img width='100%' src={screen.comparsion} alt='' />
              </div>
            )}

            {m.category === 'indicator' && (
              <>
                {m.features.map(
                  (f, fi) =>
                    screen[f] && (
                      <div
                        id={`#module-${index}-${fi}`}
                        className='report-preview-img report-scroll-element'
                        key={`report-preview-${index}-${fi}`}
                      >
                        <img width='100%' src={screen[f]} alt='' />
                      </div>
                    )
                )}
              </>
            )}
          </div>
        ))}
      </div>
    );
  };
  renderEdit = () => {
    const { showModal, modules } = this.state;

    return (
      <div>
        {this.renderName()}
        {modules.map((item, index) =>
          item.category === 'indicator'
            ? this.renderIndicator(index)
            : this.renderCompare(index)
        )}
        <div
          className='report-add'
          onClick={() => this.setState({ showModal: true })}
        >
          <i className='material-icons'>add_circle</i>
          <span className='report-add-message'>{t('Report.add module')}</span>
          {/* <Icon type='question-circle' /> */}
        </div>
        <Modal
          visible={showModal}
          onConfirm={this.newModule}
          close={() => this.setState({ showModal: false })}
        />
      </div>
    );
  };

  changeMode = () => {
    const { mode } = this.state;
    this.setState({ mode: mode === 'edit' ? 'preview' : 'edit' });
  };
  render() {
    const { mode, modules } = this.state;
    return (
      <>
        <div className='report-title-button'>
          <Button
            customType='dark'
            disabled={mode === 'edit' && !modules.length}
            onClick={this.changeMode}
          >
            {mode === 'edit' ? t('Preview') : t('Edit')}
          </Button>
          &nbsp;&nbsp;
          <Button customType='light' onClick={this.saveTemplate}>
            {t('Save')}
          </Button>
        </div>
        {mode === 'edit' ? this.renderEdit() : this.renderPreview()}
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V2: {
    certifications,
    dictionary: { indicators }
  }
}) => ({
  certifications: certifications.data,
  indicators
});

export default withRouter(connect(mapStateToProps)(CreateTemplate));
