import { put, call } from 'redux-saga/effects';
import * as actions from 'actions/V3/search';
import { watchRequestNoCancel } from 'sagas/helper';
import { transform } from 'lodash';
import { apiV3 } from 'utils/api';

function* request(args: { q: string }) {
    let { data } = yield call(apiV3, '/search', 'get', {
        ...args
    });
    data = transform(
        data,
        function(result, value: any[], key: string) {
            result[key] = value.filter(
                v => v.id && (key === 'workspaces' || v.workspace_id)
            );
        },
    {} as any
    );
    yield put(actions.getV3Search(data));
    return data;
}

function* requestDetail(args: { q: string }) {
    let { data } = yield call(apiV3, '/search', 'get', {
        ...args
    });

    data = transform(
        data,
        function(result, value: any[], key: string) {
            // workspaces 则不判断 workspace_id
            result[key] = value.filter(
                v => v.id && (key === 'workspaces' || v.workspace_id)
            );
        },
    {} as any
    );
    yield put(actions.getV3SearchDetail(data));
    return data;
}

export default watchRequestNoCancel(actions.REQUEST_SEARCH_V3, request);
export const watchSearchDetail = watchRequestNoCancel(
    actions.REQUEST_SEARCH_DETAIL_V3,
    requestDetail
);
