import moment from 'moment'
import { isNumber, last } from 'lodash'
import i18n from 'configs/i18n'
import c from 'css-color-function'
import cssVars from 'css-vars-ponyfill'
import { Reading } from 'actions/V3/kiosks'
import loadScript from './loadScript'
import { logging } from 'utils/loggers'

export const downloadToLocal = (filename, dataUrl) => {
  const link = document.createElement('a')
  link.setAttribute('download', filename)
  link.setAttribute('href', dataUrl)
  link.click()
}

export const notificationDate = date => {
  const time = moment(date)
  if (i18n.language !== 'en') {
    return time.locale('zh-cn').format('MMM D号, HH:mm')
  }
  return time.format('MMM D, HH:mm')
}

export const filterWorkingHour = (
  times: string[],
  workingHours,
  type: '(]' | '()' | '[)' | '[]' // ( = 不包含 [] = 包含
) => {
  return times.map(time => {
    const [date, tim] = time.split(' ')
    workingHours = workingHours.filter(w => w.begin_time !== w.end_time)
    const dayWorkHours = workingHours.find(d => d.day_in_week === moment(date).isoWeekday() % 7)

    if (!dayWorkHours) {
      return null
    }

    const begin = dayWorkHours.begin_time.split(':').map(Number)
    const end = dayWorkHours.end_time.split(':').map(Number)

    const range = [begin[0] * 60 + begin[1], end[0] * 60 + end[1]]
    const timeArr = tim.split(':').map(Number)
    const timeValue = timeArr[0] * 60 + timeArr[1]
    let inRange = false
    switch (type) {
      case '()':
        inRange = timeValue > range[0] && timeValue < range[1]
        break
      case '(]':
        inRange = timeValue > range[0] && timeValue <= range[1]

        break

      case '[)':
        inRange = timeValue >= range[0] && timeValue < range[1]

        break

      case '[]':
        inRange = timeValue >= range[0] && timeValue <= range[1]

        break

      default:
        inRange = false
    }

    return inRange ? time : null
  })
}

export const calcWorkingHourRange = (times, workingHours) => {
  const workhoursTimes = filterWorkingHour(times, workingHours, '[]')
  const res: any[] = []

  workhoursTimes.forEach((t, i) => {
    if (!t) return

    let lastRange = last(res)

    if (!lastRange || lastRange.length === 2) {
      res.push([])
    }

    lastRange = last(res)

    if (!i || !workhoursTimes[i - 1]) {
      lastRange[0] = i
    }

    if (!workhoursTimes[i + 1]) {
      lastRange[1] = i
    }
  })
  return res
}

export const calcAvg = (arr: any[], precision: number) => {
  const safeArr = arr.filter(isNumber)

  const avg = safeArr.length && safeArr.reduce((p, n) => p + n) / safeArr.length

  return avg ? avg.toFixed(precision) : undefined
}

let retryTimes = 0
let reload = false
export const changeColor = async (color, fontColor = '#ffffff', opacity = 0.9) => {
  try {
    await window.less
      .modifyVars({
        '@primary-color': color
      })
      .then(() => {
        const borderColor = c.convert(`color(${color} shade(5%))`)
        const kioskColor = c.convert(`color(${color} alpha(-${100 - opacity * 100}%))`)

        const kioskUnderline = c.convert(`color(color(${color} whiteness(30%)) alpha(-50%))`)
        cssVars({
          variables: {
            '--accent-color': color,
            '--kiosk-background-color': kioskColor,
            '--blue-border-color': borderColor,
            '--kiosk-indicator-color': fontColor,
            '--kiosk-indicator-unit': fontColor,
            '--kiosk-underline-color': kioskUnderline
          }
        })
      })
    retryTimes = 0
  } catch (error: any) {
    logging.error(`theme compile failed: ${error.message}, retry: ${retryTimes}`)

    if (retryTimes < 5) {
      setTimeout(() => {
        retryTimes++
        changeColor(color, fontColor, opacity)
      }, 1000 * 1 * retryTimes)
    } else {
      if (!reload) {
        reload = true
        loadScript('https://cdnjs.cloudflare.com/ajax/libs/less.js/2.7.2/less.min.js').then(() => {
          retryTimes = 0
          changeColor(color, fontColor, opacity)
        })
      }
    }
  }
}

export const calcValueByFormula = (obj: Reading, readingDict: { [P: string]: number }) => {
  if (obj.value === 0) {
    return 0
  }
  if (!obj.value) {
    return '-'
  }
  // use aqi 不套用公式
  if (obj.use_aqi || !obj.formula) {
    return obj.value
  }

  let num = obj.value
  let formula = obj.formula
  Object.keys(readingDict).forEach(k => {
    if (formula.includes(k)) {
      formula = formula.replace(k, readingDict[k].toString())
    }
  })
  try {
    // x 为公式中的变量名， eval 取环境上下文的 x
    const x = obj.value
    logging.error(x, formula)
    // tslint:disable-next-line:no-eval
    // eslint-disable-next-line no-eval
    num = eval(formula)
  } catch {
    return 'NA'
    // message.error('formula contain invalid params: ' + obj.formula);
  }
  return Number(num).toFixed(obj.precision)
}

export const iosMonth = (year, month) => {
  if (month < 10) {
    return moment(`${year}-0${month}`)
  } else {
    return moment(`${year}-${month}`)
  }
}

export const parseDigit = (num?: number, max = 5) => {
  if (!num) return num
  const numStr = String(num)
  const len = numStr.length
  const isFloat = numStr.includes('.')
  if (len <= max || !isFloat) return num

  const [intStr] = numStr.split('.')

  return +num.toFixed(max >= intStr.length + 2 ? 2 : 1)
}
