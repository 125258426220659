import { redirectTypes } from './index'
export const paths = [
  '/changelog',
  '/workspaces/:id',
  '/notification',
  '/search',
  '/download',
  '/profile',
  '/password',
  '/workspaces',
  '/upgrade/:workspace_id',
  '/upgrade/:workspace_id/intro',
  '/locations',
  '/favorites',
  '/billing',
  '/invoice',
  '/public'
].concat(redirectTypes.map(t => `/${t}/:id`))
