import * as actions from 'actions/V3/following'

export type FollowListState = {
  data: actions.FollowItem[],
  meta?: Meta,
  query: any
}

export default function Following(
  state: FollowListState = {
    data: [],
    query: {}
  },
  action: actions.GetFollowsAction
) {
  switch (action.type) {
    case actions.GET_FOLLOWING_V3:
      return {
        data: action.data,
        meta: action.meta,
        query: action.query
      }
    default:
      return state
  }
}
