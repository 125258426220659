import * as React from 'react'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import classNames from 'classnames'
import './Table.css'

interface Props extends TableProps<{}> {
  type: 'light' | 'dark';
  border?: boolean;
  centerAlign?: boolean;
  size?: 'default' | 'small';
}

const CustomTable: React.SFC<Props> = React.memo(({ type, size, className, border, centerAlign, ...props }) => (
  <Table
    size={size || 'small'}
    bordered={border || false}
    className={classNames(className, 'custom-table', `custom-table--${type}`, {
      centerAlign
    })}
    {...props}
  />
))

export default CustomTable
