import { put, call } from 'redux-saga/effects'
import {
  REQUEST_ANNOUNCEMENT,
  REQUEST_ANNOUNCEMENT_LATEST,
  getAnnouncement,
  getChanglog
} from 'actions/V2/announcement'
import { watchRequestNoCancel } from 'sagas/helper'

import api from 'utils/api'

function* requestChangelog(args: {}) {
  const { data } = yield call(api, '/announcements', 'get', {
    ...args
  })

  yield put(getChanglog(data))
  return data
}

export default watchRequestNoCancel(REQUEST_ANNOUNCEMENT, requestChangelog)

function* request(args: {}) {
  const { data } = yield call(api, '/announcements/latest', 'get', {
    ...args
  })

  yield put(getAnnouncement(data))
  return data
}

export const watchAnnouncement = watchRequestNoCancel(REQUEST_ANNOUNCEMENT_LATEST, request)
