import * as actions from 'actions/V3/location'

export type LocationState = {
  base?: actions.LocationBase,
  edit?: actions.LocationEdit
}

export default function Location(state: LocationState = {}, action: actions.LocationV3Action) {
  switch (action.type) {
    case actions.GET_LOCATION_BASE_V3:
      return {
        ...state,
        base: action.locationBase
      }
    case actions.GET_LOCATION_EDIT_V3:
      return {
        ...state,
        edit: action.locationEdit
      }
    case actions.GET_LOCATION_UPDATE_V3:
      return {
        ...state,
        base: action.locationBase
      }
    default:
      return state
  }
}
