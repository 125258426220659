import * as React from 'react'
import Exception from 'pages/Exception'
import { t } from 'i18next'
import { isNaN } from 'lodash'
import Loading from 'containers/Loading'
import Layout from 'containers/Layout'
import { message } from 'antd'
import api, { stack } from 'utils/api'
import { getCookie } from 'utils/auth'
import { hashCode } from 'utils/hash'
import { logging } from 'utils/loggers'

type State = Readonly<{
  error?: {
    title: string,
    message: string
  }
}>

const excludeMessage = ['Failed to fetch', 'Network request failed', 'Network Error']

class ErrorBoundary extends React.PureComponent<{}, State> {
  state: State = {}
  networkMessage: any
  componentDidMount() {
    window.addEventListener('offline', this.showOffline, false)
    window.addEventListener('online', this.hideOffline, false)
    window.addEventListener('unhandledrejection', this.catchPromiseEvent)
  }

  componentWillUnmount() {
    window.removeEventListener('offline', this.showOffline, false)
    window.removeEventListener('online', this.hideOffline, false)
  }

  componentDidCatch(error: Error) {
    try {
      error = error || {}
      this.postError(error, 'V3_BOUNDARY_CATCH')
      if (!error.message || excludeMessage.includes(error.message)) return
      this.setState(
        {
          error: {
            title: error.message,
            message: error.stack || ''
          }
        },
        () => {
          this.refresh()
        }
      )
    } catch (error) {
      logging.error('DidCatch Error!, aborting...')
    }
  }

  catchPromiseEvent = (event: PromiseRejectionEvent) => {
    try {
      event.preventDefault()
      const error = event.reason || {}
      this.postError(error, 'V3_PROMISE_CATCH')
      if (!error.message || excludeMessage.includes(error.message)) return
      const isApiError = !!error.code
      this.setState(
        {
          error: {
            title: `Promise Error: ${error.message || ''}`,
            message: isApiError ? `Code: ${error.code} id: ${error.id} Status: ${error.status}` : error.stack || ''
          }
        },
        () => {
          this.refresh()
        }
      )
    } catch (error) {
      logging.error('Promise Catch Error!, aborting...')
    }
  }

  refresh() {
    const refresh = localStorage.getItem('error_refresh')
    let refreshTime = refresh && !isNaN(+refresh) ? +refresh : 4
    if (refreshTime < 32) {
      refreshTime *= 2
    } else {
      refreshTime = 4
    }

    localStorage.setItem('error_refresh', refreshTime.toString())
    setTimeout(this.resetError, refreshTime * 60000)
  }

  resetError = () => {
    window.location.reload()
  }

  showOffline = () => {
    if (!this.networkMessage) {
      this.networkMessage = message.error(t('Network Offline'), 0)
    }
  }

  hideOffline = () => {
    if (this.networkMessage) {
      this.networkMessage()
      this.networkMessage = null
    }
  }

  postError(e, description) {
    try {
      if (excludeMessage.includes(e.message) || e.code) return null
      const cookie = getCookie()
      const web_navigator = {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        appVersion: navigator.appVersion,
        language: navigator.language,
        platform: navigator.platform,
        userAgent: navigator.userAgent,
        vendor: navigator.vendor
      }

      const web_environment = {
        navigator: web_navigator,
        cookie: document.cookie,
        refreshTime: localStorage.getItem('error_refresh'),
        time: new Date().toLocaleString(),
        href: window.location.href,
        tag: window.TAG_VERSION,
        compileTime: window.COMPILE_TIME
      }

      const error = {
        exception_class: `${description}: ${e.name}`,
        message: e.message,
        web_backtrace: `${description}: ${e.name} ${e.description}
                        stack: ${e.stack}`,
        controller_name: '',
        action_name: '',
        web_environment: web_environment,
        user_id: cookie && cookie.id,
        version: window.QLEAR_VERSION,
        web_request: stack
      }

      if (process.env.REACT_APP_QLEAR_ENV === 'production') {
        const hash = hashCode(e.stack || '').toString()
        const lastHashTime = localStorage.getItem(hash) || '0'
        const now = Date.now()
        if (now - +lastHashTime > 1000 * 60 * 60) {
          localStorage.setItem(hash, now.toString())
          api('/exceptions', 'post', error)
        }
      }

      return logging.error(error)
    } catch (error) {
      logging.error('POST ERROR FAILED!!', error)
    }
  }

  render() {
    const { error } = this.state

    return (
      <Layout>
        <Loading>
          {error ? (
            <Exception
              button={{
                message: t('Reload'),
                handle: () => this.setState({ error: undefined })
              }}
              error={error}
              title={t('oops!')}
              message={t('Something went wrong')}
            />
          ) : (
            this.props.children
          )}
        </Loading>
      </Layout>
    )
  }
}

export default ErrorBoundary
