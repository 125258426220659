import * as React from 'react'
import cache from 'sagas/cache'
import className from 'classnames'
import Editable from 'components/Editable'
import Form from 'containers/IndicatorDetail/Form'
import History from 'containers/IndicatorDetail/History'
import Input from 'components/Custom/Input'
import { apiV3 } from 'utils/api'
import { connect } from 'react-redux'
import { Guard } from 'utils/guard'
import { Link, Route, Switch, withRouter } from 'react-router-dom'
import { message, Breadcrumb, Switch as AntSwitch } from 'antd'
import { P, StateProps } from './type'
import { REQUEST_INDICATOR_V3, requestIndicatorDetail } from 'actions/V3/indicators'
import { requestNotificationConfigV3 } from 'actions/V3/notificationConfig'
import { t } from 'i18next'
import './index.css'
import Indicator from '-!svg-react-loader!images/svg/Indicator.svg'
import TableIcon from '-!svg-react-loader!images/svg/Table.svg'

type State = Readonly<{ name?: string, checked?: boolean }>

@Guard
class Detail extends React.PureComponent<P, State> {
  state: State = {}

  componentDidMount() {
    this.fetchDetail()
  }

  UNSAFE_componentWillReceiveProps(next: P) {
    const { indicator_id } = next.match.params
    const { indicator_id: iID } = this.props.match.params

    if (indicator_id !== iID) {
      this.fetchDetail(false, next)
    }
  }

  fetchDetail = (show = true, props = this.props) => {
    const {
      dispatch,
      match: {
        params: { indicator_id }
      }
    } = props

    dispatch(requestIndicatorDetail({ indicator_id: +indicator_id, show }))

    dispatch(
      requestNotificationConfigV3({
        category: 'indicator',
        indicator_id: +indicator_id,
        size: 1000
      })
    )
  }

  switchChange = async (status: boolean) => {
    const {
      match: {
        params: { indicator_id }
      }
    } = this.props

    await apiV3(`/indicators/${status ? 'enable' : 'disable'}`, 'post', {
      indicator_id: [+indicator_id]
    })
    cache.cleanCache(REQUEST_INDICATOR_V3)
    this.setState({ checked: status })
  }

  updateIndicator = async disable => {
    const { name } = this.state
    if (name === undefined) {
      return disable()
    }
    if (!name) {
      return message.warn(t('indicator.input indicator'))
    }
    const {
      match: {
        params: { indicator_id }
      }
    } = this.props
    await apiV3(`/indicators/${indicator_id}`, 'put', {
      indicator: { name }
    })
    disable()
    this.fetchDetail(false)
  }

  GuardHandle_indicatorUpdate = (rule?: GuardRule) => rule && rule.enable

  render() {
    const {
      location: { pathname },
      indicator,
      limit,
      match: {
        params: { id, indicator_id }
      }
    } = this.props

    if (!indicator) {
      return null
    }

    const inFormPage = !pathname.includes('/audit')

    const { name, checked } = this.state
    const editable = this.GuardHandle_indicatorUpdate()
    return (
      <>
        <div className='ds-detail-header'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link className='collection-link' to={`/workspaces/${id}/indicators`}>
                <Indicator />
                {t('indicator.All Indicators')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{indicator.name}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Link className='link-icon' to={`/workspaces/${id}/data?cate=indicator&indicator_id=${indicator.id}`}>
            <TableIcon />
            <span>{t('navbar.Table')}</span>
          </Link>
        </div>
        <div className='collection-detail-title collection-detail-row'>
          <div className='collection-detail-row_left'>
            <div
              className={className('collection-detail-status', {
                'is-online': indicator.reading.value !== null && !indicator.reading.level,
                [`is-${indicator.reading.level}`]: indicator.reading.level
              })}
            >
              <span className='collection-detail-status_num'>{indicator.reading.value}</span>
              <span className='collection-detail-status_text'>
                {indicator.reading.level
                  ? indicator.reading.level
                  : indicator.reading.value
                  ? t('Notification.online')
                  : t('Notification.offline')}
              </span>
              {indicator.unit && (
                <span
                  className={className('collection-detail-status_unit', {
                    'is-online': indicator.reading.value !== null && !indicator.reading.level,
                    [`is-${indicator.reading.level}`]: indicator.reading.level
                  })}
                >
                  {indicator.unit}
                </span>
              )}
            </div>
          </div>
          <div className='collection-detail-row_right'>
            <Editable
              showIcon={editable}
              iconPosition='end'
              iconClass='collection-detail-name_icon'
              render={(edit, disableFunc) =>
                edit ? (
                  <Input
                    disabled={!edit}
                    className='collection-detail-name collection-detail-name--input'
                    type='none'
                    onChange={e => this.setState({ name: e.target.value })}
                    onPressEnter={() => this.updateIndicator(disableFunc)}
                    onBlur={() => this.updateIndicator(disableFunc)}
                    value={name !== undefined ? name : indicator.name}
                  />
                ) : (
                  <div className='edit-disabled-text'>{name !== undefined ? name : indicator.name}</div>
                )
              }
            />

            <AntSwitch
              size='small'
              disabled={!editable}
              checkedChildren={<span className='small_text'>{t('indicator.active')}</span>}
              unCheckedChildren={<span className='small_text'>{t('indicator.inactive')}</span>}
              onChange={this.switchChange}
              checked={checked !== undefined ? checked : indicator.status === 'enable'}
            />

            <div>
              <Link
                replace={true}
                to={{
                  pathname: `/workspaces/${id}/indicators/${indicator_id}`
                }}
                className={className('collection-detail-tab', {
                  'is-active': inFormPage
                })}
              >
                {t('indicator.Indicator Details')}
              </Link>

              <Link
                replace={true}
                to={{
                  pathname: `/workspaces/${id}/indicators/${indicator_id}/audit`
                }}
                className={className('collection-detail-tab', {
                  'is-active': !inFormPage
                })}
              >
                {t('indicator.Audit Trail')}
              </Link>
            </div>
          </div>
        </div>
        <div>
          <Switch>
            <Route
              exact={true}
              path='/workspaces/:id/indicators/:indicator_id'
              render={() => <Form limit={limit} refresh={this.fetchDetail} indicator={indicator} />}
            />
            <Route
              exact={true}
              path='/workspaces/:id/indicators/:indicator_id/audit'
              render={() => (
                <History
                  requstQuery={{
                    item_type: 'indicator',
                    indicator_id: +indicator_id
                  }}
                />
              )}
            />
          </Switch>
        </div>
      </>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V3: {
    indicators,
    notificationConfig: { indicator }
  }
}) => ({
  indicator: indicators.detail,
  limit: indicator.data
})

export default withRouter(connect(mapStateToProps)(Detail))
