import { requestOrgBillingOV, requestOrgBillings } from 'actions/V3/billing'
import Button from 'components/Custom/Button'
import Table from 'components/Custom/Table'
import { t } from 'i18next'
import * as React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { iosMonth } from 'utils/helper'
import logo_print from '../../images/logo_print.png'
import './index.css'
import { P, StateProps } from './type'

class Invoice extends React.PureComponent<P, {}> {
  componentDidMount() {
    const {
      dispatch,
      billingOV,
      match: {
        params: { id, year, month }
      }
    } = this.props
    if (!billingOV) {
      dispatch(requestOrgBillingOV({ id }))
    }
    dispatch(
      requestOrgBillings({
        id,
        year,
        month
      })
    )
  }

  renderPrintHeader = () => {
    return (
      <div className='invoice-page-header'>
        <div className='invoice-page-print-logo'>
          <img src={logo_print} alt='QLEAR' />
        </div>
        <div className='invoice-page-print-top'>
          <div className='invoice-page-print-address'>
            1F-B, 102 Lixi Lu, Shanghai 200050
            <br />
            中国上海长宁区利西路102号1楼B
          </div>
        </div>
      </div>
    )
  }

  renderPrintFooter = () => {
    return (
      <div className='report-pr-panel-footer'>
        <strong>{t('Report.Email us at')} </strong>
        info@qlear.io
        <strong> {t('Report.or visit')} </strong>
        qlear.io
        <strong> {t('Report.to learn more')}.</strong>
      </div>
    )
  }

  geneTableColumns = () => {
    return [
      {
        title: t('ITEM(S)'),
        dataIndex: 'id',
        width: '30%',
        key: 'id',
        render: () => {
          return 'Pro Workspaces'
        }
      },
      {
        title: t('DESCRIPTION'),
        dataIndex: 'year',
        width: '50%',
        key: 'year',
        render: (text, record) => {
          return (
            <div>
              <div className='title'>{record.workspaces_count} Pro Workspaces</div>
              <div className='date'>For Month of {iosMonth(record.year, record.month).format('MMM YYYY')}</div>
            </div>
          )
        }
      },
      {
        title: t('SUB-TOTAL'),
        dataIndex: 'total_price',
        width: '20%',
        key: 'total_price',
        render: (text, record) => {
          return <div>CNY {text}</div>
        }
      }
    ]
  }

  navBack = () => {
    const { history } = this.props
    history.goBack()
  }

  handlePrint = () => {
    window.print()
  }

  displayPrice = price => {
    return price.toFixed(2)
  }

  render() {
    const { billingsByMonth, billingOV } = this.props
    if (!billingsByMonth.length || !billingOV) {
      return null
    }
    const obj = billingsByMonth[0]

    return (
      <div className='location-wrapper'>
        <div className='billing-container'>
          <div className='header-nav'>
            <div className='go-back'>
              <div className='title'>
                <i className='iconfont icon-back' onClick={this.navBack} />
              </div>
            </div>
            <Button customType='dark' size='large' style={{ width: 120 }} onClick={this.handlePrint}>
              {t('Print')}
            </Button>
          </div>
          <div className='invoice-container'>
            <div>
              {this.renderPrintHeader()}
              <div className='invoice-page-print-top2'>
                <table>
                  <tbody>
                    <tr>
                      <td>{t('Organization')}:</td>
                      <td className='strong'>{billingOV.name}</td>
                    </tr>
                    <tr>
                      <td>{t('Date')}:</td>
                      <td className='strong'>{iosMonth(obj.year, obj.month).format('YYYY-MM')}</td>
                    </tr>
                    <tr>
                      <td>{t('Invoice')}#:</td>
                      <td className='strong'>{obj.code}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='invoice-page-title'>
                {t('billing.all workspaces invoice title', {
                  month: iosMonth(obj.year, obj.month).format('MMM')
                })}
              </div>
            </div>
            <div className='invoice-page-table'>
              <Table
                rowClassName={() => 'invoice-row'}
                type='light'
                pagination={false}
                columns={this.geneTableColumns()}
                dataSource={[Object.assign(obj, { key: 1 })]}
                size='default'
              />
              <table className='invoice-page-summary-footer'>
                <tbody>
                  <tr>
                    <td>SUB-TOTAL:</td>
                    <td className='strong'>CNY {obj.total_price}</td>
                  </tr>
                  <tr className='text-danger'>
                    <td>TAX({billingOV.tax_rate * 100}%):</td>
                    <td className='strong'>CNY {this.displayPrice(billingOV.tax_rate * obj.total_price)}</td>
                  </tr>
                  <tr className='total'>
                    <td className='width200'>TOTAL COST:</td>
                    <td className='strong'>CNY {this.displayPrice(obj.total_price * (1 + billingOV.tax_rate))}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {this.renderPrintFooter()}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { billing } }) => ({
  billingsByMonth: billing.list.data,
  billingOV: billing.ov
})

export default withRouter(connect(mapStateToProps)(Invoice))
