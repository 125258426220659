import { call, put } from 'redux-saga/effects'

import { getCertifications, REQUEST_CERTIFICATIONS } from 'actions/V2/certifications'
import { watchRequestNoCancel } from 'sagas/helper'

import api from 'utils/api'

function* request() {
  const { data } = yield call(api, '/certifications', 'get')
  yield put(getCertifications(data))
  return data
}

export default watchRequestNoCancel(REQUEST_CERTIFICATIONS, request)
