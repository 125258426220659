import { KioskCollection, KioskDetail } from 'actions/V3/kiosks'
import { t } from 'i18next'
import React from 'react'

export interface IndexDetailsParams {
  key: string
  kiosk: KioskDetail
  defaultCollection: KioskCollection
}

export default function IndexDetails(params: IndexDetailsParams) {
  const { key, kiosk, defaultCollection } = params
  if (!key || !defaultCollection) return
  if (!kiosk) return
  const {average} = kiosk

  let title: string = ''
  let subtitle: string = ''
  let percentage
  let unit: string = ''
  let amount: string = ''
  let amount_unit: string = ''
  let smallData
  let boldData
  switch (key) {
    case 'system health':
      if (!average || !average.covid_index_data) return
      const immunity = average.covid_index_data.term_immunity || 0
      title = t('kioskV3.Immune_System_Health')
      subtitle = t('kioskV3.index_detail_subtitle')

      const calculatedPercentage = (1-immunity)*100
      percentage = calculatedPercentage.toFixed(1)
      unit = '%'
      boldData = false
      break

    case 'Air changes per hour':
      if (!kiosk || !kiosk.covid_index_supplementary_data) return
      const ACH = kiosk.covid_index_supplementary_data.data[0].value
      title = t('kioskV3.air_changes_per_hour')
      subtitle = ''
      percentage = ACH
      unit = ''
      break

    case 'filtration':
      if (!kiosk || !kiosk.covid_index_supplementary_data) return
      const cadrValue = kiosk.covid_index_supplementary_data.data[1].value
      const filtrationValue = kiosk.covid_index_supplementary_data.data[2].value
      if (cadrValue == null && filtrationValue == null) return
      title = t('kioskV3.CADR')
      subtitle = t('kioskV3.Filtration')
      amount = cadrValue
      amount_unit = 'CMH'
      percentage = filtrationValue
      unit = '%'
      smallData = true
      boldData = true
      break
  }

  return (
    <div className='index-detail-row'>
      <div>
        <p className='index-detail-name'>{title}</p>
        <p
          className='index-detail-name'
          style={{
            fontWeight: boldData ? 'bolder' : 'normal',
            fontSize: boldData ? '2.5vh' : '2.0vh',
            lineHeight: boldData ? '2.5vh' : '2.0vh'
          }}
        >
          {subtitle}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          margin: 'auto 0 auto auto'
        }}
      >
        <span
          style={{
            color: '#006B94',
            fontSize: '2.4vh',
            fontWeight: 'bolder',
            lineHeight: '2.4vh'
          }}
        >
          {amount} {amount_unit}
        </span>
        <span
          style={{
            color: '#006B94',
            fontWeight: 'bolder',
            fontSize: smallData ? '2.4vh' : '2.4vh',
            textAlign: 'right'
          }}
        >
          {percentage}
          {unit}
        </span>
      </div>
    </div>
  )
}
