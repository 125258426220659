import {
    KiosksAction,
    GET_KIOSK_V3,
    KioskDetail,
    KioskListItem,
    KioskEdit,
    KioskLock,
    GET_KIOSK_EDIT_V3,
    GET_KIOSK_DELETE_V3,
    GET_KIOSK_DETAIL_V3,
    GET_KIOSK_NEW_V3,
    GET_KIOSK_EDIT_INFO_V3,
    CLEAN_KIOSK_V3
} from 'actions/V3/kiosks';

export type KiosksState = {
  data: {
    id: number | string;
    name: string;
    kiosk_list: KioskListItem[]
  }[];
  meta?: Meta;
  query?: {};
  detail?: KioskDetail;
  edit?: KioskEdit;
  lock?: KioskLock;
};

export default function Kiosks(state: KiosksState = { data: [] }, action: KiosksAction) {
    switch (action.type) {
    case GET_KIOSK_V3:
        return {
            data: action.kiosks,
            meta: action.meta,
            query: action.query
        };
    case GET_KIOSK_EDIT_INFO_V3:
        return {
            ...state,
            edit: action.KioskEditInfo
        };
    case GET_KIOSK_EDIT_V3:
        const obj = state.data.find(item => item.id === action.kioskEdit.id);
        if (obj) {
            Object.assign(obj, action.kioskEdit);
        }
        return { ...state };
    case GET_KIOSK_NEW_V3:
        const location = state.data.find(e => e.id === action.kioskNew.location_id)
        if (location) {
            location.kiosk_list.push(action.kioskNew);
        }
        return {
            ...state,
            data: state.data
        };
    case GET_KIOSK_DELETE_V3:
        const { id, location_id } = action.kioskDelete
        const newData = state.data.slice()
        const locationIdx = newData.findIndex(item => item.id === +location_id);
        newData[locationIdx].kiosk_list = newData[locationIdx].kiosk_list.filter(item => item.id !== +id)
        return {
            ...state,
            data: newData
        };
    case GET_KIOSK_DETAIL_V3:
        return {
            ...state,
            detail: action.kioskDetail,
            lock: action.kioskLock
        };
    case CLEAN_KIOSK_V3:
        return {
            ...state,
            detail: undefined,
            lock: undefined
        };
    default:
        return state;
    }
};
