import * as React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { P, State, StateProps } from './type'
import { requestOrgHistories } from 'actions/V3/org'
import { t } from 'i18next'
import { withRouter } from 'react-router-dom'
import './index.css'

class History extends React.PureComponent<P, State> {
  state: State = {}

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    dispatch(
      requestOrgHistories({
        id
      })
    )
  }

  render() {
    const { histories } = this.props
    return (
      <div className='histories-page'>
        {histories.map(e => (
          <div className='history-item' key={e.id}>
            <div className='desc'>
              <strong>{e.user_name}</strong> {e.action} {t('upgrade.workspace')}
              <span className='link'>{e.workspace_name}</span>
              {t('upgrade.to')}
              <span className='link'>{e.action === 'downgrade' ? t('pricing.basic') : t('pricing.pro')}</span>
            </div>
            <div className='timestamp'>{moment(e.created_at).format('YYYY-MM-DD HH:mmA')}</div>
          </div>
        ))}
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { org } }) => ({
  histories: org.histories || []
})

export default withRouter(connect(mapStateToProps)(History))
