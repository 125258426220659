import {GET_USER_SHIP, UserShipAction, WorkspaceItem} from 'actions/V2/userShip'

const EMPTY_ARRAY = []

export type UserShipState = {
  data: WorkspaceItem[],
  all: WorkspaceItem[]
}

export default function UserShip(
  state: UserShipState = {
    data: EMPTY_ARRAY,
    all: EMPTY_ARRAY
  },
  action: UserShipAction
) {
  switch (action.type) {
    case GET_USER_SHIP:
      return {
        // If the data or all is and was empty, no need to change
        data: action.data.length  ? action.data : EMPTY_ARRAY,
        all: action.all.length ?  action.all : EMPTY_ARRAY
      }
    default:
      return state
  }
}
