import { Action, ActionCreator } from 'redux'

export const REQUEST_ORG_LIST_V3 = 'REQUEST_ORG_LIST_V3'
export const GET_ORG_LIST_V3 = 'GET_ORG_LIST_V3'
export const REQUEST_ORG_WORKSPACES_V3 = 'REQUEST_ORG_WORKSPACES_V3'
export const GET_ORG_WORKSPACES_V3 = 'GET_ORG_WORKSPACES_V3'
export const GET_ORG_USERS_V3 = 'GET_ORG_USERS_V3'
export const REQUEST_ORG_USERS_V3 = 'REQUEST_ORG_USERS_V3'
export const REQUEST_ORG_HISTORIES_V3 = 'REQUEST_ORG_HISTORIES_V3'
export const GET_ORG_HISTORIES_V3 = 'GET_ORG_HISTORIES_V3'
export const REQUEST_ORG_DETAIL_V3 = 'REQUEST_ORG_DETAIL_V3'
export const GET_ORG_DETAIL_V3 = 'GET_ORG_DETAIL_V3'
export const REQUEST_NEW_ORG_USER_V3 = 'REQUEST_NEW_ORG_USER_V3'
export const GET_NEW_ORG_USER_V3 = 'GET_NEW_ORG_USER_V3'
export const REQUEST_DELETE_ORG_USER_V3 = 'REQUEST_DELETE_ORG_USER_V3'
export const GET_DELETE_ORG_USER_V3 = 'GET_DELETE_ORG_USER_V3'
export const REQUEST_UPDATE_ORG_USER_V3 = 'REQUEST_UPDATE_ORG_USER_V3'
export const GET_UPDATE_ORG_USER_V3 = 'GET_UPDATE_ORG_USER_V3'

export type OrgObj = {
  id: number,
  name: string,
  contact: string,
  phone: string,
  email: string,
  created_at: string,
  workspaces: {
    id: number,
    name: string
  }[]
}

export type WorkspaceObj = {
  id: number,
  name: string,
  code: string,
  created_at: string,
  pro: boolean
}

export type UserObj = {
  id: number,
  user_id: number,
  org_id: number,
  user_name: string,
  user_email: string,
  permission: string
}

export type UserDelete = {
  id: number
}

export type HisObj = {
  id: number,
  workspace_id: number,
  user_id: number,
  workspace_name: string,
  user_name: string,
  action: string,
  created_at: string
}

export interface RequestOrgListAction extends Action<typeof REQUEST_ORG_LIST_V3> {
  args: any;
}

export interface RequestOrgWorkspacesAction extends Action<typeof REQUEST_ORG_WORKSPACES_V3> {
  args: any;
}

export interface RequestOrgUsersAction extends Action<typeof REQUEST_ORG_USERS_V3> {
  args: any;
}

export interface RequestOrgHistoriesAction extends Action<typeof REQUEST_ORG_HISTORIES_V3> {
  args: any;
}

export interface RequestOrgDetailAction extends Action<typeof REQUEST_ORG_DETAIL_V3> {
  args: any;
}

export interface RequestNewOrgUserAction extends Action<typeof REQUEST_NEW_ORG_USER_V3> {
  args: any;
}

export interface RequestDeleteOrgUserAction extends Action<typeof REQUEST_DELETE_ORG_USER_V3> {
  args: any;
}

export interface RequestUpdateOrgUserAction extends Action<typeof REQUEST_UPDATE_ORG_USER_V3> {
  args: any;
}

interface GetOrgListAction extends Action<typeof GET_ORG_LIST_V3> {
  orgs: OrgObj[];
}

interface GetOrgWorkspacesAction extends Action<typeof GET_ORG_WORKSPACES_V3> {
  workspaces: WorkspaceObj[];
}

interface GetOrgUsersAction extends Action<typeof GET_ORG_USERS_V3> {
  users: UserObj[];
}

interface GetOrgHistoriesAction extends Action<typeof GET_ORG_HISTORIES_V3> {
  histories: HisObj[];
}

interface GetOrgDetailAction extends Action<typeof GET_ORG_DETAIL_V3> {
  org: OrgObj;
}

interface GetNewOrgUserAction extends Action<typeof GET_NEW_ORG_USER_V3> {
  user: UserObj;
}

interface GetDeleteOrgUserAction extends Action<typeof GET_DELETE_ORG_USER_V3> {
  userDel: UserDelete;
}

interface GetUpdateOrgUserAction extends Action<typeof GET_UPDATE_ORG_USER_V3> {
  user: UserObj;
}

export type OrgAction =
  | GetOrgListAction
  | GetOrgWorkspacesAction
  | GetOrgHistoriesAction
  | GetOrgDetailAction
  | GetNewOrgUserAction
  | GetDeleteOrgUserAction
  | GetUpdateOrgUserAction
  | GetOrgUsersAction

export const requestOrgList: ActionCreator<RequestOrgListAction> = args => ({
  type: REQUEST_ORG_LIST_V3,
  args
})

export const getOrgList: ActionCreator<GetOrgListAction> = orgs => ({
  type: GET_ORG_LIST_V3,
  orgs
})

export const requestOrgWorkspaces: ActionCreator<RequestOrgWorkspacesAction> = args => ({
  type: REQUEST_ORG_WORKSPACES_V3,
  args
})

export const getOrgWorkspaces: ActionCreator<GetOrgWorkspacesAction> = workspaces => ({
  type: GET_ORG_WORKSPACES_V3,
  workspaces
})

export const requestOrgUsers: ActionCreator<RequestOrgUsersAction> = args => ({
  type: REQUEST_ORG_USERS_V3,
  args
})

export const getOrgUsers: ActionCreator<GetOrgUsersAction> = users => ({
  type: GET_ORG_USERS_V3,
  users
})

export const requestOrgHistories: ActionCreator<RequestOrgHistoriesAction> = args => ({
  type: REQUEST_ORG_HISTORIES_V3,
  args
})

export const getOrgHistories: ActionCreator<GetOrgHistoriesAction> = histories => ({
  type: GET_ORG_HISTORIES_V3,
  histories
})

export const requestOrgDetail: ActionCreator<RequestOrgDetailAction> = args => ({
  type: REQUEST_ORG_DETAIL_V3,
  args
})

export const getOrgDetail: ActionCreator<GetOrgDetailAction> = org => ({
  type: GET_ORG_DETAIL_V3,
  org
})

export const requestNewOrgUser: ActionCreator<RequestNewOrgUserAction> = args => ({
  type: REQUEST_NEW_ORG_USER_V3,
  args
})

export const getNewOrgUser: ActionCreator<GetNewOrgUserAction> = user => ({
  type: GET_NEW_ORG_USER_V3,
  user
})

export const requestDeleteOrgUser: ActionCreator<RequestDeleteOrgUserAction> = args => ({
  type: REQUEST_DELETE_ORG_USER_V3,
  args
})

export const getDeleteOrgUser: ActionCreator<GetDeleteOrgUserAction> = userDel => ({
  type: GET_DELETE_ORG_USER_V3,
  userDel
})

export const requestUpdateOrgUser: ActionCreator<RequestUpdateOrgUserAction> = args => ({
  type: REQUEST_UPDATE_ORG_USER_V3,
  args
})

export const getUpdateOrgUser: ActionCreator<GetUpdateOrgUserAction> = user => ({
  type: GET_UPDATE_ORG_USER_V3,
  user
})
