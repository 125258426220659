import qs from 'qs';
import { ReactElement } from 'react';
import { pick, pickBy } from 'lodash';

type QueryTransform = (
  config: {
    search?: string;
    key: string;
    state: {
      [value: string]: ReactElement<{}>;
    };
  }
) => ReactElement<{}>;

/* query transform */
export const queryTransform: QueryTransform = ({ search = '', key, state }) => {
    const query = qs.parse(search.slice(1));
    if (!query || !query[key]) {
        return state.default;
    }
    return state[Object(query)[key]] || state.default;
};

/* query detect */
type QueryDetect = (
  buildConfig: {
    key: string;
    availlabel?: string[];
  }
) => (
  config: {
    search?: string;
    value: string | string[];
    defaultResult?: boolean;
  }
) => boolean;
export const queryDetect: QueryDetect = ({ availlabel = [], key }) => ({
    search = '',
    value,
    defaultResult = false
}) => {
    const query = qs.parse(search.slice(1));
    const param = Object(query)[key];
    if (!query || !param || !availlabel.includes(param || '')) {
        return defaultResult;
    }

    if (Array.isArray(value)) {
        return value.includes(param);
    }
    return param === value;
};

/* query merge */

type QueryMerge = (
  config: {
    search?: string;
    query?: {
      [key: string]: string;
    };
    delKeys?: string[];
    keepKeys?: string[];
  }
) => string;

export const queryMerge: QueryMerge = ({
    search = '',
    query = {},
    delKeys = [],
    keepKeys = []
}) => {
    const q = qs.parse(search.slice(1));
    let newQuery = { ...q, ...query };

    if (delKeys.length) {
        delKeys.forEach((key, i) => {
            if (newQuery[key]) {
                delete newQuery[key];
            }
        });
    }

    if (keepKeys.length) {
        newQuery = pick(newQuery, keepKeys);
    }

    newQuery = pickBy(newQuery, v => !!v);

    return `?${qs.stringify(newQuery)}`;
};

type QueryContain = (
  argus: {
    search: string;
    key: string[];
    all?: boolean;
  }
) => boolean;

export const queryContain: QueryContain = ({ search, key, all = true }) => {
    const q = qs.parse(search.slice(1));

    if (all) {
        return key.every(k => {
            return !!q[k];
        });
    }

    return key.some(k => {
        return !!q[k];
    });
};
