import { GetSelect, GET_LOCATION_SELECT_V3, Location } from 'actions/V3/locationSelect'

export type LocationSelectState = {
  data: Location[]
}

export default function LocationSelect(
  state: LocationSelectState = {
    data: []
  },
  action: GetSelect
) {
  switch (action.type) {
    case GET_LOCATION_SELECT_V3:
      return {
        data: action.data
      }
    default:
      return state
  }
}
