import { put, call } from 'redux-saga/effects'

import {
  REQUEST_WORKSPACE_DETAIL,
  REQUEST_WORKSPACE_LIST,
  getWorkspace,
  getWorkspaceList,
  REQUEST_WORKSPACE_DELETE,
  getWorkspaceDelete,
  REQUEST_WORKSPACE_EDIT,
  getWorkspaceEdit,
  REQUEST_WORKSPACE_NEW,
  getWorkspaceNew
} from 'actions/V3/workspace'
import { watchRequest, watchRequestNoCancel } from 'sagas/helper'
import { apiV3 } from 'utils/api'

function* request(args: { workspace_id: number }) {
  const { data } = yield call(apiV3, '/workspaces/' + args.workspace_id, 'get')

  yield put(getWorkspace(data))
  return data
}

export default watchRequestNoCancel(REQUEST_WORKSPACE_DETAIL, request)

function* requestWorkspacesNew(args: any) {
  const { data } = yield call(apiV3, '/workspaces', 'post', {
    workspace: args
  })
  yield put(getWorkspaceNew(data))
  return data
}

export const watchWorkspacesNew = watchRequest(REQUEST_WORKSPACE_NEW, requestWorkspacesNew)

function* requestWorkspacesList(args: any) {
  const { data } = yield call(apiV3, '/workspaces', 'get', {
    sort_field: 'id',
    sort_type: 'asc',
    org_id: args.org_id
  })
  yield put(getWorkspaceList(data))
  return data
}

export const watchWorkspacesList = watchRequestNoCancel(REQUEST_WORKSPACE_LIST, requestWorkspacesList)

function* requestWorkspaceDelete(args: any) {
  yield call(apiV3, '/workspaces/' + args.id, 'delete')
  yield put(getWorkspaceDelete(args))
  return args
}

export const watchWorkspaceDelete = watchRequest(REQUEST_WORKSPACE_DELETE, requestWorkspaceDelete)

function* requestWorkspaceEdit(args: any) {
  const { data } = yield call(apiV3, '/workspaces/' + args.id, 'patch', {
    workspace: { name: args.name, org_id: args.org_id }
  })
  yield put(getWorkspaceEdit(args))
  return data
}

export const watchWorkspaceEdit = watchRequest(REQUEST_WORKSPACE_EDIT, requestWorkspaceEdit)
