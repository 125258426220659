import cssVars from "css-vars-ponyfill";
const theme = require("../styles/theme.json");

cssVars({
  variables: theme["custom-properties"],
  onError: console.warn
});

if (typeof localStorage === "object") {
  try {
    localStorage.setItem("localStorage", "1");
    localStorage.removeItem("localStorage");
  } catch (e) {
    Storage.prototype = Storage.prototype.setItem;
    Storage.prototype.setItem = () => { };
    console.warn("not support localstorage");
  }
}

if (typeof sessionStorage === "object") {
  try {
    sessionStorage.setItem("sessionStorage", "1");
    sessionStorage.removeItem("sessionStorage");
  } catch (e) {
    Storage.prototype._setItem = Storage.prototype.setItem;
    Storage.prototype.setItem = () => { };
    console.warn("not support sessionStorage");
  }
}
