import * as React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { P, StateProps } from './type'
import { requestChangelog } from 'actions/V2/announcement'
import { t } from 'i18next'
import { Timeline } from 'antd'
import './index.css'

class Changelog extends React.PureComponent<P, {}> {
  componentDidMount() {
    const { dispatch } = this.props
    dispatch(requestChangelog())
  }
  render() {
    const { changelog } = this.props

    return (
      <div className='location-wrapper'>
        <div className='location-inner'>
          <div className='profile-container'>
            <h2 className='profile-container-title'>{t('CHANGE LOG')}</h2>
            <p className='profile-container-line' />
            <Timeline className='changelog-container changelog-content'>
              {changelog.map((log, i) => (
                <Timeline.Item key={`changelog-${i}`}>
                  <h2 className='changelog-title'>{log.title}</h2>
                  <div className='grey_text small_text changelog-date'>
                    {moment(log.created_at).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: log.content }} />
                </Timeline.Item>
              ))}
            </Timeline>
          </div>
        </div>
      </div>
    )
  }
}

const mapState: MapState<StateProps> = ({ V2: { announcement } }) => ({
  changelog: announcement.changelog
})

export default connect(mapState)(Changelog)
