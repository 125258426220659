import * as React from 'react';
import api from 'utils/api';
import Button from 'components/Custom/Button';
import cache from 'sagas/cache';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import SearchBar from 'components/SearchBar';
import { connect } from 'react-redux';
import {
    Dropdown,
    Input,
    Menu,
    message,
    Modal,
    notification,
    Pagination,
    Select,
    Tag,
    Tooltip
} from 'antd';
import { emailValidate } from 'utils/validate';
import { getCookie } from 'utils/auth';
import { Guard } from 'utils/guard';
import { P, State, StateProps } from './type';
import { pick, uniq } from 'lodash';
import { queryMerge } from 'utils/query';
import { refreshPermission } from 'actions/V3/permission';
import { REQUEST_ACCOUNTS } from 'actions/V2/accounts';
import { requestAccounts } from 'actions/V2/accounts';
import { requestUserShip } from 'actions/V2/userShip';
import { t } from 'i18next';
import { withRouter } from 'react-router-dom';
import './index.css';

@Guard
class People extends React.PureComponent<P, State> {
  state: State = {
      invite: false,
      modal: '',
      email: '',
      filterType: 'all',
      updatedPermission: {},
      removed: [],
      emails: [],
      access: 'regular'
  };

  resetCache = cache.resetCache.bind(null, REQUEST_ACCOUNTS);
  translation = {
      regular: t('people.regular'),
      admin: t('people.admin'),
      user: {
          all: t('people.allUser'),
          admin: t('people.adminUser'),
          regular: t('people.regularUser')
      }
  };

  componentDidMount() {
      const {
          location: { search }
      } = this.props;
      const firstElement = 1
      const query = pick(qs.parse(search.slice(firstElement)), ['page', 'name']);
      this.fetchAccounts({
        page: +(query.page || 0),
        name: query.name
      });
  }

  fetchAccounts = (query = {}) => {
      const {
          dispatch,
          match: {
              params: { id }
          }
      } = this.props;
      dispatch(
          requestAccounts({
              ...query,
              workspace_id: id
          })
      );
  };

  updatePermisson = async (userID: number, permission: 'admin' | 'regular') => {
      const {
          match: {
              params: { id }
          },
          dispatch
      } = this.props;

      this.resetCache();

      await api(`/user_ships/${userID}`, 'patch', {
          permission,
          workspace_id: id
      });
      const { updatedPermission } = this.state;
      this.setState({
          updatedPermission: {
              ...updatedPermission,
              [userID]: permission
          }
      });

      if (getCookie()!.id === userID) {
          dispatch(requestUserShip({ show: false }));
      }
  };

  removeEmail = (e, index) => {
      e.preventDefault();
      const { emails } = this.state;
      const clone = emails.slice();
      const replaceByFistElement = 1
      clone.splice(index, replaceByFistElement);
      this.setState({ emails: clone });
  };

  removeUser = async userID => {
      const {
          match: {
              params: { id }
          },
          dispatch
      } = this.props;

      this.resetCache();

      await api(`/user_ships/${userID}`, 'delete', { workspace_id: id });
      const { removed } = this.state;
      this.setState({ removed: [...removed, userID] });

      if (getCookie()!.id === userID) {
          this.props.history.replace('/');
          dispatch(requestUserShip({ show: false }));
      }

      dispatch(
          refreshPermission({
              show: false
          })
      );
  };

  pushEmail = () => {
      const { email, emails } = this.state;
      const max = this.maxMails();
      if (!email || emails.includes(email) || !max) {
          return;
      }

      const mails = uniq(
          email
              .split(',')
              .map(v => v.trim())
              .filter(v => v && !emails.includes(v))
      );

      const passed = mails.filter(m => emailValidate(m));
      const unPassed = mails.filter(m => !emailValidate(m));

      if (unPassed.length) {
          message.warn(t('people.email invalid', { mail: unPassed.join(',') }));
      }
      const sliceStart = 0
      this.setState({
          email: '',
          emails: emails.concat(passed).slice(sliceStart, max)
      });
  };

  Guard_inviteUsers = async () => {
      const { emails, access } = this.state;
      const {
          match: {
              params: { id }
          },
          dispatch
      } = this.props;
      this.resetCache();
      api('/user_ships', 'post', {
              email: emails,
              workspace_id: id,
              permission: access
      }).then(() => {

          notification.success({
              message: t('people.invite success'),
              description: t('people.join workspace', { count: emails.length })
          });
      }).catch ((error) => {
          const invalid = error.message.split(',');

          if(error.status === 'not_allowed') {
            notification.error({
                message: t('people.can not invite'),
                duration: null,
                description: (
                    <>
                        <p>{t('You do not have the permission to invite, update or remove an user!')}</p>
                    </>
                )
            });
            return
          }

          // FIXME: Kept for later when we properly validate the email in the API.
          if(false && error.status === 'user_email_invalid') {
            notification.error({
                message: t('people.can not invite'),
                duration: null,
                description: (
                    <>
                        <p>{t('Invalid E-Mail address.')}</p>
                    </>
                )
            });
            return
          }

          notification.error({
              message: t('people.can not invite'),
              duration: null,
              description: (
                  <>
                      <p>{t('people.this user')}</p>
                      <br />
                      {invalid.map((mail, index) => (
                          <p key={`error-mail-${index}`}>{`- ${mail}`}</p>
                      ))}
                      <br />
                      <p>{t('people.after create')}</p>
                  </>
              )
          });
      }).finally (() => {
          dispatch(
              refreshPermission({
                  show: false
              })
          );
          dispatch(
              requestAccounts({
                  workspace_id: id,
                  show: false
              })
          );
          this.setState({
              email: '',
              emails: [],
              invite: false
          });
      });
  };

  GuardHandle_addUser = (rule?: GuardRule, message?: string) => ({
      rule,
      message
  });

  addUser = () => {
      const cookie = getCookie()!;
      const isSystem = false && cookie.category === 'system';
      const addRule = this.GuardHandle_addUser();
      const enabled = addRule.rule && addRule.rule.enable;

      return isSystem || enabled ? (
          <div
              className='people-header-invite accent_text'
              onClick={() => this.setState({ invite: true })}
          >
              <i className='material-icons line_icon'>add_circle_outline</i>
              {t('people.Invite to Workspace')}
          </div>
      ) : (
          <Tooltip title={addRule.message}>
              <div className='people-header-invite grey_text is-disabled'>
                  <i className='material-icons line_icon'>add_circle_outline</i>
                  {t('people.Invite to Workspace')}
              </div>
          </Tooltip>
      );
  };

  GuardTrigger_userPermission = (account: any) => {
      const { updatedPermission } = this.state;

      return (
          <Dropdown
              trigger={['click']}
              overlay={this.menu(
                  account.id,
                  updatedPermission[account.id] || account.permission
              )}
              placement='bottomRight'
          >
              <div className='people-accounts-action'>
                  {
                      this.translation[
                          updatedPermission[account.id] || account.permission
                      ]
                  }
                  <i className='material-icons line_icon'>edit</i>
              </div>
          </Dropdown>
      );
  };

  GuardHandle_userPermission = (rule?: GuardRule) => {
      const { currentUserPermission } = this.props;
      return (getCookie()!.category === 'system' || (rule && rule.enable)) && currentUserPermission === 'admin';
  }

  menu = (id, permission) => {
      const cookie = getCookie()!;
      const self = cookie.id === id;
      return (
          <Menu>
              <Menu.Item key='0'>
                  <a
                      className={classNames('small_text', 'people-menu-item', {
                          'is-active': permission === 'admin'
                      })}
                      onClick={() => this.updatePermisson(id, 'admin')}
                  >
                      {permission === 'admin' && <i className='material-icons'>check</i>}
                      {this.translation.admin}
                  </a>
              </Menu.Item>

              <Menu.Item key='1'>
                  <a
                      className={classNames('small_text', 'people-menu-item', {
                          'is-active': permission === 'regular'
                      })}
                      onClick={() => {
                          if (self) {
                              this.setState({ modal: 'regular' });
                          } else {
                              this.updatePermisson(id, 'regular');
                          }
                      }}
                  >
                      {permission === 'regular' && (
                          <i className='material-icons'>check</i>
                      )}
                      {this.translation.regular}
                  </a>
              </Menu.Item>

              <Menu.Item key='3'>
                  <a
                      className='small_text danger_text people-menu-item'
                      onClick={() => {
                          if (self) {
                              this.setState({ modal: 'remove' });
                          } else {
                              this.removeUser(id);
                          }
                      }}
                  >
                      {t('Remove')}
                  </a>
              </Menu.Item>
          </Menu>
      );
  };

  filterMenu = () => {
      const { filterType } = this.state;
      return (
          <Menu>
              <Menu.Item key='0'>
                  <a
                      className={classNames('small_text', 'people-menu-item', {
                          'is-active': filterType === 'all'
                      })}
                      onClick={() => this.setState({ filterType: 'all' })}
                  >
                      {filterType === 'all' && <i className='material-icons'>check</i>}
                      {this.translation.user.all}
                  </a>
              </Menu.Item>
              <Menu.Item key='1'>
                  <a
                      className={classNames('small_text', 'people-menu-item', {
                          'is-active': filterType === 'admin'
                      })}
                      onClick={() => this.setState({ filterType: 'admin' })}
                  >
                      {filterType === 'admin' && <i className='material-icons'>check</i>}
                      {this.translation.user.admin}
                  </a>
              </Menu.Item>
              <Menu.Item key='2'>
                  <a
                      className={classNames('small_text', 'people-menu-item', {
                          'is-active': filterType === 'regular'
                      })}
                      onClick={() => this.setState({ filterType: 'regular' })}
                  >
                      {filterType === 'regular' && (
                          <i className='material-icons'>check</i>
                      )}
                      {this.translation.user.regular}
                  </a>
              </Menu.Item>
          </Menu>
      );
  };

  filter = () => {
      const { accounts } = this.props;
      const { filterType, removed } = this.state;
      return accounts
          .filter(v => !removed.includes(v.id))
          .filter(v => (filterType === 'all' ? true : v.permission === filterType))
      // .sort((a,b) => b.permission === 'admin' ? 1 : -1);
  };

  renderList = () => {
      const { updatedPermission } = this.state;
      const { query, count } = this.props;
      const firstIteration = 1
      const editable = this.GuardHandle_userPermission() && count > firstIteration;
      return this.filter().map((account, index) => (
          <div key={index} className='people-accounts'>
              <div className='people-accounts-message'>
                  <h4>
                      {(query as any).name ? (
                          <Highlighter
                              searchWords={[(query as any).name]}
                              autoEscape={true}
                              textToHighlight={account.name || ''}
                          />
                      ) : (
                          account.name
                      )}
                  </h4>
                  <p>
                      {(query as any).name ? (
                          <Highlighter
                              searchWords={[(query as any).name]}
                              autoEscape={true}
                              textToHighlight={account.email}
                          />
                      ) : (
                          account.email
                      )}
                  </p>
              </div>
              {editable ? (
                  this.GuardTrigger_userPermission(account)
              ) : (
                  <div className='people-accounts-action'>
                      {
                          this.translation[
                              updatedPermission[account.id] || account.permission
                          ]
                      }
                  </div>
              )}
          </div>
      ));
  };

  selectPage = page => {
      const {
          location: { search, pathname },
          history,
          query: q
      } = this.props;
      const firstPage = 1
      const query = queryMerge({
          search,
          query: { page },
          delKeys: page === firstPage ? ['page'] : []
      });
      history.push({
          pathname,
          search: query
      });
      this.fetchAccounts({ ...q, page });
  };

  search = (text: string): void => {
      const name = text.trim();
      const {
          location: { search, pathname },
          history
      } = this.props;

      const query = queryMerge({
          search,
          query: { name },
          delKeys: name ? ['page', 'size'] : ['name', 'page', 'size']
      });

      history.push({
          pathname,
          search: query
      });

      this.fetchAccounts({ name });
  };

  renderModalElement = () => {
      const { modal } = this.state;
      const id = getCookie()!.id;

      switch (modal) {
      case 'remove':
          return (
              <div className='colletion_modal-container'>
                  <h3 className='register-title'>{t('people.remove user')}</h3>
                  <div className='register-body'>
                      <p className='register-message small_text'>
                          {t('people.are you sure to remove yourself')}
                      </p>
                  </div>
                  <div className='colletion_modal-button_group'>
                      <Button
                          customType='light'
                          radius='rect'
                          onClick={() => this.setState({ modal: '' })}
                      >
                          {t('Cancel')}
                      </Button>
              &nbsp;&nbsp;
                      <Button
                          customType='danger'
                          radius='rect'
                          onClick={() => {
                              this.removeUser(id);
                              this.setState({ modal: '' });
                          }}
                      >
                          {t('Remove')}
                      </Button>
                  </div>
              </div>
          );
      case 'regular':
          return (
              <div className='colletion_modal-container'>
                  <h3 className='register-title'>{t('people.change permission')}</h3>
                  <div className='register-body'>
                      <p className='register-message small_text'>
                          {t('people.Are you sure to change yourself to regular')}
                      </p>
                  </div>
                  <div className='colletion_modal-button_group'>
                      <Button
                          customType='light'
                          radius='rect'
                          onClick={() => this.setState({ modal: '' })}
                      >
                          {t('Cancel')}
                      </Button>
              &nbsp;&nbsp;
                      <Button
                          customType='dark'
                          radius='rect'
                          onClick={() => {
                              this.updatePermisson(id, 'regular');
                              this.setState({ modal: '' });
                          }}
                      >
                          {t('Continue')}
                      </Button>
                  </div>
              </div>
          );
      default:
          return null;
      }
  };

  maxMails = () => {
      const addRule = this.GuardHandle_addUser();
      const { removed } = this.state;
      const { meta } = this.props;
      const emailLimit = 10
      if (!meta || !addRule.rule || addRule.rule.p) return emailLimit;

      try {
          const noMail = 0
          const resetMails = +addRule.rule.v - meta.total_count! + removed.length;
          return resetMails < noMail ? noMail : resetMails;
      } catch (error) {}
      return emailLimit;
  };

  render() {
      const { filterType, invite, emails, email, access, modal } = this.state;
      const passed = emails.every(e => emailValidate(e));
      const { meta, query } = this.props;
      const maxMails = this.maxMails();
      const modalWidth = 540
      const modalPadding = 0
      const paginationSize = 20
      const SelectFieldWidth = 130
      return (
          <>
              <div className='people-header'>
                  <div className='people-header-search'>
                      <SearchBar
                          onSearch={this.search}
                          defaultValue={query.name || ''}
                          containerClassName='people-search'
                          placeholder={t('people.searchUser')}
                      />

                      <Dropdown
                          trigger={['click']}
                          overlay={this.filterMenu()}
                          placement='bottomRight'
                      >
                          <div className='people-header-dropdown'>
                              {this.translation.user[filterType]}
                              <i className='material-icons line_icon'>arrow_drop_down</i>
                          </div>
                      </Dropdown>
                  </div>
                  {this.addUser()}
              </div>
              <div className='people-list'>{this.renderList()}</div>
              {meta && (
                  <Pagination
                      hideOnSinglePage={true}
                      showQuickJumper={true}
                      onChange={this.selectPage}
                      style={{ padding: '20px 0', textAlign: 'right' }}
                      current={meta.current_page}
                      defaultPageSize={paginationSize}
                      total={meta.total_count}
                  />
              )}

              <Modal
                  visible={invite}
                  title={
                      <>
                          <h3>{t('people.Invite users to workspace')}</h3>
                          <p className='small_text grey_text'>
                              {t('people.input One or Group')}
                          </p>
                      </>
                  }
                  centered={true}
                  onCancel={() => this.setState({ invite: false })}
                  footer={
                      <Button
                          onClick={this.Guard_inviteUsers}
                          disabled={!emails.length || !passed}
                          customType='dark'
                      >
                          {t('people.Send invite')}
                      </Button>
                  }
              >
                  <div className='people-invite-tags'>
                      {emails.map((e, index) => (
                          <Tag
                              key={`email-tag-${index}`}
                              closable={true}
                              color={emailValidate(e) ? 'blue' : 'magenta'}
                              onClose={event => this.removeEmail(event, index)}
                          >
                              {e}
                          </Tag>
                      ))}
                  </div>
                  <div className='people-invite-input'>
                      <Input
                          prefix={<span>To</span>}
                          value={email}
                          disabled={emails.length >= maxMails}
                          onBlur={this.pushEmail}
                          onChange={e => this.setState({ email: e.target.value })}
                          onPressEnter={this.pushEmail}
                          placeholder={t('people.Enter up to 10 email address(es)', {
                              max: maxMails
                          })}
                      />
                  </div>
                  <div className='people-invite-access'>
                      <p>{t('people.Access')}</p>
                      <Select
                          style={{ width: SelectFieldWidth }}
                          value={access}
                          onSelect={v => this.setState({ access: v as any })}
                      >
                          <Select.Option value='admin'>
                              {this.translation.admin}
                          </Select.Option>
                          <Select.Option value='regular'>
                              {this.translation.regular}
                          </Select.Option>
                      </Select>
                  </div>
              </Modal>

              <Modal
                  visible={!!modal}
                  centered={true}
                  onCancel={() => this.setState({ modal: '' })}
                  footer={null}
                  bodyStyle={{ padding: modalPadding }}
                  width={modalWidth}
              >
                  {this.renderModalElement()}
              </Modal>
          </>
      );
  }
}

const mapState: MapState<StateProps> = ({ V2: { accounts, userShip } }) => {
    // usership 数据有缓存，改变permission后未能及时更新权限状态
    const worksapce = userShip.data.find(w => w.id === +accounts.query.workspace_id)
    return {
        accounts: accounts.data,
        query: accounts.query,
        currentUserPermission: worksapce ? worksapce.permission : 'regular',
        meta: accounts.meta,
        count: accounts.count
    }
};

export default withRouter(connect(mapState)(People));
