// 创建纹理图案 40 x 40
export const createBgPattern = () => {
  const canvas = document.createElement('canvas')
  canvas.width = 40
  canvas.height = 40
  const ctx = canvas.getContext('2d')
  if (ctx) {
    ctx.beginPath()
    const ps = [
      [0, 0, 40, 40],
      [0, 10, 30, 40],
      [0, 20, 20, 40],
      [0, 30, 10, 40],
      [10, 0, 40, 30],
      [20, 0, 40, 20],
      [30, 0, 40, 10]
    ]
    ps.forEach(p => {
      ctx.moveTo(p[0], p[1])
      ctx.lineTo(p[2], p[3])
    })
    ctx.closePath()
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)'
    ctx.fillRect(0, 0, 40, 40)
    ctx.strokeStyle = '#ddd'
    ctx.lineWidth = 1
    ctx.stroke()
  }
  return canvas
}

// 渲染纹理图案到画布
export const drawPatternArea = (ctx, x, y, w, h) => {
  ctx.save()
  const img = createBgPattern()
  ctx.globalCompositeOperation = 'source-over'
  const pat = ctx.createPattern(img, 'repeat')
  ctx.fillStyle = pat
  ctx.fillRect(x, y, w, h)
  ctx.restore()
}

export const drawHighlightArea = (ctx, x, y, w, h) => {
  ctx.save()
  ctx.globalCompositeOperation = 'source-over'
  ctx.fillStyle = '#fff'
  ctx.fillRect(x, y, w, h)
  ctx.restore()
}

// 高亮区域外阴影渲染
export const drawRectOuterShadow = (ctx, x, y, w, h, ratio) => {
  ctx.save()
  ctx.globalCompositeOperation = 'source-over'
  const imgdata = ctx.getImageData(x * ratio, y * ratio, w * ratio, h * ratio)
  ctx.shadowColor = '#666'
  ctx.shadowBlur = 16
  ctx.lineWidth = 1
  ctx.strokeStyle = '#fff'
  ctx.strokeRect(x + 1, y + 1, w - 2, h - 2)
  ctx.putImageData(imgdata, x * ratio, y * ratio)
  ctx.restore()
}

// 由y轴数值计算对应y轴上的刻度所在position.top，左上角为起始坐标
export const calcYByVal = (top, heightY, maxYVal, val, startYVal) => {
  val = val > maxYVal ? maxYVal : val
  if (startYVal < 0) {
    maxYVal = maxYVal - startYVal
    val = val - startYVal
  }
  const pieceHeight = heightY / maxYVal
  const v = pieceHeight * val
  const absv = v > 0 ? v : 10
  return top + heightY - absv
}

interface CircleBadgeOpts {
  r?: number;
  color?: string;
  direction?: string;
}
// 渲染小圆点badge,白色外边框及外阴影
export const drawCircleBadge = (ctx, x, y, text, opts: CircleBadgeOpts = {}) => {
  const r = opts.r || 6
  const color = opts.color || '#D0021B'
  ctx.save()
  ctx.beginPath()
  ctx.globalCompositeOperation = 'source-over'
  ctx.strokeStyle = '#fff'
  ctx.fillStyle = '#fff'
  ctx.lineWidth = 1
  if (opts.direction) {
    ctx.arc(x, y, r, Math.PI * 0.75, 0.25 * Math.PI)
    ctx.lineTo(x, y + r * 1.5)
    const dr = Math.sqrt((r * r) / 2)
    ctx.lineTo(x - dr, y + dr)
  } else {
    ctx.arc(x, y, r, 0, 2 * Math.PI)
  }
  ctx.shadowOffsetY = 1
  ctx.shadowColor = '#666'
  ctx.shadowBlur = 3
  ctx.closePath()
  ctx.stroke()
  ctx.fill()
  ctx.restore()
  ctx.save()
  ctx.beginPath()
  ctx.strokeStyle = color
  ctx.fillStyle = color
  ctx.arc(x, y, r - 1, 0, 2 * Math.PI)
  ctx.closePath()
  ctx.stroke()
  ctx.fill()
  ctx.font = '8px Nunito'
  ctx.textBaseline = 'middle'
  ctx.fillStyle = '#fff'
  const textX = x - ctx.measureText(text).width / 2
  ctx.fillText(text, textX, y + 1)
  ctx.restore()
}

interface RoundRectOptions {
  fill?: string;
  stroke?: string;
  shadow?: string;
  rightArrow?: boolean;
}

export const drawRoundRect = (ctx, x, y, width, height, radius, opts: RoundRectOptions = {}) => {
  if (typeof radius === 'number') {
    radius = { tl: radius, tr: radius, br: radius, bl: radius }
  } else {
    const defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 }
    Object.keys(defaultRadius).forEach(side => {
      radius[side] = radius[side] || defaultRadius[side]
    })
  }
  ctx.beginPath()
  // ctx.moveTo(x + radius.tl, y);
  if (opts.rightArrow) {
    ctx.moveTo(x, y)
    ctx.lineTo(x + 5, y + height / 2)
    ctx.lineTo(x, y + height)
    ctx.lineTo(x - width + radius.bl, y + height)
    // 左下角弧线
    ctx.quadraticCurveTo(x - width, y + height, x - width, y + height - radius.bl)
    // 左边竖直线
    ctx.lineTo(x - width, y + radius.tl)
    // 左上角弧线
    ctx.quadraticCurveTo(x - width, y, x - width + radius.tl, y)
    // 顶部横直线
    ctx.lineTo(x, y)
  } else {
    ctx.moveTo(x, y)
    // 横直线
    ctx.lineTo(x + width - radius.tr, y)
    // 右上角弧线
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr)
    // 右边竖直线
    ctx.lineTo(x + width, y + height - radius.br)
    // 右下角弧线
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height)
    // 底部横直线
    ctx.lineTo(x, y + height)
    ctx.lineTo(x - height / 3, y + height / 2)
    ctx.lineTo(x, y)
  }
  ctx.closePath()
  ctx.save()
  if (opts.stroke) {
    ctx.strokeStyle = opts.stroke
    ctx.stroke()
  }
  if (opts.shadow) {
    ctx.shadowOffsetY = 1
    ctx.shadowColor = opts.shadow
    ctx.shadowBlur = 3
  }
  if (opts.fill) {
    ctx.fillStyle = opts.fill
    ctx.fill()
  }
  ctx.restore()
}

export const drawColourBar = (ctx, x, y, w, h, breakpoints) => {
  ctx.save()
  ctx.beginPath()
  const r = w / 2
  ctx.moveTo(x, y + r)
  ctx.arcTo(x, y, x + r, y, r)
  ctx.arcTo(x + w, y, x + w, y + r, r)
  ctx.lineTo(x + w, y + h)
  ctx.lineTo(x, y + h)
  ctx.lineTo(x, y)
  ctx.closePath()
  const gradientColor = ctx.createLinearGradient(0, h, 0, 0)
  breakpoints.forEach(bp => {
    gradientColor.addColorStop(parseFloat(bp[0]), bp[1])
  })
  ctx.fillStyle = gradientColor
  ctx.fill()
  ctx.restore()
}

export const drawVerticalGreenLine = (ctx, left, top) => {
  ctx.beginPath()
  ctx.strokeStyle = '#37B30C'
  ctx.moveTo(left + 411, top + -5)
  ctx.lineTo(left + 411, top + 95)
  ctx.stroke()
  ctx.closePath()
}

export const drawArrowLabel = (ctx, left, top, text, arrowDirection = 'DOWN_RIGHT') => {
  ctx.save()
  ctx.font = '10px Nunito'
  ctx.textBaseline = 'top'
  let w = ctx.measureText(text).width + 6
  let offsetX = 0
  if (w < 30) {
    offsetX = 15 - w / 2
    w = 30
  }
  const h = 16
  let x = left - w
  if (arrowDirection === 'DOWN_LEFT') {
    x = left
  }
  let y = top
  ctx.beginPath()
  // direction down right
  switch (arrowDirection) {
    case 'DOWN_RIGHT':
      y = top - 30
      ctx.moveTo(x + 2, y)
      ctx.lineTo(x + w - 2, y)
      ctx.arcTo(x + w, y, x + w, y + 2, 2)
      ctx.lineTo(x + w, y + h + h / 3)
      ctx.lineTo(x + w - h / 3, y + h)
      ctx.lineTo(x + 2, y + h)
      ctx.arcTo(x, y + h, x, y + h - 2, 2)
      ctx.lineTo(x, y + 2)
      ctx.arcTo(x, y, x + 2, y, 2)
      break
    case 'RIGHT':
      ctx.moveTo(x + 2, y)
      ctx.lineTo(x + w + h / 3, y)
      ctx.lineTo(x + w, y + h / 3)
      ctx.lineTo(x + w, y + h - 2)
      ctx.arcTo(x + w, y + h, x + w - 2, y + h, 2)
      ctx.lineTo(x + 2, y + h)
      ctx.arcTo(x, y + h, x, y + h - 2, 2)
      ctx.lineTo(x, y + 2)
      ctx.arcTo(x, y, x + 2, y, 2)
      break
    case 'DOWN_LEFT':
      y = top - 30
      ctx.moveTo(x + 2, y)
      ctx.lineTo(x + w - 2, y)
      ctx.arcTo(x + w, y, x + w, y + 2, 2)
      ctx.lineTo(x + w, y + h)
      ctx.arcTo(x + w, y + h, x, y + h - 2, 2)
      ctx.lineTo(x + 2 + h / 3, y + h)
      ctx.lineTo(x, y + h + h / 3)
      ctx.lineTo(x, y + 2)
      ctx.arcTo(x, y, x + 2, y, 2)
      break
    default:
      break
  }
  ctx.closePath()
  ctx.fillStyle = '#9B9B9B'
  ctx.fill()
  ctx.fillStyle = '#fff'
  ctx.fillText(text, x + 3 + offsetX, y + 3)
  ctx.restore()
}

export const drawStandrdLine = (ctx, x1, y1, x2, y2, val, label) => {
  ctx.save()
  //  label
  ctx.font = 'bold 8px Nunito'
  ctx.fillStyle = '#ffffff'
  ctx.textBaseline = 'middle'
  const lw = ctx.measureText(label).width
  const rectWidth = lw + 8
  drawRoundRect(ctx, x2 - rectWidth, y2 - 8, rectWidth, 16, 3, {
    fill: window.PRIMARY_COLOR,
    stroke: window.PRIMARY_COLOR
  })
  ctx.fillText(label, x2 - lw - 5, y2 + 1)

  // value
  ctx.font = 'bold 14px Nunito'
  ctx.fillStyle = '#ffffff'
  const w = ctx.measureText(val).width
  drawRoundRect(ctx, x1 - 18, y1 - 10, w + 8, 16, 3, {
    fill: window.PRIMARY_COLOR,
    stroke: window.PRIMARY_COLOR,
    rightArrow: true
  })
  ctx.fillText(val, x1 - w - 20, y1)
  ctx.restore()
  ctx.save()

  // line
  ctx.lineWidth = 1
  ctx.setLineDash([7])
  ctx.strokeStyle = window.PRIMARY_COLOR
  ctx.moveTo(x1, y1)
  ctx.lineTo(x2 - rectWidth - 15, y2)
  ctx.stroke()
  ctx.restore()
}

export const drawBreakPointLabel = (ctx, x, y, text, rightArrow = false) => {
  const h = 14
  y = y - h / 2
  ctx.font = '8px Nunito'
  const w = ctx.measureText(text).width
  drawRoundRect(ctx, x, y, w + 8, h, 3, {
    fill: '#fff',
    stroke: '#fff',
    shadow: '#666',
    rightArrow
  })
  ctx.save()
  ctx.fillStyle = '#4A4A4A'
  ctx.textBaseline = 'top'
  let textX = x + 3
  if (rightArrow) {
    textX = x - w - 4
  }
  ctx.fillText(text, textX, y + 3)
  ctx.restore()
}
