import { put, call } from 'redux-saga/effects';
import { getDictionaryData, REQUEST_DICTIONARY } from 'actions/V2/dictionary';
import { watchRequestNoCancel } from 'sagas/helper';
import i18n from 'configs/i18n';
import { entries } from 'lodash';
import { formatZoneToOffset } from 'utils/timeZone';
import api from 'utils/api';
import { Timer } from '../../utils/enums'

const getCache = (name: string) => {
    try {
        const json = localStorage.getItem(name);

        if (!json) {
            return;
        }

        const data: { expire: number; data: any } = JSON.parse(json);
        return Date.now() > data.expire ? undefined : data.data;
    } catch (e) {
        localStorage.removeItem(name);
    }
};

function* request(args: { name: string }) {

    const cacheName = `${args.name}-${i18n.language}`;
    const cache = getCache(cacheName);
    if (cache) {
        yield put(getDictionaryData(args.name, cache));
        return cache;
    }

    let { data } = yield call(api, `/dictionaries/${args.name}`, 'get');

    if (args.name === 'time_zones') {
        const obj = {};
        entries(data as { [p: string]: string }).forEach(([area, timeZone]) => {
            const offset = formatZoneToOffset(timeZone);
            if (obj[offset]) {
                if (obj[offset][timeZone]) {
                    obj[offset][timeZone].push(area);
                } else {
                    obj[offset][timeZone] = [area];
                }
            } else {
                obj[offset] = { [timeZone]: [area] };
            }
        });
        data = obj;
    }

    const cacheString = JSON.stringify({
        expire: Date.now() + Timer.SECOND * Timer.MINUTE * Timer.HOUR,
        data
    });

    localStorage.setItem(cacheName, cacheString);
    yield put(getDictionaryData(args.name, data));
    return data;
}

export default watchRequestNoCancel(REQUEST_DICTIONARY, request);
