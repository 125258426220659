import React from 'react'
import moment from 'moment'
import { t } from 'i18next'
import { KioskCollection, KioskDetail } from '../../actions/V3/kiosks'
import CovidKioskLeftSide from './CovidKioskLeftSide'
import CovidDoughnutChart from './CovidDoughutChart'
import ClickableCanvas from './ClickableCanvas'
import { CurrentCovidIndicators } from './type';

interface CovidIndexKioskProps {
  kiosk: KioskDetail,
  collectionsList: KioskCollection[]
}
export default function CovidIndexKiosk({ kiosk, collectionsList }: CovidIndexKioskProps) {
  const [covidIndicators, setCovidIndicators] = React.useState<CurrentCovidIndicators>(kiosk.average || kiosk.collections[0]);

  const returnChart = React.useMemo(() => {
    return covidIndicators && covidIndicators.covid_index_data
      ? <CovidDoughnutChart covidIndicators={covidIndicators}/>
      : null
  }, [covidIndicators])

  const { background_image, location_name, city_name, covid_index_supplementary_data, time_zone } = kiosk

  return (
    <div className='kiosk-bodyV3'>
      <div
        className='kiosk-bodyV3 kiosk-blurred-element'
        style={{
          background: `no-repeat linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url(${background_image} )`,
          backgroundSize: 'cover'
        }}
      />
      <div className='kiosk-page v2'>
        <CovidKioskLeftSide collectionsList={collectionsList}
                            kiosk={kiosk}
                            covidIndicators={covidIndicators}
                            setCovidIndicators={setCovidIndicators} />
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative'
          }}
          className='kiosk-page--group-panel-right g-right'
        >
          <p className='ResetViralTitle'>{t('kioskV3.title')}</p>
          <p className='ResetViralOfficeName'>{location_name}</p>
          <p className='ResetViralOfficeCity'>{city_name}</p>
          {covid_index_supplementary_data &&
          covid_index_supplementary_data.data.every(
            (covidIndicator: Record<string, any>) => covidIndicator.value !== null
          ) ? (
            <div style={{ margin: '1vh 0 1vh 0' }} className='kiosk-page--transmission-risk'>
              <ClickableCanvas />
              {returnChart}
              <p className='LastUpdateCentered'>{t('kioskV3.lastUpdate')}</p>
              <p className='LastUpdateCenteredTime'>{moment().format('hh:mm A')}</p>
              <p className='LastUpdateCentered'>{time_zone}</p>
            </div>
          ) : (
            <div className='empty-data-template' />
          )}
        </div>
      </div>
    </div>
  )
}
