import * as React from 'react'
import { connect } from 'react-redux'
import { t } from 'i18next'
import { Icon, Divider, Tooltip, Pagination } from 'antd'
import { P, State, StateProps } from 'components/WorkspaceModals/type'
import { entries, isEmpty, isArray } from 'lodash'
import { translations } from 'utils/constant'
import { withRouter } from 'react-router-dom'
import './index.css'
import moment from 'moment'
import { requestActiveLog } from 'actions/V3/activeLog'
import { titleCase } from 'change-case'

class ActivityLogs extends React.PureComponent<P, State> {
  state: State = {
    collapsed: []
  }

  componentDidMount() {
    this.fetchLogs()
  }

  toggleDetail = (id: number) => {
    const { collapsed } = this.state
    this.setState({
      collapsed: {
        ...collapsed,
        [id]: !collapsed[id]
      }
    })
  }

  fetchLogs = (query = {}, workspaceID?: number) => {
    const {
      dispatch,
      match: {
        params: { id }
      }
    } = this.props
    dispatch(
      requestActiveLog({
        ...query,
        item_type: 'all',
        workspace_id: workspaceID || +id
      })
    )
  }

  selectPage = page => {
    this.fetchLogs({ page })
  }

  render() {
    const { logs, meta } = this.props

    return (
      <div>
        <h2 className='workspace-ov-activity-title accent_text'>{t('overview.Activity')}</h2>
        <Divider />
        {logs.map((log, i) => {
          const showDetail = log.event !== 'destroy' && log.details && !isEmpty(log.details)
          const { collapsed } = this.state
          const show = collapsed[log.id]
          return (
            <React.Fragment key={`log-${i}`}>
              <div className='workspace-ov-activity-line'>
                <div className='workspace-ov-activity-message'>
                  {showDetail ? (
                    <Icon
                      onClick={() => this.toggleDetail(log.id)}
                      type={show ? 'minus-circle' : 'plus-circle'}
                      className='workspace-ov-activity-icon'
                    />
                  ) : (
                    <span className='workspace-ov-activity-icon' />
                  )}
                  <span className='workspace-ov-activity-name'>{log.user_name}</span>
                  <span className='workspace-ov-activity-event'>{translations[log.event] || log.event}</span>
                  <Tooltip title={`${translations[log.item_type] || log.item_type}: ${log.item_id}`}>
                    <span className='workspace-ov-activity-type'>
                      {translations[log.item_type] || log.item_type}
                      {log.item_name && ` - ${log.item_name}`}
                    </span>
                  </Tooltip>
                </div>

                <span className='workspace-ov-activity-date'>{moment(log.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
              </div>
              {showDetail && show && (
                <div className='workspace-ov-activity-detail'>
                  {entries(log.details).map(([key, value], index) => {
                    if (isArray(value)) {
                      return (
                        <div className='workspace-ov-activity-message'>
                          <span className='grey_text'>{` - ${log.user_name}`}</span>
                          <span className='workspace-ov-activity-event'>{translations[log.event] || log.event}</span>
                          <span className='workspace-ov-activity-type'>{`${titleCase(key)}: ${value[0]} -> ${value[1]}`}</span>
                        </div>
                      )
                    }

                    return <p key={`details-${index}`}>{`${titleCase(key)}: ${value}`}</p>
                  })}
                </div>
              )}
            </React.Fragment>
          )
        })}
        {meta && (
          <Pagination
            size='small'
            onChange={this.selectPage}
            hideOnSinglePage={true}
            style={{ textAlign: 'right', marginTop: 20 }}
            current={meta.current_page}
            defaultPageSize={20}
            total={meta.total_count}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({
  uiState: {
    siteState: { workspaceID }
  },
  V3: { activeLog }
}) => ({
  logs: activeLog.data,
  meta: activeLog.meta,
  query: activeLog.query,
  workspaceID
})

export default withRouter(connect(mapStateToProps)(ActivityLogs))
