/* eslint @typescript-eslint/no-var-requires: "off" */
const en = require('./en/translation.json')
const zh = require('./zh/translation.json')

const Translations = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
}

export default Translations
