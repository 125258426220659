import { Action, ActionCreator } from 'redux'

export const REQUEST_ADMIN_PARAMS_BANNER = 'REQUEST_ADMIN_PARAMS_BANNER'
export const GET_ADMIN_PARAMS_BANNER = 'GET_ADMIN_PARAMS_BANNER'

export type BannerData = {
  show_technical_difficulties_banner: 'false'|'true'
}

export interface GetAdminParamsBannerAction extends Action<typeof GET_ADMIN_PARAMS_BANNER> {
  data: BannerData;
}

export type RequestBannerAction = Action<typeof REQUEST_ADMIN_PARAMS_BANNER>

export const requestBanner: ActionCreator<RequestBannerAction> = args => ({
  type: REQUEST_ADMIN_PARAMS_BANNER,
  args
})

export const getAdminParamsBanner = (data): GetAdminParamsBannerAction => ({
  type: GET_ADMIN_PARAMS_BANNER,
  data
})
