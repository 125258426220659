import * as React from 'react'
import Billing from 'pages/Billing'
import BillingDetail from 'pages/Billing/detail'
import WorkspaceDetail from 'pages/Billing/workspaceDetail'
import { Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { BasicDeny } from 'components/Permission'

type StateProps = {
  hasPro: boolean
}

class BillingRoute extends React.PureComponent<StateProps, {}> {
  billingRoute = () => (
    <Switch>
      <Route exact={true} path='/billing/:id/detail/:billing_id' component={BillingDetail} />
      <Route exact={true} path='/billing/:id/workspaces/:workspace_id' component={WorkspaceDetail} />
      <Route path='/billing/:id' component={Billing} />
    </Switch>
  )

  render() {
    const { hasPro } = this.props
    return hasPro ? this.billingRoute() : <BasicDeny />
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { org } }) => {
  const hasPro = !!org.detail
  return {
    hasPro
  }
}

export default connect(mapStateToProps)(BillingRoute)
