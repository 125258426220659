import { CertificationAction, GET_CERTIFICATIONS, CertificationObj } from 'actions/V2/certifications'

export type CertificationsState = {
  data: CertificationObj[]
}

export default function Certifications(
  state: CertificationsState = {
    data: []
  },
  action: CertificationAction
) {
  switch (action.type) {
    case GET_CERTIFICATIONS:
      return {
        data: action.certifications
      }
    default:
      return state
  }
}
