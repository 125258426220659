import { GetActivitynAction, GET_ACTIVITY_CITY, ActivityCity } from 'actions/V2/activities'

export type ActivitiesState = {
  data?: ActivityCity
}

export default function Activities(state: ActivitiesState = {}, action: GetActivitynAction) {
  switch (action.type) {
    case GET_ACTIVITY_CITY:
      return {
        data: action.activities
      }
    default:
      return state
  }
}
