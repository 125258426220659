import { LocationItem } from 'actions/V3/locationList'
import { Icon, Tooltip } from 'antd'
import classNames from 'classnames'
import { t } from 'i18next'
import indoor from 'images/Indoor Placeholder.png'
import outdoor from 'images/Location Photo.png'
import { uniq } from 'lodash'
import * as React from 'react'
import Img from 'react-image'
import LazyLoad from 'react-lazyload'
import { indicatorShortName, LEVEL_DICT } from 'utils/constant'
import { parseDigit } from 'utils/helper'
import air_icon from '../../images/icons/air_icon@3x.png'
import electricity_icon from '../../images/icons/electricity_icon@3x.png'
import light_icon from '../../images/icons/light_icon@3x.png'
import lock_icon from '../../images/icons/locked_ENABLED@3x.png'
import public_icon from '../../images/icons/public_ENABLED@3x.png'
import sound_icon from '../../images/icons/sound_icon@3x.png'
import './index.css'

const icons = {
  air: air_icon,
  electricity: electricity_icon,
  light: light_icon,
  sound: sound_icon,
  public: public_icon,
  lock: lock_icon
}

const Card: React.SFC<{
  data: LocationItem,
  onClick: () => void
}> = React.memo(({ data, onClick }) => {
  const category = {
    indoor: t('filter.indoor'),
    outdoor: t('filter.outdoor')
  }
  const hideInfo = data.type === 'public'
  const translation = LEVEL_DICT()
  return (
    <div
      className={classNames('locationcard-container', {
        public: hideInfo
      })}
      onClick={onClick}
    >
      <div className='locationcard-background'>
        <LazyLoad height={120}>
          <Img
            className='locationcard-background-img'
            loader={
              <div className='locationcard-background-loader'>
                <div>
                  <Icon type='loading' />
                </div>
                <p className='small_text grey_text'>{t('loading.in load')}</p>
              </div>
            }
            src={[data.background_image, data.category === 'indoor' ? indoor : outdoor]}
          />
        </LazyLoad>
        {hideInfo && (
          <Tooltip title={data.name}>
            <div className='locationcard-title'>{data.name}</div>
          </Tooltip>
        )}
      </div>
      {!hideInfo && (
        <div className='locationcard-type'>
          <span className='f10_text'>{category[data.category] || data.category}</span>
          <div className='locationcard-type-ability'>
            {data.average &&
              uniq(data.average.readings.map(v => v.type))
                .concat(Array.from({ length: 4 }))
                .slice(0, 4)
                .map((v, i) => <img key={`card-ablity-${i}`} src={v ? icons[v] : ''} alt='' />)}
          </div>
        </div>
      )}
      {!hideInfo && (
        <div className='locationcard-indicator'>
          <Tooltip title={data.name}>
            <span className='locationcard-indicator-title'>{data.name}</span>
          </Tooltip>

          <div className='locationcard-indicator-row'>
            {data.pro &&
              data.average &&
              data.average.readings
                .filter(v => v.value !== null)
                .slice(0, 3)
                .map((v, i) => (
                  <div className='locationcard-indicator-item' key={`location-indicator-${i}`}>
                    <Tooltip title={v.name}>
                      <p className='locationcard-indicator-name'>{indicatorShortName[v.data_channel] || v.name}</p>
                    </Tooltip>
                    <p className='locationcard-indicator-value'>{parseDigit(v.value)}</p>
                  </div>
                ))}
          </div>
        </div>
      )}
      <div
        className={classNames('locationcard-status', {
          [`is-${data.average ? data.average.performance : 'basic'}`]:
            data.pro && data.average && !!data.average.performance && data.average.status !== 'offline'
        })}
      >
        {data.pro && data.average ? (
          data.average.status === 'offline' ? (
            <div className='locationcard-status-offline-text'>{translation[data.average.status]}</div>
          ) : (
            <>
              <div className='locationcard-status-mask' data-text={data.average.status ? translation[data.average.status] : '--'} />
              <div className='locationcard-status-performance'>{translation[data.average.performance] || '--'}</div>
            </>
          )
        ) : (
          <div className='locationcard-status-performance'>--</div>
        )}
      </div>
    </div>
  )
})
export default Card
