import * as React from 'react'
import { Redirect, RedirectProps } from 'react-router-dom'
import { connect, DispatchProp } from 'react-redux'
import { updateSiteState, RecordSiteStateAction } from 'actions/uiState'

type P = RedirectProps & DispatchProp<RecordSiteStateAction>

class RecordRedirect extends React.PureComponent<P, {}> {
  constructor(props: P) {
    super(props)
    const { href, origin } = window.location
    props.dispatch(
      updateSiteState({
        redirectURL: href.replace(origin, '')
      })
    )
  }

  render() {
    return <Redirect {...this.props} />
  }
}

export default connect()(RecordRedirect)
