import { Action, ActionCreator } from 'redux';
export const CHANGE_ICON_MODAL = 'CHANGE_ICON_MODAL';
export const RECORD_SITE_STATE = 'RECORD_SITE_STATE';
export const CHANGE_WORKSPACE = 'CHANGE_WORKSPACE';

declare global {
  type ModalType =
    | ''
    | 'AssignList'
    | 'AssignTable'
    | 'SelectLocation'
    | 'AddLocation'
    | 'AddCollection'
    | 'AddDatasource'
    | 'AddIndicator'
    | 'ActivityLogs';
}

export type SiteState = {
  workspaceID?: string;
  locationID?: string;
  indicatorID?: string;
  collectionID?: string;
  datasourceID?: string;
  settingsURL?: string;
  collectionsURL?: string;
  indicatorsURL?: string;
  datasourcesURL?: string;
  peopleURL?: string;
  redirectURL?: string;
  originURL?: string;
  showNavbar?: boolean;
  showNoteBar?: boolean;
};

export interface ModalChangeAction extends Action<typeof CHANGE_ICON_MODAL> {
  modal: ModalType;
}

export interface RecordSiteStateAction
  extends Action<typeof RECORD_SITE_STATE> {
  siteState: SiteState;
}

export interface ChangeWorkspaceAction extends Action<typeof CHANGE_WORKSPACE> {
  id: string;
}

export type UiAction = ModalChangeAction | RecordSiteStateAction;

export const changeModal = (modal: ModalType): ModalChangeAction => ({
    type: CHANGE_ICON_MODAL,
    modal
});

export const updateSiteState = (
    siteState: SiteState
): RecordSiteStateAction => ({
    type: RECORD_SITE_STATE,
    siteState
});

export const changeWorkspace: ActionCreator<ChangeWorkspaceAction> = (
    id: string
) => ({
    type: CHANGE_WORKSPACE,
    id
});
