const ShanghaiTower49F = 205766

export const JPMCKioskAccess: number[] = [ShanghaiTower49F]

export const keys = [
  'background_image',
  'background_video',
  'enable_background_video',
  'data_panel_position',
  'data_panel_opacity',
  'theme_color',
  'font_color',
  'font',
  'logo_image',
  'locked',
  'password',
  'background_full_screen',
  'use_aqi',
  'hide_location_average'
]
