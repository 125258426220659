import {
    BillingAction,
    GET_ORG_BILLINGS_V3,
    BillingObj,
    GET_ORG_BILLINGS_OV_V3,
    BillingOV,
    GET_BILLING_DETAIL_V3,
    WorkspaceBillingObj,
    GET_WORKSPACE_BILLING_V3,
    GET_WORKSPACE_BILLING_DETAIL_V3,
    GET_MONTH_BILLING_V3
} from 'actions/V3/billing';

export type BillingState = {
  list: {
    data: BillingObj[];
    meta?: Meta;
    query?: any;
  };
  ov?: BillingOV;
  detail: {
    data: BillingObj[];
    meta?: Meta;
    query?: any;
  };
  month: {
    data: BillingObj[];
    meta?: Meta;
    query?: any;
  };
  workspaceBillings: {
    data: WorkspaceBillingObj[];
    meta?: Meta;
    query?: any;
  };
  workspaceBillingDetail: {
    data: BillingObj[];
    meta?: Meta;
    query?: any;
  };
};

export default function Billing(
    state: BillingState = {
        list: {
            data: []
        },
        detail: {
            data: []
        },
        month: {
            data: []
        },
        workspaceBillings: {
            data: []
        },
        workspaceBillingDetail: {
            data: []
        }
    },
    action: BillingAction
) {
    switch (action.type) {
    case GET_ORG_BILLINGS_V3:
        return {
            ...state,
            list: {
                data: action.billings,
                meta: action.meta,
                query: action.query
            }
        };
    case GET_ORG_BILLINGS_OV_V3:
        return {
            ...state,
            ov: action.billingOV
        };
    case GET_BILLING_DETAIL_V3:
        return {
            ...state,
            detail: {
                data: action.detail,
                meta: action.meta,
                query: action.query
            }
        };
    case GET_MONTH_BILLING_V3:
        return {
            ...state,
            month: {
                data: action.data,
                meta: action.meta,
                query: action.query
            }
        };
    case GET_WORKSPACE_BILLING_V3:
        return {
            ...state,
            workspaceBillings: {
                data: action.data,
                meta: action.meta,
                query: action.query
            }
        };
    case GET_WORKSPACE_BILLING_DETAIL_V3:
        return {
            ...state,
            workspaceBillingDetail: {
                data: action.data,
                meta: action.meta,
                query: action.query
            }
        };
    default:
        return state;
    }
};
