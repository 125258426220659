import * as Cookies from 'js-cookie';
import { pick } from 'lodash';
import { UserInfo } from 'actions/V2/userSign';
const host = window.location.host || window.location.hostname;
const { NODE_ENV: ENV } = process.env;
const cookieName = ENV === 'production' ? 'qlear' : 'qlear-testing';
const domain = window.location.port ? 'localhost' : `.${host}`;

type UserCookie = {
  email: string;
  auth_token: string;
  category: 'regular' | 'system';
  id: number;
  user_name: string;
  logout_url: string | undefined;
};

export const isLogin = (): boolean => {
    const cookieValue = Cookies.get(cookieName);
    if (!cookieValue) {
        return false;
    }
    try {
        const cookie = JSON.parse(cookieValue);
        if (!cookie.auth_token) {
            return false;
        }
        return true;
    } catch (e) {
        return false;
    }
};

export const getCookie = (): UserCookie | undefined => {
    return isLogin() ? (Cookies.getJSON(cookieName) as UserCookie) : undefined;
};

export const setCookie = (obj: UserInfo, expiredays: number) => {
    const data = pick(obj, [
        'user_name',
        'auth_token',
        'id',
        'category',
        'email'
    ]);
    Cookies.remove(cookieName);
    Cookies.set(cookieName, data, {
        expires: expiredays,
        path: '/',
        domain
    });
    return null;
};

export const cleanCookie = () => {
    Cookies.remove(cookieName);
    Cookies.set(cookieName, '', {
        path: '/',
        expires: 0,
        domain
    });
    if (host !== 'qlear.io' && ENV !== 'development') {
        Cookies.set(cookieName, '', {
            path: '/',
            expires: 0,
            domain: '.qlear.io'
        });
    }
};

export const getLogoutUrl = (): string | undefined => {
    const cookie = getCookie();
    if(!cookie) {
        return undefined;
    }
    return cookie.logout_url;
};
