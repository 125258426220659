import { DownloadAction, GET_DOWNLOADS, DownloadObj } from 'actions/V2/downloads'

export type DownloadsState = {
  data: DownloadObj[],
  totalCount: number
}

export default function Downloads(
  state: DownloadsState = {
    data: [],
    totalCount: 0
  },
  action: DownloadAction
) {
  switch (action.type) {
    case GET_DOWNLOADS:
      return {
        data: action.downloads,
        totalCount: action.totalCount
      }
    default:
      return state
  }
}
