const Levels = {
  'PM 2.5': function pm2p5UpperCase(level: string, unit: string) {
    switch (level) {
      case 'good':
        return `0 ~ 35 ${unit}`
      case 'moderate':
        return `36 ~ 75 ${unit}`
      case 'sensitive':
        return `76 ~ 100 ${unit}`
      case 'unhealthy':
        return `101 ~ 120 ${unit}`
      case 'very-unhealthy':
        return `121 ~ 200 ${unit}`
      case 'hazardous':
        return `more than 200 ${unit}`
      default:
        return ''
    }
  },
  pm2p5(level: string, unit: string) {
    switch (level) {
      case 'good':
        return `0 ~ 35 ${unit}`
      case 'moderate':
        return `36 ~ 75 ${unit}`
      case 'sensitive':
        return `76 ~ 100 ${unit}`
      case 'unhealthy':
        return `101 ~ 120 ${unit}`
      case 'very-unhealthy':
        return `121 ~ 200 ${unit}`
      case 'hazardous':
        return `more than 200 ${unit}`
      default:
        return ''
    }
  },
  'PM 10': function pm10UpperCase(level: string, unit: string) {
    switch (level) {
      case 'good':
        return `0 ~ 50 ${unit}`
      case 'moderate':
        return `51 ~ 150 ${unit}`
      case 'sensitive':
        return `151 ~ 250 ${unit}`
      case 'unhealthy':
        return `251 ~ 350 ${unit}`
      case 'very-unhealthy':
        return `351 ~ 420 ${unit}`
      case 'hazardous':
        return `more than 420 ${unit}`
      default:
        return ''
    }
  },
  humidity(level: string, unit: string) {
    switch (level) {
      case 'good':
        return `0 ~ 55 ${unit}`
      case 'moderate':
        return `56 ~ 65 ${unit}`
      case 'sensitive':
        return `66 ~ 75 ${unit}`
      case 'unhealthy':
        return `76 ~ 85 ${unit}`
      case 'very-unhealthy':
        return `86 ~ 90 ${unit}`
      case 'hazardous':
        return `91 ~ 100 ${unit}`
      default:
        return ''
    }
  },
  tvoc(level: string, unit: string) {
    switch (level) {
      case 'good':
        return `0 ~ 0.45 ${unit}`
      case 'moderate':
        return `0.46 ~ 0.55 ${unit}`
      case 'unhealthy':
        return `0.56 ~ 1 ${unit}`
      default:
        return ''
    }
  },
  HCHO(level: string, unit: string) {
    switch (level) {
      case 'good':
        return `0 ~ 0.072 ${unit}`
      case 'moderate':
        return `0.073 ~ 0.088 ${unit}`
      case 'unhealthy':
        return `0.089 ~ 1 ${unit}`
      default:
        return ''
    }
  },
  co2(level: string, unit: string) {
    switch (level) {
      case 'excellent':
        return `0 ~ 600 ${unit}`
      case 'good':
        return `601 ~ 1000 ${unit}`
      case 'moderate':
        return `1001 ~ 1500 ${unit}`
      case 'sensitive':
        return `1501 ~ 2500 ${unit}`
      case 'impaired':
        return `2501 ~ 5000 ${unit}`
      case 'unhealthy':
        return `more than 5000 ${unit}`
      default:
        return ''
    }
  },
  temperature() {
    return ''
  }
}

export const levelColor = (level: string): string => {
  switch (level) {
    case 'online':
      return '#0b75a9'
    case 'good':
      return '#21b5bb'
    case 'hazardous':
      return '#590103'
    case 'moderate':
      return '#f7e5a8'
    case 'sensitive':
      return '#fdd694'
    case 'unhealthy':
      return '#f47944'
    case 'very-unhealthy':
      return '#de392c'
    case 'excellent':
      return '#25bdc3'
    default:
      return '#a5a5a5'
  }
}

export default Levels
