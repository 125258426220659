import { GetLogAction, GET_ACTIVE_LOG_V3, LogItem } from 'actions/V3/activeLog'

export type ActiveLogState = {
  data: LogItem[],
  meta?: Meta,
  query?: any
}

export default function ActiveLog(
  state: ActiveLogState = {
    data: []
  },
  action: GetLogAction
) {
  switch (action.type) {
    case GET_ACTIVE_LOG_V3:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        query: action.query
      }
    default:
      return state
  }
}
