import * as React from 'react'
import LocationList from 'containers/LocationList'
import NavLinkContaier from 'containers/NavLinkContainer'
import { t } from 'i18next'

const DashboardLocation: React.SFC<{}> = React.memo(() => (
  <NavLinkContaier active='favorites'>
    <React.Fragment>
      <div className='home-tab-header'>
        <div className='home-tab-label'>{t('Favorites')}</div>
      </div>
      <LocationList type='dashboard' />
    </React.Fragment>
  </NavLinkContaier>
))

export default DashboardLocation
