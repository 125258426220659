import * as React from 'react'
import Button from 'components/Custom/Button'
import { apiV3 } from 'utils/api'
import { Form, Input } from 'antd'
import { t } from 'i18next'
import './index.css'
import { FormComponentProps } from 'antd/lib/form'
import { KioskLock } from 'actions/V3/kiosks'

type Props = {
  kiosk: KioskLock,
  tyrLogin: () => void,
  request: () => any
}

type P = FormComponentProps & Props

const KioskForm: React.SFC<P> = React.memo(props => {
  const {
    form: { getFieldDecorator, validateFields },
    kiosk,
    tyrLogin,
    request
  } = props

  const handleSubmit = e => {
    e.preventDefault()
    validateFields(async (err, values) => {
      if (!err) {
        try {
          const { data, meta } = await apiV3<any>(`/kiosks/${kiosk.id}/unlock`, 'post', values)
          if (data) {
            const token = meta.unlock_token
            localStorage.setItem(`kiosk-${kiosk.id}`, token)
            request()
          }
        } catch (error) {}
      }
    })
  }

  return (
    <div className='kiosk-body'>
      <div className='kiosk-page is-pwd'>
        <div
          className='kiosk-page--container-with-background'
          style={{
            backgroundImage: kiosk.background_image ? `url(${kiosk.background_image})` : undefined
          }}
        />
        <div className='kiosk-permision-dialog'>
          <Form onSubmit={handleSubmit} hideRequiredMark={true} layout='vertical' className='kiosk-pwd-form'>
            <div className='location-name'>{kiosk.name}</div>
            <div className='pwd-tip'>{t('kiosk.lock message')}</div>
            <Form.Item className='kiosk-input-group' label={t('password')} hasFeedback={false}>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: t('user.Please input your Password')
                  }
                ]
              })(<Input placeholder={t('enter your password')} />)}
            </Form.Item>
            <div className='kiosk-btn-group'>
              <a onClick={tyrLogin} className='link'>
                {t('kiosk.login')}
              </a>
              <Button customType='dark' htmlType='submit'>
                {t('Confirm')}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
})

export default Form.create<any>()(KioskForm)
