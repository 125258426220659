import { LocationAction, GET_LOCATION, GET_OUTDOOR, LocationObj, Outdoor } from 'actions/V2/location'

export type LocationState = {
  data?: LocationObj,
  outdoor?: Outdoor
}

export default function Location(state: LocationState = {}, action: LocationAction) {
  switch (action.type) {
    case GET_LOCATION:
      return {
        ...state,
        data: action.location
      }
    case GET_OUTDOOR:
      return {
        ...state,
        outdoor: action.outdoor
      }
    default:
      return state
  }
}
