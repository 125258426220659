import { Action, ActionCreator } from 'redux'
export const REQUEST_COLLECTIONS_V3 = 'REQUEST_COLLECTIONS_V3'
export const GET_COLLECTIONS_V3 = 'GET_COLLECTIONS_V3'
export const REQUEST_COLLECTION_DETAIL_V3 = 'REQUEST_COLLECTION_DETAIL_V3'
export const GET_COLLECTIONS_DETAIL_V3 = 'GET_COLLECTIONS_DETAIL_V3'
export const REQUEST_COLLECTION_FOR_SELECTOR_V3 = 'REQUEST_COLLECTION_FOR_SELECTOR_V3'
export const GET_COLLECTION_FOR_SELECTOR_V3 = 'GET_COLLECTION_FOR_SELECTOR_V3'

export type CollectionItem = {
  abbr: string,
  code: string,
  id: number,
  name: string,
  indicators: {
    name: string,
    data_channel: string,
    data_channel_name: string,
    data_source_id: number,
    data_source_identifier: string,
    id: number
  }[],
  locations: {
    id: number,
    name: string,
    category: LocationCategory
  }[],
  notes?: string,
  reading: {
    last_reading_time?: string,
    stale: boolean,
    status?: string,
    data_channels: {
      data_channel: string,
      level?: string,
      name: string,
      type: DataChannelType,
      unit: string,
      value?: number
    }[]
  }
}
export interface RequestCollectionAction extends Action<typeof REQUEST_COLLECTIONS_V3> {
  args: any;
}

interface GetCollectionAction extends Action<typeof GET_COLLECTIONS_V3> {
  data: CollectionItem[];
  meta: Meta;
  query: {};
}

export interface RequestCollectionDetailAction extends Action<typeof REQUEST_COLLECTION_DETAIL_V3> {
  args: any;
}

interface GetCollectionDetailAction extends Action<typeof GET_COLLECTIONS_DETAIL_V3> {
  data: CollectionItem;
}

export interface RequestCollectionForSelectorAction extends Action<typeof REQUEST_COLLECTION_FOR_SELECTOR_V3> {
  args: any;
}

interface GetCollectionForSelectorAction extends Action<typeof GET_COLLECTION_FOR_SELECTOR_V3> {
  data: CollectionItem[];
}

export type CollectionAction = GetCollectionAction | GetCollectionDetailAction | GetCollectionForSelectorAction

export const requestCollection = (args: {
  workspace_id: number,
  location_id?: number,
  name?: string,
  page?: number,
  size?: number,
  show?: boolean,
  sort_field?: string,
  sort_type?: 'asc' | 'desc'
}): RequestCollectionAction => ({
  type: REQUEST_COLLECTIONS_V3,
  args
})

export const getCollection: ActionCreator<GetCollectionAction> = (data, meta, query) => ({
  type: GET_COLLECTIONS_V3,
  data,
  meta,
  query
})

export const requestCollectionDetail = (args: { collection_id: number }): RequestCollectionDetailAction => ({
  type: REQUEST_COLLECTION_DETAIL_V3,
  args
})

export const getCollectionDetail: ActionCreator<GetCollectionDetailAction> = data => ({
  type: GET_COLLECTIONS_DETAIL_V3,
  data
})

export const requestCollectionForSelector = (args): RequestCollectionForSelectorAction => ({
  type: REQUEST_COLLECTION_FOR_SELECTOR_V3,
  args
})

export const getCollectionForSelector: ActionCreator<GetCollectionForSelectorAction> = data => ({
  type: GET_COLLECTION_FOR_SELECTOR_V3,
  data
})
