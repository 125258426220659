import * as React from 'react'
import { Select, Input, message } from 'antd'
import Button from 'components/Custom/Button'
import './modal.css'
import { t } from 'i18next'
import { formulas } from 'utils/constant'

const Option = Select.Option
type ButtonAction = () => void
type ButtonActionConfirm = (data: any) => void

interface StateProps {
  handleCancel: ButtonAction;
  handleConfirm: ButtonActionConfirm;
  calibration: any;
}

type State = Readonly<{
  rows: { start_value: string, end_value: string, config: string }[],
  factorValue: string,
  formula: string
}>

export class Calibration extends React.Component<StateProps> {
  state: State = {
    rows: [{ start_value: '', end_value: '', config: '' }],
    factorValue: '',
    formula: ''
  }

  componentDidMount() {
    const { calibration } = this.props
    if (calibration.formula) {
      this.setState({
        rows: calibration.factors,
        factorValue: this.createString(calibration.formula),
        formula: calibration.formula
      })
    }
  }

  updateFormula = newValue => {
    const str = this.createString(newValue)
    const { rows } = this.state
    const newRows = rows.map(v => {
      const item = v
      item.config = str
      return item
    })
    this.setState({
      formula: newValue,
      factorValue: str,
      rows: newRows
    })
  }

  createString = formula => {
    const Reg1 = /[^(x|y]\w(?=\*)/g
    let str = ''
    const obj = {}
    formula.match(Reg1).forEach(item => {
      obj[item.trim()] = 1
    })
    const words = formula.match(/[a-z]/g).filter(item => item !== 'x' && item !== 'y')
    words.forEach((key, index) => {
      const lastCaracter = 1
      if (obj[key.trim()]) {
        str += `${key.trim()}:1.0, `
      } else if (index === words.length - lastCaracter) {
        str += `${key.trim()}:0.0`
      } else {
        str += `${key.trim()}:0.0, `
      }
    })
    return str
  }

  removeRow = idx => {
    const { rows } = this.state
    this.setState({ rows: rows.filter((item, index) => index !== idx) })
  }

  addRow = () => {
    const { rows, factorValue } = this.state
    if (!factorValue) {
      rows.push({ start_value: '', end_value: '', config: '' })
    } else {
      rows.push({ start_value: '-1', end_value: '-1', config: factorValue })
    }
    this.setState({ rows })
  }

  onChange = (e, i, type) => {
    const { rows } = this.state
    rows[i][type] = e.target.value
    this.setState({ rows })
  }

  checkInput = () => {
    const { rows } = this.state
    const startValue: string[] = []
    const endValue: string[] = []
    const factors: string[] = []
    for (let i = 0; i < rows.length; i++) {
      if (!rows[i].start_value || !rows[i].end_value || !rows[i].config) {
        return {
          type: false,
          result: t("Use '-1' in Min and Max Value to ignore it")
        }
      }
      if (Number.isNaN(+rows[i].start_value) || Number.isNaN(+rows[i].end_value)) {
        return {
          type: false,
          result: t('Start & End must be a Number')
        }
      }

      const configArr = rows[i].config.split(',')
      for (let j = 0; j < configArr.length; j++) {
        const value = Number(configArr[j].replace(/\w:/, ''))
        if (Number.isNaN(value)) {
          return {
            type: false,
            result: t('Factor value must be a Number')
          }
        }
      }
      startValue.push(rows[i].start_value)
      endValue.push(rows[i].end_value)
      factors.push(rows[i].config)
    }

    return {
      type: true,
      startValue,
      endValue,
      factors
    }
  }

  updateCalibration = () => {
    const { handleConfirm } = this.props
    const { formula } = this.state
    const checkObject = this.checkInput()
    if (!checkObject.type) {
      message.error(checkObject.result)
      return
    }
    if (!formula) {
      message.error(t('Formula must be chosen'))
      return
    }

    const obj = {
      formula,
      start_value: checkObject.startValue,
      end_value: checkObject.endValue,
      factors: checkObject.factors
    }

    handleConfirm(obj)
  }

  renderRow = () => {
    const { rows } = this.state

    return rows.map((row, i) => (
      <div key={i} className='datasource_modal-calibration-content'>
        <div className='datasource_modal-calibration-factor'>
          <Input size='small' placeholder='i.e. “a:0.1, b:0.0”' value={row.config} onChange={e => this.onChange(e, i, 'config')} />
        </div>
        <div className='datasource_modal-calibration-value'>
          <Input size='small' value={row.end_value} onChange={e => this.onChange(e, i, 'end_value')} />
        </div>
        <div className='datasource_modal-calibration-value'>
          <Input size='small' value={row.start_value} onChange={e => this.onChange(e, i, 'start_value')} />
        </div>
        <a onClick={() => this.removeRow(i)} className='material-icons datasource_modal-calibration-delete danger_text'>
          delete
        </a>
      </div>
    ))
  }

  render() {
    const { handleCancel } = this.props
    return (
      <div className='datasource_modal-calibration'>
        <div className='datasource_modal-calibration-row'>
          <div className='datasource_modal-calibration-label'>{t('Formula')}</div>
          <div className='datasource_modal-calibration-content'>
            <Select
              size='small'
              className='datasource_modal-calibration-select'
              placeholder={t('datasource.Select')}
              value={this.state.formula}
              onChange={this.updateFormula}
            >
              {formulas.map((item, i) => (
                <Option key={i} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        </div>

        <div className='datasource_modal-calibration-row is-text'>
          <div className='datasource_modal-calibration-label' />
          <div className='datasource_modal-calibration-content'>
            <div className='datasource_modal-calibration-factor'>{t('Factor')}</div>
            <div className='datasource_modal-calibration-value'>{t('Max Value')}</div>
            <div className='datasource_modal-calibration-value'>{t('Min Value')}</div>
          </div>
        </div>

        <div className='datasource_modal-calibration-row'>
          <div className='datasource_modal-calibration-label'>{t('Factors')}</div>
          <div className='datasource_modal-calibration-factors'>{this.renderRow()}</div>
        </div>

        <div className='datasource_modal-calibration-row'>
          <div className='datasource_modal-calibration-label' />
          <div className='datasource_modal-calibration-content'>
            <div className='datasource_modal-calibration-factor'>
              <p className='accent_text collection-add_text' onClick={this.addRow}>
                <i className='material-icons'>add</i>
                {t('Add Factor')}
              </p>
            </div>
          </div>
        </div>

        <div className='datasource_modal-calibration-button_group'>
          &nbsp; &nbsp; &nbsp;
          <Button customType='light' size='small' onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          &nbsp; &nbsp;
          <Button customType='dark' size='small' onClick={this.updateCalibration}>
            {t('Save')}
          </Button>
        </div>
      </div>
    )
  }
}
