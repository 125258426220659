export const covidDoughnutPlugin = {
  beforeDraw(chart: any) {
    const {
      ctx,
      chartArea: {top},
      options: {plugins: {covidDoughnut}}
    } = chart;
    const chartCircles = {
      arcStart:0,
      arcEnd: 2 * Math.PI,
      smallRadius: 80,
      largeRadius: 110
    }
    const halfWidth = chart.width / 2
    const halfHeight = chart.height / 2
    const {chartQuarters, covidIndex, covidIndicator} = covidDoughnut
    const chartColors = {
      green: '#37B30C',
      orange: '#FDA802',
      red: '#B53B43',
      lightBlue: 'rgba(241, 245, 251, 0.9)'
    }

    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = chartColors.lightBlue;
    ctx.arc(
      halfWidth,
      halfHeight,
      chartCircles.smallRadius,
      chartCircles.arcStart,
      chartCircles.arcEnd
    );
    ctx.closePath();
    ctx.fill();

    const chartCenter = {x: 155, y: 115};
    const angleStart = [0.7, 1, -0.5, 0]; // [0.5, 1, -0.5, 0] for a full circle

    const quadrants = [
      {
        angleStart: Math.PI * angleStart[0], // 0.5 is the bottom
        angleEnd: Math.PI * chartQuarters[0], // 1 is the left
        x1: chartCenter.x,
        y1: chartCenter.y + chartCircles.largeRadius,
        x2: chartCenter.x - chartCircles.largeRadius,
        y2: chartCenter.y,
        colorStops: [
          {stop: 0, color: chartColors.red},
          {stop: 1, color: chartColors.red}
        ]
      },
      {
        angleStart: Math.PI * angleStart[1], // 1 is the left
        angleEnd: Math.PI * chartQuarters[1], // 1.5 is the top
        x1: chartCenter.x - chartCircles.largeRadius,
        y1: chartCenter.y,
        x2: chartCenter.x,
        y2: chartCenter.y - chartCircles.largeRadius,
        colorStops: [
          {stop: 0, color: chartColors.red},
          {stop: 1, color: chartColors.orange}
        ]
      },
      {
        angleStart: Math.PI * angleStart[2], // -0.5 is the top
        angleEnd: chartQuarters[2], // 0 is the right
        x1: chartCenter.x,
        y1: chartCenter.y - chartCircles.largeRadius,
        x2: chartCenter.x + chartCircles.largeRadius,
        y2: chartCenter.y,
        colorStops: [
          {stop: 0, color: chartColors.orange},
          {stop: 1, color: chartColors.green}
        ]
      },
      {
        angleStart: angleStart[3], // 0 is the right
        angleEnd: Math.PI * chartQuarters[3], // 0.3 is the bottom
        x1: chartCenter.x + chartCircles.largeRadius,
        y1: chartCenter.y,
        x2: chartCenter.x,
        y2: chartCenter.y + chartCircles.largeRadius,
        colorStops: [
          {stop: 0, color: chartColors.green},
          {stop: 1, color: chartColors.green}
        ]
      }
    ];

    //black circle line
    ctx.beginPath();
    ctx.lineCap = 'round'; //round the end of arc
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 3;
    ctx.arc(
      halfWidth,
      halfHeight,
      chartCircles.largeRadius,
      0.9,
      0.7 * Math.PI,
      true
    );
    ctx.stroke();
    ctx.closePath();
    for (let i = 0; i < quadrants.length; ++i) {
      const quad = quadrants[i];
      const grad = ctx.createLinearGradient(quad.x1, quad.y1, quad.x2, quad.y2);
      // Color stops.
      for (let j = 0; j < quad.colorStops.length; ++j) {
        const cs = quad.colorStops[j];
        grad.addColorStop(cs.stop, cs.color);
      }
      // Draw arc.
      ctx.beginPath();
      ctx.arc(
        halfWidth,
        halfHeight,
        chartCircles.largeRadius,
        Math.PI * angleStart[i],
        quad.angleEnd,
        false
      );
      ctx.strokeStyle = grad;
      ctx.lineWidth = 8;
      ctx.stroke();
      ctx.closePath();
    }

    //white circle around the "i"
    ctx.beginPath();
    ctx.fillStyle = 'rgba(241, 245, 251, 0.99)';
    ctx.arc(
      halfWidth,
      halfHeight + 90,
      18,
      0,
      chartCircles.arcEnd
    );
    ctx.closePath();
    ctx.fill();

    //dark "i" in the bottom center
    ctx.beginPath();
    ctx.fillStyle = 'gray';
    ctx.font = 'bolder 30px Nunito';
    ctx.fillText(
      'i',
      halfWidth,
      halfHeight + 100
    );
    ctx.closePath();
    ctx.fill();

    //big cicle text content
    ctx.beginPath();
    ctx.fillStyle = 'black';
    ctx.font = 'bold 55px Nunito';
    ctx.textAlign = 'center';
    ctx.fillText(
      covidIndex + '%',
      halfWidth,
      top + halfHeight
    );
    ctx.closePath();
    ctx.fill();
    ctx.beginPath();
    ctx.fillStyle = 'black';
    ctx.font = 'bolder 30px Nunito';
    ctx.fillText(
      covidIndicator,
      halfWidth,
      top + 45 + halfHeight
    );
    ctx.closePath();
    ctx.fill();
    ctx.restore();

  }
};
