import { combineReducers } from 'redux'
import location, { LocationState } from 'reducers/V3/location'
import downloads, { DownloadsState } from 'reducers/V3/downloads'
import workspace, { WorkspaceState } from 'reducers/V3/workspace'
import kiosks, { KiosksState } from 'reducers/V3/kiosks'
import collections, { CollectionState } from 'reducers/V3/collections'
import indicators, { IndicatorState } from 'reducers/V3/indicators'
import datasources, { DatasourcesState } from 'reducers/V3/datasources'
import search, { SearchState } from 'reducers/V3/search'
import notificationConfig, { NotificationLimitState } from 'reducers/V3/notificationConfig'
import locationList, { LocationListState } from 'reducers/V3/locationList'
import userConfig, { UserConfigState } from 'reducers/V3/userConfig'
import activeLog, { ActiveLogState } from 'reducers/V3/activeLog'
import locationSelect, { LocationSelectState } from 'reducers/V3/locationSelect'
import config, { ConfigState } from 'reducers/V3/config'
import tabledata, { TableDataState } from 'reducers/V3/tabledata'
import report, { ReportState } from 'reducers/V3/report'
import org, { OrgState } from 'reducers/V3/org'
import permission, { PermissionState } from 'reducers/V3/permission'
import billing, { BillingState } from 'reducers/V3/billing'
import follows, { FollowListState } from 'reducers/V3/following'
import adminParams, { AdminParamsState } from 'reducers/V3/adminParams'

export interface StoreStateV3 {
  location: LocationState;
  downloads: DownloadsState;
  workspace: WorkspaceState;
  kiosks: KiosksState;
  collections: CollectionState;
  indicators: IndicatorState;
  datasources: DatasourcesState;
  search: SearchState;
  // notification: NotificationState;
  locationList: LocationListState;
  notificationConfig: NotificationLimitState;
  userConfig: UserConfigState;
  activeLog: ActiveLogState;
  locationSelect: LocationSelectState;
  config: ConfigState;
  tabledata: TableDataState;
  report: ReportState;
  org: OrgState;
  permission: PermissionState;
  billing: BillingState;
  follows: FollowListState;
  adminParams: AdminParamsState;
}

export default combineReducers<StoreStateV3>({
  location,
  downloads,
  workspace,
  kiosks,
  collections,
  indicators,
  datasources,
  tabledata,
  search,
  // notification,
  locationList,
  notificationConfig,
  userConfig,
  activeLog,
  locationSelect,
  config,
  report,
  org,
  permission,
  billing,
  follows,
  adminParams
})
