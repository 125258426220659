import {
    DatasourcesAction,
    GET_DATA_SOURCES_V3,
    DatasourceObj,
    Brand,
    Calibration,
    DatasourceDetailObj,
    GET_DATA_SOURCE_DETAIL_V3,
    GET_DATA_SOURCE_EDIT_V3,
    GET_DATA_SOURCE_DELETE_V3,
    GET_DATA_CHANNEL_EDIT_V3,
    GET_DATA_CHANNEL_CALIBRATION_V2,
    GET_DATA_SOURCE_BRAND_V3
} from 'actions/V3/datasources'

export type DatasourcesState = {
  data: DatasourceObj[],
  meta?: Meta,
  query?: {},
  detail?: DatasourceDetailObj,
  brands: Brand[],
  calibrations: Calibration[]
}

export default function Datasources(
    state: DatasourcesState = {
        data: [],
        brands: [],
        calibrations: []
    },
    action: DatasourcesAction
) {
    switch (action.type) {
    case GET_DATA_SOURCES_V3:
        return {
            ...state,
            data: action.datasources,
            meta: action.meta,
            query: action.query
        }
    case GET_DATA_SOURCE_DETAIL_V3:
        return Object.assign({}, state, {
            detail: action.datasource
        })
    case GET_DATA_SOURCE_EDIT_V3:
        //const detail = Object.assign(state.detail, action.datasource)
        return Object.assign(state, {
            detail: action.datasource
        })
    case GET_DATA_SOURCE_DELETE_V3:
        return Object.assign({}, state, {
            detail: null
        })
    case GET_DATA_CHANNEL_EDIT_V3:
        const chId = Number(action.datachannel.id)
        let detail2
        if (state.detail && state.detail.channels) {
            const channels = state.detail.channels.slice(0)
            const idx = channels.findIndex(ch => ch.id === chId)
            channels[idx] = action.datachannel
            detail2 = Object.assign({}, state.detail, { channels })
        }
        return Object.assign({}, state, {
            detail: detail2
        })
    case GET_DATA_CHANNEL_CALIBRATION_V2:
        return { ...state, calibrations: action.calibrations }
    case GET_DATA_SOURCE_BRAND_V3:
        return {
            ...state,
            brands: action.brands
        }
    default:
        return state
    }
}
