import * as actions from 'actions/V3/permission'
import { apiV3 } from 'utils/api'
import { call, put, select } from 'redux-saga/effects'
import { watchRequestNoCancel } from 'sagas/helper'

const selector = ({
  V3: {
    permission: { query }
  }
}) => query

function* requestList(args: any) {
  const { data, meta } = yield call(apiV3, '/users/permissions', 'get', {
    ...args
  })

  //
  // data.push({
  //   "key": "IconGroup.add_indicator",
  //   "name": "assign indicator",
  //   "c": "limit",
  //   "t": "number",
  //   "s": "workspace",
  //   "o": "",
  //   "v": "",
  //   "b": true,
  //   "p": true,
  //   "cl": null,
  //   "cv": null,
  //   "enable": true,
  //   "use": ["function", "handle"]
  // })

  yield put(actions.getUserPermission(data, meta, args))
  return data
}

export const permission = watchRequestNoCancel(actions.REQUEST_USER_PERMISSION_V3, requestList)

function* refreshList() {
  const args = yield select(selector)
  const { data, meta } = yield call(apiV3, '/users/permissions', 'get', {
    ...args
  })

  yield put(actions.getUserPermission(data, meta, args))
  return data
}

export const refreshPermission = watchRequestNoCancel(actions.REFRESH_USER_PERMISSION_V3, refreshList)
