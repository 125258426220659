import i18next, { i18n } from 'i18next'
import LngDetector from 'i18next-browser-languagedetector'
import Cache from 'i18next-localstorage-cache'
import resources from 'translations'
import moment from 'moment'
import 'moment/locale/zh-cn'

const lngDetector = new LngDetector()
lngDetector.addDetector({
  name: 'qlearDetector',
  lookup(options: { order: string[] }) {
    const order = options.order.slice(1)
    const res = lngDetector.detect(order)
    return res.includes('zh') ? 'zh' : 'en'
  }
})

const i18nInstance: i18n = i18next
  .use(lngDetector)
  .use(Cache)
  .init({
    detection: {
      order: ['qlearDetector', 'querystring', 'cookie', 'localStorage', 'navigator'],
      lookupQuerystring: 'lang',
      lookupCookie: 'i18next',
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage', 'cookie'],
      excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
      cookieMinutes: 10,
      cookieDomain: '/'
    },
    cache: {
      enabled: process.env.NODE_ENV !== 'development',
      prefix: 'i18next_res_',
      expirationTime: 1 * 24 * 60 * 60 * 1000,
      versions: {}
    },
    fallbackLng: ['en', 'zh'],
    debug: process.env.NODE_ENV === 'development',
    resources
  })

if (i18nInstance.language.includes('zh')) {
  // moment.updateLocale('zh-cn', cnLocale);
  moment.locale('zh-cn')
} else {
  moment.locale('en-US')
}

moment.relativeTimeThreshold('s', 59)
moment.relativeTimeThreshold('m', 59)
moment.relativeTimeThreshold('h', 23)
moment.relativeTimeThreshold('d', 28)
moment.relativeTimeThreshold('M', 12)

export default i18nInstance
