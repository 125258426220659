import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { t } from 'i18next';
import qs from 'qs';
import { notification, message } from 'antd';
import i18n from 'configs/i18n';
import { SuggestLogin } from 'components/Notification';
import { apiPath, V2Url } from 'utils/host';
import { isLogin, getCookie } from 'utils/auth';
import { history } from 'index';
import { pick } from 'lodash';

export const stack: any[] = [];
const maxSize = 10;
const whilteList = ['/exceptions'];

const pushStack = (data: any) => {
    if (stack.length === maxSize) {
        stack.shift();
    }
    stack.push(data);
};

const getAxiosClient = async () => {
    const base = await apiPath();
    const V2 = axios.create({
        baseURL: `${base}/v2/`,
        paramsSerializer(params: object) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }
    });

    const V3 = axios.create({
        baseURL: `${base}/v3/`
    });

    V2.interceptors.request.use(function (config) {
        const req = pick(config, ['baseURL', 'data', 'method', 'params', 'url']);
        if (req.url && !whilteList.includes(req.url)) {
            pushStack(req);
        }
        return config;
    });

    V3.interceptors.request.use(function (config) {
        const req = pick(config, ['baseURL', 'data', 'method', 'params', 'url']);
        if (req.url && !whilteList.includes(req.url)) {
            pushStack(req);
        }
        return config;
    });

    return {
        V2,
        V3
    };
};

const clients = getAxiosClient();

export const resHandle = (res: AxiosResponse) => {
    if (res.data && res.data.meta) {
        switch (res.data.meta.code) {
        case 10000:
        case 10023:
            return res.data;
        case 10001:
            notification.error({
                duration: null,
                key: 'global Error',
                message: `code: ${res.data.meta.code} ${res.data.meta.message}`,
                description: !!res.data.meta.auth_token
                    ? t('exception.accessDeny')
                    : React.createElement(SuggestLogin)
            });
            break;
        case 10004:
            notification.error({
                message: res.data.meta.message || t('Password Error')
            });
            return Promise.reject({ ...res.data.meta, reject: true });

        case 10005:
            history.replace('/404', {
                title: '404',
                message: `
          ${t('exception.notfound')}
          <p class='small_text'>from: ${window.location.href}</p>
          `
            });
            break;
        case 10007:
            return Promise.reject({ ...res.data.meta, reject: true });
        case 10009:
        case 10013:
            notification.error({
                duration: null,
                key: 'global Error',
                message: `code: ${res.data.meta.code} ${res.data.meta.message}`,
                description: React.createElement(SuggestLogin)
            });
            break;
            // case 10023:
            //   // Kiosk Lock
            //   return Promise.reject({
            //     ...res.data.meta,
            //     data: res.data.data,
            //     reject: true
            //   });
        case 10019:
        case 10050:
            if (res.data.meta.status !== 'covid_index_input_set_not_found') {
                message.error(`code: ${res.data.meta.code} ${res.data.meta.message}`);
            }
            return Promise.reject({ ...res.data.meta, reject: true });
        case 10015:
        // kiosk not found
        // history.replace('/404', {
        //   title: '404',
        //   message: `
        //   ${t('exception.notfound')}
        //   <p class='small_text'>from: ${window.location.href}</p>
        //   `
        // });
            return Promise.reject({ ...res.data.meta, reject: true });
        case 30002:
            window.location.href = V2Url();
            break;
        case 400:
        case 403:
            return Promise.reject({ ...res.data.meta, reject: false });
        default:
            message.error(`code: ${res.data.meta.code} ${res.data.meta.message}`);
            return Promise.reject({ ...res.data.meta, reject: false });
        }
        return Promise.reject({ ...res.data.meta, reject: false });
    }
    message.error('Application error: Unrecognized response from server.');

    return Promise.reject(`status: ${res.status} ${res.statusText}`);
};

export const requestMeta = () => {
    const c: any = {
        access_token: process.env.REACT_APP_APP_TOKEN || '88c04de6-a690-41ac-823f-0bf07581efe3',
        source: 'qlear-web-v3',
        locale: i18n.language,
        v: window.QLEAR_VERSION
    };

    if (isLogin()) {
        c.auth_token = getCookie()!.auth_token;
        c.upgrade = true;
    }

    return c;
};

const api = <T extends any>(
    url: string,
    method: 'post' | 'patch' | 'delete' | 'put' | 'get' = 'get',
    data?: any
): Promise<T> => {
    const config = requestMeta();

    if (['post', 'patch', 'put'].includes(method)) {
        return clients.then(({ V2 }) =>
            V2[method as string](url, {
                ...data,
                ...config
            })
            .then(resHandle)
            // FIXME: Set workaround for dealing with the stupidity of people pretending to be developer.
            .catch(err => {
                return resHandle(err.response);
            })
        );
    }

    return clients.then(({ V2 }) =>
        V2[method as string](url, {
            params: {
                ...data,
                ...config
            }
        })
        .then(resHandle)
        // FIXME: Set workaround for dealing with the stupidity of people pretending to be developer.
        .catch(err => {
            return resHandle(err.response);
        })
    );
};

export default api;

export const apiV3 = <T extends any>(
    url: string,
    method: 'post' | 'patch' | 'delete' | 'put' | 'get' = 'get',
    data?: any
): Promise<T> => {
    const config = requestMeta();

    // const headers = isLogin()
    //   ? { Authorization: `Token token=${btoa(getCookie()!.auth_token)}` }
    //   : {};
    const headers = {};

    if (['post', 'patch', 'put'].includes(method)) {
        return clients.then(({ V3 }) =>
            V3[method as string](
                url,
                {
                    ...data,
                    ...config
                },
                {
                    headers
                }
            )
            .then(resHandle)
            // FIXME: Set workaround for dealing with the stupidity of people pretending to be developer.
            .catch(err => {
                return resHandle(err.response);
            })
        );
    }

    return clients.then(({ V3 }) =>
        V3[method as string](url, {
            headers,
            params: {
                ...data,
                ...config
            }
        })
        .then(resHandle)
        // FIXME: Set workaround for dealing with the stupidity of people pretending to be developer.
        .catch(err => {
            return resHandle(err.response);
        })
    );
};
