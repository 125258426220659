import * as React from 'react';
import { Tooltip, Switch, Popconfirm } from 'antd';
import Select, { Option } from 'components/Custom/Select';
import Button from 'components/Custom/Button';
import { t } from 'i18next';
import Reset from '-!svg-react-loader!images/svg/reset_logo.svg';
import Arc from 'images/arc-logo_white.png';
import screen1 from 'images/screenshot1.png';
import screen2 from 'images/screenshot2.png';
import screen3 from 'images/screenshot3.png';
import { LocationEdit } from 'actions/V3/location';
import { apiV3, requestMeta } from 'utils/api';
import { apiPath } from 'utils/host';
import qs from 'qs';
import { debounce } from 'lodash';
import CustomSpinner from 'components/Custom/Spinner';
import moment from 'moment';
import i18n from 'configs/i18n';

interface ApiResponse {
    data: any;
}

export type Status = 'list' | 'reset' | 'arc';
export type ArcStatus =
    | 'no-auth'
    | 'authed'
    | 'successed'
    | 'failed'
    | 'pending';

type ArcDetail = {
    created_at: string;
    expired_at: string;
    id: number;
    indicators: string[];
    project_id?: string;
    project_name?: string;
    location_id: number;
    updated_at: string;
    workspace_id: number;
};

type Indicators = {
    key: string;
    name: string;
}[];

type Project = {
    city: string;
    country: string;
    id: string;
    name: string;
    publish: boolean;
    state: string;
    street: string;
};

type Prop = {
    locationEdit: LocationEdit;
    locationID: number;
    workspaceID: number;
    status?: Status;
    arcStatus?: ArcStatus;
    collections: { name: string; code: string }[];
    close: () => void;
    refresh: () => void;
};

const getProjects = async (workspaceID, locationID, query): Promise<Project[]> => {
    try {
        const params: any = {
            workspace_id: workspaceID,
            location_id: locationID
        }
        if (query) {
            params.query = query
        }
        const response: ApiResponse = await apiV3('/arc/projects', 'get', params);
        const { data } = response;
        return data;
    } catch (e) {
        return [];
    }
};

const useIndicators = (pid, wid, lid) => {
    const [indicators, setIndicators] = React.useState<Indicators>([]);

    React.useEffect(() => {
        if (pid) {
            apiV3('/arc/meters', 'get', {
                workspace_id: wid,
                location_id: lid,
                project_id: pid
            }).then((response) => {
                if (response && typeof response === 'object' && 'data' in response) {
                    const responseData = response.data as Indicators;
                    setIndicators(responseData);
                }
            });
        }
    }, [lid, pid, wid]);
    return indicators;
};


const ArcPage: React.SFC<{
    arcStatus?: ArcStatus;
    workspaceID: number;
    locationID: number;
    back: () => void;
    close: () => void;
}> = React.memo(props => {
    const [arcStatus, setArc] = React.useState(props.arcStatus || 'pending');
    // const [arcCheck, setCheck] = React.useState(false);
    const [detail, setDetail] = React.useState<ArcDetail>();
    const [projectLoading, setProjectLoading] = React.useState(true);

    const [projects, setProjects] = React.useState<Project[]>([]);
    const [isEditing, setEdit] = React.useState(false);
    const indicators = useIndicators(
        detail && detail.project_id,
        props.workspaceID,
        props.locationID
    );

    React.useEffect(() => {
        apiV3('/arc', 'get', {
            workspace_id: props.workspaceID,
            location_id: props.locationID
        })
            .then((response) => {
                if (response && typeof response === 'object' && 'data' in response) {
                    const { data } = response as { data: any };
                    if (
                        data &&
                        data.expired_at &&
                        moment().isBefore(moment(data.expired_at))
                    ) {
                        setDetail(data);
                        setEdit(!data.project_id);
                        setProjectLoading(true);
                        getProjects(props.workspaceID, props.locationID, data.project_id)
                            .then(pro => {
                                setProjects(pro);
                                setArc('authed');
                                setProjectLoading(false);
                            })
                            .catch(() => setProjectLoading(false));
                    } else {
                        setArc('no-auth');
                    }
                }
            })
            .catch(() => {
                setArc('no-auth');
            });
    }, [props.locationID, props.workspaceID]);


    const goToAuth = async () => {
        const path = await apiPath();
        const meta = qs.stringify({
            location_id: props.locationID,
            workspace_id: props.workspaceID,
            ...requestMeta()
        });
        window.open(`${path}/v3/arc/authorize?${meta}`);
    };

    const unbindArc = () => {
        apiV3(`/arc/${detail!.id}`, 'delete');
        props.close();
    };

    const bindArc = async () => {
        try {
            await apiV3('/arc/project', 'post', {
                workspace_id: props.workspaceID,
                location_id: props.locationID,
                project_id: detail!.project_id
            });

            await apiV3('/arc/meters', 'post', {
                workspace_id: props.workspaceID,
                location_id: props.locationID,
                project_id: detail!.project_id,
                indicators: detail!.indicators
            });

            setArc('successed');
        } catch (error) {
            setArc('failed');
        }
    };

    const updateArc = (key: keyof ArcDetail, value) => {
        if (detail) {
            const data =
                key === 'project_id'
                    ? { ...detail, [key]: value, indicators: [] }
                    : { ...detail, [key]: value };
            setDetail(data);
        }
    };

    const resetArc = () => {
        updateArc('project_id', undefined);
        setEdit(true);
    };

    const disabled = detail && !!detail.project_id && !isEditing;
    const { language } = i18n;
    const arcScreen = language.includes('zh') ? screen2 : screen3;
    const halfSecond = 500
    return (
        <div className='location_detail-integration-container'>
            <div className='location_detail-integration-title'>
                <i
                    className='material-icons accent_text location_detail-integration-back'
                    onClick={props.back}
                >
                    navigate_before
                </i>
                <img
                    src={Arc}
                    alt='arc logo'
                    className='location_detail-integration-arc'
                />
                <span className='location_detail-integration-name'>Arc</span>
            </div>

            {['no-auth', 'authed'].includes(arcStatus) && (
                <div className='location_detail-setting-part'>
                    <h4>{t('locationOverview.what is arc')}</h4>
                    <p className='small_text'>{t('locationOverview.arc introduce')}</p>
                </div>
            )}

            {arcStatus === 'pending' && (
                <div className='location_detail-setting-button'>
                    <br />
                    <CustomSpinner />
                </div>
            )}
            {arcStatus === 'no-auth' && (
                <>
                    <div className='location_detail-setting-part'>
                        <h4>{t('locationOverview.Push Data to Arc')}</h4>
                        <p className='small_text'>
                            {t('locationOverview.click button go to arc')}
                        </p>
                    </div>

                    <div className='location_detail-integration-button'>
                        <Button
                            size='large'
                            customType='dark'
                            onClick={goToAuth}
                            // disabled={!arcCheck}
                            block={true}
                        >
                            {t('Authorization')}
                        </Button>
                    </div>
                    <br />
                    <div className='location_detail-setting-part'>
                        <h4>{t('locationOverview.auth step example')}</h4>
                        <div className='location_detail-integration-box'>
                            <p>{t('locationOverview.click will jump to arc')}</p>
                            <img src={screen1} alt='screen shot' />
                            <p>
                                {t('locationOverview.input username and password will back')}
                            </p>
                            <img src={arcScreen} alt='screen shot' />
                        </div>
                    </div>
                </>
            )}
            {arcStatus === 'authed' && detail && (
                <>
                    <div className='location_detail-setting-part'>
                        <h4>{t('locationOverview.Push Data to Arc')}</h4>

                        <div className='location_detail-integration-success'>
                            <i className='material-icons md-24 accent_text report-success-icon'>
                                check_circle
                            </i>
                            <p className='location_detail-integration-success_message'>
                                {t('Authorized')}
                            </p>
                            <p className='small_text grey_text'>
                                {t('locationOverview.if you dont want continue')}
                                <Popconfirm
                                    title={t(
                                        'locationOverview.Are you sure you want to cancel project authorization'
                                    )}
                                    onConfirm={unbindArc}
                                    okType='danger'
                                >
                                    <a className='danger_text location_detail-integration-unbind'>
                                        {t('locationOverview.Cancel Authorization')}
                                    </a>
                                </Popconfirm>
                            </p>
                        </div>
                    </div>
                    <div className='location_detail-setting-part'>
                        <h4>
                            {t('locationOverview.Project')}
                            {disabled && (
                                <a
                                    className='location_detail-integration-reset_button'
                                    onClick={resetArc}
                                >
                                    {t('locationOverview.reset')}
                                </a>
                            )}
                        </h4>
                        {disabled ? <div>
                            {projects.find(e => e.id === detail.project_id)!.name}
                            <p className='small_text grey_text'>{detail.project_id}</p>
                        </div> :
                            <Select
                                block={true}
                                disabled={disabled}
                                showArrow={!disabled}
                                value={detail.project_name || detail.project_id}
                                placeholder={t('locationOverview.select you project')}
                                optionLabelProp='label'
                                showSearch={true}
                                allowClear={true}
                                loading={projectLoading}
                                onChange={v => {
                                    updateArc('project_id', v);
                                }}
                                optionFilterProp='label'
                                filterOption={true}
                                onSearch={debounce(v => {
                                    getProjects(props.workspaceID, props.locationID, v)
                                        .then(pro => {
                                            setProjects(pro)
                                        })
                                }, halfSecond)}
                            >
                                {projects.map((item, i) => (
                                    <Option
                                        key={`project-${i}`}
                                        value={item.id}
                                        {...{ label: item.name }}
                                    >
                                        <div>
                                            {item.name}
                                            <p className='small_text grey_text'>{item.id}</p>
                                        </div>
                                    </Option>
                                ))}
                            </Select>}
                    </div>

                    <div className='location_detail-setting-part'>
                        {disabled && <h4>{t('locationOverview.your indicators')}</h4>}
                        <Select
                            block={true}
                            mode='tags'
                            disabled={disabled}
                            placeholder={!disabled && t('Please select Indicators')}
                            value={detail.indicators}
                            onChange={v => updateArc('indicators', v)}
                        >
                            {(detail.project_id ? indicators : []).map((v, i) => (
                                <Option value={v.key} key={`indicators-${i}`}>
                                    {v.name}
                                </Option>
                            ))}
                        </Select>
                    </div>

                    {!disabled && (
                        <div className='location_detail-integration-button'>
                            <Button size='large' customType='light' onClick={props.close}>
                                {t('Cancel')}
                            </Button>

                            <Button size='large' customType='dark' onClick={bindArc}>
                                {t('Save')}
                            </Button>
                        </div>
                    )}
                </>
            )}
            {arcStatus === 'successed' && (
                <>
                    <br />
                    <div className='location_detail-integration-success'>
                        <i className='material-icons md-24 accent_text report-success-icon'>
                            check_circle
                        </i>
                        <p className='location_detail-integration-success_message'>
                            {t('Bind success')}
                        </p>
                    </div>
                    <div className='location_detail-integration-button'>
                        <Button
                            size='large'
                            customType='dark'
                            block={true}
                            onClick={props.close}
                        >
                            {t('okey, I Know')}
                        </Button>
                    </div>
                </>
            )}
            {arcStatus === 'failed' && (
                <>
                    <br />
                    <div className='location_detail-integration-success'>
                        <i className='material-icons md-24 danger_text report-success-icon'>
                            cancel
                        </i>
                        <p className='location_detail-integration-success_message'>
                            {t('Authorization failed')}
                        </p>
                    </div>
                    <div className='location_detail-integration-button'>
                        <Button
                            size='large'
                            customType='dark'
                            block={true}
                            onClick={props.close}
                        >
                            {t('okey, I Know')}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
});

const Integration: React.SFC<Prop> = React.memo(props => {
    const [status, setStatus] = React.useState<Status>(props.status || 'list');
    const [enableReset, setReset] = React.useState(
        props.locationEdit.push_channel === 'reset'
    );
    const [arcAuth, setArcAuth] = React.useState(false);

    React.useEffect(() => {
        apiV3('/arc', 'get', {
            workspace_id: props.workspaceID,
            location_id: props.locationID
        })
            .then((response: any) => {
                const { data } = response;
                setArcAuth(data &&
                    data.expired_at &&
                    moment().isBefore(moment(data.expired_at)))
            })
    }, [props.locationID, props.workspaceID]);

    const updateReset = async () => {
        await apiV3(`/locations/${props.locationID}`, 'patch', {
            location: {
                ...props.locationEdit,
                push_channel: enableReset ? 'reset' : null
            }
        });

        props.refresh();
        props.close();
    };

    const listPage = (
        <div className='location_detail-integration-container'>
            <div
                className='location_detail-integration-list_card'
                onClick={() => setStatus('reset')}
            >
                <Reset className='location_detail-integration-reset' />
                <span className='location_detail-integration-name'>RESET</span>
                {enableReset &&
                    <span className='status-icon'>
                        <i className='material-icons md-24 accent_text report-success-icon'>
                            check_circle</i>
                    </span>
                }
            </div>
            <div
                className='location_detail-integration-list_card'
                onClick={() => setStatus('arc')}
            >
                <img
                    src={Arc}
                    alt='arc logo'
                    className='location_detail-integration-arc'
                />
                <span className='location_detail-integration-name'>Arc</span>
                {arcAuth && <span className='status-icon'>
                    <i className='material-icons md-24 accent_text report-success-icon'>
                        check_circle</i>
                </span>}
            </div>
        </div>
    );

    const resetPage = (
        <div className='location_detail-integration-container'>
            <div className='location_detail-integration-title'>
                <i
                    className='material-icons accent_text location_detail-integration-back'
                    onClick={() => setStatus('list')}
                >
                    navigate_before
                </i>
                <Reset className='location_detail-integration-reset' />
                <span className='location_detail-integration-name'>RESET</span>
            </div>

            <div className='location_detail-setting-part'>
                <h4>{t('locationOverview.what is reset')}</h4>
                <p className='small_text'>{t('locationOverview.reset introduce')}</p>
            </div>

            <div className='location_detail-setting-row location_detail-setting-part'>
                <div className='location_detail-setting-row_left'>
                    <h4>{t('locationOverview.Push Data to RESET')}</h4>
                    <p className='small_text'>
                        {t(
                            'locationOverview.Enable data to be sent to RESET for certification'
                        )}
                    </p>
                    {enableReset && (
                        <ul className='location_detail-setting-reset_list'>
                            <li>
                                <div>{t('locationOverview.Collection Name')}</div>
                                <div>{t('locationOverview.RESET Code')}</div>
                            </li>
                            {props.collections.map((v, i) => (
                                <li key={`location-collection-${i}`}>
                                    <div>
                                        <Tooltip title={v.name}>{v.name}</Tooltip>
                                    </div>
                                    <div>{v.code}</div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

                <div className='location_detail-setting-row_right'>
                    <Switch checked={enableReset} onChange={e => setReset(e)} />
                </div>
            </div>

            <div className='location_detail-integration-button'>
                <Button size='large' customType='light' onClick={props.close}>
                    {t('Cancel')}
                </Button>

                <Button size='large' customType='dark' onClick={updateReset}>
                    {t('Save')}
                </Button>
            </div>
        </div>
    );

    return (
        <div className='colletion_modal-container'>
            <h3 className='register-title'>{t('locationOverview.integration')}</h3>
            {status === 'list' && listPage}
            {status === 'reset' && resetPage}
            {status === 'arc' && (
                <ArcPage
                    workspaceID={props.workspaceID}
                    locationID={props.locationID}
                    back={() => setStatus('list')}
                    close={props.close}
                    arcStatus={props.arcStatus}
                />
            )}
        </div>
    );
});

export default Integration;
