import {
  CollectionAction,
  GET_COLLECTIONS_V3,
  GET_COLLECTIONS_DETAIL_V3,
  CollectionItem,
  GET_COLLECTION_FOR_SELECTOR_V3
} from 'actions/V3/collections'

export type CollectionState = {
  data: CollectionItem[],
  meta?: Meta,
  query?: {},
  detail?: CollectionItem,
  list: CollectionItem[]
}

export default function Collections(
  state: CollectionState = {
    data: [],
    list: []
  },
  action: CollectionAction
) {
  switch (action.type) {
    case GET_COLLECTIONS_V3:
      return {
        ...state,
        data: action.data,
        meta: action.meta,
        query: action.query
      }
    case GET_COLLECTIONS_DETAIL_V3:
      return {
        ...state,
        detail: action.data
      }
    case GET_COLLECTION_FOR_SELECTOR_V3:
      return {
        ...state,
        list: action.data
      }
    default:
      return state
  }
}
