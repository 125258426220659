import * as React from 'react'
import Highlighter from 'react-highlight-words'
import qs from 'qs'
import { connect, DispatchProp } from 'react-redux'
import { pick } from 'lodash'
import { queryMerge } from 'utils/query'
import { requestV3SeacrhDetail, RequestV3SearchDetailAction, SearchResult } from 'actions/V3/search'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { t } from 'i18next'
import './index.css'
import SearchIcon from '-!svg-react-loader!images/svg/search.svg'
interface StateProps {
  search?: SearchResult;
}

export type P = StateProps & DispatchProp<RequestV3SearchDetailAction> & RouteComponentProps<{}>

const types = ['collections', 'locations', 'data_sources', 'workspaces', 'indicators']

const searchTitle = {
  collections: t('search.collections'),
  locations: t('search.locations'),
  data_sources: t('search.data_sources'),
  workspaces: t('search.workspaces'),
  indicators: t('search.indicators')
}

class Search extends React.PureComponent<P, {}> {
  componentDidMount() {
    const {
      location: { search, pathname },
      history,
      dispatch
    } = this.props
    const query = pick(qs.parse(search.slice(1)), ['q', 'type'])

    if (!query.q) {
      return history.replace('/')
    }

    if (types.includes(query.type?.toString() || '')) {
      // FIXME: Ugly code, refactor that please!
      const type_size = query.type?.toString() || ''
      return dispatch(
        requestV3SeacrhDetail({
          q: query.q.toString(),
          [`${type_size.slice(0, -1)}_size`]: 1000
        })
      )
    }

    dispatch(
      requestV3SeacrhDetail({
        q: query.q.toString()
      })
    )

    if (query.type) {
      history.replace({
        pathname,
        search: queryMerge({
          search,
          delKeys: ['type']
        })
      })
    }
  }

  jumpUrl = (v, title) => {
    const { history } = this.props
    switch (title) {
      case 'collections':
        return history.push(`/workspaces/${v.workspace_id}/collections/${v.id}`)
      case 'locations':
        return history.push(`/workspaces/${v.workspace_id}/locations/${v.id}`)
      case 'data_sources':
        return history.push(`/workspaces/${v.workspace_id}/datasources/${v.id}`)
      case 'workspaces':
        return history.push(`/workspaces/${v.id}`)
      case 'indicators':
        return history.push(`/workspaces/${v.workspace_id}/indicators/${v.id}`)
      default:
    }
  }

  gotoSearch = (type: string, search: string) => {
    const { history } = this.props
    history.push(`/search?q=${search}&type=${type}`)
  }

  renderSearchCard = () => {
    const {
      search,
      location: { search: q }
    } = this.props
    if (!search) {
      return []
    }

    const query = pick(qs.parse(q.slice(1)), ['q', 'type'])
    const searchString = query.q?.toString() || '';
    const filter = query.type && types.includes(query.type.toString() || '') && query.type

    const data = Object.keys(search)
      .map(key => ({
        title: key,
        children: search[key]
      }))
      .filter(v => (filter ? v.children.length && v.title === filter : v.children.length))

    return data.length ? (
      data.map((item, index) => (
        <div className='search-result' key={`search-result-${index}`}>
          <h2 className='search-result-title'>
            {t('search.search for')}
            <span className='accent_text'>{`${query.q || ''}`}</span>
            {t('search.related', {
              title: searchTitle[item.title]
            })}
            {!filter && searchString && (
              <a className='header-search-more' onClick={() => this.gotoSearch(item.title, searchString)}>
                {t('search.more')}
              </a>
            )}
          </h2>
          <div className='search-result-line' />
          <ul className='search-result-list'>
            {item.title === 'data_sources' && (
              <li>
                <span className='grey_text search-result-uuid'>UUID</span>
                <span className='grey_text search-result-name'>{t('NAME')}</span>
              </li>
            )}
            {item.children.map((v, i) => (
              <li key={`search-result-${index}-${i}`}>
                {item.title === 'data_sources' ? (
                  <div onClick={() => this.jumpUrl(v, item.title)}>
                    <a className='search-result-uuid'>
                      <Highlighter searchWords={[searchString]} autoEscape={true} textToHighlight={v.identifier || ''} />
                    </a>
                    <a>
                      <Highlighter searchWords={[searchString]} autoEscape={true} textToHighlight={v.name || ''} />
                    </a>
                  </div>
                ) : (
                  <a onClick={() => this.jumpUrl(v, item.title)}>
                    <Highlighter searchWords={[searchString]} autoEscape={true} textToHighlight={v.name} />
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
      ))
    ) : (
      <div className='search-nodata-container'>
        <SearchIcon className='search-nodata-search' />
        <h2>{t('search.find any code match')}</h2>
        <p>{t('search.any code mathing', { search: searchString || '' })}</p>
        <p>{t('search.You can search with more')}</p>
      </div>
    )
  }

  render() {
    return (
      <div className='location-wrapper'>
        <div className='location-inner search-wrapper'>{this.renderSearchCard()}</div>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { search } }) => ({
  search: search.detail
})

export default withRouter(connect(mapStateToProps)(Search))
