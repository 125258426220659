const customKioskSettings = {
  covid_index: {
    template_key: 'covidIndex'
  },
  jpmc: {
    template_key: 'templateJPMC'
  },
  cushman: {
    id: '202445',
    height: '94.5vh'
  }
}

export default customKioskSettings
