import CalibrationNoneIcon from '-!svg-react-loader!images/svg/calibration-none.svg';
import CalibrationIcon from '-!svg-react-loader!images/svg/calibration.svg';
import DatasourceIcon from '-!svg-react-loader!images/svg/dataSource.svg';
import TableIcon from '-!svg-react-loader!images/svg/Table.svg';
import {
  requestDatachannelCalibrations,
  requestDatachannelEdit,
  requestDatasourceBrands,
  requestDatasourceDelete,
  requestDatasourceDetail,
  requestDatasourceEdit, REQUEST_DATA_SOURCES_V3
} from 'actions/V3/datasources';
import { refreshPermission } from 'actions/V3/permission';
import {
  Breadcrumb,
  Icon,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Tooltip,
  Upload
} from 'antd';
import { DraggerProps } from 'antd/lib/upload';
import Button from 'components/Custom/Button';
import CustomSpinner from 'components/Custom/Spinner';
import Table from 'components/Custom/Table';
import IconEdit from 'components/Editable';
import { t } from 'i18next';
import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import cache from 'sagas/cache';
import { promiseDispatch, uploadImage } from 'utils';
import api, { apiV3 } from 'utils/api';
import { indicatorUnitDict, unitList } from 'utils/constant';
import { Guard } from 'utils/guard';
import NoDatasourceImg from '../../images/NoDatasourceImg.png';
import './detail.css';
import { P, State, StateProps } from './detailType';
import { Calibration } from './modal';

const Option = Select.Option;
const Dragger: React.SFC<DraggerProps> = Upload.Dragger;

@Guard
class DatasourceDetail extends React.Component<P & State> {
  state: State = {};

  componentDidMount() {
    this.requestDatasourceDetail();
  }

  UNSAFE_componentWillReceiveProps(next: P) {
    const { datasource_id } = next.match.params;
    const { datasource_id: dID } = this.props.match.params;

    if (datasource_id !== dID) {
      this.requestDatasourceDetail(next);
    }
  }

  requestDatasourceDetail = (props = this.props) => {
    const {
      dispatch,
      match: {
        params: { datasource_id }
      }
    } = props;

    dispatch(requestDatasourceBrands());

    dispatch(
      requestDatasourceDetail({
        id: datasource_id,
        channel: true
      })
    );
    dispatch(
      requestDatachannelCalibrations({
        datasource_id: +datasource_id
      })
    );
  };

  showModal = (type, id = undefined) => {
    this.setState({
      modalVisibleType: type,
      currentDataChannelId: id
    });
  };

  handleModalCancel = () => {
    this.setState({
      modalVisibleType: undefined
    });
  };

  GuardTrigger_limitSelector = (value, name) => (
    <Select
      className='ds-limit-selector'
      defaultValue={value || 360}
      onChange={(v) => this.handleDetailUpdate({ [name]: v })}
      size='small'
    >
      <Option value={360}>{t('datasource.default hours')}</Option>
      <Option value={30}>{t('datasource.30 minutes')}</Option>
      <Option value={60}>{t('datasource.1 hour')}</Option>
    </Select>
  );

  GuardTrigger_datasourceRemoveButton = () => (
    <Button customType='danger' block={true} onClick={this.confirmRemove}>
      {t('datasource.Remove Data Source')}
    </Button>
  );

  GuardHandle_datasourceUpdate = (rule?: GuardRule) => rule && rule.enable;

  geneColumns = () => {
    const updateName = (e, id) =>
      this.handleDataChannelUpdate({ name: e.target.value }, id);

    const editInfo = this.GuardHandle_datasourceUpdate();
    return [
      {
        title: t('DATA CHANNEL NAME'),
        dataIndex: 'name',
        key: 'name',
        className: 'td-1',
        render: (text, record) => {
          return (
            <div>
              <IconEdit
                showIcon={editInfo}
                className='row-1-edit'
                iconPosition='start'
                render={(editable, disableEdit) =>
                  editable ? (
                    <Input
                      autoFocus={true}
                      size='small'
                      className='datachannel-name-input'
                      defaultValue={record.name || record.channel_name}
                      onBlur={(e) => {
                        disableEdit();
                        updateName(e, record.id);
                      }}
                      onPressEnter={(e) => {
                        disableEdit();
                        updateName(e, record.id);
                      }}
                    />
                  ) : (
                    <span
                      className='second'
                      style={{ paddingLeft: editInfo ? 0 : 18 }}
                    >
                      {record.name || record.channel_name}
                    </span>
                  )
                }
              />
              <div className='item-origin-name'>{record.channel_name}</div>
            </div>
          );
        }
      },
      {
        title: t('REPORTED UNITS'),
        dataIndex: 'unit',
        key: 'unit',
        render: (text, record) => {
          if (record.indicator) {
            return text || '-';
          }
          const handleChannelUnitChange = (v) => {
            this.handleDataChannelUpdate({ unit: v }, record.id);
          };
          const units = indicatorUnitDict[record.channel] || unitList;
          return (
            <Select
              disabled={!editInfo}
              className='ds-channel-unit-selector'
              defaultValue={text}
              onChange={handleChannelUnitChange}
              size='small'
            >
              {units.map((item, i) => (
                <Option key={i} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          );
        }
      },
      {
        title: t('Assigned Indicator'),
        dataIndex: 'indicator',
        width: '150px',
        key: 'indicator',
        render: (text, record) => {
          const workspaceId = this.props.match.params.id;
          if (record.indicator) {
            if (this.state.unassigning) {
              return <div className='unassign'>{t('Unassigning')}</div>;
            } else {
              return (
                <Link
                  className='ds-link'
                  to={`/workspaces/${workspaceId}/indicators/${record.indicator.id}`}
                >
                  {record.indicator.name}
                </Link>
              );
            }
          } else {
            return (
              <div className='unassign'>
                <span>{t('Unassigned')}</span>
              </div>
            );
          }
        }
      },
      {
        title: t('Calibration'),
        render: (text, record) => {
          return editInfo ? (
            <span
              className='ds-calibrate-btn'
              onClick={() => this.showModal('Calibration', record.id)}
            >
              {record.calibration ? (
                <CalibrationIcon />
              ) : (
                <CalibrationNoneIcon />
              )}
            </span>
          ) : (
            <span className='ds-calibrate-btn'>
              {record.calibration && <CalibrationIcon />}
            </span>
          );
        }
      }
    ];
  };

  handleDetailUpdate = (data) => {
    const {
      dispatch,
      match: {
        params: { datasource_id }
      }
    } = this.props;
    dispatch(
      requestDatasourceEdit({
        id: datasource_id,
        data
      })
    );
  };

  handleDataChannelUpdate = (data, id) => {
    const {
      dispatch,
      match: {
        params: { datasource_id }
      }
    } = this.props;
    dispatch(
      requestDatachannelEdit({
        id: datasource_id,
        channel_id: id,
        data
      })
    );
  };

  uploadDsImage = ({ file }: any) => {
    uploadImage(file, 'logo').then((observable) => {
      if (observable) {
        observable.subscribe(
          (next) => {
            /* next handle */
          },
          (err) => {
            /* error handle */
            message.error(t('upload Error', { message: err.message }));
          },
          ({ key: path }) => {
            this.handleDetailUpdate({ image: path });
          }
        );
      }
    });
  };

  handleRemove = async () => {
    const {
      dispatch,
      match: {
        params: { datasource_id }
      },
      history
    } = this.props;
    await promiseDispatch({
      dispatch,
      actionCreator: requestDatasourceDelete,
      payload: {
        id: datasource_id
      }
    });
    this.handleModalCancel();
    cache.cleanCache(REQUEST_DATA_SOURCES_V3);
    dispatch(
      refreshPermission({
        show: false
      })
    );
    history.goBack();
  };

  formatDate = (date) => {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '-';
  };

  updateDsName = (e) => {
    const name = e.target.value;
    if (name) {
      this.handleDetailUpdate({ name });
    }
  };

  rowClass = (record) => {
    return 'ds-channel-row st-' + record.status;
  };

  handleUnassignAll = () => {
    const {
      match: {
        params: { datasource_id }
      }
    } = this.props;
    apiV3(`/data_sources/${datasource_id}/unassign`, 'post').then(() => {
      this.handleModalCancel();
      this.setState({
        unassigning: true
      });
    });
  };

  getCurrentChannel = () => {
    const { datasource } = this.props;
    const { currentDataChannelId } = this.state;
    return (
      datasource &&
      (datasource.channels || []).find(
        (item) => item.id === currentDataChannelId
      )
    );
  };

  handleCalibrationUpdate = async (data) => {
    const {
      match: {
        params: { datasource_id }
      },
      dispatch
    } = this.props;
    const channelObj = this.getCurrentChannel();
    if (channelObj) {
      data.indicator = channelObj.channel;
      await api(`/monitors/${datasource_id}/calibrations`, 'post', data);
      this.handleModalCancel();
      dispatch(
        requestDatachannelCalibrations({
          datasource_id: +datasource_id
        })
      );
    }
  };

  // only admin user can change
  onChangeStatusNoti = (v) => {
    this.handleDetailUpdate({
      enable_status_notification: v
    });
  };

  getCurrentCalibration = () => {
    const { calibrations } = this.props;
    const channelObj = this.getCurrentChannel();
    const c =
      channelObj &&
      calibrations.find((v) => v.indicator === channelObj.channel);
    const calibration = c && c.calibration;
    return calibration || {};
  };

  geneColumnsRawData = () => [
    {
      title: t('Indicator Name'),
      dataIndex: 'channel_name',
      className: 'rtd-1',
      key: 'channel_name'
    },
    {
      title: t('DATA'),
      dataIndex: 'value',
      className: 'rtd-2',
      key: 'value'
    },
    {
      title: t('UNITS'),
      dataIndex: 'unit',
      className: 'rtd-3',
      key: 'unit'
    }
  ];

  getModelsByBrandId = (bid) => {
    const { brands } = this.props;
    const brand = brands.find((e) => e.id === bid);
    return brand ? brand.models : [];
  };

  confirmRemove = () => {
    Modal.confirm({
      title: t('datasource.Remove Data Source?'),
      content: (
        <div className='datasource_modal-body'>
          <p>{t('datasource.Removing this Data Source')}</p>
          <p>{t('datasource.This does not affect billing')}</p>
          <p>{t('datasource.If you are trying to assign this Data Source')}</p>
          <p>{t('datasource.This does not affect billing')}</p>
        </div>
      ),
      okType: 'danger',
      okText: t('datasource.Remove Data Source'),
      onOk: this.handleRemove
    });
  };

  confirmUnassign = () => {
    Modal.confirm({
      title: t('datasource.Unassign All Indicators?'),
      content: (
        <div className='datasource_modal-body'>
          <p>{t('datasource.By unassigning all indicators')}</p>
          {t('datasource.This does not affect billing')}
        </div>
      ),
      okType: 'danger',
      okText: t('datasource.Unassign All Indicators'),
      onOk: this.handleUnassignAll
    });
  };

  render() {
    const {
      datasource,
      calibrations,
      match: {
        params: { id }
      },
      brands
    } = this.props;
    const { modalVisibleType } = this.state;

    if (!datasource) {
      return null;
    }

    const data = (datasource.channels || []).map((item, i) => ({
      key: i,
      calibration: (
        calibrations.find((c) => c.indicator === item.channel) || {}
      ).calibration,
      ...item
    }));

    const rawData = data.map((item) => ({
      ...item,
      value: (datasource.reading || {})![item.channel]
    }));

    const image =
      datasource.image || NoDatasourceImg;
    const rawDataReadAt =
      datasource.reading && this.formatDate(datasource.reading.reading_time);
    const editInfo = this.GuardHandle_datasourceUpdate();

    return (
      <>
        <div className='ds-detail-header'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={`/workspaces/${id}/datasources`}>
                <DatasourceIcon />
                <span className='label'>
                  {t('datasource.All Data Sources')}
                </span>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{datasource.name}</span>
              <small>{datasource.identifier}</small>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Link
            className='link-icon hidden-mobile'
            to={`/workspaces/${id}/data?cate=data_source&datasource_id=${datasource.id}`}
          >
            <TableIcon />
            <span>{t('navbar.Table')}</span>
          </Link>
        </div>
        <div className='ds-detail'>
          <div className='ds-info-panel'>
            <div className='ds-img'>
              <img src={image} alt='' />
              {editInfo && (
                <div className='ds-img-tip'>
                  <Dragger
                    accept='image/jpeg,image/png,image/jpg'
                    showUploadList={false}
                    customRequest={this.uploadDsImage}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('datasource.update photo')
                      }}
                    />
                  </Dragger>
                </div>
              )}
            </div>
            <div className='ds-status'>
              <span className={'status large ' + datasource.status}>
                {datasource.status}
              </span>
            </div>
            <dl className='spec-list'>
              <dt>UUID</dt>
              <dd>{datasource.identifier}</dd>
              <dt>
                <Tooltip
                  overlayClassName='ds-tooltip'
                  placement='bottomLeft'
                  title={t('datasource.Data Source Name Tip Pop')}
                >
                  <Icon
                    type='exclamation-circle-o'
                    style={{ color: '#0B75A9' }}
                  />
                  <span className='icon-l'>{t('Name')}</span>
                </Tooltip>
              </dt>
              <dd>
                <IconEdit
                  showIcon={editInfo}
                  iconPosition='end'
                  render={(editable, disableEdit) =>
                    editable ? (
                      <Input
                        autoFocus={true}
                        size='small'
                        className='datasource-name-input'
                        defaultValue={datasource.name || ''}
                        onBlur={(e) => {
                          this.updateDsName(e);
                          disableEdit();
                        }}
                        onPressEnter={(e) => {
                          this.updateDsName(e);
                          disableEdit();
                        }}
                      />
                    ) : (
                      <span
                        className={datasource.name ? 'primary' : 'untitled'}
                      >
                        {datasource.name || 'Untitled'}
                      </span>
                    )
                  }
                />
              </dd>
              <dt>{t('datasource.Brand')}</dt>
              <dd>
                <Select
                  className='register-device-brand-selector'
                  defaultValue={
                    datasource.brand_id ? +datasource.brand_id : undefined
                  }
                  placeholder={t('Select brand')}
                  onChange={(v) =>
                    this.handleDetailUpdate({
                      brand_id: v,
                      model_id: brands.find((e) => e.id === +v)!.models[0].id
                    })
                  }
                  size='small'
                >
                  {brands.map((item, i) => (
                    <Select.Option key={i} value={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </dd>
              <dt>{t('datasource.Device Model')}</dt>
              <dd>
                {datasource.brand_id ? (
                  <Select
                    className='register-device-brand-selector'
                    defaultValue={datasource.model_id}
                    value={datasource.model_id}
                    placeholder={t('Select model')}
                    onChange={(v) => this.handleDetailUpdate({ model_id: v })}
                    size='small'
                  >
                    {this.getModelsByBrandId(datasource.brand_id).map(
                      (item, i) => (
                        <Select.Option key={i} value={item.id}>
                          <Tooltip title={item.name}>{item.name}</Tooltip>
                        </Select.Option>
                      )
                    )}
                  </Select>
                ) : (
                  '-'
                )}
              </dd>
              <dt>{t('datasource.Offline Limit')}</dt>
              <dd>
                {this.GuardTrigger_limitSelector(
                  datasource.offline_limit,
                  'offline_limit'
                )}
              </dd>
              {/* <dt>{t('datasource.Flatline Limit')}</dt>
              <dd>
                {this.GuardTrigger_limitSelector(
                  datasource.flatline_limit,
                  'flatline_limit'
                )}
              </dd> */}
              <dt>{t('datasource.Last Received Time')}</dt>
              <dd className='ds-date-time'>
                {this.formatDate(datasource.last_reading_time)}
              </dd>
              <dt>{t('datasource.Register Time')}</dt>
              <dd className='ds-date-time'>
                {this.formatDate(datasource.created_at)}
              </dd>
              {editInfo && (
                <>
                  <dt>{t('datasource.Status Notification')}</dt>
                  <dd>
                    <Switch
                      defaultChecked={datasource.enable_status_notification}
                      onChange={this.onChangeStatusNoti}
                    />
                  </dd>
                </>
              )}
            </dl>
            <div className='ds-action'>
              {editInfo && (
                <Button
                  customType='light'
                  block={true}
                  onClick={this.confirmUnassign}
                >
                  {t('datasource.Unassign All Indicators')}
                </Button>
              )}
              {this.GuardTrigger_datasourceRemoveButton()}
            </div>
          </div>
          <div className='ds-channel-list'>
            {!data.length ? (
              <div className='empty-table'>
                <div className='empty-title'>
                  {t('datasource.waiting data')} <CustomSpinner />
                </div>
                <div className='empty-tip'>{t('datasource.after waiting')}</div>
              </div>
            ) : (
              <Table
                type='light'
                rowClassName={this.rowClass}
                columns={this.geneColumns()}
                dataSource={data}
                size='default'
                pagination={false}
              />
            )}
            {rawDataReadAt && (
              <div className='ds-raw-data-panel'>
                <div className='ds-raw-data-panel-header'>
                  <div className='label'>{t('RAW DATA')}</div>
                  <div className='read-at'>
                    <span>{t('datasource.Last Reading')}</span>
                    {rawDataReadAt}
                  </div>
                </div>
                <div className='body'>
                  <Table
                    type='light'
                    columns={this.geneColumnsRawData()}
                    dataSource={rawData}
                    size='default'
                    pagination={false}
                  />
                </div>
              </div>
            )}
          </div>
          <Modal
            closable={false}
            footer={null}
            centered={true}
            maskClosable={true}
            visible={!!modalVisibleType}
            onCancel={this.handleModalCancel}
          >
            {modalVisibleType === 'Calibration' && (
              <Calibration
                calibration={this.getCurrentCalibration()}
                handleCancel={this.handleModalCancel}
                handleConfirm={this.handleCalibrationUpdate}
              />
            )}
          </Modal>
        </div>
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { datasources } }) => ({
  datasource: datasources.detail,
  brands: datasources.brands,
  calibrations: datasources.calibrations
});

export default withRouter(connect(mapStateToProps)(DatasourceDetail));
