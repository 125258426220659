import React from 'react'
import { Row, Col, Input } from 'antd'
import { t } from 'i18next'
import { AlertPanelChoices } from '../../utils/enums'
import dsIcon from 'images/icons/dsIcon.png'
import { notificationDate } from 'utils/helper'

export default function AlertPanel({ currentKiosk, opts, movedDownSetState }) {
    opts[opts.optionsValues] = opts[opts.obj]
    opts[opts.optionTemplate] = opts[opts.ty]
    const ConfigurationAdvice = currentKiosk.template_config.advices

    ConfigurationAdvice.configs = ConfigurationAdvice.configs || {}
    const defaultValue =
    opts.optionTemplate === AlertPanelChoices.ONLINE
        ? ConfigurationAdvice.offline
        : ConfigurationAdvice.configs[opts.optionsValues.id]

    return (
        <div className='alert-panel' key={opts.optionsValues ? opts.optionsValues.id : 'offline'}>
            <div className='alert-panel-title'>{t('kiosk.alert')}</div>
            {opts.optionTemplate === AlertPanelChoices.OFFLINE && (
                <div className='alert-panel-header'>
                    <Row>
                        <Col span={8}>
                            <div className='alert-ty-1-label'>{t('kiosk.label type')}</div>
                            <div className='alert-ty-1-value'>
                                {opts.optionsValues.category === 'location'
                                    ? t('Location Average')
                                    : opts.optionsValues.collection_name}
                            </div>
                        </Col>
                        <Col span={4}>
                            <div className='alert-ty-1-label'>{t('kiosk.label indicator')}</div>
                            <div className='alert-ty-1-value'>{opts.optionsValues.data_channel_name}</div>
                        </Col>
                        <Col span={4}>
                            <div className='alert-ty-1-label'>{t('kiosk.label limit')}</div>
                            <div className='alert-ty-1-value'>{opts.optionsValues.type === 'max' ? t('MAX') : t('MIN')}</div>
                        </Col>
                        <Col span={4}>
                            <div className='alert-ty-1-label'>{t('kiosk.label value')}</div>
                            <div className='alert-ty-1-value'>{opts.optionsValues.value}</div>
                        </Col>
                    </Row>
                </div>
            )}
            {opts.optionTemplate === AlertPanelChoices.ONLINE && (
                <div className='alert-panel-header'>
                    <div className='alert-ty-2-label'>{t('kiosk.label offline')}</div>
                    <span className='alert-ty-2-desc'>{t('kiosk.example')}</span>
                    <div className='noti-item-wrap'>
                        <div className='noti-item-icon'>
                            <img src={dsIcon} className='noti-tableIcon' alt='' />
                        </div>
                        <div className='noti-item-inner-content'>
                            <div>
                                <b className='noti-item-title'>1116210416</b>
                                <span className='noti-rep'>{t('Notification.reported')}</span>
                                <span className={'noti-status offline'}>{t('Notification.offline')}</span>
                            </div>
                            <i className='noti-date'>{notificationDate('2020-07-06 11:45')}</i>
                        </div>
                    </div>
                </div>
            )}
            <div className='alert-panel-title'>{t('kiosk.advice')}</div>
            <div>
                <Input.TextArea
                    defaultValue={defaultValue}
                    onChange={e => {
                        if (opts.optionTemplate === AlertPanelChoices.ONLINE) {
                            ConfigurationAdvice.offline = e.target.value
                        } else if (opts.optionsValues) {
                            ConfigurationAdvice.configs[opts.optionsValues.id] = e.target.value
                        }
                        movedDownSetState({
                            currentKiosk
                        })
                    }}
                />
            </div>
        </div>
    )
}
