import * as React from 'react';
import DataTable from 'containers/DataTable';
import Detail from 'containers/LocationDetail';
import Graph from 'containers/Graph';
import KioskList from 'containers/KioskList';
import List from 'containers/LocationList';
import LocationNeed from 'components/LocationNeed';
import Report from 'containers/ReportList';
import Settings from 'containers/Settings';
import WorkspaceOverview from 'containers/WorkspaceOverview';
import { connect } from 'react-redux';
import { message } from 'antd';
import { P, StateProps } from 'pages/Main/type';
import { promiseDispatch } from 'utils';
import { requestLocationSelect } from 'actions/V3/locationSelect';
import { requestUserPermission } from 'actions/V3/permission';
import { Switch, withRouter, Route } from 'react-router-dom';
import { t } from 'i18next';
import { updateSiteState } from 'actions/uiState';
import './index.css';
import { Guard } from 'utils/guard';

@Guard
class Main extends React.PureComponent<P> {
    componentDidMount() {
        const inWorkspace = !!this.props.list.find(
            (v) => v.id === +this.props.match.params.id
        );

        if (inWorkspace) {
            this.fetchData();
        } else {
            message.warn(t('workspace.not in'));
            this.props.history.replace('/workspaces');
        }
    }

    UNSAFE_componentWillReceiveProps(next: P) {
        const id = this.props.match.params.id;
        const nextID = next.match.params.id;

        if (id !== nextID) {
            this.fetchData(next);
        }
    }

    componentWillUnmount() {
        const { dispatch } = this.props;

        dispatch(
            updateSiteState({
                showNavbar: false
            })
        );
    }

  GuardHandle_averageAndOutdoor = (rule?: GuardRule) => rule && rule.enable;

  fetchData = async (props = this.props) => {
      const {
          dispatch,
          match: {
              params: { id }
          },
          workspaceID
      } = props;

      const siteState = {
          showNavbar: true,
          locationID: '',
          indicatorID: '',
          collectionID: '',
          datasourceID: '',
          settingsURL: '',
          collectionsURL: '',
          indicatorsURL: '',
          datasourcesURL: '',
          peopleURL: '',
          originURL: ''
      };

      if (workspaceID !== id) {
          (siteState as any).workspaceID = id;
      }

      dispatch(updateSiteState(siteState));

      const actionPromise: Promise<any>[] = [];

      actionPromise.push(
          promiseDispatch({
              dispatch,
              actionCreator: requestLocationSelect,
              payload: {
                  workspace_id: id
              }
          })
      );

      actionPromise.push(
          promiseDispatch({
              dispatch,
              actionCreator: requestUserPermission,
              payload: {
                  workspace_id: id
              }
          })
      );
      await Promise.all(actionPromise);
  };

  render() {
      const { location } = this.props;
      const feature = !!this.GuardHandle_averageAndOutdoor();
      return (
          <div className='location-wrapper'>
              <div className='location-inner'>
                  <Switch location={location}>
                      <Route
                          exact={true}
                          path='/workspaces/:id/'
                          component={WorkspaceOverview}
                      />
                      <Route
                          exact={true}
                          path='/workspaces/:id/locations'
                          render={() => <List type='workspace' />}
                      />
                      <Route
                          path='/workspaces/:id/locations/:location_id'
                          component={Detail}
                      />
                      <Route
                          exact={true}
                          path='/workspaces/:id/graph'
                          component={LocationNeed}
                      />
                      <Route
                          exact={true}
                          path='/workspaces/:id/data'
                          component={DataTable}
                      />
                      <Route path='/workspaces/:id/reports' component={Report} />
                      <Route
                          path='/workspaces/:id/graph/:location_id'
                          render={() => (
                              <Graph features={{ outdoor: feature, average: feature }} />
                          )}
                      />
                      <Route path='/workspaces/:id/kiosk' component={KioskList} />
                      <Route path='/workspaces/:id' component={Settings} />
                  </Switch>
              </div>
          </div>
      );
  }
}

const mapStateToProps: MapState<StateProps> = ({
    uiState: {
        siteState: { workspaceID }
    },
    V2: {
        userShip: { all }
    }
}) => ({
    workspaceID,
    list: all
});

export default withRouter(connect(mapStateToProps)(Main));
