import { REQUEST_FAILED, REQUEST_SUCCESS, REQUEST_START, GloblalAction } from 'actions/global'

export type GlobalState = {
  loading: boolean,
  message: string,
  error: boolean
}

export default function Global(
  state: GlobalState = {
    loading: false,
    message: '',
    error: false
  },
  action: GloblalAction
) {
  switch (action.type) {
    case REQUEST_START:
      if (action.show) {
        return {
          ...state,
          error: false,
          message: '',
          loading: true
        }
      }
      return state
    case REQUEST_SUCCESS:
      return {
        ...state,
        error: false,
        message: '',
        loading: false
      }
    case REQUEST_FAILED:
      return {
        ...state,
        error: true,
        message: action.message,
        loading: false,
        forceLoading: false
      }
    default:
      return state
  }
}
