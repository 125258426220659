import activeLog from 'sagas/V3/activeLog';
import collection, {
    collectionDetailWatcher,
    collectionForSelectorWatcher
} from 'sagas/V3/collections';
import config from 'sagas/V3/config';
import datasources, {
    watchDatachannelCalibrations,
    watchDatachannelEdit,
    watchDatasourceBrands,
    watchDatasourceDelete,
    watchDatasourceDetail,
    watchDatasourceEdit
} from 'sagas/V3/datasources';
import downloads from 'sagas/V3/downloads';
import indicator, {
    indicatorAvailableDataSourceWatcher,
    indicatorDataSourceWatcher,
    indicatorDetailWatcher
} from 'sagas/V3/indicators';
import kiosks, {
    watchKioskDelete,
    watchKioskDetail,
    watchKioskEdit,
    watchKioskEditInfo,
    watchKioskNew
} from 'sagas/V3/kiosks';
import location, { watchLocationEdit, watchLocationUpdate } from 'sagas/V3/location';
import locationList, {
    watchLocationListAvailable
} from 'sagas/V3/locationList';
import locationSelect from 'sagas/V3/locationSelect';
import notificationConfig from 'sagas/V3/notificationConfig';
import orgBillings, {
    watchBillingDetail,
    watchOrgBillingOV,
    watchWorkspacesBilling,
    watchWorkspacesBillingDetail,
    watchMonthBilling
} from 'sagas/V3/billing';
import orgList, {
    watchDeleteOrgUser,
    watchNewOrgUser,
    watchOrgDetail,
    watchOrgHistories,
    watchOrgUsers,
    watchUpdateOrgUser
} from 'sagas/V3/org';
import seacrh, { watchSearchDetail } from 'sagas/V3/search';
import tabledata from 'sagas/V3/tabledata';
import userConfig from 'sagas/V3/userConfig';
import workspace, {
    watchWorkspaceDelete,
    watchWorkspaceEdit,
    watchWorkspacesList,
    watchWorkspacesNew
} from 'sagas/V3/workspace';
import { all, fork } from 'redux-saga/effects';
import { permission, refreshPermission } from 'sagas/V3/permission';
import { reportDetail, reportList, reportTemplate } from 'sagas/V3/report';
import follow from 'sagas/V3/following';
import { watchAdminParamsBanner } from 'sagas/V3/adminParams';

export default function* watch() {
    yield all([
        fork(location),
        fork(watchLocationEdit),
        fork(watchLocationUpdate),
        fork(downloads),
        fork(workspace),
        fork(watchWorkspacesList),
        fork(watchWorkspaceDelete),
        fork(watchWorkspaceEdit),
        fork(watchWorkspacesNew),
        fork(kiosks),
        fork(tabledata),
        fork(watchKioskEdit),
        fork(watchKioskDetail),
        fork(watchKioskNew),
        fork(watchKioskEditInfo),
        fork(collection),
        fork(indicator),
        fork(indicatorDetailWatcher),
        fork(watchKioskDelete),
        fork(datasources),
        fork(watchDatasourceDetail),
        fork(watchDatasourceEdit),
        fork(watchDatasourceDelete),
        fork(watchDatachannelEdit),
        fork(watchDatasourceBrands),
        fork(watchDatachannelCalibrations),
        fork(watchDatasourceBrands),
        fork(indicatorAvailableDataSourceWatcher),
        fork(indicatorDataSourceWatcher),
        fork(seacrh),
        fork(watchSearchDetail),
        fork(locationList),
        fork(watchLocationListAvailable),
        fork(notificationConfig),
        fork(userConfig),
        fork(activeLog),
        fork(locationSelect),
        fork(config),
        fork(collectionDetailWatcher),
        fork(collectionForSelectorWatcher),
        fork(reportList),
        fork(reportTemplate),
        fork(reportDetail),
        fork(orgList),
        fork(orgBillings),
        fork(watchOrgBillingOV),
        fork(watchBillingDetail),
        fork(watchOrgUsers),
        fork(watchOrgHistories),
        fork(watchOrgDetail),
        fork(watchNewOrgUser),
        fork(watchDeleteOrgUser),
        fork(watchUpdateOrgUser),
        fork(permission),
        fork(refreshPermission),
        fork(follow),
        fork(watchWorkspacesBilling),
        fork(watchWorkspacesBillingDetail),
        fork(watchMonthBilling),
        fork(watchAdminParamsBanner)
    ]);
}
