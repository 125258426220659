import { changeModal, ModalChangeAction } from 'actions/uiState';
import {
  requestCollection, RequestCollectionAction, REQUEST_COLLECTIONS_V3
} from 'actions/V3/collections';
import {
  IndicatorItem,
  requestIndicator,
  RequestIndicatorAction
} from 'actions/V3/indicators';
import { RefreshPermission, refreshPermission } from 'actions/V3/permission';
import { Checkbox, Icon, Input } from 'antd';
import Button from 'components/Custom/Button';
import Table from 'components/Custom/Table';
import SearchBar from 'components/SearchBar';
import { t } from 'i18next';
import { xor } from 'lodash';
import * as React from 'react';
import Highlighter from 'react-highlight-words';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import cache from 'sagas/cache';
import { apiV3 } from 'utils/api';
import './index.css';

interface ApiResponse {
    data: any;
}

type State = Readonly<{
    name: string;
    showIndicator: boolean;
    checked: number[];
    id?: number;
}>;

type StateProps = {
    indicators: IndicatorItem[];
    meta?: Meta;
    query?: {};
};

type Props = {
    type?: 'add' | 'bind';
    collectionID?: number;
    name?: string;
    close: () => void;
};

type P = Props &
    DispatchProp<
        | RequestIndicatorAction
        | ModalChangeAction
        | RefreshPermission
        | RequestCollectionAction
    > &
    StateProps &
    RouteComponentProps<{ id: string }>;

class AddCollection extends React.PureComponent<P, State> {
    static defaultProps = {
        type: 'add' as 'add',
        name: ''
    };
    resetCache = cache.resetCache.bind(null, REQUEST_COLLECTIONS_V3);

    columns = [
        {
            dataIndex: 'check',
            key: 'check',
            width: 50,
            render: checked => (
                <div style={{ textAlign: 'center' }}>
                    <Checkbox checked={checked} className='cricle_checkbox' />
                </div>
            )
        },
        {
            title: t('ASSIGNED DATA SOURCE'),
            dataIndex: 'dataSource',
            width: '10rem',
            key: 'dataSource',
            render: text => <span className='grey_text'>{text}</span>
        },
        {
            title: t('INDICATOR NAME'),
            dataIndex: 'indicatorName',
            width: '10rem',
            key: 'indicatorName'
        },
        {
            title: t('PARAMETERS TYPES'),
            dataIndex: 'parameter',
            width: '10rem',
            key: 'parameter',
            render: text => <span className='grey_text'>{text}</span>
        }
    ];

    constructor(props: P) {
        super(props);
        this.state = {
            name: props.name!,
            id: props.collectionID,
            showIndicator: props.type === 'bind',
            checked: []
        };
    }

    componentDidMount() {
        this.fetchIndicators();
    }

    fetchIndicators = (query = {}) => {
        const {
            match: {
                params: { id }
            },
            dispatch
        } = this.props;
        dispatch(requestIndicator({ ...query, workspace_id: +id, size: 10 }));
    };

    selectPage = page => {
        const { query } = this.props;
        this.fetchIndicators({ ...query, page, show: false });
    };

    search = (text: string): void => {
        const name = text.trim();
        const {
            dispatch,
            match: {
                params: { id }
            }
        } = this.props;

        dispatch(
            requestIndicator({
                q: name,
                workspace_id: +id
            })
        );
    };

    pagination = () => {
        const { meta } = this.props;
        return meta
            ? {
                hideOnSinglePage: true,
                showQuickJumper: true,
                style: {
                    padding: '0 20px'
                },
                onChange: this.selectPage,
                current: meta.current_page,
                defaultPageSize: 10,
                total: meta.total_count
            }
            : {};
    };

    renderHighlight = text => {
        const { query } = this.props;
        return query && (query as any).q ? (
            <Highlighter
                searchWords={[(query as any).q]}
                autoEscape={true}
                textToHighlight={text}
            />
        ) : (
            text
        );
    };

    createTable = () => {
        const { indicators } = this.props;
        const { checked } = this.state;
        return indicators.map((v, i) => ({
            key: i,
            id: v.id,
            check: checked.includes(v.id),
            indicatorName: this.renderHighlight(v.name),
            parameter: v.data_channel_name,
            dataSource:
                v.data_source && this.renderHighlight(v.data_source.identifier)
        }));
    };

    createCollection = async () => {
        const {
            match: {
                params: { id }
            },
            dispatch
        } = this.props;
        const { name } = this.state;
        this.resetCache();
        const response: ApiResponse = await apiV3('/collections', 'post', {
            collection: {
                name,
                workspace_id: id
            }
        });
        const { data } = response;

        dispatch(
            requestCollection({
                workspace_id: +id
            })
        );

        dispatch(
            refreshPermission({
                show: false
            })
        );
        this.setState({ showIndicator: true, id: data.id });
    };

    bindIndicator = async () => {
        const { checked, id } = this.state;
        this.resetCache();
        await apiV3(`/collections/${id}/indicators`, 'post', {
            indicators: checked
        });
        this.props.close();
    };

    rowClick = record => {
        const { checked } = this.state;
        const check = xor(checked, [record.id]);
        return {
            onClick: () => this.setState({ checked: check })
        };
    };

    assignIndicator = () => {
        const { dispatch, close } = this.props;
        close();
        dispatch(changeModal('AssignList'));
    };

    render() {
        const { name, showIndicator, checked } = this.state;
        const { meta } = this.props;
        return showIndicator ? (
            <>
                <h3 className='register-title'>
                    {t('collection.Add indicators to Collection')}
                </h3>
                <div className='register-body' style={{ marginTop: '1rem' }}>
                    <div className='register-collection'>
                        <h3>{name}</h3>
                        <p
                            className='register-device-label small_text'
                            style={{ marginBottom: '0.625rem' }}
                        >
                            {t(
                                'collection.Select the data source you would like to put into your collection'
                            )}
                        </p>
                    </div>

                    <div className='indicator-search'>
                        <SearchBar
                            onSearch={this.search}
                            className='colletion_modal-search'
                            placeholder={t('collection.Search by data source name or UUID')}
                            iconStyle={{
                                marginLeft: 0
                            }}
                        />
                        {meta && meta.total_count ? (
                            <Table
                                type='dark'
                                onRow={this.rowClick}
                                pagination={this.pagination()}
                                dataSource={this.createTable()}
                                columns={this.columns}
                            />
                        ) : (
                            <p className='register-no_result'>
                                {t('No resultes found please')}
                                <a className='accent_text' onClick={this.assignIndicator}>
                                    {t('datasource.Assign Indicator')}
                                </a>
                                {t('No resultes first')}
                            </p>
                        )}
                    </div>
                </div>
                <p className='indicator-message small_text'>
                    {t('collection.to be added to this collection', {
                        count: checked.length
                    })}
                </p>
                <div className='indicator-button'>
                    <Button
                        onClick={this.props.close}
                        customType='light'
                        radius='rect'
                        size='small'
                    >
                        {t('Cancel')}
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                        disabled={!checked.length}
                        customType='dark'
                        onClick={this.bindIndicator}
                        radius='rect'
                        size='small'
                    >
                        {t('Finish')}
                    </Button>
                </div>
            </>
        ) : (
            <>
                <h3 className='register-title'>{t('collection.Create Collection')}</h3>
                <div className='register-body'>
                    <p className='register-message small_text'>
                        {t(
                            'collection.Collection&Indicator are used to organize how display data in Location'
                        )}
                    </p>
                    <div>
                        <div>
                            {t('Collection Name')}
                            <Icon
                                type='info-circle-o'
                                className='register-add_location-icon'
                            />
                        </div>
                        <Input
                            onChange={e => this.setState({ name: e.target.value })}
                            style={{ width: '100%', marginTop: 15 }}
                        />
                    </div>
                </div>
                <div className='register-button_group'>
                    <Button
                        disabled={!name}
                        customType='dark'
                        onClick={this.createCollection}
                    >
                        {t('Register.Confirm')}
                    </Button>
                    <span />
                </div>
            </>
        );
    }
}

const mapToState: MapState<StateProps> = ({ V3: { indicators } }) => ({
    indicators: indicators.data,
    query: indicators.query,
    meta: indicators.meta
});

export default withRouter(connect(mapToState)(AddCollection));
