import { CollectionsAction, GET_COLLECTIONS, CollectionObj } from 'actions/V2/collections'

export type CollectionsState = {
  data: CollectionObj[]
}

export default function Collections(
  state: CollectionsState = {
    data: []
  },
  action: CollectionsAction
) {
  switch (action.type) {
    case GET_COLLECTIONS:
      return {
        data: action.collections
      }
    default:
      return state
  }
}
