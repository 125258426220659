import { Action, ActionCreator } from 'redux'

export const REQUEST_ACCOUNTS = 'REQUEST_ACCOUNTS'
export const GET_ACCOUNTS = 'GET_ACCOUNTS'

export type Account = {
  id: number,
  name?: string,
  email: string,
  permission: 'admin' | 'regular'
}

export type RequestAccountAction = Action<typeof REQUEST_ACCOUNTS>

export interface GetAccountAction extends Action<typeof GET_ACCOUNTS> {
  accounts: Account[];
  meta: Meta;
  query: any;
}

export const requestAccounts: ActionCreator<RequestAccountAction> = args => ({
  type: REQUEST_ACCOUNTS,
  args
})

export const getAccounts: ActionCreator<GetAccountAction> = (accounts, meta, query) => ({
  type: GET_ACCOUNTS,
  accounts,
  meta,
  query
})
