import { put, call } from 'redux-saga/effects'

import {
  REQUEST_DATA_SOURCES_V3,
  getDatasources,
  REQUEST_DATA_SOURCE_DETAIL_V3,
  getDatasourceDetail,
  REQUEST_DATA_SOURCE_EDIT_V3,
  getDatasourceEdit,
  REQUEST_DATA_SOURCE_DELETE_V3,
  REQUEST_DATA_CHANNEL_EDIT_V3,
  getDatachannelEdit,
  getDatachannelCalibrations,
  REQUEST_DATA_CHANNEL_CALIBRATION_V2,
  getDatasourceDelete,
  getDatasrouceBrands,
  REQUEST_DATA_SOURCE_BRAND_V3
} from 'actions/V3/datasources'
import { watchRequest, watchRequestNoCancel } from 'sagas/helper'
import { apiV3 } from 'utils/api'
import apiV2 from 'utils/api'

type Args = {
  size?: number,
  page: number,
  workspace_id: number,
  name?: string,
  identifier?: string
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/data_sources', 'get', { ...args })
  yield put(getDatasources(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_DATA_SOURCES_V3, request)

function* requestDatasourceDetail(args: { id: number, channel: boolean }) {
  const { data } = yield call(apiV3, '/data_sources/' + args.id, 'get', {
    ...args
  })
  yield put(getDatasourceDetail(data))
  return data
}

export const watchDatasourceDetail = watchRequest(REQUEST_DATA_SOURCE_DETAIL_V3, requestDatasourceDetail)

type ArgsEdit = {
  id: string,
  data: {
    name?: string,
    identifier?: string,
    brand_id?: string,
    model_id?: string,
    offline_limit?: number,
    image?: string,
    enable_status_notification?: boolean
  }
}

function* requestDatasourceEdit(args: ArgsEdit) {
  const { data } = yield call(apiV3, '/data_sources/' + args.id, 'patch', {
    data_source: args.data
  })

  yield put(getDatasourceEdit(data))
  return data
}

export const watchDatasourceEdit = watchRequest(REQUEST_DATA_SOURCE_EDIT_V3, requestDatasourceEdit)

function* requestDatasourceDelete(args: { id: number }) {
  const { data } = yield call(apiV3, '/data_sources/' + args.id, 'delete')

  yield put(getDatasourceDelete({ id: args.id }))
  return data
}

export const watchDatasourceDelete = watchRequest(REQUEST_DATA_SOURCE_DELETE_V3, requestDatasourceDelete)

type ArgsChannelEdit = {
  id: number,
  channel_id: number,
  data: {
    name: string,
    unit: string
  }
}

function* requestDatachannelEdit(args: ArgsChannelEdit) {
  const { data } = yield call(apiV3, `/data_sources/${args.id}/channels/${args.channel_id}`, 'patch', { ...args.data })

  yield put(getDatachannelEdit(data))
  return data
}

export const watchDatachannelEdit = watchRequest(REQUEST_DATA_CHANNEL_EDIT_V3, requestDatachannelEdit)

function* requestDatachannelCalibrations(args: { datasource_id: number }) {
  const { data } = yield call(apiV2, `/monitors/${args.datasource_id}/calibrations`, 'get')

  yield put(getDatachannelCalibrations(data))
  return data
}

export const watchDatachannelCalibrations = watchRequest(
  REQUEST_DATA_CHANNEL_CALIBRATION_V2,
  requestDatachannelCalibrations
)

function* requestDatasourceBrands() {
  const { data } = yield call(apiV3, '/data_sources/brands', 'get')
  yield put(getDatasrouceBrands(data))
  return data
}

export const watchDatasourceBrands = watchRequest(REQUEST_DATA_SOURCE_BRAND_V3, requestDatasourceBrands)
