import * as React from 'react';
import Create from './Create';
import CreateReport from './CreateReport';
import List from './List';
import ReportView from './ReportView';
import TemplateList from './TemplateList';
import { Breadcrumb } from 'antd';
import { Guard } from 'utils/guard';
import {
    Link,
    RouteComponentProps,
    Route,
    Switch,
    withRouter
} from 'react-router-dom';
import { t } from 'i18next';
import './index.css';

@Guard
class Report extends React.PureComponent<
  RouteComponentProps<{ id: string }>,
  {}
> {
  renderBreadcrumb = () => {
      const {
          match: {
              params: { id }
          },
          location: { pathname, state }
      } = this.props;

      const inTemplate = pathname.includes('template');
      const inStrictTemplate = /\/reports\/template(?!\/)/.test(pathname);
      const inStrictList = /\/reports(?!\/)/.test(pathname);
      const inCreate = pathname.includes('template/create');
      const inEditTemplate = /\/reports\/template\/\d+/.test(pathname);
      const inReportCreate = pathname.includes('reports/create');
      const inReportView = /\/reports\/\d+/.test(pathname);
      const stateName = state && state.name;
      const routes = [
          inStrictList
              ? { name: t('Report.Report List') }
              : { path: `/workspaces/${id}/reports`, name: t('Report.Report List') },
          (inTemplate || inReportCreate) &&
        (inStrictTemplate
            ? {
                name: t('Report.Template')
            }
            : {
                path: `/workspaces/${id}/reports/template`,
                name: t('Report.Template')
            }),
          inCreate && {
              name: t('Report.Create New Template')
          },
          inEditTemplate && {
              name: t('Report.Edit Template')
          },
          inReportCreate && { name: stateName || t('Report.Request Report') },
          inReportView && { name: t('Report.View Report') }
      ].filter(Boolean) as { path?: string; name: string }[];

      return routes.map((item, index) => (
          <Breadcrumb.Item key={`report-breadcrumb-${index}`}>
              {item.path ? (
                  <Link to={item.path} className='location_detail-breadcrumb'>
                      {item.name}
                  </Link>
              ) : (
                  <span className='location_detail-breadcrumb'>{item.name}</span>
              )}
          </Breadcrumb.Item>
      ));
  }

  GuardRender_reportRoute = () => (
      <div className='report-container'>
          <div className='report-title'>
              <Breadcrumb
                  className='report-breadcrumb'
                  separator={
                      <i className='material-icons report-separator md-24'>
              keyboard_arrow_right
                      </i>
                  }
              >
                  {this.renderBreadcrumb()}
              </Breadcrumb>
          </div>
          <Switch>
              <Route exact={true} path='/workspaces/:id/reports' component={List} />
              <Route
                  exact={true}
                  path='/workspaces/:id/reports/template'
                  component={TemplateList}
              />
              <Route
                  exact={true}
                  path='/workspaces/:id/reports/template/create'
                  component={Create}
              />
              <Route
                  exact={true}
                  path='/workspaces/:id/reports/template/:template_id'
                  component={Create}
              />
              <Route
                  exact={true}
                  path='/workspaces/:id/reports/create'
                  component={CreateReport}
              />
              <Route
                  exact={true}
                  path='/workspaces/:id/reports/:report_id'
                  component={ReportView}
              />
          </Switch>
      </div>
  )

  render() {
      return this.GuardRender_reportRoute();
  }
}

export default withRouter(Report);
