import { Action, ActionCreator } from 'redux'

export const REQUEST_CONFIG = 'REQUEST_CONFIG'
export const GET_CONFIG = 'GET_CONFIG'

export type Config = {
  name: string,
  domain: string,
  logo: string,
  login_logo: string,
  theme_color: string
}

export interface GetConfigAction extends Action<typeof GET_CONFIG> {
  data: Config;
}

export type RequestConfigAction = Action<typeof REQUEST_CONFIG>

export const requestConfig: ActionCreator<RequestConfigAction> = args => ({
  type: REQUEST_CONFIG,
  args
})

export const getConfig = (data): GetConfigAction => ({
  type: GET_CONFIG,
  data
})
