import * as React from 'react';
import { apiV3 } from 'utils/api';
import { notification } from 'antd';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type LinkType =
  | 'locations'
  | 'collections'
  | 'indicators'
  | 'datasources'
  | 'graph'
  | 'reports'
  | 'kiosks'
  | 'data';

type Props = RouteComponentProps<{ id: string }> & { type?: LinkType };

class RedirectLink extends React.PureComponent<Props, {}> {
  static defaultProps = {
      type: 'locations' as LinkType
  };

  componentDidMount() {
      this.checkAndRedirect();
  }

  async checkAndRedirect() {
      const {
          match: {
              params: { id }
          },
          history
      } = this.props;

      const type = this.switchType();

      try {
          const { data } = await apiV3<any>('/metas/query', 'get', { type, id });
          this.switchLink(id, data.workspace_id);
      } catch (error: any) {
          notification.error({
              message: `code: ${error.code}`,
              description: `/metas/query 查询异常: ${error.message}`,
              duration: 0
          });
          return history.replace('/');
      }
  }

  switchType() {
      const { type } = this.props;
      const location = ['graph', 'data', 'locations'];
      if (!type || location.includes(type)) {
          return 'location';
      }

      if (type === 'datasources') {
          return 'data_source';
      }
      return type.slice(0, -1);
  }

  switchLink(id: string, wID: number) {
      const { history, type } = this.props;
      switch (type) {
      case 'locations':
          return history.replace(`/workspaces/${wID}/locations/${id}`);
      case 'graph':
          return history.replace(`/workspaces/${wID}/graph/${id}`);
      case 'data':
          return history.replace(`/workspaces/${wID}/data?location_id=${id}`);
      case 'datasources':
          return history.replace(`/workspaces/${wID}/datasources/${id}`);
      case 'indicators':
          return history.replace(`/workspaces/${wID}/indicators/${id}`);
      case 'kiosks':
          return history.replace(`/${id}`);
      case 'collections':
          return history.replace(`/workspaces/${wID}/collections/${id}`);
      case 'reports':
          return history.replace(`/workspaces/${wID}/reports/${id}`);
      default:
          return history.replace('/');
      }
  }

  render() {
      return null;
  }
}

export default withRouter(RedirectLink);
