import * as dictionary from 'actions/V2/dictionary'

export type DictionaryState = {
  cities: dictionary.CityItem[],
  time_zones: dictionary.TimeZoneItem[],
  // outdoors: dictionary.OutdoorItem[];
  indicators?: dictionary.IndicatorItem,
  monitor_configs: dictionary.MonitorConfigItem[]
}

export default function Dictionary(
  state: DictionaryState = {
    cities: [],
    time_zones: [],
    monitor_configs: []
  },
  action: dictionary.GetDictionaryAction
) {
  switch (action.type) {
    case dictionary.GET_DICTIONARY_DATA:
      return {
        ...state,
        [action.name]: action.data
      }
    default:
      return state
  }
}
