export const temperatureScale: any = {
  max: 'temperature',
  min: '19'
}
export const tvocScale: any = {
  good: 0.45,
  moderate: 0.55
}
export const humidityScale: any = {
  max: 60,
  min: 40
}
export const co2Scale: any = {
  excellent: 600,
  good: 1000,
  moderate: 1500,
  sensitive: 2500,
  impaired: 5000
}
export const pm2p5Scale: any = {
  good: 35,
  moderate: 75,
  sensitive: 100,
  unhealthy: 120,
  veryUnhealthy: 200
}
