import * as React from 'react'
import Button from 'components/Custom/Button'
import classnames from 'classnames'
import moment from 'moment'
import { apiV3 } from 'utils/api'
import { Checkbox, Col, Input, Row } from 'antd'
import { connect } from 'react-redux'
import { P, State, StateProps } from './type'
import { requestWorkspace, REQUEST_WORKSPACE_LIST } from 'actions/V3/workspace'
import { t } from 'i18next'
import { withRouter } from 'react-router-dom'
import './index.css'
import cache from 'sagas/cache'

class Upgrade extends React.PureComponent<P, State> {
  state: State = {
    showDetail: false,
    canUpgrade: false
  }

  list = [
    {
      title: t('pricing.basic'),
      price: 0,
      message: t('pricing.free'),
      button: t('pricing.getBasic'),
      buttonType: 'light',
      to: '/user/sign_up',
      content: [
        {
          title: t('pricing.data.title'),
          list: [
            {
              message: t('pricing.data.up tp 2'),
              status: 'check'
            },
            {
              message: t('pricing.data.7 days'),
              status: 'check'
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            }
          ]
        },
        {
          title: t('pricing.management.title'),
          list: [
            {
              message: t('pricing.management.up to 1 location'),
              status: 'check'
            },
            {
              message: t('pricing.management.up to 2 collections'),
              status: 'check'
            },
            {
              message: t('pricing.management.up tp 1 kiosk'),
              status: 'check'
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            }
          ]
        },
        {
          title: t('pricing.analytics.title'),
          list: [
            {
              message: t('pricing.analytics.up to 7 days'),
              status: 'check'
            },
            {
              message: t('pricing.analytics.access to graph'),
              status: 'check'
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            },
            {
              message: '',
              status: ''
            }
          ]
        },
        {
          title: t('pricing.user.title'),
          list: [
            {
              message: t('pricing.user.up to 3 users'),
              status: 'check'
            },
            {
              message: '',
              status: ''
            }
          ]
        }
      ]
    },
    {
      title: t('pricing.pro'),
      price: 16,
      message: (
        <>
          <span className='accent_text'>$ 0.2</span>
          <span className='gery_text'>{t('upgrade.indicator/day')}</span>
        </>
      ),
      // button: t('footer.contact'),
      // buttonType: 'dark',
      // to: '/contact',
      content: [
        {
          title: t('pricing.data.title'),
          list: [
            {
              message: t('pricing.data.unlimited air quality'),
              status: 'bold'
            },
            {
              message: t('pricing.data.unlimited historical data'),
              status: 'bold'
            },
            {
              message: t('pricing.data.derived indicators'),
              status: 'bold'
            },
            {
              message: t('pricing.data.energy light sound'),
              status: 'bold'
            },
            {
              message: t('pricing.data.download historical data'),
              status: 'bold'
            }
          ]
        },
        {
          title: t('pricing.management.title'),
          list: [
            {
              message: t('pricing.management.unlimited locations'),
              status: 'bold'
            },
            {
              message: t('pricing.management.unlimited collections'),
              status: 'bold'
            },
            {
              message: t('pricing.management.unlimited kiosks'),
              status: 'bold'
            },
            {
              message: t('pricing.management.free location'),
              status: 'bold'
            },
            {
              message: t('pricing.management.outdoor monitors'),
              status: 'bold'
            },
            {
              message: t('pricing.management.locking and sharing'),
              status: 'bold'
            }
          ]
        },
        {
          title: t('pricing.analytics.title'),
          list: [
            {
              message: t('pricing.analytics.unlimited online data visualization'),
              status: 'bold'
            },
            {
              message: t('pricing.analytics.6d access to graph'),
              status: 'bold'
            },
            {
              message: t('pricing.analytics.access to data table'),
              status: 'bold'
            },
            {
              message: t('pricing.analytics.access to reports'),
              status: 'bold'
            },
            {
              message: t('pricing.analytics.advanced notifications'),
              status: 'bold'
            },
            {
              message: t('pricing.analytics.api access'),
              status: 'bold'
            }
          ]
        },
        {
          title: t('pricing.user.title'),
          list: [
            {
              message: t('pricing.user.unlimited users'),
              status: 'bold'
            },
            {
              message: t('pricing.user.user roles'),
              status: 'bold'
            }
          ]
        }
      ]
    }
  ]

  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { workspace_id }
      }
    } = this.props
    dispatch(
      requestWorkspace({
        workspace_id
      })
    )
  }

  forbinUpgradeForPerson = () => {
    return this.props.match.path.match('intro')
  }

  onAgree = e => {
    this.setState({
      canUpgrade: e.target.checked
    })
  }

  backList = () => {
    const { history } = this.props
    history.replace('/workspaces')
  }

  toggleDetail = e => {
    e.preventDefault()
    const { showDetail } = this.state
    this.setState({
      showDetail: !showDetail
    })
  }

  onConfirmWsName = e => {
    this.setState({
      workspaceName: e.target.value
    })
  }

  renderUpgradeMsg = workspace => {
    const today = moment().format('LL')
    const forbinUpgradeForPerson = this.forbinUpgradeForPerson()
    const tomorrow = moment(new Date()).add(1, 'days').format('LL')
    if (forbinUpgradeForPerson) {
      return (
        <p>
          {t('Workspace Name')}: {workspace.name} (ID: {workspace.code})
        </p>
      )
    }
    return (
      <p>
        {t('upgrade.if')} {workspace.name} (ID: {workspace.code}){workspace.pro ? t('upgrade.tobasic') : t('upgrade.toPro')}，
        {workspace.pro
          ? t('upgrade.tobasic info', { date: today })
          : t('upgrade.topro info', {
              date: tomorrow,
              num: workspace.counter && workspace.counter.indicator ? workspace.counter.indicator.total : 0
            })}
      </p>
    )
  }

  doUpgrade = workspace => {
    const { history } = this.props
    apiV3(`/workspaces/${workspace.id}/${workspace.pro ? 'downgrade' : 'upgrade'}`, 'post').then(() => {
      cache.cleanCache(REQUEST_WORKSPACE_LIST)
      history.replace('/workspaces/' + workspace.id)
    })
  }

  render() {
    const { showDetail, canUpgrade, workspaceName } = this.state
    const { workspace } = this.props
    if (!workspace) {
      return null
    }

    let canUpgradeBtn = canUpgrade
    if (workspace.pro) {
      canUpgradeBtn = canUpgradeBtn && workspaceName === workspace.name.trim()
    }
    const forbinUpgradeForPerson = this.forbinUpgradeForPerson()

    return (
      <div className='location-wrapper'>
        <div className='location-inner'>
          <div className='upgrade-container'>
            <h2 className='upgrade-container-title'>{workspace.pro ? t('upgrade.tobasic') : t('upgrade.toPro')}</h2>
            <div className='upgrade-content'>
              {this.renderUpgradeMsg(workspace)}
              <p>{workspace.pro ? t('upgrade.tobasic desc') : t('upgrade.topro desc')}</p>
              <ol className={classnames({ down: !!workspace.pro })}>
                <li>{t('upgrade.benefit1')}</li>
                <li>{t('upgrade.benefit2')}</li>
                <li>{t('upgrade.benefit3')}</li>
                {/* <li>{t('upgrade.benefit4')}</li>
                <li>{t('upgrade.benefit5')}</li> */}
              </ol>
              <p>
                <a href='#' onClick={this.toggleDetail}>
                  {showDetail ? t('upgrade.Pack Up') : t('upgrade.More Detail')}
                </a>
              </p>
              {showDetail && (
                <div className='pricing-list pricing-cardList'>
                  {this.list.map((item, index) => (
                    <Col span={12} key={`pricing-list-${index}`} className='pricing-list-col'>
                      <div className='pricing-list-card'>
                        <div className='pricing-list-head'>
                          <h2 className='title'>{item.title}</h2>
                          <h4 className='subtitle pricing-list-message'>{item.message}</h4>
                          <p>
                            <span className='common-line' />
                          </p>
                        </div>
                        <div className='pricing-list-content'>
                          {item.content.map((list, _index) => (
                            <div key={`pricing-list-${index}-${_index}`} className='pricing-list-part'>
                              <h3 className='subtitle'>{list.title}</h3>
                              {list.list.map((li, i) => (
                                <React.Fragment key={`pricing-li-${i}`}>
                                  <h4 className={classnames('subtitle', 'pricing-list-item', `is-${li.status}`)}>{li.message}</h4>
                                </React.Fragment>
                              ))}
                            </div>
                          ))}
                        </div>
                        <p className='pricing-list-button'>
                          <span className='common-line' />
                        </p>
                      </div>
                    </Col>
                  ))}
                </div>
              )}
              {workspace.pro && (
                <p className='input-workspace-name'>
                  <Input onChange={this.onConfirmWsName} placeholder={t('upgrade.please input tip')} />
                </p>
              )}
              {!forbinUpgradeForPerson && (
                <p>
                  <Checkbox onChange={this.onAgree}>
                    {workspace.pro ? t('upgrade.confirm downgrade') : t('upgrade.confirm upgrade')}
                  </Checkbox>
                </p>
              )}
              {!forbinUpgradeForPerson ? (
                <Row gutter={16} className='bot-action'>
                  <Col span={12}>
                    <Button block={true} customType='light' onClick={this.backList}>
                      {t('Cancel')}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button block={true} disabled={!canUpgradeBtn} customType='dark' onClick={() => this.doUpgrade(workspace)}>
                      {workspace.pro ? t('Downgrade') : t('Upgrade')}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <p>
                  {t('upgrade.contact')}
                  <a style={{ marginLeft: '10px' }} href='mailto:support@qlear.io'>
                    support@qlear.io
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { workspace } }) => ({
  workspace: workspace.data
})

export default withRouter(connect(mapStateToProps)(Upgrade))
