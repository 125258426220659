import * as React from 'react';
import {
    ActivityCity,
    requestActivity,
    RequestActivityAction
} from 'actions/V2/activities';
import { Breadcrumb, Skeleton } from 'antd';
import { connect } from 'react-redux';
import { DispatchProp } from 'react-redux';
import { entries } from 'lodash';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import LazyLoad from 'react-lazyload';
import './index.css';
type StateProps = {
  cities?: ActivityCity;
};

export type P = StateProps & DispatchProp<RequestActivityAction>;

class CityList extends React.PureComponent<P, {}> {
    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(requestActivity());
    }

  renderList = () => {
      const { cities } = this.props;

      return entries(cities!).map(([type, cityArr], index) => {
          const restNum = 3 - (cityArr.length % 3);
          const arr = Array(restNum).fill(undefined);
          return (
              <React.Fragment key={`cities-${type}-${index}`}>
                  <div className='city-title'>{type}</div>
                  <div className='city-container'>
                      {cityArr.concat(arr).map((v, i) =>
                          v ? (
                              <Link key={`cities-${type}-${i}`} to={`/public/${v.id}`}>
                                  <LazyLoad height={280}>
                                      <div
                                          className='city-card'
                                          style={{ backgroundImage: `url(${v.image})` }}
                                      >
                                          <div className='city-layer'>
                                              <h4>{v.name}</h4>
                                          </div>
                                      </div>
                                  </LazyLoad>
                              </Link>
                          ) : (
                              <div
                                  className='city-card is-empty'
                                  key={`cities-${type}-${i}`}
                              />
                          )
                      )}
                  </div>
              </React.Fragment>
          );
      });
  };

  render() {
      const { cities } = this.props;
      return (
          <>
              <div className='home-tab-header is-workspace'>
                  <Breadcrumb
                      separator={
                          <i className='material-icons location_detail-header-arrow'>
                keyboard_arrow_right
                          </i>
                      }
                  >
                      <Breadcrumb.Item>
                          <Link to='/public' className='location_detail-breadcrumb'>
                              {t('public.Public Locations')}
                          </Link>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>{t('City')}</Breadcrumb.Item>
                  </Breadcrumb>
              </div>
              {cities ? this.renderList() : <Skeleton loading={true} active={true} />}
          </>
      );
  }
}

const mapStateToProps: MapState<StateProps> = ({ V2: { activies } }) => ({
    cities: activies.data
});

export default connect(mapStateToProps)(CityList);
