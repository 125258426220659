import * as React from 'react'
import AllLocation from 'pages/AllLocation'
import Billing from 'pages/Billing/Route'
import Changelog from 'pages/Changelog'
import Container from 'containers/Container'
import DownloadCenter from 'containers/DownloadCenter'
import Header from 'containers/Header'
import Invoice from 'pages/Invoice/Route'
import LoginedRoute from 'decorate/LoginedRoute'
import Profile from 'pages/Profile'
import PublicLocation from 'pages/PublicLocation'
import RedirectLink from 'pages/RedirectLink'
import Search from 'pages/Search'
import Upgrade from 'pages/Upgrade'
import Workspaces from 'pages/Workspaces'
import Dashboard from 'pages/Dashboard'
import { Route, Switch } from 'react-router-dom'
import Main from 'pages/Main'
import Notification from 'pages/Notification'
// const Main = load(import('pages/Main'));
// const Notification = load(import('pages/Notification'));
// import RedirectQuery from 'pages/RedirectQuery';
export const redirectTypes = [
  'locations',
  'collections',
  'indicators',
  'datasources',
  'kiosks',
  'graph',
  'data',
  'reports'
]
const Layout: React.SFC<{}> = () => (
  <>
    <Header />
    <Container>
      <Switch>
        <Route path='/changelog' component={Changelog} />
        <LoginedRoute path='/workspaces/:id' component={Main} />
        <LoginedRoute path='/notification' component={Notification} />
        <LoginedRoute exact={true} path='/search' component={Search} />
        <LoginedRoute exact={true} path='/download' component={DownloadCenter} />
        <LoginedRoute exact={true} path='/profile' component={Profile} />
        <LoginedRoute exact={true} path='/password' component={Profile} props={{ type: 'password' }} />
        <LoginedRoute exact={true} path='/workspaces' component={Workspaces} />
        <LoginedRoute
          exact={true}
          path={['/upgrade/:workspace_id', '/upgrade/:workspace_id/intro']}
          component={Upgrade}
        />
        <LoginedRoute exact={true} path='/locations' component={AllLocation} />
        <LoginedRoute exact={true} path='/favorites' component={Dashboard} />
        <LoginedRoute path='/billing' component={Billing} />
        <LoginedRoute path='/invoice' component={Invoice} />
        <Route path='/public' component={PublicLocation} />
        {redirectTypes.map((t, i) => (
          <LoginedRoute key={t} exact={true} path={`/${t}/:id`} component={RedirectLink} props={{ type: t }} />
        ))}
      </Switch>
    </Container>
  </>
)

// {/* 重定向 */}
// <LoginedRoute exact={true} path='/q/:id' component={RedirectQuery} />

export default Layout
