import * as React from 'react'
import ConfigControl from './ConfigControl'

const Layout: React.SFC<{}> = React.memo(props => {
  const { children } = props
  const [childReady, setChildReady] = React.useState(false)

  return (
    <>
      <ConfigControl setReady={() => setChildReady(true)} />
      {childReady ? children : null}
    </>
  )
})

export default Layout
