import { Action, ActionCreator } from 'redux'

export const REQUEST_DATA_SOURCES_V3 = 'REQUEST_DATA_SOURCES_V3'
export const GET_DATA_SOURCES_V3 = 'GET_DATA_SOURCES_V3'

export const REQUEST_DATA_SOURCE_DETAIL_V3 = 'REQUEST_DATA_SOURCE_DETAIL_V3'
export const GET_DATA_SOURCE_DETAIL_V3 = 'GET_DATA_SOURCE_DETAIL_V3'

export const REQUEST_DATA_SOURCE_EDIT_V3 = 'REQUEST_DATA_SOURCE_EDIT_V3'
export const GET_DATA_SOURCE_EDIT_V3 = 'GET_DATA_SOURCE_EDIT_V3'

export const REQUEST_DATA_SOURCE_DELETE_V3 = 'REQUEST_DATA_SOURCE_DELETE_V3'
export const GET_DATA_SOURCE_DELETE_V3 = 'GET_DATA_SOURCE_DELETE_V3'

// export const REQUEST_DATA_SOURCE_NEW_V3 = 'REQUEST_DATA_SOURCE_NEW_V3';
// export const GET_DATA_SOURCE_NEW_V3 = 'GET_DATA_SOURCE_NEW_V3';

export const REQUEST_DATA_CHANNEL_EDIT_V3 = 'REQUEST_DATA_CHANNEL_EDIT_V3'
export const GET_DATA_CHANNEL_EDIT_V3 = 'GET_DATA_CHANNEL_EDIT_V3'

export const REQUEST_DATA_CHANNEL_CALIBRATION_V2 = 'REQUEST_DATA_CHANNEL_CALIBRATION_V2'
export const GET_DATA_CHANNEL_CALIBRATION_V2 = 'GET_DATA_CHANNEL_CALIBRATION_V2'

export const REQUEST_DATA_SOURCE_BRAND_V3 = 'REQUEST_DATA_SOURCE_BRAND_V3'
export const GET_DATA_SOURCE_BRAND_V3 = 'GET_DATA_SOURCE_BRAND_V3'

export type DatasourceObj = {
  assigned_data_channels: string[],
  brand: string,
  brand_id: number,
  brand_name: string,
  calibrated: boolean,
  created_at: string,
  enable_status_notification: boolean,
  id: number,
  identifier: string,
  image?: string,
  model: string,
  model_id: number,
  model_name: string,
  name?: string,
  parameters: string[],
  search_time_enabled_at: string,
  status: string,
  unassigned_count: number,
  channels?: {
    abbr?: string,
    channel: string,
    channel_name: string,
    data_source_id: number,
    id: number,
    indicator: any,
    name?: string,
    status: string,
    unit: string
  }[]
}

type CalibrationObj = {
  calibration_id: number,
  formula: string,
  factors: {
    config: string,
    end_value: number,
    start_value: number,
    factor_id?: number
  }[]
}

export type DatasourceDetailObj = {
  assigned_data_channels: string[],
  brand: string,
  brand_id: number,
  brand_name: string,
  calibrated: boolean,
  channels?: {
    channel: string,
    channel_name: string,
    data_source_id: number,
    id: number,
    indicator: string,
    name: string,
    unit: string,
    abbr?: string
  }[],
  created_at: string,
  enable_status_notification: boolean,
  flatline_limit?: number,
  id: number,
  identifier: string,
  image?: string,
  last_averaged_at?: string,
  last_calibrated_at?: string,
  last_consumed_time?: string,
  last_reading_time?: string,
  last_received_time?: string,
  model: string,
  model_id: number,
  model_name: string,
  name: string,
  offline_limit?: number,
  parameters: string[],
  reading?: {
    reading_time: string,
    enqueue_time: string,
    source: string,
    type: string
  },
  search_time_enabled_at: string,
  status: string,
  unassigned_count: number
}

export type DatasourceDeleteObj = {
  id: number
}

export type DatachannelEditObj = {
  channel: string,
  channel_name: string,
  data_source_id: number,
  id: number,
  indicator: string,
  name: string,
  unit: string
}

export type Calibration = {
  indicator: string,
  indicator_name: string,
  calibration: CalibrationObj
}

export type Brand = {
  id: number,
  name: string,
  models: {
    id: number,
    name: string,
    image: string,
    type: string
  }[]
}

export interface RequestDatasourcesAction extends Action<typeof REQUEST_DATA_SOURCES_V3> {
  args: any;
}

export interface RequestDatasourceDetailAction extends Action<typeof REQUEST_DATA_SOURCE_DETAIL_V3> {
  args: any;
}

export interface RequestDatasourceEditAction extends Action<typeof REQUEST_DATA_SOURCE_EDIT_V3> {
  args: any;
}

export interface RequestDatasourceDeleteAction extends Action<typeof REQUEST_DATA_SOURCE_DELETE_V3> {
  args: any;
}

export interface RequestDatachannelEditAction extends Action<typeof REQUEST_DATA_CHANNEL_EDIT_V3> {
  args: any;
}

export interface RequestDatachannelCalibrationsAction extends Action<typeof REQUEST_DATA_CHANNEL_CALIBRATION_V2> {
  args: any;
}

export interface RequestDatasourceBrandAction extends Action<typeof REQUEST_DATA_SOURCE_BRAND_V3> {
  args: any;
}

interface GetDatasourcesAction extends Action<typeof GET_DATA_SOURCES_V3> {
  datasources: DatasourceObj[];
  meta: Meta;
  query: {};
}

interface GetDatasourceDetailAction extends Action<typeof GET_DATA_SOURCE_DETAIL_V3> {
  datasource: DatasourceDetailObj;
}

interface GetDatasourceEditAction extends Action<typeof GET_DATA_SOURCE_EDIT_V3> {
  datasource: DatasourceDetailObj;
}

interface GetDatasourceDeleteAction extends Action<typeof GET_DATA_SOURCE_DELETE_V3> {
  datasourceDel: DatasourceDeleteObj;
}

interface GetDatachannelEditAction extends Action<typeof GET_DATA_CHANNEL_EDIT_V3> {
  datachannel: DatachannelEditObj;
}

interface GetDatachannelCalibrationsAction extends Action<typeof GET_DATA_CHANNEL_CALIBRATION_V2> {
  calibrations: Calibration[];
}

interface GetDatasourceBrandAction extends Action<typeof GET_DATA_SOURCE_BRAND_V3> {
  brands: Brand[];
}

export type DatasourcesAction =
  | GetDatasourcesAction
  | GetDatasourceDetailAction
  | GetDatasourceDeleteAction
  | GetDatachannelEditAction
  | GetDatachannelCalibrationsAction
  | GetDatasourceBrandAction
  | GetDatasourceEditAction

export const requestDatasources: ActionCreator<RequestDatasourcesAction> = args => ({
  type: REQUEST_DATA_SOURCES_V3,
  args
})

export const requestDatasourceDetail: ActionCreator<RequestDatasourceDetailAction> = args => ({
  type: REQUEST_DATA_SOURCE_DETAIL_V3,
  args
})

export const requestDatasourceEdit: ActionCreator<RequestDatasourceEditAction> = args => ({
  type: REQUEST_DATA_SOURCE_EDIT_V3,
  args
})

export const requestDatasourceDelete: ActionCreator<RequestDatasourceDeleteAction> = args => ({
  type: REQUEST_DATA_SOURCE_DELETE_V3,
  args
})

// export const requestDatasourceNew: ActionCreator<
//   RequestDatasourceNewAction
// > = args => ({
//   type: REQUEST_DATA_SOURCE_NEW_V3,
//   args
// });

export const requestDatachannelEdit: ActionCreator<RequestDatachannelEditAction> = args => ({
  type: REQUEST_DATA_CHANNEL_EDIT_V3,
  args
})

export const requestDatachannelCalibrations: ActionCreator<RequestDatachannelCalibrationsAction> = args => ({
  type: REQUEST_DATA_CHANNEL_CALIBRATION_V2,
  args
})

export const requestDatasourceBrands: ActionCreator<RequestDatasourceBrandAction> = args => ({
  type: REQUEST_DATA_SOURCE_BRAND_V3,
  args
})

export const getDatasources: ActionCreator<GetDatasourcesAction> = (datasources, meta, query) => ({
  type: GET_DATA_SOURCES_V3,
  datasources,
  meta,
  query
})

export const getDatasourceDetail: ActionCreator<GetDatasourceDetailAction> = (datasource: DatasourceDetailObj) => ({
  type: GET_DATA_SOURCE_DETAIL_V3,
  datasource
})

export const getDatasourceEdit: ActionCreator<GetDatasourceEditAction> = (datasource: DatasourceDetailObj) => ({
  type: GET_DATA_SOURCE_EDIT_V3,
  datasource
})

export const getDatasourceDelete: ActionCreator<GetDatasourceDeleteAction> = (datasourceDel: DatasourceDeleteObj) => ({
  type: GET_DATA_SOURCE_DELETE_V3,
  datasourceDel
})

export const getDatachannelEdit: ActionCreator<GetDatachannelEditAction> = (datachannel: DatachannelEditObj) => ({
  type: GET_DATA_CHANNEL_EDIT_V3,
  datachannel
})

export const getDatachannelCalibrations: ActionCreator<GetDatachannelCalibrationsAction> = (
  calibrations: Calibration[]
) => ({
  type: GET_DATA_CHANNEL_CALIBRATION_V2,
  calibrations
})

export const getDatasrouceBrands: ActionCreator<GetDatasourceBrandAction> = (brands: Brand[]) => ({
  type: GET_DATA_SOURCE_BRAND_V3,
  brands
})
