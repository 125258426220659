import * as Indicator from 'components/LocationModals/Indicator'
import * as React from 'react'
import ActivityLogs from 'components/WorkspaceModals/activityLogs'
import AddCollection from 'components/LocationModals/AddCollection'
import AddDataSource from 'components/LocationModals/AddDatasource'
import AddLocation from 'components/LocationModals/AddLocation'
import Navbar from 'containers/Navbar'
import { changeModal, ModalChangeAction } from 'actions/uiState'
import { connect, DispatchProp } from 'react-redux'
import { Modal } from 'antd'
import './index.css'

interface StateProps {
  showNavbar: boolean;
  modal: ModalType;
}

type P = StateProps & DispatchProp<ModalChangeAction>

class Container extends React.PureComponent<P, {}> {
  changeModal = (modal: ModalType): void => {
    this.props.dispatch(changeModal(modal))
  }

  renderModal() {
    const { modal } = this.props
    switch (modal) {
      case 'AddDatasource':
        return <AddDataSource />
      case 'AssignList':
        return <Indicator.AssignTable />
      case 'AssignTable':
        return <Indicator.AssignTable />
      // case 'SelectLocation':
      //   return <DataSource.SelectLocation />;
      case 'AddLocation':
        return <AddLocation />
      case 'AddCollection':
        return <AddCollection close={() => this.changeModal('')} />
      case 'ActivityLogs':
        return <ActivityLogs />
      default:
        return null
    }
  }

  render() {
    const { showNavbar, children, modal } = this.props
    const wide = ['AssignList', 'ActivityLogs'].includes(modal)
    return (
      <div className='container-wrapper'>
        {showNavbar && <Navbar />}
        {children}
        <Modal
          visible={!!modal}
          closable={true}
          onCancel={() => this.changeModal('')}
          footer={null}
          width={wide ? 740 : 540}
          bodyStyle={{ padding: '1.5rem 2.5rem' }}
          centered={true}
        >
          {this.renderModal()}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({
  uiState: {
    modal,
    siteState: { showNavbar }
  }
}) => ({
  showNavbar: !!showNavbar,
  modal
})

export default connect(mapStateToProps)(Container)
