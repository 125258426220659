import * as React from 'react'
import './Spinner.css'

const CustomSpinner: React.SFC = React.memo(() => (
  <div className='spinner-dot'>
    <div className='bounce1' />
    <div className='bounce2' />
    <div className='bounce3' />
  </div>
))

export default CustomSpinner
