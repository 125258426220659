import * as React from 'react'
import IndicatorDetail from 'containers/IndicatorDetail'
import IndicatorTable from 'containers/IndicatorTable'
import { connect } from 'react-redux'
import { P } from './type'
import { Switch, withRouter, Route } from 'react-router-dom'

class Indicators extends React.PureComponent<P, {}> {
  render() {
    return (
      <div className='collection-content'>
        <Switch>
          <Route path='/workspaces/:id/indicators' exact={true} component={IndicatorTable} />
          <Route path='/workspaces/:id/indicators/:indicator_id' component={IndicatorDetail} />
        </Switch>
      </div>
    )
  }
}

export default withRouter(connect()(Indicators))
