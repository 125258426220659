import { Action, ActionCreator } from 'redux'
import { DatasourceObj } from './datasources'
export const REQUEST_INDICATOR_V3 = 'REQUEST_INDICATOR_V3'
export const GET_INDICATOR_V3 = 'GET_INDICATOR_V3'
export const REQUEST_INDICATOR_DETAIL_V3 = 'REQUEST_INDICATOR_DETAIL_V3'
export const GET_INDICATOR_DETAIL_V3 = 'GET_INDICATOR_DETAIL_V3'

export const REQUEST_INDICATOR_AVAILABLE_DATA_SOURCE_V3 = 'REQUEST_INDICATOR_AVAILABLE_DATA_SOURCE_V3'
export const REQUEST_INDICATOR_DATA_SOURCE_V3 = 'REQUEST_INDICATOR_DATA_SOURCE_V3'
export const GET_INDICATOR_AVAILABLE_DATA_SOURCE_V3 = 'GET_INDICATOR_AVAILABLE_DATA_SOURCE_V3'

export type IndicatorItem = {
  id: number,
  latest_reading?: string,
  name: string,
  state: ParameterState,
  status: 'enable' | 'disable',
  unit?: string,
  reading: {
    value?: number,
    level?: string
  },
  updated_at: string,
  created_at: string,
  data_channel?: string,
  data_channel_name?: string,
  first_reading?: string,
  collections: {
    id: number,
    name: string,
    code: string
  }[],
  data_source?: {
    id: number,
    name: string,
    identifier: string
  }
}

export interface RequestIndicatorAction extends Action<typeof REQUEST_INDICATOR_V3> {
  args: any;
}

export interface RequestIndicatorDetailAction extends Action<typeof REQUEST_INDICATOR_DETAIL_V3> {
  args: any;
}

export interface RequestIndicatorAvailableDataSourceAction
  extends Action<typeof REQUEST_INDICATOR_AVAILABLE_DATA_SOURCE_V3> {
  args: any;
}

export interface RequestIndicatorDataSourceAction extends Action<typeof REQUEST_INDICATOR_DATA_SOURCE_V3> {
  args: any;
}

interface GetIndicatorAction extends Action<typeof GET_INDICATOR_V3> {
  data: IndicatorItem[];
  meta: Meta;
  query: {};
}

interface GetIndicatorDetailAction extends Action<typeof GET_INDICATOR_DETAIL_V3> {
  data: IndicatorItem;
}

interface GetIndicatorAvailabelDataSourceAction extends Action<typeof GET_INDICATOR_AVAILABLE_DATA_SOURCE_V3> {
  data: DatasourceObj[];
  meta: Meta;
  query: {};
}

export type IndicatorAction = GetIndicatorAction | GetIndicatorDetailAction | GetIndicatorAvailabelDataSourceAction

export const requestIndicator = (args: {
  workspace_id: number,
  size?: number,
  data_source_id?: number,
  data_source_identifier?: string,
  q?: string,
  name?: string
}): RequestIndicatorAction => ({
  type: REQUEST_INDICATOR_V3,
  args
})

export const getIndicator: ActionCreator<GetIndicatorAction> = (data, meta, query) => ({
  type: GET_INDICATOR_V3,
  data,
  meta,
  query
})

export const requestIndicatorDetail = (args): RequestIndicatorDetailAction => ({
  type: REQUEST_INDICATOR_DETAIL_V3,
  args
})

export const getIndicatorDetail: ActionCreator<GetIndicatorDetailAction> = data => ({
  type: GET_INDICATOR_DETAIL_V3,
  data
})

export const requestIndicatorAvailableDataSource = (args: {
  indicator_id: number,
  size?: number,
  show?: boolean,
  page?: number,
  name?: string
}): RequestIndicatorAvailableDataSourceAction => ({
  type: REQUEST_INDICATOR_AVAILABLE_DATA_SOURCE_V3,
  args
})

export const requestIndicatorDataSource = (args: {
  workspace_id: number,
  size?: number,
  show?: boolean,
  page?: number,
  name?: string
}): RequestIndicatorDataSourceAction => ({
  type: REQUEST_INDICATOR_DATA_SOURCE_V3,
  args
})

export const getIndicatorAvailabelDataSource: ActionCreator<GetIndicatorAvailabelDataSourceAction> = (
  data,
  meta,
  query
) => ({
  type: GET_INDICATOR_AVAILABLE_DATA_SOURCE_V3,
  data,
  meta,
  query
})
