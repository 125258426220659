import * as React from 'react'
import Svg from '-!svg-react-loader!images/svg/KpnpchXsobRgLElEozzI.svg'
import Button from 'components/Custom/Button'
import { Col, Collapse, Icon, Row } from 'antd'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { t } from 'i18next'
import './index.css'
import Header from 'containers/Header'
const Panel = Collapse.Panel

type Props = {
  title?: string,
  message?: string,
  oneLine?: boolean,
  error?: {
    title: string,
    message: string
  },
  button?: {
    message: string,
    navigate?: string,
    replace?: string,
    push?: string,
    handle?: Function
  }
}

const Excepetion: React.SFC<RouteComponentProps<{}> & Props> = React.memo(
  ({ location: { state: routeState }, history, oneLine, ...props }) => {
    const state = routeState || props

    const clickHandle = ({ replace, navigate, push, handle }) => {
      if (replace) {
        window.location.replace(replace)
        return
      }

      if (navigate) {
        history.replace(navigate)
        return
      }

      if (push) {
        history.push(push)
        return
      }

      if (handle) {
        handle()
        return
      }

      return
    }

    if (state.title === '404') {
      state.button = {
        message: t('exception.gohome'),
        navigate: '/'
      }
    }
    return (
      <>
        <Header />
        <div className='container-wrapper'>
          <div className='exception-container'>
            <div className='location-wrapper'>
              <Row type='flex' justify='space-between' align='middle'>
                {!oneLine && (
                  <Col md={16} span={24} className='exception-col'>
                    <Svg className='exception-logo' />
                  </Col>
                )}
                <Col md={oneLine ? 24 : 8} span={24} className='exception-col'>
                  {state.title && <h1 className='exception-title'>{state.title}</h1>}
                  {state.message && <h3 className='exception-message' dangerouslySetInnerHTML={{ __html: state.message }} />}
                  <p className='small_text'>{new Date().toLocaleString()}</p>
                  <br />
                  {state.button && (
                    <p className='exception-button'>
                      <Button customType='dark' onClick={() => clickHandle(state.button)}>
                        {state.button.message}
                      </Button>
                    </p>
                  )}
                </Col>
              </Row>
              {state.error && (
                <>
                  <br />
                  <Collapse bordered={false} expandIcon={({ isActive }) => <Icon type='caret-right' rotate={isActive ? 90 : 0} />}>
                    <Panel
                      header={t('Click below for detailed error information')}
                      key='1'
                      style={{
                        background: 'white',
                        borderRadius: 4,
                        marginBottom: 24,
                        border: 0,
                        overflow: 'hidden'
                      }}
                    >
                      <h1 className='exception-title'>{state.error.title}</h1>
                      <h3
                        className='exception-message'
                        dangerouslySetInnerHTML={{
                          __html: state.error.message
                        }}
                      />
                    </Panel>
                  </Collapse>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
)

Excepetion.defaultProps = {
  title: '404',
  message: t('exception.notfound')
}

export default withRouter(Excepetion)
