import { takeLatest, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { CHANGE_WORKSPACE, ChangeWorkspaceAction, updateSiteState } from 'actions/uiState'

function* updateStore(action: ChangeWorkspaceAction) {
  const { id } = action

  yield put(
    updateSiteState({
      workspaceID: id,
      locationID: '',
      indicatorID: '',
      collectionID: '',
      datasourceID: '',
      settingsURL: '',
      collectionsURL: '',
      indicatorsURL: '',
      datasourcesURL: '',
      peopleURL: '',
      originURL: ''
    })
  )

  yield put(push(`/workspaces/${id}`))
}

export default function* watchChangeWorkspace() {
  yield takeLatest(CHANGE_WORKSPACE, updateStore)
}
