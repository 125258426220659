// if (process.env.NODE_ENV === 'production') {
//   __webpack_public_path__ = window.CDN_PATH;
// }
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import zhCN from 'antd/lib/locale-provider/zh_CN';
import 'components/LineChart/plugin';
import { configureStore } from 'configs/configureStore';
import i18nInstance from 'configs/i18n';
import 'configs/polyfill';
import * as serviceWorker from 'configs/registerServiceWorker';
import { ConnectedRouter } from 'connected-react-router';
import 'core-js/stable';
import { createBrowserHistory } from 'history';
import 'intersection-observer';
import App from 'pages/App';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import 'regenerator-runtime/runtime';
import 'styles/index.css';
import 'styles/index.less';

// add google analytics
ReactGA.initialize(process.env.GOOGLE_ANALYTICS_UA || 'UA-191013391-1');

export const history = createBrowserHistory();
export const store = configureStore(history);
const root = document.getElementById('root') as HTMLElement;
window.QLEAR_VERSION = '3.3.1';
window.TAG_VERSION = '3.3.1-app_theme';
window.CDN_PATH = process.env.PUBLIC_URL || '/';
window.COMPILE_TIME = process.env.REACT_APP_COMPILE_TIME!;
window.QLEAR_ENV = process.env.REACT_APP_QLEAR_ENV;

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={i18nInstance.language === 'zh' ? zhCN : enUS}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </ConfigProvider>
  </Provider>,
  root
);

if (module.hot) {
  module.hot.accept('./pages/App', () => {
    const NextApp = require('./pages/App').default;
    ReactDOM.render(
      <Provider store={store}>
        <ConfigProvider locale={i18nInstance.language === 'zh' ? zhCN : enUS}>
          <ConnectedRouter history={history}>
            <NextApp />
          </ConnectedRouter>
        </ConfigProvider>
      </Provider>,
      root
    );
  });
}

serviceWorker.register();
