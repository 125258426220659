import { put, call } from 'redux-saga/effects'
import { REQUEST_TABLEDATA, getTableData } from 'actions/V3/tabledata'
import { watchRequestNoCancel } from 'sagas/helper'
import { apiV3 } from 'utils/api'

type Args = {
  t: string, // hour/raw
  location_id: number,
  station_id: number,
  begin_time: string,
  end_time: string,
  category: 'location' | 'collection' | 'indicator' | 'data_source',
  size?: number
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/readings/data', 'get', {
    ...args
  })

  yield put(getTableData(data, meta, args))
  return data
}

export default watchRequestNoCancel(REQUEST_TABLEDATA, request)
