import { Action, ActionCreator } from 'redux'

export const REQUEST_WORKSPACE_DETAIL = 'REQUEST_WORKSPACE_DETAIL'
export const GET_WORKSPACE = 'GET_WORKSPACE'
export const REQUEST_WORKSPACE_LIST = 'REQUEST_WORKSPACE_LIST'
export const GET_WORKSPACE_LIST = 'GET_WORKSPACE_LIST'
export const REQUEST_WORKSPACE_DELETE = 'REQUEST_WORKSPACE_DELETE'
export const GET_WORKSPACE_DELETE = 'GET_WORKSPACE_DELETE'
export const REQUEST_WORKSPACE_EDIT = 'REQUEST_WORKSPACE_EDIT'
export const GET_WORKSPACE_EDIT = 'GET_WORKSPACE_EDIT'
export const REQUEST_WORKSPACE_NEW = 'REQUEST_WORKSPACE_NEW'
export const GET_WORKSPACE_NEW = 'GET_WORKSPACE_NEW'

export type WorkspaceObj = {
  id: number,
  name: string,
  code: string,
  pro: boolean,
  created_at: string,
  subscribed: boolean,
  subscribe_begin_at: string,
  subscribe_end_at: string,
  org_id?: number,
  config?: {
    enable_conditional_notification: boolean,
    enable_state_notification: boolean
  },
  counter?: {
    updated_at: string,
    location: {
      empty: number,
      total: number,
      online: number,
      offline: number,
      'partial-offline': number
    },
    collection: {
      total: number
    },
    data_source: {
      total: number,
      offline: number,
      online: number
    },
    indicator: {
      total: number
    },
    user: {
      total: number
    },
    performance: {
      total: number,
      good: number,
      moderate: number,
      sensitive: number,
      hazardous: number,
      very_unhealthy: number,
      unhealthy: number,
      empty: number
    },
    parameter: {
      total: number,
      air?: number,
      electricity?: number
    }
  }
}

export type WorkspaceDelete = {
  id: number
}

export type WorkspaceEdit = {
  id: number,
  name: string,
  org_id: string
}

export interface RequestWorkspaceAction extends Action<typeof REQUEST_WORKSPACE_DETAIL> {
  args: {
    workspace_id?: number
  };
}

export interface RequestWorkspaceNewAction extends Action<typeof REQUEST_WORKSPACE_NEW> {
  args: any;
}

export interface RequestWorkspaceListAction extends Action<typeof REQUEST_WORKSPACE_LIST> {
  args: any;
}

export interface RequestWorkspaceDeleteAction extends Action<typeof REQUEST_WORKSPACE_DELETE> {
  args: WorkspaceDelete;
}

export interface RequestWorkspaceEditAction extends Action<typeof REQUEST_WORKSPACE_EDIT> {
  args: WorkspaceEdit;
}

interface GetWorkspaceNewAction extends Action<typeof GET_WORKSPACE_NEW> {
  workspace: WorkspaceObj;
}

interface GetWorkspaceAction extends Action<typeof GET_WORKSPACE> {
  workspace: WorkspaceObj;
}

interface GetWorkspaceListAction extends Action<typeof GET_WORKSPACE_LIST> {
  workspaces: WorkspaceObj[];
}

interface GetWorkspaceDeleteAction extends Action<typeof GET_WORKSPACE_DELETE> {
  workspaceDelete: WorkspaceDelete;
}

interface GetWorkspaceEditAction extends Action<typeof GET_WORKSPACE_EDIT> {
  workspaceEdit: WorkspaceEdit;
}

export type WorkspaceAction =
  | GetWorkspaceAction
  | GetWorkspaceListAction
  | GetWorkspaceDeleteAction
  | GetWorkspaceNewAction
  | GetWorkspaceEditAction

export const requestWorkspace: ActionCreator<RequestWorkspaceAction> = args => ({
  type: REQUEST_WORKSPACE_DETAIL,
  args
})

export const requestWorkspaceNew: ActionCreator<RequestWorkspaceNewAction> = args => ({
  type: REQUEST_WORKSPACE_NEW,
  args
})

export const requestWorkspaceList: ActionCreator<RequestWorkspaceListAction> = args => ({
  type: REQUEST_WORKSPACE_LIST,
  args
})

export const requestWorkspaceDelete: ActionCreator<RequestWorkspaceDeleteAction> = args => ({
  type: REQUEST_WORKSPACE_DELETE,
  args
})

export const requestWorkspaceEdit: ActionCreator<RequestWorkspaceEditAction> = args => ({
  type: REQUEST_WORKSPACE_EDIT,
  args
})

export const getWorkspaceNew: ActionCreator<GetWorkspaceNewAction> = (workspace: WorkspaceObj) => ({
  type: GET_WORKSPACE_NEW,
  workspace
})

export const getWorkspace: ActionCreator<GetWorkspaceAction> = (workspace: WorkspaceObj) => ({
  type: GET_WORKSPACE,
  workspace
})

export const getWorkspaceList: ActionCreator<GetWorkspaceListAction> = (workspaces: WorkspaceObj[]) => ({
  type: GET_WORKSPACE_LIST,
  workspaces
})

export const getWorkspaceDelete: ActionCreator<GetWorkspaceDeleteAction> = (workspaceDelete: WorkspaceDelete) => ({
  type: GET_WORKSPACE_DELETE,
  workspaceDelete
})

export const getWorkspaceEdit: ActionCreator<GetWorkspaceEditAction> = (workspaceEdit: WorkspaceEdit) => ({
  type: GET_WORKSPACE_EDIT,
  workspaceEdit
})
