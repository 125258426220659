import { Action, ActionCreator } from 'redux'
export const REQUEST_DOWNLOADS = 'REQUEST_DOWNLOADS'
export const GET_DOWNLOADS = 'GET_DOWNLOADS'

export type DownloadObj = {
  id: number,
  begin_time: string,
  end_time: string,
  category: string,
  count: number,
  month: number,
  ref_id: number,
  url: string,
  year: number
}

export interface RequestDownloadsAction extends Action<typeof REQUEST_DOWNLOADS> {
  args: any;
}

interface GetDownloadsAction extends Action<typeof GET_DOWNLOADS> {
  downloads: DownloadObj[];
  totalCount: number;
}

export type DownloadAction = GetDownloadsAction

export const requestDownloads: ActionCreator<RequestDownloadsAction> = args => ({
  type: REQUEST_DOWNLOADS,
  args
})

export const getDownloads: ActionCreator<GetDownloadsAction> = (downloads: DownloadObj[], totalCount: number) => ({
  type: GET_DOWNLOADS,
  downloads,
  totalCount
})
