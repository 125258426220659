import * as React from 'react';
import cache from 'sagas/cache';
import Highlighter from 'react-highlight-words';
import qs from 'qs';
import Table from 'components/Custom/Table';
import { changeModal } from 'actions/uiState';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { P, State, StateProps } from './type';
import { pick } from 'lodash';
import { queryMerge } from 'utils/query';
import SearchBar from 'components/SearchBar';
import {
    requestCollection,
    REQUEST_COLLECTIONS_V3
} from 'actions/V3/collections';
import { withRouter } from 'react-router-dom';

import { t } from 'i18next';
import './index.css';


export enum indicators {
    arrayStart = 0,
    fiveIndicators = 5,
}

class Collections extends React.PureComponent<P, State> {
    state: State = {
        indicatorID: -1,
        collectionID: -1
    };

    resetCache = cache.resetCache.bind(null, REQUEST_COLLECTIONS_V3);
    columns = [
        {
            title: t('Collection Name'),
            key: 'collectionName',
            dataIndex: 'collectionName'
        },
        {
            title: t('PARAMETERS TYPES'),
            key: 'parameter',
            dataIndex: 'parameter',
            render: indicators => (
                <div className='collection-table-parmeter'>
                    {indicators.slice(indicators.arrayStart, indicators.fiveIndicators).map((indicator, index) => (
                        <span key={`collection-${index}`}>{indicator.name}</span>
                    ))}
                    {indicators.length > indicators.fiveIndicators && (
                        <Tooltip
                            placement='bottom'
                            title={indicators
                                .slice(indicators.fiveIndicators)
                                .map(ii => ii.name + ' ')
                                .toString()}
                        >
                            <span className='grey_text'>{`+${indicators.length - indicators.fiveIndicators}`}</span>
                        </Tooltip>
                    )}
                </div>
            )
        }
    ];

    componentDidMount() {
        const {
            location: { search }
        } = this.props;
        const secondElementStart = 1
        const query = pick(qs.parse(search.slice(secondElementStart)), ['page', 'name']);
        if (typeof query.page === 'string') {
            query.page = String(query.page);
        }

        this.fetchCollections(query);
    }

    fetchCollections = (query = {}) => {
        const {
            match: {
                params: { id }
            },
            dispatch
        } = this.props;
        dispatch(
            requestCollection({
                ...query,
                workspace_id: +id,
                sort_field: 'created_at',
                sort_type: 'desc'
            })
        );
    };

    refreshCollections = () => {
        const { query } = this.props;
        this.fetchCollections({ ...query, show: false });
    };

    selectPage = page => {
        const {
            location: { search, pathname },
            history,
            query: q
        } = this.props;
        const firstPage = 1
        const query = queryMerge({
            search,
            query: {
                page: page
            },
            delKeys: page === firstPage ? ['page'] : []
        });
        history.push({
            pathname,
            search: query
        });
        this.fetchCollections({ ...q, page });
    };

    changeModal = (modal: ModalType): void => {
        this.props.dispatch(changeModal(modal));
    };

    pagination = () => {
        const { meta } = this.props;
        const pageSize = 20
        return meta
            ? {
                hideOnSinglePage: true,
                showQuickJumper: true,
                onChange: this.selectPage,
                current: meta.current_page,
                defaultPageSize: pageSize,
                total: meta.total_count
            }
            : {};
    };

    createTable = () => {
        const { collections, query } = this.props;
        return collections.map((v, i) => ({
            key: `collection-${i}`,
            id: v.id,
            collectionName:
                query && (query as any).name ? (
                    <Highlighter
                        searchWords={[(query as any).name]}
                        autoEscape={true}
                        textToHighlight={v.name}
                    />
                ) : (
                    v.name
                ),
            parameter: v.indicators
        }));
    };

    rowClick = record => {
        const {
            match: {
                params: { id }
            },
            history
        } = this.props;
        return {
            onClick: () => history.push(`/workspaces/${id}/collections/${record.id}`)
        };
    };

    onSearch = (text: string): void => {
        const name = text.trim();
        const {
            dispatch,
            location: { search },
            history,
            match: {
                params: { id }
            }
        } = this.props;

        const query = queryMerge({
            search,
            query: {
                name
            },
            delKeys: name ? ['page', 'size'] : ['page', 'size', 'name']
        });

        history.push({
            pathname: `/workspaces/${id}/collections`,
            search: query
        });

        dispatch(
            requestCollection({
                name,
                workspace_id: +id
            })
        );
    };

    render() {
        const { meta, location: { search } } = this.props;
        const secondElementStart = 1
        const name = pick(qs.parse(search.slice(secondElementStart)), ['name']).name || '';

        return (
            <>
                <div className='collection-content-title'>
                    <span>
                        <i className='material-icons collection-icon'>group_work</i>
                        {t('Collections')}
                    </span>
                    <span className='collection-content-total'>
                        {meta && t('collection.count', { count: meta.total_count })}
                    </span>
                </div>
                <div className='indicator-active-search'>
                    <SearchBar
                        defaultValue={name as string}
                        placeholder={t('collection.searchCollection') as string}
                        onSearch={this.onSearch}
                    />

                </div>
                {meta && (
                    <Table
                        type='light'
                        columns={this.columns}
                        onRow={this.rowClick}
                        size='default'
                        rowClassName={() => 'ds-datasource-item'}
                        className='collection-table'
                        pagination={this.pagination()}
                        dataSource={this.createTable()}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { collections } }) => ({
    collections: collections.data,
    meta: collections.meta,
    query: collections.query
});

export default withRouter(connect(mapStateToProps)(Collections));
