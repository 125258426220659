import {
  AnnouncementAction,
  GET_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_LATEST,
  CLEAN_ANNOUNCEMENT_LATEST,
  AnnouncementItem
} from 'actions/V2/announcement'

export type AnnouncementState = {
  changelog: AnnouncementItem[],
  announcement?: AnnouncementItem
}

export default function Announcement(
  state: AnnouncementState = {
    changelog: []
  },
  action: AnnouncementAction
) {
  switch (action.type) {
    case GET_ANNOUNCEMENT:
      return {
        ...state,
        changelog: action.data
      }
    case GET_ANNOUNCEMENT_LATEST:
      return {
        ...state,
        announcement: action.data
      }
    case CLEAN_ANNOUNCEMENT_LATEST:
      return {
        ...state,
        announcement: undefined
      }
    default:
      return state
  }
}
