import {getAdminParamsBanner, REQUEST_ADMIN_PARAMS_BANNER} from 'actions/V3/adminParams'
import {call, put} from 'redux-saga/effects'
import {watchRequestNoCancel} from 'sagas/helper'
import {apiV3} from 'utils/api'

function* requestBanner() {

  const { data } = yield call(apiV3, '/web_admin_params/show_technical_difficulties_banner', 'get')
  yield put(getAdminParamsBanner(data))
  return data
}

export const watchAdminParamsBanner =  watchRequestNoCancel(REQUEST_ADMIN_PARAMS_BANNER, requestBanner)
