import React, { useState } from 'react';
import { JPMCKioskAccess } from './kiosks';
import { Button, Input, Select, Radio } from 'antd';
import { t } from 'i18next';
import template1 from 'images/kiosk/template-1.png';
import CovidIndexTemplateImg from 'images/kiosk/covidIndex.png';
import { Route } from 'react-router-dom';
import JPMC from '../KioskCustom/index';
import CustomSelect from 'components/Custom/Select';
import { promiseDispatch } from 'utils';
import { requestKioskNew } from 'actions/V3/kiosks';
import { NewKioskModalSteps } from '../../utils/enums';

const Option = Select.Option;

export default function NewKioskModal({
    state,
    openSetting,
    locationBase,
    newKioskModalObj,
    nameInput,
    dispatch,
    movedDownSetState
}) {
    const { newKioskModalStep } = state;

    const [newKiosk, setNewKiosk] = useState<any>();


    const doNewKiosk = async () => {
        if (newKioskModalObj) {
            const newKioskData: any = await promiseDispatch({
                dispatch,
                actionCreator: requestKioskNew,
                payload: {
                    location_id: newKioskModalObj.id,
                    name: newKioskModalObj.name,
                    template_key: newKioskModalObj.template,
                    collection_id: newKioskModalObj.collection_id
                }
            });
            setNewKiosk(newKioskData);
            movedDownSetState({
                newKioskModalStep: NewKioskModalSteps.SUCCESS_CONFIRMATION
            });
        }
    };

    const renderCollectionSelector = (onChange, defaultValue) => {
        const collections = locationBase ? locationBase.collections : [];
        return (
            <CustomSelect
                underline={false}
                style={{ width: '100%' }}
                dropdownMatchSelectWidth={true}
                defaultValue={defaultValue || 0}
                disabled={!onChange}
                getPopupContainer={(trigger) => trigger!.parentElement!}
                onChange={onChange}
            >
                <Option key={0} value={0}>
                    {t('kiosk.location average')}
                </Option>
                {collections.map((item: any, i: number) => (
                    <Option key={i} value={item.id}>
                        {item.name}
                    </Option>
                ))}
            </CustomSelect>
        );
    };

    const radioStyle = {
        display: 'block',
        lineHeight: '30px'
    };

    return (
        <div className='body'>
            {newKioskModalStep === NewKioskModalSteps.TEMPLATE_SELECTION && (
                <React.Fragment>
                    <div className='step-title'>1. {t('kiosk.Choose Template')}</div>
                    <div>
                        <Radio.Group
                            defaultValue='default'
                            onChange={(e) => {
                                newKioskModalObj.template = e.target.value;
                                movedDownSetState({ newKioskModalObj });
                            }}
                        >
                            <Radio style={radioStyle} value='default'>
                                {t('kiosk.Template 1')}
                                <div className='template-img tabbed'>
                                    <img src={template1} alt='' />
                                </div>
                            </Radio>
                                <Radio style={radioStyle} value='covidIndex'>
                                    {t('kiosk.CovidIndexTemplate')}
                                    <div className='template-img tabbed'>
                                        <img
                                            src={CovidIndexTemplateImg}
                                            alt='CovidIndexTemplateImg'
                                        />
                                    </div>
                                </Radio>
                            {JPMCKioskAccess.includes(newKioskModalObj.id) && (
                                <Route
                                    path='/workspaces/60/kiosk'
                                    exact={true}
                                    component={JPMC}
                                />
                            )}
                        </Radio.Group>
                    </div>
                </React.Fragment>
            )}
            {newKioskModalStep === NewKioskModalSteps.KIOSK_NAMING && (
                <React.Fragment>
                    <div className='step-title'>2. {t('kiosk.Input Name of Kiosk')}</div>
                    <div>
                        <Input
                            ref={(e) => (nameInput = e)}
                            onChange={(e) => {
                                newKioskModalObj.name = e.target.value;
                                movedDownSetState({
                                    newKioskModalObj
                                });
                            }}
                        />
                    </div>
                    {newKioskModalObj.template === 'fixed_panels' && (
                        <div className='choose-collection'>
                            <div>{t('kiosk.select collection or location average')}</div>
                            <div>
                                {renderCollectionSelector((v) => {
                                    if (v) {
                                        newKioskModalObj.collection_id = +v;
                                        movedDownSetState({
                                            newKioskModalObj
                                        });
                                    }
                                }, 0)}
                            </div>
                        </div>
                    )}
                </React.Fragment>
            )}
            {newKioskModalStep === NewKioskModalSteps.SUCCESS_CONFIRMATION && (
                <div className='step-3'>
                    <i className='iconfont icon-success' />
                    <div className='step-title'>{t('kiosk.success created')}</div>
                </div>
            )}
            {newKioskModalStep === NewKioskModalSteps.TEMPLATE_SELECTION && (
                <div className='footer'>
                    <Button
                        type='primary'
                        ghost={true}
                        onClick={() => {
                            movedDownSetState({
                                newKioskModalObj: undefined
                            });
                        }}
                    >
                        {t('Cancel')}
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => {
                            if (newKioskModalObj.template) {
                                movedDownSetState({
                                    newKioskModalStep: NewKioskModalSteps.KIOSK_NAMING
                                });
                            }
                        }}
                    >
                        {t('kiosk.next step')}
                    </Button>
                </div>
            )}
            {newKioskModalStep === NewKioskModalSteps.KIOSK_NAMING && (
                <div className='footer'>
                    <Button
                        type='primary'
                        ghost={true}
                        onClick={() => {
                            movedDownSetState({
                                newKioskModalStep: NewKioskModalSteps.TEMPLATE_SELECTION
                            });
                        }}
                    >
                        {t('kiosk.prev step')}
                    </Button>
                    <Button
                        type='primary'
                        onClick={() => {
                            if (newKioskModalObj.name) {
                                doNewKiosk();
                            } else {
                                nameInput.focus();
                            }
                        }}
                    >
                        {t('kiosk.create')}
                    </Button>
                </div>
            )}
            {newKioskModalStep === NewKioskModalSteps.SUCCESS_CONFIRMATION && (
                <div className='footer'>
                    <Button
                        type='primary'
                        onClick={() => {
                            movedDownSetState({
                                newKioskModalObj: undefined
                            });
                            if (newKiosk) {
                                openSetting(newKiosk);
                            }
                        }}
                    >
                        {t('kiosk.go to setting')}
                    </Button>
                </div>
            )}
        </div>
    );
}
