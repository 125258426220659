import * as React from 'react'
import moment from 'moment'
import Table from 'components/Custom/Table'
import { BillingObj, requestWorkspaceBillingDetail, RequestWorkspaceBillingDetailAction } from 'actions/V3/billing'
import { connect } from 'react-redux'
import { DispatchProp } from 'react-redux'
import { iosMonth } from 'utils/helper'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { t } from 'i18next'
import './index.css'

interface StateProps {
  data: BillingObj[];
  query?: any;
  meta?: Meta;
}

type P = StateProps & DispatchProp<RequestWorkspaceBillingDetailAction> & RouteComponentProps<{ id: string, workspace_id: string }>

class BillingDetail extends React.PureComponent<P, {}> {
  componentDidMount() {
    this.fetchBillingList()
  }

  fetchBillingList = (page = 1) => {
    const {
      dispatch,
      match: {
        params: { id, workspace_id }
      }
    } = this.props
    dispatch(requestWorkspaceBillingDetail({ id, workspace_id, page }))
  }

  renderTable = () => {
    const {
      data,
      match: {
        params: { id }
      }
    } = this.props

    const tableData = data.map((item, i) => ({
      ...item,
      key: i
    }))

    const currentMonth = moment().format('YYYY-MM')

    const columns = [
      {
        title: t('Month'),
        dataIndex: 'month',
        key: 'month',
        render: (text, record) => {
          return iosMonth(record.year, record.month).format('MMM YYYY')
        }
      },

      {
        title: t('Paid/Unpaid'),
        dataIndex: 'paid',
        key: 'paid',
        render: (text, record) => {
          if (record.total_price > 0) {
            return (
              <div>{record.paied ? <div className='accent_text'>{t('Paid')}</div> : <div className='danger_text'>{t('Unpaid')}</div>}</div>
            )
          }
          return '-'
        }
      },
      {
        title: t('indicator.active count'),
        dataIndex: 'active_indicators_count',
        key: 'active_indicators_count',
        render: (text, record) => {
          return text || record.indicators_count
        }
      },
      {
        title: t('Total'),
        dataIndex: 'total_price',
        key: 'total_price',
        render: (text, record) => {
          const lastBillingMonth = iosMonth(record.year, record.month).format('YYYY-MM')
          return currentMonth === lastBillingMonth ? (
            <div>
              CNY {record.estimated_price} ({t('billing.estimated price')})
            </div>
          ) : (
            <div>CNY {text}</div>
          )
        }
      },
      {
        title: t('Invoice'),
        dataIndex: 'id',
        width: '15%',
        key: 'id',
        render: (text, record) => {
          const lastBillingMonth = iosMonth(record.year, record.month).format('YYYY-MM')
          return (
            currentMonth !== lastBillingMonth && (
              <Link to={`/invoice/workspace/${id}/${text}/${record.workspace_id}`}>{t('See Invoice')}</Link>
            )
          )
        }
      }
    ]

    return <Table rowClassName={() => 'home-ws-row'} type='light' columns={columns} dataSource={tableData} size='default' />
  }

  navBack = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { data } = this.props
    return (
      <div className='location-wrapper'>
        <div className='billing-container'>
          <div className='go-back'>
            <div className='title'>
              <i className='iconfont icon-back' onClick={this.navBack} />
              {data[0] && `${data[0].workspace_name}${t('billing.workspaces billing')}`}
            </div>
          </div>
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V3: {
    billing: { workspaceBillingDetail }
  }
}) => workspaceBillingDetail

export default withRouter(connect(mapStateToProps)(BillingDetail))
