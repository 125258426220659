import { put, call } from 'redux-saga/effects'

import { REQUEST_ACTIVE_LOG_V3, getActiveLog } from 'actions/V3/activeLog'
import { watchRequest } from 'sagas/helper'

import { apiV3 } from 'utils/api'

type Args = {
  size?: number,
  page?: number,
  name?: string,
  workspace_id: number
}

function* request(args: Args) {
  const { data, meta } = yield call(apiV3, '/active_logs', 'get', {
    ...args
  })

  yield put(getActiveLog(data, meta, args))
  return data
}

export default watchRequest(REQUEST_ACTIVE_LOG_V3, request)
