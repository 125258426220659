import * as React from 'react'
import { t } from 'i18next'
import './index.css'
import { Modal, Input, Icon } from 'antd'
import api from 'utils/api'

const TextArea = Input.TextArea

interface Props {
  onClose?: () => void;
}

type State = Readonly<{
  expand: boolean,
  content: string
}>

export default class TopNoteBar extends React.PureComponent<Props, State> {
  state: State = {
    expand: false,
    content: ''
  }
  textarea: any

  expandDialog = e => {
    e.preventDefault()
    this.setState({
      expand: true
    })
  }

  hideDialog = () => {
    this.setState({
      expand: false
    })
  }

  onChangeContent = e => {
    this.setState({
      content: e.currentTarget.value
    })
  }

  doConfirm = () => {
    const { content } = this.state
    if (content) {
      api('/feedbacks', 'post', {
        content
      }).then(() => {
        this.setState({
          expand: false,
          content: ''
        })
      })
    } else {
      this.textarea.focus()
    }
  }

  render() {
    const { onClose } = this.props
    const { expand, content } = this.state
    return (
      <div className='top-note-bar-wrap'>
        <div className='top-note-bar-container'>
          <div>
            {t('Welcome to QLEAR Beta')}
            <a href='mailto:support@qlear.io' onClick={this.expandDialog}>
              {t('Click here')}
            </a>
          </div>
          <div className='top-note-bar-close' onClick={onClose}>
            <Icon type='close' />
          </div>
        </div>
        <Modal
          title={t('Feedback')}
          closable={true}
          centered={true}
          onOk={this.doConfirm}
          maskClosable={true}
          visible={expand}
          okType='primary'
          okText={t('Submit')}
          onCancel={this.hideDialog}
          wrapClassName='kiosk-confirm-modal'
        >
          {/* <div className="feedback-tips">
            <p>FEEDBACK EXAMPLE</p>
            <p>Summary:</p>
            <p>I can not change the logo of my location</p>
            <p>Source URL:</p>
            <p>https://testing.qlear.io/workspaces/341/kiosk</p>
            <p>Except Results:</p>
            <p>I should be able to change the logo for any of my location</p>
          </div> */}
          <div className='feedback-input'>
            <TextArea
              ref={e => (this.textarea = e)}
              autosize={false}
              rows={4}
              value={content}
              onChange={this.onChangeContent}
              placeholder={t('Please enter your feedback message')}
            />
          </div>
        </Modal>
        <div className='feedback-btn' onClick={this.expandDialog}>
          {t('Feedback')}
        </div>
      </div>
    )
  }
}
