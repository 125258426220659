import * as React from 'react'
import moment from 'moment'
import qs from 'qs'
import TextRow from 'containers/IndicatorDetail/TextRow'
import { connect, DispatchProp } from 'react-redux'
import { entries, isArray, isEmpty, pick } from 'lodash'
import { LogItem, requestActiveLog, RequestLogAction } from 'actions/V3/activeLog'
import { Pagination, Tooltip } from 'antd'
import { queryMerge } from 'utils/query'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { titleCase } from 'change-case'
import { translations } from 'utils/constant'

type StateProps = {
  logs: LogItem[],
  meta?: Meta,
  query: any
}

type Props = {
  requstQuery: any
}

type P = StateProps & Props & DispatchProp<RequestLogAction> & RouteComponentProps<{ indicator_id: string }>

class History extends React.PureComponent<P, {}> {
  componentDidMount() {
    const {
      location: { search }
    } = this.props

    const query = pick(qs.parse(search.slice(1)), ['page']);
    if (typeof query.page === 'string') {
      query.page = String(query.page);
    }

    this.fetchLogs(query);

  }

  fetchLogs = (query = {}) => {
    const { dispatch, requstQuery } = this.props
    dispatch(
      requestActiveLog({
        ...query,
        ...requstQuery
      })
    )
  }

  selectPage = (page, pageSize) => {
    const {
      location: { search, pathname },
      history,
      query: q
    } = this.props
    const query = queryMerge({
      search,
      query: {
        page
      },
      delKeys: page === 1 ? ['page'] : []
    })
    history.push({
      pathname,
      search: query
    })
    this.fetchLogs({ ...q, page })
  }

  render() {
    const { logs, meta } = this.props
    return (
      <div style={{ paddingBottom: 20 }}>
        {logs.map((v, i) => {
          const showDetail = v.event !== 'destroy' && v.details && !isEmpty(v.details)
          return (
            <div key={`collection-history-${i}`}>
              <TextRow label={moment(v.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                <div className='workspace-ov-activity-message'>
                  <span className='workspace-ov-activity-name'>{v.user_name}</span>{' '}
                  <span className='workspace-ov-activity-event'>{translations[v.event] || v.event}</span>{' '}
                  <Tooltip title={`${translations[v.item_type] || v.item_type}: ${v.item_id}`}>
                    <span className='workspace-ov-activity-type'>
                      {translations[v.item_type] || v.item_type}
                      {v.item_name && ` - ${v.item_name}`}
                    </span>
                  </Tooltip>
                </div>
                {showDetail && (
                  <div className='workspace-ov-activity-detail'>
                    {entries(v.details).map(([key, value], index) => {
                      if (isArray(value)) {
                        return (
                          <div className='workspace-ov-activity-message' key={`details-${index}`}>
                            <span className='grey_text'>{` - ${v.user_name}`}</span>
                            <span className='workspace-ov-activity-event'>{v.event}</span>
                            <span className='workspace-ov-activity-type'>{`${titleCase(key)}: ${value[0]} -> ${value[1]}`}</span>
                          </div>
                        )
                      }

                      return <p key={`details-${index}`}>{`${titleCase(key)}: ${value}`}</p>
                    })}
                  </div>
                )}
              </TextRow>
            </div>
          )
        })}

        {meta && (
          <Pagination
            hideOnSinglePage={true}
            showQuickJumper={true}
            onChange={this.selectPage}
            style={{ padding: '20px 0', textAlign: 'right' }}
            current={meta.current_page}
            defaultPageSize={15}
            total={meta.total_count}
          />
        )}
      </div>
    )
  }
}

const matState: MapState<StateProps> = ({ V3: { activeLog } }) => ({
  logs: activeLog.data,
  meta: activeLog.meta,
  query: activeLog.query
})

export default withRouter(connect(matState)(History))
