import { put, take, call, fork, cancel } from 'redux-saga/effects'
import { requestStart, requestFailed, requestSuccess } from 'actions/global'
import { SagaIterator } from 'redux-saga'
import cache from 'sagas/cache'

// 只执行最后一次调用
export const watchRequest = (ActionName: string, saga: (...args: any[]) => any) =>
  function* () {
    let lastTask
    while (true) {
      const action = yield take(ActionName)
      if (lastTask) {
        yield cancel(lastTask)
      }
      lastTask = yield fork(RequestSagaCreator, saga, action)
    }
  }

// 每次都执行
export const watchRequestNoCancel = (ActionName: string, saga: (...args: any[]) => any) =>
  function* () {
    while (true) {
      const action = yield take(ActionName)
      yield fork(RequestSagaCreator, saga, action)
    }
  }

function* RequestSagaCreator(
  saga: (...args: any[]) => SagaIterator,
  action: any,
  useCache: boolean = true // flag 调用是否开启缓存
) {
  // 判断是否用缓存
  const use = cache.shouldUseCache(action)
  const state = cache.getCache()[action.type] || {}
  const args = action.args || {}

  if (useCache && use) {
    // 针对promise action 将上次的结果 return 给 promise
    if (args.resolve) {
      action.args.resolve(state.data)
    }

    return
  }

  // 控制请求的 loading show === false 时不加载
  yield put(requestStart(args.show === false ? false : true))

  try {
    // 真正执行请求的部分 data 为requst 函数的返回值
    const data = yield call(saga, args)
    yield put(requestSuccess())

    if (args.resolve) {
      if (useCache) {
        // 像 cache 表中存上结果 以便promise 中使用
        state.data = data
      }
      action.args.resolve(data)
    }
  } catch (e: any) {
    yield put(requestFailed(e))
    // 判断错误是否需要交给之后的组件catch
    if (e.reject) {
      if (action.args && action.args.reject) {
        action.args.reject(e)
      }
    }
  }
}
