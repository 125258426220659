import { CertificationObj } from 'actions/V2/certifications';
import {
  ReportDetail,
  requestReportDetail,
  RequestReportDetailAction
} from 'actions/V3/report';
import BarChart from 'components/BarChart';
import LineChart from 'components/LineChart';
import ScrollAffix from 'components/ScrollAffix';
import { t } from 'i18next';
import { max, min } from 'lodash';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { promiseDispatch } from 'utils';
import { LEVEL } from 'utils/constant';
import { ReportColors } from 'utils/enums';
import { calcAvg, calcWorkingHourRange, filterWorkingHour } from 'utils/helper';
import './report.css';

type StateProps = {
  report?: ReportDetail;
  certifications: CertificationObj[];
};

type P = StateProps &
  DispatchProp<RequestReportDetailAction> &
  RouteComponentProps<{ id: string; report_id: string }>;

type State = Readonly<{
  loaded: boolean;
  notFound: boolean;
}>;

const level = LEVEL();
class ReportView extends React.PureComponent<P, State> {
  state: State = {
    loaded: false,
    notFound: false
  };
  workHours: (string | null)[] = [];
  workHoursRange?: number[][];
  componentDidMount() {
    this.fetchReportData();
  }

  fetchReportData = async () => {
    const {
      dispatch,
      match: {
        params: { report_id }
      }
    } = this.props;

    const report = await promiseDispatch<any>({
      dispatch,
      actionCreator: requestReportDetail,
      payload: { report_id }
    }).catch(() => this.setState({ notFound: true }));
    this.workHours = filterWorkingHour(
      report.times,
      report.working_hours,
      '(]'
    );

    this.workHoursRange = calcWorkingHourRange(
      report.times,
      report.working_hours
    );
    this.setState({
      loaded: true
    });
  };

  summayData = (data, precision: number, workingHours: boolean) => {
    const compareOutdoor = this.props.report!.enable_outdoor_location;
    const outdoor = data.outdoor || [];
    const indoorWorkValue = workingHours
      ? data.indoor.filter((_, i) => this.workHours[i] !== null)
      : [];

    const indoorAvgAll = calcAvg(data.indoor, precision);

    const outdoorAvgAll = compareOutdoor
      ? calcAvg(outdoor, precision)
      : undefined;
    const indoorWorkAvg = workingHours
      ? calcAvg(indoorWorkValue, precision)
      : undefined;

    const outdoorWorkAvg =
      workingHours && compareOutdoor
        ? calcAvg(
          outdoor.filter((_, i) => this.workHours[i] !== null),
          precision
        )
        : undefined;

    const maxAll = max(data.indoor);
    const minAll = min(data.indoor);
    const maxWork = workingHours ? max(indoorWorkValue) : undefined;
    const minWork = workingHours ? min(indoorWorkValue) : undefined;

    return [
      {
        label: t('Report.INDOOR AVERAGE'),
        primary: indoorWorkAvg || indoorAvgAll,
        second: indoorWorkAvg && indoorAvgAll
      },
      compareOutdoor && {
        label: t('Report.OUTDOOR AVERAGE'),
        primary: outdoorWorkAvg || outdoorAvgAll,
        // all hours
        second: outdoorWorkAvg && outdoorAvgAll
      },
      {
        label: t('Report.MAX'),
        badge: 1,
        primary: maxWork || maxAll,
        // all hours
        second: maxWork && maxAll
      },
      {
        label: t('Report.MIN'),
        badge: 2,
        primary: minWork || minAll,
        // all hours
        second: minWork && minAll
      }
    ].filter(Boolean) as {
      label: string;
      primary: string;
      second?: string;
      badge: number;
    }[];
  };

  prSummaryData = (data, workingHours: boolean) => {
    const indoorWorkValue = workingHours
      ? data.filter((_, i) => this.workHours[i] !== null)
      : [];
    const PRECISION = 1
    const maxAll = max(data);
    const minAll = min(data);
    const avg = calcAvg(data, PRECISION);
    const workAvg = workingHours ? calcAvg(indoorWorkValue, PRECISION) : undefined;
    const maxWork = workingHours ? max(indoorWorkValue) : undefined;
    const minWork = workingHours ? min(indoorWorkValue) : undefined;
    return [
      {
        label: t('Report.AVERAGE'),
        primary: workAvg || avg,
        second: workAvg && avg
      },
      {
        label: t('Report.MAX'),
        badge: 1,
        primary: maxWork || maxAll,
        // all hours
        second: maxWork && maxAll
      },
      {
        label: t('Report.MIN'),
        badge: 2,
        primary: minWork || minAll,
        // all hours
        second: minWork && minAll
      }
    ];
  };

  maxAndMin = (reading) => {
    const maxValue = max(reading) as number;
    const minValue = min(reading) as number;
    const maxIdx = reading
      .map((v, i) => (v === maxValue ? i : null))
      .filter((v) => v !== null);
    const minIdx = reading
      .map((v, i) => (v === minValue ? i : null))
      .filter((v) => v !== null);

    return {
      max: maxValue,
      min: minValue,
      maxIdx,
      minIdx
    };
  };

  adjustCategoryByTimes = (times) => {
    const removeZero = 0
    const firstElement = 0
    const category = times.slice(removeZero);
    const first = times[firstElement].split(' ')[firstElement] + ' 00:00:00';
    category.unshift(first);
    category.pop();
    return category;
  };

  featureRender = (type, fi, mi) => {
    const report = this.props.report!;
    const module = report.modules[mi];
    const undefinedValue = 0
    const currentStandard = this.props.certifications.find(
      (c) => c.name === module.configs.health_standard
    ) || {
      name: '',
      standards: {
        default: {
          pm2p5: undefinedValue,
          co2: undefinedValue,
          tvoc: undefinedValue
        }
      }
    };
    if (!currentStandard.standards) {
      currentStandard.standards = {
        default: {
          pm2p5: undefinedValue,
          co2: undefinedValue,
          tvoc: undefinedValue
        }
      };
    }
    const standards = currentStandard.standards.default;

    switch (type) {
      case 'overall': {
        const indicatorData = [
          {
            label: module.indicator_name,
            color: ReportColors.Green,
            data: module.overall!.indoor
          }
        ];

        if (report.enable_outdoor_location) {
          indicatorData.push({
            label: t('OUTDOOR'),
            color: ReportColors.Blue,
            data: module.overall!.outdoor
          });
        }

        const summayData = this.summayData(
          module[type],
          module.precision,
          !!module.configs.enable_working_hours
        );

        const indoorReading = !!module.configs.enable_working_hours
          ? module.overall!.indoor.map((v, i) => (this.workHours[i] ? v : null))
          : module.overall!.indoor;
        const summary = this.maxAndMin(indoorReading);

        const maxTicksLimit = 4
        const minBadge = 1
        const maxBadge = 2
        const minVertical = 0
        return (
          <>
            <div className='report-view-subtitle'>
              {`${module.indicator_name} ${t('Report.overall')}`}
            </div>
            <div className='report-view-summary'>
              {summayData.map((item, index) => (
                <div key={index} className='report-summary-unit'>
                  <div className='report-summary-unit-label'>{item.label}</div>
                  <div className='report-summary-unit-content2'>
                    <div className='report-v-top'>
                      <div className='report-v-left'>
                        <div className='report-v-primary'>
                          {item.primary || '-'}
                        </div>
                      </div>
                      <div className='report-v-right'>
                        {item.badge && (
                          <div className='report-v-badge'>
                            <span>{item.badge}</span>
                          </div>
                        )}
                        <div className='report-v-unit'>{module.unit}</div>
                      </div>
                    </div>
                    {!!module.configs.enable_working_hours && (
                      <div className='report-v-bot'>
                        {item.second || '-'}{' '}
                        <span className='report-v-bot-desc'>
                          {t('Report.all hours')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className='report-bar-header'>
              <div className='report-bar-legend'>
                <span style={{ borderColor: ReportColors.Green }}>
                  {t('INDOOR')}
                </span>
                {report.enable_outdoor_location && (
                  <span style={{ borderColor: ReportColors.Blue }}>
                    {t('OUTDOOR')}
                  </span>
                )}
              </div>
              {(!!module.configs.enable_working_hours ||
                !!module.configs.health_standard) && (
                  <div className='report-filter'>
                    {t('Report.Filters applied')}:
                    {module.configs.enable_working_hours ? (
                      <span className='report-filter-tag'>
                        {t('Report.Working Hours')}
                      </span>
                    ) : null}
                    {module.configs.health_standard ? (
                      <span className='report-filter-tag'>
                        {module.configs.health_standard}
                      </span>
                    ) : null}
                  </div>
                )}
            </div>
            <LineChart
              isLegendDisplayed={false}
              isStatic={true}
              category={this.adjustCategoryByTimes(report.times)}
              labelText={module.unit}
              maxTicksLimit={maxTicksLimit}
              workHoursFilter={
                module.configs.enable_working_hours
                  ? this.workHoursRange
                  : undefined
              }
              indicatorData={indicatorData}
              summary={summary}
              standardVal={standards[module.indicator]}
              standardLabel={currentStandard.name}
              // 待修改 高度区间 颜色
              colourBar={level[module.indicator]}
              highLow={[minBadge, maxBadge]}
              minYStart={minVertical}
              maxYEnd={undefined}
            />
          </>
        );
      }
      case 'historical': {
        const barData = [
          {
            label: t('INDOOR'),
            color: ReportColors.Green,
            data: module.historical!.map((v) => v.indoor)
          }
        ];

        if (report.enable_outdoor_location) {
          barData.push({
            label: t('OUTDOOR'),
            color: ReportColors.Blue,
            data: module.historical!.map((v) => v.outdoor)
          });
        }
        const fiveElements = 5
        const tenFirstElements = 10
        const labels = module.historical!.map(
          (v) =>
            `${v.begin_date.slice(fiveElements, tenFirstElements).replace(/-/g, '/')}~${v.end_date
              .slice(fiveElements, tenFirstElements)
              .replace(/-/g, '/')}`
        );
        return (
          <>
            <div className='report-view-subtitle'>
              {`${module.indicator_name} ${t(
                'Report.Historical Comparison'
              )} (${t('Average')})`}
            </div>
            <div className='report-bar-header'>
              <div className='report-bar-legend'>
                <span style={{ borderColor: ReportColors.Green }}>
                  {t('INDOOR')}
                </span>
                {report.enable_outdoor_location && (
                  <span style={{ borderColor: ReportColors.Blue }}>
                    {t('OUTDOOR')}
                  </span>
                )}
              </div>
              {module.configs.enable_working_hours ? (
                <div className='report-filter'>
                  {t('Report.Filters applied')}:
                  <span className='report-filter-tag'>
                    {t('Report.Working Hours')}
                  </span>
                </div>
              ) : null}
            </div>
            <BarChart
              labels={labels}
              labelText={module.unit}
              indicatorData={barData}
            />
          </>
        );
      }
      case 'overall_percent_reduction': {
        const summayData = this.prSummaryData(
          module.overall_percent_reduction!.readings,
          !!module.configs.enable_working_hours
        );

        const summary = this.maxAndMin(
          module.overall_percent_reduction!.readings
        );

        const minBadge = 1
        const maxBadge = 2
        const maxTicksLimit = 6
        const minVertical = -100
        const maxVertical = 100
        return (
          <>
            <div className='report-view-subtitle'>
              <div className='report-panel-title'>
                {`${module.indicator_name} ${t(
                  'Report.Percent Reduction Data'
                )}`}
              </div>
            </div>
            <div className='report-view-summary'>
              {summayData.map((item, index) => (
                <div key={index} className='report-summary-unit'>
                  <div className='report-summary-unit-label'>{item.label}</div>
                  <div className='report-summary-unit-content2'>
                    <div className='report-v-top'>
                      <div className='report-v-left'>
                        <div className='report-v-primary'>
                          {(item.primary as string) || '-'}
                        </div>
                      </div>
                      <div className='report-v-right'>
                        {item.badge && (
                          <div className='report-v-badge'>
                            <span>{item.badge}</span>
                          </div>
                        )}
                        <div className='report-v-unit'>%</div>
                      </div>
                    </div>
                    {!!module.configs.enable_working_hours && (
                      <div className='report-v-bot'>
                        {item.second || '-'}{' '}
                        <span className='report-v-bot-desc'>
                          {t('Report.all hours')}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div className='report-bar-header'>
              <div className='report-bar-legend'>
                <span style={{ borderColor: ReportColors.Yellow }}>
                  {t('Report.Reduction')}
                </span>
              </div>
              {module.configs.enable_working_hours ? (
                <div className='report-filter'>
                  {t('Report.Filters applied')}:
                  <span className='report-filter-tag'>
                    {t('Report.Working Hours')}
                  </span>
                </div>
              ) : null}
            </div>
            <LineChart
              isLegendDisplayed={false}
              isStatic={true}
              category={this.adjustCategoryByTimes(report.times)}
              labelText='%'
              maxTicksLimit={maxTicksLimit}
              workHoursFilter={
                module.configs.enable_working_hours
                  ? this.workHoursRange
                  : undefined
              }
              indicatorData={[
                {
                  label: t('Report.Reduction'),
                  color: ReportColors.Yellow,
                  data: module.overall_percent_reduction!.readings
                }
              ]}
              summary={summary}
              // 待修改 高度区间 颜色
              highLow={[minBadge, maxBadge]}
              minYStart={minVertical}
              maxYEnd={maxVertical}
            />
          </>
        );
      }

      case 'historical_percent_reduction': {
        const barData = [
          {
            label: t('Report.Reduction'),
            color: ReportColors.Yellow,
            data: module.historical_percent_reduction!.map((v) => v.value)
          }
        ];
        const fiveElements = 5
        const tenFirstElements = 10
        const labels = module.historical!.map(
          (v) =>
            `${v.begin_date.slice(fiveElements, tenFirstElements).replace(/-/g, '/')}~${v.end_date
              .slice(fiveElements, tenFirstElements)
              .replace(/-/g, '/')}`
        );

        let numArr: number[] = []
        barData.forEach((item) => {
          console.log('item.data',item.data)
          numArr = [...numArr, ...item.data]
        })
        const numMin: number = Math.min(...numArr)
        let xMin = 0
        if(numMin < 0){
          xMin = Math.floor(numMin/20)*20
        }

        return (
          <>
            <div className='report-view-subtitle'>
              {`${module.indicator_name} ${t(
                'Report.historical_percent_reduction'
              )}`}
            </div>
            <div className='report-bar-header'>
              <div className='report-bar-legend'>
                <span style={{ borderColor: ReportColors.Yellow }}>
                  {t('Report.Reduction')}
                </span>
              </div>
              {module.configs.enable_working_hours ? (
                <div className='report-filter'>
                  {t('Report.Filters applied')}:
                  <span className='report-filter-tag'>
                    {t('Report.Working Hours')}
                  </span>
                </div>
              ) : null}
            </div>
            <BarChart labels={labels} labelText='%' indicatorData={barData} suggestedMin={xMin}/>
          </>
        );
      }
      default:
        return;
    }
  };

  renderCompare = (index: number) => {
    const report = this.props.report!;
    const module = report.modules[index];
    const firstElement = 0
    const secondElement = 1
    const indicator1 = module.compared_overall![firstElement];
    const indicator2 = module.compared_overall![secondElement];

    const indicatorData = [
      {
        label: indicator1.indicator_name,
        color: ReportColors.Green,
        data: indicator1.readings
      },
      {
        label: indicator2.indicator_name,
        color: ReportColors.Orange,
        data: indicator2.readings
      }
    ];

    const minBadge = 1
    const maxBadge = 2
    const minVertical = 0
    const maxTicksLimit = 4
    return (
      <div
        key={`compare-module-${index}`}
        id={`#module-${index}-0`}
        className='report-scroll-element'
      >
        <div className='report-view-subtitle'>
          {`${t('Report.comparsion')} (${indicator1.indicator_name} VS ${indicator2.indicator_name
            })`}
        </div>
        <div className='report-bar-header'>
          <div className='report-bar-legend'>
            <span style={{ borderColor: ReportColors.Green }}>
              {indicator1.indicator_name}
            </span>
            <span style={{ borderColor: ReportColors.Orange }}>
              {indicator2.indicator_name}
            </span>
          </div>
          {module.configs.enable_working_hours ? (
            <div className='report-filter'>
              {t('Report.Filters applied')}:
              <span className='report-filter-tag'>
                {t('Report.Working Hours')}
              </span>
            </div>
          ) : null}
        </div>
        <LineChart
          isLegendDisplayed={false}
          isStatic={true}
          noFill={true}
          multipleYxes={true}
          category={this.adjustCategoryByTimes(report.times)}
          labelText={module.compared_overall!.map((v) => v.unit)}
          maxTicksLimit={maxTicksLimit}
          workHoursFilter={
            module.configs.enable_working_hours
              ? this.workHoursRange
              : undefined
          }
          indicatorData={indicatorData}
          colourBar={module.compared_overall!.map((v) => level[v.indicator])}
          // summary={summary}
          // 待修改 高度区间 颜色
          highLow={[minBadge, maxBadge]}
          minYStart={minVertical}
        />
      </div>
    );
  };

  render() {
    const { loaded } = this.state;
    const { report } = this.props;
    if (!loaded || !report) {
      return null;
    }
    const fakeT = t;
    const outerBorderDistance = 300
    return (
      <div>
        <ScrollAffix
          offsetTop={outerBorderDistance}
          trigerName='.report-scroll-element'
          scrollConfig={(hash) => ({
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          })}
        >
          {report.modules.map((m, i) => (
            <React.Fragment key={`module-${i}`}>
              <div className='scroll-affix-title'>{m.name}</div>
              <ul className='scroll-affix-list'>
                {m.category === 'indicator' ? (
                  m.features.map((f, fi) => (
                    <li key={`scroll-item-${fi}`}>
                      <a href={`#module-${i}-${fi}`}>{fakeT(`Report.${f}`)}</a>
                    </li>
                  ))
                ) : (
                  <li>
                    <a href={`#module-${i}-0`}>{fakeT('Report.comparsion')}</a>
                  </li>
                )}
              </ul>
            </React.Fragment>
          ))}
        </ScrollAffix>

        <div className='report-preview-title'>{report.location_name}</div>

        {report.modules.map((module, index) => (
          <div className='report-panel' key={`report-panel-${index}`}>
            <div className='report-view-title'>{module.name}</div>
            {module.category === 'indicator'
              ? module.features.map((f, fi) => (
                <div
                  className='report-scroll-element'
                  key={`module-${index}-feature-${fi}`}
                  id={`#module-${index}-${fi}`}
                >
                  {this.featureRender(f, fi, index)}
                </div>
              ))
              : this.renderCompare(index)}
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({
  V2: { certifications },
  V3: {
    report: { detail }
  }
}) => ({
  report: detail,
  certifications: certifications.data
});

export default withRouter(connect(mapStateToProps)(ReportView));
