import React, { useState } from 'react'
import { t } from 'i18next'
import { Button, Collapse, Radio, Tabs, Popconfirm, Input, Switch } from 'antd'
import SettingModalContentT2 from './SettingModalContentT2'
import { promiseDispatch } from 'utils'
import { requestKioskDelete } from 'actions/V3/kiosks'
import Collapsedpannel from './Collapsedpannel'
import ThemeSetting from './ThemeSetting'
import { requestAccounts } from 'actions/V2/accounts'
import { SettingModalContentSize } from '../../utils/enums'
import { CollapsePanelinfo } from 'actions/V3/kiosks'

const TabPane = Tabs.TabPane
const Panel = Collapse.Panel

export default function SettingModalContent({
  movedDownSetState,
  updateKioskData,
  GuardHandle_OpenSetting,
  GuardHandle_kioskAverage,
  handleSettingModalCancel,
  _getUserNotificationSettings,
  doSave,
  doSaveTemplate2,
  dispatch,
  uploadWebBackground,
  uploadWebLogo,
  onChangeRemoveImage,
  handleColorChange,
  handleFontColorChange,
  match,
  meta,
  accounts,
  currentKiosk,
  currentTab,
  state
}) {
  const [tab, setTab] = useState(currentTab)

  const StandardSize: SettingModalContentSize = 100

  const setUnitType = (e: any) => {
    movedDownSetState({
      currentKiosk: Object.assign({}, currentKiosk, {
        use_aqi: e.target.value === 'b'
      })
    })
  }

  const WorkspaceAccounts = (size: any) => {
    dispatch(
      requestAccounts({
        size,
        workspace_id: match.params.id
      })
    )
  }

  const renderCollapsePanel = (obj: any, isAverage: boolean, useAqi: any, template_key: any, meta: any) => {
    const { data_channels, name, id } = obj

    return (
      <Panel
        header={isAverage ? t('Location Average') : name}
        key={id || 'average'}
        extra={
          <div className='kiosk-collection-hidden'>
            <span className='desc'>{t('Visible')}</span>
            <Switch defaultChecked={!obj.hidden} onClick={(v, e) => e.stopPropagation()} onChange={v => (obj.hidden = !v)} />
          </div>
        }
      >
        <Collapsedpannel
          template_key={template_key}
          obj={obj}
          currentKiosk={currentKiosk}
          useAqi={useAqi}
          data_channels={data_channels}
          meta={meta}
        />
      </Panel>
    )
  }

  const handleDeleteKiosk = async ({ id, location_id }) => {
    await promiseDispatch({
      dispatch,
      actionCreator: requestKioskDelete,
      payload: {
        kiosk_id: id,
        location_id: location_id
      }
    })
    handleSettingModalCancel()
  }

  const GuardRender_publicSetting = () => {
    if (!currentKiosk) {
      return <div>{t('all locations has kiosks')}</div>
    }
    return (
      <div className='location_detail-setting-row is-working_hours'>
        <div className='location_detail-setting-row_left'>
          <div className='label'>{t('locationOverview.Locked')}</div>
          <p className='small_text'>{t('locationOverview.if locked')}</p>
          {currentKiosk.locked && (
            <div style={{ marginTop: 16 }}>
              <p className='register-group-label'>{t('password')}</p>
              <Input value={currentKiosk.password} onChange={e => updateKioskData('password', e.target.value)} />
            </div>
          )}
        </div>

        <div className='location_detail-setting-row_right'>
          <Switch checked={currentKiosk.locked} onChange={v => updateKioskData('locked', v)} />
        </div>
      </div>
    )
  }

  if (!currentKiosk) {
    return <div>{t('all locations has kiosks')} </div>
  }

  if (currentKiosk.template_key === 'fixed_panels') {
    if (!accounts.length) {
      WorkspaceAccounts(StandardSize)
    }
    return (
      <SettingModalContentT2
        currentKiosk={currentKiosk}
        currentTab={currentTab}
        notificationConfigs={state.notificationConfigs}
        movedDownSetState={movedDownSetState}
        accounts={accounts}
        _getUserNotificationSettings={_getUserNotificationSettings}
        GuardRender_publicSetting={GuardRender_publicSetting}
        handleDeleteKiosk={handleDeleteKiosk}
        handleSettingModalCancel={handleSettingModalCancel}
        doSaveTemplate2={doSaveTemplate2}
      />
    )
  }

  const r = GuardHandle_OpenSetting()

  return (
    <div className='body'>
      {(currentKiosk.id || (r.rule && r.rule.enable)) && (
        <Tabs
          defaultActiveKey='1'
          onChange={key => {
            setTab(key)
          }}
          animated={{ inkBar: true, tabPane: false }}
        >
          <TabPane tab={t('kiosk.tab content')} key='1'>
            <div className='unit-of-type'>
              <span className='label'>{t('Units of Type')}:</span>
              <Radio.Group onChange={setUnitType} size='small' defaultValue={currentKiosk.use_aqi ? 'b' : 'a'} buttonStyle='solid'>
                <Radio.Button value='a'>{t('kiosk.Concentration')}</Radio.Button>
                <Radio.Button value='b'>AQI</Radio.Button>
              </Radio.Group>
              <div className='help-hint'>({t('Units of Type hint')})</div>
            </div>
            <Collapse accordion={true} bordered={false} defaultActiveKey={['average']}>
              {GuardHandle_kioskAverage() &&
                renderCollapsePanel(
                  //need to update currentKiosk to display new titles
                  currentKiosk.average,
                  true,
                  currentKiosk.use_aqi,
                  currentKiosk.template_key,
                  meta
                )}
              {currentKiosk.collections.map((collection: CollapsePanelinfo) =>
                renderCollapsePanel(collection, false, currentKiosk.use_aqi, currentKiosk.template_key, meta)
              )}
            </Collapse>
          </TabPane>
          <TabPane tab={t('kiosk.tab web theme')} key='2'>
            <ThemeSetting
              state={state}
              updateKioskData={updateKioskData}
              movedDownSetState={movedDownSetState}
              uploadWebBackground={uploadWebBackground}
              onChangeRemoveImage={onChangeRemoveImage}
              uploadWebLogo={uploadWebLogo}
              handleColorChange={handleColorChange}
              handleFontColorChange={handleFontColorChange}
            />
          </TabPane>
          <TabPane tab={t('kiosk.tab public')} key='3'>
            {GuardRender_publicSetting()}
          </TabPane>
          <TabPane tab={t('kiosk.tab delete')} key='4'>
            <div className='body'>
              <div className='tab-title'>{t('kiosk.tab delete')}</div>
              <p className='tab-desc'>{t('kiosk.tab delete content')}</p>
            </div>
          </TabPane>
        </Tabs>
      )}
      {tab === '4' ? (
        <div className='footer'>
          <Popconfirm
            title='Are you sure delete this kiosk?'
            onConfirm={() => {
              handleDeleteKiosk(currentKiosk)
            }}
            okText='Yes'
            cancelText='No'
          >
            <Button type='danger'>{t('kiosk.tab delete')}</Button>
          </Popconfirm>
        </div>
      ) : (
        <div className='footer'>
          <Button type='primary' ghost={true} onClick={handleSettingModalCancel}>
            {t('Cancel')}
          </Button>
          <Button type='primary' onClick={doSave}>
            {t('Save')}
          </Button>
        </div>
      )}
    </div>
  )
}
