import { DownloadAction, GET_DOWNLOADS_V3, DownloadObj } from 'actions/V3/downloads'

export type DownloadsState = {
  data: DownloadObj[],
  meta?: Meta,
  query?: {}
}

export default function Downloads(
  state: DownloadsState = {
    data: []
  },
  action: DownloadAction
) {
  switch (action.type) {
    case GET_DOWNLOADS_V3:
      return {
        data: action.downloads,
        meta: action.meta,
        query: action.query
      }
    default:
      return state
  }
}
