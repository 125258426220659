import * as React from 'react'
import Redirect from './Redirect'
import { isLogin } from 'utils/auth'
import { RouteProps, Route } from 'react-router-dom'
import { t } from 'i18next'

const LoginedRoute: React.SFC<
  {
    component: any,
    props?: {}
  } & RouteProps
> = React.memo(({ component: C, props: componentProps = {}, ...routeProps }) => (
  <Route
    {...routeProps}
    render={() =>
      isLogin() ? (
        <C {...componentProps} {...routeProps} />
      ) : (
        <Redirect
          to={{
            pathname: '/exception',
            state: {
              message: t('exception.needLogin'),
              button: {
                message: t('exception.login'),
                navigate: '/user/sign_in'
              }
            }
          }}
        />
      )
    }
  />
))

export default LoginedRoute
