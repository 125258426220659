import {
  WorkspaceAction,
  GET_WORKSPACE,
  GET_WORKSPACE_LIST,
  GET_WORKSPACE_EDIT,
  GET_WORKSPACE_DELETE,
  GET_WORKSPACE_NEW,
  WorkspaceObj
} from 'actions/V3/workspace'

export type WorkspaceState = {
  data?: WorkspaceObj,
  list?: WorkspaceObj[]
}

export default function Workspace(state: WorkspaceState = {}, action: WorkspaceAction) {
  switch (action.type) {
    case GET_WORKSPACE:
      return {
        ...state,
        data: action.workspace
      }
    case GET_WORKSPACE_NEW:
      state.list = state.list || []
      state.list.push(action.workspace)
      return Object.assign({}, state, { list: state.list })
    case GET_WORKSPACE_LIST:
      return {
        list: action.workspaces
      }
    case GET_WORKSPACE_EDIT:
      if (state.data) {
        state.data = Object.assign(state.data, {
          ...action.workspaceEdit
        })
      }
      if (state.list) {
        const id = Number(action.workspaceEdit.id)
        const objIdx = state.list.findIndex(i => i.id === id)
        state.list[objIdx] = Object.assign({}, state.list[objIdx], {
          ...action.workspaceEdit
        })
        state.list = state.list.slice()
      }
      return { ...state }
    case GET_WORKSPACE_DELETE:
      let newList = state.list
      if (state.list) {
        newList = state.list.filter(i => i.id !== action.workspaceDelete.id)
      }
      return {
        data: undefined,
        list: newList
      }
    default:
      return state
  }
}
