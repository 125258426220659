import * as React from 'react'
import moment from 'moment'
import Table from 'components/Custom/Table'
import { BillingObj, requestMonthBilling, RequestMonthBillingAction } from 'actions/V3/billing'
import { connect } from 'react-redux'
import { DispatchProp } from 'react-redux'
import { iosMonth } from 'utils/helper'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'

import { t } from 'i18next'
import './index.css'

interface StateProps {
  data: BillingObj[];
  query?: any;
  meta?: Meta;
}

type P = StateProps & DispatchProp<RequestMonthBillingAction> & RouteComponentProps<{ id: string, billing_id: string }>

class BillingDetail extends React.PureComponent<P, {}> {
  componentDidMount() {
    this.fetchBillingList()
  }

  fetchBillingList = (page = 1) => {
    const {
      dispatch,
      match: {
        params: { id, billing_id }
      }
    } = this.props

    dispatch(requestMonthBilling({ id, billing_id, page }))
  }

  // pagination = () => {
  //   const { meta } = this.props;
  //   return meta
  //     ? {
  //         hideOnSinglePage: true,
  //         showQuickJumper: true,
  //         onChange: this.fetchBillingList,
  //         current: meta.current_page,
  //         pageSize: 20,
  //         total: meta.total_count,
  //         showTotal: () => {
  //           return t("showTotal", { total: meta.total_count });
  //         }
  //       }
  //     : {};
  // };

  renderTable = () => {
    const {
      data,
      match: {
        params: { id }
      }
    } = this.props

    const tableData = data.map((item, i) => ({
      ...item,
      key: i
    }))
    const today = moment().format('MMM DD')
    const isLastMonth = data[0] && moment().format('YYYY MMMM') === iosMonth(data[0].year, data[0].month).format('YYYY MMMM')

    const columns: any[] = [
      {
        title: t('overview.Workspace ID'),
        dataIndex: 'workspace_code',
        width: '15%',
        key: 'workspace_code'
      },
      {
        title: t('Workspace Name'),
        dataIndex: 'workspace_name',
        width: '20%',
        key: 'workspace_name'
      },
      {
        title: t('indicator.active count'),
        dataIndex: 'active_indicators_count',
        key: 'active_indicators_count',
        render: (text, record) => {
          return text || record.indicators_count
        }
      }
    ]

    const geneTableColumns2 = () => {
      if (isLastMonth) {
        return [
          {
            title: t('Estimated Total'),
            dataIndex: 'estimated_price',
            width: '20%',
            key: 'estimated_price',
            render: (text, record) => {
              return <div>CNY {text}</div>
            }
          },
          {
            title: t('Accrued Cost') + `( ${t('billing.as of', { date: today })} )`,
            dataIndex: 'total_price',
            width: '20%',
            key: 'total_price',
            render: (text, record) => {
              return <div>CNY {text}</div>
            }
          }
        ]
      }
      return [
        {
          title: t('Accrued Cost'),
          dataIndex: 'total_price',
          width: '20%',
          key: 'total_price',
          render: (text, record) => {
            return <div>CNY {text}</div>
          }
        },
        {
          title: t('Invoice'),
          dataIndex: 'id',
          width: '15%',
          key: 'id',
          render: (text, record) => {
            return <Link to={`/invoice/workspace/${id}/${text}/${record.workspace_id}`}>{t('See Invoice')}</Link>
          }
        }
      ]
    }

    return (
      <Table
        rowClassName={() => 'home-ws-row'}
        type='light'
        // pagination={this.pagination()}
        columns={columns.concat(geneTableColumns2())}
        dataSource={tableData}
        size='default'
      />
    )
  }

  navBack = () => {
    const { history } = this.props
    history.goBack()
  }

  render() {
    const { data } = this.props

    return (
      <div className='location-wrapper'>
        <div className='billing-container'>
          <div className='go-back'>
            <div className='title'>
              <i className='iconfont icon-back' onClick={this.navBack} />
              {data &&
                data[0] &&
                t('billing.All Workspace Billing in', {
                  month: iosMonth(data[0].year, data[0].month).format('MMM')
                })}
            </div>
          </div>
          {this.renderTable()}
        </div>
      </div>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { billing } }) => billing.month

export default withRouter(connect(mapStateToProps)(BillingDetail))
