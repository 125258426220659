import { put, call } from 'redux-saga/effects'
import * as actions from 'actions/V3/report'
import { watchRequestNoCancel } from 'sagas/helper'
import { apiV3 } from 'utils/api'

function* requestList(args: any) {
  const { data, meta } = yield call(apiV3, '/reports', 'get', {
    ...args
  })

  yield put(actions.getReportList(data, meta, args))
  return data
}

export const reportList = watchRequestNoCancel(actions.REQUEST_REPORT_LIST_V3, requestList)

function* requestTemplate(args: any) {
  const { data, meta } = yield call(apiV3, '/report_templates', 'get', {
    ...args
  })

  yield put(actions.getReportTemplate(data, meta, args))
  return data
}

export const reportTemplate = watchRequestNoCancel(actions.REQUEST_REPORT_TEMPLATE_V3, requestTemplate)

function* requestDetail(args: any) {
  const { data } = yield call(apiV3, `/reports/${args.report_id}`)

  yield put(actions.getReportDetail(data))
  return data
}

export const reportDetail = watchRequestNoCancel(actions.REQUEST_REPORT_DETAIL_V3, requestDetail)
