import { Action, ActionCreator } from 'redux'

export const REQUEST_LOCATION_LIST_V3 = 'REQUEST_LOCATION_LIST_V3'
export const GET_LOCATION_LIST_V3 = 'GET_LOCATION_LIST_V3'

export const REQUEST_LOCATION_LIST_AVAILABLE_V3 = 'REQUEST_LOCATION_LIST_AVAILABLE_V3'
export const GET_LOCATION_LIST_AVAILABLE_V3 = 'GET_LOCATION_LIST_AVAILABLE_V3'
type Reading = {
  data_channel: string,
  name: string,
  type: DataChannelType,
  unit: string,
  value?: number,
  level?: string
}
export type LocationItem = {
  abbr: string,
  id: number,
  city_id: number,
  pro: boolean,
  qr_code: string,
  category: LocationCategory,
  time_zone: string,
  address?: string,
  created_at: string,
  followed: boolean,
  following_id?: number,
  locked: boolean,
  name: string,
  city_name: string,
  workspace_id: number,
  workspace_name: string,
  search_time_enabled_at: string,
  background_image?: string,
  logo_image?: string,
  type?: string,
  work_hours?: {
    active: boolean,
    data: {
      day_in_week: number,
      active: boolean,
      begin_time: string,
      end_time: string
    }[]
  },
  outdoor?: {
    id: number,
    name: string
  },
  collections: {
    id: number,
    code: string,
    name: string
  }[],
  average?: {
    status: string,
    performance: string,
    stale: boolean,
    last_reading_time: string,
    readings: Reading[]
  },
  data_channels: {
    channel: string,
    name: string
  }[]
}

export interface RequestLocationListV3Action extends Action<typeof REQUEST_LOCATION_LIST_V3> {
  args: any;
}

export interface RequestLocationListAvailableV3Action extends Action<typeof REQUEST_LOCATION_LIST_AVAILABLE_V3> {
  args: any;
}

interface GetLocationListV3 extends Action<typeof GET_LOCATION_LIST_V3> {
  data: LocationItem[];
  meta: Meta;
  query: any;
}

interface GetLocationListAvailableV3 extends Action<typeof GET_LOCATION_LIST_AVAILABLE_V3> {
  data: LocationItem[];
}

export type LocationListV3Action = GetLocationListV3 | GetLocationListAvailableV3

export const requestLocationsV3 = (args: {}): RequestLocationListV3Action => ({
  args,
  type: REQUEST_LOCATION_LIST_V3
})

export const getLocations: ActionCreator<GetLocationListV3> = (data, meta, query) => ({
  data,
  meta,
  query,
  type: GET_LOCATION_LIST_V3
})

export const requestLocationsAvailableV3 = (args: {}): RequestLocationListAvailableV3Action => ({
  args,
  type: REQUEST_LOCATION_LIST_AVAILABLE_V3
})

export const getLocationsAvailable: ActionCreator<GetLocationListAvailableV3> = data => ({
  data,
  type: GET_LOCATION_LIST_AVAILABLE_V3
})
