import { CHANGE_ICON_MODAL, UiAction, RECORD_SITE_STATE, SiteState } from 'actions/uiState'

export type UiState = {
  modal: ModalType,
  siteState: SiteState
}

export default function UIState(
  state: UiState = {
    modal: '',
    siteState: {}
  },
  action: UiAction
) {
  switch (action.type) {
    case CHANGE_ICON_MODAL:
      return {
        ...state,
        modal: action.modal
      }
    case RECORD_SITE_STATE:
      return {
        ...state,
        siteState: { ...state.siteState, ...action.siteState }
      }
    default:
      return state
  }
}
