import { put, call } from 'redux-saga/effects'
import { REQUEST_CONFIG, getConfig } from 'actions/V3/config'
import { watchRequestNoCancel } from 'sagas/helper'
import { apiV3 } from 'utils/api'
import { changeColor } from 'utils/helper'

function* request() {
  const { host, hostname } = window.location
  const base = host || hostname

  const { data } = yield call(apiV3, '/configs', 'get', { host: base })

  if (!window.location.pathname.includes('reportprint') && data) {
    changeColor(data.theme_color)
    document.title = data.name
  } else {
    window.PRIMARY_COLOR = '#006b94'
    // window.KIOSK_COLOR = c.convert(`color(#006b94 alpha(-10%))`);
  }
  yield put(getConfig(data))
  return data
}

export default watchRequestNoCancel(REQUEST_CONFIG, request)
