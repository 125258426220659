import { put, call } from 'redux-saga/effects'
import { REQUEST_LOCATION_SELECT_V3, getLocationSelect } from 'actions/V3/locationSelect'
import { watchRequestNoCancel } from 'sagas/helper'

import { apiV3 } from 'utils/api'

type Args = {
  size?: number,
  page?: number,
  workspace_id: number
}

function* request(args: Args) {
  const { data } = yield call(apiV3, '/locations/select', 'get', {
    ...args
  })

  yield put(getLocationSelect(data))
  return data
}

export default watchRequestNoCancel(REQUEST_LOCATION_SELECT_V3, request)
