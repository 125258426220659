import { LocationBase } from 'actions/V3/location';
import Button from 'components/Custom/Button';
import Select, { Option } from 'components/Custom/Select';
import { t } from 'i18next';
import * as React from 'react';
import { connect, DispatchProp } from 'react-redux';

import {
  NotificationLimit,
  requestNotificationConfigV3,
  RequestNotificationConfigV3Action
} from 'actions/V3/notificationConfig';
import { Divider, Input, Radio, Tooltip } from 'antd';
import classNames from 'classnames';
import { groupBy } from 'lodash';
import { apiV3 } from 'utils/api';
import { indicatorShortName } from 'utils/constant';
type Props = Readonly<{
  disabled?: boolean;
  locationID: number;
  locationBase: LocationBase;
  close: () => void;
}>;

type StateProps = {
  collectionState: NotificationLimit[];
  averageState: NotificationLimit[];
};

type Limit = NotificationLimit & { _status?: 'create' | 'update' | 'delete' };

type P = Props & DispatchProp<RequestNotificationConfigV3Action> & StateProps;

const SelectWrapper: React.SFC<{ invaild?: boolean }> = React.memo(
  ({ invaild, children }) => {
    if (invaild) {
      return (
        <Tooltip title={t('locationOverview.this indicator not exist now')}>
          <div className='location_detail-limit-indicator ant-form-item-control has-error'>
            {children}
          </div>
        </Tooltip>
      );
    }

    return <div className='location_detail-limit-indicator'>{children}</div>;
  }
);

const ReadingLimit: React.SFC<P> = React.memo(props => {
  const {
    locationBase,
    locationID,
    dispatch,
    averageState,
    collectionState,
    close,
    disabled
  } = props;
  const [average, setAverage] = React.useState<Limit[]>([]);
  const [collection, setCollection] = React.useState<Limit[]>([]);


  const newLimit = (type: 'location' | 'collection') => {
    const data = {
      category: type,
      data_channel_name: '',
      data_channel: '',
      id: -1,
      location_id: locationID,
      type: 'max' as 'max',
      value: 0,
      _status: 'create' as 'create',
      workspace_id: -1,
      workspace_name: ''
    };
    if (type === 'location') {
      return setAverage(prevState => prevState.concat([data]));
    }
    return setCollection(prevState => prevState.concat([data]));
  };

  const updateLimit = (
    type: 'location' | 'collection',
    index: number,
    key: keyof Limit,
    value
  ) => {
    const clone = (type === 'location' ? average : collection).slice();
    const item: any = clone[index];
    item[key] = value;
    if (type === 'collection' && key === 'collection_id') {
      item.data_channel = null;
    }
    if (!item._status) {
      item._status = 'update';
    }
    const setData = type === 'location' ? setAverage : setCollection;
    setData(clone);
  };

  const deleteLimit = (type: 'location' | 'collection', index: number) => {
    const clone = (type === 'location' ? average : collection).slice();
    const item = clone[index];
    if (!item._status || item._status === 'update') {
      item._status = 'delete';
    }

    if (item._status === 'create') {
      clone.splice(index, 1);
    }

    const setData = type === 'location' ? setAverage : setCollection;
    setData(clone);
  };

  const saveAndUpdate = async () => {
    const tasks = groupBy(
      average.concat(collection).filter(v => v._status),
      '_status'
    );
    const createTasks = (tasks.create || []).map(
      ({ collection_id, location_id, category, data_channel, type, value }) =>
        apiV3('/notification_configs', 'post', {
          location_id,
          category,
          collection_id: collection_id ? collection_id : null,
          factors: [
            {
              channel: data_channel,
              type,
              value
            }
          ]
        })
    );

    const updateTasks = (tasks.update || []).map(
      ({ id, collection_id, data_channel, type, value }) =>
        apiV3(`/notification_configs/${id}`, 'patch', {
          notification_config: {
            data_channel,
            collection_id,
            type,
            value
          }
        })
    );

    const deleteTasks = (tasks.delete || []).map(({ id }) =>
      apiV3(`/notification_configs/${id}`, 'delete')
    );

    await Promise.all([...createTasks, ...updateTasks, ...deleteTasks]);
    close();
  };

  const collectionIndicator = id => {
    const c = locationBase.collections.find(v => v.id === id);
    return c
      ? c.readings.map(reading => ({
        value: reading.data_channel,
        label: reading.name
      }))
      : [];
  };

  const invaildNumber = v => (!v && v !== 0) || isNaN(+v);

  React.useEffect(() => {

    dispatch(
      requestNotificationConfigV3({
        category: 'collection',
        show: false,
        location_id: locationID,
        size: 1000
      })
    );
    dispatch(
      requestNotificationConfigV3({
        category: 'location',
        show: false,
        location_id: locationID,
        size: 1000
      })
    );

  }, [dispatch, locationID]);

  React.useEffect(() => {
    setAverage(averageState);
    setCollection(collectionState);
  }, [averageState, collectionState]);

  const buttonDisabled = React.useMemo(() => {
    const averageInvaild = average.some(
      v => invaildNumber(v.value) || !v.data_channel
    );

    const collectionInvaild = collection.some(
      v => !v.data_channel || invaildNumber(v.value) || !v.collection_id
    );
    return averageInvaild || collectionInvaild;
  }, [average, collection]);

  const renderAverageLimits = () => {
    return (
      <>
        <h3 className='accent_text'>{t('Average')}</h3>
        <div className='location_detail-limit-row'>
          <div className='location_detail-limit-type small_text grey_text'>
            {t('Type')}
          </div>
          <div className='location_detail-limit-indicator small_text grey_text'>
            {t('locationOverview.Indicator')}
          </div>
          <div className='location_detail-limit-limit small_text grey_text'>
            {t('locationOverview.Limit')}
          </div>
          <div className='location_detail-limit-value small_text grey_text'>
            {t('locationOverview.Value')}
          </div>
        </div>
        {(disabled ? [] : average).map((v, i) => {
          const indicatorOption = locationBase.average!.readings.map(
            reading => ({
              label: reading.name,
              value: reading.data_channel
            })
          );

          const selectInvaid =
            !!v.data_channel &&
            !indicatorOption.find(opt => opt.value === v.data_channel);
          return (
            v._status !== 'delete' && (
              <div className='location_detail-limit-row' key={`new-limit-${i}`}>
                <div className='location_detail-limit-type'>
                  {t('Location Average')}
                </div>
                <SelectWrapper invaild={selectInvaid}>
                  <Select
                    block={true}
                    size='small'
                    dropdownClassName='location_detail-limit-indicator_dropdown'
                    underline={true}
                    showArrow={true}
                    value={
                      selectInvaid
                        ? indicatorShortName[v.data_channel] || v.data_channel
                        : v.data_channel
                    }
                    onSelect={name =>
                      updateLimit('location', i, 'data_channel', name)
                    }
                  >
                    {indicatorOption.map((option, index) => (
                      <Option
                        key={`limit-${i}-indicator-${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </SelectWrapper>
                <div className='location_detail-limit-limit'>
                  <Radio.Group
                    value={v.type}
                    size='small'
                    buttonStyle='solid'
                    onChange={e =>
                      updateLimit('location', i, 'type', e.target.value)
                    }
                  >
                    <Radio.Button value='max'>{t('MAX')}</Radio.Button>
                    <Radio.Button value='min'>{t('MIN')}</Radio.Button>
                  </Radio.Group>
                </div>
                <div
                  className={classNames(
                    'location_detail-limit-value',
                    'ant-form-item-control w-70',
                    {
                      'has-error': invaildNumber(v.value)
                    }
                  )}
                >
                  <Input
                    size='small'
                    value={v.value}
                    onChange={e =>
                      updateLimit('location', i, 'value', e.target.value)
                    }
                  />
                </div>
                <div className='location_detail-limit-delete'>
                  <a
                    onClick={() => deleteLimit('location', i)}
                    className='material-icons'
                  >
                    delete
                  </a>
                </div>
              </div>
            )
          );
        })}

        {!disabled && (
          <div
            className='accent_text collection-add_text location_detail-limit-add_text'
            onClick={() => newLimit('location')}
          >
            <i className='material-icons'>add</i>
            <span>{t('locationOverview.Add Reading Limit')}</span>
          </div>
        )}
      </>
    );
  };

  const renderCollectionLimits = () => {
    return (
      <>
        <h3 className='accent_text'>{t('Collection')}</h3>
        <div className='location_detail-limit-row'>
          <div className='location_detail-limit-type small_text grey_text'>
            {t('locationOverview.type')}
          </div>
          <div className='location_detail-limit-indicator small_text grey_text'>
            {t('locationOverview.Indicator')}
          </div>
          <div className='location_detail-limit-limit small_text grey_text'>
            {t('locationOverview.Limit')}
          </div>
          <div className='location_detail-limit-value small_text grey_text'>
            {t('locationOverview.Value')}
          </div>
        </div>
        {(disabled ? [] : collection).map((v, i) => {
          const indicatorOption = collectionIndicator(v.collection_id);

          const selectInvaid =
            !!v.data_channel &&
            !collectionIndicator(v.collection_id).find(
              opt => opt.value === v.data_channel
            );
          return (
            v._status !== 'delete' && (
              <div className='location_detail-limit-row' key={`new-limit-${i}`}>
                <div className='location_detail-limit-type'>
                  <Select
                    dropdownTextCenter={true}
                    size='small'
                    dropdownClassName='location_detail-limit-type_dropdown'
                    block={true}
                    underline={true}
                    showArrow={true}
                    showSearch={true}
                    value={v.collection_id}
                    onSelect={id =>
                      updateLimit('collection', i, 'collection_id', id)
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) =>
                      (option.props.children as string)
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                  >
                    {locationBase.collections.map((c, index) => (
                      <Option
                        key={`limit-${i}-collection-${index}`}
                        value={c.id}
                      >
                        {c.name}
                      </Option>
                    ))}
                  </Select>
                </div>
                <SelectWrapper invaild={selectInvaid}>
                  <Select
                    block={true}
                    size='small'
                    dropdownClassName='location_detail-limit-indicator_dropdown'
                    underline={true}
                    showArrow={true}
                    value={
                      selectInvaid
                        ? indicatorShortName[v.data_channel] || v.data_channel
                        : v.data_channel
                    }
                    onSelect={name =>
                      updateLimit('collection', i, 'data_channel', name)
                    }
                  >
                    {indicatorOption.map((option, index) => (
                      <Option
                        key={`limit-${i}-indicator-${index}`}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    ))}
                  </Select>
                </SelectWrapper>
                <div className='location_detail-limit-limit'>
                  <Radio.Group
                    value={v.type}
                    size='small'
                    buttonStyle='solid'
                    onChange={e =>
                      updateLimit('collection', i, 'type', e.target.value)
                    }
                  >
                    <Radio.Button value='max'>{t('MAX')}</Radio.Button>
                    <Radio.Button value='min'>{t('MIN')}</Radio.Button>
                  </Radio.Group>
                </div>
                <div
                  className={classNames(
                    'location_detail-limit-value',
                    'ant-form-item-control',
                    {
                      'has-error': invaildNumber(v.value)
                    }
                  )}
                >
                  <Input
                    size='small'
                    value={v.value}
                    onChange={e =>
                      updateLimit('collection', i, 'value', e.target.value)
                    }
                  />
                </div>
                <div className='location_detail-limit-delete'>
                  <a
                    onClick={() => deleteLimit('collection', i)}
                    className='material-icons'
                  >
                    delete
                  </a>
                </div>
              </div>
            )
          );
        })}

        {!disabled && (
          <div
            className='accent_text collection-add_text location_detail-limit-add_text'
            onClick={() => newLimit('collection')}
          >
            <i className='material-icons'>add</i>
            <span>{t('locationOverview.Add Reading Limit')}</span>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <div className='register-body'>
        <p className='register-message small_text'>
          {t('locationOverview.Subscribe to reading changes')}
        </p>
        {renderAverageLimits()}
        <Divider />
        {renderCollectionLimits()}
        <br />
        <br />
        <div className='location_detail-setting-button'>
          <Button size='large' customType='light' onClick={close}>
            {t('Cancel')}
          </Button>
          &nbsp; &nbsp; &nbsp;
          <Button
            size='large'
            onClick={saveAndUpdate}
            customType='dark'
            disabled={disabled || buttonDisabled}
          >
            {t('Save')}
          </Button>
        </div>
      </div>
    </>
  );
});

const mapStateToProps: MapState<StateProps> = ({
  V3: {
    notificationConfig: { location, collection }
  }
}) => ({
  averageState: location.data,
  collectionState: collection.data
});

export default connect(mapStateToProps)(ReadingLimit);
