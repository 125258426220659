import { Action, ActionCreator } from 'redux'
export const REQUEST_ACTIVE_LOG_V3 = 'REQUEST_ACTIVE_LOG_V3'
export const GET_ACTIVE_LOG_V3 = 'GET_ACTIVE_LOG_V3'

export type LogItem = {
  content: string,
  created_at: string,
  event: string,
  id: number,
  item_id: number,
  item_type: string,
  user_id: number,
  user_name: string,
  item_name?: string,
  details?: any
}

export interface RequestLogAction extends Action<typeof REQUEST_ACTIVE_LOG_V3> {
  args: any;
}

export interface GetLogAction extends Action<typeof GET_ACTIVE_LOG_V3> {
  data: LogItem[];
  meta: Meta;
  query: {};
}

export const requestActiveLog = (args: {
  item_type: 'location' | 'data_source' | 'collection' | 'indicator' | 'workspace' | 'all',
  location_id?: number,
  data_source_id?: number,
  collection_id?: number,
  indicator_id?: number,
  workspace_id?: number | string,
  user_id?: number,
  name?: string,
  page?: number,
  size?: number,
  show?: boolean
}): RequestLogAction => ({
  type: REQUEST_ACTIVE_LOG_V3,
  args
})

export const getActiveLog: ActionCreator<GetLogAction> = (data, meta, query) => ({
  type: GET_ACTIVE_LOG_V3,
  data,
  meta,
  query
})
