import ChartDataLabels from 'chartjs-plugin-datalabels';
import { t } from 'i18next';
import { isEqual } from 'lodash';
import * as React from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import './Chart.roundedBarCharts';

interface Props {
  indicatorData: {
    label: string;
    color: string;
    data: number[];
  }[];
  labelText: string;
  suggestedMin?: number;
  suggestedMax?: number;
  labels?: string[];
}

class BarChart extends React.Component<Props> {
  static defaultProps = {
    labels: [
      t('Report.Current week'),
      t('Report.Previous week'),
      [t('Report.Previous year'), '(' + t('Report.same period') + ')']
    ]
  };
  shouldComponentUpdate(nextProps: any) {
    return !isEqual(this.props, nextProps);
  }

  chartData = (canvas) => {
    const { indicatorData, labels } = this.props;
    const datasets = indicatorData.map((item) => {
      return {
        label: item.label,
        backgroundColor: item.color,
        data: item.data
      };
    });
    return {
      labels,
      datasets
    };
  };

  chartOptions = () => {


    return {
      plugins: {
        bill: false,
        addArrowLabel: {
          label: this.props.labelText,
          direction: 'RIGHT'
        },
        doughnut: false,
        datalabels: {
          color: 'white',
          anchor: 'end',
          align: 'start',
          font: {
            size: '14'
          }
        }
      },
      animation: false,
      events: [],
      cornerRadius: 2,
      legend: {
        display: false
      },
      layout: {
        padding: {
          left: 20,
          right: 40,
          top: 20,
          bottom: 20
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontSize: 14,
              padding: 20,
              fontFamily: 'Nunito',
              fontColor: '#999'
            },
            gridLines: {
              display: false,
              color: '#f1f1f1'
            },
            categoryPercentage: 0.6,
            barPercentage: 0.7,
            maxBarThickness: 26
          }
        ],
        xAxes: [
          {
            // display: false,
            position: 'top',
            ticks: {
              labelOffset: 0,
              fontSize: 14,
              min: this.props.suggestedMin || 0,
              fontFamily: 'Nunito',
              // autoSkip: false,
              padding: 10,
              fontColor: '#999',
              // suggestedMin: 50,
              suggestedMax: this.props.suggestedMax || null
            },
            gridLines: {
              color: '#f1f1f1',
              drawTicks: false
              // display: false
            }
          }
        ]
      }
    }
  };

  render() {
    return (
      <HorizontalBar
        height={120}
        plugins={ChartDataLabels as any}
        data={this.chartData}
        options={this.chartOptions()}
      />
    );
  }
}

export default BarChart;
