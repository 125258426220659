export enum ReportColors {
  Green = '#029588',
  Blue = '#1564C0',
  Orange = '#D48265',
  Purple = '#CE93D8',
  Yellow = '#FFA000'
}
export enum ReportStatus {
  Pending = 0,
  GeneratingReport,
  DoneforReport,
  ReportFail,
  GeneratingPDF,
  DoneforPDF,
  PDFFail
}
export enum NewKioskModalSteps {
  TEMPLATE_SELECTION = 1,
  KIOSK_NAMING = 2,
  SUCCESS_CONFIRMATION = 3
}
export enum Timer {
  SECOND = 1000,
  MINUTE = 60,
  FIVE_MINUTES = 5,
  HOUR = 60
}
export enum AlertPanelChoices {
  /** when a user sets up an offline alert template */
  /** the UI is different */
  OFFLINE = 1,
  /** when a user selects the online alert template */
  ONLINE = 2
}
export enum SettingModalContentSize {
  BASIC_SIZE = 100
}

export enum KioskCollectionName {
  LOCATION_AVERAGE = '-1'
}