import * as React from 'react';
import { apiV3 } from 'utils/api';
import {
    Button,
    Input,
    message,
    Modal,
    notification,
    Select,
    Spin,
    Tabs
} from 'antd';
import {
    CollectionItem,
    requestCollectionForSelector,
    RequestCollectionForSelectorAction
} from 'actions/V3/collections';
import { connect, DispatchProp } from 'react-redux';
import {
    DatasourceObj,
    RequestDatasourcesAction
} from 'actions/V3/datasources';
import { debounce, pick } from 'lodash';
import { promiseDispatch } from 'utils';
import { requestDatasources } from 'actions/V3/datasources';
import {
    requestDictionary,
    RequestDictionaryAction
} from 'actions/V2/dictionary';
import { t } from 'i18next';

const TabPane = Tabs.TabPane;
const Option = Select.Option;

type Props = Readonly<{
  workspaceID: number;
  locationID: number;
  name: string;
  createable: boolean;
  filterCollectionIds: number[];
  close: () => void;
  refresh: () => void;
}>;

type StateProps = {
  meta?: Meta;
  query?: {};
  indicators: any;
  datasourceList: DatasourceObj[];
  collections: CollectionItem[];
};

type State = Readonly<{
  checked: string[];
  activedTab: string;
  showNotFound?: boolean;
  spinning: boolean;
  datasourceSelectedList: {
    id: number;
    identifier: string;
    assigned_data_channels: string[];
    channels?: string[];
  }[];
  collectionName?: string;
}>;

type P = Props &
  DispatchProp<
    | RequestCollectionForSelectorAction
    | RequestDatasourcesAction
    | RequestDictionaryAction
  > &
    StateProps;
const halfSecond = 500

class AddCollection extends React.PureComponent<P, State> {
    constructor(p: P) {
        super(p);
        this.state = {
            checked: [],
            datasourceSelectedList: [],
            activedTab: p.createable ? '1' : '2',
            spinning: false
        };
    }

  search = debounce(v => this.onSelectorSearch(v), halfSecond);

  componentDidMount() {
      const { dispatch, workspaceID } = this.props;
      this.fetchCollections();
      dispatch(
          requestDatasources({
              workspace_id: workspaceID,
              show: false,
              channel: true
          })
      );
      dispatch(requestDictionary({ name: 'indicators' }));
  }

  fetchCollections = (query = {}) => {
      const { workspaceID, dispatch } = this.props;
      dispatch(
          requestCollectionForSelector({
              workspace_id: workspaceID,
              show: false
          })
      );
  };

  addCollection = async () => {
      const {
          checked,
          activedTab,
          datasourceSelectedList,
          collectionName
      } = this.state;
      const { locationID, workspaceID, refresh, close } = this.props;
      if (activedTab === '2') {
          if (!checked.length) {
              return;
          }
          await apiV3(`/locations/${locationID}/collections`, 'post', {
              collection_id: checked
          });
      } else {
          if (!datasourceSelectedList.length) {
              return;
          }

          await apiV3('/collections', 'post', {
              location_id: locationID,
              workspace_id: workspaceID,
              name: collectionName,
              model: 'v2',
              data_sources: datasourceSelectedList.map(e => {
                  return pick(e, ['id', 'channels']);
              })
          });
      }
      refresh();
      close();
      notification.success({
          message: t('collection.Add Success'),
          // duration: 0,
          className: 'notification-icon-primary'
      });
  };

  handleCollectionChange = e => {
      this.setState({
          checked: e
      });
  };

  onSelectorSearch = async v => {
      const { dispatch, workspaceID } = this.props;
      if (!v) {
          return this.setState({
              showNotFound: false
          });
      }
      this.setState({
          spinning: true
      });
      await promiseDispatch({
          dispatch,
          actionCreator: requestDatasources,
          payload: {
              workspace_id: workspaceID,
              identifier: v,
              channel: true,
              show: false
          }
      });
      this.setState({
          showNotFound: true,
          spinning: false
      });
  };

  updateIndicators = (id, channels) => {
      const { datasourceSelectedList } = this.state;
      this.setState({
          datasourceSelectedList: datasourceSelectedList.map(v =>
              v.id === id ? { ...v, channels } : v
          )
      });
  };

  onDatasourceChanged = (currentObj, id) => {
      const { datasourceSelectedList } = this.state;
      const { datasourceList } = this.props;
      const existObj = datasourceSelectedList.find(e => e.id === id);
      if (existObj) {
          message.warning(t('datasource.Exist'));
          return;
      }
      const selectedDatasource = datasourceList.find(e => e.id === id);
      if (selectedDatasource) {
          const picked = {
              ...pick(selectedDatasource, [
                  'id',
                  'identifier',
                  'assigned_data_channels'
              ]),
              channels: (selectedDatasource.channels || []).map(c => c.channel)
          };

          // // 编辑已选择的
          if (currentObj && currentObj.id !== id) {
              return this.setState({
                  datasourceSelectedList: datasourceSelectedList.map(e =>
                      e.id === currentObj.id ? picked : e
                  )
              });
          }
          return this.setState({
              datasourceSelectedList: datasourceSelectedList.concat([picked])
          });
      }
  };

  handleRemoveDatasource = obj => {
      const { datasourceSelectedList } = this.state;
      Modal.confirm({
          title: t('datasource.Remove Data Source?'),
          onOk: () => {
              this.setState({
                  datasourceSelectedList: datasourceSelectedList.filter(e => {
                      return e.id !== obj.id;
                  })
              });
          }
      });
  };

  renderDatasourcePanel = obj => {
      const { datasourceList, indicators, workspaceID } = this.props;
      const { showNotFound } = this.state;
      return (
          <div className='datasource-panel' key={obj && obj.id}>
              {obj && (
                  <div className='header-action'>
                      <i
                          onClick={() => this.handleRemoveDatasource(obj)}
                          className='iconfont icon-yichu'
                          title={t('Remove')}
                      />
                  </div>
              )}
              <div className='form-group'>
                  <div className='label'>
            UUID
                      <a
                          href={`/workspaces/${workspaceID}/datasources`}
                          target='blank'
                          className='link'
                      >
                          {t('check datasources list')}
                      </a>
                  </div>

                  <Select
                      showSearch={true}
                      placeholder={t('datatable.Select datasource')}
                      onChange={v => this.onDatasourceChanged(obj, v)}
                      notFoundContent={showNotFound ? t('Not Found') : ''}
                      onSearch={this.search}
                      dropdownMatchSelectWidth={true}
                      filterOption={(input, option) =>
                          (option.props.children as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                      }
                      optionFilterProp='children'
                      value={obj ? obj.identifier : undefined}
                  >
                      {datasourceList
                          .filter(e => e.channels && e.channels.length)
                          .map(ds => (
                              <Option key={ds.id} value={ds.id}>
                                  {ds.identifier}
                              </Option>
                          ))}
                  </Select>
              </div>
              {obj && (
                  <div className='form-group'>
                      <div className='label'>{t('Indicator')}</div>
                      <Select
                          mode='multiple'
                          style={{ width: '100%' }}
                          placeholder={t('Click to select indicators')}
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                              (option.props.children as string)
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                          }
                          onChange={v => this.updateIndicators(obj.id, v)}
                          defaultValue={obj.channels}
                      >
                          {obj.channels.map((dc, i) => (
                              <Option key={i} value={dc}>
                                  {indicators[dc]}
                              </Option>
                          ))}
                      </Select>
                  </div>
              )}
          </div>
      );
  };

  render() {
      const { close, collections, filterCollectionIds, createable } = this.props;
      const {
          activedTab,
          datasourceSelectedList,
          checked,
          collectionName,
          spinning
      } = this.state;
      const tabOne = 1
      const tabTwo = 2
      let submitDisable = true;
      if (+activedTab === tabOne) {
          submitDisable = !datasourceSelectedList.length || !collectionName;
      } else if (+activedTab === tabTwo) {
          submitDisable = !checked.length;
      }
      const collectionShow = collections.filter(
          e => !filterCollectionIds.includes(e.id)
      );
      return (
          <div className='colletion_modal-wrap'>
              <h3 className='register-title'>
                  {t('locationOverview.AddCollection')}
              </h3>
              <Tabs
                  // animated={false}
                  activeKey={activedTab}
                  onChange={e => this.setState({ activedTab: e })}
              >
                  {createable && (
                      <TabPane tab={t('collection.Add New Collection')} key='1'>
                          <Spin spinning={spinning}>
                              <div className='form-group'>
                                  <div className='form-label'>{t('Collection Name')}</div>
                                  <Input
                                      onChange={e =>
                                          this.setState({ collectionName: e.target.value })
                                      }
                                  />
                              </div>
                              <div className='form-label'>{t('Data Source')}</div>
                              <div
                                  className='grey_text small_text'
                                  style={{ marginTop: -5, marginBottom: 10 }}
                              >
                                  {t(
                                      'collection.Data sources that have no data will not show up in the search'
                                  )}
                              </div>
                              {datasourceSelectedList.map(obj =>
                                  this.renderDatasourcePanel(obj)
                              )}
                              {this.renderDatasourcePanel(null)}
                          </Spin>
                      </TabPane>
                  )}
                  <TabPane tab={t('collection.Add Existing Collections')} key='2'>
                      {collectionShow.length ? (
                          <div>
                              <div className='tip'>
                                  {t('collection.Add Existing Collections tip')}
                              </div>
                              <Select
                                  mode='multiple'
                                  style={{ width: '100%' }}
                                  placeholder={t('Click to select Collections')}
                                  optionFilterProp='children'
                                  filterOption={(input, option) =>
                                      (option.props.children as string)
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                  }
                                  onChange={this.handleCollectionChange}
                              >
                                  {collectionShow.map((col, i) => (
                                      <Option key={i} value={String(col.id)}>
                                          {col.name}
                                      </Option>
                                  ))}
                              </Select>
                          </div>
                      ) : (
                          <p className='register-no_result'>
                              {t('No resultes found please')}
                              <a
                                  className='accent_text'
                                  onClick={() => this.setState({ activedTab: '1' })}
                              >
                                  {t('collection.Create Collection')}
                              </a>
                              {t('No resultes first')}
                          </p>
                      )}
                  </TabPane>
              </Tabs>
              <div className='colletion_modal-footer'>
                  <Button type='primary' ghost={true} onClick={close}>
                      {t('Cancel')}
                  </Button>
                  <Button
                      disabled={submitDisable}
                      type='primary'
                      onClick={this.addCollection}
                  >
                      {t('Confirm')}
                  </Button>
              </div>
          </div>
      );
  }
}

const mapState: MapState<StateProps> = ({
    V3: { collections, datasources },
    V2: {
        dictionary: { indicators }
    }
}) => ({
    collections: collections.list,
    datasourceList: datasources.data,
    indicators
});

export default connect(mapState)(AddCollection);
