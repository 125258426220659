import { Action, ActionCreator } from 'redux'

export const REQUEST_USER_PERMISSION_V3 = 'REQUEST_USER_PERMISSION_V3'
export const REFRESH_USER_PERMISSION_V3 = 'REFRESH_USER_PERMISSION_V3'
export const GET_USER_PERMISSION_V3 = 'GET_USER_PERMISSION_V3'

export interface GetUserPermissionAction extends Action<typeof GET_USER_PERMISSION_V3> {
  data: GuardRule[];
  meta: Meta;
  query: any;
}

export interface RequestUserPermissionAction extends Action<typeof REQUEST_USER_PERMISSION_V3> {
  args: any;
}

export interface RefreshPermission extends Action<typeof REFRESH_USER_PERMISSION_V3> {
  args: any;
}

export const requestUserPermission: ActionCreator<RequestUserPermissionAction> = args => ({
  type: REQUEST_USER_PERMISSION_V3,
  args
})

export const refreshPermission: ActionCreator<RefreshPermission> = args => ({
  type: REFRESH_USER_PERMISSION_V3,
  args
})

export const getUserPermission: ActionCreator<GetUserPermissionAction> = (data, meta, query) => ({
  type: GET_USER_PERMISSION_V3,
  data,
  meta,
  query
})
