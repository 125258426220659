import activies, { ActivitiesState } from 'reducers/V2/activities'
import certifications, { CertificationsState } from 'reducers/V2/certifications'
import collections, { CollectionsState } from 'reducers/V2/collections'
import dictionary, { DictionaryState } from 'reducers/V2/dictionary'
import downloads, { DownloadsState } from 'reducers/V2/downloads'
import graph, { GraphState } from 'reducers/V2/graph'
import locationInfo, { LocationState } from 'reducers/V2/location'
// import notificationConfig, {
//   NotificationConfigState
// } frosrc/reducers/V2/[deprecated]notificationConfigfig';
import notifications, { NotificationsState } from 'reducers/V2/notification'
// import report, { ReportState } from "reducers/V2/[Deprecated]report";
// import reportPeriods, { ReportPeriodsState } from "reducers/V2/[Deprecated]reportPeriods";
import userShip, { UserShipState } from 'reducers/V2/userShip'
import userSign, { UserSignState } from 'reducers/V2/userSign'
import { combineReducers } from 'redux'
// import { locationList, LocationListState } from 'reducers/V2/[deprecated]locationList';
import accounts, { AccountsState } from 'reducers/V2/accounts'
import announcement, { AnnouncementState } from 'reducers/V2/announcement'

export interface StoreStateV2 {
  // report: ReportState;
  activies: ActivitiesState;
  certifications: CertificationsState;
  locationInfo: LocationState;
  // reportPeriods: ReportPeriodsState;
  graph: GraphState;
  collections: CollectionsState;
  userShip: UserShipState;
  notifications: NotificationsState;
  // notificationConfig: NotificationConfigState;
  userSign: UserSignState;
  // locationList: LocationListState;
  dictionary: DictionaryState;
  downloads: DownloadsState;
  accounts: AccountsState;
  announcement: AnnouncementState;
}

export default combineReducers<StoreStateV2>({
  // report,
  activies,
  graph,
  certifications,
  locationInfo,
  // reportPeriods,
  collections,
  downloads,
  notifications,
  // notificationConfig,
  userSign,
  // locationList,
  dictionary,
  accounts,
  announcement,
  userShip
})
