import { Action, ActionCreator } from 'redux'
import { ReportStatus } from 'utils/enums'
export const REQUEST_REPORT_LIST_V3 = 'REQUEST_REPORT_LIST_V3'
export const GET_REPORT_LIST_V3 = 'GET_REPORT_LIST_V3'

export const REQUEST_REPORT_TEMPLATE_V3 = 'REQUEST_REPORT_TEMPLATE_V3'
export const GET_REPORT_TEMPLATE_V3 = 'GET_REPORT_TEMPLATE_V3'

export const REQUEST_REPORT_DETAIL_V3 = 'REQUEST_REPORT_DETAIL_V3'
export const GET_REPORT_DETAIL_V3 = 'GET_REPORT_DETAIL_V3'

export type Report = {
  code: string,
  begin_date: string,
  created_at: string,
  end_date: string,
  id: number,
  location_id: number,
  location_name: string,
  report_status: ReportStatus,
  updated_at: string
}

export type Module = {
  category: 'indicator' | 'comparison',
  isCreated?: boolean,
  name: string,
  indicators: string[],
  features: string[],
  configs: {
    health_standard: string,
    enable_working_hours: any
  }
}

export type Template = {
  created_at: string,
  id: number,
  name: string,
  updated_at: string,
  modules: Module[]
}

export type ReportDetail = {
  begin_date: string,
  compared_begin_date: string,
  enable_outdoor_location: boolean,
  end_date: string,
  generae_pdf: any,
  id: number,
  location_id: number,
  location_name: string,
  report_status: any,
  report_template_id: string,
  times: string[],
  working_hours: {
    begin_time: string,
    end_time: string,
    day_in_week: number
  }[],
  modules: {
    category: 'indicator' | 'comparison',
    indicator: string,
    indicator_name: string,
    name: string,
    precision: number,
    type: string,
    unit: string,
    configs: {
      enable_working_hours: boolean,
      health_standard: string
    },
    features: string[],
    historical?: {
      begin_date: string,
      end_date: string,
      indoor: number,
      outdoor: number
    }[],
    historical_percent_reduction?: {
      begin_date: string,
      end_date: string,
      value: number
    }[],
    overall?: {
      indoor: number[],
      outdoor: number[]
    },
    overall_percent_reduction?: {
      readings: number[]
    },
    compared_overall?: {
      indicator: string,
      indicator_name: string,
      precision: number,
      readings: number[],
      type: string,
      unit: string
    }[]
  }[]
}

export interface RequestReportListAction extends Action<typeof REQUEST_REPORT_LIST_V3> {
  args: any;
}

export interface RequestReportTemplateAction extends Action<typeof REQUEST_REPORT_TEMPLATE_V3> {
  args: any;
}

export interface RequestReportDetailAction extends Action<typeof REQUEST_REPORT_DETAIL_V3> {
  args: any;
}

interface GetReportListAction extends Action<typeof GET_REPORT_LIST_V3> {
  data: Report[];
  meta: Meta;
  query: any;
}

interface GetReportTemplateAction extends Action<typeof GET_REPORT_TEMPLATE_V3> {
  data: Template[];
  meta: Meta;
  query: any;
}

interface GetReportDetailAction extends Action<typeof GET_REPORT_DETAIL_V3> {
  data: ReportDetail;
}

export type ReportAction = GetReportListAction | GetReportTemplateAction | GetReportDetailAction

export const requestReportList: ActionCreator<RequestReportListAction> = args => ({
  type: REQUEST_REPORT_LIST_V3,
  args
})

export const getReportList: ActionCreator<GetReportListAction> = (data, meta, query) => ({
  type: GET_REPORT_LIST_V3,
  data,
  meta,
  query
})

export const requestReportTemplate: ActionCreator<RequestReportTemplateAction> = args => ({
  type: REQUEST_REPORT_TEMPLATE_V3,
  args
})

export const getReportTemplate: ActionCreator<GetReportTemplateAction> = (data, meta, query) => ({
  type: GET_REPORT_TEMPLATE_V3,
  data,
  meta,
  query
})

export const requestReportDetail: ActionCreator<RequestReportDetailAction> = args => ({
  type: REQUEST_REPORT_DETAIL_V3,
  args
})

export const getReportDetail: ActionCreator<GetReportDetailAction> = data => ({
  type: GET_REPORT_DETAIL_V3,
  data
})
