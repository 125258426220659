import { t } from 'i18next'
import { KioskCollection, Reading } from '../../actions/V3/kiosks'
import iconCO2 from '../../images/ellipseIcons/icon_CO2.svg'
import iconHumidity from '../../images/ellipseIcons/icon_Humidity.svg'
import iconPM25 from '../../images/ellipseIcons/icon_pm2.5.svg'
import iconTemperature from '../../images/ellipseIcons/icon_Temperature.svg'
import iconTVOC from '../../images/ellipseIcons/icon_TVOC.svg'
import { co2Scale, humidityScale, pm2p5Scale, temperatureScale, tvocScale } from './progressBarScales'
import { Current } from './type'

export default function ProgressBar(key: string, current: Current, defaultedData: KioskCollection) {
  const commonColors = {
    black: 'black',
    darkDreen: '#0cb314',
    green: '#37B30C',
    orangeYellow: '#fdc202',
    orangeRed: '#fd9902',
    redOrange: '#fd4d02',
    red: '#FDA802'
  }
  let reading: Reading | undefined

  if (current && current.readings) {
    reading = current.readings.find(envValue => envValue.channel === key)
  } else if (defaultedData && defaultedData.readings) {
    reading = defaultedData.readings.find(envValue => envValue.channel === key)
  } else return

  if (!reading) return
  const translate = (indicator: string) => {
    return t(`level.${indicator}`)
  }
  const pollutionLevels = {
    EXCELLENT: translate('Excellent'),
    GOOD: translate('Good'),
    MODERATE: translate('Moderate'),
    UNHEALTHY: translate('Unhealthy'),
    SENSITIVE: translate('Sensitive'),
    IMPAIRED: translate('Impaired'),
    VERY_UNHEALTHY: translate('Very Unhealthy'),
    HAZARDOUS: translate('Hazardous')
  }
  let coloredTextIndicator = ''
  let unit = '°C'
  let name = t('kiosk.Temperature')
  let img = ''
  if (reading.value && !isNaN(reading.value)) {
    switch (key) {
      case 'temperature':
        if (!reading.value) return
        if (temperatureScale.min < reading.value && reading.value <= temperatureScale.max)
          coloredTextIndicator = pollutionLevels.GOOD
        else coloredTextIndicator = ''
        name = t('kiosk.Temperature')
        img = iconTemperature
        break
      case 'tvoc':
        if (reading.value < tvocScale.good) {
          coloredTextIndicator = pollutionLevels.GOOD
        } else if (reading.value < tvocScale.moderate) {
          coloredTextIndicator = pollutionLevels.MODERATE
        } else if (reading.value >= tvocScale.moderate) {
          coloredTextIndicator = pollutionLevels.UNHEALTHY
        } else {
          coloredTextIndicator = ''
        }
        name = t('kiosk.tvoc')
        unit = 'mg/m3'
        img = iconTVOC
        break
      case 'humidity':
        reading.value = Math.round(reading.value)
        if (humidityScale.min < reading.value && reading.value <= humidityScale.max)
          coloredTextIndicator = pollutionLevels.GOOD
        else coloredTextIndicator = ''
        unit = '% RH'
        name = t('kiosk.Relative_Humidity')
        img = iconHumidity
        break
      case 'co2':
        if (co2Scale.excellent > reading.value) {
          coloredTextIndicator = pollutionLevels.EXCELLENT
        } else if (co2Scale.good > reading.value) {
          coloredTextIndicator = pollutionLevels.GOOD
        } else if (co2Scale.moderate > reading.value) {
          coloredTextIndicator = pollutionLevels.MODERATE
        } else if (co2Scale.sensitive > reading.value) {
          coloredTextIndicator = pollutionLevels.SENSITIVE
        } else if (co2Scale.impaired > reading.value) {
          coloredTextIndicator = pollutionLevels.IMPAIRED
        } else if (co2Scale.impaired < reading.value) {
          coloredTextIndicator = pollutionLevels.UNHEALTHY
        } else {
        }
        unit = 'ppm'
        name = t('kiosk.co2')
        img = iconCO2
        break
      case 'pm2p5':
        if (pm2p5Scale.good >= reading.value) {
          coloredTextIndicator = pollutionLevels.GOOD
        } else if (pm2p5Scale.moderate >= reading.value) {
          coloredTextIndicator = pollutionLevels.MODERATE
        } else if (pm2p5Scale.sensitive >= reading.value) {
          coloredTextIndicator = pollutionLevels.SENSITIVE
        } else if (pm2p5Scale.unhealthy >= reading.value) {
          coloredTextIndicator = pollutionLevels.UNHEALTHY
        } else if (pm2p5Scale.veryUnhealthy >= reading.value) {
          coloredTextIndicator = pollutionLevels.VERY_UNHEALTHY
        } else if (pm2p5Scale.veryUnhealthy <= reading.value) {
          coloredTextIndicator = pollutionLevels.HAZARDOUS
        } else {
        }
        unit = 'μg/m³'
        name = t('kiosk.PM25')
        img = iconPM25
        break
      default:
        coloredTextIndicator = ''
    }
  } else {
    coloredTextIndicator = ''
  }

  let IndicatorColor = ''
  if (coloredTextIndicator === pollutionLevels.EXCELLENT) {
    IndicatorColor = commonColors.darkDreen
  } else if (coloredTextIndicator === pollutionLevels.GOOD) {
    IndicatorColor = commonColors.green
  } else if (coloredTextIndicator === pollutionLevels.MODERATE) {
    IndicatorColor = commonColors.orangeYellow
  } else if (coloredTextIndicator === pollutionLevels.UNHEALTHY) {
    IndicatorColor = commonColors.orangeRed
  } else if (coloredTextIndicator === pollutionLevels.SENSITIVE) {
    IndicatorColor = commonColors.redOrange
  } else if (coloredTextIndicator === pollutionLevels.VERY_UNHEALTHY) {
    IndicatorColor = commonColors.redOrange
  } else if (coloredTextIndicator === pollutionLevels.IMPAIRED) {
    IndicatorColor = commonColors.red
  } else if (coloredTextIndicator === pollutionLevels.HAZARDOUS) {
    IndicatorColor = commonColors.red
  } else {
    IndicatorColor = commonColors.black
  }

  return (
    <div className='index-row'>
      <div className='index-logo' style={{ display: 'flex', alignItems: 'center', marginRight: '1.6vw' }}>
        <span
          style={{
            background: '#006B94',
            borderRadius: '2.2vw',
            padding: '0.3vw'
          }}
        >
          <img className='elipseSize' alt='elipse' src={img} />
        </span>
      </div>
      <div>
        <p className='index-name'>{name}</p>
        <p className='index-value'>
          {reading.value} {unit}
        </p>
        <div
          className='index-value-indicator'

        >
          <p style={{
            fontWeight: 'bold',
            color: IndicatorColor
          }}>{coloredTextIndicator}</p>
        </div>
      </div>
    </div>
  )
}
