import { Button, Carousel, Icon } from 'antd'
import { t } from 'i18next'
import * as React from 'react'
import guideImageOne from '../../images/guide/1.png'
import guideImageTwo from '../../images/guide/2.png'
import guideImageThree from '../../images/guide/3.png'
import './index.css'

interface Props {
  onClose?: () => void;
}

type State = Readonly<{
  currentSlide: number
}>

export default class WelcomeModal extends React.PureComponent<Props, State> {
  carousel: any
  state: State = {
    currentSlide: 1
  }

  navPrev = e => {
    e.preventDefault()
    this.carousel.prev()
  }

  afterChange = current => {
    this.setState({
      currentSlide: current + 1
    })
  }

  navNext = e => {
    e.preventDefault()
    this.carousel.next()
  }

  render() {
    const { onClose } = this.props
    const { currentSlide } = this.state
    const guideOne = guideImageOne;
    const guideTwo = guideImageTwo;
    const guideThree = guideImageThree;
    return (
      <div className='welcome-modal-wrap'>
        <div className='welcome-modal-mask'>
          <div className='welcome-modal-body'>
            {currentSlide > 1 && (
              <a className='welcome-modal-nav nav-left' onClick={this.navPrev}>
                <Icon type='left' />
              </a>
            )}
            {currentSlide < 3 && (
              <a className='welcome-modal-nav nav-right' onClick={this.navNext}>
                <Icon type='right' />
              </a>
            )}
            <Carousel afterChange={this.afterChange} ref={e => (this.carousel = e)} effect='scrollx'>
              <div>
                <h1>{t('welcome.title1')}</h1>
                <div className='welcome-modal-desc'>{t('welcome.desc1')}</div>
                <img src={guideOne} alt='' />
              </div>
              <div>
                <h1>{t('welcome.title2')}</h1>
                <div className='welcome-modal-desc'>{t('welcome.desc2')}</div>
                <img src={guideTwo} alt='' />
              </div>
              <div>
                <h1>{t('welcome.title3')}</h1>
                <div className='welcome-modal-desc'>{t('welcome.desc3')}</div>
                <img src={guideThree} alt='' />
                <div className='start-v3-btn'>
                  <Button size='large' onClick={onClose} type='primary'>
                    {t('welcome.start')}
                  </Button>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    )
  }
}
