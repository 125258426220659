import TableIcon from '-!svg-react-loader!images/svg/Table.svg';
import { requestCollectionDetail, REQUEST_COLLECTIONS_V3 } from 'actions/V3/collections';
import { refreshPermission } from 'actions/V3/permission';
import {
  Breadcrumb, Icon, Input as antInput,
  message,
  Modal,
  notification, Tooltip
} from 'antd';
import className from 'classnames';
import Button from 'components/Custom/Button';
import Input from 'components/Custom/Input';
import Editable from 'components/Editable';
import AddCollection from 'components/LocationModals/AddCollection';
import History from 'containers/IndicatorDetail/History';
import * as ModalElement from 'containers/IndicatorDetail/Modal';
import TextRow from 'containers/IndicatorDetail/TextRow';
import { t } from 'i18next';
import { flatten } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import cache from 'sagas/cache';
import { promiseDispatch } from 'utils';
import { apiV3 } from 'utils/api';
import { Guard } from 'utils/guard';
import { P, State, StateProps } from './type';

const { TextArea } = antInput;
@Guard
class Detail extends React.PureComponent<P, State> {
  state: State = { modal: '', indicatorID: -1, noteEdit: false };
  resetCache = cache.resetCache.bind(null, REQUEST_COLLECTIONS_V3);

  componentDidMount() {
    this.fetchDetail();
  }

  UNSAFE_componentWillReceiveProps(next: P) {
    const { collection_id } = next.match.params;
    const { collection_id: cID } = this.props.match.params;

    if (collection_id !== cID) {
      this.fetchDetail(false, next);
    }
  }

  GuardHandle_indicatorUpdate = (rule?: GuardRule) => rule && rule.enable;

  fetchDetail = async (show = true, props = this.props) => {
    const {
      dispatch,
      match: {
        params: { collection_id }
      }
    } = props;

    await promiseDispatch({
      dispatch,
      actionCreator: requestCollectionDetail,
      payload: { collection_id: +collection_id, show }
    }).catch((err: ResError) => {
      notification.error({
        message: t('Request Error', { id: err.id, code: err.code }),
        description: err.message,
        duration: null
      });

      if (err.code === 10005) {
        const {
          history,
          match: {
            params: { id }
          }
        } = this.props;
        history.replace(`/workspaces/${id}/collections`);
      }
    });
  };

  GuardHandle_collectionUpdate = (rule?: GuardRule) => rule && rule.enable;

  GuardTrigger_collectionDeleteButton = () => (
    <Button
      onClick={() => this.setState({ modal: 'deleteCollection' })}
      customType='danger'
    >
      {t('collection.Delete Collection')}
    </Button>
  );
  form = () => {
    const collection = this.props.collection!;
    const {
      match: {
        params: { id }
      },
      history
    } = this.props;
    const editable = this.GuardHandle_collectionUpdate();

    const { noteEdit, note } = this.state;

    const indicators = flatten(
      collection.indicators.map((c, i) => [
        {
          label: i === 0 && t('Indicators Name'),
          child: (
            <div className='collection-indicator'>
              <span className='collection-name'>
                {c.name}
                <Tooltip title={t('collection.config indicator')}>
                  <Icon
                    type='setting'
                    onClick={() =>
                      history.push(`/workspaces/${id}/indicators/${c.id}`)
                    }
                    style={{ color: window.PRIMARY_COLOR, marginLeft: 10 }}
                  />
                </Tooltip>
              </span>
              <span>
                {editable && (
                  <Tooltip title={t('collection.remove indicator')}>
                    <i
                      className='material-icons collection-remove'
                      onClick={() =>
                        this.setState({
                          modal: 'removeIndicator',
                          indicatorID: c.id
                        })
                      }
                    >
                      remove_circle
                    </i>
                  </Tooltip>
                )}
              </span>
            </div>
          ),
          top: i === 0 ? 30 : 10
        },
        {
          label: i === 0 && (
            <span className='small_text'>
              {t('collection.assigned Datasource')}
            </span>
          ),
          child: (
            <>
              <Link
                className='collection-name small_text'
                to={`/workspaces/${id}/datasources/${c.data_source_id}`}
              >
                {c.data_source_identifier || ''}
              </Link>
            </>
          ),
          top: 1
        }
      ])
    );

    return indicators.concat([
      {
        label: '',
        child: (
          <div
            className='collection-add_text accent_text'
            style={{ margin: '10px 0' }}
            onClick={() => this.setState({ modal: 'AddIndicator' })}
          >
            <i className='material-icons'>add</i>
            {t('collection.Add indicators to Collection')}
          </div>
        ),
        top: indicators.length ? 1 : 0
      },
      {
        label: t('Location Name'),
        child: (
          <div className='collection-date grey_text'>
            {collection.locations.map((c, i) => (
              <div key={`collection-${i}`}>
                <Link
                  to={`/workspaces/${id}/locations/${c.id}#collection-${collection.id}`}
                >
                  {c.name}
                </Link>
              </div>
            ))}
          </div>
        ),
        top: 30
      },
      {
        label: t('Notes'),
        child: (
          <div className='collection-note hidden-mobile'>
            <TextArea
              rows={5}
              disabled={!noteEdit}
              className='collection-note-textArea'
              value={note === undefined ? collection.notes : note}
              onChange={e => this.setState({ note: e.target.value })}
            />
            {noteEdit ? (
              <i className='collection-note-edit' onClick={this.updateNotes}>
                {t('done')}
              </i>
            ) : (
              <i
                className='material-icons collection-note-edit'
                onClick={() => this.setState({ noteEdit: true })}
              >
                edit
              </i>
            )}
          </div>
        ),
        top: 30
      }
    ]);
  };

  updateCollection = async disable => {
    const { name } = this.state;
    const {
      match: {
        params: { collection_id }
      }
    } = this.props;
    if (name === undefined) {
      return disable();
    }
    if (!name) {
      return message.warn(t('collection.input collection'));
    }
    await apiV3(`/collections/${collection_id}`, 'patch', {
      name
    });

    disable();
    this.fetchDetail(false);
  };

  updateNotes = async () => {
    const { note } = this.state;
    const {
      match: {
        params: { collection_id }
      }
    } = this.props;
    await apiV3(`/collections/${collection_id}`, 'patch', {
      notes: note
    });

    this.setState({ noteEdit: false });
  };

  unbindIndicator = async () => {
    const { indicatorID } = this.state;
    const {
      match: {
        params: { collection_id }
      }
    } = this.props;
    this.resetCache();
    await apiV3(`/collections/${collection_id}/indicators/unbinding`, 'post', {
      indicator_id: [indicatorID]
    });

    this.setState({
      modal: ''
    });
    this.fetchDetail(false);
  };

  deleteCollection = async () => {
    const {
      match: {
        params: { collection_id }
      },
      history,
      dispatch
    } = this.props;
    this.resetCache();
    await apiV3(`/collections/${collection_id}`, 'delete');
    dispatch(
      refreshPermission({
        show: false
      })
    );
    this.setState({ modal: '' });
    history.goBack();
  };

  close = () => {
    this.setState({ modal: '' });
    this.fetchDetail(false);
  };

  renderModalElement = () => {
    const { modal, indicatorID } = this.state;
    const { collection } = this.props;

    switch (modal) {
      case 'dataSource':
        return (
          <ModalElement.DataSource
            indicatorID={indicatorID}
            close={this.close}
          />
        );
      case 'AddIndicator':
        return (
          <AddCollection
            close={this.close}
            type='bind'
            collectionID={collection!.id}
            name={collection!.name}
          />
        );
      case 'deleteCollection':
        return (
          <div className='colletion_modal-container'>
            <h3 className='register-title'>
              {t('collection.Delete Collection')}
            </h3>
            {collection && !!collection.locations.length && (
              <p className='small_text'>
                {t('collection.This Collection is being used')}
              </p>
            )}

            <div className='indicator-body'>
              {collection &&
                collection.locations.map((location, v) => (
                  <p
                    key={`collction-location-${v}`}
                    className='indicator-location'
                  >
                    {location.name}
                  </p>
                ))}

              <p className='small_text'>
                {t('collection.Deleting this Collection')}
              </p>
            </div>
            <div className='indicator-button'>
              <Button
                customType='light'
                size='small'
                onClick={() => this.setState({ modal: '' })}
              >
                {t('Cancel')}
              </Button>
              &nbsp;&nbsp;
              <Button
                customType='danger'
                size='small'
                onClick={this.deleteCollection}
              >
                {t('Delete')}
              </Button>
            </div>
          </div>
        );

      case 'removeIndicator':
        return (
          <div className='colletion_modal-container'>
            <h3 className='register-title'>{t('Remove Indicator')}</h3>
            <div className='register-body'>
              <p
                className='register-message small_text'
                dangerouslySetInnerHTML={{
                  __html: t('collection.Remove Indicator from this Collection')
                }}
              />
            </div>
            <div className='colletion_modal-button_group'>
              <Button
                customType='light'
                radius='rect'
                onClick={() => this.setState({ modal: '' })}
              >
                {t('Cancel')}
              </Button>
              &nbsp;&nbsp;
              <Button
                customType='danger'
                radius='rect'
                onClick={this.unbindIndicator}
              >
                {t('Remove')}
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    const {
      collection,
      location: { pathname },
      match: {
        params: { id, collection_id }
      }
    } = this.props;
    const { modal } = this.state;

    if (!collection) {
      return null;
    }

    const editable = this.GuardHandle_collectionUpdate();

    const inFormPage = !pathname.includes('/audit');

    const { name } = this.state;
    return (
      <>
        <div className='ds-detail-header hidden-mobile'>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link
                className='collection-link'
                to={`/workspaces/${id}/collections`}
              >
                <i className='material-icons collection-icon'>group_work</i>
                {t('collection.All Collections')}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>{collection!.name}</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <Link
            className='link-icon'
            to={`/workspaces/${id}/data?cate=collection&collection_id=${collection.id}`}
          >
            <TableIcon />
            <span>{t('navbar.Table')}</span>
          </Link>
        </div>
        <div className='collection-detail-title collection-detail-row'>
          <div className='collection-detail-row_left'>
            <div
              className={className('collection-detail-status is-collection', {
                [`is-${collection.reading.status}`]: collection.reading.status
              })}
            >
              <span className='collection-detail-status_num'>
                {/* {indicator.reading.value} */}
              </span>
              <span className='collection-detail-status_text'>
                {/* {indicator.reading.level
                  ? indicator.reading.level
                  : indicator.reading.value
                  ? t('Notification.online')
                  : t('Notification.offline')} */}
              </span>
            </div>
          </div>
          <div
            className='collection-detail-row_right'
            style={{ marginTop: 12 }}
          >
            <Editable
              showIcon={editable}
              iconPosition='end'
              iconClass='collection-detail-name_icon'
              render={(edit, disableFunc) =>
                edit ? (
                  <Input
                    disabled={!edit}
                    className='collection-detail-name collection-detail-name--input'
                    type='none'
                    onChange={e => this.setState({ name: e.target.value })}
                    onPressEnter={() => this.updateCollection(disableFunc)}
                    onBlur={() => this.updateCollection(disableFunc)}
                    value={name !== undefined ? name : collection!.name}
                  />
                ) : (
                  <div className='edit-disabled-text'>
                    {name !== undefined ? name : collection!.name}
                  </div>
                )
              }
            />
            <div style={{ marginTop: 8 }}>
              <Link
                replace={true}
                to={{
                  pathname: `/workspaces/${id}/collections/${collection_id}`
                }}
                className={className('collection-detail-tab', {
                  'is-active': inFormPage
                })}
              >
                {t('collection.collection Details')}
              </Link>

              <Link
                replace={true}
                to={{
                  pathname: `/workspaces/${id}/collections/${collection_id}/audit`
                }}
                className={className('collection-detail-tab', {
                  'is-active': !inFormPage
                })}
              >
                {t('indicator.Audit Trail')}
              </Link>
            </div>
          </div>
        </div>

        <Switch>
          <Route
            exact={true}
            path='/workspaces/:id/collections/:collection_id'
            render={() => (
              <div>
                {this.form().map((v: any, i: number) => (
                  <TextRow
                    label={v.label}
                    key={`collection-form-${i}`}
                    top={v.top}
                  >
                    {v.child}
                  </TextRow>
                ))}
                <div className='collection-detail-row hidden-mobile'>
                  <div className='collection-detail-row_left' />
                  <div className='collection-del_button is-collection collection-detail-row_right'>
                    {this.GuardTrigger_collectionDeleteButton()}
                  </div>
                </div>
              </div>
            )}
          />

          <Route
            exact={true}
            path='/workspaces/:id/collections/:collection_id/audit'
            render={() => (
              <History
                requstQuery={{
                  item_type: 'collection',
                  collection_id: +collection_id
                }}
              />
            )}
          />
        </Switch>
        <Modal
          visible={!!modal}
          onCancel={() => this.setState({ modal: '' })}
          footer={null}
          closable={modal !== 'dataSource'}
          bodyStyle={
            modal !== 'AddIndicator'
              ? { padding: 0 }
              : { padding: '1.5rem 2.5rem' }
          }
          width={modal !== 'dataSource' ? 540 : 730}
          centered={true}
          wrapClassName='overview-modal'
        >
          {this.renderModalElement()}
        </Modal>
      </>
    );
  }
}

const mapStateToProps: MapState<StateProps> = ({ V3: { collections } }) => ({
  collection: collections.detail
});

export default withRouter(connect(mapStateToProps)(Detail));
