import * as React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import './Select.css';

interface Props {
  hideIcon?: boolean;
  block?: boolean;
  dropdownTextCenter?: boolean;
  underline?: boolean;
}

export const Option = Select.Option;

const CustomSelect: React.SFC<Props & SelectProps> = React.memo(
    ({
        className,
        hideIcon,
        underline,
        dropdownTextCenter,
        block = false,
        ...other
    }) => (
        <Select
            defaultActiveFirstOption={false}
            dropdownClassName={classNames(
                'custom-select--dropdown',
                dropdownTextCenter ? 'text-center' : ''
            )}
            dropdownMatchSelectWidth={false}
            className={classNames(
                'custom-select',
                className,
                block ? 'is-block' : '',
                hideIcon ? 'custom-select--hideIcon' : '',
                underline ? 'custom-select--underline' : ''
            )}
            {...other as any}
        />
    )
);

export default CustomSelect;
