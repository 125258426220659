import { requestResetUserPWD, requestUserSignIn, requestUserSignUp } from 'actions/V2/userSign'
import { Checkbox, Form, Icon, Input } from 'antd'
import CustomButton from 'components/Custom/Button'
import CustomRadioGroup from 'components/Custom/RadioGroup'
import Container from 'containers/Container'
import { t } from 'i18next'
import { isEmpty, pick, values } from 'lodash'
import qs from 'qs'
import * as React from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { promiseDispatch } from 'utils'
import api from 'utils/api'
import { isLogin } from 'utils/auth'
import logoImage from '../../images/logo_large.png'
import './index.css'
import { P, State, StateProps } from './type'

class Sign extends React.PureComponent<P, State> {
  state: State = { type: '' }

  handleSubmitForget = e => {
    e.preventDefault()
    const { form, dispatch } = this.props
    form.validateFields(async (err, v) => {
      if (!err) {
        try {
          await promiseDispatch({
            dispatch,
            actionCreator: requestResetUserPWD,
            payload: v
          })
          this.setState({
            type: 'afterForget'
          })
        } catch (error: any) {
          if (error.reject) {
            this.setState({
              errorMsg: error.message
            })
          }
        }
      }
    })
  }

  handleSubmitResetPwd = (e, email, token) => {
    e.preventDefault()
    const { form } = this.props
    form.validateFields(async (err, v) => {
      if (!err) {
        await api('/users/reset_password', 'post', {
          email,
          password: v.password,
          reset_password_token: token
        })

        this.setState({
          type: 'afterReset'
        })
      }
    })
  }

  handleSubmitSignIn = e => {
    e.preventDefault()
    const { form, dispatch } = this.props
    form.validateFields(async (err, v) => {
      if (!err) {
        try {
          await promiseDispatch({
            dispatch,
            actionCreator: requestUserSignIn,
            payload: v
          })
        } catch (error: any) {
          this.setState({
            errorMsg: error.code === 10007 ? t('account.error') : error.message
          })
        }
      }
    })
  }

  handleSubmitSignUp = e => {
    e.preventDefault()
    const { form, dispatch } = this.props
    form.validateFields(async (err, v) => {
      if (!err) {
        try {
          await promiseDispatch({
            dispatch,
            actionCreator: requestUserSignUp,
            payload: v
          })
        } catch (error: any) {
          return this.setState({
            errorMsg: error.message
          })
        }

        this.setState({
          type: 'afterSignUp'
        })
      }
    })
  }

  getEmailField = (hasFeedback = false, needPlaceholder = false) => {
    const { getFieldDecorator } = this.props.form
    let placeholder = ''
    if (needPlaceholder) {
      placeholder = t('user.Enter your email')
    }

    return (
      <Form.Item label={t('user.E-mail Address')} hasFeedback={hasFeedback}>
        {getFieldDecorator('email', {
          rules: [
            {
              type: 'email',
              message: t('user.Invalid email format')
            },
            {
              required: true,
              message: t('user.Please input your email')
            }
          ],
          initialValue: hasFeedback ? '' : localStorage.getItem('emailSource') || ''
        })(<Input placeholder={placeholder} />)}
      </Form.Item>
    )
  }

  getPasswordField = (hasFeedBack = false, confirm = false) => {
    const { getFieldDecorator } = this.props.form
    const passwordRule: {}[] = [
      {
        required: true,
        message: t('user.Please input your Password')
      }
    ]
    if (confirm) {
      passwordRule.push({
        validator: this.compareToFirstPassword
      })
    } else {
      passwordRule.push({
        min: 8,
        message: t('user.at least 8 character')
      })
    }
    const label = confirm ? t('user.Confirm Password') : t('user.Password')
    return (
      <Form.Item label={label} hasFeedback={hasFeedBack}>
        {getFieldDecorator(confirm ? 'confirm' : 'password', {
          rules: passwordRule
        })(
          confirm ? (
            <Input placeholder={t('user.confirm your password')} type='password' />
          ) : (
            <Input placeholder={t('user.enter your password')} type='password' />
          )
        )}
      </Form.Item>
    )
  }

  onRadioChange = e => {
    const { history } = this.props
    history.replace(`/user/${e.target.value}`)
  }

  navToDashboard = () => {
    const { history } = this.props
    history.replace('/')
  }

  detectPage = () => {
    const {
      location: { pathname }
    } = this.props
    if (pathname.includes('sign_in')) {
      return 'sign_in'
    }
    if (pathname.includes('sign_up')) {
      return 'sign_up'
    }

    return 'reset'
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form
    if (value && value !== form.getFieldValue('password')) {
      callback(t('Two passwords that you enter is inconsistent'))
    } else {
      callback()
    }
  }

  hideError = () => {
    this.setState({
      errorMsg: undefined
    })
  }

  renderActionDone = (desc, btnText, btnHandle = () => { }) => {
    return (
      <>
        <div className='signinup-icon'>
          <Icon type='check-circle' />
        </div>
        <div className='signinup-resetpwd-tip'>{desc}</div>
        <CustomButton customType='dark' block={true} onClick={btnHandle} className='reset-pwd-button'>
          {btnText}
        </CustomButton>
      </>
    )
  }

  renderSignIn = () => {
    const {
      form: { getFieldDecorator, getFieldsError }
    } = this.props
    const { errorMsg } = this.state
    const disable = values(getFieldsError()).some(v => !!v)
    return (
      <Form onSubmit={this.handleSubmitSignIn} hideRequiredMark={true} layout='vertical' className='signinup-form'>
        {errorMsg && (
          <div className='signinup-error-msg'>
            <Icon type='info-circle-o' />
            <span className='text'>{errorMsg}</span>
            <Icon className='close' type='close' onClick={this.hideError} />
          </div>
        )}
        {this.getEmailField(false, true)}
        {this.getPasswordField()}
        <Form.Item className='signinup-ext'>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true
          })(<Checkbox>{t('user.Remember Me')}</Checkbox>)}
          <Link to='/user/forget' className='login-form-forgot'>
            {t('user.Forget password?')}
          </Link>
        </Form.Item>

        <CustomButton customType='dark' block={true} disabled={disable} htmlType='submit' className='login-form-button'>
          {t('user.Sign In')}
        </CustomButton>
      </Form>
    )
  }

  renderSignUp = () => {
    const { type, errorMsg } = this.state

    const {
      form: { getFieldDecorator, getFieldsError, getFieldsValue },
      history
    } = this.props
    const val = getFieldsValue()
    const disable = isEmpty(val) || values(getFieldsError()).some(v => !!v) || values(val).some(v => !v)

    return type === 'afterSignUp' ? (
      this.renderActionDone(t('user.Your account has been created'), t('user.Enter'), () =>
        history.replace('/workspaces')
      )
    ) : (
      <Form onSubmit={this.handleSubmitSignUp} hideRequiredMark={true} layout='vertical' className='signinup-form'>
        {errorMsg && (
          <div className='signinup-error-msg'>
            <Icon type='info-circle-o' />
            <span className='text'>{errorMsg}</span>
            <Icon className='close' type='close' onClick={this.hideError} />
          </div>
        )}
        {this.getEmailField(true, true)}
        <Form.Item label={t('user.User name')} hasFeedback={true}>
          {getFieldDecorator('user_name', {
            rules: [
              {
                required: true,
                message: t('user.Please input your name!')
              }
            ]
          })(<Input placeholder={t('user.enter your name')} />)}
        </Form.Item>
        {this.getPasswordField(true)}
        {this.getPasswordField(true, true)}
        <Form.Item className='signinup-ext'>
          {getFieldDecorator('agree', {
            valuePropName: 'checked',
            initialValue: true
          })(
            <Checkbox>
              <span dangerouslySetInnerHTML={{ __html: t('user.read and agreed') }} />
            </Checkbox>
          )}
        </Form.Item>
        <CustomButton customType='dark' block={true} disabled={disable} htmlType='submit' className='login-form-button'>
          {t('user.Create an account')}
        </CustomButton>
      </Form>
    )
  }

  renderForget = () => {
    const { type, errorMsg } = this.state
    const {
      form: { getFieldsError, getFieldsValue }
    } = this.props
    const val: { email?: string } = getFieldsValue()

    const disable = values(getFieldsError()).some(v => !!v) || !val.email

    return type === 'afterForget' ? (
      this.renderActionDone(t('user.Reset email has been sent'), t('user.OK'), this.navToDashboard)
    ) : (
      <>
        <h3>{t('user.Frogot your password')}</h3>
        <div className='signinup-resetpwd-tip'>{t('user.Enter your E-mail below')}</div>
        <Form onSubmit={this.handleSubmitForget} hideRequiredMark={true} layout='vertical' className='reset-pwd-form'>
          {errorMsg && (
            <div className='signinup-error-msg'>
              <Icon type='info-circle-o' />
              <span className='text'>{errorMsg}</span>
              <Icon className='close' type='close' onClick={this.hideError} />
            </div>
          )}
          {this.getEmailField(true)}
          <Form.Item>
            <CustomButton
              customType='dark'
              block={true}
              disabled={disable}
              htmlType='submit'
              className='reset-pwd-button'
            >
              {t('user.Send Reset Link')}
            </CustomButton>
          </Form.Item>
        </Form>
      </>
    )
  }

  renderReset = () => {
    const { type } = this.state
    const {
      form: { getFieldsError, getFieldsValue },
      location: { search },
      history
    } = this.props

    const { email, token } = pick(qs.parse(search.slice(1)), ['token', 'email'])

    if (!email || !token) {
      history.replace('/user/sign_in')
      return null
    }

    const val = getFieldsValue()
    const disable = isEmpty(val) || values(getFieldsError()).some(v => !!v) || values(val).some(v => !v)
    return type === 'afterReset' ? (
      this.renderActionDone(t('user.Your password has been changed'), t('user.Sign In'), () => {
        history.replace('/user/sign_in')
        this.setState({
          type: ''
        })
      })
    ) : (
      <>
        <h1>{t('user.Reset Password')}</h1>
        <div className='signinup-resetpwd-tip'>
          {t('user.Hello there!')}
          <br />
          {t('user.Please reset your new password')}
        </div>
        <Form
          onSubmit={e => this.handleSubmitResetPwd(e, email, token)}
          hideRequiredMark={true}
          layout='vertical'
          className='reset-pwd-form'
        >
          <Form.Item label={t('user.E-mail Address')}>
            <Input value={email.toString()} disabled={true} />
          </Form.Item>
          {this.getPasswordField(true)}
          {this.getPasswordField(true, true)}
          <Form.Item>
            <CustomButton
              customType='dark'
              block={true}
              disabled={disable}
              htmlType='submit'
              className='reset-pwd-button'
            >
              {t('user.Update New Password')}
            </CustomButton>
          </Form.Item>
        </Form>
      </>
    )
  }

  render() {
    const page = this.detectPage()
    const { type } = this.state
    const { config } = this.props
    const logo = config ? config.logo : logoImage;
    return (
      <Container>
        <div className='location-wrapper'>
          <div className='signinup-wrap location-inner'>
            <div className='signiup-wrap-mobile'>
              <div className='signinup-logo-container'>
                <Link to='/'>
                  <img className='signinup-logo' src={logo} alt='QLEAR' />
                </Link>
              </div>

              <div className='signinup-container'>
                <div className='signinup-left'>
                  {!type && (page === 'sign_in' || page === 'sign_up') && (
                    <CustomRadioGroup
                      value={page}
                      onChange={this.onRadioChange}
                      list={[
                        {
                          label: t('user.Sign In'),
                          value: 'sign_in'
                        },
                        {
                          label: t('user.Sign Up'),
                          value: 'sign_up'
                        }
                      ]}
                    />
                  )}
                  {!type && (page === 'sign_in' || page === 'sign_up') && (
                    <div className='signinup-tip'>
                      {t('user.Hello there!')}
                      <br />
                      {page === 'sign_in' && t('user.Sign in to start managing your data')}
                      {page === 'sign_up' && t('user.Create an account to get started')}
                    </div>
                  )}
                  <Switch>
                    <Route path='/user/password/reset' component={this.renderReset} />
                    {isLogin() && <Redirect to='/' />}
                    <Route path='/user/sign_in' component={this.renderSignIn} />
                    <Route path='/user/sign_up' component={this.renderSignUp} />
                    <Route path='/user/forget' component={this.renderForget} />

                    <Redirect to='/user/sign_in' />
                  </Switch>
                </div>
                <div className='signinup-right' />
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }
}

const mapStateToProps: MapState<StateProps> = ({ V2: { userSign }, V3: { config } }) => ({
  userInfo: userSign.data,
  config: config.data
})

export default Form.create()(withRouter(connect(mapStateToProps)(Sign)))
