import { put, call } from 'redux-saga/effects';
import { getCookie } from 'utils/auth';
import { REQUEST_USER_SHIP, getUserShip } from 'actions/V2/userShip';
import { watchRequest } from 'sagas/helper';
import api from 'utils/api';

export function* request() {
    const { data } = yield call(api, '/user_ships', 'get', {
        user_id: getCookie()!.id
    });
    yield put(getUserShip(data.filter(v => v.visibility === 'show'), data));
    return data;
}

export default watchRequest(REQUEST_USER_SHIP, request);
