import React, { useState, useEffect } from 'react';
import {
    Collapse,
    Icon,
    Input,
    Switch,
    Select,
    InputNumber,
    Button
} from 'antd';
import CustomSelect from 'components/Custom/Select';
import { t } from 'i18next';
import './index.css';
import { apiV3 } from 'utils/api';
import { logging } from 'utils/loggers';
import customKioskSettings from '../../vendors/custom';

const Panel = Collapse.Panel;
const Option = Select.Option;

type item = {
    available_units: string[],
    channel: string,
    default_unit: string,
    formula: any,
    hidden: boolean,
    name: string,
    type: string,
    unit: null,
    identifier?: string,
    max: number,
    value: number,
    info: string
}
type ChannelEditInfo = {
    available_units: string[],
    channel: string,
    default_unit: string,
    formula?: string,
    hidden: boolean,
    identifier: string,
    indicator_id: number,
    name: string,
    type: DataChannelType,
    unit?: string
  }

export default function Collapsedpannel({
    obj,
    currentKiosk,
    useAqi,
    data_channels,
    template_key,
    meta
}) {
    const [dataChannels, setDataChannels] = useState<ChannelEditInfo|any>();
    const [forceUpdate, setForceUpdate] = useState(0)
    const [covidIndicators, setCovidIndicators] = useState({
        ach: null,
        cadr: null,
        efficiency: null
    });
    const [isCovidIndicators, setIsCovidIndicators] = useState(true);

    const IndexKioskTemplateKey = customKioskSettings.covid_index.template_key;
    const miscellenaousIndexKioskAPICall = {
        source: 'qlear-web-v3',
        locale: 'en',
        v: '3.3.1',
        upgrade: true
    };

    async function fetchCovidIndexData() {
        const params = {
            kiosk_id: currentKiosk.id,
            auth_token,
            access_token,
            miscellenaousIndexKioskAPICall
        };
        try {
            const { ach, cadr, efficiency }: any = await apiV3(
                '/covid_index_input_set',
                'get',
                params
            );
            setCovidIndicators({
                ach,
                cadr,
                efficiency
            });
        } catch (err) {
            logging.error('get covid indicators', err);
        }
    }
    useEffect(() => {
        currentKiosk.template_key === IndexKioskTemplateKey &&
      fetchCovidIndexData();
    }, [IndexKioskTemplateKey, currentKiosk.template_key]);

    useEffect(() => setDataChannels(data_channels), [data_channels]);

    const { access_token, auth_token } = meta;

    const NO_UUID_INDICATORS = ['ach', 'cadr', 'efficiency'];

    const showCovidIndexSetUp = () => {
        if (isCovidIndicators) {
            setDataChannels([
                ...dataChannels,
                {
                    channel: NO_UUID_INDICATORS[0],
                    name: 'ACH',
                    value: covidIndicators.ach,
                    info: 'ACH info',
                    max: 25,
                    unit: ''
                },
                {
                    channel: NO_UUID_INDICATORS[1],
                    name: 'CADR',
                    value: covidIndicators.cadr,
                    info: 'CADR info',
                    max: 40000,
                    unit: '(CMH)'
                },
                {
                    channel: NO_UUID_INDICATORS[2],
                    name: 'Efficiency',
                    value: covidIndicators.efficiency,
                    info: 'Efficiency info',
                    max: 100,
                    unit: ''
                }
            ]);
        } else {
            for (let i = 0; i < NO_UUID_INDICATORS.length; i++) dataChannels.pop();
        }
        setIsCovidIndicators(!isCovidIndicators);
    };

    const updateCovidIndicators = (clickEvent:number | undefined, item:item) => {
        setCovidIndicators({ ...covidIndicators, [item.channel]: clickEvent });
    };

    const sendCovidIndicators = async () => {
        const { ach, cadr, efficiency } = covidIndicators;
        const params = {
            kiosk_id: currentKiosk.id,
            auth_token,
            access_token,
            ach,
            cadr,
            efficiency,
            miscellenaousIndexKioskAPICall
        };

        const fetchCovidIndexData = async () => {
            try {
                await apiV3('/covid_index_input_set', 'put', params);
            } catch (err) {
                logging.error('Updating Covid Data', err);
            }
        };
        fetchCovidIndexData();
    };

    const renderDatachannelName = ({ formula, name }) =>
        formula ? (
            <span>
                {name} <span className='formula-label'>F</span>
            </span>
        ) : (
            name
        );

    const swapItemPositions = (
        arr: any[],
        itemPositionA: number,
        itemPositionB: number
    ) => {
        [arr[itemPositionA], arr[itemPositionB]] = [arr[itemPositionB], arr[itemPositionA]]
    }

    return (
        <>
            {dataChannels && dataChannels.length ? (
                <div>
                    <div className='data-channel-settings'>
                        <div className='sm-label'>{t('Indicator')}</div>
                        <Collapse accordion={true}>
                            {dataChannels.map((item:item, itemPosition:number) => (
                                <Panel
                                    key={itemPosition}
                                    header={renderDatachannelName(item)}
                                    extra={
                                        <>
                                            <Switch
                                                checkedChildren={<Icon type='check' />}
                                                unCheckedChildren={<Icon type='close' />}
                                                onClick={(v, clickEvent) => clickEvent.stopPropagation()}
                                                onChange={(v) => (dataChannels[itemPosition].hidden = !v)}
                                                defaultChecked={!item.hidden}
                                            />
                                            <span
                                                className='order-btn'
                                                onClick={(clickEvent) => {
                                                    clickEvent.stopPropagation();
                                                    const itemPositionIndices = {
                                                        start: 0,
                                                        offset: -1
                                                    }

                                                    if (itemPosition > itemPositionIndices.start) {
                                                        swapItemPositions(dataChannels, itemPosition, itemPosition + itemPositionIndices.offset)
                                                        setDataChannels(dataChannels);
                                                        setForceUpdate(forceUpdate + itemPositionIndices.offset)
                                                    }
                                                }}
                                            >
                                                <i className='iconfont icon-rising' />
                                            </span>
                                            <span
                                                className='order-btn'
                                                onClick={(clickEvent) => {
                                                    clickEvent.stopPropagation();
                                                    const itemPositionIndices = {
                                                        end: dataChannels.length - 1,
                                                        offset: 1
                                                    }

                                                    if (itemPosition < itemPositionIndices.end) {
                                                        swapItemPositions(dataChannels, itemPosition, itemPosition + itemPositionIndices.offset)
                                                        setDataChannels(dataChannels);
                                                        setForceUpdate(forceUpdate + itemPositionIndices.offset)
                                                    }

                                                }}
                                            >
                                                <i className='iconfont icon-falling' />
                                            </span>
                                        </>
                                    }
                                >
                                    {item.identifier && (
                                        <div className='dc-identifier'>
                                            <span className='cell-label'>UUID:</span>
                                            {item.identifier}
                                        </div>
                                    )}
                                    {useAqi && item.channel === 'pm2p5' ? (
                                        t('kiosk.aqi used')
                                    ) : (
                                        <div className='data-channel-advance'>
                                            {!NO_UUID_INDICATORS.includes(item.channel) ? (
                                                <div className='data-formula'>
                                                    <div className='cell-label'>
                                                        <span>{t('Formula')}</span>
                                                        <a
                                                            href='https://blog.qlear.io/2019/04/25/How-to-switch-between-different-units.html'
                                                            target='blank'
                                                            className='help-link'
                                                        >
                                                            <i className='iconfont icon-help' />
                                                        </a>
                                                    </div>
                                                    <Input
                                                        defaultValue={item.formula}
                                                        onChange={(clickEvent) =>
                                                            (dataChannels[itemPosition].formula = clickEvent.currentTarget.value)
                                                        }
                                                    />
                                                </div>
                                            ) : (
                                                <div className='data-formula'>
                                                    <div className='cell-label'>
                                                        <span>{t('Value')}</span>
                                                    </div>
                                                    <InputNumber
                                                        min={0.1}
                                                        max={item.max}
                                                        className='data-covid-input'
                                                        id={item.channel}
                                                        onChange={(clickEvent) => updateCovidIndicators(clickEvent, item)}
                                                        onBlur={() => sendCovidIndicators()}
                                                        defaultValue={item.value ? item.value : 1}
                                                    />
                                                    {item.unit && <Button>{item.unit}</Button>}
                                                    <span className='data-covid-info'>
                                                        {t(`${item.info}`)}
                                                    </span>
                                                </div>
                                            )}

                                            {!NO_UUID_INDICATORS.includes(item.channel) && (
                                                <div className='unit-group'>
                                                    <div className='cell-label'>{t('UNITS')}</div>
                                                    <CustomSelect
                                                        underline={false}
                                                        className='data-channel-unit'
                                                        dropdownMatchSelectWidth={true}
                                                        defaultValue={item.unit || item.default_unit}
                                                        onChange={(v) => (dataChannels[itemPosition].unit = v)}
                                                    >
                                                        {item.available_units.map((unit: string, ui: number) => (
                                                            <Option key={ui} value={unit}>
                                                                {unit}
                                                            </Option>
                                                        ))}
                                                    </CustomSelect>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                    {template_key === IndexKioskTemplateKey && (
                        <div
                            onClick={() => {
                                showCovidIndexSetUp();
                            }}
                            className='data-button-unit'
                        >
                            {isCovidIndicators ? (
                                <>
                                    <i className='material-icons data-button-add'>add</i>
                                    {t('Set up Covid indicators')}
                                </>
                            ) : (
                                <>
                                    <i className='material-icons data-button-add'>remove</i>
                                    {t('Remove Covid indicators')}
                                </>
                            )}
                        </div>
                    )}

                    <div className='primary-indicator-settings'>
                        <div className='sm-label'>{t('Primary Data Channel')}</div>
                        <CustomSelect
                            underline={false}
                            className='primary-indicator'
                            dropdownMatchSelectWidth={true}
                            defaultValue={obj.primary_data_channel}
                            getPopupContainer={(trigger) => trigger!.parentElement!}
                            onChange={(v) => (obj.primary_data_channel = v || null)}
                        >
                            <Option key={-1} value={undefined}>
                                {t('kiosk.none')}
                            </Option>
                            {dataChannels.map((item:item, i:number) => (
                                <Option key={i} value={item.channel}>
                                    {item.name}
                                </Option>
                            ))}
                        </CustomSelect>
                    </div>
                </div>
            ) : (
                <div className='empty-tip'>No Data channel</div>
            )}
        </>
    );
}
